// Not exhaustive, feel free to complete it
export enum MimeType {
    IMAGE_PNG = 'image/png',
    IMAGE_JPEG = 'image/jpeg',
    IMAGE_HEIC = 'image/heic',
    IMAGE_HEIF = 'image/heif',
    VIDEO_MP4 = 'video/mp4',
    VIDEO_QUICKTIME = 'video/quicktime',
    VIDEO_WEBM = 'video/webm',
}

export function guessMimeTypeFromExtension(extension: string): MimeType | null {
    const lowerCasedExtension = extension.toLowerCase();
    if (lowerCasedExtension === 'mp4') {
        return MimeType.VIDEO_MP4;
    }
    if (['mov', 'movie', 'qt', 'quicktime'].includes(lowerCasedExtension)) {
        return MimeType.VIDEO_QUICKTIME;
    }
    if (lowerCasedExtension === 'webm') {
        return MimeType.VIDEO_WEBM;
    }
    return null;
}
