import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';

import { PostSource } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { SocialPostsListV2Component } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list.component';
import { UpsertSocialPostModalComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.component';
import {
    UpsertSocialPostModalProps,
    UpsertSocialPostModalResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { DuplicateSocialPostsService } from ':modules/posts-v2/social-posts/duplicate-social-posts.service';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';

import { SocialPostsListComponent } from './social-posts-list/social-posts-list.component';

@Component({
    selector: 'app-social-posts-root',
    templateUrl: './social-posts-root.component.html',
    styleUrls: ['./social-posts-root.component.scss'],
    standalone: true,
    imports: [MalouSpinnerComponent, SocialPostsListComponent, SocialPostsListV2Component],
})
export class SocialPostsRootComponent implements OnInit, OnDestroy {
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _router = inject(Router);
    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _duplicateSocialPostsService = inject(DuplicateSocialPostsService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _dialogService = inject(DialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);
    private readonly _experimentationService = inject(ExperimentationService);
    readonly isNewSocialPostsAndMediasEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-social-posts-and-medias'),
        { initialValue: false }
    );

    readonly isGrowthbookLoading = signal(true);

    ngOnInit(): void {
        this._experimentationService.isLoaded$.pipe(filter(Boolean), take(1)).subscribe(() => {
            this.isGrowthbookLoading.set(false);
        });
    }

    ngOnDestroy(): void {
        this._socialPostsContext.stopPollingPostsStatus$.next();
    }

    onCreatePost(): void {
        this._openUpsertSocialPostModal({ disconnectedPlatforms$: this._socialPostsContext.disconnectedPlatforms$ });
    }

    onUpdatePost(data: { postId: string; shouldOpenFeedbacks?: boolean }): void {
        this._openUpsertSocialPostModal({ ...data, disconnectedPlatforms$: this._socialPostsContext.disconnectedPlatforms$ });
    }

    onDeletePost(data: { postId: string; isPublishedOnFacebook: boolean }): void {
        this._dialogService.open({
            title: this._pluralTranslatePipe.transform('social_post.delete_confirmation_modal.title', 1),
            message: data.isPublishedOnFacebook
                ? this._pluralTranslatePipe.transform('social_post.delete_confirmation_modal.message_published_on_facebook', 1)
                : this._pluralTranslatePipe.transform('social_post.delete_confirmation_modal.message', 1),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translateService.instant('common.confirm'),
                action: () => {
                    this._socialPostsContext.deleteSocialPosts([data.postId]);
                },
            },
            secondaryButton: {
                label: this._translateService.instant('common.cancel'),
            },
        });
    }

    onCreateReelOrTikTok(): void {
        this._openUpsertSocialPostModal({ isReel: true, disconnectedPlatforms$: this._socialPostsContext.disconnectedPlatforms$ });
    }

    onDuplicateHere({ postIds, postDestination }: { postIds: string[]; postDestination: PostSource }): void {
        return this._duplicateSocialPostsService.duplicateHere({
            postIds,
            postDestination,
            fromRestaurantId: this._socialPostsContext.restaurant().id,
            onDuplicationSuccess: this._onDuplicationSuccess,
            onDuplicationError: this._onDuplicationError,
        });
    }

    onDuplicateToOtherRestaurants({ postIds, postDestination }: { postIds: string[]; postDestination: PostSource }): void {
        return this._duplicateSocialPostsService.duplicateToOtherRestaurants({
            postIds,
            postDestination,
            fromRestaurantId: this._socialPostsContext.restaurant().id,
            onDuplicationSuccess: this._onDuplicationSuccess,
            onDuplicationError: this._onDuplicationError,
        });
    }

    private _onDuplicationSuccess = (): void => {
        this._socialPostsContext.postSelection.unselectAll();
        this._socialPostsContext.isSelecting.set(false);
        this._customDialogService.closeAll();
    };

    private _onDuplicationError = (error: unknown): void => {
        console.error(error);
        this._socialPostsContext.postSelection.unselectAll();
        this._socialPostsContext.isSelecting.set(false);
        this._customDialogService.closeAll();
    };

    private _openUpsertSocialPostModal(data: UpsertSocialPostModalProps): void {
        this._customDialogService
            .open<UpsertSocialPostModalComponent, UpsertSocialPostModalProps, UpsertSocialPostModalResult>(
                UpsertSocialPostModalComponent,
                {
                    width: '100vw',
                    disableClose: false,
                    height: '100vh',
                    data,
                },
                { animateScreenSize: DialogScreenSize.ALL }
            )
            .afterClosed()
            .subscribe((result) => {
                if (result?.post) {
                    // Replace the post in the list and in the feed
                    this._socialPostsContext.upsertSocialPost(result.post);

                    // Re-fetch the filter options count
                    this._socialPostsContext.shouldFetchFilterOptionsCount$.next();

                    if (result.duplicateToSeo) {
                        this._socialPostsContext.duplicatePosts({
                            postIds: [result.post.id],
                            restaurantIds: [this._socialPostsContext.restaurant().id],
                            postDestination: PostSource.SEO,
                        });
                    }
                }

                if (result?.scrollToPostId) {
                    this._socialPostsContext.setFetchUntilPostIsFound(result.scrollToPostId);
                    this._socialPostsContext.fetchPostsUntilPostIsFound(result.scrollToPostId);
                    this._socialPostsContext.highlightPosts([result.scrollToPostId]);
                }

                const queryParams = this._activatedRoute.snapshot.queryParams;
                if (Object.keys(queryParams).length > 0) {
                    // Remove postId query param from the URL
                    this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: {} });
                }
            });
    }
}
