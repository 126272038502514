@let progress = loadingProgress();

<div class="relative w-full select-none" #slideBar>
    <div class="flex h-[75px] w-full overflow-hidden rounded-md" (click)="onTimelineClick($event)">
        @if (progress === undefined) {
            @if (this.timelinePreviewUrls()) {
                @for (url of this.timelinePreviewUrls(); track url) {
                    <img class="grow object-cover" draggable="false" [src]="url" />
                }
            }
        } @else {
            <!-- progress bar -->
            <div class="h-[8px] self-center rounded-full bg-malou-color-primary" [style]="'width: ' + (progress * 90 + 10) + '%;'"></div>
        }
    </div>

    <div
        class="absolute top-[-5px] h-[85px] w-[47px] cursor-grab overflow-hidden rounded-md border-[1px] border-solid border-malou-color-primary"
        [ngClass]="{ invisible: progress !== undefined || position() === undefined }"
        (mousedown)="sliderOnMouseDown($event)"
        #slider>
        <img class="h-full object-cover" draggable="false" [src]="sliderThumbnailUrl()" />
    </div>

    @if (progress === undefined) {
        <div
            class="absolute right-2 top-2 flex h-[24px] w-[24px] items-center justify-center rounded-md bg-white leading-none"
            [ngClass]="{ 'cursor-pointer': !isReadonly() }"
            [matTooltip]="'Remplacer la vidéo'"
            (click)="!isReadonly() && onMediaDeleted.emit()">
            <mat-icon class="!h-[16px] !w-[16px] text-malou-color-chart-pink--accent" [svgIcon]="SvgIcon.TRASH"></mat-icon>
        </div>
    }
</div>
