import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpResponseBase } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, combineLatest, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { AggregationTimeScale, MalouMetric, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { InsightsService } from ':modules/statistics/insights.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { getStatisticsError, isDateSetOrGenericPeriod } from ':shared/helpers';
import {
    DatesAndPeriod,
    DiffCombinedSocialPageInsights,
    InsightsByPlatformByRestaurant,
    Restaurant,
    SocialPlatformsInsights,
} from ':shared/models';
import { ValueError } from ':shared/models/value-error';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import * as AggregatedStatisticsSelectors from '../../store/aggregated-statistics.selectors';

enum InsightsTableColumn {
    RESTAURANT_NAME = 'restaurantName',
    FOLLOWERS = 'followers',
    IMPRESSIONS = 'impressions',
    ENGAGEMENTS = 'engagements',
    POSTS = 'posts',
}

const SOCIAL_METRICS = [MalouMetric.FOLLOWERS, MalouMetric.ENGAGEMENTS, MalouMetric.IMPRESSIONS, MalouMetric.POSTS];

@Component({
    selector: 'app-aggregated-posts-insights-table',
    templateUrl: './aggregated-posts-insights-table.component.html',
    styleUrls: ['./aggregated-posts-insights-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SortByFiltersComponent,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        NgClass,
        RouterLink,
        NgTemplateOutlet,
        SkeletonComponent,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        ApplySelfPurePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
})
export class AggregatedPostsInsightsTableComponent implements OnInit {
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Input() hideRestaurantWithErrors = false;
    @Output() tableSortOptionsChange = new EventEmitter<Sort>();
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);
    private readonly _insightsService = inject(InsightsService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);

    readonly SvgIcon = SvgIcon;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    readonly isLoading: WritableSignal<boolean> = signal(true);
    readonly socialNetworksPlatforms: WritableSignal<PlatformKey[]> = signal([]);
    readonly insights: WritableSignal<ValueError<DiffCombinedSocialPageInsights[], string>> = signal({ value: [] });

    dataSource = new MatTableDataSource<DiffCombinedSocialPageInsights>();

    readonly sortOrder: WritableSignal<SortDirection> = signal(ChartSortBy.DESC);
    readonly sortKey: WritableSignal<string> = signal('');

    readonly DISPLAYED_COLUMNS: InsightsTableColumn[] = Object.values(InsightsTableColumn);
    readonly SORT_OPTIONS: FilterOption[] = [
        {
            key: InsightsTableColumn.RESTAURANT_NAME,
            label: this._translateService.instant('aggregated_statistics.social_networks.business'),
        },
        {
            key: InsightsTableColumn.FOLLOWERS,
            label: this._translateService.instant('aggregated_statistics.social_networks.followers'),
        },
        {
            key: InsightsTableColumn.IMPRESSIONS,
            label: this._translateService.instant('aggregated_statistics.social_networks.impressions'),
        },
        {
            key: InsightsTableColumn.ENGAGEMENTS,
            label: this._translateService.instant('aggregated_statistics.social_networks.engagement_rate'),
        },
        {
            key: InsightsTableColumn.POSTS,
            label: this._translateService.instant('aggregated_statistics.social_networks.posts'),
        },
    ];

    constructor() {
        toObservable(this.isLoading).subscribe((isLoading) => this.isLoadingEvent.emit(isLoading));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                const { active, direction } = sort;
                this.tableSortOptionsChange.emit({ active, direction });
                switch (property) {
                    case InsightsTableColumn.FOLLOWERS:
                        return item.current?.followers ?? 0;
                    case InsightsTableColumn.IMPRESSIONS:
                        return item.current?.impressions ?? 0;
                    case InsightsTableColumn.ENGAGEMENTS:
                        return item.current?.engagements ?? 0;
                    case InsightsTableColumn.POSTS:
                        return item.current?.posts ?? 0;
                    default:
                        return item[property];
                }
            };
            this.dataSource.sort = sort;

            if (this.tableSortOptions) {
                this.dataSource.sort?.sort({
                    id: this.tableSortOptions.active,
                    start: this.tableSortOptions.direction,
                    disableClear: false,
                });
            } else {
                this.dataSource.sort?.sort({
                    id: this.dataSource.sort.active || InsightsTableColumn.FOLLOWERS,
                    start: this.sortOrder(),
                    disableClear: false,
                });
            }
        }
    }

    ngOnInit(): void {
        this._getInsights();
    }

    onSortOrderChange(): void {
        this.sortOrder.set(this.dataSource.sort?.direction === ChartSortBy.ASC ? ChartSortBy.DESC : ChartSortBy.ASC);
        this.dataSource.sort?.sort({
            id: this.dataSource.sort.active,
            start: this.sortOrder(),
            disableClear: false,
        });
    }

    onSortByChange(sortBy: string): void {
        this.sortKey.set(sortBy);
        this.dataSource.sort?.sort({ id: sortBy, start: this.sortOrder(), disableClear: false });
    }

    redirectToRestaurantSocialMediaStatsPage(restaurantId: string): void {
        this._router.navigate([`/restaurants/${restaurantId}/statistics/social-networks`]);
    }

    private _getInsights(): void {
        combineLatest([
            this.dates$,
            this.platformKeys$,
            this.selectedRestaurants$,
            this._experimentationService.isFeatureEnabled$('release-aggregated-social-media-performance-improvements'),
        ])
            .pipe(
                filter(
                    ([dates, platforms, restaurants]) => isDateSetOrGenericPeriod(dates) && platforms.length > 0 && restaurants.length > 0
                ),
                filter(([dates]) => {
                    const { startDate, endDate } = dates;
                    return !!startDate && !!endDate;
                }),
                tap(() => {
                    this.isLoading.set(true);
                }),
                switchMap(([dates, socialNetworksPlatforms, restaurants, isReleaseAggregatedSocialMediaPerformanceImprovementsEnabled]) => {
                    const startDate = dates.startDate as Date;
                    const endDate = dates.endDate as Date;
                    const restaurantIds = restaurants.map((r) => r._id);
                    this.socialNetworksPlatforms.set(socialNetworksPlatforms);
                    if (isReleaseAggregatedSocialMediaPerformanceImprovementsEnabled) {
                        return forkJoin([
                            this._insightsService
                                .getInsightsV2({
                                    platformKeys: socialNetworksPlatforms ?? [],
                                    metrics: SOCIAL_METRICS,
                                    aggregators: [AggregationTimeScale.TOTAL],
                                    startDate: startDate.toISOString(),
                                    endDate: endDate.toISOString(),
                                    previousPeriod: false,
                                    restaurantIds,
                                })
                                .pipe(map((res) => res.data)),
                            this._insightsService
                                .getInsightsV2({
                                    platformKeys: socialNetworksPlatforms ?? [],
                                    metrics: SOCIAL_METRICS,
                                    aggregators: [AggregationTimeScale.TOTAL],
                                    startDate: startDate.toISOString(),
                                    endDate: endDate.toISOString(),
                                    previousPeriod: true,
                                    restaurantIds,
                                })
                                .pipe(map((res) => res.data))
                                .pipe(catchError(() => of(null))),
                            of(restaurants),
                        ]);
                    } else {
                        return forkJoin([
                            this._insightsService
                                .getInsights({
                                    platformKeys: socialNetworksPlatforms,
                                    metrics: SOCIAL_METRICS,
                                    aggregators: [AggregationTimeScale.TOTAL],
                                    startDate,
                                    endDate,
                                    previousPeriod: false,
                                    restaurantIds,
                                })
                                .pipe(map((res) => res.data)),
                            this._insightsService
                                .getInsights({
                                    platformKeys: socialNetworksPlatforms,
                                    metrics: SOCIAL_METRICS,
                                    aggregators: [AggregationTimeScale.TOTAL],
                                    startDate,
                                    endDate,
                                    previousPeriod: true,
                                    restaurantIds,
                                })
                                .pipe(map((res) => res.data))
                                .pipe(catchError(() => of(null))),
                            of(restaurants),
                        ]);
                    }
                }),
                catchError((error) => {
                    console.error('error', error);
                    this.isLoading.set(false);
                    return of({
                        error: error instanceof HttpResponseBase ? getStatisticsError(error) : (error.message as string),
                    });
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([currentInsights, previousInsights, restaurants]: [
                    InsightsByPlatformByRestaurant,
                    InsightsByPlatformByRestaurant,
                    Restaurant[],
                ]) => {
                    const mappedCurrent = this._mapDataToSocialPageInsights(currentInsights, restaurants);
                    const mappedPrevious = this._mapDataToSocialPageInsights(previousInsights, restaurants);
                    const diffCombinedSocialPageInsights = mappedCurrent
                        .map((combinedInsights) => {
                            const previousAssociatedRestaurantInsights = mappedPrevious.find(
                                (c) => c.restaurant?._id === combinedInsights.restaurant?._id
                            );
                            return new DiffCombinedSocialPageInsights(
                                combinedInsights,
                                previousAssociatedRestaurantInsights,
                                this._translateService
                            );
                        })
                        .filter((insight) => !(this.hideRestaurantWithErrors && insight.hasErrors()));
                    const insights = { value: diffCombinedSocialPageInsights };

                    if (insights.value) {
                        this.dataSource = new MatTableDataSource<DiffCombinedSocialPageInsights>(insights.value);
                    }
                    if (!insights.value?.length) {
                        this.hasDataChange.emit(false);
                    }
                    this.insights.set(insights);
                    this.isLoading.set(false);
                }
            );
    }

    private _mapDataToSocialPageInsights(insights: InsightsByPlatformByRestaurant, restaurants: Restaurant[]): SocialPlatformsInsights[] {
        const mappedInsights: SocialPlatformsInsights[] = [];
        Object.entries(insights)?.forEach(([restaurantId, insightsForRestaurant]) => {
            const mappedInsightsForRestaurant = {};
            for (const platformKey of this.socialNetworksPlatforms()) {
                const total = insightsForRestaurant?.[platformKey]?.total;
                if (!total) {
                    mappedInsightsForRestaurant[platformKey] = insightsForRestaurant?.[platformKey] || {
                        error: true,
                        message: 'unknown_error',
                    };
                } else {
                    const metricsKeys = Object.keys(total);
                    const mappedInsightsForPlatform = {};
                    metricsKeys.forEach((metric) => {
                        mappedInsightsForPlatform[metric] = total[metric]?.value;
                    });
                    mappedInsightsForRestaurant[platformKey] = mappedInsightsForPlatform;
                }
            }
            const restaurant = restaurants.find((r) => r._id === restaurantId);
            if (restaurant) {
                mappedInsights.push({
                    ...mappedInsightsForRestaurant,
                    restaurant: {
                        _id: restaurantId,
                        name: restaurant.internalName,
                        type: restaurant.type,
                    },
                });
            }
        });
        return mappedInsights;
    }
}
