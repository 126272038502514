import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { differenceBy } from 'lodash';

import { Interaction } from ':shared/models/interaction';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-interactions-browser',
    standalone: true,
    imports: [MatIconModule],
    templateUrl: './interactions-browser.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionsBrowserComponent {
    readonly previousIconId = input.required<string>();
    readonly nextIconId = input.required<string>();
    readonly interactions = input.required<Interaction[]>();
    readonly reset = input.required<boolean>();

    readonly interactionChanged = output<Interaction>();

    readonly currentInteractionIndex = signal<number | undefined>(undefined);
    readonly totalInteractions = computed(() => this.interactions().length);

    readonly SvgIcon = SvgIcon;

    private _previousInteractions: Interaction[] = [];

    constructor() {
        effect(
            () => {
                this.reset(); // Just to trigger the effect
                if (this.totalInteractions() === 1) {
                    this.currentInteractionIndex.set(1);
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                const interactions = this.interactions();
                if (!interactions.length) {
                    this.currentInteractionIndex.set(undefined);
                    this._previousInteractions = [];
                    return;
                }
                const newAiInteractionIndex = this._getNewAiInteractionIndex(interactions, this._previousInteractions);
                this._previousInteractions = interactions;
                if (this.currentInteractionIndex() === undefined) {
                    this.currentInteractionIndex.set(interactions.length - 1);
                } else if (newAiInteractionIndex !== undefined) {
                    this.currentInteractionIndex.set(newAiInteractionIndex);
                }
            },
            { allowSignalWrites: true }
        );
    }

    toPreviousInteraction(): void {
        const index = this.currentInteractionIndex() ?? 0;
        const newCurrentInteractionIndex = Math.max(0, index - 1);
        this.currentInteractionIndex.set(newCurrentInteractionIndex);
        this.interactionChanged.emit(this.interactions()[newCurrentInteractionIndex]);
    }

    toNextInteraction(): void {
        const index = this.currentInteractionIndex() ?? 0;
        const newCurrentInteractionIndex = Math.min(this.totalInteractions() - 1, index + 1);
        this.currentInteractionIndex.set(newCurrentInteractionIndex);
        this.interactionChanged.emit(this.interactions()[newCurrentInteractionIndex]);
    }

    private _getNewAiInteractionIndex(updatedInteractions: Interaction[], interactions: Interaction[]): number | undefined {
        if (updatedInteractions?.length <= 1 || !interactions?.length || updatedInteractions?.length === interactions?.length) {
            return;
        }
        const newInteraction = differenceBy(updatedInteractions, interactions, 'id')[0];
        if (newInteraction.isAiInteraction) {
            return this._getInteractionIndex(updatedInteractions, newInteraction);
        }
    }

    private _getInteractionIndex(interactions: Interaction[], newInteraction: Interaction): number {
        return interactions.findIndex((interaction) => interaction.id === newInteraction.id);
    }
}
