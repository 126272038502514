import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const attachCredentialParamsValidator = z.object({
    platformId: objectIdValidator,
    credentialId: objectIdValidator,
});
export type AttachCredentialParamsDto = z.infer<typeof attachCredentialParamsValidator>;
// ------------------------------------------------------------------------------------------
export const pullOverviewPlatformParamsValidator = z
    .object({
        platform_key: z.nativeEnum(PlatformKey),
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        platformKey: data.platform_key,
        restaurantId: data.restaurant_id,
    }));

export const pullPlatformQueryValidator = z
    .object({
        switch_platform: z.string(),
    })
    .transform((data) => ({
        switchPlatform: data.switch_platform,
    }));

export type PullOverviewPlatformParamsDto = z.infer<typeof pullOverviewPlatformParamsValidator>;
export type PullPlatformQueryDto = z.infer<typeof pullPlatformQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getPlatformParamsValidator = z
    .object({
        platform_key: z.nativeEnum(PlatformKey),
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ platformKey: data.platform_key, restaurantId: data.restaurant_id }));
export type GetPlatformParamsDto = z.infer<typeof getPlatformParamsValidator>;

// ------------------------------------------------------------------------------------------
export const getPlatformsByRestaurantIdsBodyValidator = z.object({
    restaurantIds: z.array(z.string()),
    keys: z.array(z.string()).optional(),
});
export type GetPlatformsByRestaurantIdsBodyDto = z.infer<typeof getPlatformsByRestaurantIdsBodyValidator>;
// ------------------------------------------------------------------------------------------
export const searchPlatformKeyParamsValidator = z.object({
    platform_key: z.nativeEnum(PlatformKey),
});
export type SearchPlatformKeysParamsDto = z.infer<typeof searchPlatformKeyParamsValidator>;

export const searchPlatformKeyQueryValidator = z.object({
    restaurant_id: z.string().nullish(),
    credential_id: z.string().optional(),
    social_id: z.string().optional(),
});
export type SearchPlatformKeysQueryDto = z.infer<typeof searchPlatformKeyQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getPlatformsForRestaurantParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type GetPlatformsForRestaurantParamsDto = z.infer<typeof getPlatformsForRestaurantParamsValidator>;

export const getPlatformsForRestaurantQueryValidator = z
    .object({
        platform_keys: z.array(z.nativeEnum(PlatformKey)).optional(),
    })
    .transform((data) => ({
        platformKeys: data.platform_keys,
    }));
export type GetPlatformsForRestaurantQueryDto = z.infer<typeof getPlatformsForRestaurantQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getPlatformsForRestaurantsBodyValidator = z.object({
    restaurantIds: z.array(z.string()),
});
export type GetPlatformsForRestaurantsBodyDto = z.infer<typeof getPlatformsForRestaurantsBodyValidator>;

// ------------------------------------------------------------------------------------------

export const platformKeyParamsValidator = z
    .object({
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({
        platformKey: data.platform_key,
    }));

export type PlatformKeyParamsDto = z.infer<typeof platformKeyParamsValidator>;

// ------------------------------------------------------------------------------------------

export const scrapPlatformBodyValidator = z.object({
    endpoint: z.string(),
});

export type ScrapPlatformBodyValidator = z.infer<typeof scrapPlatformBodyValidator>;

// ------------------------------------------------------------------------------------------

export const deletePlatformByRestaurantIdAndPlatformKeyParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        platformKey: data.platform_key,
    }));

export type DeletePlatformByRestaurantIdAndPlatformKeyParamsDto = z.infer<typeof deletePlatformByRestaurantIdAndPlatformKeyParamsValidator>;

// ------------------------------------------------------------------------------------------

export const lockOrUnlockFieldParamsValidator = z
    .object({
        platform_id: z.string(),
        field_key: z.string(),
    })
    .transform((data) => ({
        platformId: data.platform_id,
        fieldKey: data.field_key,
    }));

export type LockOrUnlockFieldParamsDto = z.infer<typeof lockOrUnlockFieldParamsValidator>;

// ------------------------------------------------------------------------------------------

export const upsertPlatformBodyValidator = z
    .object({
        restaurant_id: objectIdValidator,
        platform: z
            .object({
                socialId: z.string().optional(),
                key: z.nativeEnum(PlatformKey),
            })
            .passthrough(),
        credentialId: z.string().optional(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        platform: data.platform,
        credentialId: data.credentialId,
    }));

export type UpsertPlatformBodyDto = z.infer<typeof upsertPlatformBodyValidator>;

export const sendMapstrReminderBodyValidator = z.object({
    restaurantId: objectIdValidator,
});
export type SendMapstrReminderBodyDto = z.infer<typeof sendMapstrReminderBodyValidator>;
