import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const getRestaurantUserTagsHistoryParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetRestaurantUserTagsHistoryParamsDto = z.infer<typeof getRestaurantUserTagsHistoryParamsValidator>;
