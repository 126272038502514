import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { HeapEventName, ReportType } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { ReportsService } from ':core/services/report.service';
import { ToastService } from ':core/services/toast.service';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-unsubscribe-report-v2',
    templateUrl: './unsubscribe-report-v2.component.html',
    styleUrls: ['./unsubscribe-report-v2.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ImagePathResolverPipe, TranslateModule, MatButtonModule, IllustrationPathResolverPipe, NgTemplateOutlet],
})
export class UnsubscribeReportV2Component implements OnInit {
    readonly Illustration = Illustration;

    public reportTypeTranslationTitle = signal('');
    public reportTypeTranslationSubtitle = signal('');
    public requestSucceeded = signal(false);

    private _hash: string;
    private _configurationId: string | null = null;
    private _email: string | null = null;
    private _reportType: ReportType | null = null;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _reportService: ReportsService,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _heapService: HeapService
    ) {}

    ngOnInit(): void {
        this._route.queryParams.subscribe((params) => {
            if (params.hash) {
                this._hash = params.hash;

                const { configurationId, email, reportType } = JSON.parse(window.atob(this._hash));

                if (configurationId) {
                    this._configurationId = configurationId;
                }
                if (email) {
                    this._email = email;
                }
                if (reportType) {
                    this._reportType = reportType;
                }
                if (reportType as ReportType) {
                    switch (reportType) {
                        case ReportType.WEEKLY_PERFORMANCE:
                            this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.weekly_performance_title'));
                            this.reportTypeTranslationSubtitle.set(
                                this._translate.instant('reports.unsubscribe.weekly_performance_subtitle', { email: this._email })
                            );
                            break;
                        case ReportType.MONTHLY_PERFORMANCE:
                            this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.monthly_performance_title'));
                            this.reportTypeTranslationSubtitle.set(
                                this._translate.instant('reports.unsubscribe.monthly_performance_subtitle', { email: this._email })
                            );
                            break;
                        case ReportType.WEEKLY_REVIEWS:
                            this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.weekly_reviews_title'));
                            this.reportTypeTranslationSubtitle.set(
                                this._translate.instant('reports.unsubscribe.weekly_reviews_subtitle', { email: this._email })
                            );
                            break;
                        case ReportType.DAILY_REVIEWS:
                            this.reportTypeTranslationTitle.set(this._translate.instant('reports.unsubscribe.daily_reviews_title'));
                            this.reportTypeTranslationSubtitle.set(
                                this._translate.instant('reports.unsubscribe.daily_reviews_subtitle', { email: this._email })
                            );
                            break;
                        default:
                            this.reportTypeTranslationTitle.set('');
                            this.reportTypeTranslationSubtitle.set('');
                            break;
                    }
                }
            }
        });
    }

    public unsubscribeFromConfiguration(): void {
        if (!this._hash) {
            return;
        }

        this._reportService
            .unsubscribeReportV2({
                hash: this._hash,
            })
            .subscribe({
                next: () => {
                    this._heapService.track(HeapEventName.TRACKING_UNSUBSCRIBE_FROM_REPORT, {
                        configurationId: this._configurationId,
                        email: this._email,
                        reportType: this._reportType,
                    });
                    this.requestSucceeded.set(true);
                },
                error: () => {
                    this._toastService.openErrorToast(this._translate.instant('common.unknown_error'));
                },
            });
    }
}
