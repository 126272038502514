import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import {
    AiPostGenerationEmojiStatus,
    AiPostSettingsLength,
    AiPostSettingsTone,
    ApplicationLanguage,
    FrenchTutoiementVouvoiement,
    PostSource,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { AiPostSettingsModalTabComponent } from ':modules/ai-settings/ai-post-settings/edit-ai-post-settings-modal/ai-post-settings-modal-tab/ai-post-settings-modal-tab.component';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { RestaurantAiSettings, RestaurantAiSettingsDispatch } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { AiPostSettingsPreviewComponent } from './ai-post-settings-preview/ai-post-settings-preview.component';

export interface LangOption {
    value: ApplicationLanguage;
    text: string;
}

export enum AiPostSettingsTab {
    SOCIAL = 'social',
    SEO = 'seo',
}

@Component({
    selector: 'app-edit-ai-post-settings-modal',
    templateUrl: './edit-ai-post-settings-modal.component.html',
    styleUrls: ['./edit-ai-post-settings-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        TranslateModule,
        CloseWithoutSavingModalComponent,
        MalouSpinnerComponent,
        FormsModule,
        ReactiveFormsModule,
        AiPostSettingsModalTabComponent,
        AiPostSettingsPreviewComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAIPostSettingsModalComponent implements OnInit {
    readonly data: { uniqueTab?: AiPostSettingsTab } = inject(MAT_DIALOG_DATA);

    readonly PostSource = PostSource;
    readonly AiPostSettingsTab = AiPostSettingsTab;
    readonly SvgIcon = SvgIcon;

    readonly aiSocialPostSettingsForm = new FormGroup({
        denomination: new FormControl<FrenchTutoiementVouvoiement>(FrenchTutoiementVouvoiement.DOES_NOT_MATTER, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        tone: new FormControl<AiPostSettingsTone[]>([AiPostSettingsTone.engaging], {
            nonNullable: true,
            validators: [Validators.required, Validators.minLength(1), Validators.maxLength(3)],
        }),
        length: new FormControl<AiPostSettingsLength>(AiPostSettingsLength.medium, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        emoji: new FormControl<AiPostGenerationEmojiStatus>(AiPostGenerationEmojiStatus.some, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        prompt: new FormControl<string>(''),
    });
    readonly aiSeoPostSettingsForm = new FormGroup({
        denomination: new FormControl<FrenchTutoiementVouvoiement>(FrenchTutoiementVouvoiement.DOES_NOT_MATTER, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        tone: new FormControl<AiPostSettingsTone[]>([AiPostSettingsTone.engaging], {
            nonNullable: true,
            validators: [Validators.required, Validators.minLength(1), Validators.maxLength(3)],
        }),
        length: new FormControl<AiPostSettingsLength>(AiPostSettingsLength.medium, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        emoji: new FormControl<AiPostGenerationEmojiStatus>(AiPostGenerationEmojiStatus.some, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        prompt: new FormControl<string>(''),
    });

    readonly selectedIndex = signal(0);

    readonly aiSettingsContext = inject(AiSettingsContext);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _dialogRef = inject(MatDialogRef<EditAIPostSettingsModalComponent>);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly isSocialPostFormValid = toSignal(
        this.aiSocialPostSettingsForm.valueChanges.pipe(map(() => this.aiSocialPostSettingsForm.valid)),
        { initialValue: true }
    );
    readonly isSeoPostFormValid = toSignal(this.aiSeoPostSettingsForm.valueChanges.pipe(map(() => this.aiSeoPostSettingsForm.valid)), {
        initialValue: true,
    });

    readonly areFormsValid = computed(() => {
        if (this.data.uniqueTab === AiPostSettingsTab.SOCIAL) {
            return this.isSocialPostFormValid();
        }
        if (this.data.uniqueTab === AiPostSettingsTab.SEO) {
            return this.isSeoPostFormValid();
        }
        return this.isSocialPostFormValid() && this.isSeoPostFormValid();
    });

    readonly displayCloseModal = signal(false);
    readonly isSubmitting = signal(false);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    ngOnInit(): void {
        if (this.data.uniqueTab === AiPostSettingsTab.SEO) {
            this.selectedIndex.set(1);
        }
        const settings = this.aiSettingsContext.restaurantAiSettings();
        if (settings) {
            this.aiSocialPostSettingsForm.patchValue({
                denomination: settings.postSettings?.social?.denomination ?? FrenchTutoiementVouvoiement.DOES_NOT_MATTER,
                tone: settings.postSettings?.social?.tone ?? [AiPostSettingsTone.engaging],
                length: settings.postSettings?.social?.length ?? AiPostSettingsLength.very_short,
                emoji: settings.postSettings?.social?.emoji ?? AiPostGenerationEmojiStatus.some,
                prompt: settings.postSettings?.social?.prompt ?? '',
            });
            this.aiSeoPostSettingsForm.patchValue({
                denomination: settings.postSettings?.seo?.denomination ?? FrenchTutoiementVouvoiement.DOES_NOT_MATTER,
                tone: settings.postSettings?.seo?.tone ?? [AiPostSettingsTone.engaging],
                length: settings.postSettings?.seo?.length ?? AiPostSettingsLength.short,
                emoji: settings.postSettings?.seo?.emoji ?? AiPostGenerationEmojiStatus.some,
                prompt: settings.postSettings?.seo?.prompt ?? '',
            });
        }
    }

    async submit(): Promise<void> {
        try {
            this.isSubmitting.set(true);
            const aiSocialPostSettings = this.aiSocialPostSettingsForm.value;
            const aiSeoPostSettingsForm = this.aiSeoPostSettingsForm.value;

            const settings = this.aiSettingsContext.restaurantAiSettings()!;

            const isSocialTabOnly = this.data.uniqueTab === AiPostSettingsTab.SOCIAL;
            const isSeoTabOnly = this.data.uniqueTab === AiPostSettingsTab.SEO;

            const restaurantAiSettings = new RestaurantAiSettings({
                ...settings,
                postSettings: {
                    social: isSeoTabOnly
                        ? settings.postSettings?.social
                        : {
                              denomination: aiSocialPostSettings.denomination!,
                              tone: aiSocialPostSettings.tone!,
                              length: aiSocialPostSettings.length!,
                              emoji: aiSocialPostSettings.emoji!,
                              prompt: aiSocialPostSettings.prompt!,
                          },
                    seo: isSocialTabOnly
                        ? settings.postSettings?.seo
                        : {
                              denomination: aiSeoPostSettingsForm.denomination!,
                              tone: aiSeoPostSettingsForm.tone!,
                              length: aiSeoPostSettingsForm.length!,
                              emoji: aiSeoPostSettingsForm.emoji!,
                              prompt: aiSeoPostSettingsForm.prompt!,
                          },
                },
            });

            await this.aiSettingsContext.updateAiSettings({ restaurantAiSettings, dispatcher: RestaurantAiSettingsDispatch.post });
            this._toastService.openSuccessToast(this._translateService.instant('restaurant_ai_settings.modals.upsert.success'));
            this._dialogRef.close();
        } catch (e) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
        }
        this.isSubmitting.set(false);
    }

    onTabChanged(event: MatTabChangeEvent): void {
        this.selectedIndex.set(event.index);
    }

    close(): void {
        if (this.isSubmitting()) {
            return;
        }
        this.confirmClose();
    }

    confirmClose(): void {
        this._dialogRef.close();
    }

    displayOption(option: { text: string }): string {
        return option.text;
    }

    goToAiSettings(): void {
        const url = `${window.location.origin}/restaurants/${this._restaurantsService.restaurantSelected$.value?._id}/settings/ai`;
        window.open(url, '_blank');
        this._dialogRef.close();
    }
}
