import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { isNumber } from 'lodash';

import { KeywordConditionCriteria } from '@malou-io/package-utils';

import { Indication } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-keywords-score-tips',
    standalone: true,
    imports: [CommonModule, TranslateModule, NgTemplateOutlet, MatIconModule],
    templateUrl: './keywords-score-tips.component.html',
    styleUrls: ['./keywords-score-tips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsScoreTipsComponent {
    readonly indicationsList = input<Indication[]>([]);
    readonly flexDirection = input<'flex-row' | 'flex-col'>('flex-row');

    readonly flexWrapClass = computed(() => (this.flexDirection() === 'flex-row' ? 'flex-wrap' : ''));

    readonly SvgIcon = SvgIcon;
    readonly isNumber = isNumber;
    readonly KeywordConditionCriteria = KeywordConditionCriteria;
}
