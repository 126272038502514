import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';

import {
    AggregationTimeScale,
    getPositiveAndNegativeStatsForSemanticAnalysis,
    getReviewsForSemanticAnalysisStats,
    MalouComparisonPeriod,
    MalouMetric,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

import { StarValue } from ':core/constants';
import { ReviewsByAnswerStatus } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import { InsightsByPlatform, Restaurant } from ':shared/models';
import { SummarySectionsData } from ':shared/services/csv-services/insights/summary/summary.interface';

interface PlatformWithRatingValue {
    platformKey: string;
    value: number;
}

const DEFAULT_REVIEWS_RATING = {
    [StarValue.UNKNOWN]: null,
    [StarValue.ONE]: null,
    [StarValue.TWO]: null,
    [StarValue.THREE]: null,
    [StarValue.FOUR]: null,
    [StarValue.FIVE]: null,
};

@Injectable({ providedIn: 'root' })
export class SummaryCsvInsightsEReputationSectionService {
    constructor(
        private readonly _insightsService: InsightsService,
        private readonly _reviewsService: ReviewsService
    ) {}

    execute(
        filters: StatisticsState['filters'],
        restaurant: Restaurant,
        platformKeys: PlatformKey[]
    ): Observable<{ current: SummarySectionsData['eReputation'] | null; previous: SummarySectionsData['eReputation'] | null }> {
        const platformKeysWithReview: string[] = PlatformDefinitions.getPlatformKeysWithReview();
        const platformKeysWithRating: string[] = PlatformDefinitions.getPlatformKeysWithRating();
        const platformKeysToUse = platformKeys.filter((platform: PlatformKey) => platformKeysWithReview.includes(platform));
        const connectedPlatformsWithRating: PlatformKey[] = platformKeysToUse.filter((platform: string) =>
            platformKeysWithRating.includes(platform)
        );
        const connectedPlatformsWithReview: PlatformKey[] = platformKeysToUse.filter((platform: string) =>
            platformKeysWithReview.includes(platform)
        );
        const restaurantId = restaurant._id;
        const startDate = filters.dates.startDate;
        const endDate = filters.dates.endDate;
        return forkJoin([
            // Platforms rating
            this._insightsService
                .getInsights({
                    restaurantIds: [restaurant._id],
                    startDate: filters.dates.startDate,
                    endDate: filters.dates.endDate,
                    platformKeys: connectedPlatformsWithRating,
                    metrics: [MalouMetric.PLATFORM_RATING],
                    aggregators: [AggregationTimeScale.BY_DAY],
                })
                .pipe(
                    map((res) => res?.data[restaurant._id]),
                    map((res) => this._getLatestRatingForEachPlatformFromPeriod(res)),
                    catchError(() => of([]))
                ),
            this._insightsService
                .getInsights({
                    restaurantIds: [restaurant._id],
                    startDate: filters.dates.startDate,
                    endDate: filters.dates.endDate,
                    platformKeys: connectedPlatformsWithRating,
                    metrics: [MalouMetric.PLATFORM_RATING],
                    aggregators: [AggregationTimeScale.BY_DAY],
                    previousPeriod: true,
                    comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                })
                .pipe(
                    map((res) => res?.data[restaurant._id]),
                    map((res) => this._getLatestRatingForEachPlatformFromPeriod(res)),
                    catchError(() => of([]))
                ),
            // Review count
            this._reviewsService
                .getChartRestaurantsReviewsTotal({ restaurantId, platforms: connectedPlatformsWithReview, startDate, endDate })
                .pipe(catchError(() => of(null))),
            this._reviewsService
                .getChartRestaurantsReviewsTotal({
                    restaurantId,
                    platforms: connectedPlatformsWithReview,
                    startDate,
                    endDate,
                    comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                })
                .pipe(catchError(() => of(null))),
            // Review answer rate
            this._reviewsService
                .getChartRestaurantsReviewsReplied({ restaurantId, platforms: connectedPlatformsWithReview, startDate, endDate })
                .pipe(catchError(() => of(null))),
            this._reviewsService
                .getChartRestaurantsReviewsReplied({
                    restaurantId,
                    platforms: connectedPlatformsWithReview,
                    startDate,
                    endDate,
                    comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                })
                .pipe(catchError(() => of(null))),
            // Review answer time
            this._reviewsService
                .getChartAverageAnswerTime({ restaurantId, platforms: connectedPlatformsWithReview, startDate, endDate })
                .pipe(catchError(() => of(null))),
            this._reviewsService
                .getChartAverageAnswerTime({
                    restaurantId,
                    platforms: connectedPlatformsWithReview,
                    startDate,
                    endDate,
                    comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                })
                .pipe(catchError(() => of(null))),
            // Review average rating
            this._reviewsService
                .getRestaurantsReviewsAverageChartData({
                    restaurantId,
                    platforms: connectedPlatformsWithReview,
                    startDate,
                    endDate,
                })
                .pipe(catchError(() => of(null))),
            this._reviewsService
                .getRestaurantsReviewsAverageChartData({
                    restaurantId,
                    platforms: connectedPlatformsWithReview,
                    startDate,
                    endDate,
                    comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                })
                .pipe(catchError(() => of(null))),
            // Reviews per rating
            this._reviewsService
                .getChartRestaurantsReviewsRatings({ restaurantId, platforms: connectedPlatformsWithReview, startDate, endDate })
                .pipe(catchError(() => of(null))),
            this._reviewsService
                .getChartRestaurantsReviewsRatings({
                    restaurantId,
                    platforms: connectedPlatformsWithReview,
                    startDate,
                    endDate,
                    comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                })
                .pipe(catchError(() => of(null))),
            // Reviews Analysis
            this._reviewsService.getReviewsWithAnalysis(startDate, endDate, connectedPlatformsWithReview, restaurantId),
        ]).pipe(
            map(
                ([
                    currentPlatformRating,
                    previousPlatformRatings,
                    currentReviewCount,
                    previousReviewCount,
                    currentReviewsAnswerRate,
                    previousReviewsAnswerRate,
                    currentReviewAnswerTime,
                    previousReviewAnswerTime,
                    currentReviewAverageRating,
                    previousReviewAverageRating,
                    currentReviewsPerRating,
                    previousReviewsPerRating,
                    reviewsAnalysis,
                ]) => {
                    const currentNotes = currentPlatformRating
                        .map((platform) => ({
                            platform: platform.platformKey,
                            note: platform.value,
                        }))
                        .filter((note) => !!note.note);
                    const previousNotes = previousPlatformRatings
                        .map((platform) => ({
                            platform: platform.platformKey,
                            note: platform.value,
                        }))
                        .filter((note) => !!note.note);

                    const currentReviews = {
                        totalCount: currentReviewCount?.results?.[0]?.total ?? null,
                        averageRating: currentReviewAverageRating?.results?.[0]?.averageRating ?? null,
                    };
                    const previousReviews = {
                        totalCount: previousReviewCount?.results?.[0]?.total ?? null,
                        averageRating: previousReviewAverageRating?.results?.[0]?.averageRating ?? null,
                    };

                    const currentReviewsRating =
                        currentReviewsPerRating?.results?.reduce((acc, result) => {
                            acc[result.rating] = (result.total * 100) / currentReviewsPerRating?.results.length;
                            return acc;
                        }, DEFAULT_REVIEWS_RATING) ?? DEFAULT_REVIEWS_RATING;

                    const previousReviewsRating =
                        previousReviewsPerRating?.results?.reduce((acc, result) => {
                            acc[result.rating] = (result.total * 100) / previousReviewsPerRating?.results.length;
                            return acc;
                        }, DEFAULT_REVIEWS_RATING) ?? DEFAULT_REVIEWS_RATING;

                    const currentReviewsResponse = {
                        rate: this._getAnswerRate(currentReviewsAnswerRate),
                        averageTime: currentReviewAnswerTime,
                    };

                    const previousReviewsResponse = {
                        rate: this._getAnswerRate(previousReviewsAnswerRate),
                        averageTime: previousReviewAnswerTime,
                    };

                    const currentSentimentsPercentage = getPositiveAndNegativeStatsForSemanticAnalysis(
                        getReviewsForSemanticAnalysisStats(reviewsAnalysis)
                    );
                    const previousSentimentsPercentage = getPositiveAndNegativeStatsForSemanticAnalysis(
                        getReviewsForSemanticAnalysisStats(reviewsAnalysis)
                    );

                    return {
                        current: {
                            notes: currentNotes,
                            reviews: currentReviews,
                            reviewsRatings: currentReviewsRating,
                            reviewsResponse: currentReviewsResponse,
                            sentimentsPercentage: {
                                positive: currentSentimentsPercentage.positiveSentimentsPercentage,
                                negative: currentSentimentsPercentage.negativeSentimentsPercentage,
                            },
                        },
                        previous: {
                            notes: previousNotes,
                            reviews: previousReviews,
                            reviewsRatings: previousReviewsRating,
                            reviewsResponse: previousReviewsResponse,
                            sentimentsPercentage: {
                                positive: previousSentimentsPercentage.positiveSentimentsPercentage,
                                negative: previousSentimentsPercentage.negativeSentimentsPercentage,
                            },
                        },
                    };
                }
            )
        );
    }

    private _getLatestRatingForEachPlatformFromPeriod(platformsInsights?: InsightsByPlatform): PlatformWithRatingValue[] {
        if (!platformsInsights) {
            return [];
        }
        return Object.keys(platformsInsights)?.map((platformKey) => {
            const platformInsightsByDayRatings =
                platformsInsights?.[platformKey]?.[AggregationTimeScale.BY_DAY]?.[MalouMetric.PLATFORM_RATING];
            if (!platformInsightsByDayRatings) {
                return { platformKey, value: null };
            }
            const platformInsightsByDayRatingsCleaned = platformInsightsByDayRatings
                .sort((a, b) => new Date(b.date)?.getTime() - new Date(a.date)?.getTime())
                .filter((rating) => !!rating.value);
            if (!platformInsightsByDayRatingsCleaned[0]?.value) {
                return { platformKey, value: null };
            }
            return {
                platformKey,
                value: platformInsightsByDayRatingsCleaned[0].value,
            };
        });
    }

    private _getAnswerRate(reviewAnswerRate: ReviewsByAnswerStatus | null): number | null {
        if (!reviewAnswerRate) {
            return null;
        }
        const totalReviews = reviewAnswerRate.results.answered.length + reviewAnswerRate.results.notAnswered.length;
        if (totalReviews === 0) {
            return null;
        }
        const totalAnswered = reviewAnswerRate.results.answered.length;
        return (totalAnswered * 100) / totalReviews;
    }
}
