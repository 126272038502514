<div class="flex gap-2 {{ flexDirection() }} {{ flexWrapClass() }}">
    @for (indication of indicationsList(); track $index) {
        <ng-container [ngTemplateOutlet]="indicationTemplate" [ngTemplateOutletContext]="{ indication }"></ng-container>
    }
</div>

<ng-template let-indication="indication" #indicationTemplate>
    <div class="flex">
        <!-- ICON -->
        @if (indication.fulfilled) {
            <mat-icon class="malou-color-state-success" [svgIcon]="SvgIcon.CHECK"></mat-icon>
        } @else {
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        }

        <!-- TEXT -->
        <div class="malou-text-9--medium !text-malou-color-text-2">
            {{ 'keywords_gauge.' + indication.textType + '.maintext' | translate }}
            @if (indication.shouldDisplayCurrentValue) {
                <span>
                    <span> : {{ indication.currentValue }}</span>
                    @if (indication.optimalValue) {
                        /{{ indication.optimalValue }}
                    }
                </span>
            }
            @if (indication.shouldDisplaySubText || isNumber(indication.minimumValue)) {
                <div class="malou-text-8 italic">
                    @if (indication.shouldDisplaySubText) {
                        {{ 'keywords_gauge.' + indication.textType + '.subtext' | translate }}
                    }
                    @if (indication.minimumValue && indication.maximumValue) {
                        ({{ indication.minimumValue }} {{ 'common.minimum' | translate }}, {{ indication.maximumValue }}
                        {{ 'common.maximum' | translate }})
                    }
                    @if (
                        isNumber(indication.minimumValue) &&
                        !indication.maximumValue &&
                        indication.textType !== KeywordConditionCriteria.BRICKS_NUMBER
                    ) {
                        ({{ indication.minimumValue + ' ' + ('common.minimum' | translate) }})
                    }
                    @if (indication.maximumValue && !indication.minimumValue) {
                        ({{ indication.maximumValue + ' ' + ('common.maximum' | translate) }})
                    }
                </div>
            }
        </div>
    </div>
</ng-template>
