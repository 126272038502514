import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, LegendItem, Plugin, TimeUnit, TooltipItem } from 'chart.js';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey } from '@malou-io/package-utils';

import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    malouChartColorABC,
    malouChartColorABC30Percent,
    malouChartColorDeliveroo,
    malouChartColorDeliveroo30Percent,
    malouChartColorFacebook,
    malouChartColorFacebook30Percent,
    malouChartColorFoursquare,
    malouChartColorFoursquare30Percent,
    malouChartColorGMB,
    malouChartColorGMB30Percent,
    malouChartColorInstagram,
    malouChartColorInstagram30Percent,
    malouChartColorLaFourchette,
    malouChartColorLaFourchette30Percent,
    malouChartColorLighterBlue,
    malouChartColorLighterBlue30Percent,
    malouChartColorLighterPink,
    malouChartColorLighterPink30Percent,
    malouChartColorMapstr,
    malouChartColorMapstr30Percent,
    malouChartColorOpentable,
    malouChartColorOpentable30Percent,
    malouChartColorResy,
    malouChartColorResy30Percent,
    malouChartColorTikTok,
    malouChartColorTikTok30Percent,
    malouChartColorTripadvisor,
    malouChartColorTripadvisor30Percent,
    malouChartColorUberEats,
    malouChartColorUberEats30Percent,
    malouChartColorYelp,
    malouChartColorYelp30Percent,
    malouChartColorZenchef,
    malouChartColorZenchef30Percent,
} from ':shared/helpers';
import { comparisonLegendPlugin, ComparisonLegendPluginMetadata } from ':shared/helpers/chart-comparison-legend-plugin';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

const colorByPlatformKey: Record<PlatformKey, { current: string; previous: string }> = {
    [PlatformKey.ABC]: { current: malouChartColorABC, previous: malouChartColorABC30Percent },
    [PlatformKey.DELIVEROO]: { current: malouChartColorDeliveroo, previous: malouChartColorDeliveroo30Percent },
    [PlatformKey.FACEBOOK]: { current: malouChartColorFacebook, previous: malouChartColorFacebook30Percent },
    [PlatformKey.FOURSQUARE]: { current: malouChartColorFoursquare, previous: malouChartColorFoursquare30Percent },
    [PlatformKey.GMB]: { current: malouChartColorGMB, previous: malouChartColorGMB30Percent },
    [PlatformKey.INSTAGRAM]: { current: malouChartColorInstagram, previous: malouChartColorInstagram30Percent },
    [PlatformKey.LAFOURCHETTE]: { current: malouChartColorLaFourchette, previous: malouChartColorLaFourchette30Percent },
    [PlatformKey.MAPSTR]: { current: malouChartColorMapstr, previous: malouChartColorMapstr30Percent },
    [PlatformKey.OPENTABLE]: { current: malouChartColorOpentable, previous: malouChartColorOpentable30Percent },
    [PlatformKey.PRIVATE]: { current: malouChartColorLighterPink, previous: malouChartColorLighterPink30Percent },
    [PlatformKey.RESY]: { current: malouChartColorResy, previous: malouChartColorResy30Percent },
    [PlatformKey.TIKTOK]: { current: malouChartColorTikTok, previous: malouChartColorTikTok30Percent },
    [PlatformKey.TRIPADVISOR]: { current: malouChartColorTripadvisor, previous: malouChartColorTripadvisor30Percent },
    [PlatformKey.UBEREATS]: { current: malouChartColorUberEats, previous: malouChartColorUberEats30Percent },
    [PlatformKey.WEBSITE]: { current: malouChartColorLighterBlue, previous: malouChartColorLighterBlue30Percent },
    [PlatformKey.YELP]: { current: malouChartColorYelp, previous: malouChartColorYelp30Percent },
    [PlatformKey.ZENCHEF]: { current: malouChartColorZenchef, previous: malouChartColorZenchef30Percent },
};

export type ReviewsRatingsCount = Partial<Record<PlatformKey, ChartDataArray>>;

type ChartData = ChartDataset<BarChartType, ChartDataArray> & {
    metadata?: {
        isPrevious: boolean;
        comparisonLegendPluginMetadata: ComparisonLegendPluginMetadata;
    };
};

@Component({
    selector: 'app-reviews-ratings-evolution-chart',
    templateUrl: './reviews-ratings-evolution-chart.component.html',
    styleUrls: ['./reviews-ratings-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    providers: [ShortNumberPipe, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsRatingsEvolutionChartComponent {
    readonly currentData = input.required<ReviewsRatingsCount>();
    readonly previousData = input.required<ReviewsRatingsCount>();
    readonly labels = input.required<Date[]>();
    readonly previousLabels = input.required<Date[]>();
    readonly viewBy = input.required<ViewBy>();
    readonly hiddenDatasetIndexes = input.required<number[]>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    private readonly _shortNumberPipe = inject(ShortNumberPipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslate = inject(EnumTranslatePipe);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly legendContainerId = 'reviews-ratings-evolution-legend-container';

    readonly chartDataSets = computed((): ChartDataset<BarChartType, ChartDataArray>[] => {
        const chartDataSets = this._computeChartData(this.currentData(), this.previousData());
        return this.hiddenDatasetIndexes()?.length
            ? chartDataSets.filter((_, index) => !this.hiddenDatasetIndexes().includes(index))
            : chartDataSets;
    });
    readonly chartLabels = computed((): Date[] => this._computeChartLabels(this.labels()));
    readonly chartOption = computed((): ChartOptions<BarChartType> => this._computeChartOptions(this.viewBy()));
    private _hiddenDatasetIndexes: number[] = [];

    private _onLegendItemClick = (chart: Chart, legendItem: LegendItem): void => {
        const index = legendItem.datasetIndex;
        if (!isNumber(index)) {
            return;
        }

        if (chart.isDatasetVisible(index)) {
            this._hiddenDatasetIndexes.push(index);
            chart.hide(index);
            legendItem.hidden = true;
        } else {
            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
            chart.show(index);
            legendItem.hidden = false;
        }
        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
    };

    readonly LEGEND_PLUGIN: Signal<Plugin> = computed(() =>
        comparisonLegendPlugin({
            id: 'reviewRatingsEvolutionLegendPlugin',
            elementId: this.legendContainerId,
            onLegendItemClick: this._onLegendItemClick,
            shouldOnlyShowPrimaryColor: this.viewBy() === ViewBy.DAY,
        })
    );

    private _computeChartData(data: ReviewsRatingsCount, previousData: ReviewsRatingsCount): ChartData[] {
        return Object.keys(data)
            .sort()
            .map((platform: PlatformKey) => [
                {
                    label: this._enumTranslate.transform(platform, 'platform_key'),
                    borderColor: this._getChartColor(platform, 'previous'),
                    backgroundColor: this._getChartColor(platform, 'previous'),
                    xAxisID: 'xAxis',
                    yAxisID: 'yAxis',
                    data: previousData[platform] as ChartDataArray,
                    metadata: {
                        isPrevious: true,
                        comparisonLegendPluginMetadata: {
                            display: false,
                        },
                    },
                },
                {
                    label: this._enumTranslate.transform(platform, 'platform_key'),
                    borderColor: this._getChartColor(platform, 'current'),
                    backgroundColor: this._getChartColor(platform, 'current'),
                    xAxisID: 'xAxis',
                    yAxisID: 'yAxis',
                    data: data[platform] as ChartDataArray,
                    metadata: {
                        isPrevious: false,
                        comparisonLegendPluginMetadata: {
                            display: true,
                            primaryColor: this._getChartColor(platform, 'current'),
                            secondaryColor: this._getChartColor(platform, 'previous'),
                        },
                    },
                },
            ])
            .flat();
    }

    private _getChartColor(platformKey: PlatformKey, key: 'current' | 'previous'): string {
        return colorByPlatformKey[platformKey][key];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(viewBy: ViewBy): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems, viewBy),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string) => this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[], viewBy: ViewBy): string {
        const dataSet = item[0].dataset as ChartData;
        const title: string = dataSet.metadata?.isPrevious ? this._formatDate(this.previousLabels()[item[0].dataIndex]) : item[0].label;
        if (viewBy === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.e_reputation.reviews_ratings.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }

    private _formatDate(title: Date): string {
        const format = this._computeToolTipFormatFromViewBy(this.viewBy());
        return DateTime.fromJSDate(title).toFormat(format);
    }
}
