<div
    class="relative flex w-full items-stretch rounded-lg bg-white"
    [ngClass]="{
        'malou-border-primary': !errorMessage() && !isFocused() && !(control && control.invalid && (control.dirty || control.touched)),
        'malou-border-secondary': !errorMessage() && isFocused(),
        'malou-border-error': !!errorMessage() || (control && control.invalid && (control.dirty || control.touched)),
        '!h-6 !rounded': theme() === SearchBarDisplayStyle.SMALL,
    }">
    <div class="grow px-5" [ngClass]="{ '!pl-3': theme() === SearchBarDisplayStyle.SMALL }">
        <input
            class="malou-text-12--medium box-border h-12 w-full rounded-lg border-0 bg-white outline-none"
            tabindex="-1"
            [ngClass]="{
                '!malou-text-10--medium flex !h-[22px] !rounded italic text-malou-color-text-2': theme() === SearchBarDisplayStyle.SMALL,
            }"
            [attr.data-testid]="testId()"
            [class.italic]="isEmptyValue()"
            [class.malou-text-12]="isEmptyValue()"
            [placeholder]="placeholder()"
            [formControl]="control"
            (focus)="isFocused.set(true)"
            (blur)="isFocused.set(false)"
            #inputElement />
    </div>

    <div
        class="flex items-center pr-5"
        [ngClass]="{
            '!pr-3': theme() === SearchBarDisplayStyle.SMALL,
        }"
        (click)="inputElement.focus()">
        @if (isLoading()) {
            <mat-spinner class="stroke-malou-color-primary" diameter="14"></mat-spinner>
        } @else {
            @if (isEmptyValue()) {
                <mat-icon class="malou-color-primary !h-3.5 !w-3.5" [svgIcon]="SvgIcon.SEARCH"></mat-icon>
            } @else {
                <mat-icon
                    class="malou-color-primary !h-3.5 !w-3.5 cursor-pointer"
                    [svgIcon]="SvgIcon.CROSS"
                    (click)="disabled() ? null : resetValue()"></mat-icon>
            }
        }
    </div>

    <div class="absolute bottom-0 left-0" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"></div>

    <mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #menu="matMenu">
        <div class="!flex items-center gap-x-4" mat-menu-item (click)="buildValue()">
            <div class="malou-text-12--medium to-malou-color-text-1">{{ control.value }}</div>
            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
        </div>
    </mat-menu>
</div>

@if (errorMessage()) {
    <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage() }}</div>
}
@if (!errorMessage() && control && control.invalid && (control.dirty || control.touched)) {
    <div class="malou-text-10 malou-color-state-error py-1 italic">
        {{ 'common.invalid_field' | translate }}
    </div>
}
