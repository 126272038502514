import { ChangeDetectionStrategy, Component, effect, inject, input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { round, sum } from 'lodash';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { RoiContext } from ':modules/roi/roi.context';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedEstimatedCustomersData } from '../../roi.interface';

enum TableField {
    RESTAURANT_NAME = 'restaurantName',
    ADDITIONAL_CLIENTS = 'estimatedCustomers',
    EARNINGS = 'earnings',
}

const DEFAULT_SORT: { active: TableField; direction: SortDirection } = {
    active: TableField.ADDITIONAL_CLIENTS,
    direction: ChartSortBy.DESC,
};

interface DataSource {
    restaurant: Restaurant;
    restaurantLogo: string;
    isMissingData: boolean;
    showData: boolean;
    [TableField.RESTAURANT_NAME]: string;
    [TableField.ADDITIONAL_CLIENTS]: number;
    [TableField.EARNINGS]: number;
}

@Component({
    selector: 'app-aggregated-monthly-estimated-customers-table',
    standalone: true,
    imports: [
        MatTableModule,
        MatSortModule,
        TranslateModule,
        ShortNumberPipe,
        ImagePathResolverPipe,
        MatTooltipModule,
        MatIconModule,
        MatTooltipModule,
    ],
    templateUrl: './aggregated-monthly-estimated-customers-table.component.html',
    styleUrl: './aggregated-monthly-estimated-customers-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedMonthlyEstimatedCustomersTableComponent {
    estimatedCustomersData = input.required<AggregatedEstimatedCustomersData>();

    private readonly _router = inject(Router);
    readonly roiContext = inject(RoiContext);
    readonly screenSizeService = inject(ScreenSizeService);

    readonly SvgIcon = SvgIcon;
    readonly TableField = TableField;
    readonly DEFAULT_SORT = DEFAULT_SORT;
    readonly displayedColumns = Object.values(TableField);

    readonly dataSource: MatTableDataSource<DataSource> = new MatTableDataSource();

    constructor() {
        effect(() => {
            this.dataSource.data = this._computeDataSource(this.estimatedCustomersData());
        });
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
        }
    }

    redirectToRestaurantRoiStatsPage(restaurant: Restaurant): void {
        this._router.navigate([`/restaurants/${restaurant.id}/statistics/roi`]);
    }

    private _computeDataSource(data: AggregatedEstimatedCustomersData): DataSource[] {
        if (!data) {
            return [];
        }

        return data.map((d) => {
            const associatedRoiSettings = this.roiContext
                .restaurantsRoiSettings()
                .find((roiSettings) => roiSettings.restaurant?._id === d.restaurant.id);
            return {
                restaurant: d.restaurant,
                restaurantLogo: d.restaurant?.logo?.urls?.small ?? d.restaurant?.logo?.urls?.original ?? '',
                isMissingData: d.isMissingData ?? false,
                showData: sum(d.estimatedCustomersByMonth) > 0,
                [TableField.RESTAURANT_NAME]: d.restaurant?.internalName ?? d.restaurant?.name ?? '',
                [TableField.ADDITIONAL_CLIENTS]: round(sum(d.estimatedCustomersByMonth), 0),
                [TableField.EARNINGS]: sum(d.estimatedCustomersByMonth) * (associatedRoiSettings?.averageTicket ?? 0),
            };
        });
    }
}
