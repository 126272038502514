@if (shouldDetailTagsEvolutionCharts()) {
    @if (hasData()) {
        @for (tag of filteredTags(); track tag) {
            <div class="malou-simple-card my-3 flex break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="malou-text-section-title malou-color-text-1">
                        {{ 'aggregated_statistics.e_reputation.reviews_analysis.breakdown_of_categories_by_business' | translate }} :
                        {{ getChartTitleByTag | applyPure: tag }}
                    </span>
                    <div class="flex items-center gap-2">
                        <span class="malou-text-13--regular italic">
                            {{ 'common.sort_by' | translate }}
                            {{ sortByDisplayWith | applyPure: sortByControl.value | lowercase }}
                        </span>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
            </div>
        }
    } @else {
        <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
    }
} @else {
    <div class="malou-simple-card flex break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
        <div>
            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
        </div>
        @if (hasData()) {
            <div>
                <ng-container [ngTemplateOutlet]="navigationTemplate"></ng-container>
            </div>
            <div>
                <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
            </div>
        } @else {
            <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
        }
    </div>
}

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <div class="flex items-center gap-2">
            <div class="malou-text-section-title malou-color-text-1">
                {{ 'aggregated_statistics.e_reputation.reviews_analysis.breakdown_of_categories_by_business' | translate }}
            </div>
            @if (warningTooltip()) {
                <div class="malou-status--waiting pdf-hidden" [matTooltip]="warningTooltip() ?? ''">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
        </div>

        <div class="flex items-center gap-2">
            <span class="malou-text-14--medium md:hidden">{{ 'common.sort_by' | translate }}</span>
            <app-select
                [values]="CHART_SORT_BY_VALUES"
                [displayWith]="sortByDisplayWith"
                [formControl]="sortByControl"
                (selectChange)="onSortByChange($event)"></app-select>
        </div>
    </div>
</ng-template>

<ng-template #navigationTemplate>
    <app-tab-nav-bar [tabs]="tabs()" (tabChange)="onTabChange($event)"></app-tab-nav-bar>
</ng-template>

<ng-template #chartTemplate>
    <app-tags-evolution-chart
        [chartData]="chartData()"
        [currentTag]="currentTag()"
        [restaurants]="restaurants()"
        [sortBy]="sortByControl.value"
        [isFromAggregatedStatistics]="true"
        [reviewAnalysesFilter]="reviewAnalysesFilter()">
    </app-tags-evolution-chart>
</ng-template>

<ng-template #detailedChartsTemplate>
    @if (hasData()) {
        @for (tag of filteredTags(); track tag) {
            <div class="malou-simple-card my-3 flex break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <span class="malou-text-section-title malou-color-text-1">
                        {{ 'aggregated_statistics.e_reputation.reviews_analysis.breakdown_of_categories_by_business' | translate }} :
                        {{ getChartTitleByTag | applyPure: tag }}
                    </span>
                    <div class="flex items-center gap-2">
                        <span class="malou-text-13--regular italic">
                            {{ 'common.sort_by' | translate }}
                            {{ sortByDisplayWith | applyPure: sortByControl.value | lowercase }}
                        </span>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
            </div>
        }
    } @else {
        <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
    }
</ng-template>

<ng-template #noDataTemplate>
    <div class="flex flex-col items-center py-4">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
    </div>
</ng-template>
