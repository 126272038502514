@if (isLoading()) {
    <app-skeleton skeletonClass="h-[400px] secondary-bg"></app-skeleton>
} @else if (hasData()) {
    <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="keywordsErrorTemplate" [ngTemplateOutletContext]="{ errorMessage: errorMessage() }"> </ng-container>
}

<ng-template #dataTemplate>
    <div class="malou-simple-card flex h-full flex-col gap-3 px-6 py-3 md:px-2">
        <div class="flex items-center justify-between gap-2 md:flex-col md:items-start">
            <div class="flex items-center gap-2">
                <div class="malou-text-section-title malou-color-text-1">
                    {{ 'aggregated_statistics.seo.keywords.google_ranking' | translate }}
                </div>
                @if (warningTooltip()) {
                    <div class="malou-status--waiting pdf-hidden" [matTooltip]="warningTooltip()">
                        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-col">
            <div class="hidden grow md:block">
                <app-sort-by-filters
                    [sortOptions]="SORT_OPTIONS"
                    [sortBy]="KeywordTableColumns.RESTAURANT_NAME"
                    [sortOrder]="1"
                    (changeSortBy)="onSortByChange($event)"
                    (toggleSortOrder)="onSortOrderChange()">
                </app-sort-by-filters>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table"
        matSort
        [dataSource]="dataSource"
        [matSortActive]="defaultSort().active"
        [matSortDirection]="defaultSort().direction"
        (matSortChange)="onSortChange($event)"
        #table="matTable">
        <ng-container [matColumnDef]="KeywordTableColumns.RESTAURANT_NAME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.seo.keywords.restaurant' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <span class="malou-text-13--semibold malou-color-text-1" (click)="goToRestaurantKeywordsStats(row)">
                    {{ row.restaurantName }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordTableColumns.CURRENT_TOP_20">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.seo.keywords.top20' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex">
                    <div class="malou-text-13--regular malou-color-text-1 mr-2 hidden w-48 md:block">
                        {{ 'aggregated_statistics.seo.keywords.top20' | translate }}
                    </div>
                    <div class="malou-text-13--regular malou-color-text-1 flex items-center gap-2">
                        @if (!(row | applySelfPure: 'hasError')) {
                            <span> {{ row.currentTop20 }} </span>
                            <app-number-evolution
                                size="small"
                                [value]="row | applySelfPure: 'getDiffTop20'"
                                [displayedValue]="row | applySelfPure: 'getDiffTop20' | shortNumber: { shouldDisplayMinusSign: false }">
                            </app-number-evolution>
                        } @else {
                            <ng-container [ngTemplateOutlet]="rawError" [ngTemplateOutletContext]="{ row }"></ng-container>
                        }
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordTableColumns.CURRENT_TOP_3">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.seo.keywords.top3' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex">
                    <div class="malou-text-13--regular malou-color-text-1 mr-2 hidden w-48 md:inline">
                        {{ 'aggregated_statistics.seo.keywords.top3' | translate }}
                    </div>
                    <div class="malou-text-13--regular malou-color-text-1 flex items-center gap-2">
                        @if (!(row | applySelfPure: 'hasError')) {
                            <span> {{ row.currentTop3 }} </span>
                            <app-number-evolution
                                size="small"
                                [value]="row | applySelfPure: 'getDiffTop3'"
                                [displayedValue]="row | applySelfPure: 'getDiffTop3' | shortNumber: { shouldDisplayMinusSign: false }">
                            </app-number-evolution>
                        } @else {
                            <ng-container [ngTemplateOutlet]="rawError" [ngTemplateOutletContext]="{ row }"></ng-container>
                        }
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordTableColumns.DISCOVERY_KEYWORD_IMPRESSIONS">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.seo.keywords.discovery' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex">
                    <div class="malou-text-13--regular malou-color-text-1 mr-2 hidden w-48 md:block">
                        {{ 'aggregated_statistics.seo.keywords.discovery' | translate }}
                    </div>
                    <div class="malou-text-13--regular malou-color-text-1 flex items-center gap-2">
                        @if (!(row | applySelfPure: 'hasError')) {
                            <span>
                                {{ row.discoveryKeywordImpressions | shortNumber }}
                            </span>
                            <app-number-evolution
                                size="small"
                                [value]="row | applySelfPure: 'getDiffDiscoveryKeywordImpressions'"
                                [displayedValue]="
                                    row
                                        | applySelfPure: 'getDiffDiscoveryKeywordImpressions'
                                        | shortNumber: { shouldDisplayMinusSign: false }
                                ">
                            </app-number-evolution>
                        } @else {
                            <ng-container [ngTemplateOutlet]="rawError" [ngTemplateOutletContext]="{ row }"></ng-container>
                        }
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="KeywordTableColumns.BRANDING_KEYWORD_IMPRESSIONS">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.seo.keywords.branding' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex">
                    <div class="malou-text-13--regular malou-color-text-1 mr-2 hidden w-48 md:block">
                        {{ 'aggregated_statistics.seo.keywords.branding' | translate }}
                    </div>
                    <div class="malou-text-13--regular malou-color-text-1 flex items-center gap-2">
                        @if (!(row | applySelfPure: 'hasError')) {
                            <span>
                                {{ row.brandingKeywordImpressions | shortNumber }}
                            </span>
                            <app-number-evolution
                                size="small"
                                [value]="row | applySelfPure: 'getDiffBrandingKeywordImpressions'"
                                [displayedValue]="
                                    row
                                        | applySelfPure: 'getDiffBrandingKeywordImpressions'
                                        | shortNumber: { shouldDisplayMinusSign: false }
                                ">
                            </app-number-evolution>
                        } @else {
                            <ng-container [ngTemplateOutlet]="rawError" [ngTemplateOutletContext]="{ row }"></ng-container>
                        }
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table; let i = index"></mat-row>
    </mat-table>
</ng-template>

<ng-template let-errorMessage="errorMessage" #keywordsErrorTemplate>
    @if (errorMessage) {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'common.server_error' | translate }}</span>
            <span class="malou-text-10--regular">{{ errorMessage }}</span>
        </div>
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
            <span class="malou-text-10--regular">{{ 'statistics.errors.change_filters' | translate }}</span>
        </div>
    }
</ng-template>

<ng-template let-row="row" #rawError>
    <div class="malou-status--waiting" [matTooltip]="row.error ?? ''">
        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
    </div>
</ng-template>
