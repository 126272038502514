<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal() || (displayCloseModal() && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal() && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal() ? closeModalTemplate : editModalTemplate"></ng-container>
</div>

<ng-template #closeModalTemplate>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal.set(false)"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModalTemplate>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <span>{{ (data.isEdit ? 'roles.existing_user.edit_user' : 'roles.new_user.add_users') | translate }}</span>
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>

        <div class="malou-dialog__body">
            <form [formGroup]="usersForm">
                @for (user of usersFormArray.controls; track user; let i = $index; let last = $last; let isFirst = $first) {
                    <div class="my-3" formArrayName="usersFormArray">
                        <div class="flex flex-col gap-2" [formGroupName]="i">
                            <div class="flex gap-2 md:flex-col">
                                <div class="w-1/2 md:w-full">
                                    <app-select
                                        formControlName="user"
                                        [title]="'roles.new_user.email' | translate: { userNumber: i + 1 }"
                                        [values]="availableCandidates()"
                                        [displayWith]="displayUser"
                                        [itemBuilder]="buildUser">
                                    </app-select>
                                    @if (usersFormArray.at(i).get('user')?.touched && usersFormArray.at(i).get('user')?.errors) {
                                        <mat-error class="malou-text-10--regular malou-color-state-error ml-1 py-1 italic">
                                            {{ 'common.required_field' | translate }}
                                        </mat-error>
                                    }
                                </div>
                                <div class="flex w-1/2 gap-2">
                                    <div class="w-full">
                                        <app-select-languages
                                            class="w-1/2 md:!w-full"
                                            formControlName="language"
                                            [title]="'roles.new_user.default_lang' | translate"
                                            [selectedValues]="[currentLang()]"
                                            [values]="APP_LANGUAGES"
                                            [disabled]="areExistingUsers()[i]"
                                            [multiSelection]="false"
                                            (selectLanguagesChange)="changeLang($event, i)">
                                        </app-select-languages>
                                    </div>
                                    @if (!isFirst) {
                                        <div class="flex flex-col justify-end">
                                            <button
                                                class="malou-btn-icon--secondary btn-xl"
                                                mat-icon-button
                                                [disabled]="isCreatingUser()"
                                                (click)="removeUserForm(i)">
                                                <mat-icon class="icon-btn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                            </button>
                                            @if (
                                                (usersFormArray.at(i).get('caslRole')?.touched &&
                                                    usersFormArray.at(i).get('caslRole')?.errors) ||
                                                (usersFormArray.at(i).get('user')?.touched && usersFormArray.at(i).get('user')?.errors)
                                            ) {
                                                <div class="h-[23px] w-full"></div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <app-select
                                    formControlName="caslRole"
                                    [title]="'roles.new_user.role' | translate"
                                    [values]="CASL_ROLES | map: 'key'"
                                    [displayWith]="displayCaslRole('text')">
                                    <ng-template let-value="value" #optionTemplate>
                                        <div class="flex w-full flex-col">
                                            <span class="malou-text-13--bold text-malou-color-text-2">{{
                                                displayCaslRole('text')(value)
                                            }}</span>
                                            <span class="malou-text-10--regular text-malou-color-text-2">{{
                                                displayCaslRole('subtext')(value)
                                            }}</span>
                                        </div>
                                    </ng-template>
                                </app-select>
                                @if (usersFormArray.at(i).get('caslRole')?.touched && usersFormArray.at(i).get('caslRole')?.errors) {
                                    <mat-error class="malou-text-10--regular malou-color-state-error ml-1 py-1 italic">
                                        {{ 'common.required_field' | translate }}
                                    </mat-error>
                                }
                            </div>
                        </div>
                        @if (!last && usersFormArray.controls && usersFormArray.controls.length > 1) {
                            <mat-divider class="malou-border-color-dark !mt-4"></mat-divider>
                        }
                    </div>
                }
            </form>

            @if (!data.isEdit) {
                <div class="flex justify-start">
                    <a class="malou-btn-flat !pl-0" mat-button (click)="addUserForm()">
                        <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        {{ 'roles.new_user.add_user' | translate }}
                    </a>
                </div>
            }
            @if (isFetchingUserToUpdate()) {
                <app-skeleton skeletonClass="h-[200px]"></app-skeleton>
            } @else {
                <div class="malou-color-background-dark mt-4 flex flex-col rounded-[10px] p-3">
                    <mat-checkbox color="primary" [checked]="displayRestaurantSelection()" (change)="toggleDisplayRestaurantsSelection()">
                        <span class="malou-text-13--regular malou-color-text-2">{{
                            (data.isEdit ? 'roles.existing_user.update_to_other_businesses' : 'roles.new_user.add_to_other_businesses')
                                | translate
                        }}</span>
                    </mat-checkbox>
                    @if (displayRestaurantSelection()) {
                        <section>
                            @if (ownedRestaurants$ | async; as ownedRestaurants) {
                                <app-select-restaurants
                                    [title]="'roles.new_user.business' | translate"
                                    [required]="true"
                                    [values]="ownedRestaurants"
                                    [selectedValues]="defaultSelectedRestaurants()"
                                    [displayedOptionCount]="100"
                                    (selectRestaurantsChange)="restaurantsSelectionChanged($event)"></app-select-restaurants>
                            }
                        </section>
                    }
                </div>
            }
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <div class="flex md:w-1/2">
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button [disabled]="isCreatingUser()" (click)="close()">
                    {{ 'common.cancel' | translate }}
                </button>
            </div>
            <div class="flex md:w-1/2" matTooltip="{{ 'roles.new_user.fill_required_fields' | translate }}">
                <app-button
                    class="md:grow"
                    buttonClasses="!h-11 md:w-full"
                    [text]="(data.isEdit ? 'common.validate' : 'common.add') | translate"
                    [disabled]="usersForm.invalid"
                    [loading]="isCreatingUser()"
                    (buttonClick)="save()"></app-button>
            </div>
        </div>
    </div>
</ng-template>
