import { waitFor } from '@malou-io/package-utils';

import { VideoMediaAnalyzer } from '../models/media-analyzer';
import { isSafari } from './is-safari';
import { retryPromise } from './retry-promise';

const DEFAULT_QUALITY = 1;

// A video can be played if it has an entry in this map with the value `true`.
const canPlayMap = new WeakMap<HTMLVideoElement, boolean>();

/** The created video element must be removed after use with its `.remove()` method. */
export const createVideoElement = (videoUrl: string): HTMLVideoElement => {
    const video = document.createElement('video');
    video.setAttribute('crossOrigin', 'anonymous');
    video.style.display = 'none';
    video.width = 1;
    video.height = 1;
    if (!isSafari) {
        video.preload = 'metadata';
    }
    const mediaUrl = videoUrl + '?x-request=xhr&t=' + Date.now();
    video.src = mediaUrl;

    canPlayMap.set(video, !isSafari);
    video.addEventListener('canplay', () => {
        canPlayMap.set(video, true);
    });

    document.body.appendChild(video);
    return video;
};

/**
 * Wait until the duration and the resolution of the video is known.
 * The video element must have been created by createVideoElement.
 */
const waitUntilVideoIsReady = async (video: HTMLVideoElement): Promise<void> => {
    if (video.duration === Infinity) {
        throw new Error('streaming is not supported');
    }

    let error: unknown = null;
    const onError = (e) => {
        error = e;
    };
    video.addEventListener('error', onError);

    while (Number.isNaN(video.duration) || video.videoWidth === 0 || video.videoHeight === 0 || !canPlayMap.get(video)) {
        if (error) {
            throw error;
        }
        await waitFor(100);
    }
    video.removeEventListener('error', onError);
};

const seekVideo = async (video: HTMLVideoElement, timestampSeconds: number): Promise<void> => {
    await waitUntilVideoIsReady(video);

    if (timestampSeconds > video.duration) {
        timestampSeconds = video.duration;
    }

    if (video.currentTime === timestampSeconds) {
        return;
    }

    video.currentTime = timestampSeconds;

    await new Promise((resolve, reject) => {
        const onSeeked = () => {
            resolve(undefined);
            video.removeEventListener('seeked', onSeeked);
        };
        const onError = (e) => {
            reject(e);
            video.removeEventListener('error', onError);
        };
        video.addEventListener('seeked', onSeeked);
        video.addEventListener('error', onError);
    });

    await waitUntilVideoIsReady(video);
};

/**
 * Extracts a frame from a video.
 *
 * The returned URL must be disposed with window.URL.revokeObjectURL().
 */
export const getVideoCoverUrl = async ({
    video,
    resolutionPx,
    timestampSeconds,
}: {
    video: HTMLVideoElement;
    /** The size (in pixels) of the smallest edge of the generated picture */
    resolutionPx: number;
    timestampSeconds: number;
}): Promise<{ url: string; dimensions: { width: number; height: number } }> =>
    await retryPromise(
        (async () => {
            // Load video in Safari / IE11
            video.muted = true;
            video.playsInline = true;

            timestampSeconds = Math.max(0.1, timestampSeconds);

            await seekVideo(video, timestampSeconds);

            const canvas = document.createElement('canvas');
            const aspectRatio = video.videoWidth / video.videoHeight;
            if (aspectRatio <= 1) {
                canvas.width = resolutionPx;
                canvas.height = Math.round(resolutionPx / aspectRatio);
            } else {
                canvas.height = resolutionPx;
                canvas.width = Math.round(resolutionPx * aspectRatio);
            }

            // Draw the video frame onto the canvas
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Could not get canvas context');
            }
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            return await new Promise<{ url: string; dimensions: { width: number; height: number } }>((resolve, reject) => {
                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject(new Error('Could not get canvas blob'));
                            return;
                        }
                        const thumbnailUrl = window.URL.createObjectURL(blob);
                        resolve({ url: thumbnailUrl, dimensions: { width: canvas.width, height: canvas.height } });
                    },
                    'image/jpeg',
                    DEFAULT_QUALITY
                );
            });
        })()
    );

/** Returned URLs must be disposed with window.URL.revokeObjectURL(). */
export const pickImagesFromVideo = async (params: {
    videoUrl: string;

    /** The duration is computed if the duration is not provided. */
    videoDuration?: number;

    resolutionPx: number;

    numberOfImages: number;

    /**
     * Since this function can take a while to complete, this callback can be used to
     * display a progress bar. `progress` is a number between 0 (just started) and 1
     * (completed).
     */
    onProgress: (progress: number) => void;
}): Promise<string[]> => {
    params.onProgress(0);
    const video = createVideoElement(params.videoUrl);
    const mediaDuration = params.videoDuration ? params.videoDuration : await _getMediaDuration(params.videoUrl);
    const step = mediaDuration / params.numberOfImages;
    const res: string[] = [];
    for (let i = 0; i < params.numberOfImages; i++) {
        params.onProgress((i + 1) / (params.numberOfImages + 1));
        const timestampSeconds = (i + 1 / params.numberOfImages) * step;
        const videoCover = await retryPromise(getVideoCoverUrl({ video, resolutionPx: params.resolutionPx, timestampSeconds }));
        res.push(videoCover.url);
    }
    video.remove();
    return res;
};

const _getMediaDuration = async (videoUrl: string): Promise<number> => {
    const videoMediaAnalyzer = new VideoMediaAnalyzer(undefined);
    const metadata = await videoMediaAnalyzer.getMetadata(videoUrl);
    return metadata.duration as number;
};
