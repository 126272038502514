<div class="malou-dialog">
    <div class="malou-dialog__header gap-x-5">
        <span>
            {{ 'statistics.e_reputation.reviews_analysis.related_verbatim' | translate }}
            "{{ segments[0].tag | enumTranslate: 'review_analysis_tags' }}"
            <span class="malou-text-14--regular malou-color-text-1">
                ({{ segments.length }} {{ 'statistics.e_reputation.reviews_analysis.feelings' | translate }})
            </span>
        </span>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <ng-container [ngTemplateOutlet]="desktopView"></ng-container>
    <ng-container [ngTemplateOutlet]="mobileView"></ng-container>
</div>

<ng-template #desktopView>
    <div class="malou-dialog__body flex grow gap-x-5 !overflow-hidden !pb-[26px] md:hidden">
        <div class="hide-scrollbar relative flex-2">
            <ng-container [ngTemplateOutlet]="segmentsTemplate"></ng-container>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="hide-scrollbar flex-3 overflow-y-auto">
            <ng-container [ngTemplateOutlet]="reviewsTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #mobileView>
    <div class="malou-dialog__body hidden grow gap-x-5 !overflow-hidden !pb-[26px] md:flex">
        @if (showReviewsTemplate) {
            <div class="flex-1 grow">
                <div class="hide-scrollbar overflow-y-auto">
                    <ng-container [ngTemplateOutlet]="reviewsTemplate"></ng-container>
                </div>
            </div>
        } @else {
            <mat-tab-group
                class="custom-tab-group h-full w-full"
                animationDuration="5ms"
                mat-align-tabs="start"
                [selectedIndex]="selectedTabIndex"
                (selectedIndexChange)="handleTabChange($event)">
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label>
                        <span class="malou-color-bg-success malou-color-success mr-2 h-3 w-3 rounded-full">•</span>
                        {{ 'statistics.e_reputation.reviews_analysis.positive' | pluralTranslate: PluralCategory.MANY }} &nbsp;
                        <span class="malou-text-11--regular malou-color-text-1"> ({{ positiveSegmentsLength }})</span>
                    </ng-template>
                    <div class="mt-3 flex flex-col gap-2.5">
                        @for (segment of sortedPositiveSegments; track segment) {
                            <div class="flex">
                                <ng-container
                                    [ngTemplateOutlet]="segmentTemplate"
                                    [ngTemplateOutletContext]="{
                                        segment: { key: segment, value: positiveSegments[segment] },
                                        sentiment: semanticAnalysisSentiment.POSITIVE,
                                    }">
                                </ng-container>
                            </div>
                        }
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label>
                        <span class="malou-color-bg-fail malou-color-fail mr-2 h-3 w-3 rounded-full">•</span>
                        {{ 'statistics.e_reputation.reviews_analysis.negative' | pluralTranslate: PluralCategory.MANY }} &nbsp;
                        <span class="malou-text-11--regular malou-color-text-1"> ({{ negativeSegmentsLength }})</span>
                    </ng-template>
                    <div class="mt-3 flex flex-col gap-2">
                        @for (segment of sortedNegativeSegments; track segment) {
                            <div class="flex">
                                <ng-container
                                    [ngTemplateOutlet]="segmentTemplate"
                                    [ngTemplateOutletContext]="{
                                        segment: { key: segment, value: negativeSegments[segment] },
                                        sentiment: semanticAnalysisSentiment.NEGATIVE,
                                    }">
                                </ng-container>
                            </div>
                        }
                    </div>
                </mat-tab>
            </mat-tab-group>
        }
    </div>
</ng-template>

<ng-template #segmentsTemplate>
    <mat-tab-group
        class="custom-tab-group h-full w-full"
        animationDuration="5ms"
        mat-align-tabs="start"
        [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="handleTabChange($event)">
        <mat-tab>
            <ng-template class="malou-text-14--regular" mat-tab-label>
                <span class="malou-color-bg-success malou-color-success mr-2 h-3 w-3 rounded-full">•</span>
                {{ 'statistics.e_reputation.reviews_analysis.positive' | pluralTranslate: PluralCategory.MANY }} &nbsp;
                <span class="malou-text-11--regular malou-color-text-1"> ({{ positiveSegmentsLength }})</span>
            </ng-template>
            <div class="mt-3 flex flex-col gap-2.5">
                @for (segment of sortedPositiveSegments; track segment) {
                    <div class="flex">
                        <ng-container
                            [ngTemplateOutlet]="segmentTemplate"
                            [ngTemplateOutletContext]="{
                                segment: { key: segment, value: positiveSegments[segment] },
                                sentiment: semanticAnalysisSentiment.POSITIVE,
                            }">
                        </ng-container>
                    </div>
                }
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template class="malou-text-14--regular" mat-tab-label>
                <span class="malou-color-bg-fail malou-color-fail mr-2 h-3 w-3 rounded-full">•</span>
                {{ 'statistics.e_reputation.reviews_analysis.negative' | pluralTranslate: PluralCategory.MANY }} &nbsp;
                <span class="malou-text-11--regular malou-color-text-1"> ({{ negativeSegmentsLength }})</span>
            </ng-template>
            <div class="mt-3 flex flex-col gap-2">
                @for (segment of sortedNegativeSegments; track segment) {
                    <div class="flex">
                        <ng-container
                            [ngTemplateOutlet]="segmentTemplate"
                            [ngTemplateOutletContext]="{
                                segment: { key: segment, value: negativeSegments[segment] },
                                sentiment: semanticAnalysisSentiment.NEGATIVE,
                            }">
                        </ng-container>
                    </div>
                }
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template let-segment="segment" let-sentiment="sentiment" #segmentTemplate>
    <div
        class="container-border-grey flex-1 cursor-pointer rounded-[10px] p-4"
        [ngClass]="segment.key === selectedSegment ? 'malou-color-background-dark' : ''"
        (click)="selectSegment(segment.key, sentiment)">
        <span class="malou-color-text-2" [ngClass]="segment.key === selectedSegment ? 'malou-text-12--bold' : 'malou-text-12--regular'">
            {{ segment.key }}
        </span>
        <span class="malou-color-text-2 malou-text-10--regular italic">({{ segment.value.length }})</span>
    </div>
</ng-template>

<ng-template #reviewsTemplate>
    <div class="ml-2 hidden items-center gap-2 md:flex">
        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="showReviewsTemplate = false">
            <mat-icon [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
        </button>

        <span class="malou-color-text-1 malou-text-18--bold">
            {{ 'statistics.e_reputation.reviews_analysis.related_review' | translate }}
        </span>
    </div>
    <div class="flex flex-col">
        @for (review of segmentReviews; track review) {
            <div>
                <ng-container [ngTemplateOutlet]="reviewTemplate" [ngTemplateOutletContext]="{ review }"></ng-container>
            </div>
        }
    </div>
</ng-template>

<ng-template let-review="review" #reviewTemplate>
    @let reviewerDisplayName = review.reviewer?.displayName ?? ('reviews.anonymous' | translate);
    <div class="malou-card !border-malou-color-border-primary !py-4">
        @if (isFromAggregatedStatistics) {
            <app-restaurant-header-for-review-preview
                [restaurant]="restaurantsById[review.restaurantId]"
                [review]="review"></app-restaurant-header-for-review-preview>
        }
        <div class="malou-card__header flex flex-col !pb-0 pl-0">
            <div class="flex w-full">
                <div class="malou-card__image-container">
                    <img class="avatar" alt="avatar" [lazyLoad]="reviewerDisplayName | avatar" />
                    <app-platform-logo
                        imgClasses="w-8.5 h-8.5 relative left-[-8px] top-[-27px] object-cover !rounded-full"
                        [logo]="review.key"
                        [withLazyLoading]="true"></app-platform-logo>
                </div>
                <div class="flex w-[30%] grow flex-col justify-center">
                    <div class="malou-text-12--bold truncate">{{ reviewerDisplayName }}</div>
                    <div class="malou-text__card-subtitle">{{ (review.socialCreatedAt | DateToStringPipe) || '-' }}</div>
                </div>

                <div class="malou-card__action-button">
                    <app-star-gauge class="px-4" [stars]="review.rating"></app-star-gauge>
                </div>
            </div>
        </div>
        <div class="malou-card__body-text mt-2">
            <div class="font-normal" [innerHTML]="review.highlightedText"></div>
        </div>

        <div class="mt-3">
            <span
                class="malou-color-text-primary malou-text-10--bold cursor-pointer"
                id="tracking_semantic_analysis_see_review_click"
                (click)="goToReview(review)">
                {{ 'statistics.e_reputation.reviews_analysis.show_review' | translate }}
            </span>
            <span class="malou-color-text-primary malou-text-10--regular">
                @if (isFromAggregatedStatistics) {
                    {{ 'aggregated_statistics.e_reputation.reviews_analysis.redirection_warning' | translate }}
                } @else {
                    {{ 'statistics.e_reputation.reviews_analysis.redirection_warning' | translate }}
                }
            </span>
        </div>
    </div>
</ng-template>
