<div
    class="malou-card !m-0 !py-6 transition-all"
    [ngClass]="{
        'malou-card--secondary': [PublicationStatus.PENDING, PublicationStatus.DRAFT] | includes: post().postStatus.type,
        'malou-card-active': showMovedBorder(),
    }">
    <div class="malou-card__header flex items-center justify-center gap-x-4">
        <mat-checkbox color="primary" [checked]="isChecked()" (change)="onPostCheckedChange($event)"></mat-checkbox>
        <div class="relative" #platforms>
            <div class="flex gap-x-1">
                @for (platformKey of platformKeysToShow(); track platformKey; let index = $index; let first = $first) {
                    <app-platform-logo imgClasses="h-8 w-8 max-w-fit" [logo]="platformKey"></app-platform-logo>
                }
            </div>
        </div>
        <div class="ml-6">
            <div class="malou-text-14--bold flex items-center gap-x-2 text-malou-color-text-1">
                <span class="sm:!max-w-[70px] sm:overflow-clip">{{ 'social_posts.' + post().postStatus.type | translate }}</span>
                <mat-icon
                    class="!h-4 !w-4"
                    data-testid="social-post-status-icon"
                    [ngStyle]="{ color: post().postStatus.iconColor }"
                    [svgIcon]="post().postStatus.icon"
                    [matTooltip]="
                        post().postStatus.type === ExtendedPostPublicationStatus.ERROR && errorMessage ? errorMessage : ''
                    "></mat-icon>
                @if (showFeedbackBadge) {
                    <span class="malou-text-10--semibold text-malou-color-state-warn md:hidden">
                        {{ 'social_post.new_feedback' | translate }}
                    </span>
                }
            </div>
            <div class="malou-text-12--regular italic text-malou-color-text-2">
                {{ isPhoneScreen() ? post().postStatus.smallSubText : post().postStatus.subtext }}
                @if (post().author?.name && !isPhoneScreen()) {
                    <span> {{ 'common.by' | translate }} {{ post().author?.name }}</span>
                }
            </div>
        </div>
        <div class="malou-card__action-button flex items-center gap-x-2 md:!hidden">
            @if (canOpenPostSocialLink(socialPost())) {
                <button
                    class="malou-btn-icon--secondary btn-xl hover:bg-malou-color-background-light"
                    mat-icon-button
                    (click)="openSocialLink()">
                    <mat-icon [svgIcon]="SvgIcon.EYE"></mat-icon>
                </button>
            }
            @if (socialPost().canEditPost()) {
                <button class="malou-btn-icon--secondary btn-xl hover:bg-malou-color-background-light" mat-icon-button (click)="editPost()">
                    <mat-icon [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </button>
            }

            <div
                [matTooltip]="
                    isInstagramReelWithoutVideo() ? ('social_post.instagram_reel_without_videos_cant_be_duplicated' | translate) : ''
                ">
                <button
                    class="malou-btn-icon--secondary btn-xl hover:bg-malou-color-background-light"
                    mat-icon-button
                    [matMenuTriggerFor]="duplicateActions"
                    [disabled]="isInstagramReelWithoutVideo()">
                    <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                </button>
            </div>
            @if (socialPost().canDeletePost()) {
                <button
                    class="malou-btn-icon--secondary btn-xl hover:bg-malou-color-background-light"
                    mat-icon-button
                    [matTooltip]="'common.delete' | translate"
                    (click)="deletePost()">
                    <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
            }
        </div>
        <div class="malou-card__action-button !hidden md:!block">
            <button
                class="malou-btn-icon--secondary btn-xl hover:bg-malou-color-background-light"
                mat-icon-button
                [matMenuTriggerFor]="postMenu">
                <mat-icon [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
            </button>

            <mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #postMenu="matMenu">
                @if (canOpenPostSocialLink(socialPost())) {
                    <button mat-menu-item (click)="openSocialLink()">
                        <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                        <span>{{ 'social_post.view_post' | translate }}</span>
                    </button>
                }
                @if (socialPost().canEditPost()) {
                    <button mat-menu-item (click)="editPost()">
                        <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        <span>{{ 'social_post.edit_post' | translate }}</span>
                    </button>
                }
                <button mat-menu-item [matMenuTriggerFor]="duplicateActions">
                    <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                    <span>{{ 'social_post.duplicate_post' | translate }}</span>
                </button>
                @if (socialPost().canDeletePost()) {
                    <button mat-menu-item (click)="deletePost()">
                        <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                        <span>{{ 'social_post.delete_post' | translate }}</span>
                    </button>
                }
            </mat-menu>
        </div>
    </div>

    <div class="flex gap-x-4">
        <div class="h-[105px] w-[105px]">
            @let malouMediaUrl = post() | applySelfPure: 'getMalouMediaUrl';
            @let socialMediaUrl = post() | applySelfPure: 'getSocialMediaUrl';
            @let malouMediaUrlAndDimensions = post() | applySelfPure: 'getMalouMediaUrlAndDimensions';
            @let thumbnailUrlAndDimensions = post() | applySelfPure: 'getThumbnailUrlAndDimensions';
            @let firstMediumType = post() | applySelfPure: 'getFirstMediumType';
            @let firstAttachment = post().attachments?.[0];
            @let firstSocialAttachment = post().socialAttachments?.[0];
            <app-social-post-media
                [customMediaClass]="'min-w-[105px] min-h-[105px] !rounded-[10px]'"
                [postType]="post().postType"
                [workingMedia]="socialMediaUrl || malouMediaUrl"
                [workingMediaDimensions]="socialMediaUrl ? undefined : malouMediaUrlAndDimensions?.dimensions"
                [firstAttachmentType]="firstMediumType"
                [showVideoControls]="false"
                [thumbnailUrl]="(thumbnailUrlAndDimensions | async)?.['url']"
                [thumbnailDimensions]="(thumbnailUrlAndDimensions | async)?.['dimensions']"
                [videoDimensions]="
                    firstMediumType === MediaType.VIDEO && !firstSocialAttachment?.urls?.original
                        ? firstAttachment?.dimensions?.original
                        : undefined
                "
                [transformData]="firstSocialAttachment?.urls?.original ? undefined : firstAttachment?.transformData"
                (refreshMedia)="refreshPost()"></app-social-post-media>
        </div>

        <div class="malou-card__body-text grow">
            @if (showFeedbackBadge) {
                <span class="malou-text-10--semibold hidden text-malou-color-state-warn md:!block">
                    {{ 'social_post.new_feedback' | translate }}
                </span>
            }
            @if (socialPost().text) {
                <p class="max-w-[75%] break-words transition-all" #postText>
                    {{ socialPost().text | shortText: (seeMore() ? 10000 : 500) }}
                    @if (socialPost().text.length > 500) {
                        <span>
                            <button class="malou-text-10--semibold malou-color-primary" (click)="toggleSeeMore()">
                                {{ seeMore() ? ('common.see_less' | translate) : ('common.see_more' | translate) }}.
                            </button>
                        </span>
                    }
                </p>
            }
        </div>
    </div>
</div>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #duplicateActions="matMenu">
    <button mat-menu-item (click)="duplicatePost(DuplicationDestination.HERE, getPostSocialPlatformKeys())">
        <span>{{ 'common.here' | translate }}</span>
    </button>
    @if (!isPostInError()) {
        @if (!restaurant.isBrandBusiness() && !(hasVideoInSelection | applyPure: post().attachments?.length ?? 0)) {
            <button mat-menu-item (click)="duplicatePost(DuplicationDestination.HERE, platformKeysWithSeoPost)">
                <span>{{ 'posts.duplicate_in_seo' | translate }}</span>
            </button>
        }
        <button mat-menu-item (click)="duplicatePost(DuplicationDestination.OUT, getPostSocialPlatformKeys())">
            <span>{{ 'common.to_other_venues' | translate }}</span>
        </button>
    }
</mat-menu>
