import { SocialPostItemDto } from '@malou-io/package-dto';
import {
    isNotNil,
    PlatformKey,
    PostPublicationStatus,
    PostType,
    PublicationErrorCode,
    RemoveMethodsFromClass,
} from '@malou-io/package-utils';

import { SocialPostAuthor } from ':modules/posts-v2/social-posts/models/social-post-author';
import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { IUpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { Hashtag, PostHashtags } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export type ISocialPostItem = RemoveMethodsFromClass<SocialPostItem> & { id: string };

export class SocialPostItem implements ISocialPostItem {
    id: string;
    text: string;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    isPublishing: boolean;
    postType: PostType;
    feedbackMessageCount: number;
    plannedPublicationDate: Date | null;
    media: SocialPostMedia | null;
    hashtags?: PostHashtags;
    socialLink?: string;
    socialCreatedAt?: Date;
    sortDate?: Date;
    author?: SocialPostAuthor;
    mostRecentPublicationErrorCode?: PublicationErrorCode;
    bindingId?: string;

    constructor(data: ISocialPostItem) {
        this.id = data.id;
        this.text = data.text;
        this.platformKeys = data.platformKeys;
        this.published = data.published;
        this.isPublishing = data.isPublishing;
        this.postType = data.postType;
        this.feedbackMessageCount = data.feedbackMessageCount;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.media = data.media;
        this.hashtags = data.hashtags;
        this.socialLink = data.socialLink;
        this.socialCreatedAt = data.socialCreatedAt;
        this.sortDate = data.sortDate;
        this.author = data.author;
        this.mostRecentPublicationErrorCode = data.mostRecentPublicationErrorCode;
        this.bindingId = data.bindingId;
    }

    static fromDto(dto: SocialPostItemDto): SocialPostItem {
        return new SocialPostItem({
            id: dto.id,
            text: dto.text,
            platformKeys: dto.platformKeys,
            published: dto.published,
            isPublishing: dto.isPublishing,
            postType: dto.postType,
            feedbackMessageCount: dto.feedbackMessageCount,
            plannedPublicationDate: dto.plannedPublicationDate ? new Date(dto.plannedPublicationDate) : null,
            media: dto.media ? SocialPostMedia.fromDto(dto.media) : null,
            hashtags: dto.hashtags
                ? {
                      selected: dto.hashtags.selected.map((hashtag) => new Hashtag(hashtag)),
                      suggested: dto.hashtags.suggested.map((hashtag) => new Hashtag(hashtag)),
                  }
                : undefined,
            socialLink: dto.socialLink,
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
            sortDate: dto.sortDate ? new Date(dto.sortDate) : undefined,
            author: dto.author ? SocialPostAuthor.fromDto(dto.author) : undefined,
            mostRecentPublicationErrorCode: dto.mostRecentPublicationErrorCode,
            bindingId: dto.bindingId,
        });
    }

    static fromIUpsertSocialPost(upsertSocialPost: IUpsertSocialPost): SocialPostItem {
        const firstAttachment = upsertSocialPost.attachments.length ? upsertSocialPost.attachments[0] : null;

        return new SocialPostItem({
            id: upsertSocialPost.id,
            text: upsertSocialPost.text,
            platformKeys: upsertSocialPost.platformKeys,
            published: upsertSocialPost.published,
            isPublishing: upsertSocialPost.isPublishing,
            postType: upsertSocialPost.postType,
            feedbackMessageCount: upsertSocialPost.feedbacks?.isOpen ? upsertSocialPost.feedbacks.feedbackMessages.length : 0,
            plannedPublicationDate: upsertSocialPost.plannedPublicationDate,
            media: firstAttachment
                ? new SocialPostMedia({
                      url: firstAttachment.thumbnail256OutsideUrl,
                      type: firstAttachment.type,
                      thumbnailUrl: firstAttachment.thumbnail256OutsideUrl,
                      thumbnailDimensions: firstAttachment.thumbnail256OutsideDimensions,
                      transformData: firstAttachment.transformData,
                  })
                : null,
            hashtags: upsertSocialPost.hashtags,
            socialLink: upsertSocialPost.socialLink,
            socialCreatedAt: upsertSocialPost.socialCreatedAt,
            sortDate: upsertSocialPost.plannedPublicationDate ?? new Date(),
            author: upsertSocialPost.author,
            bindingId: upsertSocialPost.bindingId,
        });
    }

    getHashtagsText(): string {
        return this.hashtags ? this.hashtags.selected.map((hashtag) => hashtag.text).join(' ') : '';
    }

    getPostDate(): Date | undefined {
        const date = this.sortDate ?? this.socialCreatedAt ?? this.plannedPublicationDate;
        return date ? new Date(date) : undefined;
    }

    getPostTypeIcon(): SvgIcon | undefined {
        switch (this.postType) {
            case PostType.VIDEO:
            case PostType.REEL:
                return SvgIcon.VIDEO_FILLED;
            case PostType.CAROUSEL:
                return SvgIcon.IMAGES_FILLED;
            default:
                return undefined;
        }
    }

    canOpenSocialLink = (): boolean => this.published === PostPublicationStatus.PUBLISHED && isNotNil(this.socialLink);

    canEdit = (): boolean => this.published !== PostPublicationStatus.PUBLISHED && !this.isPublishing;

    canDelete = (): boolean =>
        !this.isPublishing &&
        (this.published !== PostPublicationStatus.PUBLISHED ||
            (this.platformKeys.length === 1 && this.platformKeys[0] === PlatformKey.FACEBOOK));

    canSchedule = (): boolean => this.published !== PostPublicationStatus.PUBLISHED && !this.isPublishing;

    copyWith(data: Partial<ISocialPostItem>): SocialPostItem {
        return new SocialPostItem({ ...this, ...data });
    }
}
