export enum LocalStorageKey {
    CHECKLIST_MINIFIED = 'checklistMinified',
    DISCONNECTED_PLATFORMS_POPIN_LAST_OPENED_AT = 'disconnectPlatformsPopinLastOpenedAt',
    DISCONNECTED_SOCIAL_PLATFORMS_POPIN_LAST_OPENED_AT = 'disconnectSocialPlatformsPopinLastOpenedAt',
    DISCONNECTED_SOCIAL_AND_SEO_PLATFORMS_POPIN_LAST_OPENED_AT = 'disconnectSocialAndSeoPlatformsPopinLastOpenedAt',
    DO_NOT_SHOW_AGAIN_CREATE_RESTAURANT_WHEEL_CONFIRMATION = 'doNotShowAgainCreateRestaurantWheelConfirmation',
    DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL = 'doNotShowAgainEmptyStockModal',
    DOWNLOAD_INSIGHTS_PARAMS = 'downloadInsightsParams',
    IS_ROI_SETTINGS_POPUP_SHOWN = 'isRoiSettingsPopupShown',
    JWT_TOKEN = 'jwtToken',
    LANG = 'lang',
    LEAVED_REVIEW_RESTAURANT_WHEELS_OF_FORTUNE = 'leavedReviewRestaurantWheelsOfFortune',
    PLAYED_RESTAURANT_WHEELS_OF_FORTUNE = 'playedRestaurantWheelsOfFortune',
    RESTAURANT_CREATION_FORM_IN_PROGRESS = 'restaurantCreationFormInProgress',
    ROI_SETTINGS_DUPLICATE_TOOLTIP_SHOWN_COUNT = 'roiSettingsDuplicateTooltipShownCount',
    SHOULD_USE_IMAGE_ANALYSIS_IN_POSTS_BY_DEFAULT = 'shouldUseImageAnalysisInPostsByDefault',
    SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS = 'subscriptionRequestSentRestaurantIds',
    REVIEWS_CALCULATOR_RATING_GOALS = 'reviewsCalculatorRatingGoals',
}
