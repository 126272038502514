import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { capitalize, orderBy } from 'lodash';

import { GetSegmentAnalysesTopTopicsBodyDto, SegmentAnalysesTopicDto } from '@malou-io/package-dto';
import { ReviewAnalysisSentiment } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    TabIndex,
    TopicSegmentAnalysisModalComponent,
} from ':shared/components/review-analyses-v2/topic-segment-analysis-modal/topic-segment-analysis-modal.component';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-semantic-analysis-top-topics',
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, TranslateModule, IllustrationPathResolverPipe, EmojiPathResolverPipe],
    templateUrl: './semantic-analysis-top-topics.component.html',
    styleUrl: './semantic-analysis-top-topics.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SemanticAnalysisTopTopicsComponent {
    readonly positiveTopics = input<SegmentAnalysesTopicDto[]>([]);
    readonly negativeTopics = input<SegmentAnalysesTopicDto[]>([]);
    readonly segmentAnalysesTopicFilter = input.required<GetSegmentAnalysesTopTopicsBodyDto | null>();

    private readonly _customDialogService = inject(CustomDialogService);
    readonly screenSizeService = inject(ScreenSizeService);

    readonly Emoji = Emoji;

    readonly currentLang = signal(LocalStorage.getLang());

    readonly sortedPositiveTopics = computed<(SegmentAnalysesTopicDto & { displayedName: string })[]>(() =>
        orderBy(this.positiveTopics(), 'positiveCount', 'desc').map((topic) => ({
            ...topic,
            displayedName: capitalize(topic.name),
            displayedNameInCurrentLang: capitalize(topic.translations[this.currentLang()]),
        }))
    );
    readonly sortedNegativeTopics = computed<(SegmentAnalysesTopicDto & { displayedName: string })[]>(() =>
        orderBy(this.negativeTopics(), 'negativeCount', 'desc').map((topic) => ({
            ...topic,
            displayedName: capitalize(topic.name),
            displayedNameInCurrentLang: capitalize(topic.translations[this.currentLang()]),
        }))
    );

    readonly ReviewAnalysisSentiment = ReviewAnalysisSentiment;
    readonly Illustration = Illustration;
    readonly capitalize = capitalize;

    openSegmentAnalysisModal(topic: SegmentAnalysesTopicDto, sentiment: ReviewAnalysisSentiment): void {
        const segmentAnalysesTopicFilter = this.segmentAnalysesTopicFilter();
        if (!segmentAnalysesTopicFilter) {
            return;
        }
        this._customDialogService.open(TopicSegmentAnalysisModalComponent, {
            width: '80vw',
            height: '80vh',
            disableClose: true,
            data: {
                topic: topic.name,
                topicTranslations: topic.translations,
                reviewAnalysesFilter: {
                    ...segmentAnalysesTopicFilter,
                    restaurantIds: [segmentAnalysesTopicFilter.restaurantId],
                },
                isFromAggregatedStatistics: false,
                isMainCategory: false,
                shouldShowSubcategories: false,
                tabIndex: sentiment === ReviewAnalysisSentiment.NEGATIVE ? TabIndex.NEGATIVE : TabIndex.POSITIVE,
            },
        });
    }
}
