import { z } from 'zod';

import { ApplicationLanguage, ReviewAnalysisTag } from '@malou-io/package-utils';

export const segmentAnalysesTopicValidator = z.object({
    name: z.string(),
    translations: z.object({
        en: z.string(),
        fr: z.string(),
        es: z.string(),
        it: z.string(),
    }),
    positiveCount: z.number().nullable(),
    negativeCount: z.number().nullable(),
});

export type SegmentAnalysesTopicDto = z.infer<typeof segmentAnalysesTopicValidator>;

export const getSegmentAnalysesTopTopicsValidator = z.object({
    positiveTopics: z.array(segmentAnalysesTopicValidator),
    negativeTopics: z.array(segmentAnalysesTopicValidator),
});

export type GetSegmentAnalysesTopTopicsResponseDto = z.infer<typeof getSegmentAnalysesTopTopicsValidator>;

// ------------------------------------------------------------------------------------------

export interface SegmentAnalysisParentTopicInsights {
    parentTopicId: string;
    name: string;
    translations: Record<ApplicationLanguage, string>;
    isNew: boolean;
    isFavorite: boolean;
    positiveCount: number;
    negativeCount: number;
    positiveCountEvolution: number;
    negativeCountEvolution: number;
}
export interface SegmentAnalysesCategoryInsights {
    total: number;
    parentTopics: SegmentAnalysisParentTopicInsights[];
}

export type GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto = Record<ReviewAnalysisTag, SegmentAnalysesCategoryInsights>;

// ------------------------------------------------------------------------------------------

export interface TimeSeriesSegmentAnalysisDto {
    startDate: string;
    endDate: string;
    results: {
        dataPerDay: { day: number; year: number; positiveCount: number; negativeCount: number }[];
        dataPerWeek: { week: number; year: number; positiveCount: number; negativeCount: number }[];
        dataPerMonth: { month: number; year: number; positiveCount: number; negativeCount: number }[];
    };
}

// ------------------------------------------------------------------------------------------

export interface SegmentAnalysisInsightsForRestaurantDto {
    restaurantId: string;
    sentimentsByCategory: Partial<
        Record<
            ReviewAnalysisTag,
            { positiveCount: number; negativeCount: number; positiveCountEvolution: number; negativeCountEvolution: number }
        >
    >;
    topTopics: GetSegmentAnalysesTopTopicsResponseDto;
}
