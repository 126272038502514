import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    afterNextRender,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    contentChild,
    DestroyRef,
    effect,
    ElementRef,
    forwardRef,
    inject,
    Injector,
    input,
    OnInit,
    output,
    signal,
    TemplateRef,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule } from '@ngx-translate/core';
import { MentionConfig, MentionModule } from 'angular-mentions';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged, startWith, tap } from 'rxjs/operators';

import { postCaptionTextLimit } from ':core/constants';
import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { EmojiPickerComponent } from ':shared/components/emoji-picker/emoji-picker.component';
import { createTextWithEmoji, CursorPosition, focusAfterEmoji } from ':shared/helpers/text-area-emoji.helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

interface TextSelectionIndices {
    start: number;
    end: number;
}
@Component({
    selector: 'app-text-area',
    templateUrl: 'text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextAreaComponent),
        },
    ],
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        MentionModule,
        ReactiveFormsModule,
        EmojiPickerComponent,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        CdkTextareaAutosize,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent extends ControlValueAccessorConnectorComponent implements OnInit, AfterViewInit {
    /**
     * Title
     */
    readonly title = input<string>('');

    /**
     * Placeholder
     */
    readonly placeholder = input<string>('');

    /**
     * Debounce time (in milliseconds)
     */
    readonly debounceTime = input<number>(100);

    /**
     * Placeholder
     */
    readonly maxLength = input<number>(Number.MAX_SAFE_INTEGER);

    readonly showCounterInTitle = input<boolean>(true);

    readonly showMaxLength = input<boolean>(false);

    /**
     * Required option, will add an asterix after the title
     */
    readonly required = input<boolean>(false);

    /**
     * Required option, resizable or not
     */
    readonly resizable = input<boolean>(true);

    /**
     * Error message, will add a colored border and will display the error below the input
     */
    readonly errorMessage = input<string | undefined>();

    /**
     * Default number of rows
     */
    readonly rows = input<number>(5);

    readonly maxRows = input<number>(10);

    /**
     * Is emoji picker enabled
     */
    readonly isEmojiPickerEnabled = input<boolean>(true);
    readonly closeEmojiPickerOnSelect = input<boolean>(false);

    readonly shouldAutosizeRows = input<boolean>(false);

    /**
     * Mention configuration
     */
    readonly mentionConfiguration = input<MentionConfig>({ mentions: [] });
    readonly mentionListTemplate = contentChild<TemplateRef<any>>('mentionListTemplate');
    readonly mentionSearchTerm = output<string>();

    /**
     * Text area id
     */
    readonly textAreaId = input<string>('textarea');

    readonly testId = input<string>();

    readonly shouldDisplayAiInteractionHeadBand = input<boolean>(false);
    readonly showAsPanel = input<boolean>(false);
    readonly defaultValue = input<string | undefined>();
    readonly shouldDisplayAiButton = input<boolean>(false);
    readonly isLoadingAnimationEnabled = input<boolean>(false);

    readonly disabled = input<boolean>(false);

    readonly resizeToMatchPlaceholder = input<boolean>(false);

    readonly loadingAnimationTemplate = contentChild<TemplateRef<any>>('loadingAnimationTemplate');

    readonly textAreaChange = output<string>();
    readonly enterKeyPressed = output<KeyboardEvent>();
    readonly selectionChange = output<string>();
    readonly hideAiInteractionReply = output<void>();
    readonly input = output<InputEvent>();
    readonly onFocus = output<FocusEvent>();

    readonly POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    readonly isEmptyValue = signal(false);
    readonly isFocused = signal(false);

    readonly computedPlaceholder = computed(() => (this.isLoadingAnimationEnabled() ? '' : this.placeholder()));

    readonly isTextAreaOpened = signal(false);

    readonly SvgIcon = SvgIcon;

    /**
     * For implementing ControlValueAccessor
     */

    private _previousSelectionIndices: TextSelectionIndices = { start: 0, end: 0 };

    private readonly _destroyRef = inject(DestroyRef);

    private readonly _textAreaInput = viewChild.required<ElementRef<HTMLTextAreaElement>>('textAreaInput');
    private readonly _internalValue = signal<string | null>(null);
    private readonly _cdkTextareaAutosize = viewChild(CdkTextareaAutosize);

    constructor(injector: Injector) {
        super(injector);

        effect(() => {
            const isLoadingAnimationEnabled = this.isLoadingAnimationEnabled();
            if (this.disabled()) {
                return;
            }
            if (isLoadingAnimationEnabled) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        });

        effect(() => {
            this.disabled() ? this.control.disable() : this.control.enable();
        });

        // Dynamically resize textarea to match placeholder width if there is no text
        // and resizeToMatchPlaceholder is set to true
        effect(() => {
            const resizeToMatchPlaceholder = this.resizeToMatchPlaceholder();
            if (resizeToMatchPlaceholder) {
                const placeholder = this.placeholder();
                const text = this._internalValue();
                const textArea = this._textAreaInput().nativeElement;
                if (placeholder && !text) {
                    const placeholderSize = textArea.getAttribute('placeholder')?.length;
                    if (placeholderSize) {
                        const textAreaWidth = placeholderSize * 7; // 7 is an arbitrary value for an average character width
                        textArea.style.setProperty('width', `${textAreaWidth}px`, 'important');
                    }
                } else {
                    textArea.style.setProperty('width', '');
                }
            }
        });
    }

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(
                startWith(this.control.value),
                tap((value) => this.setEmptyValue(value as string)),
                distinctUntilChanged((a, b) => isEqual(a, b)),
                debounceTime(this.debounceTime()),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((value) => {
                this._internalValue.set(value as string | null);
                this.textAreaChange.emit(value);
            });

        if (this.defaultValue()) {
            this.control.setValue(this.defaultValue());
        }
    }

    triggerResize(): void {
        afterNextRender(
            () => {
                this._cdkTextareaAutosize()?.resizeToFitContent(true);
            },
            { injector: this._injector }
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.triggerResize(), 50);
    }

    setEmptyValue(value: string): void {
        this.isEmptyValue.set(!value);
    }

    emojiSelected(event: EmojiEvent): void {
        const MAX_LENGTH = 1000;
        if (this.control?.value?.length >= (this.maxLength() ?? MAX_LENGTH) - 1 || this.control.disabled) {
            return;
        }
        const textarea = this._getTextArea(this.textAreaId());
        const currentValue = textarea.value;
        const cursorPosition: CursorPosition = {
            startPosition: textarea?.selectionStart,
            endPosition: textarea?.selectionEnd,
        };
        const textWithEmoji = createTextWithEmoji(cursorPosition, currentValue, event);
        textarea.value = textWithEmoji;
        focusAfterEmoji(textarea, cursorPosition, event);
        this.setEmptyValue(textWithEmoji);
        this.control.setValue(textWithEmoji);
    }

    pressEnterKey(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.enterKeyPressed.emit(event);
        }
    }

    onMouseUp(): void {
        const textAreaHTMLElement = this._getTextArea(this.textAreaId());
        const selectionIndices = {
            start: textAreaHTMLElement.selectionStart,
            end: textAreaHTMLElement.selectionEnd,
        };

        if (
            selectionIndices.start === this._previousSelectionIndices.start &&
            selectionIndices.end === this._previousSelectionIndices.end
        ) {
            this.selectionChange.emit('');
            return;
        }
        const selection = textAreaHTMLElement.value.substring(textAreaHTMLElement.selectionStart, textAreaHTMLElement.selectionEnd);
        this._previousSelectionIndices = selectionIndices;
        this.selectionChange.emit(selection);
    }

    emitHideAiInteractionReply(): void {
        this.hideAiInteractionReply.emit();
    }

    onInput(event: InputEvent): void {
        this.input.emit(event);
    }

    openTextArea(): void {
        this.isTextAreaOpened.set(!this.isTextAreaOpened());
    }

    private _getTextArea(textAreaId: string): HTMLTextAreaElement {
        return document.getElementById(textAreaId) as HTMLTextAreaElement;
    }
}
