import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { getDateRangeFromMalouComparisonPeriod, MalouComparisonPeriod } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { linkedSignal } from ':shared/helpers/linked-signal';

export type ComparisonPeriodOption = {
    [Key in MalouComparisonPeriod]: boolean;
};

@Component({
    selector: 'app-comparison-period-selector',
    templateUrl: './comparison-period-selector.component.html',
    styleUrls: ['./comparison-period-selector.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatCheckboxModule, TranslateModule, MatExpansionModule, MatRadioModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonPeriodSelectorComponent {
    readonly restaurantStartDate = input.required<Date>();
    readonly startDate = input.required<Date>();
    readonly endDate = input.required<Date>();
    readonly comparisonPeriod = input<MalouComparisonPeriod>();

    readonly onComparisonPeriodChange = output<MalouComparisonPeriod>();

    private readonly _experimentationService = inject(ExperimentationService);

    // TODO: Remove when the feature flag is removed
    readonly isReleaseNewCalendarSinceStartOptionEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-statistics-new-calendar-since-start-option'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-statistics-new-calendar-since-start-option'),
        }
    );

    readonly selectedComparisonPeriod = linkedSignal(() => this.comparisonPeriod());

    readonly comparisonOptions = computed(() =>
        Object.values(MalouComparisonPeriod)
            .filter((key) => (this.isReleaseNewCalendarSinceStartOptionEnabled() ? true : key !== MalouComparisonPeriod.SINCE_START))
            .map((key) => {
                const label = this._getDateRangeLabel(key);
                return {
                    key,
                    disabled: label === null,
                    label,
                };
            })
    );

    onComparePeriodChange(value: MalouComparisonPeriod): void {
        this.selectedComparisonPeriod.set(value);
        this.onComparisonPeriodChange.emit(value);
    }

    private _getDateRangeLabel(period: MalouComparisonPeriod): string | null {
        const dateFromMalouComparisonPeriod = getDateRangeFromMalouComparisonPeriod({
            comparisonPeriod: period,
            dateFilters: {
                startDate: this.startDate(),
                endDate: this.endDate(),
            },
            restaurantStartDate: this.restaurantStartDate(),
        });
        if (dateFromMalouComparisonPeriod.startDate === null || dateFromMalouComparisonPeriod.endDate === null) {
            return null;
        }

        const { startDate, endDate } = dateFromMalouComparisonPeriod;
        return `${this._formatRange(this.startDate(), this.endDate())} - ${this._formatRange(startDate, endDate)}`;
    }

    private _formatRange(startDate: Date, endDate: Date): string {
        const luxonStartDate = DateTime.fromJSDate(startDate);
        const luxonEndDate = DateTime.fromJSDate(endDate);
        return `${luxonStartDate.toFormat('dd.MM.yyyy')}/${luxonEndDate.toFormat('dd.MM.yyyy')}`;
    }
}
