<div>
    @if (!isBrandBusiness) {
        <div class="mb-[15px] flex justify-between">
            <app-keywords-score-gauge
                [ngStyle]="{ visibility: !(currentRestaurant | applySelfPure: 'isBrandBusiness') ? 'visible' : 'hidden' }"
                [text$]="text$"
                [restaurant$]="restaurant$"
                [textType$]="descriptionType$"
                [lang$]="lang$"
                [keywords$]="restaurantKeywords$">
            </app-keywords-score-gauge>
        </div>
    }

    @if (descriptionHtml) {
        <div class="malou-text-12--regular min-h-28 break-words" [innerHtml]="descriptionHtml"></div>
    } @else {
        <br />
        --
    }
</div>
