@if (!isLoading()) {
    @if (hasData()) {
        <div class="mb-4 flex w-full gap-6 md:flex-col">
            @if (shouldDisplaySemanticAnalysisForReviews()) {
                <div class="malou-simple-card flex w-[50%] break-inside-avoid flex-col gap-3 px-6 py-3 pb-12 md:w-full md:px-2">
                    <div class="flex flex-col gap-1">
                        <div class="malou-text-section-title malou-color-text-1">
                            {{ 'statistics.e_reputation.reviews_analysis.semantic_analysis' | translate }}
                        </div>
                    </div>
                    <div class="justify-content-between flex lg:flex-col-reverse">
                        <div class="h-64 min-w-0 flex-1">
                            <app-tags-bar-chart
                                [chartData]="reviewAnalysesChartData()"
                                [reviewAnalysesFilter]="null"
                                [shouldDisplayAnalysesTagClickableLabels]="true"
                                [isFromAggregatedStatistics]="false"
                                (onDefaultSelectedCategoryChanged)="onDefaultSelectedCategoryChanged($event)">
                            </app-tags-bar-chart>
                        </div>
                    </div>
                </div>
            }
            @if (shouldDisplaySemanticAnalysisTopTopics()) {
                <div class="w-[50%] md:w-full">
                    <app-semantic-analysis-top-topics
                        [segmentAnalysesTopicFilter]="segmentAnalysesTopicFilter()"
                        [positiveTopics]="segmentAnalysesPositiveTopics()"
                        [negativeTopics]="segmentAnalysesNegativeTopics()"></app-semantic-analysis-top-topics>
                </div>
            }
        </div>

        @let insights = segmentsAnalysesTopicInsightsByCategory();
        @if (insights && shouldDisplaySemanticAnalysisTopicsEvolution()) {
            <app-semantic-analysis-topic-evolution
                class="w-full"
                id="semantic-analysis-topic-evolution-chart"
                [isPdfDownload]="isPdfDownload()"
                [defaultSelectedCategory]="defaultSelectedCategory()"
                [segmentAnalysesTopicInsights]="insights"
                [segmentAnalysesTopicFilter]="segmentAnalysesTopicFilter()"
                [viewBy]="viewBy()"
                (viewByChange)="viewByChange.emit($event)"
                (favoriteStatusChanged)="onUpdateFavoriteStatus($event)"></app-semantic-analysis-topic-evolution>
        }
    } @else {
        <ng-container
            [ngTemplateOutlet]="emptyTemplate"
            [ngTemplateOutletContext]="{
                text: 'aggregated_statistics.e_reputation.reviews_analysis.semantic_analysis_not_available' | translate,
                subtext: 'aggregated_statistics.e_reputation.reviews_analysis.edit_filters' | translate,
            }">
        </ng-container>
    }
} @else {
    <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
}

<ng-template let-text="text" let-subtext="subtext" #emptyTemplate>
    <div class="my-4 flex flex-col items-center py-4">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">
            {{ text }}
        </span>
        <span class="malou-text-10--regular">
            {{ subtext }}
        </span>
    </div>
</ng-template>

<ng-template #skeletonTemplate>
    <div class="mb-4 flex w-full gap-4 md:flex-col">
        <app-skeleton class="w-full" skeletonClass="!h-[350px] secondary-bg"></app-skeleton>
        <app-skeleton class="w-full" skeletonClass="!h-[350px] secondary-bg"></app-skeleton>
    </div>
    <app-skeleton skeletonClass="!h-[350px] secondary-bg"></app-skeleton>
</ng-template>
