import {
    LightNfcResponseDto,
    NfcDto,
    NfcSnapshotDto,
    NfcWithRestaurantDto,
    UpdateStickerBodyDto,
    UpdateTotemBodyDto,
} from '@malou-io/package-dto';
import {
    BaseEntity,
    BusinessCategory,
    EntityConstructor,
    NfcsPlatformKey,
    NfcStar,
    NfcType,
    PlatformDefinitions,
    ScanPlatformKey,
    WHEEL_OF_FORTUNE_PLATFORM_KEY,
} from '@malou-io/package-utils';

import { Address } from './address';

export const FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS = '65f06ee11c369d613d108a28';

export const DEFAULT_STICKER_DISPLAY_NAME = '-';

export function getNfcWithRestaurantDisplayName(nfc: NfcWithRestaurantDto): string {
    return nfc.name ?? nfc.chipName ?? DEFAULT_STICKER_DISPLAY_NAME;
}

export interface NfcRestaurant {
    id: string;
    name?: string;
    address?: Address;
    type?: BusinessCategory;
    boosterPack?: {
        activated: boolean;
        activationDate: Date | null;
    };
}

type NfcProps = EntityConstructor<Nfc, { restaurant?: NfcRestaurant }>;
export class Nfc extends BaseEntity {
    chipName?: string;
    restaurantId: string;
    active: boolean;
    name?: string;
    platformKey: NfcsPlatformKey;
    redirectionLink: string;
    notes?: string;
    starsRedirected: NfcStar[] = [];
    restaurant?: NfcRestaurant;
    type: NfcType;

    constructor(init: NfcProps) {
        super(init);
        this.chipName = init.chipName;
        this.restaurantId = init.restaurantId;
        this.active = init.active;
        this.name = init.name;
        this.platformKey = init.platformKey;
        this.redirectionLink = init.redirectionLink;
        this.notes = init.notes;
        this.starsRedirected = init.starsRedirected ?? [];
        this.restaurant = init.restaurant
            ? {
                  id: init.restaurant.id,
                  name: init.restaurant.name,
                  type: init.restaurant.type as BusinessCategory,
                  address: new Address(init.restaurant.address),
              }
            : undefined;
        this.type = init.type;
    }

    static createFakeWheelOfFortuneNfc({
        restaurantId,
        redirectionLink,
        platformKey,
        name,
    }: {
        restaurantId: string;
        redirectionLink: string;
        platformKey: NfcsPlatformKey;
        name?: string;
    }): Nfc {
        return new Nfc({
            id: FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS,
            active: true,
            restaurantId,
            chipName: 'QR_CODE',
            name: name ?? '',
            platformKey,
            redirectionLink,
            notes: undefined,
            starsRedirected: [],
            type: NfcType.TOTEM,
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    }

    static createLightFakeWheelOfFortuneNfc({
        restaurantId,
        platformKey,
        name,
        restaurantName,
    }: {
        restaurantId: string;
        platformKey: NfcsPlatformKey;
        restaurantName: string;
        name?: string;
    }): LightNfc {
        return new LightNfc({
            id: FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS,
            chipName: 'QR_CODE',
            restaurantId,
            platformKey,
            restaurantName,
            name: name ?? '',
            type: NfcType.TOTEM,
        });
    }

    static createFakeWheelOfFortuneNfcProps({
        restaurantId,
        redirectionLink,
        platformKey,
        name,
    }: {
        restaurantId: string;
        redirectionLink: string;
        platformKey: NfcsPlatformKey;
        name?: string;
    }): Nfc {
        return new Nfc({
            id: FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS,
            active: true,
            restaurantId,
            chipName: 'QR_CODE',
            name: name ?? '',
            platformKey,
            redirectionLink,
            notes: undefined,
            starsRedirected: [],
            type: NfcType.TOTEM,
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    }

    static fromNfcPatchBodyDto(dto: UpdateTotemBodyDto, type: NfcType): Nfc {
        return new Nfc({
            id: '',
            active: dto.active,
            restaurantId: dto.restaurantId,
            chipName: dto.chipName,
            name: dto.name ?? undefined,
            platformKey: dto.platformKey,
            redirectionLink: dto.redirectionLink,
            notes: dto.notes ?? undefined,
            starsRedirected: dto.starsRedirected ?? [],
            type,
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    }

    static fromNfcDto(dto: NfcDto): Nfc {
        return new Nfc({
            id: dto.id,
            restaurantId: dto.restaurantId,
            active: dto.active,
            chipName: dto.chipName ?? undefined,
            name: dto.name ?? undefined,
            platformKey: dto.platformKey ?? undefined,
            redirectionLink: dto.redirectionLink ?? undefined,
            notes: dto.notes ?? undefined,
            starsRedirected: dto.starsRedirected ?? undefined,
            type: dto.type,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
        });
    }

    static fromNfcWithRestaurantDto(dto: NfcWithRestaurantDto): Nfc {
        return new Nfc({
            ...dto,
            name: dto.name ?? undefined,
            platformKey: dto.platformKey ?? undefined,
            redirectionLink: dto.redirectionLink ?? undefined,
            notes: dto.notes ?? undefined,
            starsRedirected: dto.starsRedirected ?? undefined,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
            restaurant: {
                id: dto.restaurant.id,
                name: dto.restaurant?.name ?? undefined,
                type: dto.restaurant?.type ?? undefined,
                address: dto.restaurant?.address ? new Address(dto.restaurant.address) : undefined,
                boosterPack: dto.restaurant?.boosterPack
                    ? {
                          activated: dto.restaurant.boosterPack.activated,
                          activationDate: dto.restaurant.boosterPack.activationDate
                              ? new Date(dto.restaurant.boosterPack.activationDate)
                              : null,
                      }
                    : undefined,
            },
        });
    }

    toNfcDto(): NfcDto {
        return {
            ...this,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }

    getNfcUrl(): string {
        return this.chipName
            ? `${window.location.origin}/nfc?id=${this.chipName}`
            : `${window.location.origin}/nfc?restaurantId=${this.restaurantId}`;
    }

    isRedirectingToWheelOfFortune(): boolean {
        return !!this.redirectionLink?.match(/wheel-of-fortune/);
    }

    canBeRated(): boolean {
        if (!this.platformKey || this.isRedirectingToWheelOfFortune()) {
            return false;
        }
        return !PlatformDefinitions.getPlatformKeysNotRatedForTotems().includes(this.platformKey);
    }

    isTotem(): boolean {
        return this.type === NfcType.TOTEM;
    }

    isSticker(): boolean {
        return this.type === NfcType.STICKER;
    }

    getRestaurantName(): string | undefined {
        return this.restaurant?.name;
    }
}

type NfcSnapshotProps = EntityConstructor<NfcSnapshot, { restaurant?: NfcRestaurant }>;
export class NfcSnapshot extends BaseEntity {
    chipName?: string;
    restaurantId: string;
    active: boolean;
    name?: string;
    platformKey: ScanPlatformKey;
    redirectionLink: string;
    notes?: string;
    starsRedirected: NfcStar[];
    restaurant?: NfcRestaurant;
    type: NfcType;

    constructor(init: NfcSnapshotProps) {
        super(init);
        this.chipName = init.chipName;
        this.restaurantId = init.restaurantId;
        this.active = init.active;
        this.name = init.name;
        this.platformKey = init.platformKey;
        this.redirectionLink = init.redirectionLink;
        this.notes = init.notes;
        this.starsRedirected = init.starsRedirected;
        this.type = init.type;
        this.restaurant = init.restaurant;
    }

    static createWheelOfFortuneNfcSnapshot({
        restaurantId,
        redirectionLink,
        platformKey,
        name,
    }: {
        restaurantId: string;
        redirectionLink: string;
        platformKey: ScanPlatformKey;
        name?: string;
    }): NfcSnapshot {
        const nfc = Nfc.createFakeWheelOfFortuneNfcProps({
            restaurantId,
            redirectionLink,
            platformKey: WHEEL_OF_FORTUNE_PLATFORM_KEY,
            name,
        });
        return new NfcSnapshot({
            ...nfc,
            platformKey,
            restaurant: {
                id: restaurantId,
            },
        });
    }

    static fromNfcSnapshotDto(dto: NfcSnapshotDto): NfcSnapshot {
        return new NfcSnapshot({
            ...dto,
            name: dto.name ?? undefined,
            platformKey: dto.platformKey ?? undefined,
            redirectionLink: dto.redirectionLink ?? undefined,
            notes: dto.notes ?? undefined,
            starsRedirected: dto.starsRedirected ?? undefined,
            type: dto.type,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
        });
    }

    toNfcSnapshotDto(): NfcSnapshotDto {
        return {
            ...this,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }

    isRedirectingToWheelOfFortune(): boolean {
        return !!this.redirectionLink?.match(/wheel-of-fortune/);
    }
}
export class NfcWithStats extends Nfc {
    scanCount: number;

    constructor(init: NfcProps, stats: { scanCount: number }) {
        super(init);
        this.scanCount = stats.scanCount;
    }

    static fromNfcWithRestaurantDtoAndStats(dto: NfcWithRestaurantDto, stats: { scanCount: number }): NfcWithStats {
        return new NfcWithStats(
            {
                ...dto,
                name: dto.name ?? undefined,
                platformKey: dto.platformKey ?? undefined,
                redirectionLink: dto.redirectionLink ?? undefined,
                notes: dto.notes ?? undefined,
                starsRedirected: dto.starsRedirected ?? undefined,
                createdAt: new Date(dto.createdAt),
                updatedAt: new Date(dto.updatedAt),
                restaurant: {
                    id: dto.restaurant.id,
                    name: dto.restaurant?.name ?? undefined,
                    type: dto.restaurant?.type ?? undefined,
                    address: dto.restaurant?.address ? new Address(dto.restaurant.address) : undefined,
                },
            },
            stats
        );
    }

    toUpdateTotemBodyDto(): UpdateTotemBodyDto {
        return {
            active: this.active,
            chipName: this.chipName,
            restaurantId: this.restaurantId,
            name: this.name,
            platformKey: this.platformKey,
            redirectionLink: this.redirectionLink,
            notes: this.notes,
            starsRedirected: this.starsRedirected,
        };
    }

    toUpdateStickerBodyDto(): UpdateStickerBodyDto {
        return {
            active: this.active,
            restaurantId: this.restaurantId,
            platformKey: this.platformKey,
            redirectionLink: this.redirectionLink,
            notes: this.notes,
            starsRedirected: this.starsRedirected,
        };
    }
}

// ------------------ NFC CLASS USED FOR AGGREGATED INSIGHTS FILTER ------------------

export class LightNfc {
    id: string;
    chipName?: string;
    restaurantId: string;
    restaurantName: string;
    name?: string;
    type: NfcType;

    constructor(init: LightNfcResponseDto & { name?: string }) {
        this.id = init.id;
        this.chipName = init.chipName;
        this.restaurantId = init.restaurantId;
        this.restaurantName = init.restaurantName;
        this.name = init.name ?? undefined;
        this.type = init.type;
    }

    getNfcName(): string {
        return this.name ?? this.chipName ?? DEFAULT_STICKER_DISPLAY_NAME;
    }

    getDisplayName(): string {
        return `${this.getNfcName()} - ${this.restaurantName}`;
    }

    isTotem(): boolean {
        return this.type === NfcType.TOTEM;
    }
}
