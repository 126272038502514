import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const transformToReelParamsValidator = z
    .object({
        post_id: objectIdValidator,
    })
    .transform((data) => ({ postId: data.post_id }));
export type TransformToReelParamsDto = z.infer<typeof transformToReelParamsValidator>;
