import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MediaType } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { VideoThumbnailSliderComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/reel-media/video-thumbnail-slider/video-thumbnail-slider.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { createVideoElement, getVideoCoverUrl, pickImagesFromVideo } from ':shared/helpers/video-cover-url';

export type Thumbnail = { type: 'custom'; media: EditionMedia } | { type: 'videoFrame'; thumbnailOffsetTimeInMs: number };

@Component({
    selector: 'app-reel-media',
    templateUrl: './reel-media.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, VideoThumbnailSliderComponent, TranslateModule, MalouSpinnerComponent],
})
export class ReelMediaComponent {
    /** Must be a video (with type set to MediaType.PHOTO) */
    public readonly media = input.required<(EditionMedia & { type: MediaType.VIDEO }) | undefined>();

    public readonly thumbnail = input.required<Thumbnail | undefined>();

    public readonly isLoading = input.required<boolean>();
    readonly isReadonly = input<boolean>(false);

    public readonly openThumbnailFilePicker = output();
    public readonly importThumbnailFromGallery = output();
    public readonly deleteMedia = output();

    /**
     * After the user has moved the slider, this output emits the time of the frame to
     * capture as a floating point number between 0 (begin) and 1 (end).
     */
    public readonly onThumbnailSelectedFromVideo = output<number>();

    readonly timelinePreviewUrls = signal<string[]>([]);

    /** between 0 and 1 */
    readonly timelineLoadingProgress = signal<number | undefined>(0);

    readonly sliderThumbnailUrl = signal<string | undefined>(undefined);

    readonly thumbnailCursorPosition = computed((): number | undefined => {
        const thumbnail = this.thumbnail();
        if (!thumbnail) {
            return 0;
        }
        const media = this.media();
        if (thumbnail.type === 'custom' || !media?.duration) {
            return undefined;
        }
        return thumbnail.thumbnailOffsetTimeInMs / media.duration / 1000;
    });

    private _videoUrl = computed(() => {
        const media = this.media();
        return media?.videoUrl;
    });

    constructor() {
        // Generate the thumbnail when the cursor is moved or when the media changes
        effect(() => {
            const position = this.thumbnailCursorPosition();
            const media = this.media();
            if (position === undefined || !media?.duration) {
                return;
            }

            const video = createVideoElement(media.videoUrl);
            getVideoCoverUrl({
                video,
                resolutionPx: 200,
                timestampSeconds: position * media.duration,
            }).then((newPicture: { url: string; dimensions: { width: number; height: number } }): void => {
                this.sliderThumbnailUrl.update((oldUrl: string): string => {
                    if (oldUrl) {
                        window.URL.revokeObjectURL(oldUrl);
                    }
                    return newPicture.url;
                });
            });
            video.remove();
        });

        // Generate the pictures of the timeline of the slider the after the compenent is created
        effect(
            (onCleanup) => {
                const videoUrl = this._videoUrl();
                if (!videoUrl) {
                    return;
                }

                this.timelineLoadingProgress.set(0);

                pickImagesFromVideo({
                    videoUrl,
                    resolutionPx: 200,
                    numberOfImages: 5,
                    onProgress: (progress) => {
                        this.timelineLoadingProgress.set(progress);
                    },
                }).then((urls) => {
                    this.timelineLoadingProgress.set(undefined);
                    this.timelinePreviewUrls.set(urls);
                });

                onCleanup(() => {
                    for (const url of this.timelinePreviewUrls()) {
                        window.URL.revokeObjectURL(url);
                    }
                    this.timelinePreviewUrls.set([]);
                });
            },
            { allowSignalWrites: true }
        );
    }
}
