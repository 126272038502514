import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    effect,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs';

import { REEL_MAX_VIDEO_SIZE } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaService } from ':modules/media/media.service';
import { MediaFileUploaderComponent } from ':shared/components/media-file-uploader/media-file-uploader.component';
import {
    MediaThumbnailPickerComponent,
    Thumbnail,
    ThumbnailType,
} from ':shared/components/media-thumbnail-picker/media-thumbnail-picker.component';
import { computeTransformDataStyles } from ':shared/helpers/compute-transfrom-data-styles';
import { isSafari } from ':shared/helpers/is-safari';
import { Media } from ':shared/models';
import { ThumbnailChange } from ':shared/models/thumbnail-change';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DEFAULT_THUMBNAIL_OFFSET_TIME = 1000;
@Component({
    selector: 'app-reels-editor',
    templateUrl: './reels-editor.component.html',
    styleUrls: ['./reels-editor.component.scss'],
    standalone: true,
    imports: [
        MediaFileUploaderComponent,
        NgClass,
        IllustrationPathResolverPipe,
        TranslateModule,
        NgTemplateOutlet,
        MatProgressBarModule,
        MatIconModule,
        MatButtonModule,
        MediaThumbnailPickerComponent,
    ],
})
export class ReelsEditorComponent implements OnInit, OnDestroy {
    private _media: Media | undefined;
    public get media(): Media | undefined {
        return this._media;
    }
    @Input()
    public set media(value: Media | undefined) {
        this._media = value;
        this.currentMedia.set(value);
    }
    @Input() thumbnail?: Media | undefined;
    @Input() thumbnailOffsetTimeInMs?: number | undefined | null = DEFAULT_THUMBNAIL_OFFSET_TIME;
    @Input() disabled = false;

    @Output() fileChanged = new EventEmitter<Media[]>();
    @Output() mediaSelected = new EventEmitter<Media[]>();
    @Output() thumbnailChange: EventEmitter<ThumbnailChange> = new EventEmitter<ThumbnailChange>();

    readonly thumbnailObject: WritableSignal<Thumbnail | undefined> = signal(undefined);
    readonly maxVideoSize = REEL_MAX_VIDEO_SIZE;
    readonly maxNumberOfFiles = 1;
    readonly isSafari = isSafari;

    /** `true` if the media was just uploaded, `false` if the media was already existing */
    private readonly _justUploaded = signal(false);

    readonly draggingOver = signal(false);
    readonly currentMedia = signal<Media | undefined>(undefined);

    /** Increases progressively from 0 to 1 while the media is loading. */
    readonly computedProgress = computed(() => {
        const upload = this._uploadProgress();
        if (upload !== 1) {
            if (this._justUploaded()) {
                return upload / 2;
            }
            return upload;
        }
        const thumbnails = this._thumbnailPickerLoadingProgress();
        if (thumbnails !== 1) {
            if (!this.currentMedia()) {
                return 1;
            }
            if (this._justUploaded()) {
                return 0.5 + thumbnails / 2;
            }
            return thumbnails;
        }
        return 1;
    });

    readonly SvgIcon = SvgIcon;

    /** Increases progressively from 0 to 1 while the thumbnail picker is loading. */
    private readonly _thumbnailPickerLoadingProgress = signal(0);

    /**
     * 1 means that no upload is in progress. If a media is being uploaded this number
     * increases from 0 to 1 progressively.
     */
    private readonly _uploadProgress = signal(1);

    private readonly _containerElement = viewChild<ElementRef<HTMLDivElement>>('containerElement');
    private readonly _videoPlayerElement = viewChild<ElementRef<HTMLVideoElement>>('videoPlayerElement');

    private readonly _toastService = inject(ToastService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _mediaService = inject(MediaService);

    private readonly _containerSizeChanged = signal(0);
    private readonly _resizeObserver = new ResizeObserver(() => {
        this._containerSizeChanged.update((e) => ++e);
    });

    get currentMediaUrl(): string | undefined {
        return this.currentMedia()?.getMediaUrl();
    }

    constructor() {
        effect(() => {
            this._containerSizeChanged(); // just a  trigger
            const media = this.currentMedia();
            const dimensions = media?.dimensions?.original;
            const transformData = media?.transformData;
            const containerElement = this._containerElement()?.nativeElement;
            const videoPlayerElement = this._videoPlayerElement()?.nativeElement;
            if (dimensions && transformData && videoPlayerElement && containerElement) {
                computeTransformDataStyles(dimensions, transformData, containerElement, videoPlayerElement);
                return;
            }
            if (videoPlayerElement) {
                videoPlayerElement.style.width = '100%';
                videoPlayerElement.style.height = '100%';
                videoPlayerElement.style.transform = '';
            }
        });
        effect(() => {
            this._resizeObserver.disconnect();
            const containerElement = this._containerElement()?.nativeElement;
            if (containerElement) {
                this._resizeObserver.observe(containerElement);
            }
        });
    }

    ngOnInit(): void {
        this.currentMedia.set(this.media);

        if (this.thumbnailOffsetTimeInMs === undefined || this.thumbnailOffsetTimeInMs === null) {
            if (this.thumbnail) {
                this.thumbnailObject.set({
                    type: ThumbnailType.CUSTOM,
                    media: this.thumbnail,
                });
            }
        } else {
            this.thumbnailObject.set({
                type: ThumbnailType.VIDEO_FRAME,
                timestampSeconds: Math.round(this.thumbnailOffsetTimeInMs / 1000),
            });
        }
    }

    ngOnDestroy(): void {
        this._resizeObserver.disconnect();
    }

    onUploadFinished(createdMedias: Media[]): void {
        const newMedia = createdMedias[0];
        this._uploadProgress.set(1);
        this.currentMedia.set(newMedia);
        this.fileChanged.emit(createdMedias);
        this._thumbnailPickerLoadingProgress.set(0);
    }

    onUploadStarted(): void {
        this._justUploaded.set(true);
        this._uploadProgress.set(0);
    }

    processError(error: Error | string): void {
        this._uploadProgress.set(1);
        const errorMessage = typeof error === 'string' ? error : (error.message ?? error.toString());
        this._toastService.openErrorToast(errorMessage);
    }

    dragOver(): void {
        if (!this.currentMedia()) {
            this.draggingOver.set(true);
        }
    }

    dragLeave(): void {
        this.draggingOver.set(false);
    }

    openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: false,
                    filter: MediaPickerFilter.ONLY_VIDEO,
                    maxVideoSize: this.maxVideoSize,
                },
            })
            .afterClosed()
            .pipe(
                filter((medias) => !!medias && medias.length > 0),
                switchMap((medias) => {
                    this.currentMedia.set(medias[0]);
                    this.mediaSelected.emit(medias);
                    return this._mediaService.fetchMediaDescription(medias?.map((media) => media.id));
                })
            )
            .subscribe();
    }

    onUploadProgressChanged(progress: number): void {
        this._uploadProgress.set(Math.min(progress, 99) / 100);
    }

    playVideo(): void {
        const videoPlayerElement = this._videoPlayerElement()?.nativeElement;
        if (!videoPlayerElement) {
            return;
        }
        if (videoPlayerElement?.paused) {
            videoPlayerElement.play();
        } else {
            videoPlayerElement.pause();
        }
    }

    muteUnmuteVideo(): void {
        const videoPlayerElement = this._videoPlayerElement()?.nativeElement;
        if (!videoPlayerElement) {
            return;
        }
        videoPlayerElement.muted = !videoPlayerElement.muted;
    }

    isVideoMuted(): boolean {
        return !!this._videoPlayerElement()?.nativeElement?.muted;
    }

    isVideoPlaying(): boolean {
        return !this._videoPlayerElement()?.nativeElement?.paused;
    }

    removeMedia(): void {
        this.currentMedia.set(undefined);
        this.mediaSelected.emit([]);
    }

    onThumbnailChange(thumbnail: Thumbnail): void {
        this.thumbnailObject.set(thumbnail);
        if (thumbnail.type === ThumbnailType.VIDEO_FRAME) {
            if (thumbnail.url) {
                this.thumbnailChange.emit({
                    thumbnailOffsetTimeAndUrl: {
                        thumbnailOffsetTimeInMs: thumbnail.timestampSeconds * 1000,
                        thumbnailUrl: thumbnail.url,
                    },
                });
            }
        } else {
            this.thumbnailChange.emit({ thumbnail: thumbnail.media });
        }
    }

    onThumbnailPickerLoading(progress: number): void {
        if (progress === 1) {
            this._justUploaded.set(false);
        }
        this._thumbnailPickerLoadingProgress.set(progress);
    }
}
