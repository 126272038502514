import { Action, createReducer, on } from '@ngrx/store';

import { KeywordStatsV3Dto } from '@malou-io/package-dto';
import { KeywordSearchImpressionsType, MalouComparisonPeriod, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { Keyword, MalouDateFilters, MalouPeriod, MalouTimeScalePeriod } from ':shared/models';

import * as StatisticsActions from './statistics.actions';
import { StatisticsState } from './statistics.interface';

const malouDateFilters = new MalouDateFilters();

export const initialState: StatisticsState = {
    filters: {
        dates: malouDateFilters.getFilter({ period: MalouPeriod.DEFAULT }),
        platforms: {
            [PlatformFilterPage.E_REPUTATION]: [],
            [PlatformFilterPage.SOCIAL_NETWORKS]: [],
        },
        totemIds: [],
        timeScale: MalouTimeScalePeriod.LAST_SIX_MONTHS,
        monthYearPeriod: MalouDateFilters.getDefaultMonthYearPeriod(),
        comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
        isFiltersLoaded: false,
    },
    data: {
        keywords: {
            keywords: [],
            stats: [],
        },
        topKeywordSearchImpressions: {
            [KeywordSearchImpressionsType.BRANDING]: [],
            [KeywordSearchImpressionsType.DISCOVERY]: [],
        },
        actions: {},
        reviews: [],
        postsWithInsights: [],
        followers: {},
        followersV2: {},
        storiesAndInsights: {
            stories: [],
            insights: [],
        },
        reviewsWithAnalysis: [],
        platformsRatings: undefined,
        reviewsRatingsEvolution: undefined,
        semanticAnalysisTopics: undefined,
        semanticAnalysisDetails: undefined,
        reviewsRatingsTotal: [],
        boosterStatsData: undefined,
        boosterStatsDataV2: undefined,
        privateReviewsData: [],
    },
};

const statisticsReducer = createReducer(
    initialState,
    on(StatisticsActions.initializeState, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...data,
            isFiltersLoaded: true,
        },
    })),
    on(StatisticsActions.editDates, (state, { dates }) => ({
        ...state,
        filters: {
            ...state.filters,
            dates: dates,
        },
    })),
    on(StatisticsActions.editComparisonPeriod, (state, { comparisonPeriod }) => ({
        ...state,
        filters: {
            ...state.filters,
            comparisonPeriod,
        },
    })),
    on(StatisticsActions.editDatesAndComparisonPeriod, (state, { dates, comparisonPeriod }) => ({
        ...state,
        filters: {
            ...state.filters,
            dates,
            comparisonPeriod,
        },
    })),
    on(StatisticsActions.editPlatforms, (state, { page, platforms }: { page: PlatformFilterPage; platforms: PlatformKey[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            platforms: {
                ...state.filters.platforms,
                [page]: platforms,
            },
        },
    })),
    on(StatisticsActions.editTotems, (state, { totemIds }: { totemIds: string[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            totemIds,
        },
    })),
    on(StatisticsActions.resetState, () => initialState),
    on(StatisticsActions.editKeywords, (state, keywords: { keywords: Keyword[]; stats: KeywordStatsV3Dto[] }) => ({
        ...state,
        data: { ...state.data, keywords },
    })),
    on(StatisticsActions.editActionsRawData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            actions: data,
        },
    })),
    on(StatisticsActions.editReviewsWithAnalysisData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            reviewsWithAnalysis: data,
        },
    })),
    on(StatisticsActions.editPlatformsRatingsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            platformsRatings: data,
        },
    })),
    on(StatisticsActions.editPostsWithInsightsRawData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            postsWithInsights: data,
        },
    })),
    on(StatisticsActions.editFollowersRawData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            followers: data,
        },
    })),
    on(StatisticsActions.editFollowersRawDataV2, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            followersV2: data,
        },
    })),
    on(StatisticsActions.editStoriesAndInsightsRawData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            storiesAndInsights: data,
        },
    })),
    on(StatisticsActions.editReviewsRatingsEvolutionData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            reviewsRatingsEvolution: data,
        },
    })),
    on(StatisticsActions.editSemanticAnalysisTopicsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            semanticAnalysisTopics: data,
        },
    })),
    on(StatisticsActions.editSemanticAnalysisDetailsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            semanticAnalysisDetails: data,
        },
    })),
    on(StatisticsActions.editReviewsRatingsTotalData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            reviewsRatingsTotal: data,
        },
    })),
    on(StatisticsActions.editBoosterStatsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            boosterStatsData: data,
        },
    })),
    on(StatisticsActions.editBoosterStatsDataV2, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            boosterStatsDataV2: data,
        },
    })),
    on(StatisticsActions.editPrivateReviewData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            privateReviewsData: data,
        },
    })),
    on(StatisticsActions.editTimeScale, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            timeScale: data,
        },
    })),
    on(StatisticsActions.editMonthYearPeriod, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            monthYearPeriod: data,
        },
    })),
    on(StatisticsActions.editTopKeywordSearchImpressions, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            topKeywordSearchImpressions: data,
        },
    }))
);

export function reducer(state: StatisticsState, action: Action): StatisticsState {
    return statisticsReducer(state, action);
}
