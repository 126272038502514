<div
    class="flex overflow-hidden rounded-[5px] border border-malou-color-border-primary bg-malou-color-background-white transition-all duration-500"
    [ngClass]="{ 'border-[4px] border-malou-color-purple--light': isHighlighted() }">
    <div class="h-[105px] w-[105px] shrink-0">
        <app-social-post-media-item
            [media]="post().media"
            [icon]="post() | applySelfPure: 'getPostTypeIcon'"
            [link]="post().socialLink"
            [tag]="feedbackCountTag()"
            [isReadonly]="isReadonly()"
            (refreshMedia)="onRefreshPost()"
            (tagClick)="onFeedbackCountTagClick()"></app-social-post-media-item>
    </div>

    <div class="flex min-w-0 grow flex-col gap-3 px-3 pb-1 pt-2">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>

        @if (post().published === PostPublicationStatus.ERROR) {
            <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
        } @else {
            <div
                class="malou-text-10--regular line-clamp-2 italic leading-5 text-malou-color-text-2"
                [innerHTML]="textAndHashtags() | htmlTag"></div>
        }
    </div>
</div>

<ng-template #errorTemplate>
    @let mostRecentPublicationErrorCode = post().mostRecentPublicationErrorCode ?? PublicationErrorCode.UNKNOWN_ERROR;
    <div class="w-fit rounded-md bg-malou-color-background-error px-3 py-1 italic">
        <span class="malou-text-10--regular text-malou-color-state-error">{{
            mostRecentPublicationErrorCode | enumTranslate: 'publication_error_code'
        }}</span>
        <span
            class="malou-text-10--semibold ml-1 text-malou-color-primary"
            [ngClass]="{ 'cursor-pointer': !isReadonly() }"
            (click)="!isReadonly() && onErrorCtaClick(mostRecentPublicationErrorCode)"
            >{{ mostRecentPublicationErrorCode | enumTranslate: 'publication_error_code_cta' }}</span
        >
    </div>
</ng-template>

<ng-template #headerTemplate>
    <div class="flex items-center justify-between">
        <div class="flex gap-x-3">
            <div class="flex gap-x-1">
                @for (platformKey of post().platformKeys; track platformKey) {
                    <app-platform-logo imgClasses="h-6 w-6" [logo]="platformKey"></app-platform-logo>
                }
            </div>

            <div
                class="malou-text-10--medium flex h-6 items-center gap-x-2 rounded-[3px] px-2"
                [ngClass]="{
                    'bg-malou-color-background-success text-malou-color-text-green': post().published === PostPublicationStatus.PUBLISHED,
                    'bg-malou-color-background-warning text-malou-color-state-warn':
                        post().published === PostPublicationStatus.PENDING && !post().isPublishing,
                    'bg-malou-color-background-lavender-light text-malou-color-chart-purple--accent':
                        post().published === PostPublicationStatus.PENDING && post().isPublishing,
                    'bg-malou-color-background-pending text-malou-color-text-purple--light':
                        post().published === PostPublicationStatus.DRAFT,
                    'bg-malou-color-background-error text-malou-color-state-error': post().published === PostPublicationStatus.ERROR,
                }">
                @if (post().published === PostPublicationStatus.PENDING && post().isPublishing) {
                    <app-malou-spinner size="xs" color="#AC32B7"></app-malou-spinner>
                    <div>{{ 'social_post.is_publishing' | translate }}</div>
                } @else {
                    {{ post().published | enumTranslate: 'publication_status' }}
                }
            </div>

            @if (post() | applySelfPure: 'getPostDate'; as postDate) {
                <app-post-date-picker
                    [selectedDate]="postDate"
                    [disabled]="!(post() | applySelfPure: 'canSchedule') || isReadonly()"
                    (selectedDateChange)="onPostDateChange($event)"></app-post-date-picker>
            }
        </div>

        <div class="flex items-center">
            @if (authorInitials(); as authorInitials) {
                <div
                    class="malou-text-10--bold flex h-[24px] min-w-[24px] items-center justify-center rounded-full bg-malou-color-background-dark px-1 text-malou-color-primary"
                    [matTooltip]="'social_post.author' | translate: { author: authorName() }">
                    {{ authorInitials }}
                </div>
            }
            @if (!isReadonly()) {
                <ng-container [ngTemplateOutlet]="actionsButtonTemplate"></ng-container>
            }
        </div>
    </div>
</ng-template>

<ng-template #actionsButtonTemplate>
    <div class="flex items-center">
        <button class="malou-btn-icon flex items-center" mat-icon-button [matMenuTriggerFor]="actionsMenu">
            <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
        </button>
        <mat-menu class="malou-mat-menu malou-box-shadow !rounded-md" #actionsMenu="matMenu">
            @if (post() | applySelfPure: 'canEdit') {
                <button class="flex items-center" mat-menu-item (click)="onUpdatePost()">
                    <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                    <span class="malou-text-15--regular">{{ 'social_post.edit_post' | translate }}</span>
                </button>
            }
            <button class="flex place-items-center" mat-menu-item [matMenuTriggerFor]="duplicateActions">
                <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                <div class="flex w-full items-center justify-between">
                    <span class="malou-text-15--regular">{{ 'social_post.duplicate_post' | translate }}</span>
                    <mat-icon class="!mr-0 ml-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                </div>
            </button>
            @if (post() | applySelfPure: 'canOpenSocialLink') {
                <button class="flex items-center" mat-menu-item (click)="onOpenSocialLink()">
                    <mat-icon class="!mr-4 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                    <span class="malou-text-15--regular">{{ 'social_post.view_post' | translate }}</span>
                </button>
            }
            @if (post() | applySelfPure: 'canDelete') {
                <button class="flex items-center" mat-menu-item (click)="onDeletePost()">
                    <mat-icon class="!mr-4 !h-4 !w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    <span class="malou-text-15--regular">{{ 'social_post.delete_post' | translate }}</span>
                </button>
            }
        </mat-menu>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #duplicateActions="matMenu">
    <button mat-menu-item (click)="onDuplicatePost(DuplicationDestination.HERE, PostSource.SOCIAL)">
        <span class="malou-text-15--regular">{{ 'common.here' | translate }}</span>
    </button>
    <div [matTooltip]="(isGoogleConnected$ | async) ? '' : ('social_post.connect_google' | translate)">
        <button
            mat-menu-item
            [disabled]="!(isGoogleConnected$ | async)"
            (click)="onDuplicatePost(DuplicationDestination.HERE, PostSource.SEO)">
            <span class="malou-text-15--regular">{{ 'posts.duplicate_in_seo' | translate }}</span>
        </button>
    </div>
    <button mat-menu-item (click)="onDuplicatePost(DuplicationDestination.OUT, PostSource.SOCIAL)">
        <span class="malou-text-15--regular">{{ 'common.to_other_venues' | translate }}</span>
    </button>
</mat-menu>
