import { z } from 'zod';

import { isValidUrl } from '@malou-io/package-utils';

export const objectIdValidator = z.string().regex(/^[0-9a-fA-F]{24}$/);

export const booleanAsStringValidator = z.string().refine((value) => ['true', 'false'].includes(value));

export const dayMonthYearValidator = z.object({
    day: z.number().int().min(1).max(31),
    month: z.number().int().min(1).max(12),
    year: z.number().int().min(1900).max(2100),
});

export const monthYearValidator = z.object({
    month: z.number().int().min(1).max(12),
    year: z.number().int().min(1900).max(2100),
});
// ------------------------------------------------------------------------------------------
export const urlValidator = (options?: { allowEmpty: boolean }) =>
    z.string().refine((url) => isValidUrl(url, { allowEmpty: options?.allowEmpty ?? false }));
