import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs';

import { PlatformKey, PostPublicationStatus, PostSource, PublicationErrorCode } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { PostDatePickerComponent } from ':modules/posts-v2/post-date-picker/post-date-picker.component';
import { SocialPostMediaItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-media-item/social-post-media-item.component';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-social-post-item',
    templateUrl: './social-post-item.component.html',
    styleUrls: ['./social-post-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        SocialPostMediaItemComponent,
        PlatformLogoComponent,
        PostDatePickerComponent,
        ApplySelfPurePipe,
        AsyncPipe,
        EnumTranslatePipe,
        MalouSpinnerComponent,
        HtmlTagPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostItemComponent {
    readonly post = input.required<SocialPostItem>();
    readonly isHighlighted = input.required<boolean>();
    readonly isReadonly = input.required<boolean>();
    readonly updatePost = output<{ postId: string; shouldOpenFeedbacks?: boolean }>();
    readonly deletePost = output<{ postId: string; isPublishedOnFacebook: boolean }>();
    readonly refreshPost = output<{ postId: string }>();
    readonly duplicatePost = output<{ postId: string; destination: DuplicationDestination; postDestination: PostSource }>();
    readonly postDateChange = output<{ postId: string; date: Date }>();

    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);
    private readonly _router = inject(Router);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsV2Service = inject(SocialPostsV2Service);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _store = inject(Store);

    readonly textAndHashtags = computed((): string => {
        const post = this.post();
        const hashtagsText = post.getHashtagsText();
        return hashtagsText && post.published !== PostPublicationStatus.PUBLISHED
            ? `${post.text}<br/>${post.getHashtagsText()}`
            : post.text;
    });

    readonly feedbackCountTag = computed((): string | undefined => {
        const feedbackCount = this.post().feedbackMessageCount;
        return feedbackCount ? this._pluralTranslatePipe.transform('social_post.feedback_count_tag', feedbackCount) : undefined;
    });

    readonly authorInitials = computed((): string => {
        const author = this.post().author;
        return author ? author.getInitials() : '';
    });

    readonly authorName = computed((): string => {
        const author = this.post().author;
        return author ? author.getFullName() : '';
    });

    readonly isGoogleConnected$ = this._store
        .select(selectPermissionsState)
        .pipe(
            map((permissionsState) => permissionsState.data.some((permission) => permission.key === PlatformKey.GMB && permission.isValid))
        );

    readonly SvgIcon = SvgIcon;
    readonly PostPublicationStatus = PostPublicationStatus;
    readonly PublicationErrorCode = PublicationErrorCode;
    readonly DuplicationDestination = DuplicationDestination;
    readonly PostSource = PostSource;

    onErrorCtaClick(mostRecentPublicationErrorCode: PublicationErrorCode): void {
        switch (mostRecentPublicationErrorCode) {
            case PublicationErrorCode.CONNECTION_EXPIRED:
                const reconnectPlatform = this.post().platformKeys.length ? this.post().platformKeys[0] : undefined;
                this._router.navigate(['restaurants', this._restaurantsService.currentRestaurant._id, 'settings', 'platforms'], {
                    queryParams: { reconnectPlatform },
                });
                return;
            case PublicationErrorCode.USER_MISSING_APPROPRIATE_ROLE:
                window.open('https://help.malou.io/en/articles/3418242', '_blank');
                return;
            case PublicationErrorCode.USER_NEEDS_TO_LOG_IN:
                window.open('https://www.facebook.com', '_blank');
                return;
            case PublicationErrorCode.USER_NEEDS_TO_LOG_IN_TO_IG_APP:
            case PublicationErrorCode.UNKNOWN_ERROR:
                this._socialPostsV2Service.publishPostNow$({ postId: this.post().id }).subscribe({
                    next: () => {
                        const now = DateTime.now().toJSDate();
                        const postWithNewReference = this.post().copyWith({
                            published: PostPublicationStatus.PENDING,
                            plannedPublicationDate: now,
                            sortDate: now,
                            isPublishing: true, // We anticipate the post to be publishing for UX reasons
                        });
                        this._socialPostsContext.upsertSocialPost(postWithNewReference);
                        this._socialPostsContext.shouldFetchFilterOptionsCount$.next();
                    },
                    error: (err) => {
                        console.error(err);
                        const message = this._translateService.instant('social_post_item.publish_now_error');
                        this._toastService.openErrorToast(message);
                    },
                });
        }
    }

    onOpenSocialLink(): void {
        const link = this.post().socialLink;
        if (link) {
            window.open(link, '_blank');
        }
    }

    onUpdatePost(): void {
        this.updatePost.emit({ postId: this.post().id });
    }

    onDeletePost(): void {
        const post = this.post();
        this.deletePost.emit({
            postId: post.id,
            isPublishedOnFacebook:
                post.published === PostPublicationStatus.PUBLISHED &&
                post.platformKeys.length === 1 &&
                post.platformKeys[0] === PlatformKey.FACEBOOK,
        });
    }

    onRefreshPost(): void {
        this.refreshPost.emit({ postId: this.post().id });
    }

    onFeedbackCountTagClick(): void {
        this.updatePost.emit({ postId: this.post().id, shouldOpenFeedbacks: true });
    }

    onDuplicatePost(destination: DuplicationDestination, postDestination: PostSource): void {
        this.duplicatePost.emit({ postId: this.post().id, destination, postDestination });
    }

    onPostDateChange(date: Date | null): void {
        if (!date) {
            return;
        }
        this.postDateChange.emit({ postId: this.post().id, date });
    }
}
