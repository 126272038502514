import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { AggregatedStatisticsComponent } from './aggregated-statistics.component';
import { BoostersComponent } from './boosters/boosters.component';
import { EReputationComponent } from './e-reputation/e-reputation.component';
import { RoiComponent } from './roi/roi.component';
import { SeoComponent } from './seo/seo.component';
import { SocialNetworksComponent } from './social-networks/social-networks.component';

export const AGGREGATED_STATISTICS_ROUTES: Routes = [
    {
        path: '',
        component: AggregatedStatisticsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'seo',
            },
            {
                path: 'roi',
                component: RoiComponent,
                data: { routePath: RoutePath.EARNINGS },
            },
            {
                path: 'seo',
                component: SeoComponent,
                data: { routePath: RoutePath.SEO_STATS },
            },
            {
                path: 'e-reputation',
                component: EReputationComponent,
                data: { routePath: RoutePath.E_REPUTATION_STATS },
            },
            {
                path: 'social-networks',
                component: SocialNetworksComponent,
                data: { routePath: RoutePath.SOCIAL_NETWORKS_STATS },
            },
            {
                path: 'totems',
                redirectTo: 'boosters',
                data: { routePath: RoutePath.TOTEMS },
            },
            {
                path: 'boosters',
                component: BoostersComponent,
                data: { routePath: RoutePath.BOOSTERS_STATS },
            },
        ],
    },
];
