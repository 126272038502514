import { ReviewWithTranslationsResponseDto, ScanWithNfcDto } from '@malou-io/package-dto';
import {
    ApplicationLanguage,
    CurrencyCode,
    ITranslations,
    PlatformDefinitions,
    PlatformKey,
    ReviewAnalysisSentiment,
    ReviewAnalysisStatus,
    ReviewAnalysisTag,
    ReviewCannotReplyExplanation,
    SemanticAnalysisFetchStatus,
    TranslationSource,
} from '@malou-io/package-utils';

import { formatStringDate } from ':shared/helpers';
import { IReview } from ':shared/interfaces';
import { Email } from ':shared/models/campaign';
import { Client } from ':shared/models/client';
import { MenuItemReview, Review, ReviewSocialAttachment } from ':shared/models/review';
import { SemanticAnalysis } from ':shared/models/review-analyses';
import { SegmentAnalysis } from ':shared/models/segment-analysis';

export class PrivateReview implements IReview {
    _id: string;
    restaurantId: string;
    text: string;
    lang: string;
    key: PlatformKey;
    campaignId?: string;
    scanId?: string;
    socialCreatedAt: Date;
    rating: number; // must be null if no rating
    clientId: string;
    client?: Client;
    archived: boolean;
    comments: PrivateReviewReply[] = [];
    semanticAnalysis: SemanticAnalysis | null = null; // TODO: Remove everywhere when feature toggle 'release-new-semantic-analysis' is removed
    semanticAnalysisSegments?: SegmentAnalysis[] = undefined;
    semanticAnalysisFetchStatus: SemanticAnalysisFetchStatus | null = null;
    semanticAnalysisStatus?: ReviewAnalysisStatus = undefined;
    scan?: ScanWithNfcDto;
    keywordsLang?: string;
    aiRelevantBricks?: {
        text: string;
        category: string;
        translationsId?: string;
        translations?: ITranslations;
    }[];
    aiRelatedBricksCount?: number;

    couldNotSendReply = false;

    translations?: ITranslations;

    public constructor(init?: Partial<PrivateReview>) {
        Object.assign(this, init);
        this.socialCreatedAt = new Date(this.socialCreatedAt);
        if (init?.client) {
            this.client = new Client(init.client);
        }

        this.semanticAnalysisSegments = this.semanticAnalysisSegments
            ? SegmentAnalysis.sortSegmentsByPositionInText(this.semanticAnalysisSegments, init?.text ?? null)
            : undefined;
        this.semanticAnalysisSegments = this.semanticAnalysisSegments
            ? SegmentAnalysis.filterSegmentAnalysesByTag(this.semanticAnalysisSegments, ReviewAnalysisTag.OVERALL_EXPERIENCE)
            : undefined;
        this.semanticAnalysisSegments = this.semanticAnalysisSegments
            ? SegmentAnalysis.filterSegmentAnalysesBySentiment(this.semanticAnalysisSegments, ReviewAnalysisSentiment.NEUTRAL)
            : undefined;
        this.semanticAnalysisStatus = Review.mapToReviewAnalysisStatus(this);
    }

    static fromReviewWithTranslationsResponseDto(reviewDto: ReviewWithTranslationsResponseDto): PrivateReview {
        return new PrivateReview({
            ...reviewDto,
            socialCreatedAt: reviewDto.socialCreatedAt ? new Date(reviewDto.socialCreatedAt) : undefined,
            comments: reviewDto.comments.map((comment) => ({
                ...comment,
                socialUpdatedAt: comment.socialUpdatedAt ? new Date(comment.socialUpdatedAt) : undefined,
            })),
            translations: reviewDto.translations,
            semanticAnalysis: SemanticAnalysis.fromReviewAnalysisDto(reviewDto.semanticAnalysis, reviewDto.text),
            semanticAnalysisFetchStatus: reviewDto.semanticAnalysisFetchStatus,
            semanticAnalysisSegments: reviewDto.semanticAnalysisSegments?.map((segment) => new SegmentAnalysis(segment)),
        });
    }

    public getCommentOption(): null {
        return null;
    }

    public setClient(client?: Client): void {
        this.client = client;
    }

    public getFullReviewTextWithRatingTags(options?: { showTranslation: boolean; language: string }): string {
        return options?.showTranslation ? this.getTranslation(options?.language) : (this.text ?? '');
    }

    public canBeEdited(): boolean {
        return false;
    }

    public canBeReplied(): boolean {
        return !this.getCannotReplyExplanation();
    }

    /**
     * Returns the reason why the review cannot be replied to.
     * If the review can be replied to, it returns null.
     */
    public getCannotReplyExplanation(): ReviewCannotReplyExplanation | null {
        if (!this.campaignId) {
            return ReviewCannotReplyExplanation.IS_PRIVATE_FROM_TOTEM;
        }
        return null;
    }

    public canHaveMultipleReplies(): boolean {
        return true;
    }

    public getEaterTotalOrders(): number | undefined {
        return undefined;
    }

    public getOrderTotal(): number | undefined {
        return undefined;
    }

    public getOrderCurrencyCode(): CurrencyCode | undefined {
        return undefined;
    }

    public getMenuItemReviews(): MenuItemReview[] {
        return [];
    }

    getUbereatsPromotionAmountInHundredths(): number | undefined {
        return undefined;
    }

    public getNbDaysUntilCantBeAnswered(): number | null {
        return null;
    }

    public canBeRepliedBeforeTimeLimit(): boolean {
        return false;
    }

    public getReviewDate(): string | undefined {
        return formatStringDate(this.socialCreatedAt);
    }

    public getReviewOrderDate(): string | null {
        return null;
    }

    public hasAttachments(): boolean {
        return false;
    }

    public hasReply(): boolean {
        return this.comments?.length > 0;
    }

    public hasOnlyPendingComment(): boolean {
        return false;
    }

    public hasRejectedComment(): boolean {
        return false;
    }

    public isPrivate(): this is PrivateReview {
        return true;
    }

    public hasText(): boolean {
        return !!this.text?.length;
    }

    public toggleArchived(): void {
        this.archived = !this.archived;
    }

    public getDisplayName(): string {
        return this.client?.getDisplayedValue() ?? '--';
    }

    public getFirstCommentDate(): Date | undefined {
        return this.comments?.find((r) => r.socialUpdatedAt)?.socialUpdatedAt;
    }

    public hasScanId(): boolean {
        return !!this.scanId;
    }

    public getSocialAttachments(): ReviewSocialAttachment[] {
        return [];
    }

    public getWasAnsweredAutomatically(): boolean {
        return false;
    }

    public getNbDaysLeftToReply(): null {
        return null;
    }

    public getComments(): PrivateReviewReply[] {
        return this.comments;
    }

    public copyWith(review: Partial<PrivateReview>): PrivateReview {
        return new PrivateReview({
            ...this,
            ...review,
        });
    }

    public hasTranslations(lang: string): boolean {
        return !!this.translations?.[lang];
    }

    public addTranslation(text: string, language: ApplicationLanguage, source: TranslationSource): ITranslations {
        return this.translations
            ? { ...this.translations, [language]: text }
            : {
                  id: 'fakeId',
                  [language]: text,
                  language,
                  source,
              };
    }

    public getTranslation(lang: string): string {
        return this.translations?.[lang] ?? this.text;
    }

    public doesPlatformHaveAnalysis(): boolean {
        return PlatformDefinitions.getPlatformDefinition(this.key)?.hasReviewSemanticAnalysis ?? false;
    }
}

export class PrivateReviewReply {
    text: string;
    socialUpdatedAt?: Date;
    author: {
        _id: string;
        name: string;
    }; // malou user who made the comment
    templateIdUsed: string;
    content?: Email;
    isRepliedFromAggregatedView?: boolean;

    public constructor(init?: Partial<PrivateReviewReply>) {
        Object.assign(this, init);
    }
}
