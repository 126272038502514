import { PostToDuplicateDto } from '@malou-io/package-dto';
import {
    ApplicationLanguage,
    MediaType,
    PlatformKey,
    PostCallToActionType,
    PostPublicationStatus,
    PostSource,
    PostType,
    RemoveMethodsFromClass,
} from '@malou-io/package-utils';

import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { Hashtag, PostHashtags } from ':shared/models';

type IPostToDuplicate = RemoveMethodsFromClass<PostToDuplicate> & { id: string };

export class PostToDuplicate implements IPostToDuplicate {
    id: string;
    postType: PostType;
    source: PostSource;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    text: string;
    plannedPublicationDate: Date | null;
    medias: SocialPostMedia[];
    language: ApplicationLanguage | null;
    hashtags?: PostHashtags;
    callToAction?: {
        actionType: PostCallToActionType;
        url?: string;
    } | null;

    constructor(data: IPostToDuplicate) {
        this.id = data.id;
        this.postType = data.postType;
        this.source = data.source;
        this.platformKeys = data.platformKeys;
        this.published = data.published;
        this.text = data.text;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.medias = data.medias;
        this.language = data.language;
        this.hashtags = data.hashtags;
        this.callToAction = data.callToAction;
    }

    static fromDto(dto: PostToDuplicateDto): PostToDuplicate {
        return new PostToDuplicate({
            id: dto.id,
            postType: dto.postType,
            source: dto.source,
            platformKeys: dto.platformKeys,
            published: dto.published,
            text: dto.text,
            plannedPublicationDate: dto.plannedPublicationDate ? new Date(dto.plannedPublicationDate) : null,
            medias: dto.medias,
            language: dto.language,
            hashtags: dto.hashtags
                ? {
                      selected: dto.hashtags.selected.map((hashtag) => new Hashtag(hashtag)),
                      suggested: dto.hashtags.suggested.map((hashtag) => new Hashtag(hashtag)),
                  }
                : undefined,
            callToAction: dto.callToAction,
        });
    }

    containsVideo(): boolean {
        return this.medias.some((media) => media.type === MediaType.VIDEO);
    }

    getAllSmallestAttachmentUrls(): { id: string; url: string; type: MediaType }[] {
        return this.medias.map((media) => ({
            id: '', // We don't have the id of the media here, but we do not need it in our current use cases
            url: media.thumbnailUrl ?? media.url,
            type: media.type,
        }));
    }

    removeHashtagsFromText(): string {
        let textWithNoHashtags = this.text ?? '';

        if (!this.hashtags) {
            return textWithNoHashtags.trim();
        }

        // We want to remove the longest hashtags first
        // for the case where there are 2 hashtags the start with the same string : #paris and #paris20
        const sortedHashtags = this.hashtags.selected.sort((a, b) => b.text.length - a.text.length);
        for (const hashtag of sortedHashtags) {
            textWithNoHashtags = textWithNoHashtags.replace(hashtag.text, '');
        }

        this.hashtags.selected.forEach((h) => {
            textWithNoHashtags = textWithNoHashtags.replace(h.text, '');
        });

        return textWithNoHashtags.trim();
    }

    isReel(): boolean {
        return this.postType === PostType.REEL;
    }
}
