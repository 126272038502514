import { PostType } from '../../constants';

export enum PublicationType {
    POST = 'POST',
    REEL = 'REEL',
    STORY = 'STORY',
}

export function getPublicationType(postType: PostType, isStory: boolean): PublicationType {
    if (postType === PostType.REEL) {
        return PublicationType.REEL;
    }
    if (isStory) {
        return PublicationType.STORY;
    }
    return PublicationType.POST;
}
