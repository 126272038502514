import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
    ApplicationLanguage,
    CallToActionType,
    KeywordScoreTextType,
    mapLanguageStringToApplicationLanguage,
    PostSource,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { MediaThumbnailListComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/post-media-list/components/media-thumbnail-list/media-thumbnail-list.component';
import { PostToDuplicate } from ':modules/posts-v2/social-posts/models/post-to-duplicate';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { highlightKeywordsInText, Keyword } from ':shared/models';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-original-post-preview-v2',
    standalone: true,
    imports: [
        ImagePathResolverPipe,
        ImagePathResolverPipe,
        EnumTranslatePipe,
        LazyLoadImageModule,
        ApplySelfPurePipe,
        KeywordsScoreGaugeComponent,
        TranslateModule,
        MediaThumbnailListComponent,
        PlatformLogoComponent,
    ],
    templateUrl: './original-post-preview-v2.component.html',
    styleUrl: './original-post-preview-v2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OriginalPostPreviewV2Component implements OnInit {
    readonly post = input.required<PostToDuplicate>();
    readonly restaurantKeywords = input<Keyword[]>();
    readonly keywords$ = input<Observable<Keyword[]>>();
    readonly postIndex = input<number | undefined>(undefined);
    readonly totalPostCount = input<number | undefined>(undefined);

    readonly _restaurantsService = inject(RestaurantsService);

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly currentLang = signal<ApplicationLanguage>(inject(TranslateService).currentLang as ApplicationLanguage);
    readonly callToAction = input<{
        actionType: CallToActionType;
        url?: string;
    } | null>();
    readonly hasCallToAction = computed(
        () => !!this.callToAction() && this.callToAction()?.actionType !== CallToActionType.NONE && !!this.callToAction()?.actionType
    );
    readonly langForKeywords = signal<ApplicationLanguage | undefined>(undefined);
    readonly originalPostCaptionHighlighted = signal<string | null>(null);
    readonly textType$ = of(KeywordScoreTextType.POST);
    postLang$ = new BehaviorSubject<string | null>(null);
    originalPostCaption$: Observable<string> = of('');
    readonly medias = computed(() => {
        const urls = this.post().getAllSmallestAttachmentUrls();
        return urls;
    });

    readonly postSource = computed(() => this.post().source ?? null);

    readonly PostSource = PostSource;

    readonly hashtagTexts = computed(() => {
        const hashtags = this.post().hashtags;
        if (hashtags?.selected.length) {
            return hashtags.selected.map((hashtag) => hashtag.text);
        }
        return [];
    });

    readonly postText = computed(() => {
        if (this.postSource() === PostSource.SOCIAL) {
            return this.post().removeHashtagsFromText();
        }
        return this.post().text;
    });

    ngOnInit(): void {
        this.postLang$.next(this.post().language ?? null);
        this.langForKeywords.set(this.postLang$.value ? mapLanguageStringToApplicationLanguage(this.postLang$.value) : this.currentLang());
        this.originalPostCaption$ = of(this.post().text);

        const originalPostCaptionHighlighted = highlightKeywordsInText({
            text: this.post().text,
            keywords: this.restaurantKeywords(),
            restaurantName: this._restaurantsService.restaurantSelected$.value!.name,
            currentLang: this.langForKeywords(),
        });

        this.originalPostCaptionHighlighted.set(originalPostCaptionHighlighted);
    }
}
