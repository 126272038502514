import { Component, computed, inject, input, NgZone, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BubbleDataPoint, Chart, ChartDataset, ChartEvent, ChartOptions, ChartType, ChartTypeRegistry, Plugin, Point } from 'chart.js';
import { Tick } from 'chart.js/dist/types';
import { NgChartsModule } from 'ng2-charts';

import { isNotNil, ReviewAnalysisChartDataTag, ReviewAnalysisSentiment } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { SegmentAnalysisModalComponent } from ':shared/components/review-analyses-v2/segment-analysis-modal/segment-analysis-modal.component';
import { TopicSegmentAnalysisModalComponent } from ':shared/components/review-analyses-v2/topic-segment-analysis-modal/topic-segment-analysis-modal.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ChartDataArray, ChartDataElement, malouChartColorBluePurple, malouChartColorGreen, malouChartColorRed } from ':shared/helpers';
import { Restaurant } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const RESTAURANT_NAME_MAX_LENGTH = 20;

type BarChartType = Extract<ChartType, 'bar'>;

export interface TagEvolutionData {
    [ReviewAnalysisSentiment.POSITIVE]: ChartDataArray;
    [ReviewAnalysisSentiment.NEGATIVE]: ChartDataArray;
}

@Component({
    selector: 'app-tags-evolution-chart',
    templateUrl: './tags-evolution-chart.component.html',
    styleUrls: ['./tags-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
})
export class TagsEvolutionChartComponent {
    chartData = input.required<ReviewAnalysesChartDataByRestaurantId>();
    currentTag = input.required<ReviewAnalysisChartDataTag>();
    restaurants = input.required<Restaurant[]>();
    sortBy = input.required<ChartSortBy>();
    readonly isFromAggregatedStatistics = input.required<boolean>();
    readonly reviewAnalysesFilter = input.required<ReviewAnalysesChartFilter | null>();

    private readonly _zone = inject(NgZone);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _experimentationService = inject(ExperimentationService);

    private _sortedRestaurantIds: string[] = [];

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly LABEL_CLICK_PLUGIN: Plugin = this._getLabelClickPlugin();

    chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() => {
        const { evolutionData } = this._computeSortedData(this.chartData(), this.currentTag(), this.restaurants(), this.sortBy());
        return this._computeChartData(evolutionData);
    });
    chartLabels: Signal<string[]> = computed(() => {
        const { labels } = this._computeSortedData(this.chartData(), this.currentTag(), this.restaurants(), this.sortBy());
        return this._computeChartLabels(labels);
    });
    chartOptions: ChartOptions<BarChartType> = this._computeChartOptions();

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis'),
        { initialValue: false }
    );

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _shortTextPipe: ShortTextPipe
    ) {}

    private _computeChartData(tagEvolutionData: TagEvolutionData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('aggregated_statistics.e_reputation.reviews_analysis.negative_sentiments'),
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: tagEvolutionData[ReviewAnalysisSentiment.NEGATIVE],
            },
            {
                label: this._translateService.instant('aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments'),
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: tagEvolutionData[ReviewAnalysisSentiment.POSITIVE],
            },
        ];
    }

    private _computeSortedData(
        chartData: ReviewAnalysesChartDataByRestaurantId,
        currentTag: ReviewAnalysisChartDataTag,
        restaurants: Restaurant[],
        sortBy: ChartSortBy
    ): { evolutionData: TagEvolutionData; labels: string[] } {
        const data = restaurants
            .map((restaurant) => {
                const isPresent = chartData.has(restaurant.id);
                if (!isPresent) {
                    return;
                }
                const restaurantData = chartData.get(restaurant.id);
                if (!restaurantData) {
                    return;
                }

                return {
                    label: restaurant.internalName,
                    restaurantId: restaurant.id,
                    positive: restaurantData[currentTag].positive,
                    negative: restaurantData[currentTag].negative,
                    total: restaurantData[currentTag].total,
                };
            })
            .filter(isNotNil);
        switch (sortBy) {
            case ChartSortBy.ASC:
                const sortedDataAsc = data.sort((a, b) => a.total - b.total);
                this._sortedRestaurantIds = sortedDataAsc.map(({ restaurantId }) => restaurantId);
                return this._reduceSortedData(sortedDataAsc);
            case ChartSortBy.DESC:
                const sortedDataDesc = data.sort((a, b) => b.total - a.total);
                this._sortedRestaurantIds = sortedDataDesc.map(({ restaurantId }) => restaurantId);
                return this._reduceSortedData(sortedDataDesc);
            default:
            case ChartSortBy.ALPHABETICAL:
                const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));
                this._sortedRestaurantIds = sortedData.map(({ restaurantId }) => restaurantId);
                return this._reduceSortedData(sortedData);
        }
    }

    private _reduceSortedData(
        data: { restaurantId: string; label: string; positive: ChartDataElement; negative: ChartDataElement; total: number }[]
    ): {
        evolutionData: TagEvolutionData;
        labels: string[];
    } {
        return data.reduce(
            (acc, { label, positive, negative }) => ({
                evolutionData: {
                    [ReviewAnalysisSentiment.POSITIVE]: [...acc.evolutionData[ReviewAnalysisSentiment.POSITIVE], positive],
                    [ReviewAnalysisSentiment.NEGATIVE]: [...acc.evolutionData[ReviewAnalysisSentiment.NEGATIVE], negative],
                },
                labels: [...acc.labels, label],
            }),
            { evolutionData: { [ReviewAnalysisSentiment.POSITIVE]: [], [ReviewAnalysisSentiment.NEGATIVE]: [] }, labels: [] }
        );
    }

    private _computeChartLabels(labels: string[]): string[] {
        return labels;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    stacked: true,
                    ticks: {
                        crossAlign: 'center',
                        callback: (tickValue: number, index: number, _ticks: Tick[]): string => {
                            const label = this.chartLabels()[index];
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                        color: malouChartColorBluePurple,
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _getLabelClickPlugin(): Plugin {
        return {
            id: 'labelClick',
            afterEvent: (chart: Chart, args: { event: ChartEvent }): void => {
                const { event } = args;
                const detailsBtnOffset = 27;
                const datasets = chart.data.datasets;
                if (event.type !== 'click') {
                    return;
                }
                const { x, y } = args.event;

                if (
                    !x ||
                    x < chart.scales.xAxis.left ||
                    x > chart.scales.xAxis.right ||
                    !y ||
                    y < chart.scales.xAxis.top - detailsBtnOffset
                ) {
                    return;
                }

                const index = chart.scales.xAxis.getValueForPixel(x);

                if (index !== undefined && !this._isDataPointEqualToZero(datasets, index)) {
                    const restaurantId = this._sortedRestaurantIds[index];
                    this._zone.run(() => this._openSegmentAnalysisModal([restaurantId]));
                }
            },
        };
    }

    private _isDataPointEqualToZero(
        datasets: ChartDataset<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[]>[],
        index: number
    ): boolean {
        if (index < 0) {
            return false;
        }
        return datasets.reduce((acc, next) => (next.data[index] as number) + acc, 0) === 0;
    }

    private _openSegmentAnalysisModal(restaurantIds: string[] = []): void {
        if (!this.reviewAnalysesFilter()) {
            return;
        }
        if (this.isNewSemanticAnalysisFeatureEnabled() && !this.isFromAggregatedStatistics()) {
            this._customDialogService.open(TopicSegmentAnalysisModalComponent, {
                width: '80vw',
                height: '80vh',
                disableClose: true,
                data: {
                    topic: this.currentTag(),
                    reviewAnalysesFilter: {
                        ...this.reviewAnalysesFilter(),
                        restaurantIds,
                    },
                    isFromAggregatedStatistics: this.isFromAggregatedStatistics(),
                    isMainCategory: false,
                    shouldShowSubcategories: false,
                },
            });
        } else {
            this._customDialogService.open(SegmentAnalysisModalComponent, {
                width: '80vw',
                height: '80vh',
                disableClose: true,
                data: {
                    tag: this.currentTag(),
                    reviewAnalysesFilter: {
                        ...this.reviewAnalysesFilter(),
                        restaurantIds,
                    },
                    isFromAggregatedStatistics: this.isFromAggregatedStatistics(),
                },
            });
        }
    }
}
