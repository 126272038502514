export enum AutomationFeature {
    REPLY_TO_REVIEW = 'REPLY_TO_REVIEW',
}

export enum ReviewReplyAutomationRatingCategory {
    REVIEW_1_2 = 'REVIEW_1_2',
    REVIEW_3 = 'REVIEW_3',
    REVIEW_4 = 'REVIEW_4',
    REVIEW_5 = 'REVIEW_5',
}

export enum ReviewReplyAutomationMethod {
    AI = 'AI',
    TEMPLATES = 'TEMPLATES',
}

export enum ReviewReplyAutomationComment {
    WITH_COMMENT = 'WITH_COMMENT',
    WITHOUT_COMMENT = 'WITHOUT_COMMENT',
}

export type RatingCategory = 1 | 2 | 3 | 4 | 5;

export const AUTO_REPLY_MAX_FAILED_ATTEMPTS = 5;
export const RETRY_REPLY_MAX_FAILED_ATTEMPTS = 10;
export const AUTO_REPLY_GENERATE_MAX_FAILED_ATTEMPTS = 2;
