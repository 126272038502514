import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AbsolutePipe } from ':shared/pipes/absolute.pipe';
import { XorPipe } from ':shared/pipes/xor.pipe';

@Component({
    selector: 'app-number-evolution',
    templateUrl: './number-evolution.component.html',
    styleUrls: ['./number-evolution.component.scss'],
    standalone: true,
    imports: [MatIconModule, AbsolutePipe, XorPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberEvolutionComponent {
    @Input() displayedValue: string | undefined;
    @Input() size: 'small' | 'medium' | 'xs' = 'medium';
    @Input() reverseArrow = false;
    readonly shouldSwitchColors = input<boolean>(false);
    isValuePositive = true;

    _value: number | null;
    @Input() set value(value: number | null) {
        this._value = value;
        this.isValuePositive = !value || value > 0;
    }
    get value(): number | null {
        return this._value;
    }
}
