import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-boosters-presentation',
    templateUrl: './boosters-presentation.component.html',
    styleUrls: ['./boosters-presentation.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, TranslateModule, ButtonComponent, HtmlTagPipe, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoostersPresentationComponent {
    private readonly _wheelsOfFortuneService = inject(WheelsOfFortuneService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;
    readonly ApplicationLanguage = ApplicationLanguage;

    readonly isRequestSent = signal(false);
    readonly isSendingRequest = signal(false);

    readonly isAggregatedView = signal(false);

    private readonly _ownRestaurants = toSignal(this._store.select(selectOwnRestaurants), { initialValue: [] });
    private readonly _restaurants = computed((): Restaurant[] => {
        const selectedRestaurant = this._restaurantsService.selectedRestaurant();
        return this._ownRestaurants() && this.isAggregatedView()
            ? this._ownRestaurants().filter((restaurant) => !restaurant.boosterPack?.activated)
            : selectedRestaurant
              ? [selectedRestaurant]
              : [];
    });

    readonly lang = LocalStorage.getLang();

    constructor() {
        effect(
            () => {
                const restaurants = this._restaurants();
                if (!restaurants.length) {
                    return;
                }

                const subscriptionRequestSentRestaurantIds = JSON.parse(
                    LocalStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
                );
                this.isRequestSent.set(restaurants.every((restaurant) => subscriptionRequestSentRestaurantIds.includes(restaurant.id)));
            },
            { allowSignalWrites: true }
        );

        this.isAggregatedView.set(this._router.url.includes('groups'));
    }

    sendRequest(): void {
        const restaurants = this._restaurants();
        if (!restaurants.length) {
            return;
        }

        this.isSendingRequest.set(true);

        this._wheelsOfFortuneService.sendSubscriptionRequest(restaurants).subscribe(() => {
            this._toastService.openSuccessToast(
                this._translateService.instant('wheel_of_fortune.subscription_request_modal.request_successfully_sent')
            );
            this._updateStorageWithSentRestaurantIds(restaurants);
            this.isRequestSent.set(true);
            this.isSendingRequest.set(false);
        });
    }

    redirectToMoreInformation(): void {
        const moreInformationLink =
            this.lang === ApplicationLanguage.FR
                ? 'https://help.malou.io/en/articles/3551618'
                : 'https://malouhelpcenter.frontkb.com/en/articles/3551682';
        window.open(moreInformationLink, '_blank');
    }

    private _updateStorageWithSentRestaurantIds(restaurants: Restaurant[]): void {
        const subscriptionRequestSentRestaurantIds = JSON.parse(
            LocalStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
        );
        restaurants.forEach((restaurant) => {
            subscriptionRequestSentRestaurantIds.push(restaurant.id);
        });
        const uniqSubscriptionRequestSentRestaurantIds = Array.from(new Set(subscriptionRequestSentRestaurantIds));
        const uniqSubscriptionRequestSentRestaurantIdsString = JSON.stringify(uniqSubscriptionRequestSentRestaurantIds);
        LocalStorage.setItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS, uniqSubscriptionRequestSentRestaurantIdsString);
    }
}
