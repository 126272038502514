import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    inject,
    Input,
    signal,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { TooltipPosition } from '@malou-io/package-utils';

const showHideTooltipAnimation = trigger('tooltipState', [
    state('hidden', style({ opacity: 0 })),
    state('visible', style({ opacity: 1 })),
    transition('hidden <=> visible', animate('200ms ease-in-out')),
]);

@Component({
    selector: 'app-custom-tooltip',
    standalone: true,
    imports: [NgClass, NgStyle, NgTemplateOutlet],
    templateUrl: './custom-tooltip.component.html',
    styleUrl: './custom-tooltip.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [showHideTooltipAnimation],
})
export class CustomTooltipComponent {
    // can't be readonly inputs signals because we need to assign them in the directive
    @Input() content: TemplateRef<any> | string;
    @Input() position: TooltipPosition = TooltipPosition.TOP;

    private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    readonly isVisible = signal<boolean>(false);
    readonly top = signal<number>(0);
    readonly left = signal<number>(0);

    readonly animationState = computed(() => (this.isVisible() ? 'visible' : 'hidden'));

    show(): void {
        this.isVisible.set(true);
    }

    hide(): void {
        this.isVisible.set(false);
    }

    async setPosition(target: HTMLElement): Promise<void> {
        const isTemplateContent = this.isTemplate(this.content);
        const rect = target.getBoundingClientRect();
        const { width: tooltipRectWidth, height: tooltipRectHeight } = isTemplateContent
            ? await this._calculateTemplateTooltipDimensions()
            : this._calculateStringTooltipDimentions();

        switch (this.position) {
            case TooltipPosition.TOP:
                const xTopOffset = 20;
                this.top.set(rect.top - tooltipRectHeight);
                this.left.set(rect.left - tooltipRectWidth / 2 + xTopOffset);
                break;
            case TooltipPosition.BOTTOM:
                this.top.set(rect.bottom + 10);
                this.left.set(rect.left - tooltipRectWidth / 2);
                break;
            case TooltipPosition.LEFT:
                const xLeftOffset = isTemplateContent ? 30 : 0;
                const yLeftOffset = isTemplateContent ? 10 : 0;
                this.top.set(rect.top + rect.height / 2 - tooltipRectHeight / 2 - yLeftOffset);
                this.left.set(rect.left - tooltipRectWidth - xLeftOffset);
                break;
            case TooltipPosition.RIGHT:
                const xRightOffset = isTemplateContent ? 10 : 0;
                const yRightOffset = isTemplateContent ? 10 : 0;
                this.top.set(rect.top + rect.height / 2 - tooltipRectHeight / 2 - yRightOffset);
                this.left.set(rect.right + xRightOffset);
                break;
        }
    }

    private _calculateStringTooltipDimentions(): { width: number; height: number } {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.pointerEvents = 'none';
        tempDiv.innerText = this.content as string;
        document.body.appendChild(tempDiv);
        const rect = tempDiv.getBoundingClientRect();
        document.body.removeChild(tempDiv);
        return { width: rect.width, height: rect.height };
    }

    private _calculateTemplateTooltipDimensions(): Promise<{ width: number; height: number }> {
        return new Promise((resolve) => {
            const content = this.content as TemplateRef<any>;
            const embeddedViewRef = this._viewContainerRef.createEmbeddedView(content);
            this._changeDetectorRef.detectChanges(); // Ensure Angular processes the changes
            const element = embeddedViewRef.rootNodes[0] as HTMLElement;
            document.body.appendChild(element);
            setTimeout(() => {
                const rect = element.getBoundingClientRect();
                document.body.removeChild(element);
                resolve({ width: rect.width, height: rect.height });
            }, 0); // Allow browser time to render
        });
    }

    isTemplate(value: any): value is TemplateRef<any> {
        return value instanceof TemplateRef;
    }
}
