import { z } from 'zod';

import { addressDtoValidator } from '../../restaurant';
import { objectIdValidator } from '../../utils';
import { storeLocatorStorePageCallToActionsBlockValidator } from './call-to-actions-block';
import { storeLocatorStorePageDescriptionsBlockValidator } from './descriptions-block';
import { storeLocatorStorePageGalleryBlockValidator } from './gallery-block';
import { storeLocatorStorePageHeadBlockValidator } from './head-block';
import { storeLocatorStorePageInformationBlockValidator } from './information-block';
import { storeLocatorStorePageReviewsBlockValidator } from './reviews-block';
import { storeLocatorStorePageSocialNetworksBlockValidator } from './social-networks-block';

export const storeLocatorStoreValidator = z.object({
    id: objectIdValidator,
    name: z.string(),
    internalName: z.string().optional(),
    slug: z.string(),
    address: addressDtoValidator.optional(),
    headBlock: storeLocatorStorePageHeadBlockValidator,
    galleryBlock: storeLocatorStorePageGalleryBlockValidator.optional(),
    reviewsBlock: storeLocatorStorePageReviewsBlockValidator.optional(),
    callToActionsBlock: storeLocatorStorePageCallToActionsBlockValidator.optional(),
    socialNetworksBlock: storeLocatorStorePageSocialNetworksBlockValidator.optional(),
    descriptionsBlock: storeLocatorStorePageDescriptionsBlockValidator.optional(),
    informationBlock: storeLocatorStorePageInformationBlockValidator,
});

export type GetStoreLocatorStorePageDto = z.infer<typeof storeLocatorStoreValidator>;
