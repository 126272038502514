import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, Inject, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, Observable, of } from 'rxjs';

import { MediaType, PlatformKey, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { DuplicateAndSelectPlatformsComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/duplicate-and-select-platforms/duplicate-and-select-platforms.component';
import { PreviewsFeedNotesComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/previews-feed-notes.component';
import { SocialPostContentFormComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-content-form.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import {
    TransformToReelModalComponent,
    TransformToReelModalProps,
    TransformToReelModalResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/transform-to-reel-modal/transform-to-reel-modal.component';
import { UpsertSocialPostModalFooterComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/upsert-social-post-modal-footer/upsert-social-post-modal-footer.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostHashtagsContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-hashtags.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import {
    SubmitPublicationStatus,
    UpsertSocialPostModalProps,
    UpsertSocialPostModalResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { CREATE_SOCIAL_POST_MODAL_HTML_ID } from ':modules/social-posts/new-social-post-modal/utils/utils';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-upsert-social-post-modal',
    templateUrl: './upsert-social-post-modal.component.html',
    styleUrls: ['./upsert-social-post-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        DuplicateAndSelectPlatformsComponent,
        PreviewsFeedNotesComponent,
        SocialPostContentFormComponent,
        UpsertSocialPostModalFooterComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertSocialPostModalComponent {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);
    private readonly _upsertSocialPostHashtagsContext = inject(UpsertSocialPostHashtagsContext);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly SvgIcon = SvgIcon;
    readonly CREATE_SOCIAL_POST_MODAL_HTML_ID = CREATE_SOCIAL_POST_MODAL_HTML_ID;

    readonly shouldOpenFeedbacks = signal(false);
    readonly postId = signal<string | null>(null);

    readonly isSubmitting = this._upsertSocialPostContext.upsertSocialPostState.isSubmitting;
    readonly isLoadingPost = this._upsertSocialPostContext.upsertSocialPostState.isLoadingPost;
    readonly isReadonly = computed(() => this.isSubmitting() || this.isLoadingPost());

    private readonly _openAsReel = signal(false);
    readonly isReel = computed(
        () => this._upsertSocialPostContext.upsertSocialPostState.post.postType() === PostType.REEL || this._openAsReel()
    );

    private readonly _shouldAutoSave = computed(() => {
        const initialPost = this._upsertSocialPostContext.initialPost();
        return !initialPost || initialPost.published === PostPublicationStatus.DRAFT;
    });
    private _isFirstAutoSave = true;
    private readonly _postForAutoSave = toSignal(
        toObservable(this._upsertSocialPostContext.upsertSocialPostState.post).pipe(
            filter((post) => !!post.id),
            debounceTime(500)
        )
    );

    private readonly _hasErrorOccuredDuringInit = this._upsertSocialPostContext.hasErrorOccuredDuringInit;

    readonly isTiktokEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-tiktok-platform'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-tiktok-platform'),
    });

    constructor(
        private readonly _dialogRef: MatDialogRef<UpsertSocialPostModalComponent, UpsertSocialPostModalResult>,
        @Inject(MAT_DIALOG_DATA)
        data: UpsertSocialPostModalProps
    ) {
        this._upsertSocialPostContext.closeModal$
            .pipe(filter(Boolean), takeUntilDestroyed())
            .subscribe(() => this._confirmClose({ post: null, scrollToPostId: data.postId }));
        this._upsertSocialPostContext.init(data.postId, data.disconnectedPlatforms$, { date: data.date, isReel: data.isReel });
        this._upsertSocialPostHashtagsContext.init();
        this._upsertSocialPostAiContext.initInteractions(data.postId);

        this.postId.set(data.postId ?? null);

        if (data.shouldOpenFeedbacks) {
            this.shouldOpenFeedbacks.set(true);
        }

        this.postId.set(data.postId ?? null);
        this._openAsReel.set(!!data.isReel);

        effect(
            () => {
                if (this._hasErrorOccuredDuringInit()) {
                    this._toastService.openErrorToast(this._translateService.instant('social_posts.upsert_social_post_modal.init_error'));
                    this.close();
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                const shouldAutoSave = this._shouldAutoSave();
                if (shouldAutoSave) {
                    const post = this._postForAutoSave();
                    if (post) {
                        if (this._isFirstAutoSave) {
                            this._isFirstAutoSave = false;
                            return;
                        }
                        this._upsertSocialPostContext.autosave(post);
                    }
                }
            },
            { allowSignalWrites: true }
        );
    }

    onSavePost(submitPublicationStatus: SubmitPublicationStatus): void {
        const post = this._upsertSocialPostContext.upsertSocialPostState.post();
        if (!post) {
            return;
        }
        if (
            submitPublicationStatus !== SubmitPublicationStatus.DRAFT &&
            this._isSingleVideoAttachmentPost(post.attachments, post.platformKeys, this.isReel())
        ) {
            this._openModalToTransformToReel(post);
            return;
        }
        this._upsertSocialPostContext.savePost$(post, submitPublicationStatus).subscribe((res) => {
            this._confirmClose({
                post: res.toInterface(),
                isManualSave: true,
                scrollToPostId: res.id,
            });
        });
    }

    close(): void {
        const postState = this._upsertSocialPostContext.upsertSocialPostState.post();

        // If the post is empty, and it was a creation, close the modal and delete the post
        const initialPost = this._upsertSocialPostContext.initialPost();

        const isEmptyAndCreation = !initialPost && new UpsertSocialPost(postState).isEmpty();
        if (isEmptyAndCreation) {
            this._upsertSocialPostContext.deletePost$().subscribe(() => {
                this._confirmClose({ post: null });
            });
        } else {
            // Send data to the parent component only if autosave is enabled
            const post = this._shouldAutoSave() ? (postState ?? null) : null;
            this._confirmClose({ post, scrollToPostId: post?.id });
        }
    }

    onCancel(): void {
        const initialPost = this._upsertSocialPostContext.initialPost();

        let actionBeforeClose$: Observable<UpsertSocialPost | null>;

        if (!initialPost) {
            actionBeforeClose$ = this._upsertSocialPostContext.deletePost$();
        } else if (this._shouldAutoSave()) {
            actionBeforeClose$ = this._upsertSocialPostContext.savePost$(initialPost, SubmitPublicationStatus.DRAFT);
        } else {
            actionBeforeClose$ = of(null);
        }

        actionBeforeClose$.subscribe((res) => {
            this._confirmClose({ post: res ?? null, scrollToPostId: res?.id });
        });
    }

    private _confirmClose(data: { post: IUpsertSocialPost | null; isManualSave?: boolean; scrollToPostId?: string }): void {
        this._upsertSocialPostAiContext.closePostCaptionAiGeneration();
        const socialPost = data.post ? SocialPostItem.fromIUpsertSocialPost(data.post) : null;
        const duplicateToSeo =
            !!data.isManualSave && this._upsertSocialPostContext.upsertSocialPostState.duplicateToPlatforms().includes(PlatformKey.GMB);
        this._dialogRef.close({
            post: socialPost,
            scrollToPostId: data.scrollToPostId,
            duplicateToSeo,
        });
    }

    private _isSingleVideoAttachmentPost(medias: EditionMedia[], platformKeys: PlatformKey[], isReel: boolean): boolean {
        return (
            medias.length === 1 &&
            medias[0].type === MediaType.VIDEO &&
            !isReel &&
            (platformKeys.includes(PlatformKey.INSTAGRAM) || platformKeys.includes(PlatformKey.FACEBOOK))
        );
    }

    private _openModalToTransformToReel(post: IUpsertSocialPost): void {
        let platformKey: PlatformKey.INSTAGRAM | PlatformKey.FACEBOOK | undefined;
        if (post.platformKeys.includes(PlatformKey.INSTAGRAM)) {
            platformKey = PlatformKey.INSTAGRAM;
        } else if (post.platformKeys.includes(PlatformKey.FACEBOOK)) {
            platformKey = PlatformKey.FACEBOOK;
        }

        if (!platformKey) {
            return;
        }

        this._customDialogService
            .open<TransformToReelModalComponent, TransformToReelModalProps, TransformToReelModalResult>(TransformToReelModalComponent, {
                width: '650px',
                disableClose: false,
                height: 'fit-content',
                data: {
                    post,
                    restaurantId: this._restaurantService.currentRestaurant._id,
                    platformKey,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.newPost) {
                    this._upsertSocialPostContext.updatePost(result.newPost);
                }
            });
    }
}
