import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UppyFile } from '@uppy/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    DuplicateMediaForPublicationBodyDto,
    DuplicateMediaForPublicationParamsDto,
    DuplicateMediaForPublicationResponseDto,
    GetMediaForEditionBodyDto,
    GetMediaForEditionPathDto,
    GetMediaForEditionResponseDto,
    GetVideoInformationBodyDto,
    GetVideoInformationResponseDto,
    UpdateTransformDataBodyDto,
    UpdateTransformDataParamsDto,
    UploadMediaV2QueryDto,
    UploadMediaV2ResponseDto,
} from '@malou-io/package-dto';
import { ApiResultV2, MediaCategory, PictureSizeRecord } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { environment } from ':environments/environment';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { objectToQueryParams } from ':shared/helpers/query-params';
import { ApiResult, GalleryFilters, Media, Pagination, UppyTemporaryMedia } from ':shared/models';

import { PlatformMedia, PlatformMediaDownloaderService } from './utils/platform-media-downloader.service';

export enum UploadClientError {
    /** Something went wrong between the client and the server */
    NETWORK_ERROR = 'NETWORK_ERROR',
}

/** like UploadMediaV2ResponseDto but extended for network errors */
export type UploadV2Result = UploadMediaV2ResponseDto | { success: false; errorCode: UploadClientError };

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/media`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _platformMediaDownloaderService: PlatformMediaDownloaderService
    ) {}

    uploadPlatformMedias(restaurantId: string, platformMedias: PlatformMedia[] | null | undefined): Observable<Media[]> {
        if (!platformMedias?.length) {
            return of([]);
        }
        return this._platformMediaDownloaderService.downloadMedia(platformMedias).pipe(
            switchMap((downloadedMedias) =>
                this.uploadAndCreateMedia(
                    downloadedMedias.map((media) => ({
                        data: media.file,
                        metadata: {
                            category: media.category,
                            restaurantId,
                            title: media.name,
                            description: media.description,
                        },
                    }))
                )
            ),
            map((res) => res.data)
        );
    }

    getRestaurantMediasPaginated(
        restaurantId: string,
        pagination: Pagination,
        filters: GalleryFilters & { maxVideoSize?: number },
        folderId: string | null
    ): Observable<
        ApiResult<{
            medias: Media[];
            pagination: Pagination;
        }>
    > {
        const cleanFilters = removeNullOrUndefinedField({ ...pagination, ...filters });

        if (folderId || !filters.title) {
            cleanFilters.folderId = folderId;
        }

        return this._http
            .get<ApiResult<{ medias: Media[]; pagination: Pagination }>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, {
                params: objectToSnakeCase(cleanFilters),
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    res.data.medias = res.data.medias?.map((e) => new Media(e));
                    return res;
                })
            );
    }

    getMediumById(mediumId: string): Observable<ApiResult<Media>> {
        return this._http.get<ApiResult<Media>>(`${this.API_BASE_URL}/${mediumId}`).pipe(
            map((res) => {
                res.data = new Media(res.data);
                return res;
            })
        );
    }

    replaceMediaUrls(
        mediumId: string,
        file: File,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Media>> {
        const uploadData = new FormData();
        uploadData.append('media', file);
        return this._http
            .post<ApiResult<Media>>(`${this.API_BASE_URL}/${mediumId}/replace`, uploadData, {
                withCredentials: true,
                params: { restaurant_id: restaurantId },
            })
            .pipe(
                map((res) => {
                    res.data = new Media(res.data);
                    return res;
                })
            );
    }

    updateMediaById(
        mediumId: string,
        media: Partial<Media>,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Media>> {
        return this._http
            .put<
                ApiResult<Media>
            >(`${this.API_BASE_URL}/${mediumId}`, { media }, { withCredentials: true, params: { restaurant_id: restaurantId } })
            .pipe(
                map((res) => {
                    res.data = new Media(res.data);
                    return res;
                })
            );
    }

    deleteMedia(mediaIds: string[], restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        const params = objectToQueryParams({ mediaIds });
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, { withCredentials: true, params });
    }

    duplicateMediaForRestaurants(restaurantId: string, originalMedia: Media[], restaurantIds: string[]): Observable<ApiResult<Media[]>> {
        return this._http
            .post<
                ApiResult<Media[]>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/duplicate`, { originalMedia, restaurantIds }, { withCredentials: true })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res;
                })
            );
    }

    fetchMediaDescription(mediaIds: string[]): Observable<ApiResult<void>> {
        const params = objectToQueryParams({ mediaIds });
        return this._http.get<ApiResult<void>>(`${this.API_BASE_URL}/fetch-description`, { withCredentials: true, params });
    }

    downloadSingleMedia(media: Media): Observable<Blob> {
        // add fingerprint otherwise the browser will cache the file and we cannot download
        // better solution would be to download directly from browser cache ?
        return this._http.get(media.getMediaUrl() + '?fingerprint=' + Math.random(), { responseType: 'blob' });
    }

    /**
     * Only upload aws url for media (especially used for conversation messages)
     */
    uploadOnly(
        category: string,
        file: File,
        data: { entityRelated: string; entityId: string }
    ): Observable<ApiResult<{ urls: PictureSizeRecord<string>; sizes: PictureSizeRecord<number> }>> {
        const uploadData = new FormData();
        const cleanFilters = objectToQueryParams({
            category,
            entityRelated: data.entityRelated,
            entityId: data.entityId,
        });
        uploadData.append('media', file);
        return this._http.post<ApiResult<{ urls: PictureSizeRecord<string>; sizes: PictureSizeRecord<number> }>>(
            `${this.API_BASE_URL}/upload_only`,
            uploadData,
            {
                params: cleanFilters,
                withCredentials: true,
            }
        );
    }

    getUploadParams(file: UppyFile): Observable<{
        method: any;
        url: any;
        fields: any;
        headers: any;
    }> {
        return this._http
            .post<ApiResult>(
                `${this.API_BASE_URL}/cloud-storage-upload-params`,
                { file },
                { withCredentials: true, params: { restaurant_id: this._restaurantsService.currentRestaurant._id } }
            )
            .pipe(
                map((res) => ({
                    method: res.data.method,
                    url: res.data.url,
                    fields: res.data.fields,
                    headers: res.data.headers,
                }))
            );
    }

    createManyMedias(medias: UppyTemporaryMedia[], folderId: string | null = null): Observable<ApiResult<Media[]>> {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        const mediasToUpload = medias.map((media) => ({
            name: media.name,
            sizes: media.sizes,
            urls: media.urls,
            format: media.extension.toLowerCase(),
            type: media.type,
            dimensions: media.dimensions,
            duration: media.duration,
            folderId,
        }));
        return this._http
            .post<
                ApiResult<Media[]>
            >(`${this.API_BASE_URL}/create`, { medias: mediasToUpload }, { withCredentials: true, params: { restaurant_id: restaurantId } })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res;
                })
            );
    }

    getManyMedia(mediaIds: string[]): Observable<ApiResult<Media[]>> {
        return this._http
            .get<ApiResult<Media[]>>(`${this.API_BASE_URL}`, {
                params: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'media_ids[]': mediaIds,
                },
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res;
                })
            );
    }

    moveMediaTowardsFolder(mediaIds: string[], folderId: string | null): Observable<void> {
        return this._http.put<void>(`${this.API_BASE_URL}/move`, { folderId, mediaIds });
    }

    getVideoInformation(body: GetVideoInformationBodyDto): Observable<ApiResultV2<GetVideoInformationResponseDto>> {
        return this._http.post<ApiResultV2<GetVideoInformationResponseDto>>(`${this.API_BASE_URL}/get-video-information`, body, {
            withCredentials: true,
        });
    }

    transcodeHeifToJpg(blob: Blob): Observable<Blob> {
        const formData = new FormData();
        formData.append('image', blob);
        return this._http.post(`${this.API_BASE_URL}/transcode-heif-to-jpg`, formData, { responseType: 'blob' });
    }

    uploadAndCreateMedia(
        files: {
            data: File;
            metadata: {
                category: MediaCategory;
                restaurantId?: string;
                userId?: string;
                title?: string;
                description?: string;
                originalMediaId?: string | null;
                desiredAspectRatio?: number;
            };
        }[]
    ): Observable<ApiResult<Media[]>> {
        const uploadData = new FormData();
        const restaurantId = files[0].metadata.restaurantId;
        const filesMetadata: any[] = [];
        for (const file of files) {
            uploadData.append('media', file.data);
            filesMetadata.push(file.metadata);
        }
        uploadData.append('metadata', JSON.stringify(filesMetadata));

        return this._http.post<ApiResult<Media[]>>(
            `${this.API_BASE_URL}${restaurantId ? `?restaurant_id=${restaurantId}` : ''}`,
            uploadData,
            {
                withCredentials: true,
            }
        );
    }

    uploadV2 = (params: {
        file: File;
        /** Called with a number between 0 and 1 */
        onProgress: (progress: number) => void;
        queryParams: UploadMediaV2QueryDto;
    }): Promise<UploadV2Result> =>
        new Promise((resolve) => {
            // We use the old XMLHttpRequest API because the fetch API does not support
            // progress events. I know Angular’s HttpClient uses XHR by default but the
            // backend can be switched to fetch optionnally, so I’m afraid that using
            // HttpClient would make switching to the fetch backend more complicated, and
            // we have to use an XMLHttpRequest in anyway, so…

            const jwtToken = LocalStorage.getItem(LocalStorageKey.JWT_TOKEN);

            const formData = new FormData();
            formData.append(params.file.name, params.file);

            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (event) => params.onProgress(event.loaded / event.total));

            const onNetworkError = (): void => resolve({ success: false, errorCode: UploadClientError.NETWORK_ERROR });

            xhr.responseType = 'json';
            xhr.withCredentials = true;

            xhr.upload.addEventListener('error', onNetworkError);
            xhr.upload.addEventListener('abort', onNetworkError);
            xhr.upload.addEventListener('timeout', onNetworkError);
            xhr.upload.addEventListener('loadend', () => params.onProgress(1));

            xhr.addEventListener('readystatechange', () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        const result: UploadMediaV2ResponseDto = xhr.response.data;
                        resolve(result);
                    } else {
                        onNetworkError();
                    }
                }
            });

            const url = new URL(`${this.API_BASE_URL}/upload-v2`);
            url.searchParams.set('restaurantId', params.queryParams.restaurantId);
            if (params.queryParams.videoWidthInPixels) {
                url.searchParams.set('videoWidthInPixels', params.queryParams.videoWidthInPixels.toString());
            }
            if (params.queryParams.videoHeightInPixels) {
                url.searchParams.set('videoHeightInPixels', params.queryParams.videoHeightInPixels.toString());
            }
            if (params.queryParams.videoDurationInMilliseconds) {
                url.searchParams.set('videoDurationInMilliseconds', params.queryParams.videoDurationInMilliseconds.toString());
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader('authorization', `bearer ${jwtToken}`);
            xhr.send(formData);
        });

    getMediaForEdition(
        pathParams: GetMediaForEditionPathDto,
        body: GetMediaForEditionBodyDto
    ): Observable<ApiResultV2<GetMediaForEditionResponseDto>> {
        return this._http.post<ApiResultV2<GetMediaForEditionResponseDto>>(
            `${this.API_BASE_URL}/get-media-for-edition/${pathParams.mediaId}`,
            body
        );
    }

    duplicateMediaForPublication(
        dto: DuplicateMediaForPublicationParamsDto,
        body: DuplicateMediaForPublicationBodyDto
    ): Observable<ApiResultV2<DuplicateMediaForPublicationResponseDto>> {
        return this._http.post<ApiResultV2<DuplicateMediaForPublicationResponseDto>>(
            `${this.API_BASE_URL}/${dto.mediaId}/duplicate-for-publication`,
            body
        );
    }

    updateTransformData(params: UpdateTransformDataParamsDto, dto: UpdateTransformDataBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/${params.mediaId}/update-transform-data`, dto);
    }
}
