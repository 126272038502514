export enum AspectRatio {
    SQUARE = 'SQUARE',
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE',
    ORIGINAL = 'ORIGINAL',
}

export const postAspectRatioToNumberMap = {
    [AspectRatio.SQUARE]: 1,
    [AspectRatio.PORTRAIT]: 4 / 5,
    [AspectRatio.LANDSCAPE]: 16 / 9,
    [AspectRatio.ORIGINAL]: null,
} satisfies Record<AspectRatio, number | null>;

export const reelAspectRatioToNumberMap = {
    [AspectRatio.SQUARE]: null,
    [AspectRatio.PORTRAIT]: 9 / 16,
    [AspectRatio.LANDSCAPE]: null,
    [AspectRatio.ORIGINAL]: null,
} satisfies Record<AspectRatio, number | null>;

export const storyAspectRatioToNumberMap = {
    [AspectRatio.SQUARE]: null,
    [AspectRatio.PORTRAIT]: 9 / 16,
    [AspectRatio.LANDSCAPE]: null,
    [AspectRatio.ORIGINAL]: null,
} satisfies Record<AspectRatio, number | null>;
