import { DestroyRef, inject, Injectable, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';

@Injectable({
    providedIn: 'root',
})
export class UsersContext {
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);

    readonly currentUser: Signal<User | null> = toSignal(
        this._store.select(selectUserInfos).pipe(
            takeUntilDestroyed(this._destroyRef),
            filter((user) => isNotNil(user)),
            map((user) => user ?? null)
        ),
        { initialValue: null }
    );
}
