<div class="flex flex-col gap-6 px-[8%] py-8 lg:gap-4 lg:px-0 lg:py-4">
    <div class="flex flex-col lg:gap-2 lg:px-[8%]">
        <div class="malou-text-30--bold lg:malou-text-20--bold text-center text-malou-color-text-1">
            <span class="lg:hidden">🚀</span> {{ 'boosters.presentation.title' | translate }}
        </div>
        <div class="malou-text-13--medium lg:malou-text-12--medium text-center text-malou-color-text-2">
            {{ 'boosters.presentation.subtitle' | translate }}
        </div>
    </div>

    <div class="flex lg:flex-col">
        <div class="min-w-[50%] max-w-[50%] overflow-hidden rounded-l-[10px] lg:min-w-full lg:max-w-full lg:rounded-none">
            @if (lang === ApplicationLanguage.FR) {
                <div style="padding: 56.25% 0 0 0; position: relative">
                    <iframe
                        src="https://player.vimeo.com/video/1061968235?h=1924d5ac44&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        title="Découvrez les Boosters de Malou"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            } @else {
                <img class="h-full object-cover" [src]="'boosterPack' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
            }
        </div>

        <div
            class="flex flex-col justify-between rounded-r-[10px] border border-malou-color-border-primary bg-white px-12 py-10 lg:gap-3 lg:rounded-none lg:px-8 lg:py-6">
            <div class="flex gap-x-2">
                <div class="-mt-[2px]">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </div>
                <div
                    class="malou-text-12--regular text-malou-color-text-2"
                    [innerHTML]="'boosters.presentation.totems_and_stickers' | translate | htmlTag"></div>
            </div>

            <div class="flex gap-x-2">
                <div class="-mt-[2px]">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </div>
                <div
                    class="malou-text-12--regular text-malou-color-text-2"
                    [innerHTML]="'boosters.presentation.positive_reviews' | translate | htmlTag"></div>
            </div>

            <div class="flex gap-x-2">
                <div class="-mt-[2px]">
                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                </div>
                <div
                    class="malou-text-12--regular text-malou-color-text-2"
                    [innerHTML]="'boosters.presentation.retain_your_customers' | translate | htmlTag"></div>
            </div>

            <div class="flex gap-x-3">
                <div class="flex w-1/2 flex-col gap-2">
                    <app-button
                        id="tracking_boosters_get_it_now"
                        buttonClasses="h-11 w-full"
                        [disabled]="isRequestSent()"
                        [loading]="isSendingRequest()"
                        [text]="'boosters.presentation.get_now' | translate"
                        [tooltip]="isRequestSent() ? ('wheel_of_fortune.subscription_request_modal.request_sent' | translate) : ''"
                        (buttonClick)="sendRequest()"></app-button>
                    <div class="malou-text-10 text-center italic text-malou-color-text-2">
                        {{ 'boosters.presentation.you_will_be_contacted' | translate }}
                    </div>
                </div>

                <button
                    class="malou-btn-raised--secondary h-11 w-1/2"
                    id="tracking_boosters_more_information"
                    mat-raised-button
                    (click)="redirectToMoreInformation()">
                    {{ 'boosters.presentation.learn_more' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="flex rounded-[5px] border border-malou-color-border-primary bg-white lg:mx-6 lg:flex-col">
        <div class="flex min-w-[160px] flex-col justify-center bg-malou-color-background-dark px-3 py-2 lg:items-center lg:py-3">
            <div class="malou-text-30">🏆</div>
            <div class="malou-text-10--medium text-malou-color-text-2">
                {{ 'boosters.presentation.do_like_our_client' | translate }}
            </div>
            <div class="malou-text-17--bold text-malou-color-text-1">PACIFIC GRILL</div>
        </div>
        <div class="flex grow py-3 lg:flex-col lg:px-3 lg:py-0">
            <div
                class="flex grow basis-0 flex-col items-center justify-center border-r border-malou-color-border-primary px-3 py-2 lg:border-b lg:border-r-0 lg:px-10 lg:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.more_reviews.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.more_reviews.text' | translate }}
                </div>
            </div>
            <div
                class="flex grow basis-0 flex-col items-center justify-center border-r border-malou-color-border-primary px-3 py-2 lg:border-b lg:border-r-0 lg:px-10 lg:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.reviews_per_month.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.reviews_per_month.text' | translate }}
                </div>
            </div>
            <div
                class="flex grow basis-0 flex-col items-center justify-center border-r border-malou-color-border-primary px-3 py-2 lg:border-b lg:border-r-0 lg:px-10 lg:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.average_rating.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.average_rating.text' | translate }}
                </div>
            </div>
            <div class="flex grow basis-0 flex-col items-center justify-center px-3 py-2 lg:px-10 lg:py-5">
                <div class="malou-text-40--bold leading-[40px] text-malou-color-chart-purple">
                    {{ 'boosters.presentation.gift_retrieved.figures' | translate }}
                </div>
                <div class="malou-text-12--semibold text-center text-malou-color-text-1">
                    {{ 'boosters.presentation.gift_retrieved.text' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
