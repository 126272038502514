import { z } from 'zod';

import { AggregationType, MalouComparisonPeriod } from '@malou-io/package-utils';

import { monthYearValidator, objectIdValidator } from '../utils';

export const getKeywordSearchImpressionsInsightsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetKeywordSearchImpressionsInsightsParamsDto = z.infer<typeof getKeywordSearchImpressionsInsightsParamsValidator>;

export const getKeywordSearchImpressionsInsightsBodyValidator = z.object({
    startMonthYear: monthYearValidator,
    endMonthYear: monthYearValidator,
    comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).optional(),
});
export type GetKeywordSearchImpressionsInsightsBodyDto = z.infer<typeof getKeywordSearchImpressionsInsightsBodyValidator>;

// ------------------------------------------------------------

export const getTopKeywordSearchImpressionsParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type GetTopKeywordSearchImpressionsParamsDto = z.infer<typeof getTopKeywordSearchImpressionsParamsValidator>;

export const getTopKeywordSearchImpressionsBodyValidator = z.object({
    startMonthYear: monthYearValidator,
    endMonthYear: monthYearValidator,
    limit: z.coerce.number(),
});
export type GetTopKeywordSearchImpressionsBodyDto = z.infer<typeof getTopKeywordSearchImpressionsBodyValidator>;

// ------------------------------------------------------------

export const getAggregatedKeywordSearchImpressionsInsightsBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    monthYearPeriod: z.object({
        startMonthYear: monthYearValidator,
        endMonthYear: monthYearValidator,
    }),
    aggregationType: z.nativeEnum(AggregationType),
    comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).optional(),
});
export type GetAggregatedKeywordSearchImpressionsInsightsBodyDto = z.infer<
    typeof getAggregatedKeywordSearchImpressionsInsightsBodyValidator
>;

// ------------------------------------------------------------

export const getAverageTopKeywordSearchImpressionsBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    monthYearPeriod: z.object({
        startMonthYear: monthYearValidator,
        endMonthYear: monthYearValidator,
    }),
    limit: z.coerce.number(),
    comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).optional(),
});
export type GetAverageTopKeywordSearchImpressionsBodyDto = z.infer<typeof getAverageTopKeywordSearchImpressionsBodyValidator>;
