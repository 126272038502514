import { NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    OnInit,
    Signal,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isInteger } from 'lodash';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, filter, fromEvent, switchMap, take } from 'rxjs';

import { HeapEventName, InsightsChart, InsightsTab, isNotNil, MonthYearPeriod, PlatformFilterPage } from '@malou-io/package-utils';

import { UsersContext } from ':core/context/users.context';
import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { FiltersComponent } from ':modules/statistics/filters/filters.component';
import { GmbInsightsComponent } from ':modules/statistics/seo/gmb-insights/gmb-insights.component';
import { KeywordSearchImpressionsComponent } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.component';
import { StatisticsKeywordsDetailComponent } from ':modules/statistics/seo/statistics-keywords-detail/statistics-keywords-detail.component';
import { StatisticsSeoKeywordsV3Component } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import {
    selectComparisonPeriodFilter,
    selectDatesFilter,
    selectMonthYearPeriodFilter,
} from ':modules/statistics/store/statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { DatePickerType } from ':shared/enums/date-pickers';
import { ViewBy } from ':shared/enums/view-by.enum';
import { Restaurant } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum SeoInsightsTabs {
    SEO_KEYWORDS,
    SEO_IMPRESSIONS,
}
@Component({
    selector: 'app-statistics-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FiltersComponent,
        GmbInsightsComponent,
        StatisticsKeywordsDetailComponent,
        StatisticsSeoKeywordsV3Component,
        MatButtonModule,
        MatTabsModule,
        TranslateModule,
        KeywordSearchImpressionsComponent,
    ],
})
export class SeoComponent implements OnInit {
    private readonly _scrollableContainer = viewChild<ElementRef>('scrollableContainer');

    readonly screenSizeService = inject(ScreenSizeService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _heapService = inject(HeapService);
    private readonly _store = inject(Store);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _usersContext = inject(UsersContext);

    readonly InsightsChart = InsightsChart;
    readonly DatePickerType = DatePickerType;

    readonly isKeywordsLoading = signal(true);
    readonly isGmbInsightsLoading = signal(true);
    readonly isKeywordSearchImpressionsLoading = signal(true);

    readonly isLoading = computed(() => {
        if (this.isKeywordsInsightsV2Enabled()) {
            if (this.selectedTabIndex() === SeoInsightsTabs.SEO_KEYWORDS) {
                return this.isKeywordsLoading() || this.isKeywordSearchImpressionsLoading();
            }
            return this.isGmbInsightsLoading();
        }
        return this.isKeywordsLoading() || this.isGmbInsightsLoading();
    });

    readonly isKeywordsInsightsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-keywords-insights-v2'),
        {
            initialValue: false,
        }
    );

    readonly datePickerType: WritableSignal<DatePickerType> = signal(DatePickerType.MONTH_YEAR);
    readonly selectedTabIndex: WritableSignal<number> = signal(SeoInsightsTabs.SEO_KEYWORDS);

    chartOptions: ChartOptions = {
        [InsightsChart.ACTIONS]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.APPARITIONS]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.KEYWORDS]: {
            tableSortOptions: undefined,
        },
    };

    readonly PlatformFilterPage = PlatformFilterPage;

    constructor() {
        this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((params) => {
            const tab = params['tab'];
            const expectedValues = Object.values(SeoInsightsTabs).map((value) => value.toString());

            if (tab && expectedValues.includes(tab.toUpperCase())) {
                if (isInteger(+tab)) {
                    this.selectedTabIndex.set(tab);
                } else {
                    this.selectedTabIndex.set(SeoInsightsTabs[tab.toUpperCase() as keyof typeof SeoInsightsTabs]);
                }
            }
        });

        effect(
            () => {
                const currentTabIndex = this.selectedTabIndex();
                this._router.navigate([], {
                    relativeTo: this._activatedRoute,
                    queryParams: { tab: currentTabIndex },
                });
            },
            { allowSignalWrites: true }
        );

        combineLatest([
            this._restaurantsService.restaurantSelected$,
            toObservable(this._scrollableContainer).pipe(
                filter((scrollableContainer) => isNotNil(scrollableContainer)),
                take(1)
            ),
        ])
            .pipe(
                filter(([restaurant, scrollableContainer]) => isNotNil(restaurant) && isNotNil(scrollableContainer)),
                switchMap(([_, scrollableContainer]: [Restaurant, ElementRef]) =>
                    fromEvent(scrollableContainer.nativeElement, 'scroll').pipe(take(1))
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(() => {
                this._heapService.track(HeapEventName.TRACKING_SCROLL_SEO_INSIGHTS, {
                    restaurantId: this._restaurantsService.currentRestaurant?._id?.toString(),
                    userEmail: this._usersContext.currentUser()?.email ?? '',
                    userId: this._usersContext.currentUser()?.id ?? '',
                    tab: this.isKeywordsInsightsV2Enabled() ? this.selectedTabIndex() : 'v1',
                });
            });
    }

    ngOnInit(): void {
        this._heapService.track(HeapEventName.TRACKING_VIEW_SEO_INSIGHTS, {
            restaurantId: this._restaurantsService.currentRestaurant?._id?.toString(),
            userEmail: this._usersContext.currentUser()?.email ?? '',
            userId: this._usersContext.currentUser()?.id ?? '',
            tab: this.isKeywordsInsightsV2Enabled() ? this.selectedTabIndex() : 'v1',
        });
    }

    openStatisticsDownload(): void {
        const insightsTab = this.isKeywordsInsightsV2Enabled()
            ? this.selectedTabIndex() === SeoInsightsTabs.SEO_KEYWORDS
                ? InsightsTab.SEO_KEYWORDS
                : InsightsTab.SEO
            : InsightsTab.SEO;

        combineLatest([
            this._store.select(selectDatesFilter).pipe(take(1)),
            this._store.select(selectMonthYearPeriodFilter).pipe(take(1)),
            this._store.select(selectComparisonPeriodFilter).pipe(take(1)),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, monthYearPeriod, comparisonPeriod]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }

                    const filters =
                        this.isKeywordsInsightsV2Enabled() && this.selectedTabIndex() === SeoInsightsTabs.SEO_KEYWORDS
                            ? { dates: this._getDatesFromMonthYearPeriod(monthYearPeriod), monthYearPeriod, comparisonPeriod }
                            : { dates: { startDate, endDate }, comparisonPeriod };

                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: insightsTab,
                                filters,
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                tableSortOptions: value,
            },
        };
    }

    handleTabChange(event: number): void {
        this.selectedTabIndex.set(event);
        switch (event) {
            case SeoInsightsTabs.SEO_KEYWORDS:
                this._heapService.track(HeapEventName.TRACKING_CLICK_SEO_INSIGHTS_KEYWORDS_TAB);
                this.datePickerType.set(DatePickerType.MONTH_YEAR);
                break;
            case SeoInsightsTabs.SEO_IMPRESSIONS:
                this._heapService.track(HeapEventName.TRACKING_CLICK_SEO_INSIGHTS_GMB_TAB);
                this.datePickerType.set(DatePickerType.GROUPED_DATE);
                break;
        }
    }

    private _getDatesFromMonthYearPeriod(monthYearPeriod: MonthYearPeriod): { startDate: Date; endDate: Date } {
        return {
            startDate: DateTime.fromObject(monthYearPeriod.startMonthYear).startOf('month').toJSDate(),
            endDate: DateTime.fromObject(monthYearPeriod.endMonthYear).endOf('month').toJSDate(),
        };
    }
}
