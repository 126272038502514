import { ChangeDetectionStrategy, Component, effect, inject, input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { round } from 'lodash';

import { TimeInMilliseconds } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { RoiPerformanceScoreDetails } from ':shared/models/roi-performance-score.model';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { AggregatedTipsModalComponent } from '../../aggregated-tips-modal/aggregated-tips-modal.component';
import { AggregatedPerformanceKpisData } from '../../roi.interface';

enum TableField {
    RESTAURANT_NAME = 'restaurantName',
    PERFORMANCE_SCORE = 'performanceScore',
    REVIEW_COUNT = 'reviewCount',
    ANSWERED_REVIEW_COUNT = 'answeredReviewCount',
    GOOGLE_SCORE = 'googleScore',
    POSITIVE_REVIEW_COUNT = 'positiveReviewCount',
    AVERAGE_ANSWER_TIME = 'averageAnswerTime',
    KEYWORD_SCORE = 'keywordScore',
    KEYWORDS_IN_TOP_TEN = 'keywordsInTopTen',
    GOOGLE_POST_COUNT = 'googlePostCount',
    SOCIAL_MEDIA_POST_COUNT = 'socialMediaPostCount',
    SOCIAL_IMPRESSIONS = 'socialImpressionsCount',
    VIEW_TIPS = 'viewTips',
}

const DEFAULT_SORT: { active: TableField; direction: SortDirection } = {
    active: TableField.PERFORMANCE_SCORE,
    direction: ChartSortBy.DESC,
};

interface DataSource {
    restaurant: Restaurant;
    restaurantLogo: string;
    evolution: number;
    isMissingData: boolean;
    [TableField.RESTAURANT_NAME]: string;
    [TableField.PERFORMANCE_SCORE]: number;
    [TableField.REVIEW_COUNT]: number;
    [TableField.ANSWERED_REVIEW_COUNT]: number;
    [TableField.GOOGLE_SCORE]: number;
    [TableField.POSITIVE_REVIEW_COUNT]: number;
    [TableField.AVERAGE_ANSWER_TIME]: number;
    [TableField.KEYWORD_SCORE]: number;
    [TableField.KEYWORDS_IN_TOP_TEN]: number;
    [TableField.GOOGLE_POST_COUNT]: number;
    [TableField.SOCIAL_MEDIA_POST_COUNT]: number;
    [TableField.SOCIAL_IMPRESSIONS]: number;
}

@Component({
    selector: 'app-aggregated-performance-table',
    templateUrl: './aggregated-performance-table.component.html',
    styleUrls: ['./aggregated-performance-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatTableModule,
        MatSortModule,
        MatIconModule,
        TranslateModule,
        NumberEvolutionComponent,
        ShortNumberPipe,
        ImagePathResolverPipe,
        MatTooltipModule,
        MatTooltipModule,
    ],
})
export class AggregatedPerformanceTableComponent {
    performanceKpisTableData = input.required<AggregatedPerformanceKpisData>();

    private readonly _router = inject(Router);
    private readonly _customDialogService = inject(CustomDialogService);
    readonly screenSizeService = inject(ScreenSizeService);

    readonly SvgIcon = SvgIcon;
    readonly TableField = TableField;
    readonly DEFAULT_SORT = DEFAULT_SORT;
    readonly displayedColumns = Object.values(TableField);

    readonly dataSource: MatTableDataSource<DataSource> = new MatTableDataSource();

    constructor() {
        effect(() => {
            this.dataSource.data = this._computeDataSource(this.performanceKpisTableData());
        });
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
        }
    }

    redirectToRestaurantRoiStatsPage(restaurant: Restaurant): void {
        this._router.navigate([`/restaurants/${restaurant.id}/statistics/roi`]);
    }

    private _computeDataSource(data: AggregatedPerformanceKpisData): DataSource[] {
        if (!data) {
            return [];
        }

        return data.map((d) => {
            const performanceData = new RoiPerformanceScoreDetails(d.performanceKpis ?? {}, []);
            return {
                restaurant: d.restaurant,
                restaurantLogo: d.restaurant?.logo?.urls?.small ?? d.restaurant?.logo?.urls?.original ?? '',
                evolution: round(performanceData.performanceScoreEvolution, 0),
                isMissingData: d.isMissingData ?? false,
                [TableField.RESTAURANT_NAME]: d.restaurant?.internalName ?? d.restaurant?.name ?? '',
                [TableField.PERFORMANCE_SCORE]: round(performanceData.performanceScore, 0),
                [TableField.REVIEW_COUNT]: performanceData.receivedReviewsCount.value,
                [TableField.ANSWERED_REVIEW_COUNT]: performanceData.answeredReviewsRatio.value,
                [TableField.GOOGLE_SCORE]: performanceData.gmbRating.value,
                [TableField.POSITIVE_REVIEW_COUNT]: performanceData.positiveReviewsRatio.value,
                [TableField.AVERAGE_ANSWER_TIME]: Math.floor(performanceData.averageAnswerTime.value / TimeInMilliseconds.DAY),
                [TableField.KEYWORD_SCORE]: performanceData.keywordScore.value,
                [TableField.KEYWORDS_IN_TOP_TEN]: round(performanceData.keywordsInTopTen.value, 0),
                [TableField.GOOGLE_POST_COUNT]: performanceData.gmbPostCount.value,
                [TableField.SOCIAL_MEDIA_POST_COUNT]: performanceData.facebookPostCount.value + performanceData.instagramPostCount.value,
                [TableField.SOCIAL_IMPRESSIONS]: performanceData.socialImpressionsCount.value,
            };
        });
    }

    openRestaurantTips(restaurant: Restaurant): void {
        this._customDialogService
            .open<AggregatedTipsModalComponent>(AggregatedTipsModalComponent, {
                width: '700px',
                height: '440px',
                data: {
                    restaurant,
                },
            })
            .afterClosed()
            .subscribe();
    }
}
