import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { compact, uniq } from 'lodash';
import {
    catchError,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';

import {
    getDateRangeFromMalouComparisonPeriod,
    isNotNil,
    MalouComparisonPeriod,
    PlatformFilterPage,
    PlatformKey,
} from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, PostsWithInsightsByPlatforms, Restaurant } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Injectable({
    providedIn: 'root',
})
export class SocialNetworksPostsInsightsContext {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _postsService = inject(PostsService);
    private readonly _store = inject(Store);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _destroyRef = inject(DestroyRef);

    readonly statisticsFilters$: Observable<StatisticsState['filters']> = this._store.select(StatisticsSelector.selectFilters);
    readonly selectedRestaurant$ = this._restaurantsService.restaurantSelected$.pipe(
        distinctUntilChanged((prev, curr) => prev?._id === curr?._id)
    );

    readonly httpError: WritableSignal<string | null> = signal(null);
    readonly isLoading = signal(true);
    readonly areAllPlatformsInError: WritableSignal<boolean> = signal(false);
    readonly platformsErrorTooltip: WritableSignal<string | null> = signal(null);
    readonly hasDate: WritableSignal<boolean> = signal(false);

    readonly currentPostsWithInsightsByPlatform: WritableSignal<PostsWithInsightsByPlatforms | null> = signal(null);
    readonly previousPostsWithInsightsByPlatform: WritableSignal<PostsWithInsightsByPlatforms | null> = signal(null);
    readonly previousPeriodDates: WritableSignal<{ startDate: string; endDate: string } | null> = signal(null);
    readonly comparisonPeriod: WritableSignal<MalouComparisonPeriod> = signal(MalouComparisonPeriod.PREVIOUS_PERIOD);

    readonly postsWithInsights$ = combineLatest([this.selectedRestaurant$, this.statisticsFilters$]).pipe(
        filter(
            ([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) =>
                !!restaurant &&
                isDateSetOrGenericPeriod(statisticsFilters.dates) &&
                isNotNil(statisticsFilters.dates.startDate) &&
                isNotNil(statisticsFilters.dates.endDate) &&
                statisticsFilters.isFiltersLoaded
        ),
        map(([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) => [
            restaurant,
            {
                startDate: statisticsFilters.dates.startDate,
                endDate: statisticsFilters.dates.endDate,
            },
            statisticsFilters.platforms[PlatformFilterPage.SOCIAL_NETWORKS],
            statisticsFilters.comparisonPeriod,
        ]),
        tap(() => this._reset()),
        switchMap(
            ([restaurant, dates, platformKeys, comparisonPeriod]: [Restaurant, DatesAndPeriod, PlatformKey[], MalouComparisonPeriod]) => {
                const startDate = dates.startDate as Date;
                const endDate = dates.endDate as Date;
                let previousPeriodDates: {
                    startDate: Date;
                    endDate: Date;
                } | null = null;
                const dateRangeFromMalouComparisonPeriod = getDateRangeFromMalouComparisonPeriod({
                    comparisonPeriod,
                    dateFilters: {
                        startDate,
                        endDate,
                    },
                    restaurantStartDate: new Date(restaurant.createdAt),
                });
                if (dateRangeFromMalouComparisonPeriod.startDate && dateRangeFromMalouComparisonPeriod.endDate) {
                    previousPeriodDates = {
                        startDate: dateRangeFromMalouComparisonPeriod.startDate,
                        endDate: dateRangeFromMalouComparisonPeriod.endDate,
                    };
                } else {
                    const previousPeriod = getDateRangeFromMalouComparisonPeriod({
                        comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        dateFilters: {
                            startDate,
                            endDate,
                        },
                    });
                    previousPeriodDates = {
                        startDate: previousPeriod.startDate!,
                        endDate: previousPeriod.endDate!,
                    };
                }
                const { startDate: previousStartDate, endDate: previousEndDate } = previousPeriodDates;
                const { _id: restaurantId } = restaurant;
                return forkJoin([
                    this._postsService.getPostsWithInsights(restaurantId, platformKeys, startDate, endDate),
                    this._postsService.getPostsWithInsights(restaurantId, platformKeys, previousStartDate, previousEndDate),
                    of(platformKeys),
                    of(previousPeriodDates),
                    of(comparisonPeriod),
                ]);
            }
        ),
        filter(([currentPostsWithInsightsByPlatformResponseData]) => !!currentPostsWithInsightsByPlatformResponseData.data),
        map(
            ([
                currentPostsWithInsightsByPlatformResponseData,
                previousPostsWithInsightsByPlatformResponseData,
                platformKeys,
                previousPeriodDates,
                comparisonPeriod,
            ]) => {
                const currentPostsWithInsightsByPlatform = currentPostsWithInsightsByPlatformResponseData.data;
                const previousPostsWithInsightsByPlatform = previousPostsWithInsightsByPlatformResponseData.data;

                const platformsInError: PlatformKey[] = this._getPlatformsInError(currentPostsWithInsightsByPlatform, platformKeys);
                if (platformsInError.length) {
                    this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                }
                if (platformKeys.length === platformsInError.length) {
                    this.areAllPlatformsInError.set(true);
                    this.hasDate.set(false);
                } else {
                    this.previousPeriodDates.set({
                        startDate: previousPeriodDates.startDate?.toISOString(),
                        endDate: previousPeriodDates.endDate?.toISOString(),
                    });
                    this.currentPostsWithInsightsByPlatform.set(currentPostsWithInsightsByPlatform);
                    this.previousPostsWithInsightsByPlatform.set(previousPostsWithInsightsByPlatform);
                    this.comparisonPeriod.set(comparisonPeriod);
                }
                this.isLoading.set(false);
            }
        ),
        catchError((error) => {
            this.httpError.set(error);
            this.hasDate.set(false);
            this.isLoading.set(false);
            return of(EMPTY);
        }),
        shareReplay(1),
        takeUntilDestroyed(this._destroyRef)
    );

    private _reset(): void {
        this.httpError.set(null);
        this.isLoading.set(true);
        this.platformsErrorTooltip.set(null);
        this.areAllPlatformsInError.set(false);
    }

    private _getPlatformsInError(
        postsWithInsightsByPlatforms: PostsWithInsightsByPlatforms,
        filteredPlatforms: PlatformKey[]
    ): PlatformKey[] {
        const postsWithInsightsPlatformsError = postsWithInsightsByPlatforms
            .map((postsWithInsightsByPlatform) =>
                Object.entries(postsWithInsightsByPlatform).map(([key, value]) =>
                    value.error && filteredPlatforms.includes(key as PlatformKey) ? (key as PlatformKey) : null
                )
            )
            .flat()
            .filter(Boolean);
        return compact(uniq([...postsWithInsightsPlatformsError]));
    }

    private _getPlatformsErrorTooltip(platformsInError: PlatformKey[]): string {
        return platformsInError.map((platform) => this._enumTranslatePipe.transform(platform, 'platform_key')).join(', ');
    }
}
