import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MediaType } from '@malou-io/package-utils';

import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-facebook-reel-preview',
    templateUrl: './facebook-reel-preview.component.html',
    standalone: true,
    imports: [MatIconModule, TranslateModule, ImageViewerComponent, ImagePathResolverPipe, HtmlTagPipe],
    styleUrls: ['./facebook-reel-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookReelPreviewComponent {
    readonly text = input<string>('');
    readonly media = input<EditionMedia>();
    readonly username = input<string>('');
    readonly profilePicture = input<string | undefined>();
    readonly hashtags = input<string[]>([]);

    readonly SvgIcon = SvgIcon;
    readonly MediaType = MediaType;

    readonly formattedText = computed(() => this._formatText(this.text(), this.hashtags()));

    private _formatText(text: string, hashtags: string[]): string {
        const hashtagsText = hashtags.join(' ');
        const hashtagsWithMediumFontWeight = !!hashtagsText ? `<span style="font-weight: 600;">${hashtagsText}</span>` : '';
        return !!text ? `${text} ${hashtagsWithMediumFontWeight}` : hashtagsWithMediumFontWeight;
    }
}
