<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [displayWith]="displayWith()"
    [itemBuilder]="itemBuilder()"
    [idPrefix]="idPrefix()"
    [getIdSuffixFn]="getIdSuffixFn()"
    [inputReadOnly]="inputReadOnly()"
    [testId]="testId()"
    [theme]="theme()"
    [inputWithFixedWidth]="inputWithFixedWidth()"
    [displayedOptionCount]="displayedOptionCount()"
    (selectBaseChange)="selectChange.emit($event)">
    @if (simpleSelectedValueTemplateInput(); as simpleSelectedValueTemplateInput) {
        <ng-template let-value="value" #simpleSelectedValueTemplate>
            <ng-container
                [ngTemplateOutlet]="simpleSelectedValueTemplateInput"
                [ngTemplateOutletContext]="{ value: selectedValueDisplayWith()(value) }"></ng-container>
        </ng-template>
    }

    @if (optionTemplateInput(); as optionTemplateInput) {
        <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
            <ng-container [ngTemplateOutlet]="optionTemplateInput" [ngTemplateOutletContext]="{ value, isValueSelected }"></ng-container>
        </ng-template>
    }
</app-select-base>
