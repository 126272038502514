import { z } from 'zod';

import { objectIdValidator } from '../utils/validators';

export const createSocialPostBodyValidator = z
    .object({
        restaurantId: objectIdValidator,
        date: z.string().datetime().optional(),
        isReel: z.boolean().optional(),
    })
    .transform((data) => ({
        restaurantId: data.restaurantId,
        date: data.date ? new Date(data.date) : undefined,
        isReel: !!data.isReel,
    }));
export type CreateSocialPostDto = z.infer<typeof createSocialPostBodyValidator>;
