import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { GetKeywordRankingsForOneRestaurantV3ResponseDto } from '@malou-io/package-dto';
import {
    errorReplacer,
    GeoSamplePlatform,
    getMostRecentValidRank,
    isNotNil,
    KeywordPopularity,
    LuxonFormats,
    MONTH_DAY_TO_FETCH_KEYWORD_SEARCH_IMPRESSIONS,
    RankingPosition,
    RankingPositionOutOf,
    RestaurantRankingFormat,
    Role,
} from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { KeywordEvolutionMiniComponent } from ':modules/keywords/keyword-evolution-mini/keyword-evolution-mini.component';
import { KeywordEvolutionComponent } from ':modules/keywords/keyword-evolution/keyword-evolution.component';
import { RankingsCompetitorsListComponent } from ':modules/keywords/rankings-competitors-list/rankings-competitors-list.component';
import { UpdateKeywordModalComponent } from ':modules/keywords/update-keyword-modal/update-keyword-modal.component';
import { ApparitionAdviceTooltipComponent } from ':modules/statistics/seo/statistics-keywords-detail/apparition-advice-tooltip/apparition-advice-tooltip.component';
import {
    ALWAYS_DISPLAYED_KEYWORDS_DETAIL_COLUMNS,
    KeywordsDetailsStatsTableRow,
    KeywordsDetailStatsColumn,
} from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords.interface';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { isSameDay } from ':shared/helpers';
import { SortOption } from ':shared/interfaces/sort.interface';
import { Keyword, KeywordImpression, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum KeywordsDetailSortBy {
    KEYWORD = 'keyword',
    POSITION = 'position',
    APPARITION = 'apparition',
    POPULARITY = 'popularity',
}
type KeywordsDetailSortOption = SortOption<KeywordsDetailSortBy>;
const RESULTS_TOO_FAR_ERROR = 'results_too_far';

@Component({
    selector: 'app-statistics-keywords-detail',
    templateUrl: './statistics-keywords-detail.component.html',
    styleUrls: ['./statistics-keywords-detail.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        KeywordEvolutionMiniComponent,
        SkeletonComponent,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        EmojiPathResolverPipe,
        IllustrationPathResolverPipe,
        StatisticsHttpErrorPipe,
        KeywordsPopularityComponent,
        NumberEvolutionComponent,
        ShortNumberPipe,
        ApparitionAdviceTooltipComponent,
    ],
    providers: [EmojiPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsKeywordsDetailComponent implements OnInit {
    readonly isCompetitorsColumnShown = input<boolean>(true);
    readonly tableSort = input<Sort>();
    readonly tableSortChange = output<Sort>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _keywordsService = inject(KeywordsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _router = inject(Router);
    private readonly _route = inject(ActivatedRoute);
    private readonly _emojiPathResolverPipe = inject(EmojiPathResolverPipe);
    private readonly _destroyRef = inject(DestroyRef);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly SvgIcon = SvgIcon;
    readonly Emoji = Emoji;
    readonly KeywordsDetailStatsColumn = KeywordsDetailStatsColumn;
    readonly Illustration = Illustration;
    readonly RESULTS_TOO_FAR_ERROR = RESULTS_TOO_FAR_ERROR;

    readonly displayedColumns: Signal<KeywordsDetailStatsColumn[]> = computed(() => [
        ...ALWAYS_DISPLAYED_KEYWORDS_DETAIL_COLUMNS,
        ...(this.isCompetitorsColumnShown() ? [KeywordsDetailStatsColumn.COMPETITORS] : []),
    ]);

    readonly keywords: WritableSignal<Keyword[] | null> = signal(null);
    readonly keywordRankings: WritableSignal<GetKeywordRankingsForOneRestaurantV3ResponseDto | null> = signal(null);
    restaurant: Restaurant;

    readonly isMalouAdmin: Signal<boolean> = toSignal(
        this._store.select(selectUserInfos).pipe(
            filter(isNotNil),
            map((infos) => infos.role === Role.ADMIN)
        ),
        { initialValue: false }
    );

    dataSource: MatTableDataSource<KeywordsDetailsStatsTableRow>;
    selectedSortOption: KeywordsDetailSortOption;
    sortDirection = -1;
    sortOptions: KeywordsDetailSortOption[] = [];
    sort: Sort = { active: KeywordsDetailSortBy.KEYWORD, direction: ChartSortBy.ASC };

    refreshStarted = false;
    httpError: any;
    readonly isLoading = signal(true);

    readonly endDate: WritableSignal<Date | null> = signal(null);

    readonly doesPeriodEndToday: Signal<Boolean> = computed(() => {
        const endDate = this.endDate();
        return endDate ? isSameDay(endDate, new Date()) : false;
    });

    private readonly _selectMonthYearPeriodFilter$ = this._store.select(StatisticsSelector.selectMonthYearPeriodFilter);
    private readonly _refreshSubject$: Subject<void> = new Subject();

    constructor() {
        this.dataSource = new MatTableDataSource<KeywordsDetailsStatsTableRow>();
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    default:
                    case KeywordsDetailStatsColumn.KEYWORD:
                        return item.keyword;
                    case KeywordsDetailStatsColumn.POSITION:
                        return item.currentPosition?.rank ?? Infinity;
                    case KeywordsDetailStatsColumn.APPARITION:
                        return item.impression ?? 0;
                    case KeywordsDetailStatsColumn.POPULARITY:
                        return item.popularity;
                }
            };

            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        this.sortOptions = [
            { value: KeywordsDetailSortBy.KEYWORD, text: this._translateService.instant('keywords.keywords') },
            { value: KeywordsDetailSortBy.POSITION, text: this._translateService.instant('keywords.maps_position') },
        ];

        this.selectedSortOption = this.sortOptions[0];

        combineLatest([this._restaurantsService.restaurantSelected$, this._selectMonthYearPeriodFilter$, this._refreshSubject$])
            .pipe(
                filter(([restaurant, _]) => isNotNil(restaurant)),
                map(([restaurant, selectMonthYearPeriodFilter]) => {
                    const startDate = DateTime.fromObject(selectMonthYearPeriodFilter.startMonthYear).startOf('month').toJSDate();
                    const endDate = DateTime.fromObject(selectMonthYearPeriodFilter.endMonthYear).endOf('month').toJSDate();
                    return [restaurant, startDate, endDate];
                }),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, startDate, endDate]: [Restaurant, Date, Date]) => {
                    const { _id: restaurantId } = restaurant;
                    this.endDate.set(endDate);

                    return forkJoin([
                        // keywords
                        this._keywordsService.getKeywordsByRestaurantId(restaurantId).pipe(map((res) => res.data)),

                        // currentKeywordRankings
                        this._keywordsService.getKeywordRankingsForOneRestaurantV3({
                            restaurantId,
                            startDate,
                            endDate,
                            platformKey: GeoSamplePlatform.GMAPS,
                        }),

                        // restaurant
                        of(restaurant),
                        // dates
                        of({ startDate, endDate }),
                    ]).pipe(
                        catchError((error) => {
                            this.httpError = error;
                            this.hasDataChange.emit(false);
                            this.isLoading.set(false);
                            return EMPTY;
                        })
                    );
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([keywords, currentKeywordRankings, restaurant, dates]) => {
                // important for CSV exports:
                this._store.dispatch(StatisticsActions.editKeywords({ keywords, stats: currentKeywordRankings.keywords }));

                this.keywordRankings.set(currentKeywordRankings);
                this.keywords.set(keywords);
                this.restaurant = restaurant;
                this.dataSource.data = this._mapKeywordsToRankingTableDataRows(keywords, currentKeywordRankings, dates);
                this.dataSource._updateChangeSubscription();

                this.isLoading.set(false);
            });

        const tableSort = this.tableSort();
        if (tableSort) {
            this.sort = tableSort;
        }

        this._refreshSubject$.next();
    }

    onSortChange(sort: Sort): void {
        if (sort.direction?.length) {
            this.tableSortChange.emit(sort);
        }
    }

    changeSortOrder(): void {
        this.sortDirection = this.sortDirection === 1 ? -1 : 1;
        this.sortKeywords();
    }

    sortKeywords(sortOption: KeywordsDetailSortOption = this.selectedSortOption): void {
        this.selectedSortOption = sortOption;

        const sortedData = this.dataSource.data.sort((a, b) => {
            const sortDirection = this.sortDirection === 1 ? 1 : -1;
            return this._sortActiveColumn(this.selectedSortOption.value, a, b, sortDirection);
        });

        this.dataSource.data = sortedData;
    }

    isErrorFixable(error?: string): boolean {
        return !!error?.match(/exceeded your rate-limit/);
    }

    getErrorDetail = (error: string): string => {
        if (!error) {
            return this._translateService.instant('statistics.seo.keywords.position_column.cell.no_info');
        }
        switch (error) {
            case RESULTS_TOO_FAR_ERROR:
                return this._translateService.instant('keywords.results_too_far');
            default:
                return error;
        }
    };

    isRowCurrentPositionValid(row: KeywordsDetailsStatsTableRow): boolean {
        return row.currentPosition?.rank !== Infinity;
    }

    getPrettyLang = (lang: string): string => this._translateService.instant(`header.langs.${lang}`);

    getEmojiAndTextFromPosition = (position: RankingPositionOutOf): { emojiSrc: string; title: string; caption: string } => {
        switch (true) {
            case position.rank === 1:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('trophy'),
                    title: this._translateService.instant('keywords.positions.first'),
                    caption: this._translateService.instant('keywords.positions.first_subtitle'),
                };
            case position.rank === 2:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('second_place_medal'),
                    title: this._translateService.instant('keywords.positions.second', { position: position.rank }),
                    caption: this._translateService.instant('keywords.positions.second_subtitle'),
                };
            case position.rank === 3:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('third_place_medal'),
                    title: this._translateService.instant('keywords.positions.third', { position: position.rank }),
                    caption: this._translateService.instant('keywords.positions.third_subtitle'),
                };
            case position.rank <= 10:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('flexed_biceps'),
                    title: this._translateService.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translateService.instant('keywords.positions.top10_subtitle'),
                };
            case position.rank <= 15:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('victory_hand'),
                    title: this._translateService.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translateService.instant('keywords.positions.top15_subtitle'),
                };
            case position.rank <= 20:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('raising_hands'),
                    title: this._translateService.instant('keywords.positions.top20', { position: position.rank }),
                    caption: this._translateService.instant('keywords.positions.top20_subtitle'),
                };

            default:
                return {
                    emojiSrc: this._emojiPathResolverPipe.transform('face_in_clouds'),
                    title: `+${position.outOf}`,
                    caption:
                        position.outOf === 20
                            ? this._translateService.instant('keywords.positions.other_subtitle')
                            : this._translateService.instant('keywords.positions.under20_results_subtitle', { position: position.outOf }),
                };
        }
    };

    clarifyError(err: any): string {
        if (err && err.error?.msg) {
            if (err.error.msg.includes('24 hours')) {
                return this._translateService.instant('keywords.wait24h');
            }
            return err.error.msg;
        }
        if (typeof err === 'string') {
            return err;
        }
        if (err?.error?.message?.match(/Place ID is no longer valid/)) {
            return this._translateService.instant('keywords.dead_restaurant');
        }
        return this._translateService.instant('keywords.error_unknown') + ' : ' + JSON.stringify(err, errorReplacer) + String(err);
    }

    canRefresh(keyword: KeywordsDetailsStatsTableRow): boolean {
        const does24HoursHavePassed = DateTime.local().diff(DateTime.fromJSDate(keyword.lastRefresh), 'hours').hours > 24;
        return (does24HoursHavePassed || !keyword.lastRefresh) && !keyword.isWaiting;
    }

    refreshKeyword(keywordRow: KeywordsDetailsStatsTableRow): void {
        if (this.refreshStarted) {
            return;
        }
        this.refreshStarted = true;
        keywordRow.isWaiting = true;
        this.dataSource._updateChangeSubscription();
        const restaurantKeywordId = keywordRow.restaurantKeywordId;

        this._keywordsService.refreshRankingForKeywordId(restaurantKeywordId).subscribe({
            next: (result) => {
                this.refreshStarted = false;
                if (result?.length) {
                    this._refreshSubject$.next();
                } else {
                    keywordRow.isWaiting = false;
                }
            },
            error: (err) => {
                this.refreshStarted = false;
                this._toastService.openErrorToast(this.clarifyError(err));
                keywordRow.isWaiting = false;
            },
        });
    }

    openKeywordEvolution(keywordText: string): void {
        const keywordRankings = this.keywordRankings();
        if (!keywordRankings) {
            return;
        }
        const keywordStats = keywordRankings.keywords.find((k) => k.name === keywordText);
        if (!keywordStats) {
            return;
        }

        this._customDialogService
            .open(KeywordEvolutionComponent, {
                height: 'unset',
                data: {
                    positions: keywordStats.rankHistory.map(
                        (p): RankingPosition => ({
                            position: { rank: p.rank ?? Infinity, outOf: p.outOf },
                            createdAt: new Date(p.fetchDate),
                        })
                    ),
                    keywordText,
                    restaurantId: this.restaurant._id,
                    restaurantPlaceId: this.restaurant.placeId,
                    applyFilter: (event: Event) => {
                        const filterValue = (event.target as HTMLInputElement).value;
                        this.dataSource.filter = filterValue.trim().toLowerCase();
                    },
                },
            })
            .afterClosed();
    }

    openCompetitorsList(keyword: string, ranking: RestaurantRankingFormat[], indexPosition?: number): void {
        if (!indexPosition) {
            return;
        }
        this._customDialogService
            .open(RankingsCompetitorsListComponent, {
                height: 'unset',
                data: {
                    keyword,
                    ranking,
                    indexPosition,
                },
            })
            .afterClosed();
    }

    redirectToKeywordsPage(): void {
        this._router.navigate(['../resources/keywords/list'], { relativeTo: this._route.parent });
    }

    getApparitionsTooltip = (apparitions: number | null): string => {
        if (apparitions === null) {
            const formattedDate = DateTime.now()
                .plus({ month: 1 })
                .set({ day: MONTH_DAY_TO_FETCH_KEYWORD_SEARCH_IMPRESSIONS })
                .toFormat(LuxonFormats.dayMonth);
            return this._translateService.instant('keywords.apparitions.apparitions_available_soon_tooltip', { date: formattedDate });
        }
        return '';
    };

    openCustomVolumeModal(keyword: KeywordsDetailsStatsTableRow): void {
        this._customDialogService
            .open(UpdateKeywordModalComponent, {
                data: {
                    keywordToUpdate: keyword,
                    keywords: this.keywords(),
                    restaurantId: this.restaurant._id,
                },
                height: '275px',
            })
            .afterClosed()
            .subscribe({
                next: (newValue) => {
                    if (newValue) {
                        keyword.volume = newValue.volume;
                        keyword.popularity = newValue.popularity;
                    }
                },
            });
    }

    private _sortActiveColumn(
        value: KeywordsDetailSortBy,
        a: KeywordsDetailsStatsTableRow,
        b: KeywordsDetailsStatsTableRow,
        sortDirection: number
    ): number {
        switch (value) {
            case KeywordsDetailSortBy.KEYWORD:
                return a.keyword.toLowerCase().localeCompare(b.keyword.toLowerCase()) * sortDirection;
            case KeywordsDetailSortBy.POSITION:
                const aPosition = a.currentPosition && a.currentPosition.rank <= a.currentPosition.outOf ? a.currentPosition.rank : 999;
                const bPosition = b.currentPosition && b.currentPosition.rank <= b.currentPosition.outOf ? b.currentPosition.rank : 999;
                return (aPosition - bPosition) * sortDirection;
            case KeywordsDetailSortBy.APPARITION:
                return a.impression && b.impression ? (a.impression - b.impression) * sortDirection : 0;
            case KeywordsDetailSortBy.POPULARITY:
                const popularityOrder = [
                    KeywordPopularity.PENDING,
                    KeywordPopularity.LOW,
                    KeywordPopularity.MEDIUM,
                    KeywordPopularity.HIGH,
                ];
                return popularityOrder.indexOf(a.popularity) - popularityOrder.indexOf(b.popularity);
            default:
                return 0;
        }
    }

    private _reset(): void {
        this.httpError = null;
        this.isLoading.set(true);
        this.endDate.set(null);
    }

    private _mapKeywordsToRankingTableDataRows(
        keywords: Keyword[],
        keywordRankings: GetKeywordRankingsForOneRestaurantV3ResponseDto,
        dates: { startDate: Date; endDate: Date }
    ): KeywordsDetailsStatsTableRow[] {
        const rankingTableDataRows = keywords
            .filter((keyword) => keyword.selected)
            .map((keyword): KeywordsDetailsStatsTableRow | null => {
                const keywordStats = keywordRankings.keywords.find((k) => k.keywordId === keyword.keywordId);

                const impressions = this._filterImpressionsByDate(keyword.impressionsHistory, dates);
                const impressionsEvolution = this._getImpressionsEvolution(keyword, impressions);

                return {
                    keywordId: keyword.keywordId,
                    restaurantKeywordId: keyword.restaurantKeywordId,
                    keyword: keyword.text,
                    volumeFromAPI: keyword.volume,
                    volume: keyword.getNotNullVolume(),
                    currentPosition: keywordStats ? (getMostRecentValidRank(keywordStats)?.position ?? undefined) : undefined,
                    positions: keywordStats?.rankHistory.map((p) => ({
                        position: { rank: p.rank ?? Infinity, outOf: p.outOf },
                        createdAt: new Date(p.fetchDate),
                    })),
                    impression: impressions?.length ? impressions.reduce((acc, curr) => acc + curr.value, 0) : null,
                    ranking:
                        keywordStats?.localCompetitorsPodium.map(
                            (c) => new RestaurantRankingFormat(c.name, c.placeId, c.address, c.address)
                        ) ?? [],
                    isLoading: false,
                    lastRefresh: keyword.lastRefresh,
                    isWaiting: false,
                    popularity: keyword.getPopularity(keywords),
                    impressionsEvolution: impressionsEvolution ?? 0,
                    error: keywordStats?.error ?? false,
                    errorData: keywordStats?.issuesTechnicalDetails ?? '',
                };
            })
            .filter((r): r is KeywordsDetailsStatsTableRow => !!r);

        return rankingTableDataRows;
    }

    private _filterImpressionsByDate(impressions: KeywordImpression[], dates: { startDate: Date; endDate: Date }): KeywordImpression[] {
        return impressions.filter((impression) => {
            const impressionDate = new Date(impression.date);
            return impressionDate >= dates.startDate && impressionDate <= dates.endDate;
        });
    }

    private _getImpressionsEvolution(keyword: Keyword, currentImpressions: KeywordImpression[]): number | null {
        const previousImpressions = currentImpressions
            .map((impression) => {
                const impressionDate = DateTime.fromJSDate(impression.date);
                const previousMonth = impressionDate.minus({ month: 1 });
                return keyword.impressionsHistory.find((i) => DateTime.fromJSDate(i.date).hasSame(previousMonth, 'month'));
            })
            .filter(isNotNil);

        const currentImpressionsValue = currentImpressions.reduce((acc, curr) => acc + curr.value, 0);
        const previousImpressionsValue = previousImpressions.reduce((acc, curr) => acc + curr.value, 0);

        if (!previousImpressionsValue || !currentImpressionsValue) {
            return null;
        }

        return currentImpressionsValue - previousImpressionsValue;
    }
}
