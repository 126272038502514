import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CustomerNaming, FrenchTutoiementVouvoiement } from '@malou-io/package-utils';

import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-ai-general-review-settings-modal-tab',
    templateUrl: './ai-general-review-settings-modal-tab.component.html',
    styleUrls: ['./ai-general-review-settings-modal-tab.component.scss'],
    standalone: true,
    imports: [
        MatRadioModule,
        MatFormFieldModule,
        TranslateModule,
        InputTextComponent,
        SimpleSelectComponent,
        EnumTranslatePipe,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiGeneralReviewSettingsModalTabComponent {
    readonly aiSettingsContext = inject(AiSettingsContext);
    readonly aiReviewSettingsForm = input.required<
        FormGroup<{
            replyTone: FormControl<FrenchTutoiementVouvoiement>;
            catchphrase: FormControl<string>;
            customerNaming: FormControl<CustomerNaming>;
            shouldTranslateCatchphrase: FormControl<boolean>;
            signatures: FormArray<FormControl<string>>;
            restaurantKeywordIds: FormControl<string[]>;
            forbiddenWords: FormControl<string[]>;
            shouldTranslateSignature: FormControl<boolean>;
        }>
    >();

    get signatures(): FormArray<FormControl<string>> {
        return this.aiReviewSettingsForm().controls['signatures'] as FormArray<FormControl<string>>;
    }

    readonly CUSTOMER_NAMING_OPTIONS = Object.values(CustomerNaming);

    readonly FrenchTutoiementVouvoiement = FrenchTutoiementVouvoiement;
    readonly SvgIcon = SvgIcon;

    removeSignature(idx: number, isOnlyOne: boolean): void {
        if (isOnlyOne) {
            this.signatures.at(idx).setValue('');
            return;
        }
        this.signatures.removeAt(idx);
    }

    addSignature(): void {
        this.signatures.push(new FormControl('', { nonNullable: true }));
    }
}
