import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, Injector, input, OnInit, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { isNotNil, MalouErrorCode, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ToastService } from ':core/services/toast.service';
import { AccountManagedConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-modal.service';
import {
    ComputedValue,
    PlatformsConnectionBusinessSelectorComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-business-selector/platforms-connection-business-selector.component';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { SearchPlatformRestaurantsService } from ':modules/platforms/platforms-connection-modals/shared/services/search-platform-restaurants.service';
import { UpdateAccountManagementPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/update-account-managed-platform.service';
import {
    ExtractSocialIdFromUrlService,
    URLS_REGEXES,
} from ':modules/platforms/platforms-connection-modals/shared/steps/select-business-step/extract-social-id-from-url.service';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { Platform, PlatformSearch } from ':shared/models';

type AuthorizedPlatformKeys =
    | PlatformKey.TRIPADVISOR
    | PlatformKey.FOURSQUARE
    | PlatformKey.YELP
    | PlatformKey.LAFOURCHETTE
    | PlatformKey.RESY;

interface SelectBusinessSharedDataType {
    platformKey: AuthorizedPlatformKeys;
    socialId?: string;
}

@Component({
    selector: 'app-select-business-step',
    templateUrl: './select-business-step.component.html',
    styleUrls: ['./select-business-step.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        PlatformsConnectionBusinessSelectorComponent,
        MalouSpinnerComponent,
        NgTemplateOutlet,
        InputTextComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBusinessStepComponent
    extends BaseStepComponent<SelectBusinessSharedDataType, AccountManagedConnectionModalResult>
    implements OnInit
{
    private readonly _searchPlatformRestaurantsService = inject(SearchPlatformRestaurantsService);
    private readonly _updateAccountManagementPlatformService = inject(UpdateAccountManagementPlatformService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _extractSocialIdFromUrlService = inject(ExtractSocialIdFromUrlService);
    private readonly _injector = inject(Injector);
    private readonly _store = inject(Store);

    titleTranslationKey = input.required<string>();

    readonly ButtonStyle = ButtonStyle;
    readonly UrlPlaceholder: Record<AuthorizedPlatformKeys, string> = {
        [PlatformKey.TRIPADVISOR]: 'https://www.tripadvisor.fr/...',
        [PlatformKey.LAFOURCHETTE]: 'https://www.thefork.fr/restaurant/...',
        [PlatformKey.FOURSQUARE]: 'https://foursquare.com/v/...',
        [PlatformKey.YELP]: 'https://www.yelp.fr/biz/... https://biz.yelp.fr/biz_info/...',
        [PlatformKey.RESY]: 'https://resy.com/...',
    };

    platformSearchResults: Signal<PlatformSearch[] | null>;
    readonly selectedPlatformSearch = signal<PlatformSearch | null>(null);
    readonly platformKey = computed(() => this.sharedData().platformKey);

    readonly urlControl = new FormControl<string>('', [this._platformUrlValidator()]);

    currentPlatform: Signal<Platform | undefined>;

    readonly isUpdatingPlatform = signal<boolean>(false);

    ngOnInit(): void {
        this._initCurrentPlatform();
        this._initPlatformSearch();
        this._initSelectedPlatformSearch();
    }

    private _initCurrentPlatform(): void {
        const currentPlatform$ = this._store.select(selectCurrentPlatform({ platformKey: this.platformKey() })).pipe(filter(isNotNil));
        this.currentPlatform = toSignal(currentPlatform$, { injector: this._injector });
    }

    private _initPlatformSearch(): void {
        const platformSearchResults$ = this._searchPlatformRestaurantsService.execute(this.platformKey()).pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            })
        );
        this.platformSearchResults = toSignal(platformSearchResults$, { initialValue: null, injector: this._injector });
    }

    private _initSelectedPlatformSearch(): void {
        effect(
            () => {
                const platformSearchResults = this.platformSearchResults();
                const currentSocialId = this.currentPlatform()?.socialId;
                if (platformSearchResults && currentSocialId && this.selectedPlatformSearch() === null) {
                    const foundPlatformSearch = platformSearchResults.find((platformSearch) => platformSearch.socialId === currentSocialId);
                    this.selectedPlatformSearch.set(foundPlatformSearch ?? null);
                }
            },
            { allowSignalWrites: true, injector: this._injector }
        );
    }

    platformSearchDisplayWith(value: PlatformSearch): ComputedValue {
        return {
            title: value.name ?? '',
            subtitle: value.formattedAddress,
            link: value.socialUrl,
        };
    }

    platformSearchCompareWith(value: PlatformSearch): string {
        return value.socialId;
    }

    onValidate(): void {
        this.isUpdatingPlatform.set(true);

        this.getSocialId$().subscribe({
            next: (socialId) => {
                if (!socialId) {
                    this.isUpdatingPlatform.set(false);
                    this._toastService.openErrorToast(this._translateService.instant('common.error'));
                    return;
                }

                this._updateAccountManagementPlatformService.execute(this.platformKey(), socialId).subscribe({
                    next: () => {
                        this.isUpdatingPlatform.set(false);
                        this.updateSharedData.emit({ ...this.sharedData(), socialId });
                        this.goToStep.emit({ type: 'relative', value: 1 });
                    },
                    error: (err) => {
                        console.warn(err);
                        this.isUpdatingPlatform.set(false);
                        this._toastService.openErrorToast(this._translateService.instant('common.error'));
                    },
                });
            },
            error: (err) => {
                this.isUpdatingPlatform.set(false);
                if (err?.message === MalouErrorCode.FETCH_YELP_WEBSITE_FAILED) {
                    this._toastService.openErrorToast(this._translateService.instant('platforms.connection.cannot_connect_yelp'));
                    return;
                }
                this._toastService.openErrorToast(err?.error?.message ?? err.message);
            },
        });
    }

    onBusinessChange(value: PlatformSearch): void {
        this.selectedPlatformSearch.set(value);
    }

    getSocialId$(): Observable<string | null> {
        const url = this.urlControl.value;
        if (url) {
            // prioritize the url input field over the radio buttons
            return this._extractSocialIdFromUrlService.execute(this.platformKey(), url);
        }
        return of(this.selectedPlatformSearch()?.socialId ?? null);
    }

    private _platformUrlValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.pristine) {
                return null;
            }
            const urlRegex = URLS_REGEXES[this.platformKey()];
            const isValidUrl = urlRegex.test(control.value);
            if (!isValidUrl && this.platformKey() === PlatformKey.YELP) {
                const isValidYelpProUrl = URLS_REGEXES['yelpPro'].test(control.value);
                return isValidYelpProUrl ? null : { invalidUrl: true };
            }
            return isValidUrl ? null : { invalidUrl: true };
        };
    }
}
