import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, combineLatest, debounceTime, EMPTY, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { ReviewResponseDto } from '@malou-io/package-dto';
import { InsightsChart, isNotNil } from '@malou-io/package-utils';

import { PrivateReviewsService } from ':core/services/private-reviews.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PrivateReviewStatisticsData, WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { BoostersStatisticsData } from ':modules/statistics/boosters/boosters.interface';
import { PrivateReviewCountComponent } from ':modules/statistics/boosters/private-review-count/private-review-count.component';
import { ScanCountComponent } from ':modules/statistics/boosters/scan-count/scan-count.component';
import { BoostersDataFetchingService } from ':modules/statistics/boosters/services/get-boosters-data.service';
import { TotemsEstimatedReviewCountComponent } from ':modules/statistics/boosters/totems-estimated-review-count/totems-estimated-review-count.component';
import { WheelOfFortuneGiftsDistributionComponent } from ':modules/statistics/boosters/wheel-of-fortune-gifts-distribution/wheel-of-fortune-gifts-distribution.component';
import { WheelOfFortuneGiftsKpisComponent } from ':modules/statistics/boosters/wheel-of-fortune-gifts-kpis/wheel-of-fortune-gifts-kpis.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { DatesAndPeriod, FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, MalouPeriod, Nfc } from ':shared/models';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-boosters-pdf',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        FromToDateFormatterPipe,
        IncludesPipe,
        AsyncPipe,
        ScanCountComponent,
        TotemsEstimatedReviewCountComponent,
        WheelOfFortuneGiftsDistributionComponent,
        WheelOfFortuneGiftsKpisComponent,
        PrivateReviewCountComponent,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    templateUrl: './boosters-pdf.component.html',
    styleUrls: ['./boosters-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoostersPdfComponent {
    isLoadingBoosters: WritableSignal<boolean> = signal(true);
    isErrorBoosters: WritableSignal<boolean> = signal(false);
    isLoadingGifts: WritableSignal<boolean> = signal(true);
    isErrorGifts: WritableSignal<boolean> = signal(false);
    isPrivateReviewsLoading: WritableSignal<boolean> = signal(true);
    isPrivateReviewsError: WritableSignal<boolean> = signal(false);

    readonly InsightsChart = InsightsChart;
    readonly ViewBy = ViewBy;

    readonly restaurantTotems: WritableSignal<Nfc[]> = signal([]);
    totems$: Observable<Nfc[]>;
    dates$: Observable<DatesAndPeriod>;

    data$: Observable<BoostersStatisticsData>;
    filteredData$: Observable<BoostersStatisticsData>;
    totemData$: Observable<BoostersStatisticsData>;
    wheelOfFortuneData$: Observable<BoostersStatisticsData>;
    giftsData$: Observable<WheelOfFortuneGiftsStatisticsData>;
    privateReviewData$: Observable<PrivateReviewStatisticsData>;

    scanCountHasData = true;

    chartOptions: ChartOptions = {};
    displayedCharts: InsightsChart[] = [];
    startDate: Date;
    endDate: Date;
    nfcIds: string[] = [];

    boostersScanCountHasData = true;
    boostersEstimatedReviewCountHasData = true;
    boostersPrivateReviewCountHasData = true;
    wheelOfFortuneEstimatedReviewCountHasData = true;
    wheelOfFortuneGiftsDistributionHasData = true;
    wheelOfFortuneGiftsKpisHasData = true;

    selectedRestaurantTitle$: Observable<string>;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _destroyRef: DestroyRef,
        private readonly _boostersDataFetchingService: BoostersDataFetchingService,
        private readonly _privateReviewsService: PrivateReviewsService
    ) {
        this._getRouteParams();

        this._getRestaurantsTitle();

        this._getBoostersData();

        this._getChartsData();
    }

    private _getRouteParams(): void {
        const parsedQueryParams = parseInsightsRouteParams();
        const { dates, displayedCharts, chartOptions, nfcIds } = parsedQueryParams;

        this.chartOptions = chartOptions ?? {};
        this.displayedCharts = displayedCharts;

        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.nfcIds = nfcIds ?? [];
    }

    private _getRestaurantsTitle(): void {
        this.selectedRestaurantTitle$ = this._restaurantsService.restaurantSelected$.pipe(
            map((restaurant) => restaurant?.internalName ?? restaurant?.name ?? '')
        );
    }

    private _getBoostersData(): void {
        this.totems$ = this._boostersDataFetchingService.getTotems();

        this.data$ = combineLatest([
            of({ startDate: this.startDate, endDate: this.endDate, period: MalouPeriod.CUSTOM }),
            this.totems$,
        ]).pipe(
            debounceTime(500),
            tap(() => this.isLoadingBoosters.set(false)),
            filter(([dates]) => isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorBoosters.set(false);
                this.isLoadingBoosters.set(true);
            }),
            filter(([dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap(([dates, nfcs]: [DatesAndPeriod, Nfc[]]) => {
                const data = this._boostersDataFetchingService.getChartsData(nfcs, dates);
                this.isLoadingBoosters.set(false);
                return data;
            }),
            catchError((error) => {
                console.error('error >>', error);
                this.isErrorBoosters.set(true);
                this.isLoadingBoosters.set(false);
                return EMPTY;
            }),
            tap(() => setTimeout(() => this.isLoadingBoosters.set(false), 1000)),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _getChartsData(): void {
        this.giftsData$ = of({ startDate: this.startDate, endDate: this.endDate, period: MalouPeriod.CUSTOM }).pipe(
            filter((dates) => isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorGifts.set(false);
                this.isLoadingGifts.set(true);
            }),
            debounceTime(500),
            filter((dates) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap((dates) => {
                const data = this._boostersDataFetchingService.getGiftsData(dates);
                this.isLoadingGifts.set(false);
                return data;
            }),
            catchError(() => {
                this.isErrorGifts.set(true);
                this.isLoadingGifts.set(false);
                return EMPTY;
            }),
            takeUntilDestroyed(this._destroyRef)
        );

        this.totemData$ = this.data$.pipe(
            map((data) => ({
                ...data,
                scans: data.scans.filter((scan) => !scan.isWheelOfFortuneRelated()),
                previousScans: data.previousScans.filter((scan) => !scan.isWheelOfFortuneRelated()),
            }))
        );

        this.wheelOfFortuneData$ = this.data$.pipe(
            map((data) => ({
                ...data,
                scans: data.scans.filter((scan) => scan.isWheelOfFortuneRelated()),
                previousScans: data.previousScans.filter((scan) => scan.isWheelOfFortuneRelated()),
            }))
        );

        this.filteredData$ = this.data$.pipe(
            map((data) => ({
                ...data,
                nfcs: this._getFilteredNfcs(data.nfcs, this.nfcIds),
            }))
        );

        this.privateReviewData$ = this.data$.pipe(
            tap(() => {
                this.isPrivateReviewsError.set(false);
                this.isPrivateReviewsLoading.set(true);
            }),
            switchMap(({ nfcs, scans, previousScans, startDate }) => {
                const scanIds = [...scans.map((scan) => scan.id), ...previousScans.map((scan) => scan.id)];
                const privateReviewsDtos$ = scanIds.length
                    ? this._privateReviewsService.search({ scanIds }).pipe(map((apiResult) => apiResult.data))
                    : of<ReviewResponseDto[]>([]);
                const previousPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) < startDate)
                    )
                );
                const currentPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) >= startDate)
                    )
                );
                return forkJoin({
                    nfcs: of(nfcs),
                    scans: of(scans),
                    privateReviewsDto: currentPrivateReviews$,
                    previousPrivateReviewsDto: previousPrivateReviews$,
                });
            }),
            catchError(() => {
                this.isPrivateReviewsError.set(true);
                this.isPrivateReviewsLoading.set(false);
                return EMPTY;
            }),
            tap(() => this.isPrivateReviewsLoading.set(false)),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _getFilteredNfcs(allNfcs: Nfc[], nfcs: string[] = []): Nfc[] {
        const nfcsWithWheelOfFortune = nfcs.concat([FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS]);
        if (this.displayedCharts.includes(InsightsChart.BOOSTERS_SCAN_COUNT)) {
            return allNfcs.filter((nfc: Nfc) => nfcsWithWheelOfFortune.includes(nfc.id));
        }
        return allNfcs;
    }
}
