import { DateTime } from 'luxon';

import { MAX_KEYWORD_LENGTH, MAX_WORDS_COUNT, MIN_KEYWORD_LENGTH } from './constants';
import { RankHistoryItem, RankingPosition } from './keywords.interface';

export function isValidKeywordText(keyword: string): boolean {
    if (!keyword?.trim()?.length) {
        return false;
    }
    const hasKeywordTooManyWords = keyword.split(' ').length > MAX_WORDS_COUNT;
    const isKeywordTooShort = keyword.length < MIN_KEYWORD_LENGTH;
    const isKeywordTooLong = keyword.length > MAX_KEYWORD_LENGTH;

    return !hasKeywordTooManyWords && !isKeywordTooShort && !isKeywordTooLong;
}

/**
 * Returns undefined if we have no data.
 */
export const getMostRecentRank = ({ rankHistory }: { rankHistory: RankHistoryItem[] }): RankingPosition | undefined => {
    if (!rankHistory.length) {
        return undefined;
    }
    const p = rankHistory[rankHistory.length - 1];
    return {
        createdAt: new Date(p.fetchDate),
        position: {
            rank: p.rank ?? Infinity,
            outOf: p.outOf,
        },
    };
};

export const getMostRecentValidRank = (
    { rankHistory }: { rankHistory: RankHistoryItem[] },
    backwardWeeks: number = 2
): RankingPosition | null => {
    if (!rankHistory.length) {
        return null;
    }

    const lastRankFetchDate = rankHistory[rankHistory.length - 1].fetchDate;
    const backwardDateLimit = DateTime.fromJSDate(new Date(lastRankFetchDate)).minus({ weeks: backwardWeeks }).toJSDate();

    const validRankHistoryInLastWeeks = rankHistory
        .filter((rk) => new Date(rk.fetchDate) >= backwardDateLimit)
        .filter((rk) => rk.outOf !== 0)
        .sort((a, b) => new Date(b.fetchDate).getTime() - new Date(a.fetchDate).getTime());

    const rank = validRankHistoryInLastWeeks[0];

    if (!rank) {
        return null;
    }

    return { createdAt: new Date(rank.fetchDate), position: { rank: rank.rank ?? Infinity, outOf: rank.outOf } };
};
