import { minBy } from 'lodash';

import { AspectRatio, postAspectRatioToNumberMap, reelAspectRatioToNumberMap, storyAspectRatioToNumberMap } from '../../../constants';
import { PublicationType } from '../../posts';

const PREFERRED_POST_ASPECT_RATIOS = [
    { aspectRatio: AspectRatio.PORTRAIT, number: postAspectRatioToNumberMap[AspectRatio.PORTRAIT] },
    { aspectRatio: AspectRatio.LANDSCAPE, number: postAspectRatioToNumberMap[AspectRatio.LANDSCAPE] },
];

const MAX_ASPECT_RATION_FOR_POST = 1.91;
const MIN_ASPECT_RATION_FOR_POST = 0.8;
const ASPECT_RATION_FOR_REEL = 9 / 16;
const ASPECT_RATION_FOR_STORY = 9 / 16;

interface Area {
    left: number;
    top: number;
    width: number;
    height: number;
}

export class TransformDataComputerService {
    static computeDefaultAreaFor(publicationType: PublicationType, originalAspectRatio: number): Area {
        const preferredAspectRatio = TransformDataComputerService.computePreferredAspectRatioFor(publicationType, originalAspectRatio);
        const preferredAspectRatioNumber = TransformDataComputerService.getAspectRatioNumberFor(publicationType, preferredAspectRatio);
        if (!preferredAspectRatioNumber) {
            throw new Error('[TransformDataComputerService.computeDefaultAreaFor] preferredAspectRatioNumber is null');
        }
        return TransformDataComputerService.computeArea(originalAspectRatio, preferredAspectRatioNumber);
    }

    static getAspectRatioNumberFor(publicationType: PublicationType, aspectRatio: AspectRatio): number | null {
        if (publicationType === PublicationType.POST) {
            return postAspectRatioToNumberMap[aspectRatio];
        }
        if (publicationType === PublicationType.REEL) {
            return reelAspectRatioToNumberMap[aspectRatio];
        }
        if (publicationType === PublicationType.STORY) {
            return storyAspectRatioToNumberMap[aspectRatio];
        }
        throw new Error(`[TransformDataComputerService.getAspectRatioNumber] publicationType not handled: ${publicationType}`);
    }

    static computePreferredAspectRatioFor(publicationType: PublicationType, originalAspectRatio: number) {
        if (publicationType === PublicationType.POST) {
            return TransformDataComputerService.computePreferredAspectRatioForPost(originalAspectRatio);
        }
        if (publicationType === PublicationType.REEL) {
            return TransformDataComputerService.computePreferredAspectRatioForReel(originalAspectRatio);
        }
        if (publicationType === PublicationType.STORY) {
            return TransformDataComputerService.computePreferredAspectRatioForStory(originalAspectRatio);
        }
        throw new Error(`[TransformDataComputerService.computePreferredAspectRatioFor] publicationType not handled: ${publicationType}`);
    }

    private static computePreferredAspectRatioForPost(originalAspectRatio: number): AspectRatio {
        // todo posts-v2 remove square special condition when posts v1 are removed
        const squareTolerance = 0.01;
        const isCloseToSquare = Math.abs(1 - originalAspectRatio) < squareTolerance;
        if (isCloseToSquare) {
            return AspectRatio.SQUARE;
        }
        const preferredAspectRatio = minBy(PREFERRED_POST_ASPECT_RATIOS, (e) => Math.abs(originalAspectRatio - e.number))?.aspectRatio;
        // const preferredAspectRatio = PREFERRED_POST_ASPECT_RATIOS.reduce((acc, cur) => {
        //     if (!acc.number) {
        //         return cur;
        //     }
        //     if (!cur.number) {
        //         return acc;
        //     }
        //     const diffWithAcc = Math.abs(originalAspectRatio - acc.number);
        //     const diffWithCur = Math.abs(originalAspectRatio - cur.number);
        //     return diffWithAcc < diffWithCur ? acc : cur;
        // })?.aspectRatio;

        if (!preferredAspectRatio) {
            throw new Error('[TransformDataComputerService.computePreferredAspectRatioForPost] preferredAspectRatio is null');
        }

        return preferredAspectRatio;
    }

    private static computePreferredAspectRatioForReel(_originalAspectRatio: number): AspectRatio {
        return AspectRatio.PORTRAIT;
    }

    private static computePreferredAspectRatioForStory(_originalAspectRatio: number): AspectRatio {
        return AspectRatio.PORTRAIT;
    }

    static computeArea(originalAspectRatio: number, targetAspectRatio: number): Area {
        let left: number, top: number, width: number, height: number;
        if (originalAspectRatio > targetAspectRatio) {
            top = 0;
            height = 1;
            width = targetAspectRatio / originalAspectRatio;
            left = (1 - width) / 2;
        } else if (originalAspectRatio < targetAspectRatio) {
            left = 0;
            width = 1;
            height = originalAspectRatio / targetAspectRatio;
            top = (1 - height) / 2;
        } else {
            top = 0;
            left = 0;
            width = 1;
            height = 1;
        }
        return {
            left,
            top,
            width,
            height,
        };
    }

    static getAspectRatioClosestToLimitsFor(publicationType: PublicationType, originalAspectRatio: number): number {
        if (publicationType === PublicationType.POST) {
            return TransformDataComputerService.getAspectRatioClosestToLimitsForPost(originalAspectRatio);
        }
        if (publicationType === PublicationType.REEL) {
            return TransformDataComputerService.getAspectRatioClosestToLimitsForReel(originalAspectRatio);
        }
        if (publicationType === PublicationType.STORY) {
            return TransformDataComputerService.getAspectRatioClosestToLimitsForStory(originalAspectRatio);
        }
        throw new Error(`[TransformDataComputerService.getAspectRatioClosestToLimitsFor] publicationType not handled: ${publicationType}`);
    }

    private static getAspectRatioClosestToLimitsForPost(originalAspectRatio: number): number {
        return Math.min(MAX_ASPECT_RATION_FOR_POST, Math.max(MIN_ASPECT_RATION_FOR_POST, originalAspectRatio));
    }

    private static getAspectRatioClosestToLimitsForReel(_originalAspectRatio: number): number {
        return ASPECT_RATION_FOR_REEL;
    }

    private static getAspectRatioClosestToLimitsForStory(_originalAspectRatio: number): number {
        return ASPECT_RATION_FOR_STORY;
    }
}
