<div class="relative flex overflow-hidden rounded-[10px] font-[Helvetica] text-white">
    @if (media(); as media) {
        <app-image-viewer
            class="aspect-9/16 min-w-full"
            [data]="{
                thumbnailUrl: media.thumbnail1024OutsideUrl,
                dimensions: media.thumbnail1024OutsideDimensions,
                transformData: media.transformData,
                videoOptions:
                    media.type === MediaType.VIDEO ? { videoUrl: media.videoUrl, videoDimensions: media.videoDimensions } : undefined,
            }"
            (isPlayingVideoChange)="isPlayingVideo.set($event)">
        </app-image-viewer>
    } @else {
        <div class="flex aspect-9/16 items-center justify-center bg-malou-color-background-dark">
            <img class="w-full" [src]="'default_post' | imagePathResolver" />
        </div>
    }
    <!-- HEADER -->
    <div class="malou-text-11--semibold absolute left-4 top-3">
        <img class="h-6 w-6" [src]="'live' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'svg' }" />
    </div>
    <div class="absolute left-1/2 top-3 mt-[2px] flex -translate-x-1/2 items-start gap-x-3 text-[11px] text-white">
        <div>
            {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.tiktok_reel.explore' | translate }}
        </div>
        <div>
            {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.tiktok_reel.following' | translate }}
        </div>
        <div class="flex flex-col items-center">
            <div class="font-semibold">
                {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.tiktok_reel.for_you' | translate }}
            </div>
            <div class="h-[2px] w-4 rounded-full bg-white"></div>
        </div>
    </div>
    <mat-icon class="absolute right-4 top-3 !h-4 !w-4" color="white" [svgIcon]="SvgIcon.SEARCH"></mat-icon>

    <!-- FOOTER -->
    <div class="absolute bottom-5 left-4 flex flex-col gap-2">
        <div class="text-[12px] font-medium text-white">{{ username() }}</div>

        <div class="max-w-[250px] overflow-hidden overflow-ellipsis whitespace-nowrap text-[12px] font-normal">
            {{ text() }}
        </div>

        @if (hashtagsText(); as hashtagsText) {
            <div class="max-w-[250px] overflow-hidden overflow-ellipsis whitespace-nowrap text-[11px] font-medium">
                {{ hashtagsText }}
            </div>
        }
    </div>

    <!-- RIGHT SIDEBAR ACTIONS -->
    <div class="malou-text-8--regular absolute bottom-5 right-4 flex flex-col items-center justify-center gap-3">
        <div class="relative mb-3 h-[34px] w-[34px] rounded-full border border-white">
            @if (profilePicture(); as profilePicture) {
                <img class="h-full w-full rounded-full" [src]="profilePicture" />
            } @else {
                <div class="h-full w-full rounded-full bg-malou-color-purple--light"></div>
            }
            <img
                class="absolute bottom-0 left-1/2 h-5 w-5 -translate-x-1/2 translate-y-1/2"
                [src]="'plus' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'svg' }" />
        </div>

        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'like' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'svg' }" />
            <div>37.6K</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'message' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'svg' }" />
            <div>787</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'save' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'svg' }" />
            <div>23</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'share' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'svg' }" />
            <div>6787</div>
        </div>
        <div class="h-[30px] w-[30px] overflow-hidden rounded-full">
            <img
                class="h-full w-full"
                [ngClass]="{ rotating: isPlayingVideo() }"
                [src]="'alan-walker' | imagePathResolver: { folder: 'social-post-previews/tiktok', extensionFormat: 'jpeg' }" />
        </div>
    </div>
</div>
