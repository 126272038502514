@if (isLoading()) {
    <app-skeleton skeletonClass="!h-36 secondary-bg"></app-skeleton>
} @else if (wantsRatingBeforeRestaurantCreationDate) {
    <ng-container
        [ngTemplateOutlet]="errorTemplate"
        [ngTemplateOutletContext]="{
            text: 'statistics.e_reputation.platforms_ratings.no_data' | translate,
            subtext: 'statistics.e_reputation.platforms_ratings.want_ratings_before_restaurant_creation' | translate,
        }">
    </ng-container>
} @else if (httpError) {
    <ng-container
        [ngTemplateOutlet]="errorTemplate"
        [ngTemplateOutletContext]="{
            text: 'statistics.errors.server_is_not_responding' | translate,
            subtext: httpError | statisticsHttpError,
        }">
    </ng-container>
} @else if (areSomeRatingsNotNull | applyPure: ratings) {
    <div
        class="malou-simple-card flex h-full items-center !bg-malou-color-background-white px-6 py-4 md:px-2"
        [ngClass]="{
            'min-h-40 flex-col items-baseline': showTitle,
            'min-h-36 items-center': !showTitle,
        }">
        @if (showTitle) {
            <div class="h-12.5">
                <div class="malou-text-section-title malou-color-text-1 mb-2.5">
                    {{ 'platforms_rating.ratings_by_platform' | translate }}
                </div>
            </div>
        }
        <div class="flex w-full flex-wrap items-start justify-around gap-6">
            @for (rating of ratings; track rating) {
                <div class="flex flex-col items-center gap-3">
                    <div class="relative">
                        <app-platform-logo imgClasses="h-12.5 w-12.5" [logo]="rating.platform" [withLazyLoading]="true">
                        </app-platform-logo>
                        @if (!rating.current) {
                            <mat-icon
                                class="malou-status--waiting absolute bottom-0 right-0"
                                [svgIcon]="SvgIcon.EXCLAMATION_MARK"
                                [matTooltip]="'statistics.e_reputation.platforms_ratings.no_ratings' | translate"></mat-icon>
                        }
                    </div>
                    <div class="flex flex-col items-center gap-1">
                        <div class="flex items-center">
                            <span
                                class="malou-text-section-title malou-color-text-1"
                                [ngClass]="{ 'mr-2': rating.current && rating.current !== rating.diff }">
                                {{
                                    rating.current
                                        ? (rating.current | number: NUMBER_FORMAT : localeForNumberTransformation()) +
                                          ((platformHasRatingOutOfTen | applyPure: rating.platform) ? '/10' : '')
                                        : '--'
                                }}
                            </span>
                            @if (rating.current && rating.current !== rating.diff) {
                                <app-number-evolution
                                    size="small"
                                    [value]="rating.diff"
                                    [displayedValue]="
                                        rating.diff | shortNumber: { fixNumber: 1, shouldDisplayMinusSign: false }
                                    "></app-number-evolution>
                            }
                            @if (rating.current && rating.previous) {
                                <div
                                    class="malou-text-10--regular malou-color-text-2 whitespace-nowrap italic"
                                    [ngClass]="{ 'mr-2': rating.current && rating.current !== rating.diff }"
                                    [matTooltip]="comparisonPeriodKey() | translate">
                                    ({{ rating.previous | number: NUMBER_FORMAT : localeForNumberTransformation() }})
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
} @else {
    <ng-container
        [ngTemplateOutlet]="errorTemplate"
        [ngTemplateOutletContext]="{
            text: 'statistics.errors.no_data' | translate,
            subtext: 'statistics.errors.no_data_explained' | translate,
        }">
    </ng-container>
}

<ng-template let-text="text" let-subtext="subtext" #errorTemplate>
    <div class="flex flex-col items-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ text }}</span>
        <span class="malou-text-10--regular">{{ subtext }}</span>
    </div>
</ng-template>
