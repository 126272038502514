<div class="flex items-baseline gap-2">
    <ng-container [ngTemplateOutlet]="startDateSelector"> </ng-container>
    <span class="malou-text-13 malou-color-text-2">
        {{ 'common.to_bis' | translate }}
    </span>
    <ng-container [ngTemplateOutlet]="endDateSelector"> </ng-container>
</div>

<mat-menu class="malou-mat-menu malou-box-shadow" #startdatepickermenu="matMenu">
    <div class="flex max-h-[380px] w-[400px] flex-col gap-4 overflow-y-auto p-4" (click)="$event.stopPropagation()">
        @for (interval of startMonthYearIntervals() | keyvalue: sortKeys; track interval.key) {
            <div class="malou-text-13--semibold malou-color-text-1">
                {{ interval.key }}
            </div>
            <div class="flex flex-wrap gap-2">
                @for (monthYear of interval.value; track monthYear.month) {
                    <div
                        class="malou-chip malou-text-12--regular leading-normal hover:!bg-malou-color-background-light"
                        [ngClass]="{
                            'malou-chip--dark': !monthYear.isSelected && !monthYear.isDisabled,
                            'malou-chip--dark--selected': monthYear.isSelected,
                            'malou-chip--flat !cursor-not-allowed': monthYear.isDisabled,
                        }"
                        (click)="onStartDateSelected(monthYear)">
                        {{ monthDisplayWith | applyPure: monthYear }}
                    </div>
                }
            </div>
        }
    </div>
</mat-menu>

<mat-menu class="malou-mat-menu malou-box-shadow" #enddatepickermenu="matMenu">
    <div class="flex max-h-[380px] w-[400px] flex-col gap-4 overflow-y-auto p-4" (click)="$event.stopPropagation()">
        @for (interval of endMonthYearIntervals() | keyvalue: sortKeys; track interval.key) {
            <div class="malou-text-13--semibold malou-color-text-1">
                {{ interval.key }}
            </div>
            <div class="flex flex-wrap gap-2">
                @for (monthYear of interval.value; track monthYear.month) {
                    <div
                        class="malou-chip malou-chip--dark malou-text-12--regular leading-normal hover:!bg-malou-color-background-light"
                        [ngClass]="{
                            'malou-chip--dark': !monthYear.isSelected && !monthYear.isDisabled,
                            'malou-chip--dark--selected': monthYear.isSelected,
                            'malou-chip--flat !cursor-not-allowed': monthYear.isDisabled,
                        }"
                        (click)="onEndDateSelected(monthYear)">
                        {{ monthDisplayWith | applyPure: monthYear }}
                    </div>
                }
            </div>
        }
    </div>
</mat-menu>

<ng-template #startDateSelector>
    <div
        class="malou-border-primary flex cursor-pointer justify-between rounded-lg bg-malou-color-background-white px-3 py-[12px]"
        [matMenuTriggerFor]="startdatepickermenu"
        #startdatepickermenutrigger="matMenuTrigger">
        <div>
            <span class="malou-text-14--medium malou-color-text-1 relative top-px ml-2">
                {{ monthAndYearDisplayWith | applyPure: monthYearPeriod().startMonthYear }}
            </span>
        </div>
        <div class="flex items-center">
            <mat-icon class="malou-color-primary mx-2 !h-4 !w-4" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
        </div>
    </div>
</ng-template>

<ng-template #endDateSelector>
    <div
        class="malou-border-primary flex cursor-pointer justify-between rounded-lg bg-malou-color-background-white px-3 py-[12px]"
        [matMenuTriggerFor]="enddatepickermenu"
        #enddatepickermenutrigger="matMenuTrigger">
        <div>
            <span class="malou-text-14--medium malou-color-text-1 relative top-px ml-2">
                {{ monthAndYearDisplayWith | applyPure: monthYearPeriod().endMonthYear }}
            </span>
        </div>
        <div class="flex items-center">
            <mat-icon class="malou-color-primary mx-2 !h-4 !w-4" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
        </div>
    </div>
</ng-template>
