<div class="flex flex-col gap-4 pr-3">
    @if (platformOptions().length > 0) {
        <div class="flex items-center gap-x-3">
            @if (platformOptions().length > 1) {
                <app-simple-select
                    class="grow"
                    [defaultValue]="selectedPlatformOption()"
                    [values]="platformOptions()"
                    (simpleSelectChange)="onPlatformChange($event)">
                    <ng-template let-value="value" #selectedValueTemplate>
                        <div class="flex items-center gap-x-3">
                            @if (value) {
                                <app-platform-logo imgClasses="h-7 w-7" [logo]="value.platformKey"></app-platform-logo>
                                <div class="malou-text-12--bold text-malou-color-text-1">{{ value.username }}</div>
                            }
                        </div>
                    </ng-template>

                    <ng-template let-option="option" #optionTemplate>
                        <div class="flex items-center gap-x-3">
                            <app-platform-logo imgClasses="h-7 w-7" [logo]="option.platformKey"></app-platform-logo>
                            <div class="malou-text-14--regular text-malou-color-text-2">{{ option.username }}</div>
                        </div>
                    </ng-template>
                </app-simple-select>
            }
        </div>

        <div>
            @if (selectedPlatformOption(); as selectedPlatformOption) {
                @if (isReel()) {
                    @switch (selectedPlatformOption.platformKey) {
                        @case (PlatformKey.INSTAGRAM) {
                            <app-instagram-reel-preview
                                [text]="text()"
                                [username]="selectedPlatformOption.username"
                                [media]="attachments()[0]"
                                [profilePicture]="selectedPlatformOption.profilePictureUrl"
                                [hashtags]="selectedHashtagsText()"></app-instagram-reel-preview>
                        }
                        @case (PlatformKey.FACEBOOK) {
                            <app-facebook-reel-preview
                                [text]="text()"
                                [username]="selectedPlatformOption.username"
                                [media]="attachments()[0]"
                                [profilePicture]="selectedPlatformOption.profilePictureUrl"
                                [hashtags]="selectedHashtagsText()"></app-facebook-reel-preview>
                        }
                        @case (PlatformKey.TIKTOK) {
                            <app-tiktok-reel-preview
                                [text]="text()"
                                [username]="selectedPlatformOption.username"
                                [media]="attachments()[0]"
                                [profilePicture]="selectedPlatformOption.profilePictureUrl"
                                [hashtags]="selectedHashtagsText()"></app-tiktok-reel-preview>
                        }
                    }
                } @else {
                    @switch (selectedPlatformOption.platformKey) {
                        @case (PlatformKey.INSTAGRAM) {
                            <app-instagram-preview
                                [text]="text()"
                                [username]="selectedPlatformOption.username"
                                [medias]="attachments()"
                                [profilePicture]="selectedPlatformOption.profilePictureUrl"
                                [hashtags]="selectedHashtagsText()"
                                [readonly]="isReadonly()"></app-instagram-preview>
                        }
                        @case (PlatformKey.MAPSTR) {
                            <app-mapstr-preview
                                [text]="text()"
                                [title]="title()"
                                [media]="attachments()[0]"
                                [date]="plannedPublicationDate()"
                                [callToActionType]="callToActionType()"></app-mapstr-preview>
                        }
                        @case (PlatformKey.FACEBOOK) {
                            <app-facebook-preview
                                [username]="selectedPlatformOption.username"
                                [profilePicture]="selectedPlatformOption.profilePictureUrl"
                                [text]="text()"
                                [medias]="attachments()"
                                [date]="plannedPublicationDate()"
                                [location]="location()"
                                [hashtags]="selectedHashtagsText()"></app-facebook-preview>
                        }
                    }
                }
            }
        </div>
    } @else {
        <!-- EMPTY STATE = EMPTY INSTAGRAM PREVIEW -->
        @if (isReel()) {
            <app-instagram-reel-preview [text]="''" [username]="''" [media]="undefined" [profilePicture]="''"></app-instagram-reel-preview>
        } @else {
            <app-instagram-preview
                [text]="''"
                [username]="''"
                [medias]="[]"
                [profilePicture]="''"
                [readonly]="isReadonly()"></app-instagram-preview>
        }
    }
</div>
