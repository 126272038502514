<span class="malou-text-10--semibold flex items-center text-malou-color-text-2">
    <mat-icon
        class="mr-4 !h-6 !w-6 rounded bg-malou-color-state-error--light bg-opacity-30 p-1 text-malou-color-state-error"
        [svgIcon]="SvgIcon.CROSS"></mat-icon>
    @switch (platformAccessStatus()) {
        @case (InformationUpdatesStatus.MISSING_PERMISSIONS) {
            {{ 'informations.missing_permissions_state' | translate: { platformName: platformKey() | platformName } }}
        }
        @case (InformationUpdatesStatus.BAD_ACCESS) {
            @if ([informationUpdateSupportedKeyMap.deliveroo, informationUpdateSupportedKeyMap.lafourchette] | includes: platformKey()) {
                {{ 'informations.invalid_login_password' | translate }}
            } @else if (
                [
                    informationUpdateSupportedKeyMap.tripadvisor,
                    informationUpdateSupportedKeyMap.yelp,
                    informationUpdateSupportedKeyMap.foursquare,
                    informationUpdateSupportedKeyMap.resy,
                ] | includes: platformKey()
            ) {
                {{ 'informations.missing_access' | translate }}
            } @else {
                {{ 'informations.invalid_login_password' | translate }}
            }
        }
        @case (InformationUpdatesStatus.UNCLAIMED_PAGE) {
            {{ 'informations.unclaimed_page' | translate }}
        }
        @case (InformationUpdatesStatus.NOT_FOUND) {
            {{ 'informations.not_found' | translate }}
        }
        @case (InformationUpdatesStatus.INVALID_PAGE) {
            {{ 'informations.platform_validation_process' | translate }}
        }
        @case (InformationUpdatesStatus.ERROR) {
            {{ 'platforms.connection.failed' | translate }}
        }
        @case (InformationUpdatesStatus.MANUAL_UPDATE_ERROR) {
            {{ 'platforms.connection.failed' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (InformationUpdatesStatus.UNVERIFIED) {
            {{ 'platforms.connection.unverified' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (InformationUpdatesStatus.FAILED) {
            {{ 'platforms.connection.failed' | translate }}
        }
        @default {}
    }
</span>
