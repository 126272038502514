<div class="malou-simple-card flex w-full break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
    <div class="flex flex-col gap-1">
        <div class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.e_reputation.reviews_analysis.top_topics' | translate }}
        </div>
    </div>
    <div class="flex h-80 flex-col gap-1 sm:h-[100%]">
        @if (screenSizeService.isPhoneScreen) {
            <div class="flex h-full w-full flex-col gap-1">
                <ng-container
                    [ngTemplateOutlet]="topicColumnHeaderTemplate"
                    [ngTemplateOutletContext]="{ sentiment: ReviewAnalysisSentiment.POSITIVE }"></ng-container>
                <ng-container
                    [ngTemplateOutlet]="topTopicsTemplate"
                    [ngTemplateOutletContext]="{
                        sentiment: ReviewAnalysisSentiment.POSITIVE,
                        topics: sortedPositiveTopics(),
                    }"></ng-container>
            </div>
            <div class="flex h-full w-full flex-col gap-1">
                <ng-container
                    [ngTemplateOutlet]="topicColumnHeaderTemplate"
                    [ngTemplateOutletContext]="{ sentiment: ReviewAnalysisSentiment.NEGATIVE }"></ng-container>
                <ng-container
                    [ngTemplateOutlet]="topTopicsTemplate"
                    [ngTemplateOutletContext]="{
                        sentiment: ReviewAnalysisSentiment.NEGATIVE,
                        topics: sortedNegativeTopics(),
                    }"></ng-container>
            </div>
        } @else {
            <div class="flex gap-2">
                <ng-container
                    [ngTemplateOutlet]="topicColumnHeaderTemplate"
                    [ngTemplateOutletContext]="{ sentiment: ReviewAnalysisSentiment.POSITIVE }"></ng-container>

                <ng-container
                    [ngTemplateOutlet]="topicColumnHeaderTemplate"
                    [ngTemplateOutletContext]="{ sentiment: ReviewAnalysisSentiment.NEGATIVE }"></ng-container>
            </div>
            <div class="flex h-full w-full gap-2">
                @if (!sortedPositiveTopics().length && !sortedNegativeTopics().length) {
                    <ng-container [ngTemplateOutlet]="noTopicTemplate"></ng-container>
                } @else {
                    <ng-container
                        [ngTemplateOutlet]="topTopicsTemplate"
                        [ngTemplateOutletContext]="{
                            sentiment: ReviewAnalysisSentiment.POSITIVE,
                            topics: sortedPositiveTopics(),
                        }"></ng-container>
                    <ng-container
                        [ngTemplateOutlet]="topTopicsTemplate"
                        [ngTemplateOutletContext]="{
                            sentiment: ReviewAnalysisSentiment.NEGATIVE,
                            topics: sortedNegativeTopics(),
                        }"></ng-container>
                }
            </div>
        }
    </div>
</div>

<ng-template let-sentiment="sentiment" #topicColumnHeaderTemplate>
    <div
        class="mb-2 flex w-full flex-col items-center justify-center rounded-[5px] border border-malou-color-background-dark bg-malou-color-background-light p-2">
        <img
            class="mb-1 h-4 w-4"
            [src]="(sentiment === ReviewAnalysisSentiment.POSITIVE ? Emoji.HEART : Emoji.MONOCLE) | emojiPathResolver" />
        <div class="malou-text-12--bold text-malou-color-text-1">
            {{
                sentiment === ReviewAnalysisSentiment.POSITIVE
                    ? ('statistics.e_reputation.reviews_analysis.top_topics_liked' | translate)
                    : ('statistics.e_reputation.reviews_analysis.top_topics_unliked' | translate)
            }}
        </div>
    </div>
</ng-template>

<ng-template let-topics="topics" let-sentiment="sentiment" #topTopicsTemplate>
    <div class="flex h-full w-full flex-col gap-1">
        @if (!topics.length) {
            <ng-container [ngTemplateOutlet]="noTopicTemplate"></ng-container>
        } @else {
            @for (topic of topics; track $index) {
                <div
                    class="flex max-h-9 w-full cursor-pointer items-center justify-between rounded-[5px] border border-malou-color-background-dark p-2 hover:bg-malou-color-background-dark"
                    [ngClass]="{
                        tracking_semantic_analysis_top_topic_click: sentiment === ReviewAnalysisSentiment.POSITIVE,
                        tracking_semantic_analysis_flop_topic_click: sentiment === ReviewAnalysisSentiment.NEGATIVE,
                    }"
                    (click)="openSegmentAnalysisModal(topic, sentiment)">
                    <div class="items flex w-full items-center justify-between">
                        <div class="malou-text-11--regular text-malou-color-text-2">
                            {{ topic.displayedNameInCurrentLang || topic.displayedName }}
                        </div>
                        <div
                            class="!malou-text-12--medium malou-chip flex !h-5 !w-8 !cursor-default items-center justify-center !pt-[10px] text-malou-color-text-2"
                            [ngClass]="{
                                'malou-chip--success-light text-malou-color-text-green': sentiment === ReviewAnalysisSentiment.POSITIVE,
                                'malou-chip--error-light malou-color-state-error': sentiment === ReviewAnalysisSentiment.NEGATIVE,
                            }">
                            {{
                                sentiment === ReviewAnalysisSentiment.POSITIVE ? (topic.positiveCount ?? '-') : (topic.negativeCount ?? '-')
                            }}
                        </div>
                    </div>
                </div>
            }
        }
    </div>
</ng-template>

<ng-template #noTopicTemplate>
    <div class="flex h-full w-full flex-col items-center justify-center">
        <div class="mb-8 w-24 md:mb-5 md:w-24">
            <img alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
        </div>
        <div class="px-7 text-center">
            <p class="malou-text-10--regular malou-color-text-2 mb-2">
                {{ 'statistics.e_reputation.reviews_analysis.top_topics_empty_state' | translate }}
            </p>
        </div>
    </div>
</ng-template>
