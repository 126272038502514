import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { capitalize } from 'lodash';

import { ReviewAnalysisSentiment } from '@malou-io/package-utils';

import { concatReviewTextAndRatingTags } from ':shared/helpers/review-text-and-tags';
import { Review, SegmentAnalyses, SemanticAnalysisInsightsReview } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { SegmentAnalysis } from ':shared/models/segment-analysis';

@Injectable({ providedIn: 'root' })
export class ReviewAnalysisHighlighter {
    constructor(private readonly _sanitizer: DomSanitizer) {}

    getHighlightedReviewTextHtml({
        review,
        isNewSemanticAnalysisFeatureEnabled,
    }: {
        review: Review | PrivateReview | SemanticAnalysisInsightsReview;
        isNewSemanticAnalysisFeatureEnabled: boolean;
    }): SafeHtml {
        const reviewSegments =
            isNewSemanticAnalysisFeatureEnabled || review instanceof SemanticAnalysisInsightsReview
                ? review?.semanticAnalysisSegments
                : review?.semanticAnalysis?.segmentAnalyses;

        let reviewText = review.text ?? '';
        let reviewRatingTags = (review as Review).ratingTags ?? [];
        if (!reviewSegments?.length || (!reviewText && !reviewRatingTags.length)) {
            return review.getFullReviewTextWithRatingTags() ?? '';
        }

        reviewSegments?.forEach((element) => {
            const highlightedSegment = this._getHighlightedSegment(element);
            reviewText = reviewText.toLowerCase().replace(element.segment?.toLowerCase(), highlightedSegment);
            reviewRatingTags = reviewRatingTags.map((tag) =>
                // TODO: remove the replace : temporary because AI is adding . at the end of topic and segments
                tag.toLowerCase().replace(element.segment?.toLowerCase().replace(/\.$/, ''), capitalize(highlightedSegment))
            );
        });

        const reviewTextWithRatingTags = concatReviewTextAndRatingTags(reviewText, reviewRatingTags);
        return this._sanitizer.bypassSecurityTrustHtml(reviewTextWithRatingTags);
    }

    setReviewTextHtmlSegmentsHighlights({
        review,
        segmentAnalysis,
        isNewSemanticAnalysisFeatureEnabled,
    }: {
        review: Review | PrivateReview;
        segmentAnalysis: SegmentAnalyses | SegmentAnalysis;
        isNewSemanticAnalysisFeatureEnabled: boolean;
    }): SafeHtml {
        const sameSegmentAnalysis = isNewSemanticAnalysisFeatureEnabled
            ? review.semanticAnalysisSegments?.filter(
                  (item) => item.sentiment === segmentAnalysis.sentiment && item.category === (segmentAnalysis as SegmentAnalysis).category
              )
            : review.semanticAnalysis?.segmentAnalyses?.filter(
                  (item) => item.sentiment === segmentAnalysis.sentiment && item.tag === (segmentAnalysis as SegmentAnalyses).tag
              );

        let highlightedReviewText = review.text ?? '';
        let highlightedRatingTags = (review as Review).ratingTags ?? [];
        sameSegmentAnalysis?.forEach((element) => {
            const highlightedSegment = this._getHighlightedSegment(element);
            highlightedReviewText = highlightedReviewText.toLowerCase().replace(element.segment?.toLowerCase(), highlightedSegment);
            highlightedRatingTags = highlightedRatingTags.map((tag) =>
                // TODO: remove the replace : temporary because AI is adding . at the end of topic and segments
                tag.toLowerCase().replace(element.segment?.toLowerCase().replace(/\.$/, ''), highlightedSegment)
            );
        });

        const highlightedReviewTextWithRatingTags = concatReviewTextAndRatingTags(highlightedReviewText, highlightedRatingTags);
        return this._sanitizer.bypassSecurityTrustHtml(highlightedReviewTextWithRatingTags);
    }

    // TODO: Remove SegmentAnalyses type when feature toggle 'release-new-semantic-analysis' is removed
    private _getHighlightedSegment(element: SegmentAnalyses | SegmentAnalysis): string {
        const color = element.sentiment === ReviewAnalysisSentiment.POSITIVE ? '#34B467' : '#EE116E';
        // TODO: remove the replace : temporary because AI is adding . at the end of topic and segments
        return `<span style="color: ${color};">${element.segment?.toLowerCase().replace(/\.$/, '')}</span>`;
    }
}
