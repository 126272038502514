import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const updateSegmentAnalysisParentTopicParamsValidator = z.object({
    id: objectIdValidator,
});
export const updateSegmentAnalysisParentTopicBodyValidator = z.object({
    isFavorite: z.boolean().optional(),
});

export type UpdateSegmentAnalysisParentTopicParamsDto = z.infer<typeof updateSegmentAnalysisParentTopicParamsValidator>;
export type UpdateSegmentAnalysisParentTopicBodyDto = z.infer<typeof updateSegmentAnalysisParentTopicBodyValidator>;
