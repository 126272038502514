<div class="flex w-full items-center gap-4 md:flex-col md:items-stretch md:gap-2">
    <div class="sm:w-full">
        @switch (datePickerType()) {
            @case (DatePickerType.MONTH_YEAR) {
                <ng-container [ngTemplateOutlet]="monthYearDatePickerTemplate"></ng-container>
            }
            @case (DatePickerType.TIME_SCALE) {
                <ng-container [ngTemplateOutlet]="timeScaleFilterTemplate"></ng-container>
            }
            @default {
                <ng-container [ngTemplateOutlet]="groupedDateFiltersTemplate"></ng-container>
            }
        }
    </div>

    <div class="flex min-w-0 grow items-center gap-4 sm:w-full md:flex-col md:items-stretch md:gap-2">
        <div
            class="min-w-0 flex-1 sm:max-w-none"
            [ngClass]="{ 'max-w-[50vw] flex-2': page() === PlatformFilterPage.SOCIAL_NETWORKS || page() === PlatformFilterPage.BOOSTERS }">
            <app-select-restaurants
                [values]="(restaurants$ | async) ?? []"
                [maxSelectableValues]="MAX_SELECTABLE_RESTAURANTS"
                [formControl]="restaurantsFilterControl"
                [multiSelectionElementWrap]="false"
                [shouldSwitchToWrapModeOnClick]="false"
                [defaultEmptyValueMessage]="'app_select_restaurants.no_business_found' | translate"
                [shouldUpdateValuesToDisplayAfterSelection]="false"
                [computeObjectHash]="compareByRestaurantId"
                [valuesCanBeDisabled]="page() === PlatformFilterPage.ROI"
                (selectRestaurantsChange)="onRestaurantsChange($event)">
            </app-select-restaurants>
        </div>

        @if (showPlatformsFilter()) {
            <div class="min-w-0 flex-1">
                <app-select-platforms
                    [values]="connectedPlatforms"
                    [formControl]="platformsFilterControl"
                    (selectPlatformsChange)="onPlatformsChange($event)">
                </app-select-platforms>
            </div>
        }

        @if (showNfcFilter()) {
            <div class="min-w-0 max-w-[100vw] flex-2">
                <app-select-nfcs
                    [values]="selectableNfcs()"
                    [formControl]="totemsFilterControl"
                    [multiSelectionElementWrap]="false"
                    [shouldSwitchToWrapModeOnClick]="false"
                    [shouldUpdateValuesToDisplayAfterSelection]="false"
                    [computeObjectHash]="compareByNfcId"
                    (selectNfcChange)="onNfcsChange($event)">
                </app-select-nfcs>
            </div>
        }
    </div>
</div>

<ng-template #groupedDateFiltersTemplate>
    <app-grouped-date-filters
        [startDate]="startDate()"
        [endDate]="endDate()"
        [defaultPeriod]="period()"
        [periodOptions]="DEFAULT_PERIODS"
        [withLabel]="!screenSizeService.isPhoneScreen"
        [blockFutureDates]="true"
        [isLeftMaxDateToday]="true"
        (chooseBoundaryDate)="chooseBoundaryDate($event)">
    </app-grouped-date-filters>
</ng-template>

<ng-template #timeScaleFilterTemplate>
    <app-select-time-scale-filter
        [formControl]="timeScaleFilterControl"
        [minAcceptedDate]="timeScaleMinAcceptedDate()"
        (selectTimeScaleChange)="onTimeScaleChange($event)" />
</ng-template>

<ng-template #monthYearDatePickerTemplate>
    <app-month-year-date-picker-v2
        [maxRangeInMonths]="DEFAULT_MAX_MONTH_YEAR_RANGE"
        [initialMonthYearPeriod]="monthYearPeriod()"
        (monthYearPeriodChanged)="onMonthYearPeriodChanged($event)">
    </app-month-year-date-picker-v2>
</ng-template>
