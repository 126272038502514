import { NgClass } from '@angular/common';
import { Component, effect, EventEmitter, input, Input, OnInit, Output, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

export interface NavBarTab {
    title: string;
    subtitle?: string;
    data: any;
}

export enum TabNavBarDisplayStyle {
    DEFAULT = 'default',
    LIGHT = 'light',
}

@Component({
    selector: 'app-tab-nav-bar',
    templateUrl: './tab-nav-bar.component.html',
    styleUrls: ['./tab-nav-bar.component.scss'],
    standalone: true,
    imports: [NgClass, MatTabsModule],
})
export class TabNavBarComponent implements OnInit {
    @Input() tabs: NavBarTab[];
    readonly defaultSelectedTab = input<NavBarTab | null>(null);
    readonly theme = input<string>(TabNavBarDisplayStyle.DEFAULT);

    @Output() tabChange: EventEmitter<NavBarTab> = new EventEmitter<NavBarTab>();

    readonly TabNavBarDisplayStyle = TabNavBarDisplayStyle;

    readonly activeTab = signal<NavBarTab | null>(null);

    constructor() {
        effect(
            () => {
                const defaultSelectedTab = this.defaultSelectedTab();
                if (defaultSelectedTab) {
                    this.activeTab.set(defaultSelectedTab);
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.activeTab.set(this.tabs[0]);
    }

    onTabCLick(tab: NavBarTab): void {
        this.activeTab.set(tab);
        this.tabChange.emit(tab);
    }
}
