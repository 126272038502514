import { SegmentAnalysisParentTopicDto } from '@malou-io/package-dto/src/segment-analysis-parent-topics/segment-analysis-parent-topics.response.dto';
import { ITranslations, PlatformKey, ReviewAnalysisTag } from '@malou-io/package-utils';

export class SegmentAnalysisParentTopic {
    id: string;
    platformSocialId: string;
    platformKey: PlatformKey;
    platformUniqKey: string;
    category: ReviewAnalysisTag;
    name: string;
    isFavorite: boolean;
    translations?: ITranslations;

    public constructor(init: SegmentAnalysisParentTopic | SegmentAnalysisParentTopicDto) {
        this.id = init?.id;
        this.name = init?.name;
        this.platformSocialId = init?.platformSocialId;
        this.platformKey = init?.platformKey;
        this.platformUniqKey = init?.platformUniqKey;
        this.isFavorite = init?.isFavorite;
        this.category = init?.category;
        this.translations = init?.translations;
    }
}
