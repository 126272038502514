import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentAnalysisParentTopicDto } from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({ providedIn: 'root' })
export class SegmentAnalysisParentTopicsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/segment-analysis-parent-topics`;

    constructor(private readonly _http: HttpClient) {}

    update(id: string, params: { isFavorite: boolean }): Observable<ApiResultV2<SegmentAnalysisParentTopicDto>> {
        return this._http.put<ApiResultV2<SegmentAnalysisParentTopicDto>>(`${this.API_BASE_URL}/${id}`, params);
    }
}
