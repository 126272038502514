<app-reviews-kpis-card
    [title]="'statistics.e_reputation.reviews_kpis.reviews_on_the_period' | translate"
    [comparisonPeriodTitle]="comparisonPeriodKey() | translate"
    [icon]="SvgIcon.THUMB_UP"
    [currentValue]="currentTotalReviews() | shortNumber"
    [previousValue]="previousTotalReviews() | shortNumber"
    [currentValueRaw]="currentTotalReviews()"
    [valueTooltip]="gmbTotalReviewCountTooltip()"
    [diffValue]="diffTotalReviews()"
    [diffDisplayedValue]="diffTotalReviews() | shortNumber: { shouldDisplayMinusSign: false }"
    [isLoading]="isLoading()"
    [error]="httpError()">
</app-reviews-kpis-card>
