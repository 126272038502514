import { DateTime, Interval, Settings } from 'luxon';

import { DateStringFormat, Locale, TimeInMilliseconds } from './constants';

type PossibleDateObject = { year?: number; week?: number };
type PossibleDate = Date | string | number | PossibleDateObject;

export interface Week {
    start: Date;
    end: Date;
    startDayInsideRange: Date;
    endDayInsideRange: Date;
    days: any[];
}

export interface Month {
    start: Date;
    end: Date;
    days: Date[];
}

export interface MonthAndYear {
    month: number;
    year: number;
}

export interface MonthYearPeriod {
    startMonthYear: MonthAndYear;
    endMonthYear: MonthAndYear;
}

export interface DayMonthYear {
    day: number;
    month: number;
    year: number;
}
// doc to other format https://github.com/moment/luxon/blob/master/docs/formatting.md
export enum LuxonFormats {
    YearShortMonthDay = 'yyyy LLL dd', // 2021 Jan 01
    YearMonthNumeric = 'yyyy-MM', // 2021-01
    ShortMonthYear = 'LLL yyyy', // Jan 2021
    MonthYear = 'LLLL yyyy', // January 2021
    numericYearMonthDay = 'yyyy-MM-dd', // 2021-01-01
    dayMonth = 'dd LLLL', // 01 January
    Month = 'LLLL', // January
}

export function isBeforeToday(date?: Date): boolean {
    return !date || new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
}

export function isAfterToday(date?: Date): boolean {
    return !date || new Date(date).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
}

export function isBeforeNow(date: Date): boolean {
    return date <= DateTime.now().toJSDate();
}

export function isToday(date: Date): boolean {
    return isSameDay(date, new Date());
}

export const getMonday = (date: Date) => {
    const { year, month, day, hour, minute, second } = DateTime.fromJSDate(date).startOf('week');
    return DateTime.utc(year, month, day, hour, minute, second).toJSDate();
};

export const isBetween = (date: Date, startDate: Date, endDate: Date, openedRange = true) => {
    if (!startDate && !endDate) {
        return true;
    }
    return openedRange
        ? DateTime.fromJSDate(date) >= DateTime.fromJSDate(startDate) && DateTime.fromJSDate(date) <= DateTime.fromJSDate(endDate)
        : DateTime.fromJSDate(date) > DateTime.fromJSDate(startDate) && DateTime.fromJSDate(date) < DateTime.fromJSDate(endDate);
};

export const createDate = (possibleDate: PossibleDate): Date | null => {
    if (possibleDate instanceof Date) {
        return possibleDate;
    }
    if (typeof possibleDate === 'string' || typeof possibleDate === 'number') {
        const d = new Date(possibleDate);
        if (isValidDate(d)) {
            return d;
        }
    }
    if (typeof (possibleDate as PossibleDateObject)?.year === 'number' && typeof (possibleDate as PossibleDateObject)?.week === 'number') {
        return _getDateOfISOWeek((possibleDate as PossibleDateObject).week, (possibleDate as PossibleDateObject).year);
    }
    return null;
};

const _getDateOfISOWeek = (week, year) => {
    // eslint-disable-next-line no-mixed-operators
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
};

export const isValidDate = (date) => date instanceof Date && !isNaN(date?.getTime());

export const castStringToDate = (stringDate: string): Date | null => {
    if (!stringDate) {
        return null;
    }
    const date = new Date(stringDate);
    return isValidDate(date) ? date : null;
};

export const createDateFromString = (stringDate: string, format = DateStringFormat.FR): Date | null => {
    if (!stringDate) {
        return null;
    }
    let separator: string = '';
    if (stringDate.match(/\d{2}\/\d{2}\/\d{4}/)) {
        separator = '/';
    }
    if (stringDate.match(/\d{2}-\d{2}-\d{4}/)) {
        separator = '-';
    }
    if (!separator) return null;
    let day: string, month: string, year: string;
    switch (format) {
        case DateStringFormat.EN:
            [month, day, year] = stringDate.split(separator);
            break;
        default:
            [day, month, year] = stringDate.split(separator);
            break;
    }
    return new Date(+year, +month - 1, +day);
};

export function isInDayList(day: Date, dayList: Date[]): boolean {
    return !!dayList.filter((d) => isSameDay(d, day)).length;
}

export function isSameDay(day1: Date, day2: Date): boolean {
    const day1DateTime = DateTime.fromJSDate(day1);
    const day2ADateTime = DateTime.fromJSDate(day2);
    return day1DateTime.hasSame(day2ADateTime, 'day');
}

export function setLuxonDefaultTimeZone(timeZone: string) {
    Settings.defaultZone = timeZone;
}

export function getWeeksFromPeriod(startDate: Date, endDate: Date): Week[] {
    const startMonday = DateTime.fromJSDate(getMonday(startDate));
    const endMonday = DateTime.fromJSDate(getMonday(endDate));
    const nbWeeks = endMonday.diff(startMonday, 'week').toObject().weeks;
    const weeks: Week[] = [];
    for (let weekNumber = 0; weekNumber <= (nbWeeks as number); weekNumber++) {
        const startOfCurrentWeek = startMonday.plus({ days: 7 * weekNumber });
        const endOfCurrentWeek = startMonday.plus({
            // eslint-disable-next-line no-mixed-operators
            days: 7 * (weekNumber + 1) - 1,
        });
        const startDayInsideRange = DateTime.max(DateTime.fromJSDate(startDate), startOfCurrentWeek);
        const endDayInsideRange = DateTime.min(DateTime.fromJSDate(endDate), endOfCurrentWeek);
        const daysInsideRange: Date[] = [];
        const rangeInDaysLength = Math.round(endDayInsideRange.diff(startDayInsideRange, 'day').toObject().days as number);
        for (let j = 0; j <= rangeInDaysLength; j++) {
            daysInsideRange.push(startDayInsideRange.plus({ days: j }).toJSDate());
        }
        const week = {
            start: startOfCurrentWeek.toJSDate(),
            end: endOfCurrentWeek.toJSDate(),
            startDayInsideRange: startDayInsideRange.toJSDate(),
            endDayInsideRange: endDayInsideRange.toJSDate(),
            days: daysInsideRange,
        };
        weeks.push(week);
    }
    return weeks;
}

export function getMonthsFromPeriod(startDate: Date, endDate: Date): Month[] {
    if (startDate > endDate) {
        return [];
    }

    const startsOfMonths: DateTime[] = [DateTime.fromJSDate(startDate).startOf('month')];
    const startOfMonthUpperLimit = DateTime.fromJSDate(endDate).startOf('month');

    while (startOfMonthUpperLimit > startsOfMonths[startsOfMonths.length - 1]) {
        startsOfMonths.push(startsOfMonths[startsOfMonths.length - 1].plus({ month: 1 }));
    }

    const months: Month[] = startsOfMonths.map((startOfMonth) => {
        const start: DateTime = startOfMonth;
        const end: DateTime = startOfMonth.endOf('month');
        let current: DateTime = start;
        const days: Date[] = [];
        while (end >= current) {
            days.push(current.toJSDate());
            current = current.plus({ day: 1 });
        }

        return {
            start: start.toJSDate(),
            end: end.toJSDate(),
            days: days,
        };
    });

    return months;
}

export function getDatesBetween(start: Date, end: Date): (Date | undefined)[] {
    const startDate = DateTime.fromJSDate(start);
    const endDate = DateTime.fromJSDate(end);
    const interval = Interval.fromDateTimes(startDate, endDate);
    const dates = interval.splitBy({ days: 1 }).map((dt) => dt.start?.toJSDate());
    return dates;
}

export function getTimeDifferenceInHours(date1: Date, date2: Date): number {
    return DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'hours').hours;
}

export function getTimeDifferenceInDays(date1: Date, date2: Date): number {
    return DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'days').days;
}

export function numberOfMonthsSinceGivenDate(date: Date): number {
    const malouCreationDate = DateTime.fromJSDate(date).startOf('month');
    const numberOfMonths = malouCreationDate.diffNow('months')?.months;
    return Math.floor(-numberOfMonths) ?? 0;
}

export function getNumberOfDaysFromMilliseconds(milliseconds: number): number {
    return Math.floor(milliseconds / TimeInMilliseconds.DAY);
}

export function getLastXMonthsBeforeCurrentMonth(
    nbMonths: number,
    { currentMonth, currentYear }: { currentMonth?: number; currentYear?: number } = {}
): MonthAndYear[] {
    const now = DateTime.now();
    return Array.from({ length: nbMonths }).map((_, index) => {
        const { month, year } = DateTime.local(currentYear ?? now.year, currentMonth ?? now.month, 1)
            .minus({ months: index + 1 })
            .startOf('month')
            .startOf('day');
        return { month, year };
    });
}

export function getMostRecentDate(dates: Date[]): Date | null {
    if (!dates.length) {
        return null;
    }
    return dates.filter((date) => !!date).reduce((acc, date) => (date.getTime() > acc.getTime() ? date : acc), dates[0]);
}

export function getNumberOfMonthsSinceMostRecentDate(dates: Date[]): number {
    const mostRecentDate = getMostRecentDate(dates);
    return mostRecentDate ? numberOfMonthsSinceGivenDate(mostRecentDate) : 0;
}

export function formatEnglishDateWithSuffix(date: Date): string {
    const formattedDate = DateTime.fromJSDate(date).setLocale(Locale.EN).toFormat('MMMM d');
    let suffix = '';
    switch (DateTime.fromJSDate(date).day) {
        case 1:
        case 21:
        case 31:
            suffix = 'st';
            break;
        case 2:
        case 22:
            suffix = 'nd';
            break;
        case 3:
        case 23:
            suffix = 'rd';
            break;
        default:
            suffix = 'th';
            break;
    }
    return `${formattedDate}${suffix}`;
}

export function getMonthAndYearBetweenDates(startDate: Date, endDate: Date): MonthAndYear[] {
    if (startDate > endDate) {
        return [];
    }

    const start = DateTime.fromJSDate(startDate).startOf('month');
    const end = DateTime.fromJSDate(endDate).startOf('month');
    const monthsAndYears: MonthAndYear[] = [];

    let current = start;
    while (current <= end) {
        monthsAndYears.push({ month: current.month, year: current.year });
        current = current.plus({ months: 1 });
    }

    return monthsAndYears;
}

export function getRangeForMonthYearPeriod(monthYearPeriod: MonthYearPeriod): MonthAndYear[] {
    const { startMonthYear, endMonthYear } = monthYearPeriod;
    const startDateTime = DateTime.fromObject(startMonthYear);
    const endDateTime = DateTime.fromObject(endMonthYear).plus({ months: 1 });
    return Interval.fromDateTimes(startDateTime, endDateTime)
        .splitBy({ months: 1 })
        .map((interval) => ({
            month: interval!.start!.month,
            year: interval!.start!.year,
        }));
}

export function getPreviousMonthYear(monthYear: MonthAndYear, monthsCount: number): MonthAndYear {
    const { month, year } = monthYear;
    const date = DateTime.fromObject({ month, year }).minus({ months: monthsCount });
    return {
        month: date.month,
        year: date.year,
    };
}

export function createDateFromMalouDate({ day, month, year }: DayMonthYear): Date {
    return new Date(year, month, day);
}
