<div>
    <div class="my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'statistics_pdf.social_network_pdf.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantTitle$ | async | statisticsPdfRestaurantsFormatter }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ displayedPlatforms }}</span>
    </div>

    <div class="px-8.5 py-4">
        <div class="flex flex-col">
            <div class="mt-6 flex gap-6 md:flex-col">
                @if (isReleaseBoosterPerformanceImprovementsEnabled()) {
                    <ng-container *ngTemplateOutlet="performanceImprovementsTemplate"></ng-container>
                } @else {
                    <ng-container *ngTemplateOutlet="oldVersionTemplate"></ng-container>
                }
            </div>

            @if (
                (displayedCharts | includes: InsightsChart.POST_INSIGHTS) ||
                (displayedCharts | includes: InsightsChart.STORY_INSIGHTS) ||
                (displayedCharts | includes: InsightsChart.REEL_INSIGHTS)
            ) {
                <div class="mt-10">
                    <app-posts-insights-table
                        [shouldDetailPostTables]="true"
                        [shouldDisplayPostInsightsTable]="displayedCharts | includes: InsightsChart.POST_INSIGHTS"
                        [shouldDisplayReelInsightsTable]="displayedCharts | includes: InsightsChart.REEL_INSIGHTS"
                        [shouldDisplayStoryInsightsTable]="displayedCharts | includes: InsightsChart.STORY_INSIGHTS"
                        [shouldHidePublicationsTablesIfNoData]="true"
                        [shouldLazyLoadMedia]="false"
                        [postsTableSortOptions]="chartOptions[InsightsChart.POST_INSIGHTS]?.tableSortOptions"
                        [reelsTableSortOptions]="chartOptions[InsightsChart.REEL_INSIGHTS]?.tableSortOptions"
                        [storiesTableSortOptions]="chartOptions[InsightsChart.STORY_INSIGHTS]?.tableSortOptions">
                    </app-posts-insights-table>
                </div>
            }
        </div>
    </div>
</div>

<ng-template #performanceImprovementsTemplate>
    @if ((displayedCharts | includes: InsightsChart.COMMUNITY) && communityHasData) {
        <div class="min-w-0 flex-1 break-inside-avoid">
            <app-community-v2
                [hiddenDatasetIndexes]="chartOptions[InsightsChart.COMMUNITY]?.hiddenDatasetIndexes ?? []"
                [viewBy]="chartOptions[InsightsChart.COMMUNITY]?.viewBy ?? ViewBy.DAY"
                [showViewByTextInsteadOfSelector]="true"
                (hasDataChange)="communityHasData = $event">
            </app-community-v2>
        </div>
    }
    @if ((displayedCharts | includes: InsightsChart.ENGAGEMENT) && engagementHasData) {
        <div class="min-w-0 flex-1 break-inside-avoid">
            <app-engagement-v2
                [hiddenDatasetIndexes]="chartOptions[InsightsChart.ENGAGEMENT]?.hiddenDatasetIndexes ?? []"
                [viewBy]="chartOptions[InsightsChart.ENGAGEMENT]?.viewBy ?? ViewBy.DAY"
                [showViewByTextInsteadOfSelector]="true"
                (hasDataChange)="engagementHasData = $event">
            </app-engagement-v2>
        </div>
    }
</ng-template>

<ng-template #oldVersionTemplate>
    @if ((displayedCharts | includes: InsightsChart.COMMUNITY) && communityHasData) {
        <div class="min-w-0 flex-1 break-inside-avoid">
            <app-community
                [hiddenDatasetIndexes]="chartOptions[InsightsChart.COMMUNITY]?.hiddenDatasetIndexes ?? []"
                [viewBy]="chartOptions[InsightsChart.COMMUNITY]?.viewBy"
                [showViewByTextInsteadOfSelector]="true"
                (hasDataChange)="communityHasData = $event">
            </app-community>
        </div>
    }
    @if ((displayedCharts | includes: InsightsChart.ENGAGEMENT) && engagementHasData) {
        <div class="min-w-0 flex-1 break-inside-avoid">
            <app-engagement
                [hiddenDatasetIndexes]="chartOptions[InsightsChart.ENGAGEMENT]?.hiddenDatasetIndexes ?? []"
                [viewBy]="chartOptions[InsightsChart.ENGAGEMENT]?.viewBy"
                [showViewByTextInsteadOfSelector]="true"
                (hasDataChange)="engagementHasData = $event">
            </app-engagement>
        </div>
    }
</ng-template>
