import { Action, createReducer, on } from '@ngrx/store';

import { KeywordSearchImpressionsTypeExtended, PlatformFilterPage } from '@malou-io/package-utils';

import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { AggregatedStatisticsState } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import { MalouDateFilters, MalouPeriod, MalouTimeScalePeriod, Restaurant } from ':shared/models';

const malouDateFilters = new MalouDateFilters();

export const initialState: AggregatedStatisticsState = {
    filters: {
        dates: malouDateFilters.getFilter({ period: MalouPeriod.DEFAULT }),
        platforms: {
            [PlatformFilterPage.E_REPUTATION]: [],
            [PlatformFilterPage.SOCIAL_NETWORKS]: [],
            [PlatformFilterPage.BOOSTERS]: [],
            [PlatformFilterPage.ROI]: [],
            [PlatformFilterPage.SEO]: [],
        },
        restaurantIds: [],
        roiRestaurantIds: [],
        totemIds: [],
        timeScale: MalouTimeScalePeriod.LAST_SIX_MONTHS,
        monthYearPeriod: MalouDateFilters.getDefaultMonthYearPeriod(),
    },
    data: {
        platformsRatingsByRestaurant: {},
        reviewCounts: [],
        averageReviewsRatings: [],
        boosterStatsData: undefined,
        boosterStatsDataV2: undefined,
        topKeywordSearchImpressions: {
            [KeywordSearchImpressionsTypeExtended.BRANDING]: [],
            [KeywordSearchImpressionsTypeExtended.DISCOVERY]: [],
            [KeywordSearchImpressionsTypeExtended.MALOU_SELECTED]: [],
        },
        keywordRankings: [],
    },
    page: PlatformFilterPage.ROI,
    loaded: false,
};

const aggregatedStatisticsReducer = createReducer(
    initialState,
    on(AggregatedStatisticsActions.initializeState, (state, { data }) => ({
        ...state,
        filters: {
            dates: data.dates,
            platforms: {
                [PlatformFilterPage.E_REPUTATION]: data.platforms[PlatformFilterPage.E_REPUTATION],
                [PlatformFilterPage.SOCIAL_NETWORKS]: data.platforms[PlatformFilterPage.SOCIAL_NETWORKS],
                [PlatformFilterPage.BOOSTERS]: data.platforms[PlatformFilterPage.BOOSTERS],
                [PlatformFilterPage.ROI]: data.platforms[PlatformFilterPage.ROI],
                [PlatformFilterPage.SEO]: data.platforms[PlatformFilterPage.SEO],
            },
            restaurantIds: data.restaurantIds,
            roiRestaurantIds: data.roiRestaurantIds,
            totemIds: data.totemIds,
            timeScale: data.timeScale,
            monthYearPeriod: data.monthYearPeriod,
        },
        loaded: true,
    })),
    on(AggregatedStatisticsActions.editDates, (state, { dates }) => ({
        ...state,
        filters: {
            ...state.filters,
            dates: dates,
        },
    })),
    on(AggregatedStatisticsActions.editPlatforms, (state, { page, platforms }) => {
        if (!page) {
            return state;
        }
        return {
            ...state,
            filters: {
                ...state.filters,
                platforms: {
                    ...state.filters.platforms,
                    [page]: platforms,
                },
            },
        };
    }),
    on(AggregatedStatisticsActions.editRestaurants, (state, { restaurants }: { restaurants: Restaurant[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            restaurantIds: restaurants.map((restaurant) => restaurant._id),
        },
    })),
    on(AggregatedStatisticsActions.editRoiRestaurants, (state, { roiRestaurants }: { roiRestaurants: Restaurant[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            roiRestaurantIds: roiRestaurants.map((restaurant) => restaurant._id),
        },
    })),
    on(AggregatedStatisticsActions.editTotems, (state, { totemIds }: { totemIds: string[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            totemIds,
        },
    })),
    on(AggregatedStatisticsActions.editPlatformsRatingsByRestaurantData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            platformsRatingsByRestaurant: data,
        },
    })),
    on(AggregatedStatisticsActions.editReviewCountsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            reviewCounts: data,
        },
    })),
    on(AggregatedStatisticsActions.editAverageReviewsRatingsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            averageReviewsRatings: data,
        },
    })),
    on(AggregatedStatisticsActions.editBoosterStatsData, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            boosterStatsData: data,
        },
    })),
    on(AggregatedStatisticsActions.editBoosterStatsDataV2, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            boosterStatsDataV2: data,
        },
    })),
    on(AggregatedStatisticsActions.editTimeScale, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            timeScale: data,
        },
    })),
    on(AggregatedStatisticsActions.editSelectedPage, (state, { page }) => ({
        ...state,
        page,
    })),
    on(AggregatedStatisticsActions.resetState, () => initialState),
    on(AggregatedStatisticsActions.editMonthYearPeriod, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            monthYearPeriod: data,
        },
    })),
    on(AggregatedStatisticsActions.editTopKeywordSearchImpressions, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            topKeywordSearchImpressions: data,
        },
    })),
    on(AggregatedStatisticsActions.editKeywordRankings, (state, { data }) => ({
        ...state,
        data: {
            ...state.data,
            keywordRankings: data,
        },
    }))
);

export function reducer(state: AggregatedStatisticsState, action: Action): AggregatedStatisticsState {
    return aggregatedStatisticsReducer(state, action);
}
