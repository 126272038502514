<div class="flex flex-col gap-8">
    @if (keywordsToDisplayCountByType().branding > 0) {
        <div class="flex flex-col gap-3">
            <div class="malou-simple-card--light flex flex-1 items-center justify-center py-4">
                <div class="malou-text-13--bold malou-color-text-1">
                    {{ 'statistics.seo.keyword_search_impressions.branding' | translate }}
                </div>
            </div>

            @for (index of keywordsToDisplayCountByType().branding | createIndexArray; track index) {
                @if (topKeywordSearchImpressions().branding[index]) {
                    <ng-container
                        [ngTemplateOutlet]="keywordSearchTemplate"
                        [ngTemplateOutletContext]="{ type: KeywordSearchImpressionsType.BRANDING, position: index }">
                    </ng-container>
                }
            }

            @if (
                !shouldHideTopKeywordsSeeMoreButton() &&
                topKeywordSearchImpressions().branding.length > keywordsToDisplayCountByType().branding
            ) {
                <ng-container
                    [ngTemplateOutlet]="seeMoreTemplate"
                    [ngTemplateOutletContext]="{ type: KeywordSearchImpressionsType.BRANDING }">
                </ng-container>
            }
        </div>
    }

    @if (keywordsToDisplayCountByType().discovery > 0) {
        <div class="flex flex-col gap-3">
            <div class="malou-simple-card--light flex flex-1 items-center justify-center py-4">
                <div class="malou-text-13--bold malou-color-text-1">
                    {{ 'statistics.seo.keyword_search_impressions.discovery' | translate }}
                </div>
            </div>

            @for (index of keywordsToDisplayCountByType().discovery | createIndexArray; track index) {
                @if (topKeywordSearchImpressions().discovery[index]) {
                    <ng-container
                        [ngTemplateOutlet]="keywordSearchTemplate"
                        [ngTemplateOutletContext]="{ type: KeywordSearchImpressionsType.DISCOVERY, position: index }">
                    </ng-container>
                }
            }

            @if (
                !shouldHideTopKeywordsSeeMoreButton() &&
                topKeywordSearchImpressions().discovery.length > keywordsToDisplayCountByType().discovery
            ) {
                <ng-container
                    [ngTemplateOutlet]="seeMoreTemplate"
                    [ngTemplateOutletContext]="{ type: KeywordSearchImpressionsType.DISCOVERY }">
                </ng-container>
            }
        </div>
    }
</div>

<ng-template let-position="position" let-type="type" #keywordSearchTemplate>
    <div class="malou-simple-card flex flex-1 justify-between p-3 px-4">
        <div class="flex items-center gap-2">
            <div class="w-[22px]">
                <img class="w-full" [src]="getEmojiFromPosition | applyPure: position" />
            </div>
            <p
                class="malou-text-13 malou-color-text-2"
                [matTooltip]="getKeywordSearchTooltip | applyPure: topKeywordSearchImpressions()[type][position].keywordSearch">
                {{ topKeywordSearchImpressions()[type][position].keywordSearch | shortText: SHORT_TEXT_LENGTH }}
            </p>
        </div>
        <p class="malou-text-13--bold malou-color-text-1">
            @if (topKeywordSearchImpressions()[type][position].value === MIN_KEYWORD_SEARCH_IMPRESSIONS_VALUE) {
                <span>&#60; </span>
            }
            {{ topKeywordSearchImpressions()[type][position].value }}
        </p>
    </div>
</ng-template>

<ng-template let-type="type" #seeMoreTemplate>
    <div class="flex justify-center">
        <button class="malou-btn-flat !p-0" (click)="openTopKeywordSearchModal(type)">
            {{ 'common.see_more' | translate }}
        </button>
    </div>
</ng-template>
