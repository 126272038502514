import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { combineLatest, firstValueFrom } from 'rxjs';

import { AiSocialPostDuplicationCaptionResponseDto } from '@malou-io/package-dto';
import { generateDbId, HashtagType, PlatformKey, PostPublicationStatus, PostSource, PostType } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PostToDuplicate } from ':modules/posts-v2/social-posts/models/post-to-duplicate';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { OriginalPostPreviewV2Component } from ':shared/components/duplicate-post-preview-modal/components/original-post-preview-v2/original-post-preview-v2.component';
import { SchedulePostFormComponent } from ':shared/components/duplicate-post-preview-modal/components/schedule-post-form/schedule-post-form.component';
import { SocialPostCaptionPreviewCardV2Component } from ':shared/components/duplicate-post-preview-modal/duplicate-social-post-with-text-generation-preview-modal-v2/social-post-caption-preview-card-v2/social-post-caption-preview-card-v2.component';
import { buildPlus15RoundedMinutesDate } from ':shared/components/duplicate-post-preview-modal/helpers/plus-15-minutes-date';
import { onlyFutureDate } from ':shared/components/duplicate-post-preview-modal/validators/publication-date-in-the-future';
import { KeepSamePostCaptionToggleComponent } from ':shared/components/keep-same-post-caption-toggle/keep-same-post-caption-toggle.component';
import { MultipleStepsLoaderComponent } from ':shared/components/multiple-steps-loader/multiple-steps-loader.component';
import { BaseStepComponent } from ':shared/components/stepper-modal/base-step.component';
import { isPastHour } from ':shared/helpers/date';
import { FbLocation, Hashtag, PostHashtags, Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

export interface DuplicateSocialPostWithTextGenerationInputData {
    selectedRestaurants: Restaurant[];
    index: number;
}

interface SharedData {
    postsToDuplicate: PostToDuplicate[];
    postDestination: PostSource;
}

const BaseStepDuplicatePostPreviewComponent = BaseStepComponent<DuplicateSocialPostWithTextGenerationInputData, SharedData>;

export interface DuplicateSocialPostPreviewModalSubmitData {
    restaurant: Restaurant;
    status: PostPublicationStatus;
    plannedPublicationDate: Date;
    hashtags: PostHashtags;
    text: string;
    location: FbLocation | null;
    platformKeys: PlatformKey[];
}

@Component({
    selector: 'app-duplicate-social-post-with-text-generation-preview-modal-v2',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatDividerModule,
        MatIconModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatFormFieldModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        LazyLoadImageModule,
        IllustrationPathResolverPipe,
        SchedulePostFormComponent,
        OriginalPostPreviewV2Component,
        SocialPostCaptionPreviewCardV2Component,
        MultipleStepsLoaderComponent,
        KeepSamePostCaptionToggleComponent,
    ],
    templateUrl: './duplicate-social-post-with-text-generation-preview-modal-v2.component.html',
    styleUrl: './duplicate-social-post-with-text-generation-preview-modal-v2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateSocialPostWithTextGenerationPreviewModalV2Component extends BaseStepDuplicatePostPreviewComponent implements OnInit {
    readonly Illustration = Illustration;

    readonly customizedDatePostForm = new FormGroup(
        {
            status: new FormControl<PostDateStatus>(PostDateStatus.LATER),
            plannedPublicationDate: new FormControl<Date>(new Date()),
            postTime: new FormControl(''),
        },
        {
            validators: [onlyFutureDate()],
        }
    );
    readonly willPostAllAtSameTime = signal(true);

    readonly restaurant = inject(RestaurantsService).restaurantSelected$.value;
    private readonly _postsService = inject(PostsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly isCaptionsGenerationError = signal(false);
    readonly isLoading = signal(false);
    readonly isLoaderMinDurationReached = signal(false);
    readonly _LOADER_MIN_DURATION = 500;

    readonly socialPostLoaderSteps = [
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_1'),
        this._translateService.instant('posts.duplicate_social_post_modal.loader_steps.step_2'),
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_3'),
    ];
    readonly seoPostLoaderSteps = [
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_1'),
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_seo_2'),
        this._translateService.instant('posts.duplicate_post_modal.loader_steps.step_3'),
    ];
    readonly loaderSteps = signal<string[]>(this.socialPostLoaderSteps);

    readonly previewCaptionPostForms = new FormArray<
        FormGroup<{
            plannedPublicationDate: FormControl<Date>;
            postTime: FormControl<string>;
            postCaption: FormControl<string>;
            postCaptionTriggeredByKeepSameCaptionToggle: FormControl<string>;
            keys: FormControl<PlatformKey[]>;
            hashtagTexts: FormControl<string[]>;
            // wont be visible in the UI
            restaurant: FormControl<Restaurant>;
            location: FormControl<FbLocation | null>;
            fbPlatformName: FormControl<string | null>;
            hasPlatformsConnected: FormControl<boolean>;
        }>
    >([]);

    readonly isStepValid = signal(false);
    readonly shouldKeepSameCaptionForAllPosts = signal(false);

    postToDuplicate: PostToDuplicate;

    constructor() {
        super();
        effect(() => {
            const shouldKeepSameCaptionForAllPosts = this.shouldKeepSameCaptionForAllPosts();
            this.previewCaptionPostForms.controls.forEach((form) => {
                if (shouldKeepSameCaptionForAllPosts) {
                    form.get('postCaption')!.disable();
                    form.get('postCaptionTriggeredByKeepSameCaptionToggle')!.enable();
                } else {
                    form.get('postCaption')!.enable();
                    form.get('postCaptionTriggeredByKeepSameCaptionToggle')!.disable();
                }
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.postToDuplicate = this.sharedData.postsToDuplicate[this.inputData.index];
        this.loaderSteps.set(this.sharedData.postDestination === PostSource.SEO ? this.seoPostLoaderSteps : this.socialPostLoaderSteps);
        this._initializePostForm();
        this._startSocialPostDuplication();
        this._initializeFormsValidation();
    }

    protected _submitData(): DuplicateSocialPostPreviewModalSubmitData[] {
        return this.previewCaptionPostForms.controls
            .filter((form) => form.get('hasPlatformsConnected')?.value)
            .map((form) => {
                const { status, plannedPublicationDate } = this._buildPostStatusAndDate(form);

                return {
                    restaurant: form.get('restaurant')!.value,
                    status,
                    plannedPublicationDate,
                    text: this.shouldKeepSameCaptionForAllPosts()
                        ? form.get('postCaptionTriggeredByKeepSameCaptionToggle')!.value
                        : form.get('postCaption')!.value,
                    hashtags: {
                        selected: form.get('hashtagTexts')!.value.map(
                            (text) =>
                                new Hashtag({
                                    text,
                                    type: HashtagType.RESTAURANT,
                                    createdAt: new Date(),
                                    id: generateDbId().toHexString(),
                                    isCustomerInput: false,
                                    isMain: false,
                                    updatedAt: new Date(),
                                })
                        ),
                        suggested: [],
                    },
                    location: form.get('location')!.value,
                    platformKeys: form.get('keys')!.value,
                };
            });
    }

    private _buildPostStatusAndDate(
        form: FormGroup<{
            plannedPublicationDate: FormControl<Date>;
            postTime: FormControl<string>;
            postCaption: FormControl<string>;
            postCaptionTriggeredByKeepSameCaptionToggle: FormControl<string>;
            keys: FormControl<PlatformKey[]>;
            hashtagTexts: FormControl<string[]>;
            // wont be visible in the UI
            restaurant: FormControl<Restaurant>;
            location: FormControl<FbLocation | null>;
            fbPlatformName: FormControl<string | null>;
            hasPlatformsConnected: FormControl<boolean>;
        }>
    ): { status: PostPublicationStatus; plannedPublicationDate: Date } {
        let plannedPublicationDate: Date;
        let status: PostPublicationStatus;

        if (!this.willPostAllAtSameTime()) {
            plannedPublicationDate = this._buildDate({
                plannedPublicationDate: form.get('plannedPublicationDate')!.value,
                postTime: form.get('postTime')!.value,
            });
            status = PostPublicationStatus.PENDING;
        } else {
            const statusForm = this.customizedDatePostForm.get('status')!.value;
            if (statusForm === PostDateStatus.NOW) {
                plannedPublicationDate = new Date();
                status = PostPublicationStatus.PENDING;
            } else {
                plannedPublicationDate = this._buildDate({
                    plannedPublicationDate: this.customizedDatePostForm.get('plannedPublicationDate')!.value!,
                    postTime: this.customizedDatePostForm.get('postTime')!.value!,
                });
                status = statusForm === PostDateStatus.DRAFT ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING;
            }
        }
        return { status, plannedPublicationDate };
    }

    protected _isValid(): boolean {
        return this.isStepValid();
    }

    private _buildDate({ plannedPublicationDate, postTime }: { plannedPublicationDate: Date; postTime: string }): Date {
        const date = new Date(plannedPublicationDate);
        const [hours, minutes] = postTime.split(':').map((n) => parseInt(n, 10));
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }

    private async _startSocialPostDuplication(): Promise<void> {
        this.isLoading.set(true);
        setTimeout(() => {
            this.isLoaderMinDurationReached.set(true);
        }, this._LOADER_MIN_DURATION);
        const postId = this.postToDuplicate.id;
        const restaurantIds = this.inputData.selectedRestaurants.map((restaurant) => restaurant._id.toString());

        try {
            const captions = await firstValueFrom(
                this._postsService.duplicateSocialPostTextsForRestaurants({
                    postIdToDuplicate: postId,
                    restaurantIds,
                    postDestination: this.sharedData.postDestination,
                })
            );
            this.isLoading.set(false);
            this._buildPreviewCaptionForms(captions);
        } catch (error) {
            this.isCaptionsGenerationError.set(true);
            this.isLoading.set(false);
        }
    }

    private _buildPreviewCaptionForms(captions: AiSocialPostDuplicationCaptionResponseDto): void {
        const postDestination = this.sharedData.postDestination;

        const restaurantCaptions = captions.map((caption) => {
            const restaurant = this.inputData.selectedRestaurants.find((rest) => rest._id === caption.restaurantId);
            return {
                restaurant,
                ...caption,
            };
        });
        for (const caption of restaurantCaptions) {
            let keys: PlatformKey[] = [];
            if (postDestination === PostSource.SOCIAL) {
                // carousel with video is not supported on facebook
                keys =
                    this.postToDuplicate.containsVideo() && this.postToDuplicate.postType !== PostType.REEL
                        ? caption.keys.filter((platformKey) => platformKey !== PlatformKey.FACEBOOK)
                        : caption.keys;
            } else {
                keys = caption.keys;
            }
            const hasPlatformsConnected = keys.length > 0;
            const form = new FormGroup(
                {
                    plannedPublicationDate: new FormControl<Date>(this.customizedDatePostForm.get('plannedPublicationDate')!.value!, {
                        nonNullable: true,
                    }),
                    postTime: new FormControl(this.customizedDatePostForm.get('postTime')!.value!, { nonNullable: true }),
                    postCaption: new FormControl(hasPlatformsConnected ? caption.postCaption : '', {
                        nonNullable: true,
                        validators: hasPlatformsConnected ? [Validators.required] : [],
                    }),
                    postCaptionTriggeredByKeepSameCaptionToggle: new FormControl(hasPlatformsConnected ? this.postToDuplicate.text : '', {
                        nonNullable: true,
                        validators: hasPlatformsConnected ? [Validators.required] : [],
                    }),
                    keys: new FormControl<PlatformKey[]>(keys, { nonNullable: true }),
                    hashtagTexts: new FormControl<string[]>(
                        hasPlatformsConnected && postDestination === PostSource.SOCIAL ? (caption.hashtags ?? []) : [],
                        {
                            nonNullable: true,
                        }
                    ),
                    // wont be visible in the UI
                    restaurant: new FormControl<Restaurant>(caption.restaurant!, { nonNullable: true }),
                    location: new FormControl<FbLocation | null>(null),
                    fbPlatformName: new FormControl<string | null>(postDestination === PostSource.SOCIAL ? caption.fbPlatformName : null),
                    hasPlatformsConnected: new FormControl<boolean>(hasPlatformsConnected, { nonNullable: true }),
                },
                {
                    validators: [onlyFutureDate()],
                }
            );
            this.previewCaptionPostForms.push(form);
            if (!hasPlatformsConnected) {
                form.disable();
            }
            form.get('postCaptionTriggeredByKeepSameCaptionToggle')?.disable();
        }
    }

    private _initializePostForm(): void {
        const postDate = this.postToDuplicate.plannedPublicationDate ?? new Date();
        const status = this.postToDuplicate.published === PostPublicationStatus.PENDING ? PostDateStatus.LATER : PostDateStatus.DRAFT;
        if (postDate >= new Date()) {
            const postTime = buildPlus15RoundedMinutesDate(postDate).toFormat('HH:mm');
            this.customizedDatePostForm.patchValue({
                status,
                plannedPublicationDate: postDate,
                postTime,
            });
        } else {
            const plannedPublicationDate = buildPlus15RoundedMinutesDate();
            this.customizedDatePostForm.patchValue({
                status,
                plannedPublicationDate: plannedPublicationDate.toJSDate(),
                postTime: plannedPublicationDate.toFormat('HH:mm'),
            });
        }
    }

    private _initializeFormsValidation(): void {
        combineLatest([this.customizedDatePostForm.valueChanges, this.previewCaptionPostForms.valueChanges])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.isStepValid.set(this._areFormsValid() && !this.isLoading());
                this.valid.emit(this.isStepValid());
            });
    }

    private _areFormsValid(): boolean {
        let isPlannedPublicationDateValid = false;

        if (this.willPostAllAtSameTime()) {
            isPlannedPublicationDateValid = this.customizedDatePostForm.valid;
        } else {
            isPlannedPublicationDateValid = this.previewCaptionPostForms.controls.every((postForm) => {
                const plannedPublicationDate = postForm.controls['plannedPublicationDate'].value;
                const postTime = postForm.controls['postTime'].value;
                return !isPastHour({ hourWithMinute: postTime, date: plannedPublicationDate });
            });
        }

        let isCaptionValid = false;
        if (this.shouldKeepSameCaptionForAllPosts()) {
            isCaptionValid = this.previewCaptionPostForms.controls.every(
                (postForm) => postForm.controls['postCaptionTriggeredByKeepSameCaptionToggle'].valid
            );
        } else {
            isCaptionValid = this.previewCaptionPostForms.controls.every((postForm) => postForm.controls['postCaption'].valid);
        }

        return isPlannedPublicationDateValid && isCaptionValid;
    }
}
