import { TimeSeriesSegmentAnalysisDto } from '@malou-io/package-dto';

export class TimeSeriesSegmentAnalysisWithRange {
    startDate: string;
    endDate: string;
    results: {
        dataPerDay: DaySegmentAnalysis[];
        dataPerWeek: WeekSegmentAnalysis[];
        dataPerMonth: MonthSegmentAnalysis[];
    };

    constructor(init: TimeSeriesSegmentAnalysisDto) {
        Object.assign(this, init);
    }

    static fromDto(dto: TimeSeriesSegmentAnalysisDto): TimeSeriesSegmentAnalysisWithRange {
        return new TimeSeriesSegmentAnalysisWithRange({
            startDate: dto.startDate,
            endDate: dto.endDate,
            results: {
                dataPerDay: dto.results.dataPerDay.map((item) => new DaySegmentAnalysis(item)),
                dataPerWeek: dto.results.dataPerWeek.map((item) => new WeekSegmentAnalysis(item)),
                dataPerMonth: dto.results.dataPerMonth.map((item) => new MonthSegmentAnalysis(item)),
            },
        });
    }
}

export class DaySegmentAnalysis {
    day: number;
    year: number;
    positiveCount: number;
    negativeCount: number;

    constructor(init: TimeSeriesSegmentAnalysisDto['results']['dataPerDay'][0]) {
        this.day = init.day;
        this.year = init.year;
        this.positiveCount = init.positiveCount;
        this.negativeCount = init.negativeCount;
    }

    getKey(): string {
        return `${this.year}-${this.day}`;
    }
}

export class WeekSegmentAnalysis {
    week: number;
    year: number;
    positiveCount: number;
    negativeCount: number;

    constructor(init: TimeSeriesSegmentAnalysisDto['results']['dataPerWeek'][0]) {
        this.week = init.week;
        this.year = init.year;
        this.positiveCount = init.positiveCount;
        this.negativeCount = init.negativeCount;
    }

    getKey(): string {
        return `${this.year}-${this.week}`;
    }
}

export class MonthSegmentAnalysis {
    month: number;
    year: number;
    positiveCount: number;
    negativeCount: number;

    constructor(init: TimeSeriesSegmentAnalysisDto['results']['dataPerMonth'][0]) {
        Object.assign(this, init);
    }

    getKey(): string {
        return `${this.year}-${this.month}`;
    }
}

export type TimeSeriesSegmentAnalysis = DaySegmentAnalysis | WeekSegmentAnalysis | MonthSegmentAnalysis;
