/**
 * The api doc is trash but this is better than nothing
 * https://developers.facebook.com/docs/marketing-api/error-reference/
 *
 * We prefer to base our error code on messages and match them with regex
 */
export enum FbErrorCodeOrMessage {
    FACEBOOK_FIELD_MISSING_PERMISSIONS = 'FACEBOOK_FIELD_MISSING_PERMISSIONS',
    FACEBOOK_INCONSISTENT_COORDINATES = 'FACEBOOK_INCONSISTENT_COORDINATES',
}
