import { NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    effect,
    inject,
    Injector,
    input,
    OnChanges,
    OnInit,
    runInInjectionContext,
    signal,
    Signal,
    SimpleChanges,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { asapScheduler, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import {
    HeapEventName,
    MalouComparisonPeriod,
    MonthYearPeriod,
    PlatformDefinitions,
    PlatformFilterPage,
    PlatformKey,
} from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import * as PlatformsReducer from ':modules/platforms/store/platforms.reducer';
import { StatisticsFiltersContext } from ':modules/statistics/filters/filters.context';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { GroupedDateFiltersV2Component } from ':shared/components/grouped-date-filters-v2/grouped-date-filters.component';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { MonthYearDatePickerV2Component } from ':shared/components/month-year-date-picker-v2/month-year-date-picker-v2.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { SelectTimeScaleFilterComponent } from ':shared/components/select-time-scale-filter/select-time-scale-filter.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DatePickerType } from ':shared/enums/date-pickers';
import { getSortedPlatformKeys } from ':shared/helpers/get-sorted-platform-keys';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, MalouDateFilters, MalouPeriod, MalouTimeScalePeriod, Nfc } from ':shared/models';

@Component({
    selector: 'app-statistics-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        GroupedDateFiltersComponent,
        SelectChipListComponent,
        SelectPlatformsComponent,
        NgTemplateOutlet,
        SelectTimeScaleFilterComponent,
        GroupedDateFiltersV2Component,
        MonthYearDatePickerV2Component,
    ],
})
@AutoUnsubscribeOnDestroy()
export class FiltersComponent implements OnInit, KillSubscriptions, OnChanges {
    readonly showPlatformsFilter = input<boolean>(false);
    readonly platformFilterPage = input<PlatformFilterPage>();
    readonly restaurantTotems = input<Nfc[]>([]);
    readonly isRecentRestaurant = input<boolean>(false);
    readonly timeScaleMinAcceptedDate = input<Date | null>();
    readonly datePickerType = input<DatePickerType>(DatePickerType.GROUPED_DATE);

    private readonly _store = inject(Store);
    public readonly screenSizeService = inject(ScreenSizeService);
    private readonly _translateService = inject(TranslateService);
    private readonly _injector = inject(Injector);
    private readonly _statisticsFiltersContext = inject(StatisticsFiltersContext);
    private readonly _heapService = inject(HeapService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly MalouPeriod = MalouPeriod;
    readonly DatePickerType = DatePickerType;
    readonly DEFAULT_MAX_MONTH_YEAR_RANGE = 18;

    readonly period: WritableSignal<MalouPeriod> = signal(MalouPeriod.LAST_SEVEN_DAYS);
    readonly startDate: WritableSignal<Date | null> = signal(null);
    readonly endDate: WritableSignal<Date | null> = signal(null);
    readonly comparisonPeriod: WritableSignal<MalouComparisonPeriod> = signal(MalouComparisonPeriod.PREVIOUS_PERIOD);
    readonly monthYearPeriod: WritableSignal<MonthYearPeriod> = signal(MalouDateFilters.getDefaultMonthYearPeriod());

    connectedPlatforms: PlatformKey[];
    connectedPlatforms$: Observable<PlatformKey[]>;
    readonly platformsFilterControl: FormControl<string[]> = new FormControl<string[]>([]) as FormControl<string[]>;

    readonly showTotemsFilter: Signal<boolean> = computed(() => this.restaurantTotems && this.restaurantTotems().length > 0);
    readonly totemsFilterControl: FormControl<Nfc[]> = new FormControl<Nfc[]>([]) as FormControl<Nfc[]>;
    readonly timeScaleFilterControl: FormControl<MalouTimeScalePeriod> = new FormControl<MalouTimeScalePeriod>(
        MalouTimeScalePeriod.LAST_SIX_MONTHS
    ) as FormControl<MalouTimeScalePeriod>;
    readonly restaurantStartDate = computed(() => {
        const restaurantCreatedAt = this._restaurantsService.selectedRestaurant()?.createdAt;
        if (!restaurantCreatedAt) {
            return null;
        }
        return new Date(restaurantCreatedAt);
    });
    readonly shouldShowComparisonPeriodSelector = computed(() => {
        const restaurantCreatedAt = this.restaurantStartDate();
        if (!restaurantCreatedAt) {
            return false;
        }
        return DateTime.fromJSDate(restaurantCreatedAt).diffNow().as('years') < -1;
    });

    // TODO: Remove when the feature flag is removed
    readonly isReleaseNewCalendarEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-statistics-new-calendar'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-statistics-new-calendar'),
    });

    readonly DEFAULT_PERIODS = [
        MalouPeriod.LAST_SEVEN_DAYS,
        MalouPeriod.LAST_THIRTY_DAYS,
        MalouPeriod.LAST_THREE_MONTHS,
        MalouPeriod.LAST_TWELVE_MONTHS,
    ];

    ngOnInit(): void {
        this._initDatesListener();
        this.initTimeScaleFilter();
        this._initMonthYearPeriod();

        if (this.showPlatformsFilter()) {
            this.initPlatformsFilter();
        }

        runInInjectionContext(this._injector, () => {
            effect(() => {
                if (this.showTotemsFilter()) {
                    this.initTotemsFilter();
                }
            });
        });
    }

    private _initDatesListener(): void {
        this._store
            .select(StatisticsSelector.selectDatesFilter)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((dates: DatesAndPeriod) => {
                this.period.set(dates.period);
                this.startDate.set(dates.startDate);
                this.endDate.set(dates.endDate);
                if (this.platformFilterPage() === PlatformFilterPage.SEO || this.platformFilterPage() === PlatformFilterPage.E_REPUTATION) {
                    this._trackDateChange(dates);
                }
            });
        this._store.select(StatisticsSelector.selectComparisonPeriodFilter).subscribe((comparisonPeriod) => {
            this.comparisonPeriod.set(comparisonPeriod);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.showTotemsFilter?.currentValue) {
            this.initTotemsFilter();
        }
    }

    initPlatformsFilter(): void {
        this.connectedPlatforms$ = this._store.select(PlatformsReducer.selectCurrentPlatformKeys).pipe(
            map((platforms: PlatformKey[]) =>
                platforms.filter((platform: PlatformKey) => {
                    if (this.platformFilterPage() === PlatformFilterPage.E_REPUTATION) {
                        const keys: string[] = PlatformDefinitions.getPlatformKeysWithReview();
                        return keys.includes(platform);
                    }
                    if (this.platformFilterPage() === PlatformFilterPage.SOCIAL_NETWORKS) {
                        const keys: string[] = PlatformDefinitions.getPlatformKeysWithRSStats();
                        return keys.includes(platform);
                    }
                    return true;
                })
            ),
            map((e) => getSortedPlatformKeys(e))
        );

        this.connectedPlatforms$.subscribe((platforms) => {
            this.connectedPlatforms = platforms;
            return platforms;
        });

        const platformFilterPageValue = this.platformFilterPage();
        if (!platformFilterPageValue) {
            return;
        }

        combineLatest([
            this._store.select(StatisticsSelector.selectPlatformsFilter({ page: platformFilterPageValue })),
            this.connectedPlatforms$,
        ])
            .pipe(
                filter(([_, connectedPlatforms]) => !!connectedPlatforms),
                distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(([selectedPlatforms, connectedPlatforms]) => {
                if (selectedPlatforms.length === 0) {
                    this.onPlatformsChange(connectedPlatforms);
                } else {
                    this.platformsFilterControl.setValue(getSortedPlatformKeys([...selectedPlatforms]));
                }
            });
    }

    initTimeScaleFilter(): void {
        this._store.select(StatisticsSelector.selectTimeScaleFilter).subscribe((timeScale: MalouTimeScalePeriod) => {
            if (!timeScale) {
                const timeScaleInit = this.isRecentRestaurant()
                    ? MalouTimeScalePeriod.LAST_THREE_MONTHS
                    : MalouTimeScalePeriod.LAST_SIX_MONTHS;
                this.timeScaleFilterControl.setValue(timeScaleInit);
                this.onTimeScaleChange(timeScaleInit);
            } else {
                this.timeScaleFilterControl.setValue(timeScale);
            }
        });
    }

    initTotemsFilter(): void {
        this._statisticsFiltersContext.selectedTotems$.pipe(takeUntil(this.killSubscriptions$)).subscribe((totems: Nfc[]) => {
            if (totems.length === 0 && this.restaurantTotems().length > 0) {
                this.onTotemsChange(this.restaurantTotems());
            }
            this.totemsFilterControl.setValue(totems);
        });
    }

    chooseBoundaryDate(dates: DatesAndPeriod): void {
        this._store.dispatch(StatisticsActions.editDates({ dates: dates }));
    }

    chooseBoundaryDateAndPeriod({
        comparisonPeriod,
        datesAndPeriod,
    }: {
        datesAndPeriod: DatesAndPeriod;
        comparisonPeriod: MalouComparisonPeriod;
    }): void {
        this._store.dispatch(StatisticsActions.editDatesAndComparisonPeriod({ dates: datesAndPeriod, comparisonPeriod }));
        this._heapService.track(HeapEventName.TRACKING_CALENDAR_COMPARISON_PERIOD_CHANGE, {
            period: comparisonPeriod,
        });
    }

    onPlatformsChange(platforms: PlatformKey[]): void {
        const platformFilterPageValue = this.platformFilterPage();
        if (!platformFilterPageValue) {
            return;
        }
        this._store.dispatch(
            StatisticsActions.editPlatforms({
                page: platformFilterPageValue,
                platforms: platforms.length > 0 ? platforms : this.connectedPlatforms,
            })
        );
    }

    onTotemsChange(totems: Nfc[]): void {
        asapScheduler.schedule(() => {
            this._store.dispatch(StatisticsActions.editTotems({ totemIds: totems.map((totem) => totem.id) }));
        });
    }
    onTimeScaleChange(timeScale: MalouTimeScalePeriod): void {
        this._store.dispatch(StatisticsActions.editTimeScale({ data: timeScale }));
    }

    totemsDisplayWith = (totem: Nfc): string =>
        totem.isTotem() && (totem.name ?? totem.chipName)
            ? `${this._translateService.instant('statistics.totems.totem')} ${totem.name ?? totem.chipName}`
            : this._translateService.instant('enums.nfc_type.sticker');

    getTotemHash(totem: Nfc): string {
        return totem.id;
    }

    onMonthYearPeriodChanged(monthYearPeriod: MonthYearPeriod): void {
        this._store.dispatch(StatisticsActions.editMonthYearPeriod({ data: monthYearPeriod }));
    }

    private _initMonthYearPeriod(): void {
        this._store
            .select(StatisticsSelector.selectFilters)
            .pipe(
                filter((filters) => filters.isFiltersLoaded),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((filters) => {
                this.monthYearPeriod.set(filters.monthYearPeriod);
            });
    }

    private _trackDateChange(dates: DatesAndPeriod): void {
        this._heapService.track(
            this.platformFilterPage() === PlatformFilterPage.SEO
                ? HeapEventName.TRACKING_DATE_FILTER_CHANGES_SEO
                : HeapEventName.TRACKING_DATE_FILTER_CHANGES_E_REPUTATION,
            {
                startDate: dates.startDate?.toISOString(),
                endDate: dates.endDate?.toISOString(),
                period: dates.period,
                isCustom: dates.period === MalouPeriod.CUSTOM,
            }
        );
    }
}
