import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbilityBuilder } from '@casl/ability';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import {
    AddUserToRestaurantWithEmailResponseDto,
    AdminUpdateRestaurantBodyDto,
    CalendarEventDto,
    CreateRestaurantResponseDto,
    GetRestaurantCurrentStateResponseDto,
    GetUserWithEmailRestaurantsResponseDto,
    RestaurantWithoutStickerDto,
    UpdatePlatformAccessStatusBodyDto,
    UpdateRestaurantOrganizationParamsDto,
    UpdateRestaurantOrganizationRequestBodyDto,
    UpdateRestaurantsForUserDto,
} from '@malou-io/package-dto';
import {
    ApiResultV2,
    AppAbility,
    AppCaslRule,
    CaslRole,
    getAbilityRole,
    isNotNil,
    Role,
    userRestaurantAbilities,
} from '@malou-io/package-utils';

import { AbilitiesContext } from ':core/context/abilities.context';
import { ExperimentationService } from ':core/services/experimentation.service';
import { environment } from ':environments/environment';
import { SidenavState } from ':modules/sidenav-router/store/sidenav.reducer';
import { selectCurrentUserRestaurant, selectUserInfos } from ':modules/user/store/user.selectors';
import { UsersService } from ':modules/user/users.service';
import { formatArrayKeysForQueryParams, objectToQueryParams } from ':shared/helpers/query-params';
import {
    Address,
    ApiResult,
    Category,
    IRestaurant,
    Media,
    PlatformAccess,
    PostWithInsights,
    Restaurant,
    SpecialTimePeriod,
} from ':shared/models';
import { CalendarEvent } from ':shared/models/calendar-event';

import { IRestaurantAPI, RestaurantsMapper } from './mappers/restaurants.mapper';

@Injectable({ providedIn: 'root' })
export class RestaurantsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/restaurants`;

    restaurantSelected$ = new BehaviorSubject<Restaurant | null>(null);
    currentRestaurant: Restaurant;
    restaurantLoading$ = new BehaviorSubject(false);
    restaurantsLoading$ = new BehaviorSubject(false);
    restaurants = signal<Restaurant[]>([]);
    selectedRestaurant = toSignal(this.restaurantSelected$, {
        initialValue: null,
    });

    constructor(
        private readonly _http: HttpClient,
        private readonly _usersService: UsersService,
        private readonly _store: Store,
        private readonly _abilitiesContext: AbilitiesContext,
        private readonly _experimentationService: ExperimentationService
    ) {
        this.restaurantSelected$
            .pipe(tap((r) => this._experimentationService.updateCurrentRestaurantId(r?._id)))
            .subscribe((restaurant: Restaurant) => {
                this.currentRestaurant = new Restaurant(restaurant);
            });

        this.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant: Restaurant) => this._usersService.updateUserLastVisitedRestaurant(restaurant._id))
            )
            .subscribe();

        this.restaurantSelected$
            .pipe(
                switchMap(() =>
                    forkJoin([
                        this._store.select(selectCurrentUserRestaurant).pipe(filter(isNotNil), take(1)),
                        this._store.select(selectUserInfos).pipe(filter(isNotNil), take(1)),
                    ]).pipe(map(([userRestaurant, user]) => ({ ...userRestaurant, user })))
                )
            )
            .subscribe((userRestaurant) => {
                this._updateAbility(userRestaurant);
            });
    }

    public addRestaurant(restaurant: Restaurant): void {
        this.restaurants.update((restaurants) => [...restaurants, restaurant]);
    }

    public upsertRestaurant(restaurant: Restaurant): void {
        const index = this.restaurants().findIndex((r) => r._id === restaurant._id);
        if (index !== -1) {
            this.restaurants.update((restaurants) => {
                restaurants[index] = restaurant;
                return [...restaurants];
            });
        } else {
            this.addRestaurant(restaurant);
        }
    }

    public setSelectedRestaurant(restaurant: Restaurant | null): void {
        this.restaurantSelected$.next(restaurant);
    }

    public reloadSelectedRestaurant(): Subscription {
        return this.show((this.restaurantSelected$.value as Restaurant)?._id).subscribe((res) => {
            this.setSelectedRestaurant(res.data);
        });
    }

    public reloadSelectedRestaurant$(): Observable<void> {
        return this.show((this.restaurantSelected$.value as Restaurant)?._id).pipe(map((res) => this.setSelectedRestaurant(res.data)));
    }

    index(fields: string = ''): Observable<Restaurant[]> {
        return this._http.get(this.API_BASE_URL, { params: { fields } }).pipe(
            // ApiResult cannot be typed right now because we use params fields to get only the fields we need
            map((res: ApiResult<any[]>) =>
                res.data.map(
                    (r) =>
                        new Restaurant({
                            ...r,
                            address: new Address(r.address),
                            logo: new Media(r.logo),
                            category: new Category(r.category as any),
                            cover: new Media(r.cover),
                            categoryList: r.categoryList?.map((c) => new Category(c as any)),
                            createdAt: new Date(r.createdAt),
                            updatedAt: new Date(r.updatedAt),
                        })
                )
            ),
            tap((restaurants) => {
                this.restaurants.set([...restaurants]);
            })
        );
    }

    getUserRestaurantsIds(): Observable<string[]> {
        return this._http
            .get<ApiResultV2<{ _id: string }[]>>(`${this.API_BASE_URL}`, { params: { fields: '_id' } })
            .pipe(map((res) => res.data.map((r) => r._id)));
    }

    getUserRestaurantsForSidenav(): Observable<SidenavState['ownRestaurants']> {
        return this._http
            .get<
                ApiResultV2<Pick<IRestaurant, '_id' | 'logo' | 'name' | 'internalName' | 'address' | 'type'>[]>
            >(`${this.API_BASE_URL}`, { params: { fields: '_id logo name internalName address type' } })
            .pipe(map((res) => res.data));
    }

    all(filters: any = {}, fields: string[] = []): Observable<ApiResult<Restaurant[]>> {
        return this._http.get(this.API_BASE_URL + '/all', { params: { ...filters, fields } }).pipe(
            map((res: ApiResult) => {
                res.data = res.data.map((r: IRestaurantAPI) => RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(r));
                return res;
            })
        );
    }

    show(id: string): Observable<ApiResult<Restaurant>> {
        return this._http.get(`${this.API_BASE_URL}/${id}`).pipe(
            map((res: ApiResult) => {
                res.data = RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(res.data);
                return res;
            })
        );
    }

    create(params: any): Observable<ApiResult<CreateRestaurantResponseDto>> {
        return this._http.post<ApiResult<CreateRestaurantResponseDto>>(this.API_BASE_URL, params, { withCredentials: true });
    }

    update(restaurantId: string, params: any, duplicatedFromRestaurantId: string | null = null): Observable<ApiResult<Restaurant>> {
        const payload = RestaurantsMapper.mapToMalouRestaurantPayload(params);
        return this._http
            .put<ApiResult>(`${this.API_BASE_URL}/${restaurantId}`, { ...payload, duplicatedFromRestaurantId }, { withCredentials: true })
            .pipe(
                map((res: ApiResult) => {
                    res.data = RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(res.data);
                    return res;
                })
            );
    }

    fetchPlatformAndUpsertRestaurant(restaurantId: string, upsertRestaurantPayload: any): Observable<Restaurant> {
        return this._http
            .put<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/upsert_from_platform`, upsertRestaurantPayload)
            .pipe(map((res) => RestaurantsMapper.mapToRestaurantFromRestaurantApiResponse(res.data)));
    }

    getRestaurantsForUserWithEmail(userId: string): Observable<ApiResultV2<GetUserWithEmailRestaurantsResponseDto[]>> {
        return this._http.get<ApiResultV2<GetUserWithEmailRestaurantsResponseDto[]>>(`${this.API_BASE_URL}/many/users/${userId}`, {
            withCredentials: true,
        });
    }

    addRestaurantForUser(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/user/add`);
    }

    addRestaurantForUserById(
        restaurantId: string,
        userId: string,
        caslRole: CaslRole = CaslRole.OWNER
    ): Observable<ApiResult<AddUserToRestaurantWithEmailResponseDto>> {
        return this._http.post<ApiResult<AddUserToRestaurantWithEmailResponseDto>>(
            `${this.API_BASE_URL}/${restaurantId}/users/${userId}/add`,
            { caslRole },
            {
                withCredentials: true,
            }
        );
    }

    addRestaurantsForUserById(
        restaurantIds: string[],
        userId: string,
        caslRole: CaslRole = CaslRole.OWNER
    ): Observable<ApiResultV2<AddUserToRestaurantWithEmailResponseDto[]>> {
        return this._http.post<ApiResultV2<AddUserToRestaurantWithEmailResponseDto[]>>(
            `${this.API_BASE_URL}/many/users/${userId}/add`,
            { restaurantIds, caslRole },
            {
                withCredentials: true,
            }
        );
    }

    updateRestaurantsForUserById(
        restaurantIds: string[],
        userId: string,
        caslRole: CaslRole = CaslRole.OWNER
    ): Observable<ApiResultV2<UpdateRestaurantsForUserDto>> {
        return this._http.post<ApiResultV2<UpdateRestaurantsForUserDto>>(
            `${this.API_BASE_URL}/many/users/${userId}/update`,
            { restaurantIds, caslRole },
            {
                withCredentials: true,
            }
        );
    }

    updateUserRestaurant(restaurantId: string, usersIds: string[]): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/users/update`, { usersIds }, { withCredentials: true });
    }

    removeRestaurantForUser(restaurantId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/user/remove`);
    }

    showAccess(restaurantId: string, platformKey): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/platforms/${platformKey}/access`);
    }

    createPlatformAccess(restaurantId: string, platformAccess: PlatformAccess): Observable<ApiResult<Restaurant>> {
        return this._http.post<ApiResult<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/access`, { data: platformAccess }).pipe(
            map((res: ApiResult<Restaurant>) => {
                res.data = new Restaurant(res.data);
                return res;
            })
        );
    }

    updatePlatformAccessStatus(restaurantId: string, payload: UpdatePlatformAccessStatusBodyDto): Observable<void> {
        return this._http.put<void>(`${this.API_BASE_URL}/${restaurantId}/access`, payload);
    }

    delete(id: string): Observable<any> {
        return this._http.delete(`${this.API_BASE_URL}/${id}`);
    }

    synchronize(id: string): Observable<any> {
        return this._http.post(`${this.API_BASE_URL}/${id}/synchronize`, {}).pipe(
            map((res: any) => {
                res.data = new Restaurant(res.data);
                return res;
            })
        );
    }

    bookmarkedPostJob(restaurantId: string, post: PostWithInsights): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/${restaurantId}/jobs/bookmarked_post`, { post });
    }

    getRestaurantCurrentState(restaurantId: string): Observable<ApiResult<GetRestaurantCurrentStateResponseDto>> {
        return this._http.get<ApiResult<GetRestaurantCurrentStateResponseDto>>(`${this.API_BASE_URL}/${restaurantId}/currentState`);
    }

    updateActive(restaurantId: string, active: boolean): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/admin/active`, { active })
            .pipe(map((res) => new Restaurant(res.data)));
    }

    adminUpdate(restaurantId: string, data: AdminUpdateRestaurantBodyDto): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/admin`, data)
            .pipe(map((res) => new Restaurant(res.data)));
    }

    activateYextForRestaurant(restaurantId: string): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/yext/activate`, {})
            .pipe(map((res) => new Restaurant(res.data)));
    }

    deactivateYextForRestaurant(restaurantId: string): Observable<Restaurant> {
        return this._http
            .put<ApiResultV2<Restaurant>>(`${this.API_BASE_URL}/${restaurantId}/yext/deactivate`, {})
            .pipe(map((res) => new Restaurant(res.data)));
    }

    updateRestaurantOrganization(
        params: UpdateRestaurantOrganizationParamsDto,
        body: UpdateRestaurantOrganizationRequestBodyDto
    ): Observable<ApiResultV2<undefined>> {
        return this._http.put<ApiResultV2<undefined>>(`${this.API_BASE_URL}/${params.restaurantId}/organization`, body);
    }

    getRestaurantsByIds(restaurantIds: string[]): Observable<Restaurant[]> {
        const params = formatArrayKeysForQueryParams({ restaurantIds });
        return this._http
            .get<ApiResultV2<Restaurant[]>>(`${this.API_BASE_URL}/ids`, { params })
            .pipe(map((res) => res.data.map((r) => new Restaurant(r))));
    }

    getRestaurantsWithoutSticker(): Observable<Restaurant[]> {
        return this._http
            .get<ApiResultV2<RestaurantWithoutStickerDto[]>>(`${this.API_BASE_URL}/without-sticker`)
            .pipe(map((res) => res.data.map((r) => new Restaurant({ ...r, address: new Address(r.address) }))));
    }

    getRestaurantCalendarEvents(
        restaurantId: string,
        { afterDate, beforeDate }: { afterDate: Date; beforeDate: Date }
    ): Observable<CalendarEvent[]> {
        const params = objectToQueryParams({ afterDate, beforeDate });
        return this._http
            .get<ApiResultV2<CalendarEventDto[]>>(`${this.API_BASE_URL}/${restaurantId}/calendar-events`, { params })
            .pipe(map((res) => res.data.map((event) => CalendarEvent.fromDto(event))));
    }

    duplicateSpecialHours(
        restaurantIds: string[],
        specialHoursToDuplicate: SpecialTimePeriod[],
        duplicateFromRestaurantId: string
    ): Observable<{ success: boolean }> {
        return this._http
            .post<ApiResultV2<{ success: boolean }>>(`${this.API_BASE_URL}/${duplicateFromRestaurantId}/special-hours/duplicate`, {
                restaurantIds,
                specialHoursToDuplicate,
            })
            .pipe(map((res) => res.data));
    }

    private _updateAbility(userRestaurant: {
        user: { role: Role };
        caslRole: CaslRole;
        restaurantId: string;
        restaurant: { organizationId?: string };
    }): void {
        const newRules = this._getRulesForUserRestaurant(userRestaurant);
        this._abilitiesContext.updateUserRestaurantAbilities(newRules);
    }

    private _getRulesForUserRestaurant = (userRestaurant: {
        user: { role: Role };
        caslRole: CaslRole;
        restaurantId: string;
        restaurant: { organizationId?: string };
    }): AppCaslRule[] => {
        const { can, rules } = new AbilityBuilder(AppAbility);

        const caslRole = getAbilityRole({
            userAppRole: userRestaurant.user.role,
            userRestaurantRole: userRestaurant.caslRole,
        });
        if (typeof userRestaurantAbilities[caslRole] === 'function') {
            userRestaurantAbilities[caslRole](userRestaurant, { can });
        }
        return rules;
    };
}
