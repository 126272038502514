import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual, uniqBy } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { UpdateRestaurantsForUserDto } from '@malou-io/package-dto';
import {
    ApplicationLanguage,
    CaslRole,
    isNotNil,
    mapLanguageStringToApplicationLanguage,
    mapRoleStringToCaslEnum,
    Role,
} from '@malou-io/package-utils';

import { ReportsService } from ':core/services/report.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { selectUserRestaurants } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { SelectLanguagesComponent } from ':shared/components/select-languages/select-languages.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { MapPipe } from ':shared/pipes/map.pipe';

interface CaslRoleDescription {
    key: CaslRole;
    text: string;
    subtext: string;
}

type CandidateForm = FormGroup<{
    usersFormArray: CandidateFormArray;
}>;

type CandidateFormArray = FormArray<
    FormGroup<{
        user: FormControl<Candidate | null>;
        caslRole: FormControl<CaslRole | null>;
        language: FormControl<ApplicationLanguage | null>;
    }>
>;

interface Candidate {
    _id?: string;
    email?: string;
    language: ApplicationLanguage | null;
    getDisplayedValue?: () => string;
}

interface EditUserModalData {
    userToUpdate?: User;
    userRole?: string;
    isEdit: boolean;
}

@Component({
    selector: 'app-new-user-modal',
    templateUrl: './new-user-modal.component.html',
    styleUrls: ['./new-user-modal.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        SelectComponent,
        SelectLanguagesComponent,
        SelectRestaurantsComponent,
        AsyncPipe,
        MapPipe,
        SkeletonComponent,
    ],
})
export class NewUserModalComponent implements OnInit {
    public readonly data = inject<EditUserModalData>(MAT_DIALOG_DATA);
    private readonly _dialogRef = inject(MatDialogRef<NewUserModalComponent>);
    private readonly _fb = inject(FormBuilder);
    private readonly _usersService = inject(UsersService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translate = inject(TranslateService);
    private readonly _store = inject(Store);
    public readonly screenSizeService = inject(ScreenSizeService);
    private readonly _toastService = inject(ToastService);
    private readonly _reportsService = inject(ReportsService);

    readonly SvgIcon = SvgIcon;
    readonly CASL_ROLES: CaslRoleDescription[] = [
        {
            key: CaslRole.OWNER,
            text: this._translate.instant('roles.roles.owner'),
            subtext: this._translate.instant('roles.roles.owner_subtext'),
        },
        {
            key: CaslRole.EDITOR,
            text: this._translate.instant('roles.roles.editor'),
            subtext: this._translate.instant('roles.roles.editor_subtext'),
        },
        {
            key: CaslRole.MODERATOR,
            text: this._translate.instant('roles.roles.moderator'),
            subtext: this._translate.instant('roles.roles.moderator_subtext'),
        },
        {
            key: CaslRole.GUEST,
            text: this._translate.instant('roles.roles.guest'),
            subtext: this._translate.instant('roles.roles.guest_subtext'),
        },
    ];
    readonly APP_LANGUAGES = Object.values(ApplicationLanguage);
    readonly currentLang: WritableSignal<ApplicationLanguage> = signal(mapLanguageStringToApplicationLanguage(LocalStorage.getLang()));

    readonly ownedRestaurants$ = combineLatest([this._store.select(selectOwnRestaurants), this._store.select(selectUserRestaurants)]).pipe(
        map(([ownRestaurants, userRestaurants]: [Restaurant[], any[]]) =>
            ownRestaurants.filter((r) => userRestaurants.find((ur) => ur.restaurantId === r._id)?.caslRole === 'owner')
        ),
        tap((restaurants) => {
            this.currentRestaurant.set(restaurants.find((r) => r._id === this._restaurantsService.currentRestaurant._id));
        })
    );

    readonly candidates$ = combineLatest([
        this._usersService.getUsersForRestaurant(this._restaurantsService.currentRestaurant._id).pipe(map((res) => res.data)),
        this._restaurantsService.currentRestaurant.organization?._id
            ? this._usersService.getOrganizationUsers(this._restaurantsService.currentRestaurant.organization._id)
            : of([]),
    ]).pipe(
        filter(([restaurantUsers, organizationUsers]) => !!restaurantUsers && !!organizationUsers),
        map(([restaurantUsers, organizationUsers]) => organizationUsers.filter((u) => !restaurantUsers.find((ur) => ur.userId === u._id))),
        map((users) =>
            users.map((u) => ({ _id: u._id, email: u.email, language: u.defaultLanguage, getDisplayedValue: (): string => `${u.email}` }))
        )
    );

    readonly allCandidates: WritableSignal<Candidate[]> = signal([]);
    currentSelectedCandidates: Signal<(Candidate | null | undefined)[] | undefined>;

    readonly areExistingUsers = computed(() => this.currentSelectedCandidates()?.map((u) => !!u?._id) ?? []);
    readonly availableCandidates: Signal<Candidate[]> = computed(() => {
        const candidates = this.allCandidates().filter((c) => !this.usersFormArray.getRawValue().find((u) => u.user?.email === c.email));
        const currentSelectedCandidates = this.currentSelectedCandidates();
        if (!currentSelectedCandidates?.length) {
            return candidates;
        }
        return candidates.filter((c) => !currentSelectedCandidates.find((u) => u?.email === c.email));
    });

    readonly defaultSelectedRestaurants: WritableSignal<Restaurant[]> = signal([]);
    readonly restaurantSelection: WritableSignal<Restaurant[]> = signal([]);
    readonly displayRestaurantSelection: WritableSignal<boolean> = signal(false);
    readonly currentRestaurant: WritableSignal<Restaurant | undefined> = signal(undefined);
    readonly displayCloseModal: WritableSignal<boolean> = signal(false);
    readonly isCreatingUser = signal<boolean>(false);

    readonly isFetchingUserToUpdate: WritableSignal<boolean> = signal(false);
    readonly userToUpdate: WritableSignal<User | undefined> = signal(undefined);

    readonly usersForm: CandidateForm = new FormGroup({
        usersFormArray: new FormArray(
            [
                new FormGroup({
                    user: new FormControl<Candidate | null>(null, Validators.required),
                    caslRole: new FormControl<CaslRole | null>(null, Validators.required),
                    language: new FormControl<ApplicationLanguage | null>(null),
                }),
            ],
            Validators.required
        ),
    });

    constructor() {
        this.currentSelectedCandidates = toSignal(
            this.usersFormArray.valueChanges.pipe(
                map((value) => value.map((v) => v.user)),
                tap((v) => {
                    const index = v.length - 1;
                    if (v[index]?.language) {
                        this.changeLang(v[index]?.language ?? this.currentLang(), index);
                    }
                }),
                distinctUntilChanged(isEqual)
            )
        );
    }

    get usersFormArray(): CandidateFormArray {
        return this.usersForm.controls.usersFormArray;
    }

    ngOnInit(): void {
        this._initUserForm();
        const selectedRestaurants = this.restaurantSelection();
        selectedRestaurants.push(this._restaurantsService.currentRestaurant);
        this.restaurantSelection.set(selectedRestaurants);
        this.candidates$.subscribe((candidates) => {
            this.allCandidates.set(candidates);
        });
    }

    cancel(updated = false): void {
        this._dialogRef.close(updated);
    }

    buildUser = (email: string): Observable<{ email: string; getDisplayedValue: () => string }> =>
        this._validateEmail$(email).pipe(
            switchMap(() => this._usersService.getUserByEmail$(email)),
            catchError((err) => {
                if (err.status === 404) {
                    return of(null);
                }
                return throwError(() => err);
            }),
            switchMap((user) => {
                if (user) {
                    user.organizationIds = [
                        ...user.organizationIds?.filter((oid) => oid !== this._restaurantsService.currentRestaurant.organizationId),
                        ...(this._restaurantsService.currentRestaurant.organizationId
                            ? [this._restaurantsService.currentRestaurant.organizationId]
                            : []),
                    ];
                    return this._usersService
                        .updateUserOrganizations(user._id, {
                            organizationIds: user.organizationIds,
                        })
                        .pipe(map(() => ({ ...user, getDisplayedValue: () => `${user.email}` })));
                }
                return of({ email, getDisplayedValue: () => email });
            })
        );

    save(): void {
        this.isCreatingUser.set(true);
        this.usersForm.disable();
        if (this.data.isEdit) {
            this._updateUser();
        } else {
            this._createUser();
        }
    }

    toggleDisplayRestaurantsSelection(): void {
        this.displayRestaurantSelection.set(!this.displayRestaurantSelection());
    }

    restaurantsSelectionChanged(restaurant: Restaurant[]): void {
        const currentRestaurant = this._restaurantsService.currentRestaurant;
        this.restaurantSelection.set(uniqBy([...restaurant, currentRestaurant], '_id'));
    }

    displayUser(user: Partial<User> | string): string {
        return typeof user === 'string' ? user : (user.email ?? '');
    }

    displayCaslRole =
        (key: keyof CaslRoleDescription) =>
        (role: CaslRole): string =>
            this.CASL_ROLES.find((r) => r.key === role)?.[key] || '';

    addUserForm(): void {
        const user = this._fb.group({
            user: new FormControl<Candidate | null>(null, [Validators.required]),
            caslRole: new FormControl<CaslRole | null>(null, [Validators.required]),
            language: new FormControl<ApplicationLanguage | null>(null),
        });
        this.usersFormArray.push(user);
    }

    removeUserForm(index: number): void {
        this.usersFormArray.removeAt(index);
    }

    changeLang(event: ApplicationLanguage | ApplicationLanguage[], index: number): void {
        const lang = Array.isArray(event) ? event[0] : event;
        this.usersFormArray.at(index).get('language')?.setValue(lang, { emitEvent: false });
    }

    close(): void {
        if (this.usersForm.touched) {
            this.displayCloseModal.set(true);
        } else {
            this.confirmClose();
        }
    }

    confirmClose(): void {
        this._dialogRef.close();
    }

    private _initUserForm(): void {
        if (this.data.isEdit) {
            this.isFetchingUserToUpdate.set(true);
            combineLatest([
                this._restaurantsService.getRestaurantsForUserWithEmail(this.data.userToUpdate?._id!),
                this.ownedRestaurants$,
            ]).subscribe({
                next: ([userWithEmailRestaurants, ownedRestaurants]) => {
                    const formToEdit = this.usersFormArray.at(0);

                    formToEdit?.get('user')?.setValue({
                        _id: this.data.userToUpdate?._id,
                        email: this.data.userToUpdate?.email,
                        language: this.data.userToUpdate?.defaultLanguage!,
                    });
                    formToEdit?.get('user')?.disable();
                    const userApplicationLanguage = mapLanguageStringToApplicationLanguage(this.data.userToUpdate?.defaultLanguage!);
                    formToEdit.get('language')?.setValue(userApplicationLanguage);
                    this.currentLang.set(userApplicationLanguage);
                    formToEdit.get('language')?.disable();
                    formToEdit.get('caslRole')?.setValue(mapRoleStringToCaslEnum(this.data.userRole!));

                    const selectedRestaurants = ownedRestaurants.filter((r) =>
                        userWithEmailRestaurants.data.find((ur) => ur.restaurantId === r._id)
                    );
                    this.defaultSelectedRestaurants.set(selectedRestaurants);
                    this.restaurantSelection.set(selectedRestaurants);
                    if (selectedRestaurants.length > 1) {
                        this.displayRestaurantSelection.set(true);
                    }
                    this.isFetchingUserToUpdate.set(false);
                },
                error: (err) => {
                    console.warn(err);
                    this.isFetchingUserToUpdate.set(true);
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translate).transform(err));
                },
            });
        }
    }

    private _updateUser() {
        const restaurantIds = this.restaurantSelection().map((r) => r._id);
        if (!this.data.userToUpdate) {
            this._toastService.openErrorToast(this._translate.instant('roles.existing_user.no_user_found'));
            return;
        }
        this._restaurantsService
            .updateRestaurantsForUserById(
                restaurantIds,
                this.data.userToUpdate?._id,
                this.usersFormArray.at(0).get('caslRole')?.value ?? this.data.userToUpdate.caslRole
            )
            .subscribe({
                next: (result) => {
                    this._getErrorFromUpdateUser(result.data);
                    this._dialogRef.close();
                    this.isCreatingUser.set(false);
                    this.usersForm.enable();
                },
                error: (err) => {
                    console.warn(err);
                    this.isCreatingUser.set(false);
                    this.usersForm.enable();
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translate).transform(err));
                },
            });
    }

    private _createUser() {
        const formArrayValue = this.usersFormArray.getRawValue();
        const usersToCreate = formArrayValue.filter((el) => !el.user?._id);
        const existingUsers = formArrayValue.filter((el) => !!el.user?._id);
        const newUsers$ = usersToCreate.length
            ? forkJoin(
                  usersToCreate.map((u) =>
                      this._createNewUser$(u.user!.email!, u.caslRole, u.language!).pipe(
                          map((user) => ({
                              caslRole: u.caslRole,
                              user,
                              language: u.language,
                          }))
                      )
                  )
              )
            : of([]);
        newUsers$
            .pipe(
                switchMap((newUsers) => {
                    const allUsers = existingUsers.concat(newUsers).filter(isNotNil);
                    const restaurantIds = this.restaurantSelection().map((r) => r._id);
                    return forkJoin(
                        allUsers.map((u) => this._restaurantsService.addRestaurantsForUserById(restaurantIds, u.user!._id!, u.caslRole!))
                    );
                }),
                map((responses) => responses.map((res) => res.data)),
                switchMap((userRestaurants) =>
                    this._reportsService.fillUserConfigurations(
                        userRestaurants[0][0].userId,
                        userRestaurants[0].map((ur) => ur.restaurantId)
                    )
                )
            )
            .subscribe({
                next: () => {
                    this._dialogRef.close();
                    this.isCreatingUser.set(false);
                    this.usersForm.enable();
                },
                error: (err) => {
                    console.warn(err);
                    this.isCreatingUser.set(false);
                    this.usersForm.enable();
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translate).transform(err));
                },
            });
    }

    private _validateEmail$(email: string): Observable<string> {
        const tempForm = new UntypedFormControl(email, Validators.email);
        if (tempForm.valid) {
            return of(email);
        }
        return throwError(() => {
            this._toastService.openErrorToast(this._translate.instant('new_user.error.invalid_email'));
            return new Error(this._translate.instant('new_user.error.invalid_email'));
        });
    }

    private _createNewUser$(email: string, caslRole: CaslRole | null, defaultLanguage: ApplicationLanguage): Observable<Candidate> {
        const password = this._generateRandomPassword();
        return this._usersService
            .createUser({
                email,
                defaultLanguage: defaultLanguage ?? this.currentLang,
                role: Role.MALOU_BASIC,
                caslRole: caslRole ?? CaslRole.GUEST,
                password,
                organizationIds: this._restaurantsService.currentRestaurant.organization
                    ? [this._restaurantsService.currentRestaurant.organization._id]
                    : [],
            })
            .pipe(
                map(({ data: user }) => ({
                    _id: user._id,
                    email: user.email,
                    language: user.defaultLanguage,
                    getDisplayedValue: () => `${user.email}`,
                }))
            );
    }

    private _generateRandomPassword(): string {
        let password = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 8; i++) {
            password += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return password;
    }

    private _getErrorFromUpdateUser(result: UpdateRestaurantsForUserDto): void {
        const keys = Object.keys(result);
        keys.forEach((key) => {
            if (!result[key].success) {
                this._toastService.openErrorToast(this._translate.instant(`roles.existing_user.cannot_${key}`));
            }
        });
    }
}
