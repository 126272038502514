import { z } from 'zod';

import { MalouComparisonPeriod, PlatformKey, PrivatePlatforms } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

/** Zero is a special value and matches reviews without rating. */
const ratingValidator = z.coerce
    .number()
    .int()
    .gte(0)
    .lte(5)
    .refine((n: number): n is 0 | 1 | 2 | 3 | 4 | 5 => true);

const ratingsValidator = z.array(ratingValidator).or(ratingValidator.transform((r) => [r]));

const sortValidators = {
    sort_order: z
        .enum(['-1', '1'])
        .transform((s: '-1' | '1'): -1 | 1 => (s === '-1' ? -1 : 1))
        .optional(),
    sort_by: z.string().optional(),
} as const;

/** Consider using paginationValidatorsV2 for new routes. */
const paginationValidators = {
    page_number: z.coerce.number().int().gte(0).optional(),
    page_size: z.coerce.number().int().gte(0).optional(),
    skip: z.coerce.number().int().gte(0).optional(),
    total: z.coerce.number().int().gte(0).optional(),
} as const;

const paginationValidatorsV2 = {
    page_number: z.coerce.number().int().gte(0).optional(),
    page_size: z.coerce.number().int().gte(0).optional(),
    skip: z.coerce.number().int().gte(0).optional(),
} as const;

const filtersValidators = {
    answerable: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    answered: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    archived: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    end_date: z.string().optional(),
    not_answered: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    pending: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    platforms: z
        .array(z.string())
        .or(z.string().transform((p) => [p]))
        .optional(),
    ratings: ratingsValidator.optional(),
    restaurant_ids: z.array(z.string()).or(z.string().transform((r) => (Array.isArray(r) ? r : [r]))),
    show_private: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    start_date: z.string().optional(),
    text: z.string().optional(),
    unarchived: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    with_text: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
    without_text: z
        .enum(['true', 'false'])
        .transform((s: 'true' | 'false') => s === 'true')
        .optional(),
} as const;

const filtersBodyValidator = {
    answerable: z.boolean().nullish(),
    answered: z.boolean().nullish(),
    archived: z.boolean().nullish(),
    endDate: z.string().nullish(),
    notAnswered: z.boolean().nullish(),
    pending: z.boolean().nullish(),
    platforms: z.array(z.nativeEnum(PlatformKey)),
    ratings: ratingsValidator.nullish(),
    restaurantIds: z.array(objectIdValidator),
    showPrivate: z.boolean().nullish(),
    startDate: z.string().nullish(),
    text: z.string().nullish(),
    unarchived: z.boolean().nullish(),
    withText: z.boolean().nullish(),
    withoutText: z.boolean().nullish(),
} as const;

export const getReviewWithoutPaginationQueryValidator = z.object({
    ...sortValidators,
    ...filtersValidators,
});

export const getReviewFiltersBodyValidator = z
    .object({
        ...filtersBodyValidator,
        privatePlatforms: z.array(z.nativeEnum(PrivatePlatforms)).optional(),
    })
    .transform((data) => ({ ...data, searchText: data.text, text: undefined }));

// ------------------------------------------------------------------------------------------
export const synchronizeReviewsQueryValidator = z
    .object({
        restaurant_ids: z.union([z.array(objectIdValidator), objectIdValidator]),
    })
    .transform((data) => ({
        restaurantIds: Array.isArray(data.restaurant_ids) ? data.restaurant_ids : [data.restaurant_ids],
    }));

export type SynchronizeReviewsQueryDto = z.infer<typeof synchronizeReviewsQueryValidator>;

// ------------------------------------------------------------------------------------------
// DEPRECATED
export const getRestaurantsReviewsQueryValidator = z
    .object({
        ...sortValidators,
        ...paginationValidators,
        ...filtersValidators,
        private_platforms: z
            .nativeEnum(PrivatePlatforms)
            .or(z.array(z.nativeEnum(PrivatePlatforms)))
            .optional(),
        time_zone: z.string().optional(),
    })
    .transform((data) => ({
        ...data,
        private_platforms: data.private_platforms
            ? Array.isArray(data.private_platforms)
                ? data.private_platforms
                : [data.private_platforms]
            : undefined,
        timeZone: data.time_zone,
    }));

export type GetRestaurantsReviewsOutputQueryDto = z.output<typeof getRestaurantsReviewsQueryValidator>;

// ------------------------------------------------------------------------------------------
export const getRestaurantsReviewsExternalApiQueryValidator = z
    .object({
        ...sortValidators,
        ...paginationValidators,
        ...filtersValidators,
        private_platforms: z
            .nativeEnum(PrivatePlatforms)
            .or(z.array(z.nativeEnum(PrivatePlatforms)))
            .optional(),
        time_zone: z.string().optional(),
    })
    .omit({ restaurant_ids: true })
    .transform((data) => ({
        ...data,
        private_platforms: data.private_platforms
            ? Array.isArray(data.private_platforms)
                ? data.private_platforms
                : [data.private_platforms]
            : undefined,
        timeZone: data.time_zone,
    }));

// ------------------------------------------------------------------------------------------
export const getRestaurantsReviewsV2QueryValidator = z
    .object({
        ...paginationValidatorsV2,
        ...filtersValidators,
        private_platforms: z
            .nativeEnum(PrivatePlatforms)
            .or(z.array(z.nativeEnum(PrivatePlatforms)))
            .optional(),
        time_zone: z.string().optional(),
    })
    .transform((data) => ({
        ...data,
        private_platforms: data.private_platforms
            ? Array.isArray(data.private_platforms)
                ? data.private_platforms
                : [data.private_platforms]
            : undefined,
        timeZone: data.time_zone,
    }));

export type GetRestaurantsReviewsV2QueryDto = z.output<typeof getRestaurantsReviewsV2QueryValidator>;

// ------------------------------------------------------------------------------------------
export const getReviewCountBodyValidator = getReviewFiltersBodyValidator;
export type GetReviewCountBodyDto = z.output<typeof getReviewCountBodyValidator>;
// ------------------------------------------------------------------------------------------
// DEPRECATED
export const getRestaurantsUnansweredCountQueryValidator = getReviewWithoutPaginationQueryValidator;
export type GetRestaurantsUnansweredCountQueryDto = z.infer<typeof getRestaurantsUnansweredCountQueryValidator>;
// ------------------------------------------------------------------------------------------
export const getRestaurantsUnansweredCountBodyValidator = getReviewFiltersBodyValidator;
export type GetRestaurantsUnansweredCountBodyDto = z.infer<typeof getRestaurantsUnansweredCountBodyValidator>;
// ------------------------------------------------------------------------------------------
// DEPRECATED
export const getReviewPageQueryValidator = getReviewWithoutPaginationQueryValidator;
export type GetReviewPageQueryDto = z.infer<typeof getReviewPageQueryValidator>;
// ------------------------------------------------------------------------------------------
export const getReviewPageBodyValidator = getReviewFiltersBodyValidator;
export type GetReviewPageBodyDto = z.infer<typeof getReviewPageBodyValidator>;

export const getReviewPageParamsValidator = z.object({ review_id: z.string() });
export type GetReviewPageParamsDto = z.infer<typeof getReviewPageParamsValidator>;
// ------------------------------------------------------------------------------------------
export const updateReviewArchivedValueBodyValidator = z.object({
    archived: z.boolean(),
    isPrivate: z.boolean(),
});
export type UpdateReviewArchivedValueBodyDto = z.infer<typeof updateReviewArchivedValueBodyValidator>;

export const updateReviewArchivedValueParamsValidator = z.object({ review_id: z.string() });
export type UpdateReviewArchivedValueParamsDto = z.infer<typeof getReviewPageParamsValidator>;
// ------------------------------------------------------------------------------------------

export const fetchAiRelevantBricksParamsValidator = z.object({ review_id: z.string() });
export type FetchAiRelevantBricksParamsDto = z.infer<typeof fetchAiRelevantBricksParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getReviewTotalCountParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        platformKey: data.platform_key,
    }));
export type GetReviewTotalCountParamsDto = z.infer<typeof getReviewTotalCountParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantsReviewsAverageRatingQueryValidator = z
    .object({
        start_date: z.string(),
        end_date: z.string(),
        comparison_period: z.nativeEnum(MalouComparisonPeriod).optional(),
        platforms: z.union([z.nativeEnum(PlatformKey).transform((key) => [key]), z.array(z.nativeEnum(PlatformKey))]),
    })
    .transform((data) => ({
        startDate: new Date(data.start_date),
        endDate: new Date(data.end_date),
        platforms: data.platforms,
        comparisonPeriod: data.comparison_period,
    }));
export type GetRestaurantsReviewsAverageRatingQueryDto = z.infer<typeof getRestaurantsReviewsAverageRatingQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantsReviewsAnswerTimeQueryValidator = getRestaurantsReviewsAverageRatingQueryValidator;
export type GetRestaurantsReviewsAnswerTimeQueryDto = z.output<typeof getRestaurantsReviewsAnswerTimeQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantsReviewsAnswerRateQueryValidator = getRestaurantsReviewsAverageRatingQueryValidator;
export type GetRestaurantsReviewsAnswerRateQueryDto = z.output<typeof getRestaurantsReviewsAnswerRateQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantsReviewsEvolutionQueryValidator = getRestaurantsReviewsAverageRatingQueryValidator;
export type GetRestaurantsReviewsEvolutionRateQueryDto = z.output<typeof getRestaurantsReviewsAnswerRateQueryValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantsReviewsRatingQueryValidator = getRestaurantsReviewsAverageRatingQueryValidator;
export type GetRestaurantsReviewsRatingQueryDto = z.output<typeof getRestaurantsReviewsRatingQueryValidator>;

// ------------------------------------------------------------------------------------------

export const createPrivateReviewBodyValidator = z
    .object({
        privateReview: z.object({
            restaurantId: objectIdValidator,
            text: z.string().optional(),
            lang: z.string().nullable(),
            campaignId: objectIdValidator.optional(),
            socialCreatedAt: z.string().datetime().nullish(),
            rating: z.number().nullish(),
            clientId: objectIdValidator.nullish(),
            archived: z.boolean(),
            scanId: objectIdValidator.optional(),
        }),
    })
    .transform((data) => ({
        privateReview: {
            ...data.privateReview,
            socialCreatedAt: data.privateReview.socialCreatedAt ? new Date(data.privateReview.socialCreatedAt) : undefined,
            rating: data.privateReview.rating ?? undefined,
        },
    }));

export type CreatePrivateReviewDto = z.infer<typeof createPrivateReviewBodyValidator>;
// ------------------------------------------------------------------------------------------
export const updateKeywordsLangParamsValidator = z.object({
    review_id: objectIdValidator,
});
export type UpdateKeywordsLangParamsDto = z.infer<typeof updateKeywordsLangParamsValidator>;
// ------------------------------------------------------------------------------------------

export const updateKeywordsLangBodyValidator = z.object({
    keywordsLang: z.string(),
});
export type UpdateKeywordsLangBodyDto = z.infer<typeof updateKeywordsLangBodyValidator>;

// ------------------------------------------------------------------------------------------

export const handleAnalyzeSingleReviewParamsValidator = z
    .object({
        review_id: objectIdValidator,
    })
    .transform((data) => ({
        reviewId: data.review_id,
    }));
export type HandleAnalyzeSingleReviewParamsDto = z.infer<typeof handleAnalyzeSingleReviewParamsValidator>;

// ------------------------------------------------------------------------------------------

export const deleteReviewParamsValidator = z
    .object({
        review_id: objectIdValidator,
    })
    .transform((data) => ({
        reviewId: data.review_id,
    }));

export type DeleteReviewParamsDto = z.infer<typeof deleteReviewParamsValidator>;

// ------------------------------------------------------------------------------------------

export const DeleteReviewsForPlatformParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        platformKey: data.platform_key,
    }));

export type DeleteReviewsForPlatformParamsDto = z.infer<typeof DeleteReviewsForPlatformParamsValidator>;

export const getReviewByIdParamsValidator = z.object({
    review_id: objectIdValidator,
});

export type GetReviewByIdParamsDto = z.infer<typeof getReviewByIdParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getReviewsBySocialIdsBodyValidator = z.object({
    socialIds: z.array(z.string()),
    restaurantIds: z.array(objectIdValidator),
});

export type GetReviewsBySocialIdsBodyDto = z.infer<typeof getReviewsBySocialIdsBodyValidator>;

// ------------------------------------------------------------------------------------------

export const getReviewsBySegmentTopicBodyValidator = z
    .object({
        topic: z.string(),
        isMainCategory: z.boolean(),
        startDate: z.string().datetime().nullish(),
        endDate: z.string().datetime().nullish(),
        platformKeys: z.array(z.nativeEnum(PlatformKey)).optional(),
        restaurantIds: z.array(objectIdValidator),
        shouldShowSubcategories: z.boolean(),
    })
    .transform((data) => ({
        topic: data.topic,
        isMainCategory: data.isMainCategory,
        startDate: data.startDate ? new Date(data.startDate) : undefined,
        endDate: data.endDate ? new Date(data.endDate) : undefined,
        platformKeys: data.platformKeys,
        restaurantIds: data.restaurantIds,
        shouldShowSubcategories: data.shouldShowSubcategories,
    }));

export type GetReviewsBySegmentTopicBodyDto = z.infer<typeof getReviewsBySegmentTopicBodyValidator>;

// ------------------------------------------------------------------------------------------

export const getReviewsWithSegmentAnalysesParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type GetReviewsWithSegmentAnalysesParamsDto = z.infer<typeof getReviewsWithSegmentAnalysesParamsValidator>;

export const getReviewsWithSegmentAnalysesQueryValidator = z
    .object({
        start_date: z.string().datetime(),
        end_date: z.string().datetime(),
        platform_keys: z.array(z.nativeEnum(PlatformKey)),
    })
    .transform((data) => ({
        startDate: new Date(data.start_date),
        endDate: new Date(data.end_date),
        platformKeys: data.platform_keys,
    }));

export type GetReviewsWithSegmentAnalysesQueryDto = z.infer<typeof getReviewsWithSegmentAnalysesQueryValidator>;
