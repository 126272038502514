import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    input,
    OnDestroy,
    output,
    signal,
    viewChild,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';

import { MediaType, PostType, waitFor } from '@malou-io/package-utils';

import { computeTransformDataStyles } from ':shared/helpers/compute-transfrom-data-styles';
import { PostStatus } from ':shared/models';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-social-post-media',
    templateUrl: './social-post-media.component.html',
    styleUrls: ['./social-post-media.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        MatRippleModule,
        TranslateModule,
        ImagePathResolverPipe,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostMediaComponent implements OnDestroy {
    readonly postType = input<PostType>(PostType.IMAGE);
    readonly firstAttachmentType = input<MediaType>();
    readonly customMediaStyle = input<object>({});
    readonly customMediaClass = input<string>('');
    readonly customIconStyle = input<object>({});
    readonly customIconClass = input<string>('');
    readonly tooltipText = input<string>('');
    readonly workingMedia = input.required<string>();
    readonly workingMediaDimensions = input<{ width: number; height: number }>();
    readonly showVideoControls = input<boolean>(true);
    readonly postStatus = input<PostStatus>();
    readonly socialLink = input<string>();
    readonly backupMediaUrl = input<string | undefined>();
    readonly shouldLazyLoadMedia = input<boolean>(true);
    // undefined means no thumbnailUrl, null mean thumbnailUrl is not computed yet
    readonly thumbnailUrl = input<string | null | undefined>(null);
    readonly thumbnailDimensions = input<{ width: number; height: number }>();
    readonly videoDimensions = input<{ width: number; height: number }>();
    readonly transformData = input<{
        rotationInDegrees: number;
        left: number;
        top: number;
        width: number;
        height: number;
    }>();

    readonly refreshMedia = output<ErrorEvent>();
    readonly loadedVideoMetadata = output<Event>();

    readonly PostType = PostType;

    private readonly _containerElement = viewChild.required<ElementRef<HTMLDivElement>>('containerElement');
    private readonly _imgElement = viewChild<ElementRef<HTMLImageElement>>('imgElement');
    private readonly _videoElement = viewChild<ElementRef<HTMLVideoElement>>('videoElement');

    readonly shouldLoadVideo = signal(false);

    readonly iconKey = computed(() => {
        switch (this.postType()) {
            case PostType.IMAGE:
                return 'image';
            case PostType.VIDEO:
            case PostType.REEL:
                return 'video';
            case PostType.CAROUSEL:
                return 'images';
            default:
                return 'image';
        }
    });

    private readonly _containerSizeChanged = signal(0);
    private readonly _resizeObserver = new ResizeObserver(() => {
        this._containerSizeChanged.update((e) => ++e);
    });

    constructor() {
        effect(() => {
            this._containerSizeChanged(); // just a  trigger
            const transformData = this.transformData();
            if (!transformData) {
                this._resetMediaElementStyles();
                return;
            }
            const containerElement = this._containerElement();
            if (this.firstAttachmentType() === MediaType.PHOTO) {
                const imgElement = this._imgElement();
                const workingMediaDimensions = this.workingMediaDimensions();
                if (imgElement && workingMediaDimensions) {
                    computeTransformDataStyles(
                        workingMediaDimensions,
                        transformData,
                        containerElement.nativeElement,
                        imgElement.nativeElement
                    );
                    return;
                }
            }

            if (this.firstAttachmentType() === MediaType.VIDEO) {
                const imgElement = this._imgElement();
                const thumbnailDimensions = this.thumbnailDimensions();
                if (imgElement && thumbnailDimensions) {
                    computeTransformDataStyles(
                        thumbnailDimensions,
                        transformData,
                        containerElement.nativeElement,
                        imgElement.nativeElement
                    );
                    return;
                }
                const videoElement = this._videoElement();
                const videoDimensions = this.videoDimensions();
                if (videoElement && videoDimensions) {
                    computeTransformDataStyles(videoDimensions, transformData, containerElement.nativeElement, videoElement.nativeElement);
                    return;
                }
            }

            this._resetMediaElementStyles();
        });
        effect(() => {
            this._resizeObserver.disconnect();
            const containerElement = this._containerElement()?.nativeElement;
            if (containerElement) {
                this._resizeObserver.observe(containerElement);
            }
        });
    }

    ngOnDestroy(): void {
        this._resizeObserver.disconnect();
    }

    private _resetMediaElementStyles(): void {
        const imgElement = this._imgElement()?.nativeElement;
        if (imgElement) {
            imgElement.style.width = '100%';
            imgElement.style.height = '100%';
            imgElement.style.transform = '';
        }
        const videoElement = this._videoElement()?.nativeElement;
        if (videoElement) {
            videoElement.style.width = '100%';
            videoElement.style.height = '100%';
            videoElement.style.transform = '';
        }
    }

    onLoadedVideoMetadata(event: Event): void {
        this.loadedVideoMetadata.emit(event);
    }

    async playMedia(event: MouseEvent): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        const playIcon = event.target as HTMLElement;

        this.shouldLoadVideo.set(true);
        await waitFor(0); // Wait for Angular to refresh component and add the video element

        // get closest video tag
        const video = playIcon?.parentNode?.parentNode?.children[0] as HTMLVideoElement;

        // is video playing?
        if (!video.paused) {
            playIcon.innerHTML = 'play_arrow';
            video.pause();
            return;
        }

        video.play();
        playIcon.innerHTML = 'pause';
    }

    refresh(event: ErrorEvent): void {
        this.refreshMedia.emit(event);
    }

    refreshIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this.refresh(new ErrorEvent('Could not load some media'));
        }
    }

    openSocialLink(): void {
        if (this.socialLink()) {
            window.open(this.socialLink(), '_blank');
        }
    }
}
