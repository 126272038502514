import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { TikTokConnectionModalResult } from ':modules/platforms/platforms-connection-modals/platforms-connection-tiktok-modal/tiktok-connection-modal.service';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { GetOauthUrlService } from ':modules/platforms/platforms-connection-modals/shared/services/get-oauth-url.service';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-tiktok-connection-step-0',
    templateUrl: './tiktok-connection-step-0.component.html',
    styleUrls: ['./tiktok-connection-step-0.component.scss'],
    standalone: true,
    imports: [ModalStructureComponent, TranslateModule, ImagePathResolverPipe, ReactiveFormsModule, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TikTokConnectionStep0Component extends BaseStepComponent<undefined, TikTokConnectionModalResult> {
    private readonly _getOauthUrlService = inject(GetOauthUrlService);

    readonly ButtonStyle = ButtonStyle;

    readonly tikTokOauthUrl = signal<string>('');

    constructor() {
        super();
        this._getOauthUrlService.execute(PlatformKey.TIKTOK).subscribe((url) => {
            this.tikTokOauthUrl.set(url);
            this.onConnectWithTikTok();
        });
    }

    onConnectWithTikTok(): void {
        const tikTokOauthUrl = this.tikTokOauthUrl();
        if (tikTokOauthUrl) {
            window.location.href = tikTokOauthUrl;
        }
    }

    onPrimaryClick(): void {
        // TODO tutoriel ?
    }
}
