import { AsyncPipe, CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart, PlatformFilterPage } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { StatisticsSeoRatingsComponent } from ':modules/statistics/e-reputation/ratings/ratings.component';
import { ReviewAnalysesComponent } from ':modules/statistics/e-reputation/review-analyses/review-analyses.component';
import { ReviewsKpisComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis.component';
import { ReviewsRatingsEvolutionComponent } from ':modules/statistics/e-reputation/reviews-ratings-evolution/reviews-ratings-evolution.component';
import { ReviewsRatingsTotalComponent } from ':modules/statistics/e-reputation/reviews-ratings-total/reviews-ratings-total.component';
import { SemanticAnalysisComponent } from ':modules/statistics/e-reputation/semantic-analysis/semantic-analysis.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { ChartOptions, StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-e-reputation-pdf',
    standalone: true,
    imports: [
        CommonModule,
        ReviewsKpisComponent,
        ReviewsRatingsEvolutionComponent,
        ReviewsRatingsTotalComponent,
        ReviewAnalysesComponent,
        TranslateModule,
        StatisticsSeoRatingsComponent,
        FromToDateFormatterPipe,
        IncludesPipe,
        AsyncPipe,
        SemanticAnalysisComponent,
        StatisticsPdfRestaurantsFormatterPipe,
    ],
    templateUrl: './e-reputation-pdf.component.html',
    styleUrls: ['./e-reputation-pdf.component.scss'],
})
export class EReputationPdfComponent {
    readonly InsightsChart = InsightsChart;
    readonly ViewBy = ViewBy;
    readonly StatisticsDataViewMode = StatisticsDataViewMode;

    chartOptions: ChartOptions = {};
    displayedCharts: InsightsChart[] = [];
    displayedPlatforms: string;
    startDate: Date;
    endDate: Date;

    seoRatingsHasData = true;
    reviewKpisHasData = true;
    reviewRatingsEvolutionHasData = true;
    reviewRatingsTotalHasData = true;
    reviewAnalysesHasData = true;

    readonly selectedRestaurantTitle$: Observable<string>;

    constructor(
        private _store: Store,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        public readonly translateService: TranslateService,
        private readonly _restaurantsService: RestaurantsService
    ) {
        const parsedQueryParams = parseInsightsRouteParams();
        const { dates, displayedCharts, chartOptions, platformKeys } = parsedQueryParams;

        this.chartOptions = chartOptions ?? {};
        this.displayedCharts = displayedCharts;

        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this._store.dispatch(StatisticsActions.editPlatforms({ page: PlatformFilterPage.E_REPUTATION, platforms: platformKeys ?? [] }));

        this.displayedPlatforms = this.translateService.instant('statistics_pdf.e_reputation_pdf.platforms', {
            platforms: (platformKeys ?? []).map((platformKey) => this._enumTranslatePipe.transform(platformKey, 'platform_key')).join(', '),
        });

        this.selectedRestaurantTitle$ = this._restaurantsService.restaurantSelected$.pipe(
            map((restaurant) => restaurant?.internalName ?? restaurant?.name ?? '')
        );
    }
}
