import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { HeapEventName, InsightsChart, InsightsTab, MonthYearPeriod, PlatformFilterPage } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { MINIMUM_RESTAURANTS_SELECTED_THRESHOLD } from ':modules/aggregated-statistics/aggregated-statistics.constant';
import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { GmbInsightsContext } from ':modules/aggregated-statistics/seo/context/gmb-insights.context';
import { GmbActionsV2Component } from ':modules/aggregated-statistics/seo/gmb-actions-v2/gmb-actions-v2.component';
import { GmbImpressionsV2Component } from ':modules/aggregated-statistics/seo/gmb-impressions-v2/gmb-impressions-v2.component';
import { KeywordsV4Component } from ':modules/aggregated-statistics/seo/keywords-v4/keywords-v4.component';
import { KeywordsV3Component } from ':modules/aggregated-statistics/seo/keywords/keywords-v3.component';
import { TopKeywordSearchImpressionsComponent } from ':modules/aggregated-statistics/seo/top-keyword-search-impressions/top-keyword-search-impressions.component';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import * as StatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DatePickerType } from ':shared/enums/date-pickers';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum SeoInsightsTabs {
    KEYWORDS,
    GMB,
}

@Component({
    selector: 'app-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FiltersComponent,
        GmbActionsV2Component,
        GmbImpressionsV2Component,
        KeywordsV3Component,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        MatTabsModule,
        KeywordsV4Component,
        TopKeywordSearchImpressionsComponent,
    ],
})
@AutoUnsubscribeOnDestroy()
export class SeoComponent implements OnInit, KillSubscriptions {
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    private readonly _store = inject(Store);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _heapService = inject(HeapService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _router = inject(Router);
    private readonly _gmbInsightsContext = inject(GmbInsightsContext);
    public readonly screenSizeService = inject(ScreenSizeService);

    readonly PlatformFilterPage = PlatformFilterPage;
    readonly InsightsChart = InsightsChart;
    readonly Illustration = Illustration;

    readonly isKeywordsInsightsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-keywords-insights-v2'),
        {
            initialValue: false,
        }
    );
    readonly isAggregatedKeywordsInsightsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-aggregated-keywords-insights-v2'),
        {
            initialValue: false,
        }
    );

    readonly restaurants: Signal<Restaurant[]> = toSignal(this._aggregatedStatisticsFiltersContext.selectedRestaurants$, {
        initialValue: [],
    });
    readonly hasEnoughRestaurantsSelected: Signal<boolean> = computed(
        () => this.restaurants().length >= MINIMUM_RESTAURANTS_SELECTED_THRESHOLD
    );
    readonly datePickerType: WritableSignal<DatePickerType> = signal(DatePickerType.MONTH_YEAR);
    readonly selectedTabIndex: WritableSignal<number> = signal(SeoInsightsTabs.KEYWORDS);

    chartOptions: ChartOptions = {
        [InsightsChart.AGGREGATED_APPARITIONS]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.AGGREGATED_ACTIONS]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.AGGREGATED_RANKINGS]: {
            tableSortOptions: undefined,
        },
    };

    readonly isKeywordsLoading = signal(true);
    readonly isKeywordsSearchImpressionsLoading = signal(true);

    readonly isLoading = computed(() => {
        if (this.isKeywordsInsightsV2Enabled()) {
            if (this.selectedTabIndex() === SeoInsightsTabs.KEYWORDS) {
                if (this.isAggregatedKeywordsInsightsV2Enabled()) {
                    return this.isKeywordsLoading() || this.isKeywordsSearchImpressionsLoading();
                }
                return this.isKeywordsLoading();
            }
            return this._gmbInsightsContext.isLoading();
        }

        return this._gmbInsightsContext.isLoading() || this.isKeywordsLoading();
    });

    constructor() {
        effect(
            () => {
                const currentTabIndex = this.selectedTabIndex();
                this._router.navigate([], {
                    relativeTo: this._activatedRoute,
                    queryParams: { tab: currentTabIndex },
                });
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this._store.dispatch(AggregatedStatisticsActions.editSelectedPage({ page: PlatformFilterPage.SEO }));
        this._gmbInsightsContext.gmbAggregatedInsights$.pipe(takeUntil(this.killSubscriptions$)).subscribe();
    }

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(StatisticsSelectors.selectFilters),
            this._store.select(StatisticsSelectors.selectRestaurantIdsFilter),
        ])
            .pipe(
                take(1),
                switchMap(([aggStatsFilters, restaurantIds]) => {
                    if (!aggStatsFilters.dates.startDate || !aggStatsFilters.dates.endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }

                    const filters =
                        this.isKeywordsInsightsV2Enabled() && this.selectedTabIndex() === SeoInsightsTabs.KEYWORDS
                            ? { dates: this._getDatesFromMonthYearPeriod(aggStatsFilters.monthYearPeriod), restaurantIds }
                            : {
                                  dates: { startDate: aggStatsFilters.dates.startDate, endDate: aggStatsFilters.dates.endDate },
                                  restaurantIds,
                              };

                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: this._getInsightsTab(),
                                filters,
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                chartSortBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                tableSortOptions: value,
            },
        };
    }

    handleTabChange(event: number): void {
        this.selectedTabIndex.set(event);
        switch (event) {
            case SeoInsightsTabs.KEYWORDS:
                this.datePickerType.set(DatePickerType.MONTH_YEAR);
                this._heapService.track(HeapEventName.TRACKING_CLICK_AGGREGATED_SEO_INSIGHTS_KEYWORDS_TAB);
                this.selectedTabIndex.set(SeoInsightsTabs.KEYWORDS);
                break;
            case SeoInsightsTabs.GMB:
                this.datePickerType.set(DatePickerType.GROUPED_DATE);
                this._heapService.track(HeapEventName.TRACKING_CLICK_AGGREGATED_SEO_INSIGHTS_GMB_TAB);
                this.selectedTabIndex.set(SeoInsightsTabs.GMB);
                break;
        }
    }

    private _getDatesFromMonthYearPeriod(monthYearPeriod: MonthYearPeriod): { startDate: Date; endDate: Date } {
        return {
            startDate: DateTime.fromObject(monthYearPeriod.startMonthYear).startOf('month').toJSDate(),
            endDate: DateTime.fromObject(monthYearPeriod.endMonthYear).endOf('month').toJSDate(),
        };
    }

    private _getInsightsTab(): InsightsTab {
        if (!this.isKeywordsInsightsV2Enabled()) {
            return InsightsTab.AGGREGATED_SEO;
        }

        if (this.selectedTabIndex() === SeoInsightsTabs.GMB) {
            return InsightsTab.AGGREGATED_SEO_IMPRESSIONS;
        }

        if (this.isAggregatedKeywordsInsightsV2Enabled()) {
            return InsightsTab.AGGREGATED_SEO_KEYWORDS_V2;
        }

        return InsightsTab.AGGREGATED_SEO_KEYWORDS;
    }
}
