import { inject, Injectable, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { AiCompletionDto, AiPostGenerationSettingsDto } from '@malou-io/package-dto';
import {
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    AiTextToOptimizeType,
    ApiResultV2,
    ApplicationLanguage,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { AiInteractionsService } from ':core/services/ai-interactions.service';
import { AiService } from ':core/services/ai.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { extractHashtags } from ':modules/social-posts/new-social-post-modal/utils/utils';
import { PostCaptionDisplayState } from ':shared/components/post-caption-ai-generation/post-caption-ai-generation.component';
import { Interaction } from ':shared/models/interaction';
import { OpenaiErrorService } from ':shared/openai-prompt/openai-errors.service';
import { AiOperation } from ':shared/openai-prompt/openai-prompt.component';

@Injectable({
    providedIn: 'any',
})
export class UpsertSocialPostAiContext {
    readonly _translate = inject(TranslateService);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _aiService = inject(AiService);
    private readonly _toastService = inject(ToastService);
    private readonly _openaiErrorService = inject(OpenaiErrorService);
    private readonly _aiInteractionsService = inject(AiInteractionsService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly aiRateLimitReached = signal(false);
    readonly isHashtagsAiButtonEnabled = signal(false);
    readonly isTextAreaLoadingAnimationEnabled = signal(false);
    readonly isGeneratingPostTextFromAI = signal(false);
    readonly PostCaptionAiGenerationDisplayState = PostCaptionDisplayState;
    readonly postCaptionAiGenerationDisplayState = signal(PostCaptionDisplayState.CLOSED);
    readonly loadingAnimationDefaultText = signal(
        this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
    );
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    readonly lastPrompt = signal('');
    readonly aiTextGenerationFormControl = new FormControl<string>('', [Validators.required]);
    readonly interactions = signal<Interaction[]>([]);
    readonly currentInteraction = signal<Interaction | null>(null);
    readonly resetBrowser = signal(false);
    readonly captionProposals = signal<string[]>([]);
    readonly hasPostSettingsBeenGenerated = signal(false);
    readonly selectedCaption = signal<string>('');

    readonly isImageAnalysisEnabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-ai-media-description'),
        { initialValue: false }
    );

    resetAiLoadingText(): void {
        this.loadingAnimationDefaultText.update(() =>
            this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
        );
        this.loadingAnimationSlideTextList.update(() => this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    }

    resetAiTranslationLoadingText(): void {
        this.loadingAnimationDefaultText.update(() =>
            this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_can_translate')
        );
        this.loadingAnimationSlideTextList.update(() => this._getLoadingAnimationSlideTextList(AiOperation.TRANSLATION));
    }

    closePostCaptionAiGeneration(): void {
        this.postCaptionAiGenerationDisplayState.update(() => PostCaptionDisplayState.CLOSED);
    }

    openPostCaptionAiGeneration(): void {
        this.postCaptionAiGenerationDisplayState.update(() => PostCaptionDisplayState.OPEN);
    }

    generatePost({ shouldUseImageAnalysis }: { shouldUseImageAnalysis: boolean }): void {
        this.resetAiLoadingText();
        this.isGeneratingPostTextFromAI.set(true);
        this._generateSocialNetworkPost({ userPrompt: this.aiTextGenerationFormControl.value!, shouldUseImageAnalysis });
    }

    onInteractionChanged(interaction: Interaction): void {
        const currentFormText = this._upsertSocialPostContext.upsertSocialPostState.post.text().trim();
        this.updateInteractionsList({
            id: uuidv4(),
            text: currentFormText,
            isAiInteraction: false,
            originalInteractionId: this.currentInteraction()?.isAiInteraction
                ? this.currentInteraction()?.id
                : this.currentInteraction()?.originalInteractionId,
        });
        const { textWithoutHashtag } = extractHashtags(interaction.text);
        this._upsertSocialPostContext.updateCaption(textWithoutHashtag);
        this.currentInteraction.update(() => interaction);
    }

    updateInteractionsList(interaction: Interaction): void {
        const interactions = this.interactions();
        const update = Interaction.getUpdatedInteractions(interactions, interaction);
        if (!update) {
            return;
        }
        this.interactions.set(update);
    }

    initInteractions(postId?: string): void {
        if (!postId) {
            this.interactions.set([]);
            return;
        }
        this._aiInteractionsService.getAiInteractions(postId, AiInteractionRelatedEntityCollection.POSTS).subscribe({
            next: (aiInteractions) => {
                const interactions: Interaction[] = aiInteractions
                    .filter(
                        (interaction) =>
                            interaction.isSuccessful() &&
                            interaction.type !== AiInteractionType.CHOOSE_POST_HASHTAGS &&
                            interaction.type !== AiInteractionType.TRANSLATE &&
                            interaction.type !== AiInteractionType.POST_TRANSLATION
                    )
                    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
                    .map((interaction) => ({
                        id: uuidv4(),
                        text: extractHashtags(interaction.completionText).textWithoutHashtag,
                        isAiInteraction: true,
                    }));

                this.interactions.set(interactions);
            },
        });
    }

    optimizePostCaption(): void {
        const textToOptimize = this._upsertSocialPostContext.upsertSocialPostState.post.text();
        this._upsertSocialPostContext.updateCaption('');
        this.resetAiLoadingText();
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this._aiService
            .optimizeText({
                relatedEntityId: this._upsertSocialPostContext.upsertSocialPostState.post.id(),
                textToOptimize: textToOptimize,
                textToOptimizeType: AiTextToOptimizeType.SOCIAL_NETWORK_POST,
                restaurantId: this._restaurantsService.currentRestaurant._id,
                lang: LocalStorage.getLang(),
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    const { data } = res;
                    this._aiService.handleAiInteraction();
                    const { textWithoutHashtag } = extractHashtags(data.optimizedText);
                    this.updateInteractionsList({ text: textWithoutHashtag, isAiInteraction: true, id: uuidv4() });
                    this._upsertSocialPostContext.updateCaption(textWithoutHashtag);
                },
                error: (err) => {
                    console.warn('err >', err);
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    this._upsertSocialPostContext.updateCaption(textToOptimize);
                },
            });
    }

    onCaptionProposalSelection(caption: string): void {
        this.selectedCaption.set(caption);

        let currentLegend = this._upsertSocialPostContext.upsertSocialPostState.post.text();
        if (!currentLegend) {
            this._upsertSocialPostContext.updateCaption(caption);
            return;
        }

        const existingCaptionInText = this.captionProposals().find((capt) => currentLegend.includes(capt));
        if (!!existingCaptionInText) {
            currentLegend = currentLegend.replace(`\n${existingCaptionInText}`, '').replace(existingCaptionInText, '');
        }
        const updatedLegend = currentLegend ? currentLegend + '\n' + caption : caption;
        this._upsertSocialPostContext.updateCaption(updatedLegend);
    }

    translateText(lang: ApplicationLanguage | string): void {
        this.resetAiTranslationLoadingText();
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this.isGeneratingPostTextFromAI.set(true);
        this._translateTextWithAi(lang);
    }

    private _translateTextWithAi(lang: string): void {
        const textToTranslate = this._upsertSocialPostContext.upsertSocialPostState.post.text();
        this._upsertSocialPostContext.updateCaption('');
        this._aiService
            .translateText({
                relatedEntityId: this._upsertSocialPostContext.upsertSocialPostState.post.id(),
                relatedEntityCollection: AiInteractionRelatedEntityCollection.POSTS,
                type: AiInteractionType.POST_TRANSLATION,
                restaurantId: this._restaurantsService.currentRestaurant._id,
                text: textToTranslate,
                lang,
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.update(() => false);
                    const { data: translatedText } = res;
                    this.isGeneratingPostTextFromAI.update(() => false);
                    this._aiService.handleAiInteraction();
                    this.updateInteractionsList({ text: translatedText, isAiInteraction: true, id: uuidv4() });
                    this._upsertSocialPostContext.updateCaption(translatedText);
                },
                error: (err) => {
                    this.isTextAreaLoadingAnimationEnabled.update(() => false);
                    this.isGeneratingPostTextFromAI.update(() => false);
                    this._upsertSocialPostContext.updateCaption(textToTranslate);
                    console.warn('err >', err);
                    if (err.error?.status === 429) {
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.post_subject'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.usual_tone'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.emojis'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.results_criteria'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translate.instant('common.langs.en').toLowerCase(),
                    this._translate.instant('common.langs.es').toLowerCase(),
                    this._translate.instant('common.langs.it').toLowerCase(),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }

    private _generateSocialNetworkPost({
        userPrompt,
        shouldUseImageAnalysis,
    }: {
        userPrompt: string;
        shouldUseImageAnalysis: boolean;
    }): void {
        this.captionProposals.set([]);
        const currentText = this._upsertSocialPostContext.upsertSocialPostState.post.text();
        this.lastPrompt.update(() => userPrompt);
        const postId = this._upsertSocialPostContext.upsertSocialPostState.post.id();
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        const generatePost$: Observable<ApiResultV2> = this._aiService.generateSocialNetworkPostUsingSettings({
            postId,
            description: userPrompt,
            restaurantId,
            lang: LocalStorage.getLang(),
            shouldUseImageAnalysis,
        });
        generatePost$.subscribe({
            next: (res: { data: AiCompletionDto | AiPostGenerationSettingsDto }) => {
                const { captions, hasPostSettingsBeenGenerated } = res.data as AiPostGenerationSettingsDto;
                this.captionProposals.set(captions ?? ['']);
                setTimeout(() => {
                    this.hasPostSettingsBeenGenerated.set(hasPostSettingsBeenGenerated);
                    // "just to let the user enjoy the captions" (helene)
                }, TimeInMilliseconds.SECOND * 2);
                const validGeneratedPostText = this.captionProposals()[0];
                const { textWithoutHashtag } = extractHashtags(validGeneratedPostText);
                this.isGeneratingPostTextFromAI.update(() => false);
                this._aiService.handleAiInteraction();
                this.updateInteractionsList({ text: textWithoutHashtag, isAiInteraction: true, id: uuidv4() });
                this._upsertSocialPostContext.updateCaption(currentText);
            },
            error: (err) => {
                this.isGeneratingPostTextFromAI.update(() => false);
                this._upsertSocialPostContext.updateCaption(currentText ?? '');
                if (err.error?.status === 429) {
                    this._openaiErrorService.openRateLimitErrorDialog();
                } else {
                    this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                }
                console.warn('err >', err);
            },
        });
    }
}
