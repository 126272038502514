import { Injectable } from '@angular/core';

type VideoMetadata = null | {
    widthInPixels: number;
    heightInPixels: number;
    durationInMilliseconds: number;
};

@Injectable({ providedIn: 'root' })
export class GetVideoMetadataService {
    getMetadata(file: File): Promise<{ widthInPixels: number; heightInPixels: number; durationInMilliseconds: number } | null> {
        return new GetVideoMetadataClass().getMetadata(file);
    }
}

class GetVideoMetadataClass {
    private _onLoadMetadataHandler: () => void = () => null;
    private _onErrorHandler: () => void = () => null;

    getMetadata(file: File): Promise<{ widthInPixels: number; heightInPixels: number; durationInMilliseconds: number } | null> {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);

            this._onLoadMetadataHandler = (): void => this._onLoadMetadata(video, resolve);
            this._onErrorHandler = (): void => this._onError(video, resolve);
            video.addEventListener('loadedmetadata', this._onLoadMetadataHandler);
            video.addEventListener('error', this._onErrorHandler);
        });
    }

    private _onLoadMetadata(video: HTMLVideoElement, resolve: (videoMetadata: VideoMetadata) => void): void {
        const width = video.videoWidth;
        const height = video.videoHeight;
        const durationInSeconds = video.duration;

        if (width === 0 || height === 0 || Number.isNaN(durationInSeconds) || !Number.isFinite(durationInSeconds)) {
            resolve(null);
        } else {
            resolve({ widthInPixels: width, heightInPixels: height, durationInMilliseconds: video.duration * 1000 });
        }
        this._cleanup(video);
    }

    private _onError(video: HTMLVideoElement, resolve: (videoMetadata: VideoMetadata) => void): void {
        resolve(null);
        this._cleanup(video);
    }

    private _cleanup(video: HTMLVideoElement): void {
        URL.revokeObjectURL(video.src);
        video.removeEventListener('loadedmetadata', this._onLoadMetadataHandler);
        video.removeEventListener('error', this._onErrorHandler);
    }
}
