import { z } from 'zod';

import { urlValidator } from '../../utils';

export const storeLocatorStorePageGalleryBlockValidator = z.object({
    title: z.string(),
    subtitle: z.string(),
    images: z.array(
        z.object({
            description: z.string(),
            url: urlValidator(),
        })
    ),
});
