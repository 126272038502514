import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { BOOSTERS_PRESENTATION_ROUTES } from ':modules/boosters/boosters-presentation/boosters-presentation.routing';
import { TOTEMS_ROUTES } from ':modules/totems/totems.routing';
import { WHEELS_OF_FORTUNE_ROUTES } from ':modules/wheels-of-fortune/wheels-of-fortune.routing';

import { BoostersComponent } from './boosters.component';

export const BOOSTERS_ROUTES: Routes = [
    {
        path: '',
        component: BoostersComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'wheels-of-fortune',
            },
            {
                path: 'presentation',
                loadChildren: () => BOOSTERS_PRESENTATION_ROUTES,
                data: { routePath: RoutePath.BOOSTERS_PRESENTATION },
            },
            {
                path: 'wheels-of-fortune',
                loadChildren: () => WHEELS_OF_FORTUNE_ROUTES,
                data: { routePath: RoutePath.WHEEL_OF_FORTUNE },
            },
            {
                path: 'totems',
                loadChildren: () => TOTEMS_ROUTES,
                data: { routePath: RoutePath.TOTEMS },
            },
        ],
    },
];
