import { z } from 'zod';

import { urlValidator } from '../../utils';

export const storeLocatorStorePageSocialNetworksBlockValidator = z.object({
    title: z.string(),
    socialAccount: z.object({
        name: z.string(),
        url: urlValidator(),
        logoUrl: urlValidator(),
        followersCount: z.string(),
        publicationsCount: z.string(),
    }),
    publications: z.array(
        z.object({
            imageUrl: urlValidator(),
            imageDescription: z.string(),
            mediaType: z.string(),
            url: urlValidator(),
            likesCount: z.string().optional(),
            caption: z.string(),
            mediaCount: z.number(),
            isFirstMediaVideo: z.boolean(),
        })
    ),
});
