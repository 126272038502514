import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { GmbInsightsContext } from ':modules/aggregated-statistics/seo/context/gmb-insights.context';
import { GmbImpressionsChartComponent } from ':modules/aggregated-statistics/seo/gmb-impressions-v2/gmb-impressions-chart/gmb-impressions-chart.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-gmb-impressions-v2',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
        LowerCasePipe,
        NumberEvolutionComponent,
        ShortNumberPipe,
        GmbImpressionsChartComponent,
    ],
    templateUrl: './gmb-impressions-v2.component.html',
    styleUrl: './gmb-impressions-v2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbImpressionsV2Component implements OnInit {
    readonly showSortByTextInsteadOfSelector = input<boolean | undefined>(false);
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly sortBy = input<ChartSortBy | undefined>(undefined);
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly sortByChange = output<ChartSortBy>();

    readonly gmbInsightsContext = inject(GmbInsightsContext);
    private readonly _enumTranslate = inject(EnumTranslatePipe);

    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_SORT_BY = this.sortBy() ?? ChartSortBy.DESC;
    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(this.DEFAULT_SORT_BY) as FormControl<ChartSortBy>;
    readonly sortByFilterSubject$: BehaviorSubject<ChartSortBy> = new BehaviorSubject(this.DEFAULT_SORT_BY);
    readonly SORT_BY_FILTER_VALUES = Object.values(ChartSortBy);

    readonly restaurants = computed(() => this.gmbInsightsContext.currentImpressionsChartData().restaurants);

    readonly gmbImpressionsData = computed(() => ({
        impressionsMaps: this.gmbInsightsContext.currentImpressionsChartData().impressionsMaps,
        impressionsSearch: this.gmbInsightsContext.currentImpressionsChartData().impressionsSearch,
    }));
    readonly previousImpressionsData = computed(() => ({
        impressionsMaps: this.gmbInsightsContext.previousImpressionsChartData().impressionsMaps,
        impressionsSearch: this.gmbInsightsContext.previousImpressionsChartData().impressionsSearch,
    }));
    readonly totalImpressionsMaps = computed(() => this.gmbInsightsContext.currentImpressionsChartData().totalImpressionsMaps);
    readonly totalImpressionsSearch = computed(() => this.gmbInsightsContext.currentImpressionsChartData().totalImpressionsSearch);

    readonly impressionsMapsEvolutionPercentage = computed(() => {
        const totalImpressionsMaps = this.totalImpressionsMaps();
        const previousTotalImpressionsMaps = this.gmbInsightsContext.previousImpressionsChartData().totalImpressionsMaps;
        return totalImpressionsMaps && previousTotalImpressionsMaps
            ? ((totalImpressionsMaps - previousTotalImpressionsMaps) / previousTotalImpressionsMaps) * 100
            : null;
    });

    readonly impressionsSearchEvolutionPercentage = computed(() => {
        const totalImpressionsSearch = this.totalImpressionsSearch();
        const previousTotalImpressionsSearch = this.gmbInsightsContext.previousImpressionsChartData().totalImpressionsSearch;
        return totalImpressionsSearch && previousTotalImpressionsSearch
            ? ((totalImpressionsSearch - previousTotalImpressionsSearch) / previousTotalImpressionsSearch) * 100
            : null;
    });

    ngOnInit(): void {
        this.sortByFilterSubject$.subscribe((sortBy) => {
            if (!this.gmbInsightsContext.isLoading()) {
                this.sortByChange.emit(sortBy);
                this.gmbInsightsContext.setImpressionChartDataSorted(this.restaurants(), sortBy);
            }
        });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');
}
