export enum HeapEventName {
    MANUALLY_CREATE_KEYWORD = 'tracking_keywords_manually_entered',
    NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
    REPORT_EMAIL_OPENED = 'report_email_opened',
    REPORT_EMAIL_SENT = 'report_email_sent',
    REPORT_EMAIL_UNSUBSCRIBE = 'report_email_unsubscribe',
    UPDATE_KEYWORD_LANGUAGE_FR = 'tracking_update_keyword_language_fr',
    UPDATE_KEYWORD_LANGUAGE_EN = 'tracking_update_keyword_language_en',
    UPDATE_KEYWORD_LANGUAGE_ES = 'tracking_update_keyword_language_es',
    UPDATE_KEYWORD_LANGUAGE_IT = 'tracking_update_keyword_language_it',
    WHEEL_OF_FORTUNE_GIFT_EXPIRES_SOON = 'tracking_wof_gift_expires_soon',
    WHEEL_OF_FORTUNE_RETRIEVE_GIFT = 'tracking_wof_retrieve_gift',
    TRACKING_ROI_TIP = 'tracking_roi_tip',
    TRACKING_FIRST_TIME_SETTINGS = 'tracking_first_time_settings',
    TRACKING_EDIT_SETTINGS = 'tracking_edit_settings',
    TRACKING_CHECK_PERFORMANCE_SCORE = 'tracking_check_performance_score',
    TRACKING_CHECK_EARNINGS_DETAILS_SINGLE_RESTAURANT = 'tracking_check_earnings_details_single_restaurant',
    TRACKING_CHECK_EARNINGS_DETAILS_MULTI_RESTAURANTS = 'tracking_check_earnings_details_multi_restaurants',
    TRACKING_CHECK_GAINED_TIME_DETAILS_SINGLE_RESTAURANT = 'tracking_check_gained_time_details_single_restaurant',
    TRACKING_CHECK_GAINED_TIME_DETAILS_MULTI_RESTAURANTS = 'tracking_check_gained_time_details_multi_restaurants',
    MALOUPE_TRACKING_OPENING_LANDING_PAGE = 'maloupe_tracking_opening_landing_page',
    MALOUPE_TRACKING_GET_DIAGNOSTIC_ACTION = 'maloupe_tracking_get_diagnostic_action',
    MALOUPE_TRACKING_GET_DIAGNOSTIC_SNIPPET = 'maloupe_tracking_get_diagnostic_snippet',
    MALOUPE_TRACKING_SUBMIT_HUBSPOT_FORM = 'maloupe_tracking_submit_hubspot_form',
    MALOUPE_TRACKING_CLICK_ON_DIAGNOSTIC_EMAIL_LINK = 'maloupe_tracking_click_on_diagnostic_email_link',
    MALOUPE_TRACKING_CLICK_ON_CHECK_KEYWORDS_COMPETITORS = 'maloupe_tracking_click_on_check_keywords_competitors',
    NOTIFICATION_REVIEW_REMINDER_TRACKING_EMAIL_SENT = 'notification_review_reminder_tracking_email_sent',
    NOTIFICATION_REVIEW_REMINDER_TRACKING_EMAIL_OPENED = 'notification_review_reminder_tracking_email_opened',
    NOTIFICATION_REVIEW_REMINDER_TRACKING_EMAIL_BUTTON_CLICKED = 'notification_review_reminder_tracking_email_button_clicked',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_EMAIL_SENT = 'notification_special_hour_tracking_email_sent',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_EMAIL_OPENED = 'notification_special_hour_tracking_email_opened',
    NOTIFICATION_POST_SUGGESTION_TRACKING_EMAIL_SENT = 'notification_post_suggestion_tracking_email_sent',
    NOTIFICATION_POST_SUGGESTION_TRACKING_EMAIL_OPENED = 'notification_post_suggestion_tracking_email_opened',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_RECEPTION = 'notification_special_hour_tracking_reception',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_OPEN_ACTION = 'notification_special_hour_tracking_open_action',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSED_ACTION = 'notification_special_hour_tracking_closed_action',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_UPDATE_HOURS_ACTION = 'notification_special_hour_tracking_update_hours_action',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_EMAIL_BUTTON_CLICKED = 'notification_special_hour_tracking_email_button_clicked',
    NOTIFICATION_ROI_ACTIVATED_TRACKING_EMAIL_SENT = 'notification_roi_activated_tracking_email_sent',
    NOTIFICATION_ROI_ACTIVATED_TRACKING_EMAIL_OPENED = 'notification_roi_activated_tracking_email_opened',
    NOTIFICATION_ROI_ACTIVATED_TRACKING_EMAIL_BUTTON_CLICKED = 'notification_roi_activated_tracking_email_button_clicked',
    NOTIFICATION_COMMENT_TRACKING_WEB_REDIRECT = 'notification_comment_tracking_redirect',
    NOTIFICATION_MENTION_TRACKING_WEB_REDIRECT = 'notification_mention_tracking_redirect',
    NOTIFICATION_MESSAGE_TRACKING_WEB_REDIRECT = 'notification_message_tracking_redirect',
    NOTIFICATION_REVIEWS_TRACKING_WEB_REDIRECT = 'notification_reviews_tracking_redirect',
    NOTIFICATION_NEGATIVE_REVIEW_REMINDER_TRACKING_WEB_REDIRECT = 'notification_negative_review_reminder_tracking_redirect',
    NOTIFICATION_POST_ERROR_TRACKING_WEB_REDIRECT = 'notification_post_error_tracking_redirect',
    NOTIFICATION_STORY_ERROR_TRACKING_WEB_REDIRECT = 'notification_story_error_tracking_redirect',
    NOTIFICATION_STORY_ERROR_TRACKING_EMAIL_REDIRECT = 'notification_story_error_tracking_email_redirect',
    NOTIFICATION_POST_ERROR_TRACKING_EMAIL_OPENED = 'notification_post_error_tracking_email_opened',
    NOTIFICATION_POST_ERROR_TRACKING_EMAIL_SENT = 'notification_post_error_tracking_email_sent',
    EDIT_AGGREGATED_STATISTICS_RESTAURANTS = 'edit_aggregated_statistics_restaurants',
    TRACKING_HOURS_MODAL_CLICK_REGULAR_HOURS_TAB = 'tracking_hours_modal_click_regular_hours_tab',
    TRACKING_HOURS_MODAL_CLICK_SPECIAL_HOURS_TAB = 'tracking_hours_modal_click_special_hours_tab',
    TRACKING_HOURS_MODAL_CLICK_OTHER_HOURS_TAB = 'tracking_hours_modal_click_other_hours_tab',
    NOTIFICATION_SUMMARY_TRACKING_EMAIL_SENT = 'summary_notification_tracking_email_sent',
    NOTIFICATION_SUMMARY_TRACKING_EMAIL_OPENED = 'summary_notification_tracking_email_opened',
    NOTIFICATION_ROI_ACTIVATED_TRACKING_WEB_REDIRECT = 'notification_roi_activated_tracking_web_redirect',
    NOTIFICATION_POST_SUGGESTION_TRACKING_SEO_WEB_REDIRECT = 'notification_post_suggestion_tracking_seo_web_redirect',
    NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_WEB_REDIRECT = 'notification_post_suggestion_tracking_social_web_redirect',
    NOTIFICATION_POST_SUGGESTION_TRACKING_EMAIL_BUTTON_CLICKED = 'notification_post_suggestion_tracking_email_button_clicked',
    NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_CLOSE_POPIN = 'notification_post_suggestion_tracking_social_close_popin',
    ROI_SCROLL_TRACKING = 'roi_scroll_tracking',
    ROI_AGGREGATED_SCROLL_TRACKING = 'roi_aggregated_scroll_tracking',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_OPENED_POPIN = 'notification_special_hour_tracking_opened_popin',
    NOTIFICATION_POST_SUGGESTION_TRACKING_SOCIAL_OPEN_POPIN = 'notification_post_suggestion_tracking_social_open_popin',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSE_POPIN = 'notification_special_hour_tracking_close_popin',
    NOTIFICATION_PLATFORM_DISCONNECTED_WEB_REDIRECT = 'notification_platform_disconnected_web_redirect',
    NOTIFICATION_PLATFORM_DISCONNECTED_EMAIL_SENT = 'notification_platform_disconnected_email_sent',
    NOTIFICATION_PLATFORM_DISCONNECTED_EMAIL_OPENED = 'notification_platform_disconnected_email_opened',
    NOTIFICATION_SUMMARY_WEB_REDIRECT = 'notification_summary_web_redirect',
    NOTIFICATION_CENTER_OPEN = 'notification_center_open',
    DUPLICATE_POSTS = 'duplicate_posts',
    REVIEW_REPLIED = 'review_replied',
    ROI_AGGREGATED_ESTIMATED_CUSTOMERS_VIEW_MODE_CHANGE = 'roi_aggregated_estimated_customers_view_mode_change',
    ROI_AGGREGATED_PERFORMANCE_VIEW_MODE_CHANGE = 'roi_aggregated_performance_view_mode_change',
    POST_SAVED = 'post_saved',
    MESSAGE_SENT = 'message_sent',
    SOCIAL_POSTS_CREATE_POST = 'social_posts_create_post',
    EDIT_SPECIAL_HOURS = 'edit_special_hours',
    COMMENT_REPLIED = 'comment_replied',
    INFORMATION_UPDATE_ERROR_REDIRECT = 'information_update_error_redirect',
    TRACKING_DATE_FILTER_CHANGES_AGGREGATED_REVIEWS = 'tracking_date_filter_changes_aggregated_reviews',
    TRACKING_DATE_FILTER_CHANGES_REVIEWS = 'tracking_date_filter_changes_reviews',
    TRACKING_DATE_FILTER_CHANGES_AGGREGATED_SEO = 'tracking_date_filter_changes_aggregated_seo',
    TRACKING_DATE_FILTER_CHANGES_SEO = 'tracking_date_filter_changes_seo',
    TRACKING_DATE_FILTER_CHANGES_E_REPUTATION = 'tracking_date_filter_changes_e_reputation',
    TRACKING_DATE_FILTER_CHANGES_AGGREGATED_E_REPUTATION = 'tracking_date_filter_changes_aggregated_e_reputation',
    TRACKING_CALENDAR_COMPARISON_PERIOD_CHANGE = 'tracking_calendar_comparison_period_change',
    TRACKING_CLICK_E_REPUTATION_INSIGHTS_REVIEWS_TAB = 'tracking_click_e_reputation_insights_reviews_tab',
    TRACKING_CLICK_E_REPUTATION_INSIGHTS_SEMANTIC_ANALYSES_TAB = 'tracking_click_e_reputation_insights_semantic_analyses_tab',
    TRACKING_CLICK_AGGREGATED_E_REPUTATION_INSIGHTS_REVIEWS_TAB = 'tracking_click_aggregated_e_reputation_insights_reviews_tab',
    TRACKING_CLICK_AGGREGATED_E_REPUTATION_INSIGHTS_SEMANTIC_ANALYSES_TAB = 'tracking_click_aggregated_e_reputation_insights_semantic_analyses_tab',
    TRACKING_CLICK_SEO_INSIGHTS_KEYWORDS_TAB = 'tracking_click_seo_insights_keywords_tab',
    TRACKING_CLICK_SEO_INSIGHTS_GMB_TAB = 'tracking_click_seo_insights_gmb_tab',
    TRACKING_CLICK_AGGREGATED_SEO_INSIGHTS_KEYWORDS_TAB = 'tracking_click_aggregated_seo_insights_keywords_tab',
    TRACKING_CLICK_AGGREGATED_SEO_INSIGHTS_GMB_TAB = 'tracking_click_aggregated_seo_insights_gmb_tab',
    PLATFORM_UPDATE_PER_PLATFORM_START = 'platform_update_per_platform_start',
    PLATFORM_UPDATE_PER_PLATFORM_END = 'platform_update_per_platform_end',
    TRACKING_UNSUBSCRIBE_FROM_REPORT = 'tracking_unsubscribe_from_report',
    TOP_KEYWORD_SEARCH_IMPRESSIONS_SEE_MORE = 'top_keyword_search_impressions_see_more',
    DOWNLOAD_INSIGHTS = 'download_insights',
    TRACKING_VIEW_SEO_INSIGHTS = 'tracking_view_seo_insights',
    TRACKING_SEMANTIC_ANALYSIS_SCROLLING = 'tracking_semantic_analysis_scrolling',
    AGGRAGTED_TOP_KEYWORD_SEARCH_IMPRESSIONS_SEE_MORE = 'aggragted_top_keyword_search_impressions_see_more',
    TRACKING_AGGREGATED_SEMANTIC_ANALYSIS_ROW_CLICK = 'tracking_aggregated_semantic_analysis_row_click',
    SEO_KEYWORDS_STATS_APPARITION_ADVICE_CLICK = 'seo_keywords_stats_apparition_advice_click',
    TRACKING_SCROLL_SEO_INSIGHTS = 'tracking_scroll_seo_insights',
}
