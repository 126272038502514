import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { ReviewsHeaderAutomaticReplyComponent } from ':modules/reviews/reviews-header/reviews-header-automatic-reply/reviews-header-automatic-reply.component';
import { ReviewsHeaderFiltersComponent } from ':modules/reviews/reviews-header/reviews-header-filters/reviews-header-filters.component';
import { ReviewsHeaderRestaurantsComponent } from ':modules/reviews/reviews-header/reviews-header-restaurants/reviews-header-restaurants.component';
import { ReviewsHeaderSearchComponent } from ':modules/reviews/reviews-header/reviews-header-search/reviews-header-search.component';
import { ReviewsHeaderSynchronizeComponent } from ':modules/reviews/reviews-header/reviews-header-synchronize/reviews-header-synchronize.component';
import { ButtonComponent } from ':shared/components/button/button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { selectEstimatedReviewCount } from '../store/reviews.selectors';

@Component({
    selector: 'app-reviews-header',
    templateUrl: './reviews-header.component.html',
    styleUrls: ['./reviews-header.component.scss'],
    standalone: true,
    imports: [
        ButtonComponent,
        ReviewsHeaderAutomaticReplyComponent,
        ReviewsHeaderSearchComponent,
        ReviewsHeaderRestaurantsComponent,
        ReviewsHeaderFiltersComponent,
        ReviewsHeaderSynchronizeComponent,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderComponent {
    readonly isAggregatedView = input<boolean>(false);

    readonly displayAutomaticReplyFilter = input.required<boolean>();
    readonly displayRestaurantsFilter = input.required<boolean>();

    readonly searchButtonId = input<string>('');
    readonly sortButtonId = input<string>('');
    readonly filtersButtonId = input<string>('');
    readonly synchronizeButtonId = input<string>('');

    private readonly _store = inject(Store);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _router = inject(Router);

    readonly SvgIcon = SvgIcon;

    readonly reviewCount$ = this._store.select(selectEstimatedReviewCount);
    readonly reviewCount = toSignal(this.reviewCount$, { initialValue: undefined });

    readonly showAdvancedFilters = signal(false);

    private readonly _restaurantHasBoosterPackActivated = computed(
        () => !!this._restaurantsService.selectedRestaurant()?.boosterPack.activated
    );
    private readonly _ownRestaurants = toSignal(this._store.select(selectOwnRestaurants), { initialValue: [] });
    private readonly _ownRestaurantsWithBoosterPack = computed(() =>
        this._ownRestaurants().filter((restaurant) => !!restaurant.boosterPack?.activated)
    );
    readonly shouldDisplayGetBoostersButton = computed(
        () =>
            (!this._restaurantHasBoosterPackActivated() && !this.isAggregatedView()) ||
            (this.isAggregatedView() && this._ownRestaurantsWithBoosterPack().length === 0)
    );

    toggleAdvancedFilters(): void {
        this.showAdvancedFilters.update((showAdvancedFilters) => !showAdvancedFilters);
    }

    redirectToBoosters(): void {
        let restaurant = this._restaurantsService.selectedRestaurant();
        if (!restaurant) {
            const firstRestaurantWithoutBoosterPack = this._ownRestaurants().find((rest) => !rest.boosterPack?.activated);
            if (!firstRestaurantWithoutBoosterPack) {
                return;
            }
            restaurant = firstRestaurantWithoutBoosterPack;
        }
        this._router.navigate(['/restaurants', restaurant._id, 'boosters', 'presentation']);
    }
}
