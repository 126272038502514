import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, input, output, signal, viewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { IGAccount } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

export const TAG_ACCOUNT_HEIGHT = 142;
export const TAG_ACCOUNT_WIDTH = 250;

@Component({
    selector: 'app-tag-account-v2',
    templateUrl: './tag-account.component.html',
    styleUrls: ['./tag-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        MatAutocompleteModule,
        TranslateModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        IllustrationPathResolverPipe,
        LazyLoadImageModule,
        MatTooltipModule,
        ShortTextPipe,
    ],
})
export class TagAccountV2Component {
    readonly tagControl = input.required<FormControl<string | null>>();
    readonly foundAccount = input.required<IGAccount | undefined>();
    readonly searching = input.required<boolean>();
    readonly tagPosition = input.required<{ top: number; left: number }>();
    readonly showArrowAbove = input.required<boolean>();
    readonly userTagsHistory = input.required<{ username: string; count: number; igAccount: IGAccount }[]>();

    readonly onAccountSelected = output<MatAutocompleteSelectedEvent>();
    readonly onClose = output<MouseEvent>();

    private readonly _addTagAccountContainer = viewChild.required<ElementRef<HTMLDivElement>>('addTagAccountContainer');

    readonly SvgIcon = SvgIcon;

    private readonly _searchValue = signal('');
    readonly userTagsHistoryFiltered = computed(() => {
        const userTagsHistory = this.userTagsHistory();
        const searchValue = this._searchValue() ?? '';
        return userTagsHistory.filter((tag) => tag.username.toLowerCase().includes(searchValue.toLowerCase()));
    });

    readonly TAG_ACCOUNT_HEIGHT = `${TAG_ACCOUNT_HEIGHT}px`;
    readonly TAG_ACCOUNT_WIDTH = `${TAG_ACCOUNT_WIDTH}px`;
    readonly TAG_ACCOUNT_SIZE_STYLE = { height: this.TAG_ACCOUNT_HEIGHT, width: this.TAG_ACCOUNT_WIDTH };

    readonly tagPositionStyle = computed(() => {
        const container = this._addTagAccountContainer();
        const tagPosition = this.tagPosition();
        const containerBoundingClientRect = container.nativeElement.getBoundingClientRect();
        const parentBoudingClientRect = container.nativeElement.parentElement?.parentElement?.getBoundingClientRect();

        // Arbitrary values to position the arrow, obtained experimentally
        const ARROW_ABOVE_TOP_OFFSET = -15;
        const ARROW_BELOW_TOP_OFFSET = 7;
        const LEFT_OFFSET = 6;

        if (!containerBoundingClientRect || !parentBoudingClientRect) {
            return {
                top: `${ARROW_ABOVE_TOP_OFFSET}px`,
                left: `${LEFT_OFFSET}px`,
            };
        }
        const containerPositionLeft = containerBoundingClientRect.x - parentBoudingClientRect.x;
        const containerPositionTop = containerBoundingClientRect.y - parentBoudingClientRect.y;
        const top =
            containerPositionTop -
            tagPosition.top +
            (this.showArrowAbove() ? ARROW_ABOVE_TOP_OFFSET : 2 * TAG_ACCOUNT_HEIGHT + ARROW_BELOW_TOP_OFFSET);

        const left = tagPosition.left - containerPositionLeft - LEFT_OFFSET;

        return {
            top: `${top}px`,
            left: `${left}px`,
        };
    });

    constructor() {
        effect(
            () => {
                this.tagControl().valueChanges.subscribe((value) => {
                    this._searchValue.set(value ?? '');
                });
            },
            { allowSignalWrites: true }
        );
    }
}
