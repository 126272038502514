import { InsightsChart, MalouComparisonPeriod, MonthYearPeriod, PlatformKey } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { LocalStorageKey } from ':shared/enums/local-storage-key';

export interface DownloadInsightsParams {
    displayedCharts: InsightsChart[];
    chartOptions?: ChartOptions;
    dates: { startDate: Date; endDate: Date };
    platformKeys?: PlatformKey[];
    restaurantIds?: string[];
    nfcIds?: string[];
    timeZone: string;
    comparisonPeriod?: MalouComparisonPeriod;
    monthYearPeriod?: MonthYearPeriod;
}

export function parseInsightsRouteParams(): DownloadInsightsParams {
    const queryParams = LocalStorage.getItem(LocalStorageKey.DOWNLOAD_INSIGHTS_PARAMS);
    if (!queryParams) {
        throw new Error('No pdf params found in local storage');
    }

    const parsedQueryParams = JSON.parse(queryParams);

    const dates = {
        startDate: new Date(parsedQueryParams.dates.startDate),
        endDate: new Date(parsedQueryParams.dates.endDate),
    };

    return {
        ...parsedQueryParams,
        dates,
    };
}
