<div class="flex flex-col px-7 sm:px-0">
    <div class="mb-3">
        <app-search [debounceTime]="0" [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
    </div>
    <div class="hide-scrollbar flex h-[58vh] flex-col overflow-auto">
        <mat-checkbox
            class="mb-2 pl-[27px]"
            color="primary"
            [checked]="isOneOrMoreRestaurantSelected() && isAllRestaurantsSelected()"
            [indeterminate]="isOneOrMoreRestaurantSelected() && !isAllRestaurantsSelected()"
            (change)="$event ? toggleAllRows() : null">
            <span class="malou-text-12--bold text-malou-color-text-1">
                {{ 'duplicate_to_restaurants_dialog.all_businesses' | translate }}
            </span>
        </mat-checkbox>
        <ng-container
            [ngTemplateOutlet]="displayOrganizations ? organizationTableTemplate : simpleTableTemplate"
            [ngTemplateOutletContext]="displayOrganizations ? null : { dataSourceInput: restaurantDataSource, showPagination: true }">
        </ng-container>
        <app-paginator
            [shouldBeHidden]="displayOrganizations || restaurantDataSource.filteredData.length < DEFAULT_RESTAURANT_PAGINATION"
            [pageSize]="DEFAULT_RESTAURANT_PAGINATION"
            [pageSizeOptions]="[DEFAULT_RESTAURANT_PAGINATION]"
            #RestaurantPaginator>
        </app-paginator>
        <app-paginator
            [shouldBeHidden]="!displayOrganizations || organizationDataSource.filteredData.length < DEFAULT_ORGANIZATION_PAGINATION"
            [pageSize]="DEFAULT_ORGANIZATION_PAGINATION"
            [pageSizeOptions]="[DEFAULT_ORGANIZATION_PAGINATION]"
            #OrganizationPaginator>
        </app-paginator>
    </div>
</div>

<ng-template #organizationTableTemplate>
    <div class="malou-expansion-panel">
        <mat-accordion multi>
            @for (organizationName of paginatedOrganizations; track organizationName) {
                <mat-expansion-panel [expanded]="paginatedOrganizations.length === 1">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="malou-text-12--bold malou-color-text-1">
                            <mat-checkbox
                                class="rounded-[10px] px-3 py-1"
                                color="primary"
                                [value]="organizationName"
                                [checked]="
                                    (isOrganisationSelected | applyPure: organizationName : restaurantSelection.selected.length) ||
                                    (isAllRestaurantsSelectedInOrganization
                                        | applyPure: organizationName : restaurantSelection.selected.length)
                                "
                                [indeterminate]="
                                    (isOneOrMoreRestaurantSelectedInOrganization
                                        | applyPure: organizationName : restaurantSelection.selected.length) &&
                                    !(isOrganisationSelected | applyPure: organizationName : restaurantSelection.selected.length) &&
                                    !(
                                        isAllRestaurantsSelectedInOrganization
                                        | applyPure: organizationName : restaurantSelection.selected.length
                                    )
                                "
                                (click)="$event.stopPropagation()"
                                (change)="selectOrganisation(organizationName)"></mat-checkbox>
                            <span> {{ organizationName }} ({{ restaurantsGroupedByOrganization[organizationName].length }}) </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="mx-3 mb-2">
                        <ng-container
                            [ngTemplateOutlet]="simpleTableTemplate"
                            [ngTemplateOutletContext]="{
                                dataSourceInput: toDataSource | applyPure: restaurantsGroupedByOrganization[organizationName],
                                showPagination: false,
                            }">
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template let-dataSourceInput="dataSourceInput" #simpleTableTemplate>
    @if (dataSourceInput.filteredData.length) {
        <mat-table class="malou-mat-table" matSort matSortDisableClear [dataSource]="dataSourceInput" #table="matTable">
            <ng-container matColumnDef="restaurant">
                <mat-cell *matCellDef="let restaurant; table: table">
                    <mat-checkbox
                        class="w-full rounded-[10px] px-6.5 py-1"
                        color="primary"
                        [value]="restaurant!._id"
                        [checked]="isRestaurantSelected | applyPure: restaurant : restaurantSelection.selected.length"
                        [disabled]="
                            inputData.disableRestaurantWithoutMapstrPremium &&
                            !(hasRestaurantMapstrPremium | applyPure: restaurant : (restaurantsIdsWithMapstrPremium$ | async))
                        "
                        [matTooltip]="
                            inputData.disableRestaurantWithoutMapstrPremium &&
                            !(hasRestaurantMapstrPremium | applyPure: restaurant : (restaurantsIdsWithMapstrPremium$ | async))
                                ? ('duplicate_to_restaurants_dialog.restaurant_does_not_have_mapstr_premium' | translate)
                                : null
                        "
                        (change)="selectRestaurant(restaurant)">
                        <div class="ml-2 flex w-full items-center gap-x-2">
                            <img
                                class="malou-avatar--medium h-[45px] w-[45px] sm:h-[30px] sm:w-[30px]"
                                [lazyLoad]="
                                    (restaurant?.logo | applySelfPure: 'getMediaUrl' : 'small') ??
                                    ('default-picture-grey' | imagePathResolver)
                                " />
                            <div class="ml-2">
                                <div class="flex items-center gap-x-1">
                                    <div
                                        class="flex !h-3 !w-3 items-center justify-center rounded-[50%]"
                                        [matTooltip]="
                                            ((restaurant | applySelfPure: 'isBrandBusiness') ? 'common.brand_account' : 'common.business')
                                                | translate
                                        "
                                        [ngClass]="
                                            (restaurant | applySelfPure: 'isBrandBusiness')
                                                ? 'bg-malou-color-state-warn'
                                                : 'bg-malou-color-chart-pink--light'
                                        ">
                                        <mat-icon
                                            class="!h-2 text-white"
                                            [svgIcon]="
                                                (restaurant | applySelfPure: 'isBrandBusiness') ? 'heart' : 'localisation'
                                            "></mat-icon>
                                    </div>
                                    <div class="malou-text-12--bold sm:malou-text-10--bold leading-6 text-malou-color-text-1">
                                        {{ restaurant | applySelfPure: 'getDisplayName' }}
                                    </div>
                                </div>
                                @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
                                    <div class="malou-text-12 sm:malou-text-10 truncate italic leading-6">
                                        {{ restaurant.address }}
                                    </div>
                                }
                            </div>
                        </div>
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <mat-row
                *matRowDef="let restaurant; columns: displayedColumns; table: table; let i = index"
                class="row"
                [class.selected]="isRestaurantSelected | applyPure: restaurant : restaurantSelection.selected.length"></mat-row>
        </mat-table>
    } @else {
        <app-no-results
            class="mt-4"
            [title]="'duplicate_to_restaurants_dialog.no_business_title' | translate"
            [details]="['duplicate_to_restaurants_dialog.no_business_details' | translate]"
            [picture]="Illustration.Pizza | illustrationPathResolver">
        </app-no-results>
    }
</ng-template>
