import { z } from 'zod';

import {
    ApplicationLanguage,
    PlatformKey,
    PostCallToActionType,
    PostPublicationStatus,
    PostSource,
    PostType,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';
import { SocialPostMediaDto } from './get-social-posts.dto';
import { PostHashtagDto } from './post-hashtags.dto';

export const getPostsToDuplicateQueryValidator = z
    .object({
        post_ids: z.array(objectIdValidator),
    })
    .transform((data) => ({ postIds: data.post_ids }));

export type GetPostsToDuplicateQueryDto = z.infer<typeof getPostsToDuplicateQueryValidator>;

export type PostToDuplicateDto = {
    id: string;
    text: string;
    postType: PostType;
    source: PostSource;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    plannedPublicationDate: string | null;
    medias: SocialPostMediaDto[];
    language: ApplicationLanguage | null;
    hashtags?: { selected: PostHashtagDto[]; suggested: PostHashtagDto[] };
    callToAction: {
        actionType: PostCallToActionType;
        url?: string;
    } | null;
};
