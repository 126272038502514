import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    Subject,
    takeUntil,
    tap,
} from 'rxjs';
import { v4 as uuid } from 'uuid';

import { AppEntity, HeapEventName, isNotNil, MESSAGE_MAX_VIDEO_SIZE, MessageStatus, TemplateStatus } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { HoursToTextService } from ':core/services/hours-to-text.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TemplatesService } from ':core/services/templates.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaService } from ':modules/media/media.service';
import { MessageCardComponent } from ':modules/messages/conversation-panel/message-card/message-card.component';
import * as MessagesActions from ':modules/messages/messages.actions';
import { selectCurrentConversationWithMessages } from ':modules/messages/messages.reducer';
import { MessagesService } from ':modules/messages/messages.service';
import { TemplatesPickerModalComponent } from ':modules/messages/templates-picker-modal/templates-picker-modal.component';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TemplateType } from ':shared/enums/template-type.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { getTemplateVariablesAndTranslations, Media, Pagination, Restaurant, Template } from ':shared/models';
import { ConversationWithMessages } from ':shared/models/conversation';
import { Message, MessageAttachmentUploading } from ':shared/models/message';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AsTypePipe } from ':shared/pipes/as.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface QuickText {
    key: string;
    displayKey: string;
    content: string;
}

const MAX_FILE_SIZE = 25000000;
const MAX_MESSAGE_LENGTH = 1000;

interface UserTyping {
    typing: boolean;
    conversationId?: string;
    user?: User;
}

@Component({
    selector: 'app-conversation-panel',
    templateUrl: './conversation-panel.component.html',
    styleUrls: ['./conversation-panel.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        MatCheckboxModule,
        MatChipsModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatRippleModule,
        ReactiveFormsModule,
        TranslateModule,
        MessageCardComponent,
        TextAreaComponent,
        InputTextComponent,
        ApplyPurePipe,
        AsyncPipe,
        AvatarPipe,
        DatePipe,
        PlatformLogoComponent,
        AsTypePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ConversationPanelComponent implements OnInit, KillSubscriptions {
    @Input() conversationChanged$: BehaviorSubject<ConversationWithMessages | null>;
    @Input() scrollDownEvent: EventEmitter<{ isNewConversation: boolean }>;
    @Output() conversationClosed = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;

    readonly templates$: Observable<Template[]> = this._templatesService.getTemplatesByRestaurantId(
        this._restaurantsService.currentRestaurant._id,
        TemplateType.MESSAGE
    );
    readonly currentUser$ = this._store.select(selectUserInfos);
    readonly restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    readonly currentConversationWithMessages$: Observable<ConversationWithMessages | null> = this._store.select(
        selectCurrentConversationWithMessages
    );

    readonly loadingMore$ = new BehaviorSubject(false);
    readonly Message = Message;

    readonly selectedTemplateId: WritableSignal<string | null> = signal(null);

    quickTexts: QuickText[] = [
        { key: 'phone', displayKey: '', content: '' },
        { key: 'address', displayKey: '', content: '' },
        { key: 'regularHours', displayKey: '', content: '' },
        { key: 'website', displayKey: '', content: '' },
        { key: 'menuUrl', displayKey: '', content: '' },
        { key: 'clientName', displayKey: '', content: '' },
    ];
    messageText = new UntypedFormControl('', { validators: [Validators.required] });
    attachments: MessageAttachmentUploading[] = [];
    isUserTyping = false;
    teamTyping: UserTyping = { typing: false };
    user: User;
    pagination: Pagination = { pageNumber: 1, pageSize: 10, total: Number.POSITIVE_INFINITY };
    saveTemplate = false;
    restaurant: Restaurant;
    receivedNewMessage = false;
    currentCWM: ConversationWithMessages | null;
    templates: Template[] = [];
    filteredTemplates: Template[];

    templateNameControl = new FormControl<string>('');

    moreButtonsToggled = false;

    private readonly _CHIPS_TEXT_TO_VARIABLE_IN_TEMPLATE = getTemplateVariablesAndTranslations(this._translate);

    constructor(
        private readonly _translate: TranslateService,
        private readonly _messagesService: MessagesService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _hoursToTextService: HoursToTextService,
        private readonly _mediaService: MediaService,
        private readonly _templatesService: TemplatesService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _heapService: HeapService,
        private readonly _activatedRoute: ActivatedRoute,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this._updateCurrentConversationWithMessages();
        this.restaurant$.subscribe({
            next: (restaurant) => {
                if (!restaurant) {
                    return;
                }
                this.restaurant = restaurant;
                this._initQuickText(restaurant, LocalStorage.getLang());
            },
        });
    }

    ngOnInit(): void {
        this.templates$.subscribe((templates) => {
            this.templates = templates.filter((t) => t.type === TemplateType.MESSAGE);
            this.filteredTemplates = this.templates;
        });
        this._listenToUserTyping();
        this.currentUser$.pipe(filter(isNotNil)).subscribe((user) => (this.user = user));
        this._initScrollAtBottom();
        this.scrollDownEvent.pipe(takeUntil(this.killSubscriptions$)).subscribe((event) => {
            if (this._isListScrolledToBottom() || event?.isNewConversation) {
                this._initScrollAtBottom();
            } else {
                this.receivedNewMessage = true;
            }
        });
        this._listenToUserTyping();
        this.scrollDownEvent.subscribe(() => this._initScrollAtBottom());

        combineLatest([this.restaurant$, this._translate.onLangChange]).subscribe({
            next: ([restaurant, translate]) => {
                if (!restaurant) {
                    return;
                }
                this.restaurant = restaurant;
                this.templates$.subscribe((templates) => {
                    this.templates = templates.filter((t) => t.type === 'message');
                    this.filteredTemplates = this.templates;
                });
                this._initQuickText(restaurant, translate.lang);
            },
        });

        this.messageText.valueChanges
            .pipe(
                filter(() => !!this.currentCWM?.conversation),
                tap(() => {
                    const typing = true;
                    this._messagesService.changeTeamTypingStatus(
                        (this.currentCWM as ConversationWithMessages).conversation.socialConversationId,
                        this.user,
                        typing
                    );
                }),
                debounceTime(3000)
            )
            .subscribe((value) => {
                const typing = false;
                this._messagesService.changeTeamTypingStatus(
                    (this.currentCWM as ConversationWithMessages).conversation.socialConversationId,
                    this.user,
                    typing
                );
                this._store.dispatch({
                    type: MessagesActions.editConversationTypedText.type,
                    text: value,
                });
            });

        this.templateNameControl.valueChanges.subscribe((value) => {
            this.templateNameControl.setErrors({
                notEmpty: value?.length === 0,
                duplicate: !!this.templates.find((t) => t?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase()),
            });

            if (Object.values(this.templateNameControl.errors || {}).every((e) => !e)) {
                this.templateNameControl.setErrors(null);
            }
        });
    }

    onScroll(): void {
        const div = document.querySelector('#messages-list') as HTMLDivElement;
        if (div?.scrollTop === 0 && !this.loadingMore$.value) {
            if (this._reachedMaximumMessages()) {
                return;
            }
            this.loadingMore$.next(true);
            this.onScrollUp();
            div.scrollTo(0, 10);
        }
    }

    onScrollUp(): void {
        if (!this.currentCWM) {
            return;
        }
        this._messagesService.loadMoreMessages(this.currentCWM.conversation._id, this.pagination).subscribe({
            next: (res) => {
                this._store.dispatch({
                    type: MessagesActions.loadMoreMessages.type,
                    conversationWithMessages: {
                        conversation: this.currentCWM?.conversation,
                        messages: res.data.messages,
                    },
                });
                this.loadingMore$.next(false);
                this.pagination.pageNumber++;
                this.pagination.total = res.data.count;
            },
            error: (err) => {
                this.loadingMore$.next(false);
                this._toastService.openErrorToast(err.message);
            },
        });
    }

    leaveConversation(): void {
        this._store.dispatch({
            type: MessagesActions.changeCurrentConversation.type,
            conversationId: null,
        });
        this.conversationClosed.emit(true);
    }

    insertQuickText(quickText: QuickText): void {
        const messageText = this.messageText.value ?? '';
        const contentText = quickText.content ?? '';
        this.messageText.setValue([messageText, contentText].join(' '));
    }

    getDisplayName(): string {
        return this.currentCWM?.conversation.userInfo?.displayName || '';
    }

    selectTemplate(template: Template): void {
        const formattedTemplateText = this.formatDatabaseVariablesToTranslateText(template.text);
        const regexp = /(\W)@(\w+)/g;
        const matches: string[] = [];
        let match: RegExpExecArray | null;
        while ((match = regexp.exec(formattedTemplateText.split('@').join(' @')))) {
            matches.push(match[2]);
        }
        let text = formattedTemplateText;
        for (const m of matches) {
            const qt = this.quickTexts.find((q) => q?.displayKey?.startsWith(m));
            text = text.replace(`@${qt?.displayKey}`, ` ${qt?.content}`);
        }
        this.messageText.setValue(text);
    }

    sendMessage(): void {
        const baseMessage: Partial<Message> = {
            isFromRestaurant: true,
            key: this.currentCWM?.conversation.key,
            conversationId: this.currentCWM?.conversation._id,
            socialCreatedAt: new Date().toISOString(),
            socialConversationId: this.currentCWM?.conversation.socialConversationId,
            status: MessageStatus.PENDING,
            malouAuthorId: this.user._id,
            userInfo: this.currentCWM?.conversation.userInfo,
            templateIdUsed: this.selectedTemplateId() ?? undefined,
        };
        const messagesToSend: Partial<Message>[] = [];
        const messageTextValue = this.messageText.value;
        this._heapService.track(HeapEventName.MESSAGE_SENT, {
            restaurantId: this.restaurant.id,
            restaurant: `${this.restaurant.name} - ${this.restaurant.getFullFormattedAddress()}`,
            restaurantOrganisation: this.restaurant.organization?.name,
            conversation_id: this.currentCWM?.conversation._id,
            cameFromNotificationId: this._activatedRoute.snapshot.queryParams?.nid,
            notificationChannel: this._activatedRoute.snapshot.queryParams?.nchannel,
        });
        if (messageTextValue) {
            const tempMessageTextId = `latest-msg-${new Date()}-0`;
            const textMessage = {
                ...baseMessage,
                _id: tempMessageTextId,
                text: messageTextValue,
            };
            messagesToSend.push(textMessage);
            this._store.dispatch({ type: MessagesActions.sendNewMessage.type, message: textMessage });
            this._initScrollAtBottom();
        }
        if (this.attachments) {
            let index = 1;
            for (const attachment of this.attachments) {
                const tempMessageTextId = `latest-msg-${new Date()}-${index}`;
                index++;
                if (!attachment?.urls?.original) {
                    continue;
                }
                const attachmentMessage = {
                    ...baseMessage,
                    _id: tempMessageTextId,
                    attachments: [
                        {
                            url: attachment.urls.original,
                            type: attachment.type,
                            name: attachment.name,
                        },
                    ],
                };
                messagesToSend.push(attachmentMessage);
                this._store.dispatch({ type: MessagesActions.sendNewMessage.type, message: attachmentMessage });
                this._initScrollAtBottom();
            }
        }
        this.messageText.setValue('');
        this.messageText.clearValidators();
        this.attachments = [];
        this._sendMessages(messagesToSend);
        if (!this.hasTemplateErrors(messageTextValue)) {
            this._templatesService
                .create(
                    {
                        name: this.templateNameControl.value || undefined,
                        text: messageTextValue,
                        restaurantId: this.restaurant._id,
                        status: TemplateStatus.ACTIVE,
                        type: TemplateType.MESSAGE,
                    },
                    this.restaurant._id
                )
                .subscribe({
                    next: (res) => {
                        this._toastService.openSuccessToast(this._translate.instant('templates.created_success'));
                        this.templates = [...this.templates, res];
                    },
                    error: (err) => {
                        this._toastService.openErrorToast(err.message);
                    },
                });
            this.templateNameControl.setValue('');
            this.saveTemplate = false;
        }
    }

    hasTemplateErrors(text: string = this.messageText.value): boolean {
        return (
            !this.saveTemplate ||
            !text ||
            Object.values(this.templateNameControl?.errors ?? {})?.filter((e) => e).length > 0 ||
            !this.templateNameControl.value
        );
    }

    getTemplateErrorMessages = (templateControl: FormControl<string | null>): string =>
        templateControl.hasError('duplicate')
            ? this._translate.instant('messages.message_area.duplicate_template')
            : templateControl.hasError('notEmpty')
              ? this._translate.instant('common.required_field')
              : '';

    getTextErrorMessages(): string {
        return this.messageText.value?.length >= MAX_MESSAGE_LENGTH
            ? this._translate.instant('messages.message_area.message_too_long')
            : '';
    }

    retrySendMessage(message: Partial<Message>): void {
        this._store.dispatch({
            type: MessagesActions.editMessageAfterSend.type,
            message: { ...message, status: MessageStatus.PENDING },
            correctDbId: message._id,
        });
        this._sendMessages([message]);
    }

    toggleSaveTemplate(): void {
        this.saveTemplate = !this.saveTemplate;
        this.templateNameControl.setValue('');
    }

    onRemovedChip(): void {
        this.receivedNewMessage = false;
    }

    getTeamTypingDisplayName(): string {
        return this.teamTyping?.user?.name || '';
    }

    openMediaPicker(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                height: 'unset',
                width: '600px',
                data: {
                    multi: true,
                    restaurant: this.restaurant,
                    maxVideoSize: MESSAGE_MAX_VIDEO_SIZE,
                },
            })
            .afterClosed()
            .subscribe({
                next: (medias: Media[]) => {
                    if (medias) {
                        this.attachments = this.attachments.concat(
                            medias.map((media) => ({
                                urls: {
                                    original: media.getMediaUrl('original'),
                                    small: media.getMediaUrl('small'),
                                },
                                name: media.title,
                                type: media.type === 'photo' ? 'image' : 'video',
                                isUploadingOnS3: false,
                                randomId: null,
                                hasFinishedToLoadFromBrowser: false,
                            }))
                        );
                        this.sendMessage();
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    getMediaAttachments(attachments: MessageAttachmentUploading[]): MessageAttachmentUploading[] {
        return attachments?.filter((att) => att.type !== 'file');
    }

    onFileChange(event: Event): void {
        const files = (event.target as HTMLInputElement).files;
        if (!files) {
            return;
        }
        const filesArray = Array.from(files);
        const createAwsUrl$: Observable<any>[] = [];
        let errorsSize = 0;
        for (const file of filesArray) {
            if (file.size > MAX_FILE_SIZE) {
                errorsSize++;
                continue;
            }
            const randomId = uuid();
            let fileType = 'file';
            if (file.type.match(/image/)) {
                fileType = 'image';
            } else if (file.type.match(/video/)) {
                fileType = 'video';
            }
            this.attachments.push({
                urls: {
                    original: null,
                    small: URL.createObjectURL(file),
                },
                name: file.name,
                type: fileType,
                isUploadingOnS3: true,
                randomId,
                hasFinishedToLoadFromBrowser: false,
            });
            if (this.conversationChanged$?.value?.conversation._id) {
                createAwsUrl$.push(
                    this._mediaService
                        .uploadOnly('additional', file, {
                            entityId: this.conversationChanged$.value.conversation._id,
                            entityRelated: AppEntity.RESTAURANTS,
                        })
                        .pipe(
                            map((res) => {
                                const media = res.data;
                                return [media, randomId];
                            })
                        )
                );
            }
        }
        if (errorsSize > 0) {
            this._toastService.openErrorToast(this._translate.instant('messages.max_file_size_reached'));
        }
        forkJoin(createAwsUrl$).subscribe({
            next: (results) => {
                results.forEach(([media, randomId]) => {
                    const attachmentFound = this.attachments.find((attachment) => attachment.randomId === randomId);
                    if (attachmentFound) {
                        attachmentFound.urls.original = media.urls.original;
                        attachmentFound.isUploadingOnS3 = false;
                    }
                });
                this.sendMessage();
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._toastService.openErrorToast(this._clarifyError(err));
            },
        });
    }

    formatDatabaseVariablesToTranslateText(text: string): string {
        this._CHIPS_TEXT_TO_VARIABLE_IN_TEMPLATE.forEach((chip) => {
            text = text?.replace(new RegExp(this._escapeSpecialCharacters(chip.dbVariable), 'g'), chip.translate);
        });
        return text;
    }

    scrollBottom(): void {
        this.scrollDownEvent.emit({ isNewConversation: false });
    }

    toggleMoreButtons(): void {
        this.moreButtonsToggled = !this.moreButtonsToggled;
    }

    openTemplatesPickerModal(): void {
        this._customDialogService
            .open(TemplatesPickerModalComponent, {
                height: 'unset',
                width: '700px',
                data: {
                    templates: this.templates,
                },
            })
            .afterClosed()
            .subscribe({
                next: (template: Template) => {
                    if (template) {
                        this.selectTemplate(template);
                        this.selectedTemplateId.set(template._id);
                    }
                },
            });
    }

    private _escapeSpecialCharacters(text: string): string {
        return text?.replace('[{', '\\[\\{')?.replace('}]', '\\}\\]');
    }

    private _reachedMaximumMessages(): boolean {
        return this.pagination.pageNumber * this.pagination.pageSize >= this.pagination.total;
    }

    private _clarifyError(err: any): string {
        const message: string = err?.error?.message || err?.message || String(err);
        if (message.match(/HEVC/)) {
            return this._translate.instant('social_posts.new_social_post.unsupported_codec');
        }
        return message;
    }

    private _updateCurrentConversationWithMessages(): void {
        this.currentConversationWithMessages$.subscribe((cwm) => {
            this.currentCWM = cwm;
            if (cwm?.typedText) {
                this.messageText.setValue(cwm.typedText);
            }
            this.quickTexts = this.quickTexts.map((text) =>
                text.key === 'clientName' ? { ...text, content: this.getDisplayName() } : text
            );
        });
    }

    private _initScrollAtBottom(): void {
        setTimeout(() => {
            const messagesList = document.getElementById('messages-list');
            messagesList?.scrollTo({
                top: messagesList.scrollHeight,
                behavior: 'smooth',
            });
        }, 500);
    }

    private _listenToUserTyping(): void {
        combineLatest([this._messagesService.getIsUserTyping$(), this._messagesService.getIsTeamTyping$(), this.conversationChanged$])
            .pipe(
                map(([userTyping, teamTyping]) => ({
                    userTyping:
                        userTyping.conversationId !== this.currentCWM?.conversation.socialConversationId ? false : userTyping.typing,
                    teamTyping:
                        teamTyping.conversationId !== this.currentCWM?.conversation.socialConversationId ? { typing: false } : teamTyping,
                }))
            )
            .subscribe(({ userTyping, teamTyping }) => {
                this.isUserTyping = userTyping;
                this.teamTyping = teamTyping;
                if (this._isListScrolledToBottom()) {
                    this._initScrollAtBottom();
                }
                this.loadingMore$.next(false);
                this.pagination = { pageNumber: 1, pageSize: 10, total: Number.POSITIVE_INFINITY };
            });
    }

    private _sendMessages(messages: Partial<Message>[]): void {
        let errorMessage = '';
        forkJoin(
            messages.map((message) => {
                const correctMessage = message?._id?.includes('latest') ? omit(message, '_id') : message;
                return this._messagesService.sendMessage(correctMessage, this.restaurant._id).pipe(
                    catchError((err) => {
                        errorMessage = err;
                        return of({
                            ...message,
                            status: MessageStatus.ERROR,
                        });
                    })
                );
            })
        ).subscribe({
            next: (resultMessages) => {
                let alreadyShowedSnackBar = false;
                resultMessages.forEach((message, index) => {
                    const messageFound = messages[index]; // forkJoin keeps order of elements
                    if (message.status === MessageStatus.ERROR) {
                        this._store.dispatch({
                            type: MessagesActions.editMessageAfterSend.type,
                            message: new Message({ ...messageFound, status: MessageStatus.ERROR }),
                            correctDbId: message?._id,
                        });
                        if (!alreadyShowedSnackBar) {
                            this._openErrorToast(errorMessage);
                            alreadyShowedSnackBar = true;
                        }
                    } else {
                        this._store.dispatch({
                            type: MessagesActions.editMessageAfterSend.type,
                            message: new Message({ ...messageFound, status: MessageStatus.DELIVERED }),
                            correctDbId: message?._id,
                        });
                    }
                });
            },
            error: (err) => {
                messages.forEach((message) =>
                    this._store.dispatch({
                        type: MessagesActions.editMessageAfterSend.type,
                        message: new Message({ ...message, status: MessageStatus.ERROR }),
                    })
                );
                console.warn('err :>>', err);
                this._openErrorToast(err);
            },
        });
    }

    private _openErrorToast(err: any = null): void {
        const errorMessage = `${this._translate.instant('messages.an_error_occurred')}\n\n${this._httpErrorPipe.transform(err)}`;
        this._toastService.openErrorToast(errorMessage);
    }

    private _isListScrolledToBottom(): boolean {
        const messagesList = document.getElementById('messages-list');
        if (!messagesList) {
            return true;
        }
        return messagesList.scrollTop + messagesList.clientHeight >= messagesList.scrollHeight;
    }

    private _initQuickText(restaurant: Restaurant, lang: string | null = null): void {
        const currentLang = lang || LocalStorage.getLang();

        this.quickTexts = this.quickTexts
            .filter((quickText) =>
                this.restaurant.isBrandBusiness() ? !['address', 'regularHours', 'menuUrl'].includes(quickText.key) : true
            )
            .map((quickText) => ({
                ...quickText,
                displayKey: this._translate.instant('messages.message_area')[quickText.key],
                content: this._getQuickTextContent(restaurant, quickText.key, currentLang),
            }))
            .filter((quickText) => quickText.content !== '' && quickText.content !== null);
    }

    private _getQuickTextContent(restaurant: Restaurant, key: string, currentLang: string): string {
        switch (key) {
            case 'clientName':
                return this.getDisplayName();
            case 'regularHours':
                return this._hoursToTextService.generateTextFromHours(restaurant.regularHours, currentLang);
            case 'address':
                return `${restaurant.address?.formattedAddress}, ${restaurant.address?.locality}`;
            default:
                return restaurant[key];
        }
    }
}
