import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { PlatformKey, PostPublicationStatus, PostSource, SocialPostsListFilter } from '@malou-io/package-utils';

import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { DialogService } from ':core/services/dialog.service';
import { CreateSocialPostMenuButtonComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list-header/create-social-post-menu-button/create-social-post-menu-button.component';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-social-posts-list-header',
    templateUrl: './social-posts-list-header.component.html',
    styleUrls: ['./social-posts-list-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        CreateSocialPostMenuButtonComponent,
        AsyncPipe,
        EnumTranslatePipe,
    ],
})
export class SocialPostsListHeaderComponent {
    readonly selectedFilter = model.required<SocialPostsListFilter>();
    readonly restaurant = input.required<Restaurant | null>();
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();
    readonly duplicateToOtherRestaurants = output<{ postIds: string[]; postDestination: PostSource }>();
    readonly duplicateToSeoHere = output<string[]>();

    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _translateService = inject(TranslateService);
    private readonly _dialogService = inject(DialogService);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);
    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;
    readonly SocialPostsListFilter = SocialPostsListFilter;
    readonly DuplicationDestination = DuplicationDestination;
    readonly PostSource = PostSource;

    readonly filterOptionsAndCount = signal<{ filterOption: SocialPostsListFilter; count: number | null }[]>([]);

    readonly atLeastOnePostInList = computed(() => this._socialPostsContext.sortedPosts().length > 0);
    readonly isSelecting = this._socialPostsContext.isSelecting;
    readonly selectedPosts = this._socialPostsContext.postSelection.getSelectionAsSignal();

    readonly deletePostsTooltip = computed(() => {
        const selectedPosts = this.selectedPosts();

        if (selectedPosts.length === 0) {
            return this._translateService.instant('social_posts.header.select_post_to_delete_bulk');
        }

        const deletablePosts = selectedPosts.filter(
            (post) =>
                post.published !== PostPublicationStatus.PUBLISHED ||
                (post.platformKeys.length === 1 && post.platformKeys[0] === PlatformKey.FACEBOOK)
        );

        return deletablePosts.length === selectedPosts.length
            ? ''
            : this._translateService.instant('social_posts.header.delete_bulk_not_allowed');
    });
    readonly canDeletePosts = computed(() => this.deletePostsTooltip() === '');

    readonly isGoogleConnected$ = this._store
        .select(selectPermissionsState)
        .pipe(
            map((permissionsState) => permissionsState.data.some((permission) => permission.key === PlatformKey.GMB && permission.isValid))
        );

    constructor() {
        effect(() => {
            const restaurant = this.restaurant();
            if (restaurant) {
                this._setFilterOptionsCount(restaurant._id);
            }
        });

        this._socialPostsContext.shouldFetchFilterOptionsCount$.subscribe(() => {
            const restaurant = this.restaurant();
            if (restaurant) {
                this._setFilterOptionsCount(restaurant._id);
            }
        });
    }

    selectFilter(filterOption: SocialPostsListFilter): void {
        this.selectedFilter.set(filterOption);
    }

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }

    setIsSelecting(isSelecting: boolean): void {
        this._socialPostsContext.setIsSelecting(isSelecting);
        if (!isSelecting) {
            this._socialPostsContext.postSelection.unselectAll();
        }
    }

    onDuplicateSelection(destination: DuplicationDestination, postDestination: PostSource): void {
        if (destination === DuplicationDestination.HERE) {
            const selectedPosts = this.selectedPosts();
            if (postDestination === PostSource.SEO) {
                this.duplicateToSeoHere.emit(selectedPosts.map((post) => post.id));
            } else {
                const restaurantId = this._socialPostsContext.restaurant().id;
                this._socialPostsContext.duplicatePosts({
                    postIds: selectedPosts.map((post) => post.id),
                    restaurantIds: [restaurantId],
                    postDestination,
                });
            }
        } else {
            this.duplicateToOtherRestaurants.emit({
                postIds: this.selectedPosts().map((post) => post.id),
                postDestination,
            });
        }
    }

    onDeleteSelection(): void {
        const selectedPosts = this.selectedPosts();
        const atLeastOnePostIsPublishedOnFacebook = selectedPosts.some(
            (post) =>
                post.published === PostPublicationStatus.PUBLISHED &&
                post.platformKeys.length === 1 &&
                post.platformKeys[0] === PlatformKey.FACEBOOK
        );

        this._dialogService.open({
            title: this._pluralTranslatePipe.transform('social_post.delete_confirmation_modal.title', selectedPosts.length),
            message: atLeastOnePostIsPublishedOnFacebook
                ? this._pluralTranslatePipe.transform(
                      'social_post.delete_confirmation_modal.message_published_on_facebook',
                      selectedPosts.length
                  )
                : this._pluralTranslatePipe.transform('social_post.delete_confirmation_modal.message', selectedPosts.length),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translateService.instant('common.confirm'),
                action: () => {
                    this._socialPostsContext.deleteSocialPosts(selectedPosts.map((post) => post.id));
                },
            },
            secondaryButton: {
                label: this._translateService.instant('common.cancel'),
            },
        });
    }

    private _setFilterOptionsCount(restaurantId: string): void {
        this._socialPostsService.getSocialPostsCountByFilterOptions$(restaurantId).subscribe((filterOptionsCount) => {
            const filterOptionsAndCountWithoutZero = filterOptionsCount.filter((filterOptionCount) => filterOptionCount.count !== 0);
            this.filterOptionsAndCount.set(filterOptionsAndCountWithoutZero);
        });
    }
}
