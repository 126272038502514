import { z } from 'zod';

const descriptionValidator = z.object({
    title: z.string(),
    contents: z.array(
        z.object({
            subtitle: z.string(),
            description: z.string(),
        })
    ),
    imageUrl: z.string(),
});

export const storeLocatorStorePageDescriptionsBlockValidator = z.array(descriptionValidator);
