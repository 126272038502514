@if (searchImpressionInsights().length) {
    <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="emptyState"></ng-container>
}

<ng-template #tableTemplate>
    <mat-table class="malou-mat-table" [dataSource]="dataSource()" #table="matTable">
        <ng-container [matColumnDef]="TableColumn.KEYWORD">
            <mat-header-cell *matHeaderCellDef>
                {{ 'aggregated_statistics.seo.top_keyword_search_impression.table.keyword' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="flex !max-w-[300px] items-center gap-4">
                    <div>
                        <img class="!w-[22px]" [src]="getEmojiForKeyword | applyPure: row.keywordSearch" />
                    </div>
                    <span
                        class="malou-text-13--semibold malou-color-text-1 max-w-[260px] truncate text-ellipsis"
                        [matTooltip]="row.keywordSearch">
                        {{ row.keywordSearch }}
                    </span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableColumn.AVG_APPARITIONS">
            <mat-header-cell *matHeaderCellDef>
                {{ 'aggregated_statistics.seo.top_keyword_search_impression.table.average_apparitions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <div class="malou-text-13--regular malou-color-text-1 flex items-center gap-2">
                    <span>{{ row.avgValue }} </span>
                    @if (row.evolution) {
                        <app-number-evolution
                            size="xs"
                            [value]="row.evolution"
                            [displayedValue]="row.evolution | shortNumber: { shouldDisplayMinusSign: false }">
                        </app-number-evolution>
                    } @else {
                        <span
                            [matTooltip]="
                                'aggregated_statistics.seo.top_keyword_search_impression.table.average_apparitions_tooltip' | translate
                            ">
                            -
                        </span>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableColumn.RESTAURANTS">
            <mat-header-cell *matHeaderCellDef>
                {{ 'aggregated_statistics.seo.top_keyword_search_impression.table.businesses' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                @if (shouldHideTableClickableElements()) {
                    <span class="malou-text-13--regular malou-color-text-1">
                        {{
                            'aggregated_statistics.seo.top_keyword_search_impression.table.n_businesses'
                                | translate: { count: row.restaurants.length }
                        }}
                    </span>
                } @else {
                    <button class="malou-btn-flat btn-sm !px-0" mat-button (click)="openSearchImpressionsModal(row)">
                        {{ 'common.see' | translate }} ({{ row.restaurants.length }})
                    </button>
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table; let i = index"></mat-row>
    </mat-table>
</ng-template>

<ng-template #emptyState>
    <div class="malou-simple-card flex !h-[400px] flex-1 flex-col">
        <div class="flex flex-1 flex-col items-center justify-center">
            <img class="mb-6 h-50" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
            <div class="malou-text-14--bold malou-color-text-1 mb-2">
                {{ 'statistics.seo.keyword_search_impressions.no_results.title' | translate }}
            </div>
            <div class="malou-text-10--regular malou-color-text-2">
                {{ 'statistics.seo.keyword_search_impressions.no_results.text' | translate }}
            </div>
        </div>
    </div>
</ng-template>
