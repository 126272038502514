import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import lodash from 'lodash';
import { take } from 'rxjs';

import {
    InformationUpdateErrorReason,
    InformationUpdatesStatus,
    InformationUpdateStateError,
    informationUpdateSupportedKeyMap,
    InformationUpdateSupportedPlatformKey,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

import { ChatbotService } from ':core/services/chatbot.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { InformationUpdatesStateService } from ':modules/informations/informations-updates-state/information-updates-state.service';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';

@Component({
    selector: 'app-information-update-detail-error',
    standalone: true,
    imports: [NgTemplateOutlet, TranslateModule, IncludesPipe, PlatformNamePipe, ApplyPurePipe],
    templateUrl: './information-update-detail-error.component.html',
    styleUrl: './information-update-detail-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationUpdateDetailErrorComponent implements OnInit {
    readonly InformationUpdatesStatus = InformationUpdatesStatus;
    readonly InformationUpdateErrorReason = InformationUpdateErrorReason;
    readonly informationUpdateSupportedKeyMap = informationUpdateSupportedKeyMap;
    readonly platformAccessStatus = input.required<InformationUpdatesStatus>();
    readonly platformKey = input.required<InformationUpdateSupportedPlatformKey>();
    readonly errors = input<InformationUpdateStateError[]>([]);
    readonly close = output<void>();

    private readonly _router = inject(Router);
    private readonly _currentRestaurant = inject(RestaurantsService).restaurantSelected$;
    private readonly _informationUpdatesStateService = inject(InformationUpdatesStateService);
    private readonly _chatbotService = inject(ChatbotService);
    private readonly _store = inject(Store);

    readonly facebookPlatform$ = this._store.select(selectCurrentPlatform({ platformKey: PlatformKey.FACEBOOK })).pipe(take(1));
    private _fbSocialId: string;

    ngOnInit(): void {
        this.facebookPlatform$.subscribe({
            next: (platform) => {
                if (platform) {
                    this._fbSocialId = platform.socialId;
                }
            },
        });
    }

    goToPlatforms(): void {
        this.close.emit();
        this._router.navigate([`/restaurants/${this._currentRestaurant.value!._id}/settings/platforms/connection`]);
    }

    goToGmbProfile(): void {
        window.open('https://business.google.com/locations', '_blank');
    }

    formattedErrors(): { message: string; shouldConnectToGmb: boolean; shouldConnectToFacebook: boolean }[] {
        const errors = this.errors().map((error) => ({
            message: this._informationUpdatesStateService.displayInformationUpdatesStateError(error),
            shouldConnectToGmb: this._shouldConnectToGmb(error),
            shouldConnectToFacebook: this._shouldConnectToFacebook(error),
        }));

        return lodash.uniqBy(errors, 'message');
    }

    private _shouldConnectToGmb(error: InformationUpdateStateError): boolean {
        return [
            InformationUpdateErrorReason.LAT_LNG_UPDATES_NOT_PERMITTED,
            InformationUpdateErrorReason.PIN_DROP_REQUIRED,
            InformationUpdateErrorReason.STALE_DATA,
            InformationUpdateErrorReason.GMB_PLATFORM_DISCONNECTED,
            InformationUpdateErrorReason.GMB_PLATFORM_DISABLED,
            InformationUpdateErrorReason.GMB_PLATFORM_SUSPENDED,
            InformationUpdateErrorReason.GMB_PLATFORM_PENDING_VERIFICATION,
            InformationUpdateErrorReason.GMB_PLATFORM_NOT_VERIFIED,
        ].includes(error.reason);
    }

    private _shouldConnectToFacebook(error: InformationUpdateStateError): boolean {
        return [
            InformationUpdateErrorReason.FB_PASSWORD_CHANGED,
            InformationUpdateErrorReason.FB_LOGIN_AND_FOLLOW_INSTRUCTIONS,
            InformationUpdateErrorReason.FB_NOT_CONFIRMED_USER,
            InformationUpdateErrorReason.FB_USER_NEED_TO_BE_ADMIN_OR_MODERATOR_OR_EDITOR,
            InformationUpdateErrorReason.FB_UNABLE_DOWNLOAD_PHOTOS,
        ].includes(error.reason);
    }

    goToFbProfile(): void {
        const accessLink = PlatformDefinitions.getPlatformDefinition(PlatformKey.FACEBOOK)?.accessLink;

        if (!accessLink || !this._fbSocialId) {
            return;
        }

        const link = accessLink(this._fbSocialId);
        window.open(link, '_blank');
    }

    contactSupport(): void {
        this.close.emit();
        this._chatbotService.show();
    }

    getErrorMessage = (error: InformationUpdateStateError): string =>
        this._informationUpdatesStateService.displayInformationUpdatesStateError(error);
}
