import {
    FeedbackMessageParticipantType,
    FeedbackMessageType,
    FeedbackMessageVisibility,
    Role,
    SocialAttachmentsMediaTypes,
} from '../../constants';

export enum SocialPostsListFilter {
    ALL = 'all',
    DRAFT = 'draft',
    ERROR = 'error',
    FEEDBACK = 'feedback',
}

export enum CallToActionType {
    BOOK = 'BOOK',
    ORDER = 'ORDER',
    SHOP = 'SHOP',
    LEARN_MORE = 'LEARN_MORE',
    SIGN_UP = 'SIGN_UP',
    CALL = 'CALL',
    NONE = 'NONE',
}

export enum MapstrCtaButtonType {
    SEE = 'SEE',
    BOOK = 'BOOK',
    ORDER = 'ORDER',
    MENU = 'MENU',
    JOIN = 'JOIN',
    MORE = 'MORE',
}

export const postCallToActionTypeValues = Array.from(new Set([...Object.values(MapstrCtaButtonType), ...Object.values(CallToActionType)]));
export type PostCallToActionType = (typeof postCallToActionTypeValues)[number];
export interface PostCallToAction {
    actionType: PostCallToActionType;
    url: string;
}

export interface SocialPostCallToAction {
    actionType: MapstrCtaButtonType;
    url: string;
}

export interface SeoPostCallToAction {
    actionType: CallToActionType;
    url: string;
}

export const callToActionTypesWithDefaultUrls = [CallToActionType.CALL, CallToActionType.BOOK, CallToActionType.ORDER];

export enum PostSource {
    SOCIAL = 'social',
    SEO = 'seo',
}

export interface PostSocialAttachment {
    urls: {
        original: string;
    };
    socialId?: string;
    type: SocialAttachmentsMediaTypes;
    thumbnailUrl?: string;
}

export interface PostLocation {
    id: string;
    name: string;
    link: string;
    location?: {
        city?: string;
        country?: string;
        latitude: number;
        longitude: number;
        street?: string;
        zip?: string;
    };
}

export interface PostErrorData {
    code: string;
    rawData: string;
}

export interface PostFeedbacks {
    id: string;
    isOpen: boolean;
    participants: {
        participant: PostFeedbacksParticipant;
        types: FeedbackMessageParticipantType[];
    }[];
    feedbackMessages: PostFeedbackMessage[];
    updatedAt: Date;
    createdAt: Date;
}

export interface PostFeedbacksParticipant {
    id: string;
    name: string;
    role?: Role;
    email: string;
    userId?: string;
    lastname?: string;
}

export interface PostFeedbacksAuthor {
    id: string;
    name: string;
    lastname: string;
    email: string;
    role: Role;
    userId?: string;
    profilePictureUrl?: string;
}

export interface PostFeedbackMessage {
    id: string;
    author: PostFeedbacksAuthor;
    createdAt: Date;
    updatedAt: Date;
    text?: string;
    type: FeedbackMessageType;
    visibility: FeedbackMessageVisibility;
    publishedAt?: Date;
    lastUpdatedAt?: Date;
}

export interface PostUserTag {
    username: string;
    x: number;
    y: number;
}

export interface IGAccount {
    username: string;
    followers_count: number;
    profile_picture_url?: string;
    name?: string;
    media_count?: number;
    biography?: string;
}

export enum PublicationErrorCode {
    CONNECTION_EXPIRED = 'CONNECTION_EXPIRED',
    USER_MISSING_APPROPRIATE_ROLE = 'USER_MISSING_APPROPRIATE_ROLE',
    USER_NEEDS_TO_LOG_IN = 'USER_NEEDS_TO_LOG_IN',
    USER_NEEDS_TO_LOG_IN_TO_IG_APP = 'USER_NEEDS_TO_LOG_IN_TO_IG_APP',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

// Special case, we whitelist the "Very French Beans" page because it does not contain location
const VERYFRENCHBEANS_PAGE_ID = '100270695184218';
export const WHITELISTED_PAGE_IDS = [VERYFRENCHBEANS_PAGE_ID];
