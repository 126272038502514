import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { Observable, take } from 'rxjs';

import { KeywordStatsV3Dto } from '@malou-io/package-dto';

import { selectKeywords } from ':modules/statistics/store/statistics.selectors';
import { Keyword, KeywordImpression } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    stats: KeywordStatsV3Dto[];
    keywords: Keyword[];
}

@Injectable({ providedIn: 'root' })
export class KeywordsCsvInsightsServiceV2 extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected override _getData$(): Observable<Data> {
        return this._store.select(selectKeywords).pipe(take(1));
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        const competitorHeaders = Array(20)
            .fill(0)
            .map((_, i) => [`Competitor ${i + 1}`, `Competitor ${i + 1} Address`])
            .flat();
        return ['Date', 'Keyword', 'Google Ranking', 'Appearances', 'Evolution of Appearances', ...competitorHeaders];
    }

    protected override _getCsvDataRows({ keywords, stats }: Data): CsvAsStringArrays {
        return keywords
            .filter((keyword) => keyword.selected)
            .flatMap((keyword) => {
                const keywordStats = stats.find((ks) => ks.keywordId === keyword.keywordId);
                if (!keywordStats) {
                    return [];
                }

                // FIXME: not great (we should display the competitor list evolution) but let’s improve this later
                const competitors: string[] = [...Array(20)].flatMap((_, i) => {
                    const competitor = keywordStats.localCompetitorsPodium[i];
                    if (!competitor) {
                        return ['', ''];
                    }
                    return [competitor.name, competitor.address];
                });

                return sortBy(keywordStats.rankHistory, (p) => p.fetchDate).map((point): string[] => {
                    const date = new Date(point.fetchDate).toLocaleDateString();

                    const { impressions, impressionsEvolution } = this._getImpressionsHistoryFromDate(
                        keyword.impressionsHistory,
                        new Date(point.fetchDate)
                    );
                    const rank = (point.rank ?? point.outOf).toString();
                    return [date, keyword.text, rank, impressions, impressionsEvolution, ...competitors];
                });
            });
    }

    private _getImpressionsHistoryFromDate(
        impressionsHistory: KeywordImpression[],
        date: Date
    ): { impressions: string; impressionsEvolution: string } {
        const monthImpressionLuxonDate = DateTime.fromJSDate(date).startOf('month');
        const previousMonthLuxonDate = monthImpressionLuxonDate.minus({ month: 1 });

        let value: number | undefined;
        let previousValue: number | undefined;

        for (const impression of impressionsHistory) {
            const impressionDate = DateTime.fromJSDate(impression.date).startOf('month');
            if (impressionDate.hasSame(monthImpressionLuxonDate, 'month')) {
                value = impression.value;
            }
            if (impressionDate.hasSame(previousMonthLuxonDate, 'month')) {
                previousValue = impression.value;
            }
        }

        if (value === undefined) {
            return {
                impressions: '-',
                impressionsEvolution: '-',
            };
        }

        const evolutionInPercentage = previousValue ? ((value - previousValue) / previousValue) * 100 : null;
        return {
            impressions: value?.toString() ?? '-',
            impressionsEvolution: evolutionInPercentage ? `${Math.round(evolutionInPercentage)} %` : '-',
        };
    }
}
