import { computed, DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { patchState, signalState } from '@ngrx/signals';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, catchError, combineLatest, EMPTY, map, Observable, takeUntil, tap } from 'rxjs';

import { ReelThumbnail } from '@malou-io/package-dto';
import {
    MapstrCtaButtonType,
    MediaType,
    PlatformKey,
    PostFeedbacks,
    PostPublicationStatus,
    PostType,
    TiktokPrivacyStatus,
    WHITELISTED_PAGE_IDS,
} from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SubmitPublicationStatus } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { UpsertSocialPostState } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post.interface';
import { UpsertSocialPostService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post.service';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { FbLocation, Hashtag, Platform } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpsertSocialPostContext {
    private readonly _credentialService = inject(CredentialsService);
    private readonly _upsertSocialPostService = inject(UpsertSocialPostService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _postsService = inject(PostsService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly upsertSocialPostState = signalState<UpsertSocialPostState>(this._getInitialState(null));
    readonly initialPost: WritableSignal<UpsertSocialPost | null> = signal(null);

    readonly postErrors = computed(() =>
        this._upsertSocialPostService.getPostErrors(this.upsertSocialPostState.post(), this.submitPublicationStatus())
    );

    readonly hasErrorOccuredDuringInit = signal(false);

    readonly closeModal$ = new BehaviorSubject<boolean>(false);

    readonly submitPublicationStatus = signal<SubmitPublicationStatus>(SubmitPublicationStatus.SCHEDULE);

    private readonly _disconnectedPlatforms$: BehaviorSubject<Platform[]> = new BehaviorSubject([]);
    private readonly _resetSubscription$ = new BehaviorSubject<void>(undefined);

    init(
        postId: string | undefined,
        disconnectedPlatforms$: BehaviorSubject<Platform[]>,
        options?: { date?: Date; isReel?: boolean }
    ): void {
        this.initialPost.set(null);
        patchState(this.upsertSocialPostState, this._getInitialState(null));

        this._setIsLoadingPost(true);
        this.hasErrorOccuredDuringInit.set(false);

        const currentRestaurantId = this._restaurantsService.currentRestaurant._id;

        this._resetSubscription$.next();
        this._disconnectedPlatforms$.next(disconnectedPlatforms$.getValue());
        disconnectedPlatforms$.pipe(takeUntil(this._resetSubscription$)).subscribe((disconnectedPlatforms) => {
            this._disconnectedPlatforms$.next(disconnectedPlatforms);
        });

        (postId
            ? this._upsertSocialPostService.getPost$(postId)
            : this._upsertSocialPostService.createPost$(currentRestaurantId, options)
        ).subscribe({
            next: (post) => {
                if (
                    post?.published === PostPublicationStatus.PUBLISHED ||
                    (post?.published === PostPublicationStatus.PENDING && post?.isPublishing)
                ) {
                    return this.closeModal$.next(true);
                }
                if (post && postId) {
                    this.initialPost.set(post);
                } else {
                    this.initialPost.set(null);
                }
                patchState(this.upsertSocialPostState, this._getInitialState(post));

                this._initConnectedSocialPlatformsAndLocation({
                    isNewSocialPost: !postId,
                    isReel: !!options?.isReel || (!!post && post.postType === PostType.REEL),
                });
                this._initUserTagsHistory(currentRestaurantId);

                if (this._experimentationService.isFeatureEnabled('release-tiktok-platform')) {
                    this._initTiktokCreatorInfo();
                }

                this._setIsLoadingPost(false);
            },
            error: (error) => {
                console.error(error);
                this.hasErrorOccuredDuringInit.set(true);
                this._setIsLoadingPost(false);
            },
        });
    }

    autosave(post: IUpsertSocialPost): void {
        this._setIsAutoSaving(true);
        this._updatePost$(post, SubmitPublicationStatus.DRAFT)
            .pipe(catchError((error) => this._handleError(error)))
            .subscribe(() => {
                this._setIsAutoSaving(false);
            });
    }

    savePost$(post: IUpsertSocialPost, submitPublicationStatus: SubmitPublicationStatus): Observable<UpsertSocialPost> {
        this._setIsSubmitting(true);
        return this._updatePost$(post, submitPublicationStatus).pipe(tap(() => this._setIsSubmitting(false)));
    }

    deletePost$(): Observable<null> {
        const postId = this.upsertSocialPostState.post.id();
        this._setIsSubmitting(true);
        return this._upsertSocialPostService.deletePost$(postId).pipe(
            tap(() => this._setIsSubmitting(false)),
            map(() => null)
        );
    }

    selectPlatforms(platformKeys: PlatformKey[]): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, platformKeys } }));
    }

    selectSeoPlatforms(platformKeys: PlatformKey[]): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, duplicateToPlatforms: platformKeys }));
    }

    updateTitle(title: string): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, title } }));
    }

    updateCaption(caption: string): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, text: caption } }));
    }

    updateLocation(location: FbLocation | null): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, location } }));
    }

    updateSelectedHashtags(selectedHashtags: Hashtag[]): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, hashtags: { ...state.post.hashtags, selected: selectedHashtags } },
        }));
    }

    updateSuggestedHashtags(suggestedHashtags: Hashtag[]): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, hashtags: { ...state.post.hashtags, suggested: suggestedHashtags } },
        }));
    }

    updateCtaActionType(actionType: MapstrCtaButtonType | null): void {
        if (!actionType) {
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                post: { ...state.post, callToAction: undefined },
            }));
        } else {
            patchState(this.upsertSocialPostState, (state) => ({
                ...state,
                post: { ...state.post, callToAction: { ...(state.post.callToAction ?? { url: '' }), actionType } },
            }));
        }
    }

    updateCtaUrl(url: string): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, callToAction: { ...(state.post.callToAction ?? { actionType: MapstrCtaButtonType.SEE }), url } },
        }));
    }

    updateFeedbacks(feedbacks: null | PostFeedbacks): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, feedbacks } }));
    }

    updatePlannedPublicationDate(date: Date): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, plannedPublicationDate: date } }));
    }

    updateMedias(medias: EditionMedia[]): void {
        const postType = this._getPostType(medias);
        const userTagsListReset: IUpsertSocialPost['userTagsList'] = medias.map(() => []);

        const currentUserTagsLists = this.upsertSocialPostState.post.userTagsList();
        const currentMedias = this.upsertSocialPostState.post.attachments();
        if (currentUserTagsLists.length === currentMedias.length) {
            for (const [index, currentMedia] of currentMedias.entries()) {
                const newIndex = medias.findIndex((media) => media.id === currentMedia.id);
                if (newIndex !== -1) {
                    userTagsListReset[newIndex] = currentUserTagsLists[index];
                }
            }
        }

        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                attachments: medias,
                postType,
                userTagsList: userTagsListReset,
                reelThumbnail: medias.length ? state.post.reelThumbnail : undefined,
            },
        }));
    }

    updateReelThumbnail(reelThumbnail: ReelThumbnail): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: { ...state.post, reelThumbnail },
        }));
    }

    addUserTag(index: number, userTag: { x: number; y: number; username: string }): void {
        const userTagsList = [...this.upsertSocialPostState.post.userTagsList()];
        if (userTagsList[index]) {
            userTagsList[index]!.push(userTag);
        } else {
            userTagsList[index] = [userTag];
        }

        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, userTagsList } }));
    }

    updateUserTagsForReel(userTags: { x: number; y: number; username: string }[]): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, userTagsList: [userTags] } }));
    }

    removeUserTag(index: number, username: string): void {
        const userTagsList = [...this.upsertSocialPostState.post.userTagsList()];
        if (userTagsList[index]) {
            userTagsList[index] = userTagsList[index]!.filter((userTag) => userTag.username !== username);
            patchState(this.upsertSocialPostState, (state) => ({ ...state, post: { ...state.post, userTagsList } }));
        }
    }

    updateTiktokPrivacyStatus(privacyStatus: TiktokPrivacyStatus): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    privacyStatus,
                },
            },
        }));
    }

    updateTiktokOptionsComment(comment: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    interactionAbility: {
                        ...state.post.tiktokOptions.interactionAbility,
                        comment,
                    },
                },
            },
        }));
    }

    updateTiktokOptionsDuet(duet: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    interactionAbility: { ...state.post.tiktokOptions.interactionAbility, duet },
                },
            },
        }));
    }

    updateTiktokOptionsStitch(stitch: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    interactionAbility: {
                        ...state.post.tiktokOptions.interactionAbility,
                        stitch,
                    },
                },
            },
        }));
    }

    updateTiktokOptionsBrandedContent(brandedContent: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    contentDisclosureSettings: {
                        ...state.post.tiktokOptions.contentDisclosureSettings,
                        brandedContent,
                    },
                },
            },
        }));
    }

    updateTiktokOptionsYourBrand(yourBrand: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    contentDisclosureSettings: {
                        ...state.post.tiktokOptions.contentDisclosureSettings,
                        yourBrand,
                    },
                },
            },
        }));
    }

    updateTiktokOptionsIsActivated(isActivated: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({
            ...state,
            post: {
                ...state.post,
                tiktokOptions: {
                    ...state.post.tiktokOptions,
                    contentDisclosureSettings: {
                        ...state.post.tiktokOptions.contentDisclosureSettings,
                        isActivated,
                    },
                },
            },
        }));
    }

    updatePost(post: UpsertSocialPost): void {
        patchState(this.upsertSocialPostState, this._getInitialState(post));
        this._initConnectedSocialPlatformsAndLocation({
            isNewSocialPost: false,
            isReel: post.postType === PostType.REEL,
        });
    }

    private _getInitialState(initialPost: UpsertSocialPost | null): UpsertSocialPostState {
        return cloneDeep({
            post: initialPost ? initialPost.toInterface() : UpsertSocialPost.create().toInterface(),
            isAutoSaving: false,
            isSubmitting: false,
            isLoadingPost: false,
            isLoadingLocation: false,
            duplicateToPlatforms: [],
            connectedSocialPlatforms: [],
            userTagsHistory: [],
            tiktokCreatorInfo: {
                username: '',
                privacyStatusValues: Object.values(TiktokPrivacyStatus),
                isCommentDisabled: false,
                isDuetDisabled: false,
                isStitchDisabled: false,
            },
        });
    }

    private _initUserTagsHistory(restaurantId: string): void {
        this._upsertSocialPostService.getUserTagsHistory$(restaurantId).subscribe((result) => {
            patchState(this.upsertSocialPostState, (state) => ({ ...state, userTagsHistory: result.data }));
        });
    }

    private _initConnectedSocialPlatformsAndLocation(options: { isNewSocialPost: boolean; isReel: boolean }): void {
        const getConnectedPlatforms$ = options.isReel
            ? this._upsertSocialPostService.getConnectedReelPlatforms$()
            : this._upsertSocialPostService.getConnectedSocialPlatforms$();

        combineLatest([this._disconnectedPlatforms$, getConnectedPlatforms$])
            .pipe(
                map(([disconnectedPlatforms, connectePlatforms]) =>
                    connectePlatforms.filter((platform) => !disconnectedPlatforms.some((p) => p.key === platform.key))
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((platforms) => {
                const connectedPlatformKeys = platforms.map((platform) => platform.key);
                patchState(this.upsertSocialPostState, (state) => ({
                    ...state,
                    connectedSocialPlatforms: platforms,
                }));
                if (options.isNewSocialPost) {
                    this.selectPlatforms(connectedPlatformKeys);

                    // If Facebook is connected, we initialize the location with the one specified in the Facebook page
                    const fbPlatform = platforms.find((platform) => platform.key === PlatformKey.FACEBOOK);
                    if (fbPlatform) {
                        this._initLocation(fbPlatform);
                    }
                }
            });
    }

    private _initLocation(fbPlatform: Platform): void {
        this._setIsLoadingLocation(true);

        // Special case, we whitelist the "Very French Beans" page because it does not contain location
        this._credentialService
            .pagesSearch(fbPlatform.name, {
                onlyWithLocation: true,
                whitelistedPageIds: WHITELISTED_PAGE_IDS,
            })
            .pipe(
                catchError((error) => {
                    this._setIsLoadingLocation(false);
                    return this._handleError(error);
                })
            )
            .subscribe((res) => {
                const locations = res.data;
                const currentLocation = locations.find((l) => l.id === fbPlatform.socialId) || null;

                if (currentLocation) {
                    this.updateLocation(currentLocation);
                }
                this._setIsLoadingLocation(false);
            });
    }

    private _updatePost$(post: IUpsertSocialPost, submitPublicationStatus: SubmitPublicationStatus): Observable<UpsertSocialPost> {
        const postToUpdate = this._getPostToUpdate(post, submitPublicationStatus);
        return this._upsertSocialPostService.updatePost$(postToUpdate).pipe(catchError((error) => this._handleError(error)));
    }

    private _setIsAutoSaving(isAutoSaving: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isAutoSaving }));
    }

    private _setIsSubmitting(isSubmitting: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isSubmitting }));
    }

    private _setIsLoadingPost(isLoadingPost: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isLoadingPost }));
    }

    private _setIsLoadingLocation(isLoadingLocation: boolean): void {
        patchState(this.upsertSocialPostState, (state) => ({ ...state, isLoadingLocation }));
    }

    private _handleError(_error: unknown): Observable<never> {
        // TODO handle error with toast and error mapping
        return EMPTY;
    }

    private _getPostToUpdate(post: IUpsertSocialPost, submitPublicationStatus: SubmitPublicationStatus): IUpsertSocialPost {
        switch (submitPublicationStatus) {
            case SubmitPublicationStatus.DRAFT:
                return { ...post, published: PostPublicationStatus.DRAFT };
            case SubmitPublicationStatus.SCHEDULE:
                return { ...post, published: PostPublicationStatus.PENDING };
            case SubmitPublicationStatus.NOW:
                return {
                    ...post,
                    published: PostPublicationStatus.PENDING,
                    plannedPublicationDate: DateTime.now().toJSDate(), // We used to add +1 minute to the date, but it has been removed to avoid confusion
                    isPublishing: true, // We anticipate the isPublishing state for UX reasons
                };
        }
    }

    private _getPostType(medias: EditionMedia[]): PostType {
        const isReel = this.upsertSocialPostState.post.postType() === PostType.REEL;
        if (isReel) {
            return PostType.REEL;
        }
        if (medias.length > 1) {
            return PostType.CAROUSEL;
        }
        if (medias.length === 1 && medias[0].type === MediaType.VIDEO) {
            return PostType.VIDEO;
        }
        return PostType.IMAGE;
    }

    private _initTiktokCreatorInfo(): void {
        this._postsService.queryTiktokCreatorInfo(this._restaurantsService.currentRestaurant._id).subscribe({
            next: (res) => {
                if (res.data) {
                    const tiktokCreatorInfo = {
                        username: res.data.creatorUserName,
                        privacyStatusValues: res.data.privacyLevelOptions,
                        isCommentDisabled: res.data.commentDisabled === true,
                        isDuetDisabled: res.data.duetDisabled === true,
                        isStitchDisabled: res.data.stitchDisabled === true,
                    };

                    patchState(this.upsertSocialPostState, (state) => ({
                        ...state,
                        tiktokCreatorInfo,
                    }));

                    if (tiktokCreatorInfo.isCommentDisabled) {
                        this.updateTiktokOptionsComment(false);
                    }

                    if (tiktokCreatorInfo.isDuetDisabled) {
                        this.updateTiktokOptionsDuet(false);
                    }

                    if (tiktokCreatorInfo.isStitchDisabled) {
                        this.updateTiktokOptionsStitch(false);
                    }
                }
            },
            error: () => {},
        });
    }
}
