import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { TiktokPrivacyStatus } from '@malou-io/package-utils';

import { IUpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-tiktok-options',
    templateUrl: './tiktok-options.component.html',
    styleUrls: ['./tiktok-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        SimpleSelectComponent,
        SlideToggleComponent,
        ApplyPurePipe,
    ],
})
export class TiktokOptionsComponent {
    readonly tiktokPrivacyStatusValues = input<TiktokPrivacyStatus[]>(Object.values(TiktokPrivacyStatus));
    readonly isTiktokCommentDisabled = input.required<boolean>();
    readonly isTiktokDuetDisabled = input.required<boolean>();
    readonly isTiktokStitchDisabled = input.required<boolean>();
    readonly tiktokOptions = input.required<IUpsertSocialPost['tiktokOptions']>();
    readonly isDisabled = input.required<boolean>();

    readonly privacyStatusChange = output<TiktokPrivacyStatus>();
    readonly interactionAbilityCommentChange = output<boolean>();
    readonly interactionAbilityDuetChange = output<boolean>();
    readonly interactionAbilityStitchChange = output<boolean>();
    readonly contentDisclosureSettingsIsActivatedChange = output<boolean>();
    readonly contentDisclosureSettingsYourBrandChange = output<boolean>();
    readonly contentDisclosureSettingsBrandedContentChange = output<boolean>();

    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly SvgIcon = SvgIcon;
    readonly TiktokPrivacyStatus = TiktokPrivacyStatus;

    readonly isTikTokPanelOpened = signal(true);

    readonly disabledTiktokPrivacyStatusValuesWithTooltip = computed(() => {
        const brandedContent = this.tiktokOptions().contentDisclosureSettings.brandedContent;
        return brandedContent
            ? [
                  {
                      value: TiktokPrivacyStatus.SELF_ONLY,
                      tooltip: this._translateService.instant(
                          'social_posts.new_social_post.tiktok_options.tiktok_privacy_status.self_only_disabled'
                      ),
                  },
              ]
            : [];
    });

    readonly contentDisclosureMessage = computed(() => {
        const { brandedContent, yourBrand } = this.tiktokOptions().contentDisclosureSettings;
        if (brandedContent) {
            return this._translateService.instant(
                'social_posts.new_social_post.tiktok_options.content_disclosure_settings.branded_content_message'
            );
        }
        if (yourBrand) {
            return this._translateService.instant(
                'social_posts.new_social_post.tiktok_options.content_disclosure_settings.your_brand_message'
            );
        }
        return '';
    });

    openTikTokPanel(): void {
        this.isTikTokPanelOpened.set(true);
    }

    closeTikTokPanel(): void {
        this.isTikTokPanelOpened.set(false);
    }

    tiktokPrivacyStatusDisplayWith = (option: TiktokPrivacyStatus): string =>
        this._enumTranslatePipe.transform(option, 'tiktok_privacy_status');

    onPrivacyStatusChange(privacyStatus: TiktokPrivacyStatus | null): void {
        if (!privacyStatus) {
            return;
        }
        this.privacyStatusChange.emit(privacyStatus);
    }

    onCommentChange(event: MatCheckboxChange): void {
        this.interactionAbilityCommentChange.emit(event.checked);
    }

    onDuetChange(event: MatCheckboxChange): void {
        this.interactionAbilityDuetChange.emit(event.checked);
    }

    onStitchChange(event: MatCheckboxChange): void {
        this.interactionAbilityStitchChange.emit(event.checked);
    }

    onContentDisclosureSettingsIsActivatedChange(value: boolean): void {
        this.contentDisclosureSettingsIsActivatedChange.emit(!value);
    }

    onContentDisclosureSettingsYourBrandChange(event: MatCheckboxChange): void {
        this.contentDisclosureSettingsYourBrandChange.emit(event.checked);
    }

    onContentDisclosureSettingsBrandedContentChange(event: MatCheckboxChange): void {
        this.contentDisclosureSettingsBrandedContentChange.emit(event.checked);
    }
}
