<div class="malou-expansion-panel" id="tiktokOptions" formGroupName="tiktokOptions">
    <mat-expansion-panel [expanded]="isTikTokPanelOpened()" (opened)="openTikTokPanel()" (closed)="closeTikTokPanel()">
        <mat-expansion-panel-header>
            <div class="flex justify-between">
                <div class="flex items-center gap-x-4">
                    <span class="malou-text-12--semibold">
                        {{ 'social_posts.new_social_post.tiktok_options.title' | translate }}
                    </span>
                </div>
            </div>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <hr class="border-malou-color-border-primary" />
            <div class="flex flex-col gap-3 p-3">
                <div class="flex gap-x-4">
                    <app-simple-select
                        class="w-[250px]"
                        placeholder=""
                        [defaultValue]="tiktokOptions().privacyStatus"
                        [title]="'social_posts.new_social_post.tiktok_options.tiktok_privacy_status.title' | translate"
                        [values]="tiktokPrivacyStatusValues()"
                        [disabledValuesWithTooltip]="disabledTiktokPrivacyStatusValuesWithTooltip()"
                        [displayWith]="tiktokPrivacyStatusDisplayWith"
                        [disabled]="isDisabled()"
                        (simpleSelectChange)="onPrivacyStatusChange($event)">
                        <ng-template let-value="value" #selectedValueTemplate>
                            <div class="malou-text-12--semibold text-malou-color-text-1">
                                {{ tiktokPrivacyStatusDisplayWith | applyPure: value }}
                            </div>
                        </ng-template>
                        <ng-template let-option="option" #optionTemplate>
                            <div class="leading-4">
                                <div class="malou-text-12--regular text-malou-color-text-2">
                                    {{ tiktokPrivacyStatusDisplayWith | applyPure: option }}
                                </div>
                                @if (option === TiktokPrivacyStatus.MUTUAL_FOLLOW_FRIENDS) {
                                    <div class="malou-text-10--regular italic text-malou-color-text-2">
                                        {{
                                            'social_posts.new_social_post.tiktok_options.tiktok_privacy_status.mutual_follow_friends.subtitle'
                                                | translate
                                        }}
                                    </div>
                                }
                            </div>
                        </ng-template>
                    </app-simple-select>

                    <div class="flex flex-col gap-3">
                        <div class="malou-text-10--regular pt-1 text-malou-color-text-2">
                            {{ 'social_posts.new_social_post.tiktok_options.interaction_ability.title' | translate }}
                        </div>
                        <div class="flex gap-x-3">
                            <div class="flex items-center gap-x-2">
                                <mat-checkbox
                                    color="primary"
                                    [checked]="tiktokOptions().interactionAbility.comment"
                                    [disabled]="isTiktokCommentDisabled() || isDisabled()"
                                    (change)="onCommentChange($event)"></mat-checkbox>
                                <span class="malou-text-10--regular text-malou-color-text-2">
                                    {{ 'social_posts.new_social_post.tiktok_options.interaction_ability.comment' | translate }}
                                </span>
                            </div>

                            <div class="flex items-center gap-x-2">
                                <mat-checkbox
                                    color="primary"
                                    [checked]="tiktokOptions().interactionAbility.duet"
                                    [disabled]="isTiktokDuetDisabled() || isDisabled()"
                                    (change)="onDuetChange($event)"></mat-checkbox>
                                <span class="malou-text-10--regular text-malou-color-text-2">
                                    {{ 'social_posts.new_social_post.tiktok_options.interaction_ability.duet' | translate }}
                                </span>
                            </div>

                            <div class="flex items-center gap-x-2">
                                <mat-checkbox
                                    color="primary"
                                    [checked]="tiktokOptions().interactionAbility.stitch"
                                    [disabled]="isTiktokStitchDisabled() || isDisabled()"
                                    (change)="onStitchChange($event)"></mat-checkbox>
                                <span class="malou-text-10--regular text-malou-color-text-2">
                                    {{ 'social_posts.new_social_post.tiktok_options.interaction_ability.stitch' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-2 rounded-[10px] border border-malou-color-border-primary p-3">
                    <div class="flex items-center justify-between gap-x-3">
                        <div class="text-malou-color-text-2">
                            <div class="malou-text-10--bold">
                                {{ 'social_posts.new_social_post.tiktok_options.content_disclosure_settings.title' | translate }}
                            </div>
                            <div class="malou-text-10--regular">
                                {{ 'social_posts.new_social_post.tiktok_options.content_disclosure_settings.subtitle' | translate }}
                            </div>
                        </div>

                        <app-slide-toggle
                            [checked]="tiktokOptions().contentDisclosureSettings.isActivated"
                            [disabled]="isDisabled()"
                            (onToggle)="onContentDisclosureSettingsIsActivatedChange($event)"></app-slide-toggle>
                    </div>

                    @if (tiktokOptions().contentDisclosureSettings.isActivated) {
                        <div class="flex flex-col gap-3">
                            @if (contentDisclosureMessage(); as contentDisclosureMessage) {
                                <div
                                    class="malou-text-10--regular flex items-center gap-x-2 rounded bg-malou-color-background-dark px-3 py-2 text-malou-color-text-2">
                                    <div
                                        class="flex h-[15px] w-[15px] items-center justify-center rounded-full bg-malou-color-chart-purple/70">
                                        <mat-icon class="!h-[10px] !w-[10px]" [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                    </div>
                                    {{ contentDisclosureMessage }}
                                </div>
                            }

                            <div class="flex gap-x-3">
                                <mat-checkbox
                                    color="primary"
                                    [checked]="tiktokOptions().contentDisclosureSettings.yourBrand"
                                    [disabled]="isDisabled()"
                                    (change)="onContentDisclosureSettingsYourBrandChange($event)"></mat-checkbox>
                                <div class="leading-4 text-malou-color-text-2">
                                    <div class="malou-text-10--bold">
                                        {{
                                            'social_posts.new_social_post.tiktok_options.content_disclosure_settings.your_brand.title'
                                                | translate
                                        }}
                                    </div>
                                    <div class="malou-text-10--regular">
                                        {{
                                            'social_posts.new_social_post.tiktok_options.content_disclosure_settings.your_brand.subtitle'
                                                | translate
                                        }}
                                    </div>
                                </div>
                            </div>

                            <div
                                class="flex gap-x-3"
                                [matTooltip]="
                                    tiktokOptions().privacyStatus === TiktokPrivacyStatus.SELF_ONLY
                                        ? ('social_posts.new_social_post.tiktok_options.content_disclosure_settings.branded_content.tooltip'
                                          | translate)
                                        : ''
                                ">
                                <mat-checkbox
                                    color="primary"
                                    [checked]="tiktokOptions().contentDisclosureSettings.brandedContent"
                                    [disabled]="tiktokOptions().privacyStatus === TiktokPrivacyStatus.SELF_ONLY || isDisabled()"
                                    (change)="onContentDisclosureSettingsBrandedContentChange($event)"></mat-checkbox>
                                <div
                                    class="leading-4 text-malou-color-text-2"
                                    [ngClass]="{
                                        'opacity-50': tiktokOptions().privacyStatus === TiktokPrivacyStatus.SELF_ONLY,
                                    }">
                                    <div class="malou-text-10--bold">
                                        {{
                                            'social_posts.new_social_post.tiktok_options.content_disclosure_settings.branded_content.title'
                                                | translate
                                        }}
                                    </div>
                                    <div class="malou-text-10--regular">
                                        {{
                                            'social_posts.new_social_post.tiktok_options.content_disclosure_settings.branded_content.subtitle'
                                                | translate
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                @if (tiktokOptions().contentDisclosureSettings.brandedContent) {
                    <div
                        class="malou-text-10--regular italic leading-4 text-malou-color-text-2"
                        [innerHTML]="
                            'social_posts.new_social_post.tiktok_options.content_disclosure_settings.tiktok_policy' | translate
                        "></div>
                } @else {
                    <div
                        class="malou-text-10--regular italic leading-4 text-malou-color-text-2"
                        [innerHTML]="
                            'social_posts.new_social_post.tiktok_options.content_disclosure_settings.tiktok_music_usage_policy' | translate
                        "></div>
                }
            </div>
        </ng-template>
    </mat-expansion-panel>
</div>
