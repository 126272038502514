<app-reviews-kpis-card
    [title]="'statistics.e_reputation.reviews_kpis.answer_rate' | translate"
    [comparisonPeriodTitle]="comparisonPeriodKey() | translate"
    [icon]="SvgIcon.CONVERSATION"
    [currentValue]="currentAnsweredRepliesRate() | shortNumber: { content: '%', fixNumber: 0 }"
    [previousValue]="previousAnsweredRepliesRate() | shortNumber: { content: '%', fixNumber: 0 }"
    [currentValueRaw]="currentAnsweredRepliesRate()"
    [valueTooltip]="'statistics.e_reputation.reviews_kpis.answer_rate_tooltip' | translate"
    [diffValue]="diffAnsweredRepliesRate()"
    [diffDisplayedValue]="diffAnsweredRepliesRate() | shortNumber: { content: '%', fixNumber: 0, shouldDisplayMinusSign: false }"
    [isLoading]="isLoading()"
    [error]="httpError()">
</app-reviews-kpis-card>
