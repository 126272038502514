@if (isLoading()) {
    <app-skeleton flexDirection="flex-row" gapClass="gap-2" skeletonClass="w-full h-[500px] secondary-bg" [count]="2"></app-skeleton>
} @else {
    <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
}

<ng-template #dataTemplate>
    <div class="flex flex-wrap gap-4 lg:flex-col">
        <div class="malou-simple-card flex h-full flex-1 flex-col gap-3 px-6 py-3 md:px-2">
            <div class="malou-text-section-title malou-color-text-1 p-4">
                {{ 'aggregated_statistics.seo.top_keyword_search_impression.top_branding_keywords' | translate }}
            </div>
            <app-search-impressions-table
                [searchImpressionInsights]="topKeywordSearchImpressions().malouSelected"
                [selectedRestaurantsCount]="selectedRestaurantsCount()"
                [shouldHideTableClickableElements]="shouldHideTableClickableElements()">
            </app-search-impressions-table>
        </div>
        <div class="malou-simple-card flex h-full flex-1 flex-col gap-3 px-6 py-3 md:px-2">
            <div class="malou-text-section-title malou-color-text-1 p-4">
                {{ 'aggregated_statistics.seo.top_keyword_search_impression.top_discovery_keywords' | translate }}
            </div>
            <app-search-impressions-table
                [searchImpressionInsights]="topKeywordSearchImpressions().discovery"
                [selectedRestaurantsCount]="selectedRestaurantsCount()"
                [shouldHideTableClickableElements]="shouldHideTableClickableElements()">
            </app-search-impressions-table>
        </div>
    </div>
</ng-template>
