<div class="[&>*]:py-12">
    <div class="mx-4 my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'aggregated_statistics_pdf.e-reputation.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic"
            >{{ selectedRestaurantsTitle$ | async | statisticsPdfRestaurantsFormatter: true }}
        </span>
    </div>

    <div class="px-8.5 py-4 [&>*]:py-6">
        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS) && reviewRatingKpisHasData) {
            <div class="w-full flex-1">
                <app-reviews-rating-kpis
                    [tableSortOptions]="chartOptions[InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS]?.tableSortOptions"
                    (hasDataChange)="reviewRatingKpisHasData = $event">
                </app-reviews-rating-kpis>
            </div>
        }
        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_REVIEWS_COUNT) && reviewCountHasData) {
            <div class="break-inside-avoid">
                <app-reviews-count
                    [showSortByTextInsteadOfSelector]="true"
                    [chartSortBy]="chartOptions[InsightsChart.AGGREGATED_REVIEWS_COUNT]?.chartSortBy"
                    [tableSort]="chartOptions[InsightsChart.AGGREGATED_REVIEWS_COUNT]?.tableSortOptions"
                    [viewMode]="chartOptions[InsightsChart.AGGREGATED_REVIEWS_COUNT]?.viewMode"
                    [showViewModeToggle]="false"
                    [expandTable]="true"
                    (hasDataChange)="reviewCountHasData = $event">
                </app-reviews-count>
            </div>
        }
        @if ((displayedCharts | includes: InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE) && reviewRatingsAverageHasData) {
            <div class="break-inside-avoid">
                <app-reviews-ratings-average
                    [showSortByTextInsteadOfSelector]="true"
                    [chartSortBy]="chartOptions[InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE]?.chartSortBy"
                    [tableSort]="chartOptions[InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE]?.tableSortOptions"
                    [viewMode]="chartOptions[InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE]?.viewMode"
                    [showViewModeToggle]="false"
                    [expandTable]="true"
                    (hasDataChange)="reviewRatingsAverageHasData = $event">
                </app-reviews-ratings-average>
            </div>
        }
        @if (
            reviewAnalysesHasData &&
            ((displayedCharts | includes: InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_CHARTS) ||
                (displayedCharts | includes: InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION))
        ) {
            <div class="break-inside-avoid">
                <app-review-analyses
                    [shouldDisplayAnalysesTagClickableLabels]="false"
                    [shouldDetailTagsEvolutionCharts]="true"
                    [shouldDisplayAnalysesTagCharts]="displayedCharts | includes: InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_CHARTS"
                    [shouldDisplayAnalysesTagEvolution]="displayedCharts | includes: InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION"
                    [tagsEvolutionSortBy]="
                        chartOptions[InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION]?.chartSortBy ?? SortBy.ALPHABETICAL
                    "
                    (hasDataChange)="reviewAnalysesHasData = $event">
                </app-review-analyses>
            </div>
        }
    </div>
</div>
