@if (hasData() && isPdfDownload()) {
    <div class="flex flex-col gap-2">
        @for (tab of builtTabsForPdf(); track $index) {
            <ng-container [ngTemplateOutlet]="graphTemplate" [ngTemplateOutletContext]="{ pdfSelectedTabData: tab }"></ng-container>
        }
    </div>
} @else {
    <ng-container [ngTemplateOutlet]="graphTemplate"></ng-container>
}

<ng-template let-pdfSelectedTabData="pdfSelectedTabData" #graphTemplate>
    <div class="malou-simple-card flex h-[660px] w-full break-inside-avoid flex-col gap-3 px-6 py-3 md:px-2">
        <div class="flex h-full min-h-60 flex-col gap-3">
            <div>
                <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
            </div>
            @if (hasData()) {
                <div>
                    <ng-container [ngTemplateOutlet]="navigationTemplate" [ngTemplateOutletContext]="{ pdfSelectedTabData }"></ng-container>
                </div>
                <div class="h-full">
                    <ng-container
                        [ngTemplateOutlet]="topicEvolutionTemplate"
                        [ngTemplateOutletContext]="{ pdfSelectedTabData }"></ng-container>
                </div>
            } @else {
                <div class="flex flex-col items-center py-4">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.e_reputation.reviews_analysis.topic_evolution.title' | translate }}
        </span>
        <div class="flex items-center gap-2">
            @if (isPdfDownload()) {
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: chartViewBy() | lowercase }}
                </span>
            } @else {
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [theme]="SelectBaseDisplayStyle.WITH_BACKGROUND"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    [idPrefix]="'tracking_semantic_analysis_view_by_'"
                    [getIdSuffixFn]="getIdSuffixFn"></app-select>
            }
        </div>
    </div>
</ng-template>

<ng-template let-pdfSelectedTabData="pdfSelectedTabData" #navigationTemplate>
    <app-tab-nav-bar
        [tabs]="tabs()"
        [defaultSelectedTab]="pdfSelectedTabData ?? defaultSelectedTab()"
        [theme]="TabNavBarDisplayStyle.LIGHT"
        (tabChange)="onTabChange($event)"></app-tab-nav-bar>
</ng-template>

<ng-template let-pdfSelectedTabData="pdfSelectedTabData" #topicEvolutionTemplate>
    <div class="flex h-full">
        <div class="w-[25%]">
            <ng-container [ngTemplateOutlet]="topicsTemplate" [ngTemplateOutletContext]="{ pdfSelectedTabData }"></ng-container>
        </div>
        <div class="w-[75%] pl-5">
            @if (isLoading()) {
                <app-skeleton skeletonClass="!h-[480px] secondary-bg"></app-skeleton>
            } @else {
                <ng-container [ngTemplateOutlet]="chartTemplate" [ngTemplateOutletContext]="{ pdfSelectedTabData }"></ng-container>
            }
        </div>
    </div>
</ng-template>

<ng-template let-pdfSelectedTabData="pdfSelectedTabData" #topicsTemplate>
    <div class="flex flex-col">
        <ng-container
            [ngTemplateOutlet]="topicTitleTemplate"
            [ngTemplateOutletContext]="{
                topicNumber: pdfSelectedTabData?.topics?.length ?? currentCategoryTopics().length,
                pdfSelectedTabData,
            }"></ng-container>
        @if (!isPdfDownload()) {
            <ng-container [ngTemplateOutlet]="topicFiltersTemplate"></ng-container>
        }
    </div>

    <div class="flex flex-col gap-1" [ngClass]="{ 'mt-2': isPdfDownload() }">
        @let filteredTopics = pdfSelectedTabData?.sortedTopics ?? filteredAndSortedCurrentCategoryTopics();
        @let mainTopic = pdfSelectedTabData?.currentMainTopic ?? currentMainTopic();
        @if (filteredTopics.length || (mainTopic && isCurrentMainTopicMatchingSearch())) {
            <div class="flex max-h-[350px] flex-col gap-1 overflow-y-auto">
                @if (mainTopic && isCurrentMainTopicMatchingSearch()) {
                    <ng-container
                        [ngTemplateOutlet]="singleTopicTemplate"
                        [ngTemplateOutletContext]="{
                            isMainTopic: true,
                            topic: mainTopic,
                        }"></ng-container>
                }
                @for (topic of filteredTopics; track topic.name) {
                    <ng-container
                        [ngTemplateOutlet]="singleTopicTemplate"
                        [ngTemplateOutletContext]="{ isMainTopic: false, topic }"></ng-container>
                }
            </div>
        } @else if (searchText()) {
            <ng-container
                [ngTemplateOutlet]="emptyTopicsTemplate"
                [ngTemplateOutletContext]="{
                    illustration: Illustration.BestFoodInTown,
                    subtitle: 'statistics.e_reputation.reviews_analysis.topic_evolution.no_topic_matching' | translate,
                }"></ng-container>
        } @else {
            @let category = pdfSelectedTabData?.category ?? currentCategory();
            @let categoryText = category ? (category | enumTranslate: 'review_analysis_tags')?.toLowerCase() : '';
            @let sentiment = pdfSelectedTabData?.sentiment ?? currentSentiment();
            <ng-container
                [ngTemplateOutlet]="emptyTopicsTemplate"
                [ngTemplateOutletContext]="{
                    illustration: sentiment === ReviewAnalysisSentiment.POSITIVE ? Illustration.Stars : Illustration.BestFoodInTown,
                    title:
                        sentiment === ReviewAnalysisSentiment.POSITIVE
                            ? ('statistics.e_reputation.reviews_analysis.topic_evolution.no_positive_topic.title' | translate)
                            : ('statistics.e_reputation.reviews_analysis.topic_evolution.no_negative_topic.title' | translate),
                    subtitle:
                        sentiment === ReviewAnalysisSentiment.POSITIVE
                            ? ('statistics.e_reputation.reviews_analysis.topic_evolution.no_positive_topic.subtitle'
                              | translate: { category: categoryText })
                            : ('statistics.e_reputation.reviews_analysis.topic_evolution.no_negative_topic.subtitle'
                              | translate: { category: categoryText }),
                }"></ng-container>
        }
    </div>
</ng-template>

<ng-template let-topicNumber="topicNumber" let-pdfSelectedTabData="pdfSelectedTabData" #topicTitleTemplate>
    <div class="my-1 flex w-full items-center justify-between">
        <div>
            <span class="malou-text-15--bold ml-1 text-malou-color-text-1">
                {{ 'statistics.e_reputation.reviews_analysis.topic_evolution.topics' | translate }}
            </span>
            <span class="malou-text-15--regular italic text-malou-color-text-2">({{ topicNumber }})</span>
        </div>
        <div class="flex items-center">
            <ng-container
                [ngTemplateOutlet]="sentimentSelectorTemplate"
                [ngTemplateOutletContext]="{ sentiment: ReviewAnalysisSentiment.NEGATIVE, pdfSelectedTabData }"></ng-container>
            <ng-container
                [ngTemplateOutlet]="sentimentSelectorTemplate"
                [ngTemplateOutletContext]="{ sentiment: ReviewAnalysisSentiment.POSITIVE, pdfSelectedTabData }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #topicFiltersTemplate>
    <div class="my-2 flex w-full justify-between">
        <div class="mr-2 w-full">
            <app-search
                [placeholder]="'common.search' | translate"
                [theme]="SearchBarDisplayStyle.SMALL"
                (searchChange)="onSearchValueChange($event)"></app-search>
        </div>
        <div class="flex h-[24px] w-[30px] items-center rounded-[5px] bg-malou-color-background-dark p-2">
            <mat-icon
                class="malou-color-primary !h-4 cursor-pointer"
                [svgIcon]="selectedSortBy() === TopicSortBy.ASC ? SvgIcon.SORT_DOWN : SvgIcon.SORT_UP"
                (click)="toggleSelectedSortBy()"></mat-icon>
        </div>
    </div>
</ng-template>

<ng-template let-sentiment="sentiment" let-pdfSelectedTabData="pdfSelectedTabData" #sentimentSelectorTemplate>
    @let selectedSentiment = pdfSelectedTabData?.sentiment ?? currentSentiment();
    <div
        class="cursor-pointer rounded-[5px] p-2"
        [id]="'tracking_semantic_analysis_' + (sentiment === ReviewAnalysisSentiment.POSITIVE ? 'positive' : 'negative') + '_filter_click'"
        [ngClass]="{
            'malou-text-12--medium bg-malou-color-background-dark text-malou-color-text-1': selectedSentiment === sentiment,
            'malou-text-12--regular text-malou-color-text-2': selectedSentiment !== sentiment,
        }"
        (click)="updateSentiment(sentiment)">
        {{
            sentiment === ReviewAnalysisSentiment.POSITIVE
                ? ('statistics.e_reputation.reviews_analysis.topic_evolution.positive' | translate)
                : ('statistics.e_reputation.reviews_analysis.topic_evolution.negative' | translate)
        }}
    </div>
</ng-template>

<ng-template let-topic="topic" let-isMainTopic="isMainTopic" let-pdfSelectedTabData="pdfSelectedTabData" #singleTopicTemplate>
    @let sentiment = pdfSelectedTabData?.sentiment ?? currentSentiment();
    @let sentimentCountKey = sentiment === ReviewAnalysisSentiment.POSITIVE ? 'positiveCount' : 'negativeCount';
    @let sentimentCountEvolutionKey = sentiment === ReviewAnalysisSentiment.POSITIVE ? 'positiveCountEvolution' : 'negativeCountEvolution';
    <div
        class="hover-arrow flex cursor-pointer items-center justify-between rounded-[5px] border border-malou-color-border-primary p-2 hover:bg-malou-color-background-light"
        [ngClass]="{
            'mb-0.5 bg-malou-color-background-light': isMainTopic,
            '!border-malou-color-background-dark !bg-malou-color-background-dark': selectedTopicName() === topic.name,
            'min-h-[45px]': isPdfDownload(),
        }"
        (click)="updateSelectedTopic(topic, isMainTopic)">
        <div class="flex items-center" [ngClass]="{ 'w-[80%]': !isMainTopic }">
            @if (!isMainTopic) {
                <mat-icon
                    class="mr-1 !h-4 cursor-pointer"
                    [ngClass]="{
                        'text-malou-color-state-warn': topic.isFavorite,
                        'text-malou-color-background-dark': !topic.isFavorite && selectedTopicName() !== topic.name,
                        'text-malou-color-text-white': !topic.isFavorite && selectedTopicName() === topic.name,
                    }"
                    [svgIcon]="topic.isFavorite ? SvgIcon.FAVORITE_ACTIVE : SvgIcon.FAVORITE_INACTIVE"
                    (click)="toggleFavoriteStatus(topic)"></mat-icon>
            }
            <div class="flex flex-col items-start" [ngClass]="{ 'ml-2': isMainTopic, 'max-w-[87%]': !isMainTopic }">
                <div class="flex w-full items-center">
                    <span
                        class="text-malou-color-text-1"
                        [matTooltip]="topic.displayedNameInCurrentLang || topic.name"
                        [ngClass]="{
                            'truncate text-ellipsis': !isPdfDownload(),
                            'malou-text-13--bold': isMainTopic,
                            'malou-text-12--semibold': !isMainTopic,
                        }">
                        {{ topic.displayedNameInCurrentLang || topic.name }}
                    </span>
                    @if (topic.isNew) {
                        <span
                            class="malou-text-8--semibold ml-2 rounded bg-malou-color-gradient-2 px-1 pb-0.5 pt-[3px] text-malou-color-text-white">
                            {{ 'statistics.e_reputation.reviews_analysis.topic_evolution.new' | translate }}
                        </span>
                    }
                </div>
                @if (!isPdfDownload()) {
                    <button
                        class="malou-btn-flat !malou-text-8--semibold !text-malou-color-primary"
                        id="tracking_semantic_analysis_see_related_reviews_click"
                        (click)="openTopicReviewsModal(topic, isMainTopic)">
                        {{ 'statistics.e_reputation.reviews_analysis.topic_evolution.see_associated_reviews' | translate }}
                    </button>
                }
            </div>
        </div>
        <div class="ml-2 mr-1">
            <span class="number-evolution-container flex gap-3">
                <span class="malou-text-12--medium text-malou-color-text-1">{{ topic[sentimentCountKey] }}</span>
                @if (topic[sentimentCountEvolutionKey]) {
                    <app-number-evolution
                        [value]="topic[sentimentCountEvolutionKey]"
                        [displayedValue]="''"
                        [shouldSwitchColors]="sentiment === ReviewAnalysisSentiment.NEGATIVE"></app-number-evolution>
                } @else {
                    <div class="min-w-[18px]"></div>
                }
            </span>
            <span class="hover-arrow-container hidden min-w-[18px]">
                <mat-icon class="!h-4 cursor-pointer" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
            </span>
        </div>
    </div>
</ng-template>

<ng-template let-pdfSelectedTabData="pdfSelectedTabData" #chartTemplate>
    @if (noResults()) {
        <div></div>
    } @else {
        @let positiveData = pdfSelectedTabData?.positiveChartData ?? positiveChartData();
        @let negativeData = pdfSelectedTabData?.negativeChartData ?? negativeChartData();
        @let dateLabels = pdfSelectedTabData?.dateLabels ?? labels();
        <app-area-chart
            [positiveChartData]="positiveData"
            [negativeChartData]="negativeData"
            [labels]="dateLabels"
            [viewBy]="chartViewBy()"
            [chartTopicFilter]="chartTopicFilter()"></app-area-chart>
    }
</ng-template>

<ng-template let-illustration="illustration" let-title="title" let-subtitle="subtitle" #emptyTopicsTemplate>
    <div class="flex h-full flex-col items-center justify-center">
        <div class="mb-8 w-24 md:mb-5 md:w-24">
            <img alt="Best food in town illustration" [src]="illustration | illustrationPathResolver" />
        </div>
        <div class="mb-40 px-7 text-center">
            @if (title) {
                <span class="malou-text-14--bold mb-2 text-malou-color-text-1">
                    {{ title }}
                </span>
            }
            @if (subtitle) {
                <p class="malou-text-10--regular malou-color-text-2 mb-2">
                    {{ subtitle }}
                </p>
            }
        </div>
    </div>
</ng-template>
