import { SegmentAnalysisDto } from '@malou-io/package-dto';
import { ReviewAnalysisSentiment, ReviewAnalysisTag } from '@malou-io/package-utils';

import { SegmentAnalysisParentTopic } from ':shared/models/segment-analysis-parent-topic';

export class SegmentAnalysis {
    id?: string;
    platformKey: string;
    reviewSocialId: string;
    reviewSocialCreatedAt: Date;
    platformSocialId: string;
    category: ReviewAnalysisTag;
    topic?: string;
    segment?: string;
    aiFoundSegment: string;
    sentiment: ReviewAnalysisSentiment;
    position?: number;
    segmentAnalysisParentTopic?: SegmentAnalysisParentTopic;

    public constructor(init: SegmentAnalysis | SegmentAnalysisDto) {
        this.id = init?.id;
        this.platformKey = init?.platformKey;
        this.reviewSocialId = init?.reviewSocialId;
        this.reviewSocialCreatedAt = init?.reviewSocialCreatedAt;
        this.platformSocialId = init?.platformSocialId;
        this.category = init?.category;
        this.segment = init?.segment;
        this.aiFoundSegment = init?.aiFoundSegment;
        this.sentiment = init?.sentiment;
        this.position = (init as SegmentAnalysis)?.position;
        this.segmentAnalysisParentTopic = init?.segmentAnalysisParentTopic
            ? new SegmentAnalysisParentTopic(init?.segmentAnalysisParentTopic)
            : undefined;
    }

    static sortSegmentsByPositionInText(segmentAnalyses: SegmentAnalysis[], reviewText: string | null): SegmentAnalysis[] {
        const cleanReviewText = reviewText ? reviewText.replace(/\n/g, '') : '';
        return segmentAnalyses
            ?.map((segmentAnalysis) => ({
                ...segmentAnalysis,
                position: cleanReviewText.indexOf(segmentAnalysis.segment?.replace(/\n/g, '').trim() ?? ''),
            }))
            .sort((a, b) => a.position - b.position)
            .map((segmentAnalysis: SegmentAnalysis) => {
                delete segmentAnalysis.position;
                return {
                    ...segmentAnalysis,
                    segment: segmentAnalysis.segment?.trim(),
                };
            });
    }

    static filterSegmentAnalysesByTag(segmentAnalyses: SegmentAnalysis[], category: ReviewAnalysisTag): SegmentAnalysis[] {
        return segmentAnalyses?.filter((segmentAnalysis) => segmentAnalysis.category !== category);
    }

    static filterSegmentAnalysesBySentiment(segmentAnalyses: SegmentAnalysis[], sentiment: ReviewAnalysisSentiment): SegmentAnalysis[] {
        return segmentAnalyses?.filter((segmentAnalysis) => segmentAnalysis.sentiment?.toLowerCase() !== sentiment?.toLowerCase());
    }
}
