<div>
    @if (!isLoading()) {
        @if (areAllPlatformsInError()) {
            <ng-container
                [ngTemplateOutlet]="errorTemplate"
                [ngTemplateOutletContext]="{
                    error: 'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip() },
                }"></ng-container>
        } @else {
            @if (!httpError()) {
                @if (!insightsError()) {
                    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
                        <div>
                            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
                        </div>
                        <div>
                            <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                        </div>
                        <div>
                            <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
                        </div>
                    </div>
                } @else {
                    <ng-container
                        [ngTemplateOutlet]="errorTemplate"
                        [ngTemplateOutletContext]="{
                            error: insightsError(),
                        }"></ng-container>
                }
            } @else {
                <ng-container
                    [ngTemplateOutlet]="errorTemplate"
                    [ngTemplateOutletContext]="{
                        error: httpError() | statisticsHttpError,
                    }"></ng-container>
            }
        }
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
</div>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <div class="flex items-center gap-2">
            <span class="malou-text-section-title malou-color-text-1">{{
                'statistics.social_networks.engagement.engagement' | translate
            }}</span>
            @if (platformsErrorTooltip()) {
                <span
                    class="malou-status--waiting pdf-hidden"
                    [matTooltip]="'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip() }">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </span>
            }
        </div>
        @if (!showViewByTextInsteadOfSelector()) {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--medium md:hidden">{{ 'statistics.social_networks.engagement.view_by' | translate }}</span>
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    (selectChange)="viewByFilterSubject$.next($event)">
                </app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: (viewByFilterSubject$ | async) ?? ViewBy.DAY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-engagement-chart-v2
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        [engagementData]="engagementData().currentEngagementData"
        [previousEngagementData]="engagementData().previousEngagementData"
        [comparisonPeriod]="comparisonPeriod()"
        [labels]="labels().currentLabels"
        [previousLabels]="labels().previousLabels"
        [viewBy]="(viewByFilterSubject$ | async) ?? ViewBy.DAY"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"></app-engagement-chart-v2>
</ng-template>

<ng-template #infoTemplate>
    <div class="flex gap-4 md:flex-col">
        <ng-container
            [ngTemplateOutlet]="infoCardTemplate"
            [ngTemplateOutletContext]="{
                value: totalImpressions().current | shortNumber,
                comparisonPeriodData: previousTotalImpressions() | shortNumber,
                diff: totalImpressions().diff,
                displayedValue: totalImpressions().diff | shortNumber: { shouldDisplayMinusSign: false },
                title: 'statistics.social_networks.kpis.impressions' | translate,
                tooltip: 'statistics.social_networks.engagement.impressions_details' | translate,
            }"></ng-container>

        <ng-container
            [ngTemplateOutlet]="infoCardTemplate"
            [ngTemplateOutletContext]="{
                value: totalEngagementRate().current | shortNumber: { content: '%', fixNumber: 1 },
                comparisonPeriodData: previousTotalEngagementRate() | shortNumber: { content: '%', fixNumber: 1 },
                diff: totalEngagementRate().diff,
                displayedValue: totalEngagementRate().diff | shortNumber: { content: 'pts', fixNumber: 1, shouldDisplayMinusSign: false },
                title: 'statistics.social_networks.kpis.engagement_rate' | translate,
                tooltip: 'statistics.social_networks.engagement.engagement_rate_details' | translate,
            }"></ng-container>
    </div>
</ng-template>

<ng-template
    let-value="value"
    let-comparisonPeriodData="comparisonPeriodData"
    let-diff="diff"
    let-displayedValue="displayedValue"
    let-title="title"
    let-tooltip="tooltip"
    #infoCardTemplate>
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">
                {{ value }}
            </div>
            @if (diff) {
                <app-number-evolution
                    [matTooltip]="comparedToKey() | translate"
                    [value]="diff ?? null"
                    [displayedValue]="displayedValue"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 flex items-center whitespace-nowrap">
            {{ title }}
            <mat-icon class="pdf-hidden ml-1 !h-4 !w-4" color="primary" [svgIcon]="SvgIcon.INFO" [matTooltip]="tooltip"> </mat-icon>
        </div>

        <div
            class="malou-text-10--regular malou-color-text-2 mt-1 whitespace-nowrap italic"
            [matTooltip]="comparisonPeriodKey() | translate">
            ({{ comparisonPeriodData }})
        </div>
    </div>
</ng-template>

<ng-template let-error="error" #errorTemplate>
    <div class="flex !h-[542px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
        <span class="malou-text-10--regular">{{ error }}</span>
    </div>
</ng-template>
