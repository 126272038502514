import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, effect, inject, Injector, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import {
    HeapEventName,
    isNotNil,
    MonthYearPeriod,
    PlatformDefinitions,
    PlatformFilterPage,
    PlatformKey,
    sortRestaurantsByInternalNameThenName,
} from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiContext } from ':modules/roi/roi.context';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { MonthYearDatePickerV2Component } from ':shared/components/month-year-date-picker-v2/month-year-date-picker-v2.component';
import { SelectNfcsComponent } from ':shared/components/select-nfcs/select-nfcs.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { SelectTimeScaleFilterComponent } from ':shared/components/select-time-scale-filter/select-time-scale-filter.component';
import { MAX_USER_FILTERS_SELECTABLE_RESTAURANTS } from ':shared/constants/filters';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DatePickerType } from ':shared/enums/date-pickers';
import { getSortedPlatformKeys } from ':shared/helpers/get-sorted-platform-keys';
import { KillSubscriptions } from ':shared/interfaces';
import {
    DatesAndPeriod,
    LightNfc,
    MalouDateFilters,
    MalouPeriod,
    MalouTimeScalePeriod,
    Restaurant,
    RestaurantWithTooltipProperties,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import * as AggregatedStatisticsActions from '../store/aggregated-statistics.actions';
import * as AggregatedStatisticsSelector from '../store/aggregated-statistics.selectors';
import { selectPlatformsFilter } from '../store/aggregated-statistics.selectors';
import { AggregatedStatisticsFiltersContext } from './filters.context';
import { PlatformCacheService } from './platform-cache.service';

interface InitSelectedRestaurants {
    restaurantsWithRoiActivated: Restaurant[];
}

@Component({
    selector: 'app-statistics-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        GroupedDateFiltersComponent,
        SelectPlatformsComponent,
        SelectRestaurantsComponent,
        SelectTimeScaleFilterComponent,
        FormsModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        SelectNfcsComponent,
        AsyncPipe,
        NgClass,
        MonthYearDatePickerV2Component,
    ],
})
@AutoUnsubscribeOnDestroy()
export class FiltersComponent implements OnInit, KillSubscriptions {
    readonly page = input<PlatformFilterPage>();
    readonly showPlatformsFilter = input<boolean>(true);
    readonly showNfcFilter = input<boolean>(false);
    readonly nfcs = input<LightNfc[]>([]);
    readonly timeScaleMinAcceptedDate = input<Date | null>();
    readonly datePickerType = input<DatePickerType>(DatePickerType.GROUPED_DATE);

    private readonly _store = inject(Store);
    private readonly _platformCacheService = inject(PlatformCacheService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _heapService = inject(HeapService);
    private readonly _injector = inject(Injector);
    public readonly screenSizeService = inject(ScreenSizeService);
    public readonly roiContext = inject(RoiContext);

    readonly SvgIcon = SvgIcon;
    readonly PlatformFilterPage = PlatformFilterPage;
    readonly DEFAULT_PERIODS = [
        MalouPeriod.LAST_SEVEN_DAYS,
        MalouPeriod.LAST_THIRTY_DAYS,
        MalouPeriod.LAST_THREE_MONTHS,
        MalouPeriod.LAST_TWELVE_MONTHS,
    ];
    readonly MAX_SELECTABLE_RESTAURANTS = MAX_USER_FILTERS_SELECTABLE_RESTAURANTS;
    readonly DatePickerType = DatePickerType;
    readonly DEFAULT_MAX_MONTH_YEAR_RANGE = 18;

    readonly period: WritableSignal<MalouPeriod> = signal(MalouPeriod.DEFAULT);
    readonly startDate: WritableSignal<Date | null> = signal(null);
    readonly endDate: WritableSignal<Date | null> = signal(null);
    readonly monthYearPeriod: WritableSignal<MonthYearPeriod> = signal(MalouDateFilters.getDefaultMonthYearPeriod());
    readonly MalouPeriod = MalouPeriod;

    connectedPlatforms: PlatformKey[] = [];
    restaurants$: Observable<RestaurantWithTooltipProperties[]>;
    readonly currentUser$ = this._store.select(selectUserInfos);

    user: User;

    readonly selectedRestaurants: WritableSignal<Restaurant[]> = signal([]);
    readonly selectedRestaurantIds: Signal<string[]> = computed(() => this.selectedRestaurants().map(({ _id }) => _id));
    readonly selectableNfcs: Signal<LightNfc[]> = computed(
        () => this.nfcs()?.filter((nfc) => this.selectedRestaurantIds().includes(nfc.restaurantId)) ?? []
    );
    readonly selectedNfcs: WritableSignal<LightNfc[]> = signal([]);

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    platformsFilterControl: FormControl<string[]> = new FormControl<string[]>([]) as FormControl<string[]>;
    restaurantsFilterControl: FormControl<Restaurant[]> = new FormControl<Restaurant[]>([]) as FormControl<Restaurant[]>;
    totemsFilterControl: FormControl<LightNfc[]> = new FormControl<LightNfc[]>([]) as FormControl<LightNfc[]>;
    timeScaleFilterControl: FormControl<MalouTimeScalePeriod> = new FormControl<MalouTimeScalePeriod>(
        MalouTimeScalePeriod.LAST_SIX_MONTHS
    ) as FormControl<MalouTimeScalePeriod>;

    constructor() {
        this._initTotemsFilter();
    }

    ngOnInit(): void {
        this.currentUser$.pipe(filter(isNotNil)).subscribe((user) => (this.user = user));
        this._initRestaurantsList();
        this._initMonthYearPeriod();
        this._listenStoreUpdates(this.showPlatformsFilter());
    }

    chooseBoundaryDate(dates: DatesAndPeriod): void {
        this._store.dispatch(AggregatedStatisticsActions.editDates({ dates: dates }));
    }

    onPlatformsChange(platforms: PlatformKey[]): void {
        this._store.dispatch(AggregatedStatisticsActions.editPlatforms({ page: this.page(), platforms: platforms }));
    }

    onRestaurantsChange(restaurants: Restaurant[]): void {
        switch (this.page()) {
            case PlatformFilterPage.ROI:
                const restaurantsToSet = this._initRoiSelectedRestaurants(restaurants);
                this.selectedRestaurants.set(restaurantsToSet);
                this._store.dispatch(AggregatedStatisticsActions.editRoiRestaurants({ roiRestaurants: restaurantsToSet }));
                break;

            default:
                this._store.dispatch(AggregatedStatisticsActions.editRestaurants({ restaurants }));
                this.selectedRestaurants.set(restaurants);
                if (this.page() === PlatformFilterPage.BOOSTERS) {
                    const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                    this.selectedNfcs.set(this.nfcs()?.filter((totem) => restaurantIds.includes(totem.restaurantId)) || []);
                    this._store.dispatch(
                        AggregatedStatisticsActions.editTotems({
                            totemIds: this.selectedNfcs().map((totem) => totem.id),
                        })
                    );
                }
                break;
        }
    }

    onNfcsChange(nfcs: LightNfc[]): void {
        this.selectedNfcs.set(nfcs);
        this._store.dispatch(AggregatedStatisticsActions.editTotems({ totemIds: nfcs.map((nfc) => nfc.id) }));
    }

    onTimeScaleChange(timeScale: MalouTimeScalePeriod): void {
        this._store.dispatch(AggregatedStatisticsActions.editTimeScale({ data: timeScale }));
    }

    computePlatformKeys(restaurants: string[]): void {
        combineLatest([
            this._platformCacheService.getPlatformKeysForRestaurants(restaurants).pipe(take(1)),
            this._store.select(selectPlatformsFilter({ page: this.page() })).pipe(take(1)),
        ])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([platformKeys, selectedPlatforms]) => {
                this.connectedPlatforms = platformKeys.filter((platformKey) => {
                    if (this.page() === PlatformFilterPage.E_REPUTATION) {
                        return ![PlatformKey.INSTAGRAM, PlatformKey.MAPSTR].includes(platformKey);
                    }
                    if (this.page() === PlatformFilterPage.SOCIAL_NETWORKS) {
                        const keys: string[] = PlatformDefinitions.getPlatformKeysWithRSStats();
                        return keys.includes(platformKey);
                    }
                    return true;
                });
                this.connectedPlatforms = getSortedPlatformKeys(this.connectedPlatforms);
                if (!selectedPlatforms.length) {
                    this._initPlatformsFilter(this.connectedPlatforms);
                } else {
                    this._initPlatformsFilter(selectedPlatforms.filter((platformKey) => this.connectedPlatforms.includes(platformKey)));
                }
            });
    }

    compareByRestaurantId(restaurant: Restaurant): string {
        return restaurant._id;
    }

    compareByNfcId(nfc: LightNfc): string {
        return nfc.id;
    }

    onMonthYearPeriodChanged(monthYearPeriod: MonthYearPeriod): void {
        this._store.dispatch(AggregatedStatisticsActions.editMonthYearPeriod({ data: monthYearPeriod }));
    }

    private _initMonthYearPeriod(): void {
        this._store
            .select(AggregatedStatisticsSelector.selectMonthYearPeriod)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((monthYearPeriod: MonthYearPeriod) => {
                this.monthYearPeriod.set(monthYearPeriod);
            });
    }

    private _initPlatformsFilter(platformsKeys: PlatformKey[]): void {
        this.onPlatformsChange(platformsKeys);
    }

    private _listenStoreUpdates(listenToPlatforms: boolean): void {
        this._initDatesListener();
        this._initListenToUpdates(listenToPlatforms);

        if (listenToPlatforms) {
            this._store
                .select(AggregatedStatisticsSelector.selectPlatformsFilter({ page: this.page() }))
                .pipe(takeUntil(this.killSubscriptions$))
                .subscribe((platforms: PlatformKey[]) => {
                    this.platformsFilterControl.setValue(getSortedPlatformKeys([...platforms]));
                });
        }

        this._store.select(AggregatedStatisticsSelector.selectTimeScaleFilter).subscribe((timeScale: MalouTimeScalePeriod) => {
            this.timeScaleFilterControl.setValue(timeScale);
        });
    }

    private _initDatesListener(): void {
        combineLatest([
            this._store.select(AggregatedStatisticsSelector.selectDatesFilter),
            this._store.select(AggregatedStatisticsSelector.selectIsFiltersLoaded),
        ])
            .pipe(
                filter(([_, isFiltersLoaded]) => isFiltersLoaded),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(([dates]) => {
                this.period.set(dates.period);
                this.startDate.set(dates.startDate);
                this.endDate.set(dates.endDate);
                if (this.page() === PlatformFilterPage.SEO || this.page() === PlatformFilterPage.E_REPUTATION) {
                    this._trackDateChange(dates);
                }
            });
    }

    private _initRestaurantsList(): void {
        switch (this.page()) {
            case PlatformFilterPage.ROI:
                this.restaurants$ = this._aggregatedStatisticsFiltersContext.roiRestaurants$.pipe(takeUntil(this.killSubscriptions$));
                break;
            default:
                this.restaurants$ = this._store
                    .select(selectOwnRestaurants)
                    .pipe(
                        map(
                            (restaurants) =>
                                restaurants
                                    .sort(sortRestaurantsByInternalNameThenName)
                                    .map((restaurant) => new RestaurantWithTooltipProperties(restaurant, false)) ?? []
                        )
                    );
                break;
        }
    }

    private _initListenToUpdates(listenToPlatforms: boolean): void {
        switch (this.page()) {
            case PlatformFilterPage.ROI:
                this._startRoiListener();
                break;
            default:
                this._startDefaultListener(listenToPlatforms);
                break;
        }
    }

    private _initRoiSelectedRestaurants(
        restaurants: Restaurant[],
        { restaurantsWithRoiActivated }: InitSelectedRestaurants = { restaurantsWithRoiActivated: [] }
    ): Restaurant[] {
        if (!restaurants.length) {
            return restaurantsWithRoiActivated.filter(
                (restaurant) => !restaurant.isBrandBusiness() && this.roiContext.isRestaurantRoiSettingsComplete(restaurant.id)
            );
        }
        if (!restaurantsWithRoiActivated?.length) {
            return restaurants.filter((restaurant) => this._shouldDisplayRestaurantInRoiFilter(restaurant));
        } else {
            return restaurants.filter(
                (restaurant) =>
                    restaurantsWithRoiActivated.some((restaurantWithRoiActivated) => restaurantWithRoiActivated._id === restaurant._id) &&
                    this._shouldDisplayRestaurantInRoiFilter(restaurant)
            );
        }
    }

    private _startRoiListener(): void {
        combineLatest([this._aggregatedStatisticsFiltersContext.savedRestaurantsWithRoiSettings$, this.restaurants$])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([restaurants, currentRestaurantsList]: [Restaurant[], Restaurant[]]) => {
                const restaurantsToSet = this._initRoiSelectedRestaurants(restaurants, {
                    restaurantsWithRoiActivated: currentRestaurantsList,
                });
                if (!restaurants.length && restaurantsToSet.length) {
                    this._store.dispatch(
                        AggregatedStatisticsActions.editRoiRestaurants({
                            roiRestaurants: restaurantsToSet.slice(0, MAX_USER_FILTERS_SELECTABLE_RESTAURANTS),
                        })
                    );
                }
                this.restaurantsFilterControl.setValue(restaurantsToSet);
                this.selectedRestaurants.set(restaurantsToSet);
            });
    }

    private _startDefaultListener(listenToPlatforms: boolean): void {
        combineLatest([
            this._aggregatedStatisticsFiltersContext.selectedRestaurants$,
            this._aggregatedStatisticsFiltersContext.selectedTotems$,
        ])
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe(([restaurants, totems]: [Restaurant[], LightNfc[]]) => {
                this.restaurantsFilterControl.setValue(restaurants);
                this.selectedRestaurants.set(restaurants);
                this.totemsFilterControl.setValue(totems);
                if (listenToPlatforms) {
                    void this.computePlatformKeys(restaurants.map((restaurant) => restaurant._id));
                }
            });
    }

    private _initTotemsFilter(): void {
        effect(
            () => {
                if (this.showNfcFilter() && this.selectableNfcs().length > 0 && this.selectedNfcs().length === 0) {
                    this.onNfcsChange(this.selectableNfcs());
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    private _shouldDisplayRestaurantInRoiFilter(restaurant: Restaurant): boolean {
        return (
            this.roiContext.isRestaurantRoiSettingsComplete(restaurant.id) &&
            !restaurant.isBrandBusiness() &&
            (this.user?.isAdmin() || restaurant.roiActivated)
        );
    }

    private _trackDateChange(dates: DatesAndPeriod): void {
        this._heapService.track(
            this.page() === PlatformFilterPage.SEO
                ? HeapEventName.TRACKING_DATE_FILTER_CHANGES_AGGREGATED_SEO
                : HeapEventName.TRACKING_DATE_FILTER_CHANGES_AGGREGATED_E_REPUTATION,
            {
                startDate: dates.startDate?.toISOString(),
                endDate: dates.endDate?.toISOString(),
                period: dates.period,
                isCustom: dates.period === MalouPeriod.CUSTOM,
            }
        );
    }
}
