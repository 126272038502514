import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { intersection } from 'lodash';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { PlatformDefinitions, PlatformKey, PostSource } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { PostToDuplicate } from ':modules/posts-v2/social-posts/models/post-to-duplicate';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import {
    DuplicateSocialPostPreviewModalSubmitData,
    DuplicateSocialPostWithTextGenerationInputData,
    DuplicateSocialPostWithTextGenerationPreviewModalV2Component,
} from ':shared/components/duplicate-post-preview-modal/duplicate-social-post-with-text-generation-preview-modal-v2/duplicate-social-post-with-text-generation-preview-modal-v2.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { Step } from ':shared/interfaces/step.interface';
import { Restaurant } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Injectable({
    providedIn: 'root',
})
export class DuplicateSocialPostsService {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _customDialogService = inject(CustomDialogService);

    duplicateHere({
        postIds,
        postDestination,
        fromRestaurantId,
        onDuplicationSuccess,
        onDuplicationError,
    }: {
        postIds: string[];
        fromRestaurantId: string;
        postDestination: PostSource;
        onDuplicationSuccess: () => void;
        onDuplicationError: (error: unknown) => void;
    }): void {
        this._socialPostsService.getPostsToDuplicate$(postIds).subscribe((result) => {
            const postsToDuplicate = result.data;
            const steps = this._getStepsForDuplication({
                posts: postsToDuplicate,
                postDestination,
                fromRestaurantId,
                skipRestaurantSelection: true,
            });

            const initialData: DuplicateSocialPostWithTextGenerationInputData = {
                selectedRestaurants: [this._restaurantsService.currentRestaurant],
                index: 0,
            };

            this._customDialogService.open(StepperModalComponent, {
                width: 'unset',
                height: 'unset',
                panelClass: 'malou-dialog-panel--without-border-radius',
                data: {
                    steps,
                    initialData,
                    fullScreenStepIndexes: steps.map((_, index) => index),
                    sharedData: {
                        postsToDuplicate,
                        postDestination,
                    },
                    title: this._translateService.instant('social_posts.duplicate_here_dialog.seo.title'),

                    onSuccess: (_data: { selectedRestaurants: Restaurant[]; index: number }) => {
                        onDuplicationSuccess();
                    },
                    onError: (error: unknown) => {
                        onDuplicationError(error);
                    },
                    shouldDisplayConfirmationCloseModalAfterClosed: true,
                    malouDialogBodyCustomStyle: {
                        padding: '0px',
                        margin: '0px',
                    },
                },
            });
        });
    }

    duplicateToOtherRestaurants({
        postIds,
        postDestination,
        fromRestaurantId,
        onDuplicationSuccess,
        onDuplicationError,
    }: {
        postIds: string[];
        postDestination: PostSource;
        fromRestaurantId: string;
        onDuplicationSuccess: () => void;
        onDuplicationError: (error: unknown) => void;
    }): void {
        this._socialPostsService.getPostsToDuplicate$(postIds).subscribe((result) => {
            const postsToDuplicate = result.data;
            const steps = this._getStepsForDuplication({ posts: postsToDuplicate, postDestination, fromRestaurantId });

            const containsReel = postsToDuplicate.some((post) => post.isReel());
            const containsPost = postsToDuplicate.some((post) => !post.isReel());

            const platformsForSocialPosts = this._getSocialPlatformKeysWithPost();
            const platformsForReels = this._getSocialPlatformKeysWithReel();

            let hasOneOfThesePlatforms: PlatformKey[] = [];

            if (containsPost && containsReel) {
                hasOneOfThesePlatforms = intersection(platformsForSocialPosts, platformsForReels);
            } else if (containsPost) {
                hasOneOfThesePlatforms = platformsForSocialPosts;
            } else if (containsReel) {
                hasOneOfThesePlatforms = platformsForReels;
            } else {
                console.error('Posts to duplicate do not contain any post or reel'); // should never happen
                return;
            }

            const initialData: RestaurantsSelectionData = {
                skipOwnRestaurant: true,
                withoutBrandBusiness: false,
                selectedRestaurants: [],
                hasPlatform: hasOneOfThesePlatforms,
                disableRestaurantWithoutMapstrPremium: false,
            };

            this._customDialogService.open(StepperModalComponent, {
                width: 'unset',
                height: 'unset',
                panelClass: 'malou-dialog-panel--without-border-radius',
                data: {
                    steps,
                    initialData,
                    fullScreenStepIndexes: steps.map((_, index) => index).filter((index) => index !== 0),
                    sharedData: {
                        postsToDuplicate,
                        postDestination,
                    },
                    title: this._translateService.instant('duplicate_to_restaurants_dialog.title'),

                    onSuccess: (_data: { selectedRestaurants: Restaurant[]; index: number }) => {
                        onDuplicationSuccess();
                    },
                    onError: (error: unknown) => {
                        onDuplicationError(error);
                    },
                    shouldDisplayConfirmationCloseModalAfterClosed: true,
                    malouDialogBodyCustomStyle: {
                        padding: '0px',
                        margin: '0px',
                    },
                },
            });
        });
    }

    private _getStepsForDuplication({
        posts,
        postDestination,
        fromRestaurantId,
        skipRestaurantSelection = false,
    }: {
        posts: PostToDuplicate[];
        postDestination: PostSource;
        fromRestaurantId: string;
        skipRestaurantSelection?: boolean;
    }): Step[] {
        const postCount = posts.length;
        return [
            ...(skipRestaurantSelection
                ? []
                : [
                      {
                          component: RestaurantsSelectionComponent,
                          subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                          primaryButtonText: this._translateService.instant('common.next'),
                          nextFunction$: (data: RestaurantsSelectionData) =>
                              of({
                                  selectedRestaurants: data.selectedRestaurants ?? [],
                                  index: 0,
                              }),
                      },
                  ]),
            ...posts.map((post, index) => ({
                component: DuplicateSocialPostWithTextGenerationPreviewModalV2Component,
                primaryButtonText:
                    postCount > 1 && index < postCount - 1
                        ? this._translateService.instant('common.duplicate_and_go_next')
                        : this._translateService.instant('common.duplicate'),
                hideSecondaryButton: index > 0,
                nextFunction$: (
                    data: DuplicateSocialPostPreviewModalSubmitData[]
                ): Observable<{ selectedRestaurants: Restaurant[]; index: number }> =>
                    this._socialPostsService
                        .duplicatePosts$({
                            fromRestaurantId,
                            restaurantIds: data.map((d) => d.restaurant.id),
                            postIdsToDuplicate: [post.id],
                            postDestination,
                            customFields: data.map((d) => ({
                                restaurantId: d.restaurant.id,
                                hashtags: d.hashtags,
                                location: d.location,
                                text: d.text,
                                plannedPublicationDate: d.plannedPublicationDate,
                                platformKeys: d.platformKeys,
                                published: d.status,
                            })),
                        })
                        .pipe(
                            tap(() =>
                                this._toastService.openSuccessToast(this._translateService.instant('social_posts.success_duplicate_post'))
                            ),
                            map(() => ({ selectedRestaurants: data.map((d) => d.restaurant), index: index + 1 })),
                            catchError((error) => {
                                console.error('Error while duplicating post', error);
                                this._toastService.openErrorToast(this._translateService.instant('social_posts.error_duplicate_post'));
                                return of({ selectedRestaurants: data.map((d) => d.restaurant), index: index + 1 });
                            })
                        ),
            })),
        ];
    }

    private _getSocialPlatformKeysWithPost(): PlatformKey[] {
        const allPlatformKeys = PlatformDefinitions.getSocialPlatformKeysWithPost();
        return this._getEnabledPlatformKeys(allPlatformKeys);
    }

    private _getSocialPlatformKeysWithReel(): PlatformKey[] {
        const allPlatformKeys = PlatformDefinitions.getPlatformKeysWithReel();
        return this._getEnabledPlatformKeys(allPlatformKeys);
    }

    private _getEnabledPlatformKeys(platformKeys: PlatformKey[]): PlatformKey[] {
        const featureFlaggedPlatforms = PlatformDefinitions.getFeatureFlaggedPlatforms();
        const enabledPlatformKeys = platformKeys.filter((platformKey) => {
            const ffPlatform = featureFlaggedPlatforms.find((platform) => platform.key === platformKey);
            return !ffPlatform || (ffPlatform.featureFlagKey && this._experimentationService.isFeatureEnabled(ffPlatform.featureFlagKey));
        });
        return enabledPlatformKeys;
    }
}
