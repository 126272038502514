import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';

import { ScanForAggregatedInsightsDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { GiftDrawsService } from ':core/services/gift-draws.service';
import { ScansService } from ':core/services/scans.service';
import { DatesAndPeriod, FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, LightNfc, Nfc, Restaurant } from ':shared/models';
import { ScanForAggregatedInsights } from ':shared/models/scan';

import { AggregatedBoostersStatisticsDataV2, AggregatedWheelOfFortuneGiftsStatisticsData } from '../booster.interface';

@Injectable({
    providedIn: 'root',
})
export class BoostersAggregatedDataFetchingServiceV2 {
    private readonly _translateService = inject(TranslateService);
    private readonly _scansService = inject(ScansService);
    private readonly _giftDrawsService = inject(GiftDrawsService);

    getChartsData(nfcs: LightNfc[], dates: DatesAndPeriod, restaurants: Restaurant[]): Observable<AggregatedBoostersStatisticsDataV2> {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const ScanForAggregatedInsights$: Observable<ScanForAggregatedInsightsDto> = this._scansService
            .getInsightsForRestaurants({
                nfcIds: nfcs?.map((nfc) => nfc.id).concat([FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS]) ?? [],
                restaurantIds: restaurants.map(({ _id }) => _id.toString()),
                startScannedAt: startDate.toISOString(),
                endScannedAt: endDate.toISOString(),
            })
            .pipe(
                map((apiResult) => ({
                    scans: apiResult.data.scans,
                    scansEvolution: apiResult.data.scansEvolution,
                    wofScansEvolution: apiResult.data.wofScansEvolution,
                    estimatedReviewCountPerRestaurant: apiResult.data.estimatedReviewCountPerRestaurant,
                })),
                shareReplay(1)
            );
        const wheelOfFortuneFakeNfcs = restaurants.map((restaurant) =>
            Nfc.createLightFakeWheelOfFortuneNfc({
                restaurantId: restaurant._id,
                platformKey: PlatformKey.GMB,
                restaurantName: '',
                name: this._translateService.instant('aggregated_statistics.boosters.scans.wheel_of_fortune'),
            })
        );

        const scansEvolution$ = ScanForAggregatedInsights$.pipe(map(({ scansEvolution }) => scansEvolution));
        const wofScansEvolution$ = ScanForAggregatedInsights$.pipe(map(({ wofScansEvolution }) => wofScansEvolution));
        const scans$ = ScanForAggregatedInsights$.pipe(map(({ scans }) => scans.map((scan) => new ScanForAggregatedInsights(scan))));
        const wheelOfFortuneCount$ = scans$.pipe(map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length));
        const estimatedReviewCountPerRestaurant$ = ScanForAggregatedInsights$.pipe(
            map(({ estimatedReviewCountPerRestaurant }) => estimatedReviewCountPerRestaurant)
        );

        return forkJoin({
            nfcs: of(nfcs.concat([...wheelOfFortuneFakeNfcs])),
            scans: scans$,
            scanEvolution: scansEvolution$,
            wofScansEvolution: wofScansEvolution$,
            wheelOfFortuneCount: wheelOfFortuneCount$,
            estimatedReviewCountPerRestaurant: estimatedReviewCountPerRestaurant$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    }

    getGiftsData = (dates: DatesAndPeriod, restaurants: Restaurant[]): Observable<AggregatedWheelOfFortuneGiftsStatisticsData> => {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const giftsInsightsPerRestaurant$ = this._giftDrawsService
            .getInsightsByRestaurants({
                restaurantIds: restaurants.map((restaurant) => restaurant.id),
                startDate,
                endDate,
            })
            .pipe(map((res) => res?.data));

        return forkJoin({
            giftsInsightsPerRestaurant: giftsInsightsPerRestaurant$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    };
}
