<div class="h-full overflow-y-auto">
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="DEFAULT_SORT.active"
        [matSortDirection]="DEFAULT_SORT.direction">
        <ng-container [matColumnDef]="TableField.RATING">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'statistics.e_reputation.reviews_ratings.title' | translate }}
            </mat-header-cell>
            <mat-cell
                *matCellDef="let data"
                class="malou-text-10--bold !justify-start pl-2 sm:!pl-0"
                [matTooltip]="data[TableField.RATING]">
                @for (i of data[TableField.RATING] | createIndexArray; track i) {
                    <mat-icon class="relative mr-1 !h-3.5 !w-5 text-malou-color-state-warn" [svgIcon]="SvgIcon.STAR"></mat-icon>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.COMPARISON_PERIOD">
            <mat-header-cell *matHeaderCellDef class="justify-end" mat-sort-header>
                {{ comparisonPeriodKey() | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data" class="justify-end">
                <span class="malou-color-text-1 malou-text-14--regular">
                    @if (data[TableField.COMPARISON_PERIOD]) {
                        {{ data[TableField.COMPARISON_PERIOD] | shortNumber }}
                    } @else {
                        -
                    }
                </span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.SELECTED_PERIOD">
            <mat-header-cell *matHeaderCellDef class="justify-end" mat-sort-header>
                {{ 'statistics.e_reputation.reviews_ratings.selected_period' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data" class="justify-end">
                <div class="flex gap-1">
                    <span class="malou-color-text-1 malou-text-14--regular">
                        {{ data[TableField.SELECTED_PERIOD] | shortNumber }}
                    </span>
                    @if (data.evolution) {
                        <app-number-evolution
                            [value]="data.evolution ?? null"
                            [size]="'small'"
                            [displayedValue]="data.evolution | shortNumber: { content: '', fixNumber: 0, shouldDisplayMinusSign: false }"
                            [reverseArrow]="false">
                        </app-number-evolution>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: displayedColumns" class="sm:!justify-around sm:!justify-items-start"></mat-row>
    </mat-table>
</div>
