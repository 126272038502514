import { z } from 'zod';

import { FeedbackMessageVisibility, Role } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const updateFeedbackMessageParamsValidator = z
    .object({
        feedback_id: objectIdValidator,
        message_id: objectIdValidator,
    })
    .transform((data) => ({
        feedbackId: data.feedback_id,
        messageId: data.message_id,
    }));
export type UpdateFeedbackMessageParamsDto = z.infer<typeof updateFeedbackMessageParamsValidator>;

export const updateFeedbackMessageQueryValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type UpdateFeedbackMessageQueryDto = z.infer<typeof updateFeedbackMessageQueryValidator>;

export const updateFeedbackMessageBodyValidator = z.object({
    message: z.object({
        text: z.string().nullish(),
        visibility: z.nativeEnum(FeedbackMessageVisibility),
    }),
    participants: z.array(
        z.object({
            id: objectIdValidator,
            name: z.string(),
            lastname: z.string().nullish(),
            email: z.string().email(),
            role: z.nativeEnum(Role).nullish(),
            userId: objectIdValidator.nullish(),
        })
    ),
});
export type UpdateFeedbackMessageBodyDto = z.infer<typeof updateFeedbackMessageBodyValidator>;
