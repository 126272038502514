import CountryLanguage from '@ladjs/country-language';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { isNil } from 'lodash';

import {
    ApplicationLanguage,
    applicationLanguageDisplayName,
    CaslRole,
    COUNTRY_CODES,
    FileFormat,
    LANGUAGES,
    TimeInMilliseconds,
} from './constants';
import { LanguageCodeISO_1 } from './constants/languages';
import { GrowthVariation } from './email';
import { generateDbId } from './id.provider';
import { DefaultEntityProperties } from './utility-types';

export function isNotNil<T>(object: T | null | undefined): object is T {
    return !isNil(object);
}

export function isNotNilOrEmpty<T>(object: T | null | undefined): object is T {
    return !isNil(object) && !!object;
}

export function flattenObject(obj: Record<string, any>, parentKey?: string) {
    let result: Record<string, any> = {};

    Object.entries(obj).forEach(([key, value]) => {
        const _key = parentKey ? `${parentKey}.${key}` : key;
        if (typeof value === 'object' && !Array.isArray(value) && value !== null && value !== undefined) {
            result = { ...result, ...flattenObject(value, _key) };
        } else {
            result[_key] = value;
        }
    });
    return result;
}

export function capitalize(val) {
    if (typeof val !== 'string') {
        val = '';
    }
    return val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
}

export function toLowerCase(type) {
    return type.toLowerCase();
}

export const formatPhoneNumber = (phoneNumber: string, country: string): { prefix: number; digits: number } | null => {
    const phoneString = phoneNumber.toString();
    const countryCode = COUNTRY_CODES.find((c) => c === country.toUpperCase()) as CountryCode;
    try {
        const nationalNumber = parsePhoneNumber(phoneString, countryCode).nationalNumber;
        const parsedPhone = parsePhoneNumber(nationalNumber, countryCode);
        const res = {
            prefix: parseInt(parsedPhone.countryCallingCode, 10),
            digits: parseInt(parsedPhone.nationalNumber, 10),
        };
        return res;
    } catch (error) {
        return null;
    }
};

export const getLanguageFromCountryCode = (countryCode?: string): string => {
    const defaultLanguage = 'en';

    // Return english as default if no country code is provided
    if (!countryCode) {
        return defaultLanguage;
    }

    // Get country's official language ISO codes
    const officialLanguageCodes: string[] = CountryLanguage.getCountryLanguages(countryCode.toLowerCase()).map(({ iso639_1 }) => iso639_1);

    // Find first language code that is a key in LANGUAGES
    const languageCode = officialLanguageCodes.find((code) => code in LANGUAGES) ?? defaultLanguage;

    // Return corresponding language
    return languageCode;
};

export const isFulfilled = (v: any): v is PromiseFulfilledResult<any> => v?.status === 'fulfilled';

export const processPromisesByChunks = async <T>(
    promises: (() => Promise<T>)[],
    chunkSize: number,
    afterChunkProcessed?: (chunkResults: T[]) => Promise<void>
): Promise<T[]> => {
    const results: T[] = [];

    for (let i = 0; i < promises.length; i += chunkSize) {
        const chunk = promises.slice(i, i + chunkSize);
        const chunkResults = await Promise.allSettled(chunk.map((asyncFunc) => asyncFunc()));

        const res = chunkResults
            .filter((result) => isFulfilled(result))
            .map((result) => isFulfilled(result) && result.value)
            .filter((result) => result !== undefined) as T[];

        results.push(...res);

        if (afterChunkProcessed) {
            await afterChunkProcessed(res);
        }
    }

    return results;
};

export const computeGrowth = (current: number, previous: number): number => {
    return previous === 0 ? 0 : ((current - previous) / previous) * 100;
};

export const getGrowthVariation = (growth: number): GrowthVariation => {
    if (growth > 0) {
        return GrowthVariation.UP;
    } else if (growth < 0) {
        return GrowthVariation.DOWN;
    } else {
        return GrowthVariation.SAME;
    }
};

export const numberToFixed = (number: number | undefined, decimals: number = 1): number => {
    return +(number?.toFixed(decimals) ?? 0) || 0;
};

export const roundToDecimals = (number: number, decimals: number): number => {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const projectionToArray = (fields: string | string[] | undefined): string[] => {
    if (!fields) {
        return [];
    }

    return Array.isArray(fields) ? fields : fields.split(' ');
};

export const provideEntityDefaultProperties = (): DefaultEntityProperties => {
    return {
        id: generateDbId().toHexString(),
        createdAt: new Date(),
        updatedAt: new Date(),
    };
};

export const getFileExtension = (filename: string): string => {
    const extension = filename.split('.').pop() || '';
    // filenames from Facebook & Instagram can have a query string at the end
    if (!Object.values(FileFormat).includes(extension as FileFormat)) {
        return filename.split('?')?.[0]?.split('.').pop() || '';
    }
    return extension;
};

export const getFileFormatFromExtension = (extension: string): FileFormat => {
    switch (extension.toLowerCase()) {
        case 'png':
            return FileFormat.PNG;
        case 'jpg':
            return FileFormat.JPG;
        case 'jpeg':
            return FileFormat.JPEG;
        case 'mp4':
            return FileFormat.MP4;
        case 'quicktime':
            return FileFormat.QUICKTIME;
        case 'mov':
            return FileFormat.MOV;
        default:
            return FileFormat.JPEG;
    }
};

export const isLangInApplicationLanguages = (lang: string): lang is ApplicationLanguage => {
    return Object.values(ApplicationLanguage)
        .map((appLang) => appLang.toString())
        .includes(lang);
};

export const getApplicationLanguageDisplayName = (lang: ApplicationLanguage | string, displayNameLang: string): string | null => {
    return applicationLanguageDisplayName[lang]?.[displayNameLang] ?? null;
};

export const waitFor = async function (timeMs: number): Promise<number> {
    return new Promise(function (resolve) {
        setTimeout(() => {
            resolve(1);
        }, timeMs);
    });
};

export const exponentialBackoffDelay = (retryCount: number, baseDelayInMs = 15 * TimeInMilliseconds.SECOND): number =>
    Math.pow(2, retryCount) * baseDelayInMs;

export const mapLanguageStringsToISO = (langs: string[]): LanguageCodeISO_1[] => {
    return langs
        .map((lang) => lang.slice(0, 2))
        .map((lang) => LanguageCodeISO_1[lang.toUpperCase()])
        .filter(isNotNil);
};

export const mapLanguageStringToApplicationLanguage = (lang: string): ApplicationLanguage => {
    switch (lang) {
        case 'en':
            return ApplicationLanguage.EN;
        case 'fr':
            return ApplicationLanguage.FR;
        case 'it':
            return ApplicationLanguage.IT;
        case 'es':
            return ApplicationLanguage.ES;
        default:
            return ApplicationLanguage.EN;
    }
};

export const mapRoleStringToCaslEnum = (role: string): CaslRole => {
    switch (role) {
        case 'admin':
            return CaslRole.ADMIN;
        case 'editor':
            return CaslRole.EDITOR;
        case 'guest':
            return CaslRole.GUEST;
        case 'owner':
            return CaslRole.OWNER;
        case 'moderator':
            return CaslRole.MODERATOR;
        default:
            return CaslRole.GUEST;
    }
};

export const emojiToPngUrl = (emoji: string): string => {
    // Convert each character in the emoji string to its Unicode code point
    const [unicode] = Array.from(emoji).map((char) => char.codePointAt(0)!.toString(16).padStart(4, '0'));

    return `https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/${unicode}.png`;
};

export const mergeObjectsInto = <T extends Record<string, any>>(baseObject: T, ...objects: T[]): T => {
    return Object.assign(baseObject, ...objects);
};

export function updateObjectDeepProperty(obj: any, path: string, value: any) {
    const keys = path.split('.');

    // Clone the object first to avoid mutating the original
    const newObj = cloneDeep(obj);

    // Traverse the object to the desired path
    let current = newObj;
    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {}; // Create intermediate objects if they don't exist
        }
        current = current[keys[i]];
    }

    // Set the value at the final key
    current[keys[keys.length - 1]] = value;

    return newObj;
}

function cloneDeep(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(cloneDeep);
    }

    const clonedObj: any = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) clonedObj[key] = cloneDeep(obj[key]);
    }
    return clonedObj;
}
