import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    input,
    OnInit,
    output,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, map, tap } from 'rxjs/operators';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

export enum SearchBarDisplayStyle {
    DEFAULT = 'default',
    SMALL = 'small',
}

@Component({
    selector: 'app-search',
    templateUrl: 'search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: true,
    imports: [NgClass, FormsModule, MatIconModule, MatMenuModule, MatProgressSpinnerModule, ReactiveFormsModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
    /**
     * Placeholder
     *
     * @optional
     */
    readonly placeholder = input<string>('');

    /**
     * Debounce time (in milliseconds)
     *
     * @optional
     */
    readonly debounceTime = input<number>(500);

    /**
     * default value
     *
     * @optional
     */
    readonly value = input<string>('');

    /**
     * boolean to show loading spinner
     * @optional
     * @default false
     * */
    readonly isLoading = input<boolean>(false);

    /**
     * boolean to disable the input
     */
    readonly disabled = input<boolean>(false);

    readonly errorMessage = input<string | undefined>();

    /**
     * boolean to build new value when there is no found value in the search
     *
     * @optional
     */
    readonly shouldBuildValueIfNone = input<boolean>(false);

    /**
     * On value change output
     */
    readonly searchChange = output<string>();

    /**
     * On value change output
     */
    readonly valueBuilt = output<string>();

    readonly pressEnterKey = output<string>();

    /**
     * To easily retrieve changed value event form input
     */
    readonly menuTrigger = viewChild<MatMenuTrigger>('menuTrigger');
    readonly inputElement = viewChild<ElementRef>('inputElement');

    readonly control = new UntypedFormControl();

    readonly isFocused = signal(false);
    readonly isEmptyValue = signal(true);

    readonly testId = input<string>();

    readonly theme = input<SearchBarDisplayStyle>(SearchBarDisplayStyle.DEFAULT);

    readonly SvgIcon = SvgIcon;

    private _previousValue: string;

    private readonly _destroyRef = inject(DestroyRef);

    readonly SearchBarDisplayStyle = SearchBarDisplayStyle;

    private _isPreviousValueInitialized = false;

    constructor() {
        effect(() => {
            const value = this.value();
            if (value !== this._previousValue && this._isPreviousValueInitialized) {
                this.control.setValue(value);
            }
        });
        effect(() => {
            const disabled = this.disabled();
            if (disabled) {
                this.control.disable();
            } else {
                this.control.enable({ emitEvent: false });
            }
        });
        effect(() => {
            const shouldBuildValueIfNone = this.shouldBuildValueIfNone();
            if (this.control.value) {
                this.handleValueChange(this.control.value, shouldBuildValueIfNone);
            }
        });
    }

    ngOnInit(): void {
        this._previousValue = this.value();
        this._isPreviousValueInitialized = true;
        this.control.valueChanges
            .pipe(
                map((value) => (typeof value === 'string' ? value : '')),
                tap((value) => this.setEmptyValue(value)),
                debounceTime(this.debounceTime()),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((value) => {
                this.handleValueChange(value, this.shouldBuildValueIfNone());
            });
    }

    handleValueChange(value: string, shouldBuildValueIfNone: boolean): void {
        this.valueChangeFn(value);

        if (!value?.length || !shouldBuildValueIfNone) {
            this.menuTrigger()?.closeMenu();
            this.inputElement()?.nativeElement?.removeEventListener('keydown', this.onPressEnterForBuildValue);
        }

        if (value?.length && shouldBuildValueIfNone) {
            this.menuTrigger()?.openMenu();
            setTimeout(() => this.inputElement()?.nativeElement?.focus());
            this.inputElement()?.nativeElement?.addEventListener('keydown', this.onPressEnterForBuildValue);
        }

        if (value?.length && !shouldBuildValueIfNone) {
            this.inputElement()?.nativeElement?.addEventListener('keydown', this.onPressEnter);
        }

        if (!value?.length) {
            this.inputElement()?.nativeElement?.removeEventListener('keydown', this.onPressEnter);
        }
    }

    onPressEnterForBuildValue = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            this.buildValue();
            this.control.setValue('');
        }
    };

    onPressEnter = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            this.pressEnterKey.emit(this.control.value);
        }
    };

    setEmptyValue(value: string): void {
        this.isEmptyValue.set(!value);
    }

    valueChangeFn(value: string): void {
        this.searchChange.emit(value);
    }

    resetValue(): void {
        this.control.setValue('');
        this.menuTrigger()?.closeMenu();
    }

    buildValue(): void {
        this.valueBuilt.emit(this.control.value);
        this.menuTrigger()?.closeMenu();
        this.control.setValue('');
    }
}
