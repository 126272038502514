import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const updatePlannedPublicationDateBodyValidator = z
    .object({
        plannedPublicationDate: z.string().datetime(),
    })
    .transform((data) => ({ plannedPublicationDate: new Date(data.plannedPublicationDate) }));
export type UpdatePlannedPublicationDateBodyDto = z.infer<typeof updatePlannedPublicationDateBodyValidator>;

export const updatePlannedPublicationDateParamsValidator = z
    .object({
        post_id: objectIdValidator,
    })
    .transform((data) => ({ postId: data.post_id }));
export type UpdatePlannedPublicationDateParamsDto = z.infer<typeof updatePlannedPublicationDateParamsValidator>;
