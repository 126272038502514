import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ApiResult, Restaurant } from ':shared/models';

type AuthorizedPlatformKeys =
    | PlatformKey.TRIPADVISOR
    | PlatformKey.FOURSQUARE
    | PlatformKey.YELP
    | PlatformKey.LAFOURCHETTE
    | PlatformKey.OPENTABLE
    | PlatformKey.RESY;

@Injectable({
    providedIn: 'root',
})
export class UpdateAccountManagementPlatformService {
    private readonly _platformsService = inject(PlatformsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _reviewsService = inject(ReviewsService);

    execute(platformKey: AuthorizedPlatformKeys, socialId: string): Observable<ApiResult<Restaurant>> {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        const platformToUpsert = {
            key: platformKey,
            restaurantId: restaurantId,
            socialId,
        };

        return this._platformsService.upsert(restaurantId, platformToUpsert).pipe(
            tap(() => {
                // We do not await this line because this request can be very long and timeout
                this._platformsService.pullOverview(restaurantId, platformKey, true).subscribe();
            }),
            switchMap(() => this._reviewsService.deleteRestaurantReviewsForPlatform(platformKey, restaurantId))
        );
    }
}
