import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import {
    getPreviousMonthYear,
    getRangeForMonthYearPeriod,
    KeywordSearchImpressionsType,
    MonthAndYear,
    MonthYearPeriod,
    PlatformKey,
    TooltipPosition,
} from '@malou-io/package-utils';

import { ImpressionsInsightsChartComponent } from ':modules/statistics/seo/keyword-search-impressions/impressions-insights/impressions-insights-chart/impressions-insights-chart.component';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { CustomTooltipDirective } from ':shared/directives/custom-tooltip-directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface ImpressionsInsightsEvolutionData {
    [KeywordSearchImpressionsType.BRANDING]: {
        total: number;
        evolution: number;
    };
    [KeywordSearchImpressionsType.DISCOVERY]: {
        total: number;
        evolution: number;
    };
}

@Component({
    selector: 'app-impressions-insights',
    standalone: true,
    imports: [
        ImpressionsInsightsChartComponent,
        NgTemplateOutlet,
        NumberEvolutionComponent,
        ShortNumberPipe,
        TranslateModule,
        MatIconModule,
        MatTooltipModule,
        PlatformLogoPathResolverPipe,
        CustomTooltipDirective,
    ],
    templateUrl: './impressions-insights.component.html',
    styleUrl: './impressions-insights.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpressionsInsightsComponent {
    readonly currentKeywordSearchImpressionsInsights = input.required<ImpressionsInsightsData[]>();
    readonly previousKeywordSearchImpressionsInsights = input.required<ImpressionsInsightsData[]>();
    readonly monthYearPeriod = input.required<MonthYearPeriod>();
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly hiddenDatasetIndexesChange = output<number[]>();

    readonly impressionsInsightsData: Signal<ImpressionsInsightsData[]> = computed(() =>
        this._getImpressionsInsightsData({
            currentImpressionsData: this.currentKeywordSearchImpressionsInsights(),
            monthYearPeriod: this.monthYearPeriod(),
        })
    );

    readonly impressionsInsightsEvolutionData: Signal<ImpressionsInsightsEvolutionData> = computed(() =>
        this._getImpressionsInsightsEvolutionData(
            this.currentKeywordSearchImpressionsInsights(),
            this.previousKeywordSearchImpressionsInsights(),
            this.monthYearPeriod()
        )
    );

    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;
    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;
    readonly TooltipPosition = TooltipPosition;

    onHiddenDatasetIndexesChange(hiddenDatasetIndexes: number[]): void {
        this.hiddenDatasetIndexesChange.emit(hiddenDatasetIndexes);
    }

    private _getImpressionsInsightsData({
        currentImpressionsData,
        monthYearPeriod,
    }: {
        currentImpressionsData: ImpressionsInsightsData[];
        monthYearPeriod: MonthYearPeriod;
    }): ImpressionsInsightsData[] {
        const monthAndYearRange: MonthAndYear[] = getRangeForMonthYearPeriod(monthYearPeriod);

        return monthAndYearRange.map((monthAndYear) => {
            const monthInsights = currentImpressionsData.find(
                (insight) => insight.month === monthAndYear.month && insight.year === monthAndYear.year
            );
            return {
                month: monthAndYear.month,
                year: monthAndYear.year,
                [KeywordSearchImpressionsType.BRANDING]: monthInsights?.[KeywordSearchImpressionsType.BRANDING] ?? 0,
                [KeywordSearchImpressionsType.DISCOVERY]: monthInsights?.[KeywordSearchImpressionsType.DISCOVERY] ?? 0,
            };
        });
    }

    private _getImpressionsInsightsEvolutionData(
        currentImpressionsData: ImpressionsInsightsData[],
        previousImpressionsData: ImpressionsInsightsData[],
        monthYearPeriod: MonthYearPeriod
    ): ImpressionsInsightsEvolutionData {
        const accumulateImpressionsByType = (
            acc,
            current
        ): { [KeywordSearchImpressionsType.BRANDING]: number; [KeywordSearchImpressionsType.DISCOVERY]: number } => {
            acc[KeywordSearchImpressionsType.BRANDING] += current[KeywordSearchImpressionsType.BRANDING];
            acc[KeywordSearchImpressionsType.DISCOVERY] += current[KeywordSearchImpressionsType.DISCOVERY];
            return acc;
        };

        const total = currentImpressionsData.reduce(accumulateImpressionsByType, {
            [KeywordSearchImpressionsType.BRANDING]: 0,
            [KeywordSearchImpressionsType.DISCOVERY]: 0,
        });

        const previousMonthYearsToExclude = this._getPreviousMonthYearToExclude(currentImpressionsData, monthYearPeriod);
        const previousImpressionsDataFiltered = previousImpressionsData.filter(
            (impressionsData) =>
                !previousMonthYearsToExclude.some(
                    (monthYear) => monthYear.month === impressionsData.month && monthYear.year === impressionsData.year
                )
        );
        const previousTotal = previousImpressionsDataFiltered.reduce(accumulateImpressionsByType, {
            [KeywordSearchImpressionsType.BRANDING]: 0,
            [KeywordSearchImpressionsType.DISCOVERY]: 0,
        });

        return {
            [KeywordSearchImpressionsType.BRANDING]: {
                total: total[KeywordSearchImpressionsType.BRANDING],
                evolution: total[KeywordSearchImpressionsType.BRANDING] - previousTotal[KeywordSearchImpressionsType.BRANDING],
            },
            [KeywordSearchImpressionsType.DISCOVERY]: {
                total: total[KeywordSearchImpressionsType.DISCOVERY],
                evolution: total[KeywordSearchImpressionsType.DISCOVERY] - previousTotal[KeywordSearchImpressionsType.DISCOVERY],
            },
        };
    }

    private _getPreviousMonthYearToExclude(
        currentImpressionsData: ImpressionsInsightsData[],
        monthYearPeriod: MonthYearPeriod
    ): MonthAndYear[] {
        const monthAndYearRange: MonthAndYear[] = getRangeForMonthYearPeriod(monthYearPeriod);
        const currentMonthYearsWithoutData = monthAndYearRange.filter(
            (monthAndYear) =>
                !currentImpressionsData.some((insight) => insight.month === monthAndYear.month && insight.year === monthAndYear.year)
        );
        const monthsCount = monthAndYearRange.length;
        return currentMonthYearsWithoutData.map((monthAndYear) => getPreviousMonthYear(monthAndYear, monthsCount));
    }
}
