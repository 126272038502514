import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, take } from 'rxjs';

import { LuxonFormats, MonthYearPeriod } from '@malou-io/package-utils';

import { TopKeywordSearchImpressionsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { selectMonthYearPeriodFilter, selectTopKeywordSearchImpressionsData } from ':modules/statistics/store/statistics.selectors';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    monthYearPeriod: MonthYearPeriod;
    topKeywordSearchImpressionsData: TopKeywordSearchImpressionsData;
}

@Injectable({ providedIn: 'root' })
export class TopKeywordSearchImpressionsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin([
            this._store.select(selectTopKeywordSearchImpressionsData).pipe(take(1)),
            this._store.select(selectMonthYearPeriodFilter).pipe(take(1)),
        ]).pipe(
            map(([topKeywordSearchImpressionsData, monthYearPeriod]) => ({
                monthYearPeriod,
                topKeywordSearchImpressionsData,
            }))
        );
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data && (!!data.topKeywordSearchImpressionsData.branding || !!data.topKeywordSearchImpressionsData.discovery);
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Start date', 'End date', 'User search', 'Type', 'Apparitions'];
    }

    protected override _getCsvDataRows({ monthYearPeriod, topKeywordSearchImpressionsData }: Data): CsvAsStringArrays {
        const startDate = DateTime.fromObject(monthYearPeriod.startMonthYear).startOf('month').toFormat(LuxonFormats.numericYearMonthDay);
        const endDate = DateTime.fromObject(monthYearPeriod.endMonthYear).endOf('month').toFormat(LuxonFormats.numericYearMonthDay);

        const mergedImpressions = [...topKeywordSearchImpressionsData.branding, ...topKeywordSearchImpressionsData.discovery];

        const impressionsSortedByValue = mergedImpressions.sort((a, b) => b.value - a.value);

        return impressionsSortedByValue.map((impression) => [
            startDate,
            endDate,
            impression.keywordSearch,
            impression.type,
            impression.value.toString(),
        ]);
    }
}
