import { getMostRecentRank } from '@malou-io/package-utils';

import { RestaurantsRankings } from './keyword-search-impressions.interface';

export class RestaurantWithKeywordsAndTopMap {
    private _map: Map<
        string,
        {
            restaurantName: string;
            keywords: string[];
            keywordsTop20: string[];
            keywordsTop3: string[];
            top20: number;
            top3: number;
        }
    > = new Map();

    constructor(props: RestaurantsRankings) {
        for (const restaurantStats of props.restaurants) {
            const { id } = restaurantStats.restaurantInfo;
            const keywords: string[] = [];
            const keywordsTop20: string[] = [];
            const keywordsTop3: string[] = [];
            let top20 = 0;
            let top3 = 0;

            for (const keywordStats of restaurantStats.keywords) {
                keywords.push(keywordStats.name);
                const rank = getMostRecentRank(keywordStats)?.position ?? undefined;
                if (rank !== undefined) {
                    if (rank.rank <= 3) {
                        top3++;
                        keywordsTop3.push(keywordStats.name);
                    }

                    if (rank.rank <= 20) {
                        top20++;
                        keywordsTop20.push(keywordStats.name);
                    }
                }
            }

            this._map.set(id, {
                restaurantName: restaurantStats.restaurantInfo.internalName ?? restaurantStats.restaurantInfo.name,
                keywords,
                keywordsTop20,
                keywordsTop3,
                top20,
                top3,
            });
        }
    }

    get(id: string):
        | {
              restaurantName: string;
              keywords: string[];
              keywordsTop20: string[];
              keywordsTop3: string[];
              top20: number;
              top3: number;
          }
        | undefined {
        return this._map.get(id);
    }
}
