<div class="h-full overflow-y-auto">
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        [matSortActive]="DEFAULT_SORT.active"
        [matSortDirection]="DEFAULT_SORT.direction"
        (matSortChange)="sortChange.emit($event)">
        <ng-container [matColumnDef]="TableField.RESTAURANT_NAME">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'reviews_count_table.restaurant_name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                <span class="inline break-words">
                    {{ data[TableField.RESTAURANT_NAME] }}
                    <mat-icon class="display-on-hover ml-1 !hidden !h-3 !w-3" color="primary" [svgIcon]="SvgIcon.EXPORT"></mat-icon>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="TableField.REVIEW_COUNT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'reviews_count_table.review_count' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
                <div class="flex gap-x-2">
                    <div>
                        {{ data[TableField.REVIEW_COUNT] }}
                    </div>
                    <div>
                        <app-number-evolution
                            size="small"
                            [value]="data.evolution"
                            [displayedValue]="data.evolution | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            *matRowDef="let data; columns: displayedColumns"
            (click)="redirectToRestaurantEReputationStatsPage(data.restaurantId)"></mat-row>
    </mat-table>
</div>
