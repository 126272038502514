<div class="flex h-12 items-center justify-between rounded-[10px] border border-malou-color-background-dark py-3 pl-2 pr-6">
    <div class="flex gap-2">
        <div class="flex items-center rounded-[5px] bg-malou-color-background-dark p-1">
            <mat-icon class="!h-5 !w-5" color="primary" [svgIcon]="SvgIcon.HISTORY"></mat-icon>
        </div>
        <div class="flex items-center">
            <span class="malou-text-14--semibold mx-1 text-malou-color-text-1">
                {{ 'informations.special_hours.default_name' | translate }} -
            </span>
            <span class="malou-text-14--regular ml-1 italic text-malou-color-text-2">
                {{ schedule() | applySelfPure: 'getDisplayStartDate' : LANG }}
            </span>
        </div>
    </div>
    <div class="malou-text-14--regular flex text-malou-color-text-1">
        {{ hoursInterval() }}
    </div>
</div>
