<div class="flex flex-col">
    <div class="my-6 flex flex-col items-center justify-center gap-2 px-4">
        <span class="malou-text-20--bold malou-color-text-1">
            {{ 'statistics_pdf.e_reputation_pdf.title' | translate }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ { startDate, endDate } | fromToDateFormatter }}</span>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantTitle$ | async | statisticsPdfRestaurantsFormatter }}
        </span>
        <span class="malou-text-11--regular malou-color-text-2 italic">{{ displayedPlatforms }}</span>
    </div>

    <div class="px-8.5 py-4">
        <div class="flex flex-col gap-12">
            @if ((displayedCharts | includes: InsightsChart.REVIEW_RATINGS_KPIS) && seoRatingsHasData) {
                <div class="break-inside-avoid">
                    <app-statistics-seo-ratings (hasDataChange)="seoRatingsHasData = $event"> </app-statistics-seo-ratings>
                </div>
            }
            @if ((displayedCharts | includes: InsightsChart.REVIEW_KPIS) && reviewKpisHasData) {
                <div class="break-inside-avoid">
                    <app-reviews-kpis (hasDataChange)="reviewKpisHasData = $event"></app-reviews-kpis>
                </div>
            }

            <div class="mb-4 flex gap-6">
                @if ((displayedCharts | includes: InsightsChart.REVIEW_RATING_EVOLUTION) && reviewRatingsEvolutionHasData) {
                    <div class="min-w-0 flex-1 break-inside-avoid">
                        <app-reviews-ratings-evolution
                            [hiddenDatasetIndexes]="chartOptions[InsightsChart.REVIEW_RATING_EVOLUTION]?.hiddenDatasetIndexes ?? []"
                            [viewBy]="chartOptions[InsightsChart.REVIEW_RATING_EVOLUTION]?.viewBy"
                            [showViewByTextInsteadOfSelector]="true"
                            (hasDataChange)="reviewRatingsEvolutionHasData = $event">
                        </app-reviews-ratings-evolution>
                    </div>
                }
                @if ((displayedCharts | includes: InsightsChart.REVIEW_RATING_TOTAL) && reviewRatingsTotalHasData) {
                    <div class="h-full min-w-0 flex-1 break-inside-avoid">
                        <app-reviews-ratings-total
                            [statisticsDataViewMode]="
                                chartOptions[InsightsChart.REVIEW_RATING_TOTAL]?.viewMode ?? StatisticsDataViewMode.CHART
                            "
                            (hasDataChange)="reviewRatingsTotalHasData = $event"></app-reviews-ratings-total>
                    </div>
                }
            </div>

            @if (
                reviewAnalysesHasData &&
                ((displayedCharts | includes: InsightsChart.REVIEW_ANALYSES_TAG_CHARTS) ||
                    (displayedCharts | includes: InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION))
            ) {
                <div class="break-inside-avoid">
                    <app-review-analyses
                        [shouldDisplayAnalysesTagClickableLabels]="false"
                        [shouldDetailTagsEvolutionCharts]="true"
                        [shouldDisplayAnalysesTagCharts]="displayedCharts | includes: InsightsChart.REVIEW_ANALYSES_TAG_CHARTS"
                        [shouldDisplayAnalysesTagEvolution]="displayedCharts | includes: InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION"
                        [tagEvolutionViewBy]="chartOptions[InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION]?.viewBy ?? ViewBy.DAY"
                        (hasDataChange)="reviewAnalysesHasData = $event">
                    </app-review-analyses>
                </div>
            }

            @if (
                (displayedCharts | includes: InsightsChart.SEMANTIC_ANALYSIS_REVIEWS) ||
                (displayedCharts | includes: InsightsChart.SEMANTIC_ANALYSIS_TOPICS_EVOLUTION) ||
                (displayedCharts | includes: InsightsChart.SEMANTIC_ANALYSIS_TOP_TOPICS)
            ) {
                <div class="break-inside-avoid">
                    <app-semantic-analysis
                        [isPdfDownload]="true"
                        [viewBy]="chartOptions[InsightsChart.SEMANTIC_ANALYSIS_TOPICS_EVOLUTION]?.viewBy ?? ViewBy.DAY"
                        [shouldDisplaySemanticAnalysisForReviews]="displayedCharts | includes: InsightsChart.SEMANTIC_ANALYSIS_REVIEWS"
                        [shouldDisplaySemanticAnalysisTopicsEvolution]="
                            displayedCharts | includes: InsightsChart.SEMANTIC_ANALYSIS_TOPICS_EVOLUTION
                        "
                        [shouldDisplaySemanticAnalysisTopTopics]="
                            displayedCharts | includes: InsightsChart.SEMANTIC_ANALYSIS_TOP_TOPICS
                        "></app-semantic-analysis>
                </div>
            }
        </div>
    </div>
</div>
