import { findPhoneNumbersInText } from 'libphonenumber-js';
import tlds from 'tlds';

import { CountryCode as CC, descriptionSize, DescriptionSize } from '../../constants';

export const checkDescriptionLength = (description: { size: DescriptionSize; text: string }) => {
    if (description.size === DescriptionSize.LONG) {
        return description.text.length <= descriptionSize.LONG.maxlength;
    }
    if (description.size === DescriptionSize.SHORT) {
        return description.text.length <= descriptionSize.SHORT.maxlength;
    }
    return false;
};

export const isTextContainingPhoneNumber = (text: string): boolean => {
    // todo refactor ApplicationLanguage constant to iso code format and use it here
    const countries = [CC.FRANCE, CC.UNITED_KINGDOM, CC.UNITED_STATES, CC.ITALY, CC.SPAIN] as const;
    return countries.some((country) => {
        const phoneNumbers = findPhoneNumbersInText(text, { defaultCountry: country, extended: true });
        return phoneNumbers.length > 0;
    });
};

export const isTextContainingTld = (text: string): boolean => {
    const tldsJoined = tlds.join('|');
    const regex = new RegExp(`[^\\s]+\\.(${tldsJoined})`, 'g');
    return regex.test(text);
};

export function checkDescription(description: { size: DescriptionSize; text: string }): boolean {
    return checkDescriptionLength(description) && !isTextContainingPhoneNumber(description.text) && !isTextContainingTld(description.text);
}

export function checkDescriptions(descriptions: { size: DescriptionSize; text: string }[]): boolean {
    return descriptions.length ? descriptions.every(checkDescription) : true;
}
