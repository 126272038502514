<div class="h-full w-full">
    @if (!experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
        @if (!medias().length) {
            <ng-container *ngTemplateOutlet="areMediasLoading() ? oldMediasLoading : emptyTemplate"></ng-container>
        } @else {
            <ng-template [ngTemplateOutlet]="areMediasLoading() ? oldMediasLoading : fullPageTemplate"></ng-template>
        }
    } @else {
        @if (!medias().length) {
            <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
        } @else {
            <ng-template [ngTemplateOutlet]="fullPageTemplate"></ng-template>
        }
    }
</div>
<!-- delete after feature toggle -->
<ng-template #oldMediasLoading>
    <div class="hide-scrollbar flex h-full w-full flex-col gap-y-1 overflow-y-auto">
        <div class="flex h-full w-full items-center justify-center">
            <app-malou-spinner size="large"></app-malou-spinner>
        </div>
    </div>
</ng-template>

<ng-template #mediasLoading>
    <div
        class="flex h-full w-full items-center justify-center border border-malou-color-background-dark bg-malou-color-background-light px-12">
        <mat-progress-bar color="primary" mode="determinate" [value]="mediaLoadingPercentage()"></mat-progress-bar>
    </div>
</ng-template>

<ng-template #emptyTemplate>
    <app-media-file-uploader
        [accept]="getAcceptedInputFileTypes | applyPure: acceptedMediaTypes()"
        [multiple]="maxMedia() > 1"
        [disabled]="disabled()"
        [maxVideoSize]="maxVideoSize()"
        [maxImageSize]="maxImageSize()"
        [maxNumberOfFiles]="maxMedia() - medias().length"
        (fileProcessed)="onFileProcessed($event)"
        (startReadingFile)="startReadingFile($event)"
        (processError)="processError($event)"
        (progressChanged)="onProgressChanged($event)"
        (dragEnter)="dragEnter()"
        (dragLeave)="dragLeave()"
        #emptyMediaUploader>
        @if (areMediasLoading()) {
            <ng-container *ngTemplateOutlet="mediasLoading"></ng-container>
        } @else {
            <div
                class="grid h-full w-full place-items-center rounded-[10px] border border-malou-color-background-dark bg-malou-color-background-light"
                [ngClass]="{ 'border-dashed border-malou-color-primary': draggingOver() }"
                (click)="$event.stopPropagation()">
                <div class="flex flex-col items-center gap-y-4">
                    <img class="h-[100px] w-[100px]" [src]="'Goggles' | illustrationPathResolver" />
                    <div class="malou-text-12--semibold text-malou-color-text-1">
                        {{ 'edit_image.drag_drop_file' | translate }}
                    </div>
                    <button class="malou-btn-flat" data-testid="upload-file-btn" (click)="emptyMediaUploader.openFilePicker()">
                        {{ 'edit_image.upload' | translate }}
                    </button>
                    <button
                        class="malou-btn-flat"
                        data-testid="choose-from-gallery-btn"
                        (click)="openMediaPickerModal(); $event.stopPropagation()">
                        {{ 'edit_image.browse' | translate }}
                    </button>
                </div>
            </div>
        }
    </app-media-file-uploader>
</ng-template>

<ng-template #fullPageTemplate>
    <div class="hide-scrollbar flex h-full w-full flex-col gap-y-1 overflow-y-auto">
        <div class="flex-1">
            <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
        </div>
        @if (!experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
            <div class="flex-8 mt-5">
                <ng-container *ngTemplateOutlet="mediaTemplate"></ng-container>
            </div>
        } @else {
            <div class="flex-8 overflow-hidden">
                <app-media-view
                    [media]="currentMedia()"
                    [originalMedia]="currentOriginalMedia()"
                    [cropSize]="cropOption()"
                    [isCropping]="isCropping()"
                    [imageCropperMediaLoading]="imageCropperMediaLoading()"
                    [state]="state()"
                    [angle]="angle()"
                    [ratio]="ratio()"
                    [format]="format()"
                    [tagControl]="tagControl"
                    [searching]="searching()"
                    [foundAccount]="foundAccount()"
                    [isMediaDeletable]="isMediaDeletable()"
                    [maxMedia]="maxMedia()"
                    [disabled]="disabled()"
                    [tagButtonToggled]="tagButtonToggled()"
                    [showTagContainer]="showTagContainer()"
                    [userTags]="userTags()"
                    (onAccountSelected)="addAccount($event)"
                    (onClose)="closeTagContainer()"
                    (removeAccount)="removeAccount($event)"
                    (toggleShowTagContainer)="toggleShowTagContainer($event)"
                    (removeMedia)="removeMedia($event)"
                    (imageCroppedEvent)="imageCropped($event)"
                    (imageLoadedEvent)="imageCropperReady()"></app-media-view>
            </div>
        }
        <div class="flex-1">
            <ng-container *ngTemplateOutlet="carouselTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #actionsTemplate>
    <div class="flex w-full items-center justify-between md:overflow-hidden">
        <div class="flex w-full items-center gap-x-3 sm:flex-wrap">
            <ng-container [ngTemplateOutlet]="tagButtonTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="cropButtonTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="rotateButtonTemplate"></ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="resetButtonTemplate"></ng-container>
        @if (rotationButtonToggled() || dimensionsButtonToggled()) {
            <div class="flex items-center gap-x-2">
                @if (!cropCommitLoading()) {
                    <button
                        class="malou-btn-icon--secondary !h-10 !w-10"
                        data-testid="media-editor-crop-cancel-btn"
                        mat-icon-button
                        (click)="closeDimensionEditors()">
                        <mat-icon [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    </button>
                    <button
                        class="malou-btn-icon--primary !h-10 !w-10"
                        data-testid="media-editor-crop-accept-btn"
                        mat-icon-button
                        (click)="cropCommitLoading.set(true); commitCurrentEdition()">
                        <mat-icon [svgIcon]="SvgIcon.CHECK"></mat-icon>
                    </button>
                } @else {
                    <div>
                        <app-malou-spinner size="small"></app-malou-spinner>
                    </div>
                }
            </div>
        }
    </div>
</ng-template>

<ng-template #tagButtonTemplate>
    @if (
        isTaggable() &&
        !rotationButtonToggled() &&
        !dimensionsButtonToggled() &&
        !textEditionButtonToggled() &&
        !backgroundEditionButtonToggled()
    ) {
        <div
            class="crop-options-container flex cursor-pointer items-center"
            data-testid="media-editor-tag-btn"
            [matTooltip]="'social_posts.new_social_post.tag_account' | translate"
            [ngClass]="{
                'dimensions-toggled !pr-5': tagButtonToggled(),
                'opacity-50': (currentMedia() | applySelfPure: 'isVideo') || disabled(),
            }"
            (click)="!disabled() && !isCurrentMediaVideo() && toggleTag()">
            <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
        </div>
    }
</ng-template>

<ng-template #cropButtonTemplate>
    @if (!rotationButtonToggled() && !textEditionButtonToggled() && !backgroundEditionButtonToggled()) {
        <div
            class="crop-options-container flex items-center"
            data-testid="media-editor-crop-btn"
            [matTooltip]="'gallery.crop' | translate"
            [ngClass]="{
                'dimensions-toggled': dimensionsButtonToggled(),
                'cursor-pointer': !dimensionsButtonToggled(),
                'opacity-50': disabled(),
            }"
            (click)="!disabled() && openCropEditor()">
            <mat-icon class="z-10 !h-3.5 !w-3.5 cursor-pointer" color="primary" [svgIcon]="SvgIcon.DIMENSION"></mat-icon>
            @if (dimensionsButtonToggled()) {
                <div
                    class="crop-options malou-text-12--regular flex cursor-pointer items-center gap-x-2 text-malou-color-border-secondary"
                    [matTooltip]="
                        (getResetCurrentImageErrorMessage | applyPure: currentMedia() : originalMediasById() : cropOption()) ===
                        MediaResetErrorTooltipType.NOT_FIRST_MEDIA
                            ? ('social_posts.new_social_post.not_first_media_warning' | translate)
                            : null
                    "
                    (click)="!canChangeAspectRatio() ? $event.stopPropagation() : null">
                    <span
                        data-testid="media-editor-square-btn"
                        [ngClass]="{
                            'selected-crop-option': isCropOptionSelected | applyPure: [CropOption.SQUARE] : cropOption(),
                            'opacity-50': !canChangeAspectRatio(),
                            'cursor-not-allowed': !canChangeAspectRatio(),
                        }"
                        (click)="changeAspectRatio(CropOption.SQUARE)">
                        {{ 'edit_image.square' | translate }}
                    </span>
                    <span
                        data-testid="media-editor-portrait-btn"
                        [ngClass]="{
                            'selected-crop-option': isCropOptionSelected | applyPure: [CropOption.PORTRAIT] : cropOption(),
                            'opacity-50': !canChangeAspectRatio(),
                            'cursor-not-allowed': !canChangeAspectRatio(),
                        }"
                        (click)="changeAspectRatio(CropOption.PORTRAIT)">
                        {{ 'edit_image.portrait' | translate }}
                    </span>
                    <span
                        data-testid="media-editor-landscape-btn"
                        [ngClass]="{
                            'selected-crop-option': isCropOptionSelected | applyPure: [CropOption.LANDSCAPE] : cropOption(),
                            'opacity-50': !canChangeAspectRatio(),
                            'cursor-not-allowed': !canChangeAspectRatio(),
                        }"
                        (click)="changeAspectRatio(CropOption.LANDSCAPE)">
                        {{ 'edit_image.landscape' | translate }}
                    </span>
                </div>
            }
        </div>
    }
</ng-template>

<ng-template #rotateButtonTemplate>
    @if (!dimensionsButtonToggled() && !textEditionButtonToggled() && !backgroundEditionButtonToggled()) {
        <div
            class="crop-options-container flex items-center"
            data-testid="media-editor-rotate-btn"
            [matTooltip]="'gallery.rotation' | translate"
            [ngClass]="{
                'dimensions-toggled': rotationButtonToggled(),
                'cursor-pointer': !rotationButtonToggled(),
                'opacity-50': isCurrentMediaVideo() || disabled(),
            }"
            (click)="!disabled() && !isCurrentMediaVideo() && openRotationEditor()">
            <mat-icon class="z-10 !h-3.5 !w-3.5 cursor-pointer" color="primary" [svgIcon]="SvgIcon.ROTATION"></mat-icon>
            @if (rotationButtonToggled()) {
                <div class="crop-options flex items-center gap-x-2">
                    <mat-slider
                        class="malou-mat-slider--no-thumb"
                        color="primary"
                        min="0"
                        max="360"
                        step="15"
                        showTickMarks
                        discrete
                        [disabled]="disabled()"
                        [displayWith]="displayRotationWith"
                        #ngSlider>
                        <input
                            data-testid="media-editor-slider-input"
                            matSliderThumb
                            (input)="onSliderInput($event)"
                            #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="malou-text-12--regular text-malou-color-chart-purple">{{ ngSliderThumb.value }}°</div>
                </div>
            }
        </div>
    }
</ng-template>

<ng-template #resetButtonTemplate>
    @if (!rotationButtonToggled() && !dimensionsButtonToggled() && !textEditionButtonToggled() && !backgroundEditionButtonToggled()) {
        <button
            class="malou-btn-flat"
            data-testid="media-editor-reset-btn"
            [disabled]="!!(getResetCurrentImageErrorMessage | applyPure: currentMedia() : originalMediasById() : cropOption())"
            [matTooltip]="'' | translate"
            (click)="resetCurrentImage()">
            <div class="flex items-center justify-center gap-x-2">
                <div>{{ 'common.reinitialize' | translate }}</div>
                <mat-icon class="!h-3.5 !w-3.5" color="primary" [svgIcon]="SvgIcon.SYNC"></mat-icon>
            </div>
        </button>
    }
</ng-template>

<ng-template #mediaTemplate>
    @if (!isCropping()) {
        <div
            class="w-full rounded-[10px] bg-malou-color-background-light"
            id="backgroundImageContainer"
            [ngClass]="shouldResizeImgHeight() ? 'h-[50vh]' : ''">
            <div class="relative h-full w-full">
                <div class="h-full w-full">
                    @switch (currentMedia().type) {
                        @case ('video') {
                            <video
                                class="h-full w-full object-contain"
                                muted
                                loop
                                playsinline
                                [src]="currentMediaUrl()"
                                #videoPlayer></video>
                        }
                        @default {
                            <div
                                class="flex h-full items-center"
                                id="editImageContainer"
                                [ngStyle]="{ cursor: textEditionButtonToggled() ? 'crosshair' : 'default' }"
                                #editedImage>
                                <img
                                    class="h-full w-full !rounded-none object-contain"
                                    id="imageContainer"
                                    [ngClass]="{ 'hover:cursor-crosshair': tagButtonToggled() }"
                                    [src]="currentMediaUrl()"
                                    (click)="tagButtonToggled() && toggleShowTagContainer($event)"
                                    #imageContainer />
                            </div>
                        }
                    }
                </div>
                @if (currentMedia() | applySelfPure: 'isVideo') {
                    <div
                        class="absolute left-0 top-0 grid h-full w-full cursor-pointer place-items-center transition-all"
                        [ngClass]="{ 'opacity-0 hover:opacity-100': isVideoPlaying() }"
                        (click)="playVideo()">
                        <div class="grid h-24 w-24 place-items-center rounded-full bg-white bg-opacity-70">
                            <mat-icon class="!h-9 !w-9" color="primary" [svgIcon]="isVideoPlaying() ? 'pause' : 'play'"></mat-icon>
                        </div>
                    </div>
                }
                @if (currentMedia() | applySelfPure: 'isVideo') {
                    <div class="absolute bottom-3 left-3">
                        <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="muteUnmuteVideo()">
                            <mat-icon color="primary" [svgIcon]="isVideoMuted() ? 'sound-off' : 'sound-on'"></mat-icon>
                        </button>
                    </div>
                }
                @if (maxMedia() === 1 && isMediaDeletable()) {
                    <div class="absolute bottom-3 right-3">
                        <button
                            class="malou-btn-icon--secondary btn-xl"
                            mat-icon-button
                            [disabled]="disabled()"
                            (click)="removeMedia(currentMedia())">
                            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                        </button>
                    </div>
                }
                <div class="absolute left-0 top-0 w-full p-3">
                    <div class="flex w-full flex-col gap-y-2">
                        @for (item of currentMedia().getErrors(); track item) {
                            <div
                                class="malou-text-10--regular w-full rounded-[10px] bg-malou-color-background-error px-4 py-3 italic text-malou-color-state-error">
                                {{ item }}
                            </div>
                        }
                    </div>
                </div>
                @if (tagButtonToggled() && showTagContainer()) {
                    <div
                        class="malou-box-shadow absolute left-[50%] top-[50%] z-10 w-[70%] translate-x-[-50%] translate-y-[-50%] transform rounded-[10px]"
                        id="tag-account-container">
                        <app-tag-account
                            [tagControl]="tagControl"
                            [searching]="searching()"
                            [foundAccount]="foundAccount()"
                            (onAccountSelected)="addAccount($event)"></app-tag-account>
                    </div>
                }
                @if (tagButtonToggled() && userTags().length) {
                    @for (userTag of userTags(); track userTag) {
                        <div
                            class="fade-in absolute z-[5] translate-x-[-50%] translate-y-[-50%] transform transition-all"
                            [ngStyle]="{
                                top: (getCurrentTagPositionInMedia | applyPure: userTag.x : userTag.y).top,
                                left: (getCurrentTagPositionInMedia | applyPure: userTag.x : userTag.y).left,
                            }">
                            <div class="malou-box-shadow relative flex items-center gap-x-2 rounded-[8px] bg-white p-3.5">
                                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                                <span class="malou-text-12--semibold">{{ userTag.username }}</span>
                                <div
                                    class="malou-box-shadow absolute left-[-9px] top-[-9px] grid h-5 w-5 cursor-pointer place-items-center rounded-full bg-white"
                                    (click)="removeAccount(userTag.username)">
                                    <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                                </div>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
    } @else {
        <image-cropper
            class="p-0"
            id="cropped-image"
            style="max-height: 65vh"
            [ngClass]="{ 'only-rotate': state() === 'rotate' }"
            [transform]="{ rotate: angle() }"
            [imageURL]="currentOriginalMediaUrl() + '?x-request=foo'"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="state() === 'rotate'"
            [aspectRatio]="ratio()"
            [format]="format()"
            [imageQuality]="100"
            [alignImage]="'center'"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageCropperReady()"
            #cropper>
        </image-cropper>
        @if (imageCropperMediaLoading()) {
            <app-skeleton skeletonClass="h-[50vh] w-full "></app-skeleton>
        }
    }
</ng-template>

<ng-template #carouselTemplate>
    @if (maxMedia() > 1) {
        <app-media-file-uploader
            [accept]="getAcceptedInputFileTypes | applyPure: acceptedMediaTypes()"
            [multiple]="maxMedia() > 1"
            [disabled]="disabled()"
            [maxVideoSize]="maxVideoSize()"
            [maxImageSize]="maxImageSize()"
            [maxNumberOfFiles]="maxMedia() - medias().length"
            (fileProcessed)="onFileProcessed($event)"
            (startReadingFile)="startReadingFile($event)"
            (processError)="processError($event)"
            (progressChanged)="onProgressChanged($event)"
            (dragEnter)="dragEnter()"
            (dragLeave)="dragLeave()"
            #menuMediaUploader>
            <div
                class="relative mt-4 flex w-full max-w-full items-center justify-between gap-x-3 rounded-[10px] bg-malou-color-background-light p-3"
                [ngClass]="{ 'border border-dashed border-malou-color-primary': draggingOver() }">
                <div
                    class="medias-list hide-scrollbar flex min-h-[75px] min-w-[75%] max-w-full items-end gap-x-1.5 overflow-x-auto"
                    id="carrousel-container"
                    appScrollableXAxis
                    cdkDropList
                    cdkDropListOrientation="horizontal"
                    (scrollableChange)="carrouselContainerScrollX.set($event)"
                    (cdkDropListDropped)="medias().length >= maxMedia() ? null : mediaDropped($event)">
                    @for (media of medias(); track $index + ' ' + media.id; let idx = $index) {
                        @if (!media.id && areMediasLoading()) {
                            <div
                                class="small-media relative flex aspect-square h-[75px] w-[75px] cursor-move items-center justify-center rounded-[10px] bg-malou-color-background-dark px-2">
                                <mat-progress-bar
                                    class="progress-bar"
                                    color="primary"
                                    mode="determinate"
                                    [value]="mediaLoadingPercentage()"></mat-progress-bar>
                            </div>
                        } @else {
                            <div class="relative" data-testid="media-current-post" cdkDrag>
                                <div
                                    class="small-media relative aspect-square h-[75px] w-[75px] cursor-move rounded-[10px]"
                                    (click)="changeCurrentSelectedMedia(idx)">
                                    <div class="h-full w-full overflow-hidden" #carouselContainerElement>
                                        @switch (media.type) {
                                            @case ('video') {
                                                <video
                                                    class="h-full w-full max-w-unset !rounded-[8px] object-cover"
                                                    muted
                                                    [src]="getMediaUrl | applyPure: media"
                                                    #carouselVideoElement></video>
                                            }
                                            @default {
                                                <img
                                                    class="h-full w-full max-w-unset !rounded-[8px] object-cover"
                                                    [src]="getMediaUrl | applyPure: media"
                                                    #carouselImgElement />
                                            }
                                        }
                                    </div>
                                    <div
                                        class="absolute right-1 top-2 hidden cursor-pointer transition-all hover:scale-105"
                                        (click)="!disabled() && removeMedia(media)">
                                        <div class="grid h-5 w-5 place-items-center rounded-full bg-white">
                                            <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                                        </div>
                                    </div>
                                    <div class="absolute bottom-2 right-1 hidden cursor-pointer transition-all hover:scale-105">
                                        <div class="grid h-6 w-6 place-items-center rounded-[4px] bg-malou-color-gradient-5">
                                            <mat-icon class="!h-3.5 !w-3.5 text-white" [svgIcon]="SvgIcon.MOVE"></mat-icon>
                                        </div>
                                    </div>
                                    @if (media | applySelfPure: 'isVideo') {
                                        <div class="absolute left-1 top-2">
                                            <mat-icon class="!h-3.5 !w-3.5 text-white" [svgIcon]="SvgIcon.VIDEO"></mat-icon>
                                        </div>
                                    }
                                </div>
                                @if (media.hasErrors()) {
                                    <div
                                        class="absolute right-[-8px] top-[-8px] grid h-4 w-4 place-items-center rounded-full bg-malou-color-state-error">
                                        <mat-icon class="!h-3 !w-3 text-white" [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                                    </div>
                                }
                            </div>
                        }
                    }
                </div>
                <div
                    [matTooltip]="'social_posts.max_number_of_media_reached' | translate: { max: maxMedia() }"
                    [matTooltipDisabled]="canAddMoreMedia()">
                    <button
                        class="malou-btn-icon--secondary btn-xl !h-[75px] !w-[75px]"
                        data-testid="media-editor-upload-more-btn"
                        appDnd
                        mat-icon-button
                        [disabled]="!canAddMoreMedia()"
                        [matMenuTriggerFor]="uploadFromMenu">
                        <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
                    </button>
                </div>

                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #uploadFromMenu="matMenu">
                    <button data-testid="upload-more-from-computer-btn" mat-menu-item (click)="menuMediaUploader.openFilePicker()">
                        <span class="malou-text-12--regular text-malou-color-text-2 md:hidden">
                            {{ 'common.upload_from_computer' | translate }}
                        </span>
                        <span class="malou-text-12--regular hidden text-malou-color-text-2 md:block">
                            {{ 'common.upload_from_phone' | translate }}
                        </span>
                    </button>
                    <button data-testid="upload-more-from-gallery-btn" mat-menu-item (click)="openMediaPickerModal()">
                        <span class="malou-text-12--regular text-malou-color-text-2">{{ 'common.upload_from_gallery' | translate }}</span>
                    </button>
                </mat-menu>
                <!-- Arrows scroll carousel positionned vertically at the center absolutely -->
                <div class="appear-animation absolute left-[20px] top-[40%]" [ngClass]="{ '!hidden': !carrouselContainerScrollX().left }">
                    <button
                        class="malou-btn-icon--secondary btn-sm !rounded-full"
                        mat-icon-button
                        (click)="$event.stopPropagation(); carrouselContainerScrollX().left && scrollLeft()">
                        <mat-icon [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                    </button>
                </div>
                <div class="appear-animation absolute right-[80px] top-[40%]" [ngClass]="{ '!hidden': !carrouselContainerScrollX().right }">
                    <button
                        class="malou-btn-icon--secondary btn-sm !rounded-full"
                        mat-icon-button
                        (click)="$event.stopPropagation(); carrouselContainerScrollX().right && scrollRight()">
                        <mat-icon [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                    </button>
                </div>
            </div>
        </app-media-file-uploader>
    }
</ng-template>
