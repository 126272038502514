import { z } from 'zod';

import { addressDtoValidator, latlngDtoValidator } from '../../restaurant';
import { urlValidator } from '../../utils';

export const storeLocatorStorePageHeadBlockValidator = z.object({
    title: z.string(),
    description: z.string(),
    keywords: z.string(),
    url: urlValidator(),
    imageUrl: urlValidator(),
    locale: z.string(),
    store: z.object({
        name: z.string(),
        address: addressDtoValidator,
        phone: z.string().optional(),
        menuUrl: urlValidator().optional(),
        category: z.string().optional(),
    }),
    coordinates: latlngDtoValidator,
    xUserName: z.string().optional(),
    openingHoursForMeta: z.string().optional(),
    microdata: z.string(),
});
