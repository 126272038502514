import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, KeywordPopularity } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { KeywordsDetailsStatsTableRow } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords.interface';

enum Advice {
    HIGH_POPULARITY_TARGET_TOP_3_POSITION,
    NOT_POPULAR_ENOUGH_TARGET_TOP_3_POSITION,
    LOW_POPULARITY_CHANGE_KEYWORD,
    CHANGE_KEYWORD,
}

@Component({
    selector: 'app-apparition-advice-tooltip',
    standalone: true,
    imports: [TranslateModule, MatMenuModule, MatButtonModule],
    templateUrl: './apparition-advice-tooltip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApparitionAdviceTooltipComponent {
    readonly rowData = input.required<KeywordsDetailsStatsTableRow>();

    private readonly _heapService = inject(HeapService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly advice = computed<Advice | null>(() => {
        const position = this.rowData().currentPosition?.rank ?? 20;
        const popularity = this.rowData().popularity;

        if (position > 3) {
            if (popularity === KeywordPopularity.HIGH) {
                return Advice.HIGH_POPULARITY_TARGET_TOP_3_POSITION;
            }

            return Advice.NOT_POPULAR_ENOUGH_TARGET_TOP_3_POSITION;
        }

        if (popularity === KeywordPopularity.LOW) {
            return Advice.LOW_POPULARITY_CHANGE_KEYWORD;
        }

        return Advice.CHANGE_KEYWORD;
    });

    readonly Advice = Advice;

    onAdviceButtonClick(): void {
        const restaurantId = this._restaurantsService.currentRestaurant?._id;
        this._heapService.track(HeapEventName.SEO_KEYWORDS_STATS_APPARITION_ADVICE_CLICK, {
            restaurantId,
            keyword: this.rowData().keyword,
        });
    }
}
