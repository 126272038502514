import { z } from 'zod';

import { DescriptionSize } from '@malou-io/package-utils';

import { DefaultModelProperties } from '../../utils';
import { KeywordAnalysisDto, keywordAnalysisDtoValidator } from '../keyword-analysis/keyword-analysis.dto';

// !!! WARNING !!!
// if you make change to these validators, you must also make change to the interfaces
// Because on strict false, zod make all properties optional
// So when strict true, remove interfaces and infer type with zod
export interface DescriptionDto extends DefaultModelProperties {
    language?: string;
    size: string;
    text?: string;
    active?: boolean;
    keywordAnalysis?: KeywordAnalysisDto;
}

export const descriptionDtoValidator = z.object({
    language: z.string().optional(),
    size: z.nativeEnum(DescriptionSize),
    text: z.string(),
    keywordAnalysis: keywordAnalysisDtoValidator.optional(),
});
