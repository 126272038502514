import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, NgZone, OnInit, output, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import {
    BubbleDataPoint,
    Chart,
    ChartDataset,
    ChartEvent,
    ChartOptions,
    ChartType,
    ChartTypeRegistry,
    Plugin,
    Point,
    Tick,
    TooltipItem,
} from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { ReviewAnalysisChartDataSentiment, ReviewAnalysisChartDataTag, ReviewAnalysisTag } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { SegmentAnalysisModalComponent } from ':shared/components/review-analyses-v2/segment-analysis-modal/segment-analysis-modal.component';
import { TopicSegmentAnalysisModalComponent } from ':shared/components/review-analyses-v2/topic-segment-analysis-modal/topic-segment-analysis-modal.component';
import { ChartDataArray, malouChartColorBluePurple, malouChartColorGreen, malouChartColorRed, malouChartColorText2 } from ':shared/helpers';
import { SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DEFAULT_DISPLAYED_TAGS: ReviewAnalysisChartDataTag[] = [
    ReviewAnalysisChartDataTag.FOOD,
    ReviewAnalysisChartDataTag.SERVICE,
    ReviewAnalysisChartDataTag.ATMOSPHERE,
    ReviewAnalysisChartDataTag.PRICE,
    ReviewAnalysisChartDataTag.EXPEDITIOUSNESS,
    ReviewAnalysisChartDataTag.HYGIENE,
];

type BarChartType = Extract<ChartType, 'bar'>;

@Component({
    selector: 'app-tags-bar-chart',
    templateUrl: './tags-bar-chart.component.html',
    standalone: true,
    imports: [NgChartsModule, NgClass],
    providers: [ShortNumberPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsBarChartComponent implements OnInit {
    readonly chartData = input.required<ReviewAnalysesChartDataByRestaurantId>();
    readonly reviewAnalysesFilter = input.required<ReviewAnalysesChartFilter | null>();
    readonly shouldDisplayAnalysesTagClickableLabels = input.required<boolean>();
    readonly isFromAggregatedStatistics = input.required<boolean>();
    readonly onDefaultSelectedCategoryChanged = output<ReviewAnalysisTag>();

    private readonly _zone = inject(NgZone);
    private readonly _shortNumberPipe = inject(ShortNumberPipe);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly LABEL_CLICK_PLUGIN: Plugin = this._getLabelClickPlugin();

    readonly displayedTags: Signal<ReviewAnalysisChartDataTag[]> = computed(() => {
        const chartData = this.chartData();
        return DEFAULT_DISPLAYED_TAGS.filter((tag) => chartData.hasData(tag));
    });
    readonly chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.displayedTags(), this.chartData())
    );
    readonly chartLabels: Signal<string[][]> = computed(() => this._computeChartLabels(this.displayedTags(), this.chartData()));

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis'),
        { initialValue: false }
    );

    chartOption: ChartOptions<BarChartType>;

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    private _computeChartLabels(displayedTags: ReviewAnalysisChartDataTag[], chartData: ReviewAnalysesChartDataByRestaurantId): string[][] {
        return this.shouldDisplayAnalysesTagClickableLabels() && !this.isNewSemanticAnalysisFeatureEnabled()
            ? displayedTags.map((tag) => [
                  this._computeChartTagLabel(tag, chartData),
                  this._translateService.instant('statistics.e_reputation.reviews_analysis.see_detail'),
              ])
            : displayedTags.map((tag) => [this._computeChartTagLabel(tag, chartData)]);
    }

    private _computeChartTagLabel(tag: ReviewAnalysisChartDataTag, chartData: ReviewAnalysesChartDataByRestaurantId): string {
        const translatedTag = this._enumTranslatePipe.transform(tag, 'review_analysis_tags');
        const tagCount = chartData.getCount(tag, ReviewAnalysisChartDataSentiment.TOTAL);
        return `${translatedTag}  (${tagCount})`;
    }

    private _computeChartData(
        displayedTags: ReviewAnalysisChartDataTag[],
        chartData: ReviewAnalysesChartDataByRestaurantId
    ): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: displayedTags.map((tag) => chartData.getCount(tag, ReviewAnalysisChartDataSentiment.POSITIVE)),
            },
            {
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: displayedTags.map((tag) => chartData.getCount(tag, ReviewAnalysisChartDataSentiment.NEGATIVE)),
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: this.isNewSemanticAnalysisFeatureEnabled() ? 'index' : undefined,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItems: TooltipItem<any>) => this._computeTooltipLabel(tooltipItems),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        crossAlign: 'center',
                        color: this.shouldDisplayAnalysesTagClickableLabels() ? malouChartColorBluePurple : malouChartColorText2,
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItems: TooltipItem<any>): string {
        const value = parseInt(tooltipItems.formattedValue, 10);
        const sentiment =
            tooltipItems.datasetIndex === 0
                ? this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_analysis.positive', value).toLowerCase()
                : this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_analysis.negative', value).toLowerCase();
        return (
            SMALL_TOOLTIP_TAB +
            this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_analysis.feeling', value, {
                sentiment,
            })
        );
    }

    private _openSegmentAnalysisModal(tag: ReviewAnalysisChartDataTag): void {
        if (!this.reviewAnalysesFilter() || !this.shouldDisplayAnalysesTagClickableLabels()) {
            if (tag !== ReviewAnalysisChartDataTag.TOTAL) {
                this.onDefaultSelectedCategoryChanged.emit(tag as unknown as ReviewAnalysisTag);
            }
            return;
        }
        if (this.isNewSemanticAnalysisFeatureEnabled() && !this.isFromAggregatedStatistics()) {
            this._customDialogService.open(TopicSegmentAnalysisModalComponent, {
                width: '80vw',
                height: '80vh',
                disableClose: true,
                data: {
                    topic: tag,
                    topicTranslations: {
                        fr: tag,
                        en: tag,
                        es: tag,
                        it: tag,
                    },
                    reviewAnalysesFilter: this.reviewAnalysesFilter(),
                    isFromAggregatedStatistics: this.isFromAggregatedStatistics(),
                    isMainCategory: false,
                    shouldShowSubcategories: false,
                },
            });
        } else {
            this._customDialogService.open(SegmentAnalysisModalComponent, {
                width: '80vw',
                height: '80vh',
                disableClose: true,
                data: {
                    tag,
                    reviewAnalysesFilter: this.reviewAnalysesFilter(),
                    isFromAggregatedStatistics: this.isFromAggregatedStatistics(),
                },
            });
        }
    }

    private _getLabelClickPlugin(): Plugin {
        return {
            id: 'labelClickWithCursor',
            beforeEvent(chart, args, _pluginOptions): void {
                const eventType = args.event;
                const target = args.event.native?.target;
                if (!eventType.y || !target) {
                    return;
                }
                // Check if the cursor in inside the xAxis area
                const xAxisBottom = chart.scales.xAxis.bottom;
                const xAxisTop = chart.scales.xAxis.top;
                const isInsideXAxisArea = eventType.y > xAxisTop && eventType.y < xAxisBottom;
                target['style'].cursor = isInsideXAxisArea ? 'pointer' : 'default';
                return;
            },
            afterEvent: (chart: Chart, args: { event: ChartEvent }): void => {
                const { event } = args;
                const detailsBtnOffset = 27;
                const datasets = chart.data.datasets;
                if (event.type !== 'click') {
                    return;
                }
                const { x, y } = args.event;

                if (
                    !x ||
                    x < chart.scales.xAxis.left ||
                    x > chart.scales.xAxis.right ||
                    !y ||
                    y < chart.scales.xAxis.top - detailsBtnOffset
                ) {
                    return;
                }

                const index = chart.scales.xAxis.getValueForPixel(x);

                if (index !== undefined && !this._isDataPointEqualToZero(datasets, index)) {
                    const clickedTag = this.displayedTags()[index];
                    this._zone.run(() => this._openSegmentAnalysisModal(clickedTag));
                }
            },
        };
    }

    private _isDataPointEqualToZero(
        datasets: ChartDataset<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[]>[],
        index: number
    ): boolean {
        if (index < 0) {
            return false;
        }
        return datasets.reduce((acc, next) => (next.data[index] as number) + acc, 0) === 0;
    }
}
