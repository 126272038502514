import { ChangeDetectionStrategy, Component, computed, effect, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformsRatingsComponent } from ':shared/components/platforms-ratings/platforms-ratings.component';

@Component({
    selector: 'app-statistics-seo-ratings',
    templateUrl: './ratings.component.html',
    styleUrls: ['./ratings.component.scss'],
    standalone: true,
    imports: [PlatformsRatingsComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsSeoRatingsComponent {
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    readonly hasPlatformRatingsData = signal(true);
    readonly hasData = computed(() => this.hasPlatformRatingsData());

    readonly isPlatformRatingsLoading = signal(false);
    readonly isLoading = computed(() => this.isPlatformRatingsLoading());

    constructor() {
        effect(() => this.hasDataChange.emit(this.hasData()));
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }
}
