import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    DownloadInsightsAsPdfBodyDto,
    DownloadInsightsAsPdfResponseDto,
    GetInsightsAggregatedRequestBodyDto,
    GetStoredInsightsAggregatedRequestInputBodyDto,
    GetStoredInsightsRequestInputBodyDto,
    StoredInsightsAggregatedResponseDto,
    StoredInsightsResponseDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { GetInsightsAggregatedRequestInputBodyDto } from ':modules/statistics/statistics.interfaces';
import { ApiResult, InsightsByPlatformByRestaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class InsightsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/platform-insights`;

    constructor(private readonly _http: HttpClient) {}

    getInsights(filters: GetInsightsAggregatedRequestInputBodyDto): Observable<ApiResult<InsightsByPlatformByRestaurant>> {
        return this._http.post<ApiResult<InsightsByPlatformByRestaurant>>(`${this.API_BASE_URL}`, filters);
    }

    getInsightsV2(filters: GetInsightsAggregatedRequestBodyDto): Observable<ApiResult<InsightsByPlatformByRestaurant>> {
        return this._http.post<ApiResult<InsightsByPlatformByRestaurant>>(`${this.API_BASE_URL}/v2`, filters);
    }

    getInsightsPdfUrl(body: DownloadInsightsAsPdfBodyDto): Observable<DownloadInsightsAsPdfResponseDto> {
        return this._http
            .post<ApiResultV2<DownloadInsightsAsPdfResponseDto>>(`${this.API_BASE_URL}/download-insights-as-pdf`, body)
            .pipe(map((res) => res.data));
    }

    getStoredInsights(body: GetStoredInsightsRequestInputBodyDto): Observable<ApiResultV2<StoredInsightsResponseDto>> {
        return this._http.post<ApiResultV2<StoredInsightsResponseDto>>(`${this.API_BASE_URL}/stored-insights`, body);
    }

    getStoredInsightsAggregated(body: GetStoredInsightsAggregatedRequestInputBodyDto): Observable<StoredInsightsAggregatedResponseDto> {
        return this._http
            .post<ApiResultV2<StoredInsightsAggregatedResponseDto>>(`${this.API_BASE_URL}/stored-insights-aggregated`, body)
            .pipe(map((res) => res.data));
    }
}
