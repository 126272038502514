import { effect, signal, WritableSignal } from '@angular/core';

/**
 * Limitation :
 * You will get an error if source fn contains a input.required signal and linkedSignal is called before the first OnChange (usually it's the case)
 */
export function linkedSignal<T>(source: () => T): WritableSignal<T> {
    const internalSignal = signal(source());
    effect(() => internalSignal.set(source()), { allowSignalWrites: true });
    return internalSignal;
}
