import { DateTime } from 'luxon';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import {
    AggregationTimeScale,
    DayMonthYear,
    getMonthsFromPeriod,
    getWeeksFromPeriod,
    MalouComparisonPeriod,
    MalouMetric,
    Month,
    PlatformKey,
    Week,
} from '@malou-io/package-utils';

import { CommunityChartData } from ':modules/statistics/social-networks/social-networks.interfaces';
import { ChartDataArray, ChartDataElement, formatDateToISO, getDaysFromCurrentRange, mergeArrays } from ':shared/helpers';

export class CommunityInsightsChartData {
    private _startDate: Date;
    private _endDate: Date;

    facebookFollowers: ChartDataArray = [];
    instagramFollowers: ChartDataArray = [];
    followers: ChartDataArray = [];

    instagramNewFollowers: ChartDataArray = [];
    facebookNewFollowers: ChartDataArray = [];

    dates: Date[];
    aggregationTimeScale: AggregationTimeScale;
    comparisonPeriod?: MalouComparisonPeriod;

    constructor({
        data,
        startDate,
        endDate,
        aggregationTimeScale,
        comparisonPeriod,
    }: {
        data: CommunityChartData;
        startDate: DayMonthYear;
        endDate: DayMonthYear;
        aggregationTimeScale: AggregationTimeScale;
        comparisonPeriod?: MalouComparisonPeriod;
    }) {
        this._startDate = DateTime.fromObject(startDate).toJSDate();
        this._endDate = DateTime.fromObject(endDate).toJSDate();
        this.aggregationTimeScale = aggregationTimeScale;
        this.comparisonPeriod = comparisonPeriod;
        this._initInsightsAndDates(data);
        this.followers = mergeArrays(this.facebookFollowers, this.instagramFollowers);
        this.instagramNewFollowers = this._computeNewFollowers(this.instagramFollowers);
        this.facebookNewFollowers = this._computeNewFollowers(this.facebookFollowers);
        this.instagramFollowers = this.instagramFollowers;
        this.facebookFollowers = this.facebookFollowers;
    }

    private _initInsightsAndDates(data: CommunityChartData): void {
        switch (this.aggregationTimeScale) {
            case AggregationTimeScale.BY_MONTH:
                const months = getMonthsFromPeriod(this._startDate, this._endDate);
                this.dates = months.map((month) => month.start);
                this._initInsightsAggregatedByMonth(data, months);
                break;

            case AggregationTimeScale.BY_WEEK:
                const weeks = getWeeksFromPeriod(this._startDate, this._endDate);
                this.dates = weeks.map((week) => week.start);
                this._initInsightsAggregatedByWeek(data, weeks);
                break;

            case AggregationTimeScale.BY_DAY:
            default:
                const days = getDaysFromCurrentRange(this._startDate, this._endDate);
                this.dates = days;
                this._initInsightsAggregatedByDay(data, days);
                break;
        }
    }

    private _initInsightsAggregatedByDay(data: CommunityChartData, days: Date[]): void {
        days.forEach((day) => {
            const formattedDay = formatDateToISO(day);
            if (data![PlatformKey.INSTAGRAM]) {
                this.instagramFollowers.push(data![PlatformKey.INSTAGRAM]?.[MalouMetric.FOLLOWERS]?.[formattedDay] ?? null);
            }
            if (data![PlatformKey.FACEBOOK]) {
                this.facebookFollowers.push(data![PlatformKey.FACEBOOK]?.[MalouMetric.FOLLOWERS]?.[formattedDay] ?? null);
            }
        });
    }

    private _initInsightsAggregatedByWeek(data: CommunityChartData, weeks: Week[]): void {
        this._initInsightsAggregatedByPeriod(data, weeks);
    }

    private _initInsightsAggregatedByMonth(data: CommunityChartData, months: Month[]): void {
        this._initInsightsAggregatedByPeriod(data, months);
    }

    private _initInsightsAggregatedByPeriod(data: CommunityChartData, periods: { days: Date[] }[]): void {
        periods.forEach((period) => {
            const days = period.days;
            if (data![PlatformKey.INSTAGRAM]) {
                this.instagramFollowers.push(this._getFollowersInsights(data![PlatformKey.INSTAGRAM], days, MalouMetric.FOLLOWERS));
            }
            if (data![PlatformKey.FACEBOOK]) {
                this.facebookFollowers.push(this._getFollowersInsights(data![PlatformKey.FACEBOOK], days, MalouMetric.FOLLOWERS));
            }
        });
    }

    private _getFollowersInsights(data: DailyPlatformInsights['insights'], days: Date[], metric: MalouMetric): ChartDataElement {
        const daysData = days.map((day) => data![metric]?.[formatDateToISO(day)] ?? 0);
        return daysData.reverse().find((e) => e !== 0) ?? null;
    }

    private _computeNewFollowers(arr: ChartDataArray): ChartDataArray {
        return arr.slice(0, arr.length - 1).map((e, index) => (arr[index + 1] && e ? (arr[index + 1] || 0) - e || 0 : null));
    }
}
