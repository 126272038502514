import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';

import { MalouComparisonPeriod } from '@malou-io/package-utils';

import { STARS_RATING } from ':core/constants';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ReviewsRating } from ':modules/reviews/reviews.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CreateIndexArrayPipe } from ':shared/pipes/create-array.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum TableField {
    RATING = 'rating',
    COMPARISON_PERIOD = 'comparisonPeriod',
    SELECTED_PERIOD = 'selectedPeriod',
}

const DEFAULT_SORT: { active: TableField; direction: SortDirection } = {
    active: TableField.RATING,
    direction: ChartSortBy.DESC,
};

interface DataSource {
    [TableField.RATING]: number;
    [TableField.COMPARISON_PERIOD]: number | null;
    [TableField.SELECTED_PERIOD]: number;
    evolution: number | null;
}

@Component({
    selector: 'app-reviews-ratings-total-table',
    templateUrl: './reviews-ratings-total-table.component.html',
    styleUrls: ['./reviews-ratings-total-table.component.scss'],
    standalone: true,
    imports: [
        NgChartsModule,
        TranslateModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatIconModule,
        ShortNumberPipe,
        NumberEvolutionComponent,
        CreateIndexArrayPipe,
    ],
    providers: [PluralTranslatePipe, EnumTranslatePipe],
})
export class ReviewsRatingsTotalTableComponent {
    readonly reviewsRatings = input<ReviewsRating[]>([]);
    readonly previousReviewsRatings = input<ReviewsRating[]>([]);
    readonly comparisonPeriod = input<MalouComparisonPeriod>(MalouComparisonPeriod.PREVIOUS_PERIOD);

    private readonly _screenSizeService = inject(ScreenSizeService);

    readonly SvgIcon = SvgIcon;
    readonly TableField = TableField;
    readonly DEFAULT_SORT = DEFAULT_SORT;
    readonly displayedColumns = Object.values(TableField);

    readonly isPhoneScreen = this._screenSizeService.isPhoneScreen;

    readonly dataSource: MatTableDataSource<DataSource> = new MatTableDataSource();

    readonly comparisonPeriodKey = computed(() => `date_filter.comparison_period.${this.comparisonPeriod()}`);

    constructor() {
        effect(() => {
            this.dataSource.data = this._computeDataSource(this.reviewsRatings(), this.previousReviewsRatings());
        });
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
        }
    }

    private _computeDataSource(data: ReviewsRating[], previousData: ReviewsRating[]): DataSource[] {
        if (!data) {
            return [];
        }
        return STARS_RATING.map((rating) => {
            const currentRating = data.find((d) => d.rating === rating);
            const previousRating = previousData.find((d) => d.rating === rating);
            return {
                [TableField.RATING]: rating,
                [TableField.COMPARISON_PERIOD]: previousRating ? previousRating.total : null,
                [TableField.SELECTED_PERIOD]: currentRating ? currentRating.total : 0,
                evolution: this._getEvolution(currentRating ? currentRating.total : null, previousRating ? previousRating.total : null),
            };
        });
    }

    private _getEvolution(currentTotal: number | null, previousTotal: number | null): number | null {
        if (currentTotal === null || previousTotal === null) {
            return null;
        }
        return currentTotal - previousTotal;
    }
}
