<div class="malou-box-shadow fixed left-0 top-0 z-10 flex h-[68vh] w-[330px] flex-col rounded-md bg-white" [style]="panelStyle()">
    @if (ownRestaurants().length !== 0) {
        @if (ownRestaurants().length > 1) {
            <ng-container [ngTemplateOutlet]="allBusinessesTemplate"></ng-container>
        }
        <ng-container [ngTemplateOutlet]="inputTemplate"></ng-container>
        <div class="min-h-0 grow">
            <ng-container [ngTemplateOutlet]="businessesListTemplate"></ng-container>
        </div>
        <div class="px-4 py-2">
            <ng-container [ngTemplateOutlet]="addBusinessTemplate"></ng-container>
        </div>
    } @else {
        <div class="w-full p-4">
            <app-skeleton skeletonClass="h-[50px]" [count]="5"></app-skeleton>
        </div>
    }
</div>

<ng-template #allBusinessesTemplate>
    <div
        class="flex cursor-pointer items-center justify-between gap-x-2 p-2 hover:bg-malou-color-background-dark {{ elementClassHeight }}"
        [class.bg-malou-color-background-dark]="isAllRestaurantsSelected()"
        (click)="onAllBusinessesClick($event)">
        <app-sidenav-content-restaurant-info
            [logo]="(getLogoUrl | applyPure: ownRestaurants()[0].logo) || ('default_logo' | imagePathResolver)"
            [countLogoOverlay]="ownRestaurants().length"
            [line1]="'sidenav_content.select_restaurant.panel.all_businesses' | translate"></app-sidenav-content-restaurant-info>
    </div>
</ng-template>

<ng-template #inputTemplate>
    <div class="p-2">
        <app-input-text
            autocomplete="off"
            [svgIcon]="SvgIcon.SEARCH"
            [placeholder]="'common.search' | translate"
            [showClearIcon]="true"
            (inputTextChange)="search.set($event)">
        </app-input-text>
    </div>
</ng-template>

<ng-template #businessesListTemplate>
    <cdk-virtual-scroll-viewport class="hide-scrollbar h-full" [itemSize]="50">
        <div
            *cdkVirtualFor="let restaurant of filteredOwnRestaurants(); trackBy: trackById"
            class="flex cursor-pointer items-center justify-between gap-x-2 px-4 py-2 hover:bg-malou-color-background-dark {{
                elementClassHeight
            }}"
            appIsHovered
            [class.bg-malou-color-background-dark]="selectedRestaurant()?._id === restaurant._id"
            #appIsHovered="appIsHovered">
            <div class="min-w-0 grow" (click)="onBusinessClick($event, restaurant._id)">
                <app-sidenav-content-restaurant-info
                    [logo]="(getLogoUrl | applyPure: restaurant.logo) || ('default_logo' | imagePathResolver)"
                    [line1]="restaurant.internalName || restaurant.name"
                    [line2]="
                        restaurant.type === BusinessCategory.BRAND
                            ? ('common.brand_account' | translate)
                            : (getFullFormattedAddress | applyPure: restaurant.address)
                    "></app-sidenav-content-restaurant-info>
            </div>
            @if (isPhoneScreen() || appIsHovered.isHovered()) {
                <div>
                    <mat-icon
                        class="!h-4 !w-4 text-malou-color-primary"
                        [svgIcon]="SvgIcon.EDIT"
                        (click)="onEditInternalName(restaurant._id)"></mat-icon>
                </div>
            }
        </div>
    </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #addBusinessTemplate>
    <app-button theme="secondary" buttonClasses="w-full" testId="restaurants-open-add-restaurant-btn" (buttonClick)="onBusinessAdd()">
        <ng-template #textTemplate>
            <div class="flex items-center justify-center gap-x-2">
                <div class="leading-none">
                    <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                </div>
                <div class="malou-color-text-1 malou-text-12--semibold">
                    {{ 'sidenav_content.select_restaurant.panel.add_business' | translate }}
                </div>
            </div>
        </ng-template>
    </app-button>
</ng-template>
