import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, input, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MediaType } from '@malou-io/package-utils';

import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-tiktok-reel-preview',
    templateUrl: './tiktok-reel-preview.component.html',
    standalone: true,
    imports: [NgClass, MatIconModule, TranslateModule, ImageViewerComponent, ImagePathResolverPipe],
    styleUrls: ['./tiktok-reel-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiktokReelPreviewComponent {
    readonly text = input<string>('');
    readonly media = input<EditionMedia>();
    readonly username = input<string>('');
    readonly profilePicture = input<string | undefined>();
    readonly hashtags = input<string[]>([]);

    readonly profilePictureCanvas = viewChild<ElementRef<HTMLCanvasElement>>('profilePictureCanvas');

    readonly SvgIcon = SvgIcon;
    readonly MediaType = MediaType;

    readonly hashtagsText = computed(() => this.hashtags().join(' '));

    readonly isPlayingVideo = signal(false);

    constructor() {
        effect(() => {
            const profilePictureCanvas = this.profilePictureCanvas()?.nativeElement;
            if (this.profilePicture() && profilePictureCanvas) {
                this._drawProfilePictureBorderInCanvas(profilePictureCanvas);
            }
        });
    }

    private _drawProfilePictureBorderInCanvas(canvas: HTMLCanvasElement): void {
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        // Create linear gradient
        const CIRCLE_DIAMETER = 32;
        const CIRCLE_WIDTH = 2;
        const grad = ctx.createLinearGradient(0, CIRCLE_DIAMETER, CIRCLE_DIAMETER, 0);
        grad.addColorStop(0, '#ffba4c');
        grad.addColorStop(0.5, '#ee116e');
        grad.addColorStop(1, '#8247e4');

        // Fill outlined arc with gradient
        ctx.lineWidth = CIRCLE_WIDTH;
        ctx.strokeStyle = grad;
        // Draw a circle
        ctx.arc(CIRCLE_DIAMETER / 2, CIRCLE_DIAMETER / 2, (CIRCLE_DIAMETER - CIRCLE_WIDTH) / 2, 0, 2 * Math.PI);
        ctx.stroke();
    }
}
