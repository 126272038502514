import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    GetMergedInformationUpdateBodyDto,
    GetMergedInformationUpdateResponseDto,
    HasRestaurantEverBeenUpdatedBodyDto,
    InformationUpdatesStateResponseDto,
    SaveInformationUpdateDataBodyDto,
    UpdateInformationUpdatePlatformStateStatusBodyDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class InformationUpdatesService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/information-updates`;

    constructor(private readonly _http: HttpClient) {}

    saveInformationUpdateData(body: SaveInformationUpdateDataBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/save-information-update-data`, body);
    }

    getMergedInformationUpdateData(
        body: GetMergedInformationUpdateBodyDto
    ): Observable<ApiResultV2<GetMergedInformationUpdateResponseDto>> {
        return this._http.post<ApiResultV2<GetMergedInformationUpdateResponseDto>>(
            `${this.API_BASE_URL}/get-merged-information-update-data`,
            body
        );
    }

    hasRestaurantEverBeenUpdated(body: HasRestaurantEverBeenUpdatedBodyDto): Observable<ApiResultV2<boolean>> {
        return this._http.post<ApiResultV2<boolean>>(`${this.API_BASE_URL}/has-restaurant-ever-been-updated`, body);
    }

    updateInformationUpdatePlatformStateStatus(body: UpdateInformationUpdatePlatformStateStatusBodyDto): Observable<void> {
        return this._http.post<void>(`${this.API_BASE_URL}/update-information-update-platform-state-status`, body);
    }

    getDetailedUpdateStatus(restaurantId: string): Observable<InformationUpdatesStateResponseDto> {
        return this._http.get<InformationUpdatesStateResponseDto>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/get-detailed-update-statuses`
        );
    }
}
