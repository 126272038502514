<div class="flex w-full flex-col" [class.opacity-50]="control.disabled">
    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>

    <ng-container [ngTemplateOutlet]="selectContainerTemplate"></ng-container>

    <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
</div>

<ng-template #titleTemplate>
    @if (title() || subtitle() || required()) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title()) {
                <span>{{ title() }}</span>
            }
            @if (subtitle()) {
                <span class="malou-text-10 italic">{{ subtitle() }}</span>
            }
            @if (required()) {
                <span>*</span>
            }
        </div>
    }
</ng-template>

<ng-template #selectContainerTemplate>
    <div
        class="flex items-center overflow-hidden rounded-lg bg-white pl-2"
        matAutocompleteOrigin
        [class.malou-border-primary]="!errorMessage() && !isFocused()"
        [class.malou-border-secondary]="!errorMessage() && isFocused()"
        [class.malou-border-error]="!!errorMessage()"
        [ngClass]="{
            'border-malou-color-border-primary !bg-malou-color-background-light': theme() === SelectBaseDisplayStyle.WITH_BACKGROUND,
        }"
        #origin="matAutocompleteOrigin">
        <div class="flex min-w-0 grow items-center gap-x-2">
            @if (multiSelection()) {
                <div
                    class="flex items-center gap-x-2"
                    appHideOverflowingChildren
                    [class.flex-wrap]="multiSelectionElementWrap()"
                    [maxDisplayedChildren]="maxSelectedValuesToDisplay()"
                    [disableObserver]="multiSelectionElementWrap()"
                    (childrenHidden)="onChildrenHidden($event)"
                    (refreshFn)="registerHideChildrenRefreshFn($event)">
                    <ng-container [ngTemplateOutlet]="selectedValuesTemplate"></ng-container>
                </div>
            }
            <ng-container [ngTemplateOutlet]="selectInputTemplate" [ngTemplateOutletContext]="{ origin }"></ng-container>
        </div>
        @if (!multiSelectionElementWrap() && childrenHiddenCount()) {
            <div>
                <span
                    class="malou-text-14--semibold malou-color-text-primary cursor-pointer"
                    (click)="$event.stopPropagation(); displayAllSelectedValues()">
                    +{{ childrenHiddenCount() }}
                </span>
            </div>
        }

        @if (!hideArrow()) {
            <ng-container [ngTemplateOutlet]="selectArrowTemplate"></ng-container>
        }
    </div>
</ng-template>

<ng-template #selectedValuesTemplate>
    @for (value of selectedValues(); track value; let index = $index) {
        <div class="flex h-12 shrink-0 items-center" (click)="$event.stopPropagation()">
            @if (selectedValueTemplate(); as selectedValueTemplate) {
                <ng-container
                    [ngTemplateOutlet]="selectedValueTemplate"
                    [ngTemplateOutletContext]="{ value, index, deleteValueAt }"></ng-container>
            }
        </div>
    }
</ng-template>

<ng-template let-origin="origin" #selectInputTemplate>
    <div class="h-12 grow" [class.w-full]="inputWithFixedWidth()">
        @if (!!currentSelectedValue()) {
            @if (simpleSelectedValueTemplate(); as simpleSelectedValueTemplate) {
                <div
                    class="flex h-full w-full"
                    tabindex="0"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    (click)="!control.disabled && openPanel()"
                    (keydown.backspace)="onBackspaceKeyPressed()">
                    <ng-container
                        [ngTemplateOutlet]="simpleSelectedValueTemplate"
                        [ngTemplateOutletContext]="{ value: currentSelectedValue() }"></ng-container>
                </div>
            }
        }

        <!-- size=1 -> To make the 'width' property be controlled by classes -->
        <input
            class="malou-text-12--medium malou-color-text-1 box-border h-full w-full min-w-[85px] border-0 bg-white px-3 outline-none"
            size="1"
            [readOnly]="control.disabled || inputReadOnly()"
            [hidden]="!!currentSelectedValue() && simpleSelectedValueTemplate()"
            [class.italic]="isEmptyValue()"
            [class.malou-text-12]="isEmptyValue()"
            [placeholder]="!currentSelectedValue() && !selectedValues().length ? placeholder() : ''"
            [ngClass]="{
                '!bg-malou-color-background-light': theme() === SelectBaseDisplayStyle.WITH_BACKGROUND,
            }"
            [matAutocomplete]="autocomplete"
            [matAutocompleteConnectedTo]="origin"
            [matAutocompleteDisabled]="true"
            [formControl]="control"
            [tabIndex]="-1"
            [disabled]="disabled()"
            [attr.data-testid]="testId()"
            (keydown.backspace)="onBackspaceKeyPressed()"
            (keydown.enter)="onEnterKeyPressed()"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (click)="!control.disabled && openPanel()"
            (input)="onInput($event)"
            #inputElement
            #trigger="matAutocompleteTrigger" />
        <mat-autocomplete
            class="select-base__mat-autocomplete bg-white"
            [disableRipple]="true"
            [displayWith]="displayWithHighFn"
            [hideSingleSelectionIndicator]="true"
            (optionSelected)="onSelectedOption($event)"
            (closed)="onPanelClosed()"
            #autocomplete="matAutocomplete">
            @if (showSelectAllCheckbox() && ((defaultEmptyValueMessage() && filteredValues().length) || !defaultEmptyValueMessage())) {
                <ng-container [ngTemplateOutlet]="autocompleteHeaderTemplate"></ng-container>
            }
            @for (value of filteredValues() | slice: 0 : displayedOptionCount(); track filteredValuesTrackByFn()($index, value)) {
                <mat-option
                    class="relative"
                    [value]="value"
                    [id]="getId | applyPure: value : idPrefix() : getIdSuffixFn()"
                    [disabled]="
                        (computedMaxSelectableValues() &&
                            computedMaxSelectableValues() <= selectedValues().length &&
                            !(needSelectedClass | applyPure: value : selectedValues() : checkboxOption())) ||
                        value.disabled ||
                        disabled()
                    "
                    [matTooltipDisabled]="!value.disabledWithTooltip"
                    [matTooltip]="value.tooltipMessage || ('common.option_disabled' | translate)"
                    [class.cursor-default]="value.disabledWithTooltip"
                    [class.opacity-50]="value.disabledWithTooltip"
                    [class.selected]="needSelectedClass | applyPure: value : selectedValues() : checkboxOption()"
                    [class.!p-0]="checkboxOption()">
                    @if (multiSelection()) {
                        <!-- span element is used to prevent the autocomplete from catching the click event because it closes the panel. 
                     If we reopen it with javascript, there is a blinking effect. -->
                        <span
                            class="absolute bottom-0 left-0 right-0 top-0 flex items-center"
                            [ngClass]="{ 'px-4': !checkboxOption() }"
                            (click)="onMatOptionClick(value); $event.stopPropagation()">
                            <ng-container [ngTemplateOutlet]="selectOptionTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
                        </span>
                    } @else {
                        <ng-container [ngTemplateOutlet]="selectOptionTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
                    }
                </mat-option>
            }
            @if (filteredValues().length === 0 && defaultEmptyValueMessage()) {
                <mat-option class="cursor-default" [disabled]="true">
                    <div class="malou-text-10 malou-color-text-2 italic">
                        {{ defaultEmptyValueMessage() }}
                    </div>
                </mat-option>
            }

            @if (valueBuilderTemplate() && control.value) {
                <mat-option
                    [class.opacity-50]="
                        (computedMaxSelectableValues() &&
                            computedMaxSelectableValues() <= selectedValues().length &&
                            !(
                                needSelectedClass
                                | applyPure: (buildTempChip | applyPure: control.value) : selectedValues() : checkboxOption()
                            )) ||
                        (buildTempChip | applyPure: control.value)?.disabled
                    "
                    [class.selected]="
                        needSelectedClass | applyPure: (buildTempChip | applyPure: control.value) : selectedValues() : checkboxOption()
                    "
                    [class.!p-0]="checkboxOption()"
                    [value]="buildTempChip | applyPure: control.value"
                    [disabled]="disabled()">
                    <ng-container [ngTemplateOutlet]="newValueBuilderTemplate"></ng-container>
                </mat-option>
            }

            @if (itemBuilder() && isNewOption()) {
                <mat-option [value]="control.value">
                    <span class="ml-2">{{ control.value }}</span>
                    <mat-icon class="malou-color-primary ml-1 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                </mat-option>
            }
            @if (showValuesSelectedCount() && ((defaultEmptyValueMessage() && filteredValues().length) || !defaultEmptyValueMessage())) {
                <ng-container [ngTemplateOutlet]="autocompleteFooterTemplate"></ng-container>
            }
        </mat-autocomplete>
    </div>
</ng-template>

<ng-template let-value="value" #selectOptionTemplate>
    @if (checkboxOption()) {
        <app-noop-mat-checkbox
            class="w-full px-4 text-malou-color-text-1"
            [checked]="isValueSelected | applyPure: selectedValues() : value">
            <ng-container [ngTemplateOutlet]="selectOptionTextTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
        </app-noop-mat-checkbox>
    } @else {
        <ng-container [ngTemplateOutlet]="selectOptionTextTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
    }
</ng-template>

<ng-template let-value="value" #selectOptionTextTemplate>
    @if (optionTemplate(); as optionTemplate) {
        <ng-container
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{ value, isValueSelected: isValueSelected | applyPure: selectedValues() : value }"></ng-container>
    } @else {
        {{ displayWithHighFn | applyPure: value }}
    }
</ng-template>

<ng-template #newValueBuilderTemplate>
    @if (valueBuilderTemplate(); as valueBuilderTemplate) {
        <ng-container [ngTemplateOutlet]="valueBuilderTemplate"></ng-container>
    }
</ng-template>

<ng-template #autocompleteHeaderTemplate>
    <div class="flex items-center justify-center py-2">
        <app-noop-mat-checkbox
            class="w-full px-4"
            color="primary"
            [disabled]="disabled()"
            [checked]="
                selectedValues().length === values().length ||
                isAllNonDisabledValuesSelected() ||
                (!!computedMaxSelectableValues() && selectedValues().length === computedMaxSelectableValues())
            "
            (click)="toggleAllFiltered()">
            @if (selectAllCheckboxMessage()) {
                <span class="malou-text-13--semibold text-malou-color-text-2">
                    {{ selectAllCheckboxMessage() }}
                </span>
            }
            @if (!selectAllCheckboxMessage() && showSelectAllCheckbox()) {
                <span class="malou-text-13--semibold text-malou-color-text-2">
                    {{ 'common.select_all' | translate }}
                </span>
            }
        </app-noop-mat-checkbox>
    </div>
</ng-template>

<ng-template #autocompleteFooterTemplate>
    <div class="sticky bottom-0 flex flex-col items-center justify-center bg-white py-2">
        @if (showValuesSelectedCount()) {
            <div class="malou-text-12--semibold">
                <span>{{ selectedValues().length }}</span>
                @if (computedMaxSelectableValues()) {
                    <span>/{{ computedMaxSelectableValues() }}</span>
                }
                @if (valuesSelectedCountMessage()) {
                    <span class="ml-1">{{ valuesSelectedCountMessage() }}</span>
                }
            </div>
        }
    </div>
</ng-template>

<ng-template #selectArrowTemplate>
    <div class="flex items-center px-5" (click)="!control.disabled && onArrowClick()">
        <mat-icon
            class="malou-color-primary !h-4 !w-4 cursor-pointer"
            [svgIcon]="SvgIcon.CHEVRON_DOWN"
            [class.panel-opened]="matAutocompleteTriggerElement()?.panelOpen">
        </mat-icon>
    </div>
</ng-template>

<ng-template #errorTemplate>
    @if (errorMessage()) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage() }}</div>
    }
</ng-template>
