import { AsyncPipe } from '@angular/common';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, Observable, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { getReviewsForSemanticAnalysis, isNotNil, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { TagsDoughnutChartComponent } from ':modules/statistics/e-reputation/review-analyses/tags-doughnut-chart/tags-doughnut-chart.component';
import { TagsEvolutionComponent } from ':modules/statistics/e-reputation/review-analyses/tags-evolution/tags-evolution.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { TagsBarChartComponent } from ':shared/components/review-analyses/tags-bar-chart/tags-bar-chart.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Restaurant, ReviewWithAnalysis } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-review-analyses',
    templateUrl: './review-analyses.component.html',
    styleUrls: ['./review-analyses.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        TagsBarChartComponent,
        TagsDoughnutChartComponent,
        TagsEvolutionComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
    ],
})
export class ReviewAnalysesComponent implements OnInit {
    @Input() shouldDisplayAnalysesTagCharts = true;
    @Input() shouldDisplayAnalysesTagEvolution = true;
    @Input() shouldDetailTagsEvolutionCharts = false;
    @Input() shouldDisplayAnalysesTagClickableLabels = true;
    @Input() tagEvolutionViewBy: ViewBy;
    @Output() hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() viewByChange = new EventEmitter<ViewBy>();
    @Output() hasDataChange = new EventEmitter<boolean>();
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    isLoading = signal(true);
    reviews: ReviewWithAnalysis[];
    isReviewAnalysisEnabled = true;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.dates$, this.platformKeys$, this._restaurantsService.restaurantSelected$])
            .pipe(
                tap(() => this.isLoading.set(true)),
                filter(([dates, platforms, restaurant]) => {
                    if (!isNotNil(restaurant)) {
                        return false;
                    }
                    if (!restaurant) {
                        this.isReviewAnalysisEnabled = false;
                        this.hasDataChange.emit(false);
                        this.isLoading.set(false);
                        return false;
                    }
                    if (!isDateSetOrGenericPeriod(dates) || platforms.length === 0) {
                        return false;
                    }
                    return true;
                }),
                switchMap(([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant]) => {
                    const restaurantId = restaurant._id;
                    const { startDate, endDate } = dates;

                    return this._reviewsService.getReviewsWithAnalysis(startDate, endDate, platforms, restaurantId);
                })
            )
            .subscribe({
                next: (res) => {
                    this._store.dispatch(StatisticsActions.editReviewsWithAnalysisData({ data: res }));
                    this.reviews = getReviewsForSemanticAnalysis(res);
                    if (!this.reviews?.length) {
                        this.hasDataChange.emit(false);
                    }
                    this.isLoading.set(false);
                },
            });
    }
}
