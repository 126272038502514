import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { random, sampleSize } from 'lodash';
import { map, Observable, of } from 'rxjs';

import {
    GetReviewAnalysesChartDataByRestaurantIdResponseDto,
    GetSegmentAnalysesTopTopicsBodyDto,
    GetSegmentAnalysesTopTopicsResponseDto,
    GetSegmentAnalysisParentTopicInsightsByCategoryBodyDto,
    GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto,
    SegmentAnalysisInsightsForRestaurantDto,
    TimeSeriesSegmentAnalysisDto,
} from '@malou-io/package-dto';
import { ApiResultV2, MalouComparisonPeriod, PlatformKey, ReviewAnalysisTag } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { TimeSeriesSegmentAnalysisWithRange } from ':shared/components/semantic-analysis-topic-evolution/semantic-analysis-topic-evolution.interface';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { objectToQueryParams } from ':shared/helpers/query-params';

// TODO: delete when real data
const getRandomData = (): any => ({
    positiveCount: random(1, 100),
    negativeCount: random(1, 100),
    positiveCountEvolution: random(1, 100),
    negativeCountEvolution: random(1, 100),
});

const FAKE_TOPICS = [
    {
        name: 'Pizza',
        translations: {
            fr: 'Pizza',
            es: 'Pizza',
            it: 'Pizza',
            en: 'Pizza',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Pasta',
        translations: {
            fr: 'Pasta',
            es: 'Pasta',
            it: 'Pasta',
            en: 'Pasta',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Sushi',
        translations: {
            fr: 'Sushi',
            es: 'Sushi',
            it: 'Sushi',
            en: 'Sushi',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Steak',
        translations: {
            fr: 'Steak',
            es: 'Steak',
            it: 'Steak',
            en: 'Steak',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Fish',
        translations: {
            fr: 'Fish',
            es: 'Fish',
            it: 'Fish',
            en: 'Fish',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Chicken',
        translations: {
            fr: 'Chicken',
            es: 'Chicken',
            it: 'Chicken',
            en: 'Chicken',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Burger',
        translations: {
            fr: 'Burger',
            es: 'Burger',
            it: 'Burger',
            en: 'Burger',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Salad',
        translations: {
            fr: 'Salad',
            es: 'Salad',
            it: 'Salad',
            en: 'Salad',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Soup',
        translations: {
            fr: 'Soup',
            es: 'Soup',
            it: 'Soup',
            en: 'Soup',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Curry',
        translations: {
            fr: 'Curry',
            es: 'Curry',
            it: 'Curry',
            en: 'Curry',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Rice',
        translations: {
            fr: 'Rice',
            es: 'Rice',
            it: 'Rice',
            en: 'Rice',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
    {
        name: 'Fries',
        translations: {
            fr: 'Fries',
            es: 'Fries',
            it: 'Fries',
            en: 'Fries',
        },
        positiveCount: random(1, 30),
        negativeCount: random(1, 30),
    },
];

@Injectable({ providedIn: 'root' })
export class SegmentAnalysesService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/segment-analyses`;

    constructor(private readonly _http: HttpClient) {}

    getSegmentAnalysesChartData(
        filters: ReviewAnalysesChartFilter
    ): Observable<ApiResultV2<GetReviewAnalysesChartDataByRestaurantIdResponseDto>> {
        const cleanFilters = {
            ...filters,
            startDate: filters.startDate ? filters.startDate.toISOString() : null,
            endDate: filters.endDate ? filters.endDate.toISOString() : null,
        };
        return this._http.post<ApiResultV2<GetReviewAnalysesChartDataByRestaurantIdResponseDto>>(
            `${this.API_BASE_URL}/chart`,
            cleanFilters
        );
    }

    getSegmentAnalysesTopTopics(
        filters: GetSegmentAnalysesTopTopicsBodyDto
    ): Observable<ApiResultV2<GetSegmentAnalysesTopTopicsResponseDto>> {
        const cleanFilters = {
            ...filters,
            startDate: filters.startDate ? filters.startDate.toISOString() : null,
            endDate: filters.endDate ? filters.endDate.toISOString() : null,
        };
        return this._http.post<ApiResultV2<GetSegmentAnalysesTopTopicsResponseDto>>(`${this.API_BASE_URL}/top-topics`, cleanFilters);
    }

    getSegmentAnalysisParentTopicInsightsByCategory(
        filters: GetSegmentAnalysisParentTopicInsightsByCategoryBodyDto
    ): Observable<ApiResultV2<GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto>> {
        const cleanFilters = {
            ...filters,
            startDate: filters.startDate ? filters.startDate.toISOString() : null,
            endDate: filters.endDate ? filters.endDate.toISOString() : null,
        };
        return this._http.post<ApiResultV2<GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto>>(
            `${this.API_BASE_URL}/category`,
            cleanFilters
        );
    }

    getTimeSeriesSegmentTopicsByParentTopicId(
        parentTopicId: string,
        filters: {
            keys: PlatformKey[];
            startDate: Date | null;
            endDate: Date | null;
        }
    ): Observable<TimeSeriesSegmentAnalysisWithRange> {
        const cleanFilters = objectToQueryParams({
            ...filters,
            startDate: filters.startDate ? filters.startDate.toISOString() : null,
            endDate: filters.endDate ? filters.endDate.toISOString() : null,
        });
        return this._http
            .get<ApiResultV2<TimeSeriesSegmentAnalysisDto>>(`${this.API_BASE_URL}/parent-topics/${parentTopicId}/chart/time-series`, {
                params: cleanFilters,
            })
            .pipe(map((res) => TimeSeriesSegmentAnalysisWithRange.fromDto(res.data)));
    }

    getTimeSeriesSegmentTopicsByCategory(
        category: ReviewAnalysisTag,
        filters: {
            restaurantId: string;
            keys: PlatformKey[];
            startDate: Date | null;
            endDate: Date | null;
        }
    ): Observable<TimeSeriesSegmentAnalysisWithRange> {
        const cleanFilters = removeNullOrUndefinedField(
            objectToSnakeCase({
                ...filters,
                startDate: filters.startDate ? filters.startDate.toISOString() : null,
                endDate: filters.endDate ? filters.endDate.toISOString() : null,
            })
        );
        return this._http
            .get<ApiResultV2<TimeSeriesSegmentAnalysisDto>>(`${this.API_BASE_URL}/category/${category}/chart/time-series`, {
                params: cleanFilters,
            })
            .pipe(map((res) => TimeSeriesSegmentAnalysisWithRange.fromDto(res.data)));
    }

    getAggregatedSegmentAnalysisForRestaurants({
        restaurantIds,
        startDate,
        endDate,
        platformKeys,
        comparisonPeriod,
    }: {
        restaurantIds: string[];
        startDate: Date | null;
        endDate: Date | null;
        platformKeys: PlatformKey[];
        comparisonPeriod: MalouComparisonPeriod;
    }): Observable<ApiResultV2<SegmentAnalysisInsightsForRestaurantDto[]>> {
        const _cleanFilters = {
            restaurantIds,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
            platformKeys,
            comparisonPeriod,
        };

        const FAKE_DATA = restaurantIds.map((restaurantId) => ({
            restaurantId,
            sentimentsByCategory: {
                [ReviewAnalysisTag.FOOD]: getRandomData(),
                [ReviewAnalysisTag.SERVICE]: getRandomData(),
                [ReviewAnalysisTag.ATMOSPHERE]: getRandomData(),
                [ReviewAnalysisTag.PRICE]: getRandomData(),
                [ReviewAnalysisTag.HYGIENE]: getRandomData(),
            },
            topTopics: {
                positiveTopics: sampleSize(FAKE_TOPICS, random(0, 6)),
                negativeTopics: sampleSize(FAKE_TOPICS, random(0, 6)),
            },
        }));
        return of({ data: FAKE_DATA });
        // return this._http.post<ApiResultV2<SegmentAnalysisInsightsForRestaurantDto[]>>(`${this.API_BASE_URL}/restaurants`, cleanFilters);
    }
}
