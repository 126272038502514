import lodash from 'lodash';
import { DateTime } from 'luxon';

import { Day, dayOrder, PlatformKey } from '../../constants';
import { createDateFromMalouDate } from '../../date';
import { isNotNil } from '../../functions';

type SpecialHours = {
    openTime?: string | null;
    closeTime?: string | null;
    endDate?: {
        day: number;
        year: number;
        month: number;
    };
    isClosed: boolean;
    startDate: {
        day: number;
        year: number;
        month: number;
    };
}[];

type RegularHours = {
    openTime?: string | null;
    closeTime?: string | null;
    openDay: string;
    closeDay: string;
    isClosed: boolean;
}[];

function shouldHandleSpecialHours(
    previousSpecialHours: SpecialHours,
    currentSpecialHours: SpecialHours,
    platformKey: PlatformKey
): boolean {
    switch (platformKey) {
        case PlatformKey.TRIPADVISOR:
            // Only display closed days for TripAdvisor
            return [...previousSpecialHours, ...currentSpecialHours].some((e) => e.isClosed);
        default:
            return true;
    }
}

export function getSpecialHoursToUpdate({
    previousSpecialHours,
    currentSpecialHours,
    platformKey,
}: {
    previousSpecialHours: SpecialHours;
    currentSpecialHours: SpecialHours;
    platformKey: PlatformKey;
}): {
    date: string;
    isPreviousClosed: boolean;
    previousTimes: string;
    isCurrentClosed: boolean;
    currentTimes: string;
}[] {
    const specialHoursGroupedByDay = lodash.groupBy(currentSpecialHours, ({ startDate }) =>
        DateTime.fromJSDate(createDateFromMalouDate(startDate)).toFormat('dd/MM/yyyy')
    );
    const previousSpecialHoursGroupedByDay = lodash.groupBy(previousSpecialHours, ({ startDate }) =>
        DateTime.fromJSDate(createDateFromMalouDate(startDate)).toFormat('dd/MM/yyyy')
    );

    const datesToCheck: string[] = lodash.uniq([
        ...Object.keys(specialHoursGroupedByDay),
        ...Object.keys(previousSpecialHoursGroupedByDay),
    ]);

    return datesToCheck
        .filter((date) =>
            shouldHandleSpecialHours(specialHoursGroupedByDay[date] ?? [], previousSpecialHoursGroupedByDay[date] ?? [], platformKey)
        )
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map((date) => {
            const previousSpecialHoursForDay = previousSpecialHoursGroupedByDay[date] ?? [];
            const currentSpecialHoursForDay = specialHoursGroupedByDay[date] ?? [];

            const isPreviousClosed = previousSpecialHoursForDay.some((e) => e.isClosed);
            const isCurrentClosed = currentSpecialHoursForDay.some((e) => e.isClosed);
            if (isPreviousClosed && isCurrentClosed) {
                return null;
            }

            const previousTimes = previousSpecialHoursForDay.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
            const currentTimes = currentSpecialHoursForDay.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
            if (previousTimes === currentTimes) {
                return null;
            }

            return {
                date,
                isPreviousClosed,
                previousTimes,
                isCurrentClosed,
                currentTimes,
            };
        })
        .filter(isNotNil);
}

export function getRegularHoursToUpdate({
    currentRegularHours,
    previousRegularHours,
}: {
    currentRegularHours: RegularHours;
    previousRegularHours: RegularHours;
}): {
    day: string;
    isPreviousClosed: boolean;
    previousTimes: string;
    isCurrentClosed: boolean;
    currentTimes: string;
}[] {
    const currentRegularHoursGroupedByDay = lodash.groupBy(currentRegularHours ?? [], ({ openDay }) => openDay);
    const previousRegularHoursGroupedByDay = lodash.groupBy(previousRegularHours ?? [], ({ openDay }) => openDay);

    const daysToCheck = lodash.uniq([
        ...Object.keys(previousRegularHoursGroupedByDay),
        ...Object.keys(currentRegularHoursGroupedByDay),
    ]) as Day[];

    return daysToCheck
        .map((day) => {
            const currentRegularHoursByDay = currentRegularHoursGroupedByDay[day] ?? [];
            const previousRegularHoursByDay = previousRegularHoursGroupedByDay[day] ?? [];
            const isPreviousClosed = previousRegularHoursByDay.some((e) => e.isClosed);
            const isCurrentClosed = currentRegularHoursByDay.some((e) => e.isClosed);
            if (isPreviousClosed && isCurrentClosed) {
                return null;
            }
            const previousTimes = previousRegularHoursByDay.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
            const currentTimes = currentRegularHoursByDay.map((e) => `${e.openTime} - ${e.closeTime}`).join(' / ');
            if (previousTimes === currentTimes) {
                return null;
            }

            return {
                day,
                isPreviousClosed,
                previousTimes,
                isCurrentClosed,
                currentTimes,
            };
        })
        .filter(isNotNil)
        .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);
}
