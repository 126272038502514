import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const uploadMediaV2QueryValidator = z
    .object({
        restaurantId: objectIdValidator,

        // ---- Optional fields. Can make the processing faster if specified. ---- //
        videoDurationInMilliseconds: z.coerce.number().gt(0).optional(),
        videoWidthInPixels: z.coerce.number().int().gt(0).optional(),
        videoHeightInPixels: z.coerce.number().int().gt(0).optional(),
        // ----------------------------------------------------------------------- //
    })
    .refine(
        (data) => {
            const values = [data.videoDurationInMilliseconds, data.videoWidthInPixels, data.videoHeightInPixels];
            const definedCount = values.filter((v) => v !== undefined).length;
            return definedCount === 0 || definedCount === 3;
        },
        {
            message: 'All three fields must be specified if one is provided.',
            path: ['videoDurationInMilliseconds', 'videoWidthInPixels', 'videoHeightInPixels'], // Attach error to all fields
        }
    );
export type UploadMediaV2QueryDto = z.infer<typeof uploadMediaV2QueryValidator>;

export enum UploadErrorCode {
    INVALID_FILE = 'INVALID_FILE',
}

export type UploadMediaV2ResponseDto =
    | {
          success: true;
          mediaId: string;
      }
    | {
          success: false;
          errorCode: UploadErrorCode.INVALID_FILE;
      };
