import { ImageEditorUtils } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/post-media-list/components/edit-media-modal/components/image-editor/image-editor-utils';

/**
 * This file is to be used in v1
 *
 * Copied from v2 component image-viewer.component.ts
 */

export function computeTransformDataStyles(
    mediaDimensions: { width: number; height: number },
    transformData: {
        rotationInDegrees: number;
        left: number;
        top: number;
        width: number;
        height: number;
    },
    containerElement: HTMLDivElement,
    mediaElement: HTMLImageElement | HTMLVideoElement
): void {
    const containerSize = { width: containerElement.clientWidth, height: containerElement.clientHeight };

    const is90DegreesRotated = transformData.rotationInDegrees % 180 === 90;
    const mediaDimensionsRotated = {
        width: is90DegreesRotated ? mediaDimensions.height : mediaDimensions.width,
        height: is90DegreesRotated ? mediaDimensions.width : mediaDimensions.height,
    };

    const transformArea = ImageEditorUtils.scaleTransformArea(transformData, mediaDimensionsRotated);

    const scaleRatio = ImageEditorUtils.getZoomRatioToCover(transformArea, containerSize);

    const transformAreaZoomed = ImageEditorUtils.scaleTransformArea(transformArea, { width: scaleRatio, height: scaleRatio });

    const scaleTransform = `scale(${scaleRatio})`;
    const rotateTransform = `rotate(${transformData.rotationInDegrees ?? 0}deg)`;

    const offsetTranslateModifier = _getOffsetTranslateModifier(transformData.rotationInDegrees);
    const offsetTranslateXPercentage = offsetTranslateModifier.x * ((is90DegreesRotated ? transformData.top : transformData.left) * 100);
    const offsetTranslateYPercentage = offsetTranslateModifier.y * ((is90DegreesRotated ? transformData.left : transformData.top) * 100);
    const offsetTranslateTransform = `translate(${offsetTranslateXPercentage}%, ${offsetTranslateYPercentage}%)`;

    const scaledCenteringX = ((transformAreaZoomed.width - containerSize.width) / 2) * -1;
    const scaledCenteringY = ((transformAreaZoomed.height - containerSize.height) / 2) * -1;
    const scaledTranslateTransform = `translate(${scaledCenteringX}px, ${scaledCenteringY}px)`;

    const undoRotationTranslatePercentages = _getUndoRotationTranslatePercentages(transformData.rotationInDegrees);
    const undoRotationTranslateTransform = `translate(${undoRotationTranslatePercentages.x}%, ${undoRotationTranslatePercentages.y}%)`;

    /**
     * Reminder : In css, each transform is executed from left to right
     *
     * From left to right :
     *    - offsetTranslateTransform : we simply apply the transformData 'left' and 'top' attributes
     *    - undoRotationTranslateTransform : we cancel the position shift caused by the following rotation
     *      More info in _getUndoRotationTranslatePercentages.
     *    - rotateTransform : we rotate the media
     *    - scaleTransform : we scale the media
     *    - scaledTranslateTransform : we center the media on the container
     */
    // eslint-disable-next-line max-len
    mediaElement.style.transform = `${scaledTranslateTransform} ${scaleTransform} ${rotateTransform} ${undoRotationTranslateTransform} ${offsetTranslateTransform}`;
    mediaElement.style.transformOrigin = 'top left';
    mediaElement.style.width = `${mediaDimensions.width}px`;
    mediaElement.style.height = `${mediaDimensions.height}px`;
}

/**
 * If the media has rotation, we need to flip the sign if we apply translate() if it is positioned at the right of the rotate().
 * Reminder : In css, each transform is executed from left to right
 */
function _getOffsetTranslateModifier(rotationInDegrees: number): { x: 1 | -1; y: 1 | -1 } {
    if (rotationInDegrees === 0) {
        return { x: -1, y: -1 };
    }
    if (rotationInDegrees === 90) {
        return { x: -1, y: 1 };
    }
    if (rotationInDegrees === 180) {
        return { x: 1, y: 1 };
    }
    if (rotationInDegrees === 270) {
        return { x: 1, y: -1 };
    }
    return { x: -1, y: -1 };
}

/**
 * We always want the media top left corner to be at the top left corner of his parent container.
 * If we will apply a rotate() (ie rotationInDegrees !== 0), we preemptively translate the media with these values
 * So that after the rotate(), the media is at the top left corner the his parent container.
 */
function _getUndoRotationTranslatePercentages(rotationInDegrees: number): { x: number; y: number } {
    if (rotationInDegrees === 0) {
        return { x: 0, y: 0 };
    }
    if (rotationInDegrees === 90) {
        return { x: 0, y: -100 };
    }
    if (rotationInDegrees === 180) {
        return { x: -100, y: -100 };
    }
    if (rotationInDegrees === 270) {
        return { x: -100, y: 0 };
    }
    return { x: 0, y: 0 };
}
