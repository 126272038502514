import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BarElement, Chart, ChartDataset, ChartOptions, ChartType, Plugin, Tick, TooltipItem } from 'chart.js';
import { BoxAnnotationOptions } from 'chartjs-plugin-annotation';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import {
    APP_DEFAULT_LANGUAGE,
    getMonthAndYearBetweenDates,
    KeywordSearchImpressionsType,
    LuxonFormats,
    MONTH_DAY_TO_FETCH_KEYWORD_SEARCH_IMPRESSIONS,
    MonthAndYear,
} from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import {
    ChartDataArray,
    malouChartColorBluePurple,
    malouChartColorLighterBlue,
    malouChartColorLightPurple,
    malouChartColorPrimary5Percent,
    malouChartColorText2,
} from ':shared/helpers';

type BarChartType = Extract<ChartType, 'bar'>;

interface DatasetEmptyMonth {
    datasetIndex: number;
    isApiDataUnavailable: boolean;
    month: number;
    year: number;
}

@Component({
    selector: 'app-impressions-insights-chart',
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    templateUrl: './impressions-insights-chart.component.html',
    styleUrl: './impressions-insights-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpressionsInsightsChartComponent {
    readonly impressionsInsightsData = input.required<ImpressionsInsightsData[]>();
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly hiddenDatasetIndexesChange = output<number[]>();

    private readonly _translateService = inject(TranslateService);

    private readonly _hiddenDatasetIndexes = signal<number[]>([]);
    private readonly _maxYAxisValue = computed<number>(() => {
        const hiddenDatasetIndexes = this.hiddenDatasetIndexes()?.length ? this.hiddenDatasetIndexes() : this._hiddenDatasetIndexes();
        return this._getMaxYAxisValue(this.impressionsInsightsData(), hiddenDatasetIndexes);
    });
    private readonly _MISSING_MONTHS_DATA_BOX_ANNOTATIONS = computed<BoxAnnotationOptions[]>(() =>
        this._getMissingMonthDataBoxAnnotations(this.datasetEmptyMonths(), this._maxYAxisValue())
    );
    readonly MISSING_DATA_COLUMN: Plugin = this._addTextOnMissingMonthColumnPlugin();
    readonly CHART_TYPE: BarChartType = 'bar';

    readonly chartData: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() => {
        const chartDataSets = this._computeChartData(this.impressionsInsightsData());
        if (this.hiddenDatasetIndexes().length) {
            return chartDataSets.filter((_, index) => !this.hiddenDatasetIndexes().includes(index));
        }
        return chartDataSets;
    });
    readonly chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.impressionsInsightsData()));
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions(this.chartLabels()));

    readonly datasetEmptyMonths = computed(() => this._getDatasetEmptyMonths(this.impressionsInsightsData()));

    private _computeChartData(impressionsInisghtsData: ImpressionsInsightsData[]): ChartDataset<BarChartType, ChartDataArray>[] {
        const brandingValues = impressionsInisghtsData.map((data) => data[KeywordSearchImpressionsType.BRANDING]);
        const discoveryValues = impressionsInisghtsData.map((data) => data[KeywordSearchImpressionsType.DISCOVERY]);

        this.hiddenDatasetIndexesChange.emit([]);
        return [
            {
                label: this._translateService.instant('statistics.seo.keyword_search_impressions.branding'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: brandingValues,
            },
            {
                label: this._translateService.instant('statistics.seo.keyword_search_impressions.discovery'),
                borderColor: malouChartColorLightPurple,
                backgroundColor: malouChartColorLightPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: discoveryValues,
            },
        ];
    }

    private _computeChartLabels(impressionsInisghtsData: ImpressionsInsightsData[]): string[] {
        const lang = LocalStorage.getItem(LocalStorageKey.LANG) ?? APP_DEFAULT_LANGUAGE;
        return impressionsInisghtsData.map((data) =>
            DateTime.fromObject({ month: data.month, year: data.year }).setLocale(lang).toFormat('LLL yyyy')
        );
    }

    private _computeChartOptions(labels: string[]): ChartOptions<BarChartType> {
        return {
            animation: false,
            responsive: true,
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.update((currentValue) => [...currentValue, index]);
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes.update((currentValue) => currentValue.filter((i) => i !== index));
                            ci.show(index);
                            legendItem.hidden = false;
                        }

                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes());
                    },
                },
                annotation: {
                    annotations: this._MISSING_MONTHS_DATA_BOX_ANNOTATIONS(),
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_, index: number, _ticks: Tick[]): string => labels[index],
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display: true,
                        color: (_context): string | undefined => malouChartColorLighterBlue,
                    },
                    position: 'left',
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItem: TooltipItem<any>): string {
        return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
    }

    private _getMaxYAxisValue(impressionsInsightsData: ImpressionsInsightsData[], hiddenDatasetIndexes: number[]): number {
        const orderedDatasetsLabels = [KeywordSearchImpressionsType.BRANDING, KeywordSearchImpressionsType.DISCOVERY];
        const displayedDatasetsLabels = orderedDatasetsLabels.filter((_, index) => !hiddenDatasetIndexes.includes(index));

        const defaultYAxisValue = 1;
        let maxYAxisValue = 0;
        for (const data of impressionsInsightsData) {
            const sum = displayedDatasetsLabels.reduce((acc, label) => acc + data[label], 0);
            if (sum > maxYAxisValue) {
                maxYAxisValue = sum;
            }
        }
        return maxYAxisValue || defaultYAxisValue;
    }

    private _getMissingMonthDataBoxAnnotations(datasetEmptyMonths: DatasetEmptyMonth[], maxYAxisValue: number): BoxAnnotationOptions[] {
        const boxAnnotationWidth = 0.74;

        const _roundToNearestPowerOfTen = (n: number): number => {
            const power = Math.pow(10, Math.floor(Math.log10(n)));
            return Math.round(n / power) * power;
        };

        return datasetEmptyMonths.map(({ datasetIndex }) => ({
            type: 'box',
            xMin: datasetIndex - boxAnnotationWidth / 2,
            xMax: datasetIndex + boxAnnotationWidth / 2,
            yMin: 0,
            yMax: _roundToNearestPowerOfTen(maxYAxisValue),
            backgroundColor: malouChartColorPrimary5Percent,
            borderWidth: 0,
            adjustScaleRange: true,
        }));
    }

    private _addTextOnMissingMonthColumnPlugin(): Plugin {
        return {
            id: 'customAddTextOnMissingMonthColumnPlugin',
            afterDraw: (chart: Chart): void => {
                const dataLength = chart.data.datasets?.[0].data.length;
                const { ctx } = chart;
                ctx.save();
                for (const emptyMonth of this.datasetEmptyMonths()) {
                    const bar = chart.getDatasetMeta(0).data[emptyMonth.datasetIndex] as BarElement;
                    const centerX = bar.getCenterPoint().x;
                    const maxWidth = (bar as any).width + 40;

                    const fontSize = dataLength > 9 ? '8px' : '10px';
                    const lineHeight = 15;
                    ctx.textAlign = 'center';
                    ctx.fillStyle = malouChartColorText2;

                    const missingDataText = emptyMonth.isApiDataUnavailable
                        ? this._translateService.instant('statistics.seo.keyword_search_impressions.data_available_from', {
                              date: DateTime.fromObject({
                                  month: emptyMonth.month + 1,
                                  year: emptyMonth.year + 1,
                                  day: MONTH_DAY_TO_FETCH_KEYWORD_SEARCH_IMPRESSIONS,
                              }).toFormat(LuxonFormats.dayMonth),
                          })
                        : this._translateService.instant('statistics.seo.keyword_search_impressions.missing_data');

                    const textOptions = [
                        {
                            font: `bold ${fontSize} Poppins`,
                            text: missingDataText,
                            y: bar.y / 2,
                        },
                    ];

                    ctx.textAlign = 'center';
                    textOptions.forEach(({ font, text, y }) => {
                        ctx.beginPath();
                        ctx.font = font;
                        this._fillWithLineBreak({ context: ctx, text, x: centerX, y, fitWidth: maxWidth, lineHeight });
                        ctx.fill();
                    });
                }

                ctx.restore();
            },
        };
    }

    private _getDatasetEmptyMonths(impressionsInsightsData: ImpressionsInsightsData[]): DatasetEmptyMonth[] {
        const currentMonthDay = DateTime.now().day;
        const monthYearRange = impressionsInsightsData.map(({ month, year }) => ({ month, year }));

        const previousMonths = currentMonthDay >= MONTH_DAY_TO_FETCH_KEYWORD_SEARCH_IMPRESSIONS ? 0 : 1;
        const previousMonthsInUnavailableDataRange: MonthAndYear[] = getMonthAndYearBetweenDates(
            DateTime.now().minus({ months: previousMonths }).toJSDate(),
            DateTime.now().toJSDate()
        );

        const datasetEmptyMonths: DatasetEmptyMonth[] = [];

        for (let i = 0; i < monthYearRange.length; i++) {
            const isMonthYearInUnavailableDataRange = previousMonthsInUnavailableDataRange.some(
                (date) => date.month === monthYearRange[i].month && date.year === monthYearRange[i].year
            );

            const isEmptyMonthData =
                impressionsInsightsData[i][KeywordSearchImpressionsType.BRANDING] === 0 &&
                impressionsInsightsData[i][KeywordSearchImpressionsType.DISCOVERY] === 0;

            if (isEmptyMonthData && isMonthYearInUnavailableDataRange) {
                datasetEmptyMonths.push({
                    datasetIndex: i,
                    isApiDataUnavailable: true,
                    month: monthYearRange[i].month,
                    year: monthYearRange[i].year,
                });
                continue;
            }

            if (isEmptyMonthData) {
                datasetEmptyMonths.push({
                    datasetIndex: i,
                    isApiDataUnavailable: false,
                    month: monthYearRange[i].month,
                    year: monthYearRange[i].year,
                });
            }
        }

        return datasetEmptyMonths;
    }

    private _fillWithLineBreak({
        context,
        text,
        x,
        y,
        fitWidth,
        lineHeight = 20,
    }: {
        context: CanvasRenderingContext2D;
        text: string;
        x: number;
        y: number;
        fitWidth: number;
        lineHeight: number;
    }): void {
        const words = text.split(' ');
        let currentLine = 0;
        let currentText = '';

        for (const word of words) {
            const tempText = currentText ? `${currentText} ${word}` : word;
            const tempWidth = context.measureText(tempText).width;

            if (tempWidth > fitWidth) {
                context.fillText(currentText, x, y + lineHeight * currentLine);
                currentText = word;
                currentLine++;
            } else {
                currentText = tempText;
            }
        }

        if (currentText) {
            context.fillText(currentText, x, y + lineHeight * currentLine);
        }
    }
}
