import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart, MalouComparisonPeriod, PlatformFilterPage } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { CommunityV2Component } from ':modules/statistics/social-networks/community-v2/community.component';
import { CommunityComponent } from ':modules/statistics/social-networks/community/community.component';
import { EngagementV2Component } from ':modules/statistics/social-networks/engagement-v2/engagement.component';
import { EngagementComponent } from ':modules/statistics/social-networks/engagement/engagement.component';
import { PostsInsightsTableComponent } from ':modules/statistics/social-networks/posts-insights-table/posts-insights-table.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-social-network-pdf',
    standalone: true,
    imports: [
        CommonModule,
        CommunityComponent,
        EngagementComponent,
        PostsInsightsTableComponent,
        AsyncPipe,
        TranslateModule,
        FromToDateFormatterPipe,
        IncludesPipe,
        AsyncPipe,
        StatisticsPdfRestaurantsFormatterPipe,
        CommunityV2Component,
        EngagementV2Component,
    ],
    templateUrl: './social-network-pdf.component.html',
    styleUrls: ['./social-network-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialNetworkPdfComponent {
    readonly InsightsChart = InsightsChart;
    readonly ViewBy = ViewBy;

    private readonly _store = inject(Store);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _experimentationService = inject(ExperimentationService);
    public readonly translateService = inject(TranslateService);

    chartOptions: ChartOptions = {};
    displayedCharts: InsightsChart[] = [];
    displayedPlatforms: string;

    startDate: Date;
    endDate: Date;

    communityHasData = true;
    engagementHasData = true;

    readonly selectedRestaurantTitle$: Observable<string>;

    // TODO: Remove when the feature flag is removed
    readonly isReleaseBoosterPerformanceImprovementsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-simple-social-media-performance-improvements'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-simple-social-media-performance-improvements'),
        }
    );

    constructor() {
        const parsedQueryParams = parseInsightsRouteParams();
        const { dates, displayedCharts, chartOptions, platformKeys, comparisonPeriod } = parsedQueryParams;

        this.chartOptions = chartOptions ?? {};
        this.displayedCharts = displayedCharts;

        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.displayedPlatforms = this.translateService.instant('statistics_pdf.e_reputation_pdf.platforms', {
            platforms: (platformKeys ?? []).map((platformKey) => this._enumTranslatePipe.transform(platformKey, 'platform_key')).join(', '),
        });

        this._store.dispatch(StatisticsActions.editPlatforms({ page: PlatformFilterPage.SOCIAL_NETWORKS, platforms: platformKeys ?? [] }));

        this._store.dispatch(
            StatisticsActions.editComparisonPeriod({ comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD })
        );

        this.selectedRestaurantTitle$ = this._restaurantsService.restaurantSelected$.pipe(
            map((restaurant) => restaurant?.internalName ?? restaurant?.name ?? '')
        );
    }
}
