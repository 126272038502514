import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    AggregatedKeywordSearchImpressionsInsightsDto,
    AvegrageTopKeywordSearchImpressionsDto,
    GetAggregatedKeywordSearchImpressionsInsightsBodyDto,
    GetAverageTopKeywordSearchImpressionsBodyDto,
    GetKeywordSearchImpressionsInsightsBodyDto,
    GetTopKeywordSearchImpressionsBodyDto,
    KeywordSearchImpressionsInsightsDto,
    TopKeywordSearchImpressionsDto,
} from '@malou-io/package-dto';
import { AggregationType, ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';

@Injectable({
    providedIn: 'root',
})
export class KeywordSearchImpressionsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/keyword-search-impressions`;

    constructor(private readonly _http: HttpClient) {}

    getKeywordSearchImpressionsInsights(
        restaurantId: string,
        body: GetKeywordSearchImpressionsInsightsBodyDto
    ): Observable<KeywordSearchImpressionsInsightsDto[]> {
        return this._http
            .post<
                ApiResultV2<KeywordSearchImpressionsInsightsDto[]>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}/insights`, { ...body })
            .pipe(map((res) => res.data));
    }

    getTopKeywordSearchImpressions(
        restaurantId: string,
        body: GetTopKeywordSearchImpressionsBodyDto
    ): Observable<TopKeywordSearchImpressionsDto> {
        return this._http
            .post<ApiResultV2<TopKeywordSearchImpressionsDto>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/top`, { ...body })
            .pipe(map((res) => res.data));
    }

    getKeywordSearchImpressionsAggregatedInsights<T extends AggregationType>(
        body: GetAggregatedKeywordSearchImpressionsInsightsBodyDto
    ): Observable<AggregatedKeywordSearchImpressionsInsightsDto<T>> {
        return this._http
            .post<ApiResultV2<AggregatedKeywordSearchImpressionsInsightsDto<T>>>(`${this.API_BASE_URL}/aggregated/insights`, { ...body })
            .pipe(map((res) => res.data));
    }

    getTopKeywordSearchImpressionsAggregated(
        body: GetAverageTopKeywordSearchImpressionsBodyDto
    ): Observable<AvegrageTopKeywordSearchImpressionsDto> {
        return this._http
            .post<ApiResultV2<AvegrageTopKeywordSearchImpressionsDto>>(`${this.API_BASE_URL}/aggregated/top`, { ...body })
            .pipe(map((res) => res.data));
    }
}
