import { NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    inject,
    input,
    model,
    OnInit,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, switchMap } from 'rxjs';

import { UpdatePlatformAccessStatusBodyDto } from '@malou-io/package-dto';
import { InformationUpdatePlatformStateStatus, PlatformAccessStatus, PlatformAccessType, PlatformKey } from '@malou-io/package-utils';

import { InformationUpdatesService } from ':core/services/information-update.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import {
    AccessComponent,
    AccessStatusGroupName,
    DEFAULT_ACCESS_STATUS_GROUPS,
} from ':modules/admin/platforms-management/access/access.component';
import { PlatformsManagementViewType } from ':modules/admin/platforms-management/platforms-management-actions-header/platforms-management-action-header.interface';
import { PlatformManagementPanelContent } from ':modules/admin/platforms-management/platforms-management.component';
import { PlatformManagementUpdateDoneData, UpdatesComponent } from ':modules/admin/platforms-management/updates/updates.component';
import { selectSelectedOption, selectViewType } from ':modules/admin/store/admin.reducer';
import { InformationUpdateOptions, PlatformAccess, Restaurant } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

@Component({
    selector: 'app-updates-and-accesses-panel-content',
    standalone: true,
    imports: [
        AccessComponent,
        IncludesPipe,
        MatDividerModule,
        MatExpansionModule,
        MatIconModule,
        TranslateModule,
        UpdatesComponent,
        NgStyle,
        ImagePathResolverPipe,
    ],
    templateUrl: './updates-and-accesses-panel-content.component.html',
    styleUrl: './updates-and-accesses-panel-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatesAndAccessesPanelContentComponent implements OnInit {
    readonly panelContent = model.required<PlatformManagementPanelContent>();
    readonly allAccessList = input<PlatformAccess[]>([]);

    readonly _store = inject(Store);
    readonly _informationUpdatesService = inject(InformationUpdatesService);
    readonly _toastService = inject(ToastService);
    readonly _httpErrorPipe = inject(HttpErrorPipe);
    readonly _destroyRef = inject(DestroyRef);
    readonly _restaurantsService = inject(RestaurantsService);
    readonly _translate = inject(TranslateService);

    readonly InformationUpdateOptions = InformationUpdateOptions;
    readonly viewType = this._store.selectSignal(selectViewType);
    readonly isBusinessView = computed(() => this.viewType() === PlatformsManagementViewType.BUSINESS);
    readonly selectedOption = this._store.selectSignal(selectSelectedOption);

    readonly currentOpenPanelId = signal<string | null>(null);

    readonly accessUpdate: WritableSignal<PlatformManagementUpdateDoneData | null> = signal(null);

    readonly updatesData = computed(() => this.panelContent().updatesData);

    readonly updateInformationUpdatePlatformStateStatus$ = new BehaviorSubject<{
        platformKey: PlatformKey;
        restaurant: Restaurant;
        status: InformationUpdatePlatformStateStatus;
    } | null>(null);
    readonly updatePlatformAccessStatus$ = new BehaviorSubject<(UpdatePlatformAccessStatusBodyDto & { restaurantId }) | null>(null);

    ngOnInit(): void {
        this.updateInformationUpdatePlatformStateStatus$
            .pipe(
                filter(Boolean),
                switchMap(({ restaurant, platformKey, status }) =>
                    this._informationUpdatesService.updateInformationUpdatePlatformStateStatus({
                        restaurantId: restaurant.id,
                        platformKey,
                        status,
                    })
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                error: (error) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(error));
                },
            });

        this.updatePlatformAccessStatus$
            .pipe(
                filter(Boolean),
                switchMap(({ restaurantId, ...payload }) => this._restaurantsService.updatePlatformAccessStatus(restaurantId, payload)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                error: (error) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(error));
                },
            });
    }

    openPanel(panelContent: PlatformManagementPanelContent): void {
        this.currentOpenPanelId.set(panelContent.openPanelId);
    }

    closePanel(): void {
        this.currentOpenPanelId.set(null);
    }

    onToggleInformationUpdateStatus({
        platformKey,
        restaurant,
        status,
    }: {
        platformKey: PlatformKey;
        restaurant: Restaurant;
        status: InformationUpdatePlatformStateStatus;
    }): void {
        this._updateInformationUpdatePlatformStateStatus({ platformKey, restaurant, status });

        const platformAccessStatus = this._mapInformationUpdatePlatformStateStatusToPlatformAccessStatus(status);
        const platformAccess = restaurant.access.find((a) => a.platformKey === platformKey);

        this.onTogglePlatformAccess({
            restaurantId: restaurant.id,
            element: platformAccess!,
            status: platformAccessStatus,
        });

        this.panelContent.update((p) => ({
            ...p,
            accessList: p.accessList.map((a) => {
                if (a.platformKey === platformKey && a.restaurantId === restaurant.id) {
                    return {
                        ...a,
                        status: platformAccessStatus,
                    };
                }
                return a;
            }),
            updatesData: p.updatesData.map((u) => {
                if (u.platformKey === platformKey && u.restaurant._id === restaurant.id) {
                    return {
                        ...u,
                        mergedInformationUpdate: {
                            ...u.mergedInformationUpdate,
                            platformState: {
                                ...u.mergedInformationUpdate.platformState,
                                status: status,
                            },
                        },
                    };
                    return u;
                }
                return u;
            }),
        }));
    }

    onTogglePlatformAccess({
        restaurantId,
        element,
        status,
    }: {
        restaurantId: string;
        element: PlatformAccess;
        status: PlatformAccessStatus;
    }): void {
        const accessType =
            element.accessType === PlatformAccessType.CREATE && status === PlatformAccessStatus.VERIFIED
                ? PlatformAccessType.MANAGER
                : element.accessType;
        const verifiedDate = status === PlatformAccessStatus.NEED_REVIEW ? undefined : new Date();

        let active = false;

        if (
            [PlatformAccessStatus.VERIFIED, PlatformAccessStatus.NEED_REVIEW].includes(status) &&
            [PlatformAccessType.MANAGER, PlatformAccessType.CREDENTIALS].includes(accessType)
        ) {
            active = true;
        }
        if (this._getAccessStatusGroupNameByStatus(status) === AccessStatusGroupName.ERROR) {
            active = false;
        }

        const payload: UpdatePlatformAccessStatusBodyDto = {
            platformKey: element.platformKey,
            status,
            active,
            accessType,
        };

        this._updatePlatformAccessStatus({ ...payload, restaurantId });

        this.panelContent.update((p) => ({
            ...p,
            accessList: p.accessList.map((a) => {
                if (a.platformKey === element.platformKey && a.restaurantId === restaurantId) {
                    return {
                        ...a,
                        status,
                        accessType,
                        lastVerified: verifiedDate,
                        active,
                    };
                }
                return a;
            }),
        }));
    }

    private _updateInformationUpdatePlatformStateStatus({
        platformKey,
        restaurant,
        status,
    }: {
        platformKey: PlatformKey;
        restaurant: Restaurant;
        status: InformationUpdatePlatformStateStatus;
    }): void {
        this.updateInformationUpdatePlatformStateStatus$.next({ platformKey, restaurant, status });
    }

    private _updatePlatformAccessStatus(data: UpdatePlatformAccessStatusBodyDto & { restaurantId: string }): void {
        this.updatePlatformAccessStatus$.next(data);
    }

    private _mapInformationUpdatePlatformStateStatusToPlatformAccessStatus(
        status: InformationUpdatePlatformStateStatus
    ): PlatformAccessStatus {
        switch (status) {
            case InformationUpdatePlatformStateStatus.BAD_ACCESS:
                return PlatformAccessStatus.BAD_ACCESS;
            case InformationUpdatePlatformStateStatus.INVALID_PAGE:
                return PlatformAccessStatus.INVALID_PAGE;
            case InformationUpdatePlatformStateStatus.UNCLAIMED_PAGE:
                return PlatformAccessStatus.UNCLAIMED_PAGE;
            case InformationUpdatePlatformStateStatus.DONE:
                return PlatformAccessStatus.VERIFIED;
            case InformationUpdatePlatformStateStatus.ERROR:
                return PlatformAccessStatus.FAILED;
            case InformationUpdatePlatformStateStatus.MANUAL_UPDATE_ERROR:
                return PlatformAccessStatus.FAILED;
            case InformationUpdatePlatformStateStatus.PENDING:
                return PlatformAccessStatus.NEED_REVIEW;
            case InformationUpdatePlatformStateStatus.TO_BE_SENT:
                return PlatformAccessStatus.IN_PROGRESS;
        }
    }

    private _getAccessStatusGroupNameByStatus(status: PlatformAccessStatus): AccessStatusGroupName | undefined {
        const group = DEFAULT_ACCESS_STATUS_GROUPS.find((accessStatusGroup) => accessStatusGroup.accessStatuses.includes(status));
        return group?.name;
    }
}
