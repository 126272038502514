import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    inject,
    OnInit,
    output,
    Signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { combineLatest, debounceTime, filter } from 'rxjs';

import { PostPublicationStatus, PostSource, SocialPostsListFilter, TimeInMilliseconds } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    DisconnectedPlatformsModalComponent,
    DisconnectedPlatformsModalProps,
    DisconnectedPlatformsModalResult,
    DisconnectedPlatformsModalType,
} from ':modules/informations/disconnected-platforms-modal/disconnected-platforms-modal.component';
import { FeedHeaderComponent } from ':modules/posts-v2/social-posts/components/feed-header/feed-header.component';
import { FeedComponent } from ':modules/posts-v2/social-posts/components/feed/feed.component';
import { NoSocialPostComponent } from ':modules/posts-v2/social-posts/components/no-social-post/no-social-post.component';
import { SocialPostItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-item.component';
import { SocialPostsListHeaderComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list-header/social-posts-list-header.component';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Platform, Restaurant } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-social-posts-list-v2',
    templateUrl: './social-posts-list.component.html',
    styleUrls: ['./social-posts-list.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatCheckboxModule,
        FeedComponent,
        FeedHeaderComponent,
        NoSocialPostComponent,
        SocialPostsListHeaderComponent,
        SocialPostItemComponent,
        InfiniteScrollDirective,
        SkeletonComponent,
        ApplyPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostsListV2Component implements OnInit {
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();
    readonly updatePost = output<{ postId: string; shouldOpenFeedbacks?: boolean }>();
    readonly deletePost = output<{ postId: string; isPublishedOnFacebook: boolean }>();
    readonly duplicateToOtherRestaurants = output<{ postIds: string[]; postDestination: PostSource }>();
    readonly duplicateToSeoHere = output<{ postIds: string[]; postDestination: PostSource }>();

    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _socialPostsV2Service = inject(SocialPostsV2Service);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _router = inject(Router);
    private readonly _customDialogService = inject(CustomDialogService);

    readonly posts: Signal<SocialPostItem[]> = this._socialPostsContext.sortedPosts;
    readonly isFetchingPosts: Signal<boolean> = this._socialPostsContext.isFetchingPosts;
    readonly isFetchingMorePosts: Signal<boolean> = this._socialPostsContext.isFetchingMorePosts;
    readonly selectedFilter: WritableSignal<SocialPostsListFilter> = this._socialPostsContext.selectedFilter;
    readonly selectedFilter$ = toObservable(this.selectedFilter);
    readonly fetchNextPage$ = this._socialPostsContext.fetchNextPage$;

    readonly feed: Signal<FeedItem[]> = this._socialPostsContext.feed;
    readonly isFetchingFeed: Signal<boolean> = this._socialPostsContext.isFetchingFeed;
    readonly isFetchingMoreFeed: Signal<boolean> = this._socialPostsContext.isFetchingMoreFeed;
    readonly fetchNextPageFeed$ = this._socialPostsContext.fetchFeedNextPage$;

    readonly isSelecting = this._socialPostsContext.isSelecting;
    readonly selectedPostsCount = this._socialPostsContext.postSelection.getCountAsSignal();

    readonly highlightedPostIds = this._socialPostsContext.highlightedPostIds;

    readonly restaurant$ = this._socialPostsContext.restaurant$;
    readonly restaurant = toSignal<Restaurant | null>(this.restaurant$, { initialValue: null });

    readonly FETCH_POSTS_LIMIT = 20;
    readonly FETCH_FEED_LIMIT = 30;

    readonly restaurantHasNoPost = this._socialPostsContext.restaurantHasNoPost;

    readonly PostSource = PostSource;

    ngOnInit(): void {
        this._activatedRoute.queryParams.pipe(filter((params) => !!params.postId)).subscribe((params) => {
            const shouldOpenFeedbacks = params.openFeedback === 'true';
            this.updatePost.emit({ postId: params.postId, shouldOpenFeedbacks });
        });

        this.selectedFilter$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._socialPostsContext.resetPagination();
        });

        this.restaurant$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._socialPostsContext.init();
        });

        // debounceTime(0) is used to make sure the observable is triggered after the previous observables
        combineLatest([this.restaurant$, this.selectedFilter$, this.fetchNextPage$])
            .pipe(debounceTime(0), takeUntilDestroyed(this._destroyRef))
            .subscribe(([restaurant, selectedFilter]) => {
                this._socialPostsContext.fetchPosts(selectedFilter, restaurant.id, this.FETCH_POSTS_LIMIT);
            });

        combineLatest([this.restaurant$, this.fetchNextPageFeed$])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(([restaurant]) => {
                this._socialPostsContext.fetchFeed(restaurant.id, this.FETCH_FEED_LIMIT);
            });

        // Scroll into view when a post is clicked in the feed and found in the posts list
        this._socialPostsContext.foundPost$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((postId) => {
            // Wait for Angular to render the post in the DOM
            this._changeDetectorRef.detectChanges();
            document.getElementById(`post-${postId}`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        });

        this._checkIfShouldOpenDisconnectedPlatformsModal();
    }

    onScrollDown(): void {
        const hasNextPage = this._socialPostsContext.hasNextPage();
        if (hasNextPage) {
            this._socialPostsContext.goNextPage();
        }
    }

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }

    onUpdatePost(data: { postId: string; shouldOpenFeedbacks?: boolean }): void {
        const queryParams = { postId: data.postId, ...(data.shouldOpenFeedbacks ? { openFeedback: data.shouldOpenFeedbacks } : {}) };
        this._router.navigate(['.'], {
            relativeTo: this._activatedRoute,
            queryParams,
        });
    }

    onDeletePost(data: { postId: string; isPublishedOnFacebook: boolean }): void {
        this.deletePost.emit(data);
    }

    onRefreshPost(data: { postId: string }): void {
        this._socialPostsContext.refreshSocialPost(data.postId);
    }

    onDuplicatePost(data: { postId: string; destination: DuplicationDestination; postDestination: PostSource }): void {
        if (data.destination === DuplicationDestination.HERE) {
            const restaurantId = this._socialPostsContext.restaurant().id;
            if (data.postDestination === PostSource.SEO) {
                this.duplicateToSeoHere.emit({ postIds: [data.postId], postDestination: data.postDestination });
            } else {
                this._socialPostsContext.duplicatePosts({
                    postIds: [data.postId],
                    restaurantIds: [restaurantId],
                    postDestination: data.postDestination,
                });
            }
        } else {
            this.onDuplicateToOtherRestaurants({ postIds: [data.postId], postDestination: data.postDestination });
        }
    }

    onDuplicateToOtherRestaurants(data: { postIds: string[]; postDestination: PostSource }): void {
        this.duplicateToOtherRestaurants.emit(data);
    }

    onPostDateChange(data: { postId: string; date: Date }): void {
        this._socialPostsV2Service.updatePostDate$(data).subscribe({
            next: (res) => {
                const socialPost = UpsertSocialPost.fromDto(res.data);
                this._socialPostsContext.upsertSocialPost(SocialPostItem.fromIUpsertSocialPost(socialPost.toInterface()));
                this._socialPostsContext.shouldFetchFilterOptionsCount$.next();
                this._toastService.openSuccessToast(this._translateService.instant('social_post.update_date.success'));
            },
            error: (err) => {
                console.error(err);
                this._toastService.openErrorToast(this._translateService.instant('social_post.update_date.error'));
            },
        });
    }

    onFeedItemClicked(feedItem: FeedItem): void {
        if (feedItem?.published !== PostPublicationStatus.PUBLISHED) {
            this.onUpdatePost({ postId: feedItem.postId });
        } else {
            if (this.selectedFilter() === SocialPostsListFilter.ALL) {
                this._socialPostsContext.setFetchUntilPostIsFound(feedItem.postId);
                this._socialPostsContext.fetchPostsUntilPostIsFound(feedItem.postId);
            } else {
                this._socialPostsContext.setFetchUntilPostIsFound(feedItem.postId);
                this._socialPostsContext.selectedFilter.set(SocialPostsListFilter.ALL);
            }
        }
    }

    onSelect(post: SocialPostItem): void {
        this._socialPostsContext.postSelection.toggle(post);
    }

    isSelected = (post: SocialPostItem): boolean => this._socialPostsContext.postSelection.isSelected(post);

    isHighlighted = (post: SocialPostItem, highlightedPostIds: string[]): boolean => highlightedPostIds.includes(post.id);

    private _checkIfShouldOpenDisconnectedPlatformsModal(): void {
        this._socialPostsContext.disconnectedPlatforms$
            .pipe(
                filter((platforms) => {
                    const restaurant = this.restaurant();
                    return platforms.length > 0 && !!restaurant && this._canOpenPopin(restaurant);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((platforms) => {
                this._showDisconnectedPlatformsPopin(platforms);
            });
    }

    private _canOpenPopin(restaurant: Restaurant): boolean {
        try {
            const disconnectedPlatformsPopinLastOpenedAt = this._getDisconnectedPlatformsPopinLastOpenedAtFromLocalStorage();
            const lastOpenedAt = disconnectedPlatformsPopinLastOpenedAt[restaurant._id];
            return !lastOpenedAt || new Date().getTime() - new Date(lastOpenedAt).getTime() > 1 * TimeInMilliseconds.DAY;
        } catch {
            return true;
        }
    }

    private _showDisconnectedPlatformsPopin(platforms: Platform[]): void {
        const restaurant = this.restaurant();
        if (!restaurant) {
            return;
        }

        this._customDialogService
            .open<DisconnectedPlatformsModalComponent, DisconnectedPlatformsModalProps, DisconnectedPlatformsModalResult>(
                DisconnectedPlatformsModalComponent,
                {
                    width: '600px',
                    height: 'auto',
                    data: {
                        platforms,
                        restaurantId: restaurant._id,
                        type: DisconnectedPlatformsModalType.SOCIAL,
                    },
                }
            )
            .afterClosed()
            .subscribe((res) => {
                if (!res?.ignoreUpdateLocalStorage) {
                    this._updateDisconnectedPlatformsPopinLastOpenedAtInLocalStorage(restaurant);
                }
            });
    }

    private _getDisconnectedPlatformsPopinLastOpenedAtFromLocalStorage(): Record<string, string> {
        const disconnectedPlatformsPopinLastOpenedAtString =
            LocalStorage.getItem(LocalStorageKey.DISCONNECTED_SOCIAL_PLATFORMS_POPIN_LAST_OPENED_AT) ?? '{}';
        return JSON.parse(disconnectedPlatformsPopinLastOpenedAtString);
    }

    private _updateDisconnectedPlatformsPopinLastOpenedAtInLocalStorage(restaurant: Restaurant): void {
        const now = new Date();
        try {
            const disconnectedPlatformsPopinLastOpenedAt = this._getDisconnectedPlatformsPopinLastOpenedAtFromLocalStorage();
            disconnectedPlatformsPopinLastOpenedAt[restaurant._id] = now.toISOString();
            LocalStorage.setItem(
                LocalStorageKey.DISCONNECTED_SOCIAL_PLATFORMS_POPIN_LAST_OPENED_AT,
                JSON.stringify(disconnectedPlatformsPopinLastOpenedAt)
            );
        } catch (error) {
            console.error('Error updating disconnected platforms popin last opened at in local storage', error);
        }
    }
}
