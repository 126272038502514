import { z } from 'zod';

import { MalouComparisonPeriod, PlatformKey, ReviewAnalysisTag } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const getSegmentAnalysesChartDataBodyValidator = z
    .object({
        restaurantIds: z.array(objectIdValidator),
        startDate: z.string().datetime({ offset: true }).nullish(),
        endDate: z.string().datetime({ offset: true }).nullish(),
        keys: z.array(z.nativeEnum(PlatformKey)),
    })
    .transform((data) => ({
        restaurantIds: data.restaurantIds ?? [],
        startDate: data.startDate ? new Date(data.startDate) : null,
        endDate: data.endDate ? new Date(data.endDate) : null,
        keys: data.keys ?? [],
    }));

export type GetSegmentAnalysesChartDataBodyDto = z.infer<typeof getSegmentAnalysesChartDataBodyValidator>;

// ------------------------------------------------------------------------------------------

export const getSegmentAnalysesTopTopicsBodyValidator = z
    .object({
        restaurantId: objectIdValidator,
        startDate: z.string().datetime({ offset: true }).nullish(),
        endDate: z.string().datetime({ offset: true }).nullish(),
        keys: z.array(z.nativeEnum(PlatformKey)),
    })
    .transform((data) => ({
        restaurantId: data.restaurantId,
        startDate: data.startDate ? new Date(data.startDate) : null,
        endDate: data.endDate ? new Date(data.endDate) : null,
        keys: data.keys ?? [],
    }));

export type GetSegmentAnalysesTopTopicsBodyDto = z.infer<typeof getSegmentAnalysesTopTopicsBodyValidator>;

// ------------------------------------------------------------------------------------------

export const getSegmentAnalysisParentTopicInsightsByCategoryBodyValidator = z
    .object({
        restaurantIds: z.array(objectIdValidator),
        startDate: z.string().datetime({ offset: true }).nullish(),
        endDate: z.string().datetime({ offset: true }).nullish(),
        keys: z.array(z.nativeEnum(PlatformKey)),
        comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).nullish(),
    })
    .transform((data) => ({
        restaurantIds: data.restaurantIds ?? [],
        startDate: data.startDate ? new Date(data.startDate) : null,
        endDate: data.endDate ? new Date(data.endDate) : null,
        keys: data.keys ?? [],
        comparisonPeriod: data.comparisonPeriod ?? null,
    }));

export type GetSegmentAnalysisParentTopicInsightsByCategoryBodyDto = z.infer<
    typeof getSegmentAnalysisParentTopicInsightsByCategoryBodyValidator
>;

// -----------------------------------------------------

export const getTimeSeriesSegmentAnalysesByParentTopicIdParamsValidator = z
    .object({
        parent_topic_id: objectIdValidator,
    })
    .transform((data) => {
        return {
            segmentAnalysesParentTopicId: data.parent_topic_id,
        };
    });
export const getTimeSeriesSegmentAnalysesByParentTopicIdQueryValidator = z
    .object({
        start_date: z.string().datetime(),
        end_date: z.string().datetime(),
        keys: z.union([z.nativeEnum(PlatformKey).transform((key) => [key]), z.array(z.nativeEnum(PlatformKey))]),
    })
    .transform((data) => {
        return {
            startDate: data.start_date ? new Date(data.start_date) : undefined,
            endDate: data.end_date ? new Date(data.end_date) : undefined,
            keys: data.keys ?? [],
        };
    });

export type GetTimeSeriesSegmentAnalysesByParentTopicIdParamsDto = z.infer<
    typeof getTimeSeriesSegmentAnalysesByParentTopicIdParamsValidator
>;
export type GetTimeSeriesSegmentAnalysesByParentTopicIdQueryDto = z.infer<typeof getTimeSeriesSegmentAnalysesByParentTopicIdQueryValidator>;

// -----------------------------------------------------

export const getTimeSeriesSegmentAnalysesByCategoryParamsValidator = z.object({
    category: z.nativeEnum(ReviewAnalysisTag),
});
export const getTimeSeriesSegmentAnalysesByCategoryQueryValidator = z
    .object({
        restaurant_id: objectIdValidator,
        start_date: z.string().datetime(),
        end_date: z.string().datetime(),
        keys: z.union([z.nativeEnum(PlatformKey).transform((key) => [key]), z.array(z.nativeEnum(PlatformKey))]),
    })
    .transform((data) => {
        return {
            restaurantId: data.restaurant_id,
            startDate: data.start_date ? new Date(data.start_date) : undefined,
            endDate: data.end_date ? new Date(data.end_date) : undefined,
            keys: data.keys ?? [],
        };
    });

export type GetTimeSeriesSegmentAnalysesByCategoryParamsDto = z.infer<typeof getTimeSeriesSegmentAnalysesByCategoryParamsValidator>;
export type GetTimeSeriesSegmentAnalysesByCategoryQueryDto = z.infer<typeof getTimeSeriesSegmentAnalysesByCategoryQueryValidator>;
