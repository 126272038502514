import { Routes } from '@angular/router';

import { BoostersPdfComponent } from ':modules/statistics-pdf/boosters-pdf/boosters-pdf.component';
import { EReputationPdfComponent } from ':modules/statistics-pdf/e-reputation-pdf/e-reputation-pdf.component';
import { SeoPdfComponent } from ':modules/statistics-pdf/seo-pdf/seo-pdf.component';
import { SocialNetworkPdfComponent } from ':modules/statistics-pdf/social-network-pdf/social-network-pdf.component';
import { StatisticsPdfComponent } from ':modules/statistics-pdf/statistics-pdf.component';

export const STATISTICS_PDF_ROUTES: Routes = [
    {
        path: '',
        component: StatisticsPdfComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'seo',
            },
            {
                path: 'seo',
                component: SeoPdfComponent,
            },
            {
                path: 'seo/keywords',
                component: SeoPdfComponent,
            },
            {
                path: 'seo/impressions',
                component: SeoPdfComponent,
            },
            {
                path: 'e-reputation',
                component: EReputationPdfComponent,
            },
            {
                path: 'social-networks',
                component: SocialNetworkPdfComponent,
            },
            {
                path: 'boosters',
                component: BoostersPdfComponent,
            },
        ],
    },
];
