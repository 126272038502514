import { z } from 'zod';

import { urlValidator } from '../../utils';

const reviewValidator = z.object({
    picture: z.union([
        z.object({
            url: z.string(),
        }),
        z.object({
            initials: z.string(),
            color: z.string(),
        }),
    ]),
    userName: z.string(),
    publishedAt: z.string().optional(),
    starsCount: z.number(),
    platformKey: z.string(),
    content: z.string(),
});

export const storeLocatorStorePageReviewsBlockValidator = z.object({
    title: z.string(),
    reviews: z.array(reviewValidator),
    orderUrl: urlValidator(),
});
