import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { GmbInsightsContext } from ':modules/aggregated-statistics/seo/context/gmb-insights.context';
import { GmbActionsV2Component } from ':modules/aggregated-statistics/seo/gmb-actions-v2/gmb-actions-v2.component';
import { GmbImpressionsV2Component } from ':modules/aggregated-statistics/seo/gmb-impressions-v2/gmb-impressions-v2.component';
import { KeywordsV4Component } from ':modules/aggregated-statistics/seo/keywords-v4/keywords-v4.component';
import { KeywordsV3Component } from ':modules/aggregated-statistics/seo/keywords/keywords-v3.component';
import { TopKeywordSearchImpressionsComponent } from ':modules/aggregated-statistics/seo/top-keyword-search-impressions/top-keyword-search-impressions.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-seo-pdf',
    standalone: true,
    imports: [
        KeywordsV3Component,
        AsyncPipe,
        TranslateModule,
        FromToDateFormatterPipe,
        IncludesPipe,
        StatisticsPdfRestaurantsFormatterPipe,
        KeywordsV4Component,
        TopKeywordSearchImpressionsComponent,
        GmbImpressionsV2Component,
        GmbActionsV2Component,
    ],
    templateUrl: './seo-pdf.component.html',
    styleUrls: ['./seo-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoPdfComponent {
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _gmbInsightsContext = inject(GmbInsightsContext);
    private readonly _destroyRef = inject(DestroyRef);

    readonly startDate: Date;
    readonly endDate: Date;
    readonly displayedCharts: InsightsChart[];
    readonly chartOptions: ChartOptions;
    readonly InsightsChart = InsightsChart;
    readonly selectedRestaurantsTitle$: Observable<string>;

    readonly isAggregatedKeywordsInsightsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-aggregated-keywords-insights-v2'),
        {
            initialValue: false,
        }
    );

    readonly keywordsV4HasData: WritableSignal<boolean> = signal(true);
    keywordsHasData = true;
    SortBy = ChartSortBy;

    constructor(
        private readonly _aggregateStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams();
        const { dates, displayedCharts, chartOptions } = parsedQueryParams;

        this.displayedCharts = displayedCharts;
        this.chartOptions = chartOptions ?? {};
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.selectedRestaurantsTitle$ = this._aggregateStatisticsFiltersContext.selectedRestaurants$.pipe(
            map((restaurants) => restaurants.map((restaurant) => restaurant.internalName ?? restaurant.name).join(', '))
        );

        if (
            this.displayedCharts.includes(InsightsChart.AGGREGATED_ACTIONS) ||
            this.displayedCharts.includes(InsightsChart.AGGREGATED_APPARITIONS)
        ) {
            this._gmbInsightsContext.gmbAggregatedInsights$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
        }
    }
}
