import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import {
    ROUTER_LINK_ACTIVE_OPTIONS,
    SidenavContentRouteOptions,
} from ':modules/sidenav-router/sidenav-content/sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { SidenavContentRouteGroupComponent } from '../../sidenav-content-route-group/sidenav-content-route-group.component';

@Component({
    selector: 'app-sidenav-content-boosters-routes',
    templateUrl: './sidenav-content-boosters-routes.component.html',
    standalone: true,
    imports: [SidenavContentRouteGroupComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentBoostersRoutesComponent {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _router = inject(Router);

    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly SvgIcon = SvgIcon;

    private readonly _isFeatureBoostersV2Enabled = toSignal(this._experimentationService.isFeatureEnabled$('release-boosters-v2'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-boosters-v2'),
    });

    readonly isOnBoosterPresentationPage$ = this._router.events.pipe(
        filter((event) => event.type === EventType.NavigationEnd),
        map((event: NavigationEnd) => event.url.includes('boosters/presentation'))
    );
    readonly isOnBoosterPresentationPage = toSignal(this.isOnBoosterPresentationPage$, {
        initialValue: false,
    });

    private readonly _base = computed(() => ['/restaurants', this.selectedRestaurant()?._id ?? '']);
    private readonly _wheelOfFOrtuneRouterLink = computed(() => this._base().concat(['boosters', 'wheels-of-fortune']));
    private readonly _totemsRouterLink = computed(() => this._base().concat(['boosters', 'totems']));
    private readonly _presentationRouterLink = computed(() => this._base().concat(['boosters', 'presentation']));
    private readonly _boostersRouterLink = computed(() => this._base().concat(['boosters']));

    readonly options = computed((): SidenavContentRouteOptions => {
        const selectedRestaurant = this.selectedRestaurant();
        const boosterPackActivated = !!selectedRestaurant?.boosterPack?.activated;

        const isFeatureBoostersV2Enabled = this._isFeatureBoostersV2Enabled();

        return {
            text: this._translateService.instant('sidenav_content.boosters'),
            routerLink: isFeatureBoostersV2Enabled && !boosterPackActivated ? this._presentationRouterLink() : this._boostersRouterLink(),
            svgIcon: SvgIcon.ROCKET,
            secondarySvgIcon: !boosterPackActivated ? SvgIcon.CROWN : undefined,
            isActiveOverride: isFeatureBoostersV2Enabled && !boosterPackActivated && this.isOnBoosterPresentationPage(),
        };
    });

    readonly childrenOptions = computed(() => {
        const selectedRestaurant = this.selectedRestaurant();
        const boosterPackActivated = !!selectedRestaurant?.boosterPack?.activated;

        const isFeatureBoostersV2Enabled = this._isFeatureBoostersV2Enabled();

        return isFeatureBoostersV2Enabled && !boosterPackActivated
            ? []
            : [
                  {
                      text: this._translateService.instant('sidenav_content.wheel_of_fortune'),
                      routerLink: this._wheelOfFOrtuneRouterLink(),
                      svgIcon: SvgIcon.DOT,
                      isSmallSvgIcon: true,
                      shouldHideSvgIconOnOpenedSidenav: true,
                  },
                  {
                      text: this._translateService.instant('sidenav_content.totems'),
                      routerLink: this._totemsRouterLink(),
                      svgIcon: SvgIcon.DOT,
                      isSmallSvgIcon: true,
                      shouldHideSvgIconOnOpenedSidenav: true,
                  },
              ];
    });

    private readonly _selectedRestaurant$ = this._restaurantsService.restaurantSelected$;
    private readonly _selectedRestaurant = toSignal(this._selectedRestaurant$);

    private readonly _onNavigationEnd$ = this._router.events.pipe(filter((event) => event.type === EventType.NavigationEnd));
    private readonly _onNavigationEnd = toSignal(this._onNavigationEnd$);

    constructor() {
        this._initEffectRedirection();
    }

    private _initEffectRedirection(): void {
        effect(
            () => {
                const selectedRestaurant = this._selectedRestaurant();
                if (!selectedRestaurant) {
                    return;
                }
                const isFeatureBoostersV2Enabled = this._isFeatureBoostersV2Enabled();
                this._onNavigationEnd(); // Used to trigger this effect

                const wheelOfFortuneUrl = this._wheelOfFOrtuneRouterLink()?.join('/');
                const isWheelOfFortuneRouteActive = wheelOfFortuneUrl
                    ? this._router.isActive(wheelOfFortuneUrl, ROUTER_LINK_ACTIVE_OPTIONS)
                    : false;
                const shouldRedirectFromWheelOfFortune =
                    isFeatureBoostersV2Enabled && !selectedRestaurant.boosterPack?.activated && isWheelOfFortuneRouteActive;

                const totemsUrl = this._totemsRouterLink()?.join('/');
                const isTotemsRouteActive = totemsUrl ? this._router.isActive(totemsUrl, ROUTER_LINK_ACTIVE_OPTIONS) : false;
                const shouldRedirectFromTotems =
                    isFeatureBoostersV2Enabled && !selectedRestaurant.boosterPack?.activated && isTotemsRouteActive;

                const presentationUrl = this._presentationRouterLink()?.join('/');
                const isPresentationRouteActive = presentationUrl
                    ? this._router.isActive(presentationUrl, ROUTER_LINK_ACTIVE_OPTIONS)
                    : false;
                const shouldRedirectFromPresentation =
                    (!isFeatureBoostersV2Enabled || selectedRestaurant.boosterPack.activated) && isPresentationRouteActive;

                let redirectRouterLink: string[] | undefined;

                if (shouldRedirectFromWheelOfFortune || shouldRedirectFromTotems) {
                    redirectRouterLink = this._presentationRouterLink();
                } else if (shouldRedirectFromPresentation) {
                    redirectRouterLink = this._boostersRouterLink();
                }
                if (redirectRouterLink) {
                    this._router.navigate(redirectRouterLink);
                }
            },
            { allowSignalWrites: true }
        );
    }
}
