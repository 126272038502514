import { Clipboard } from '@angular/cdk/clipboard';
import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import lodash from 'lodash';
import { map } from 'rxjs/operators';

import { InformationUpdateDataDto, MergedInformationUpdateDto } from '@malou-io/package-dto';
import { getRegularHoursToUpdate, getSpecialHoursToUpdate, isNotNil, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { ToastService } from ':core/services/toast.service';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { Platform } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { BooleanTranslatePipe } from ':shared/pipes/boolean-translate.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

enum TableFieldName {
    FIELD_NAME = 'fieldName',
    PREVIOUS_VALUE = 'previousValue',
    CURRENT_VALUE = 'currentValue',
    ACTIONS = 'actions',
}

interface TableData {
    [TableFieldName.FIELD_NAME]: string;
    tooltip?: string;
    [TableFieldName.PREVIOUS_VALUE]: string;
    [TableFieldName.CURRENT_VALUE]: string;
}

export interface UpdatesComparisonModalComponentInput {
    restaurantId: string;
    mergedInformationUpdate: MergedInformationUpdateDto;
    platform?: Platform;
}

@Component({
    selector: 'app-updates-comparison-modal',
    templateUrl: './updates-comparison-modal.component.html',
    styleUrls: ['./updates-comparison-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        MatTableModule,
        MatTooltipModule,
        EnumTranslatePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    providers: [BooleanTranslatePipe],
})
export class UpdatesComparisonModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly dataSource = new MatTableDataSource<TableData>([]);

    readonly UpdatesComparisonTableFieldName = TableFieldName;
    readonly displayedColumns = Object.values(TableFieldName);

    readonly translatedKeys = this._translateService.instant('admin.update.updates_comparison.keys');

    translatedPlatformKeys: Record<string, string>;
    platformKey: PlatformKey;

    constructor(
        private readonly _clipboard: Clipboard,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _booleanTranslatePipe: BooleanTranslatePipe,
        private readonly _translateService: TranslateService,
        private readonly _platformsService: PlatformsService,
        private readonly _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: UpdatesComparisonModalComponentInput,
        private readonly _dialogRef: MatDialogRef<UpdatesComparisonModalComponent>
    ) {
        this.platformKey = data.platform?.key as PlatformKey;
        this.translatedPlatformKeys = this._translateService.instant(`admin.update.updates_comparison.platform_keys.${this.platformKey}`);
        const keysToCheck = Object.keys(data.mergedInformationUpdate.mergedData);
        const tableData: (TableData[] | null)[] = keysToCheck.map((key: keyof InformationUpdateDataDto) => {
            switch (key) {
                case 'categoryName':
                case 'shortDescription':
                case 'longDescription':
                case 'menuUrl':
                case 'name':
                case 'openingDate':
                case 'website':
                case 'isClosedTemporarily':
                    return this._mapPrimitiveToTableData(key, data.mergedInformationUpdate);
                case 'coverUrl':
                case 'logoUrl':
                    return this._mapPrimitiveToTableData(key, data.mergedInformationUpdate, 'link');
                case 'secondaryCategoriesNames':
                    return this._mapPrimitiveArrayToTableData(key, data.mergedInformationUpdate);
                case 'phone':
                    return this._mapPhoneToTableData(key, data.mergedInformationUpdate);
                case 'address':
                case 'latlng':
                    return this._mapStringRecordToTableData(key, data.mergedInformationUpdate);
                case 'regularHours':
                    return this._mapRegularHoursToTableData(data.mergedInformationUpdate);
                case 'specialHours':
                    return this._mapSpecialHoursToTableData(data.mergedInformationUpdate);
                case 'attributes':
                    return this._mapAttributesToTableData(data.mergedInformationUpdate);
                default:
                    return null;
            }
        });
        const filteredTableData = tableData.filter(isNotNil);
        const flattenedTableData = filteredTableData.reduce((acc, val) => acc.concat(val), []);
        this.dataSource.data = flattenedTableData;
    }

    close(): void {
        this._dialogRef.close();
    }

    copy(value: string): void {
        this._clipboard.copy(value || ' ');
        this._toastService.openSuccessToast(this._translateService.instant('common.copied_to_the_clipboard'));
    }

    openPlatformTab(): void {
        this._platformsService
            .getPlatformsForRestaurant(this.data.restaurantId)
            .pipe(map((apiResult) => apiResult.data))
            .subscribe((platforms) => {
                const platform = platforms.find((p) => p.key === this.data.mergedInformationUpdate.platformState.key);
                if (!platform) {
                    return;
                }

                const platformLink = PlatformDefinitions.getPlatformDefinition(platform.key)?.updateLink?.(platform.socialId);
                if (!platformLink) {
                    return;
                }
                window.open(platformLink, '_blank');
            });
    }

    private _mapPrimitiveKeyToTableKey(key: string): string {
        return this.translatedPlatformKeys[key] || this.translatedKeys[key];
    }

    private _mapPrimitiveToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto, tooltipKey?: string): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData[key];
        const previousValue = mergedInformationUpdate.previousData[key];

        return [
            {
                [TableFieldName.FIELD_NAME]: this._mapPrimitiveKeyToTableKey(key),
                tooltip: tooltipKey ? this._mapPrimitiveKeyToTableKey(tooltipKey) : undefined,
                [TableFieldName.PREVIOUS_VALUE]:
                    typeof previousValue === 'boolean' ? this._booleanTranslatePipe.transform(previousValue) : previousValue?.toString(),
                [TableFieldName.CURRENT_VALUE]:
                    typeof currentValue === 'boolean' ? this._booleanTranslatePipe.transform(currentValue) : currentValue?.toString(),
            },
        ];
    }

    private _mapPrimitiveArrayToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData[key];
        const previousValue = mergedInformationUpdate.previousData[key];

        return [
            {
                [TableFieldName.FIELD_NAME]: this._mapPrimitiveKeyToTableKey(key),
                [TableFieldName.PREVIOUS_VALUE]: previousValue?.join(', '),
                [TableFieldName.CURRENT_VALUE]: currentValue?.join(', '),
            },
        ];
    }

    private _mapPhoneToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData.phone;
        const previousValue = mergedInformationUpdate.previousData.phone;

        return [
            {
                [TableFieldName.FIELD_NAME]: this._mapPrimitiveKeyToTableKey(key),
                [TableFieldName.PREVIOUS_VALUE]: `${previousValue?.prefix ?? ''} ${previousValue?.digits ?? ''}`,
                [TableFieldName.CURRENT_VALUE]: `${currentValue?.prefix ?? ''} ${currentValue?.digits ?? ''}`,
            },
        ];
    }

    private _mapStringRecordToTableData(key: string, mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const currentValue = mergedInformationUpdate.mergedData[key];
        const previousValue = mergedInformationUpdate.previousData[key];

        return Object.keys(currentValue).map((objectKey) => ({
            [TableFieldName.FIELD_NAME]: this._mapPrimitiveKeyToTableKey(objectKey),
            [TableFieldName.PREVIOUS_VALUE]: previousValue[objectKey],
            [TableFieldName.CURRENT_VALUE]: currentValue[objectKey],
        }));
    }

    private _mapRegularHoursToTableData(mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        return getRegularHoursToUpdate({
            currentRegularHours: mergedInformationUpdate.mergedData.regularHours ?? [],
            previousRegularHours: mergedInformationUpdate.previousData.regularHours ?? [],
        }).map(({ day, isPreviousClosed, currentTimes, previousTimes, isCurrentClosed }) => ({
            [TableFieldName.FIELD_NAME]: this._enumTranslatePipe.transform(day, 'days'),
            [TableFieldName.PREVIOUS_VALUE]: isPreviousClosed ? this._translateService.instant('common.closed') : previousTimes,
            [TableFieldName.CURRENT_VALUE]: isCurrentClosed ? this._translateService.instant('common.closed') : currentTimes,
        }));
    }

    private _mapSpecialHoursToTableData(mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        return getSpecialHoursToUpdate({
            previousSpecialHours: mergedInformationUpdate.previousData.specialHours ?? [],
            currentSpecialHours: mergedInformationUpdate.mergedData.specialHours ?? [],
            platformKey: mergedInformationUpdate.platformState.key as PlatformKey,
        }).map(({ date, isPreviousClosed, currentTimes, previousTimes, isCurrentClosed }) => ({
            [TableFieldName.FIELD_NAME]: date,
            [TableFieldName.PREVIOUS_VALUE]: isPreviousClosed ? this._translateService.instant('common.closed') : previousTimes,
            [TableFieldName.CURRENT_VALUE]: isCurrentClosed ? this._translateService.instant('common.closed') : currentTimes,
        }));
    }

    private _mapAttributesToTableData(mergedInformationUpdate: MergedInformationUpdateDto): TableData[] {
        const previousValue = mergedInformationUpdate.previousData.attributes;
        const currentValue = mergedInformationUpdate.mergedData.attributes;

        const attributeIdsToCheck: string[] = lodash.uniq(currentValue ?? []).map((e) => e.name);

        return attributeIdsToCheck.map((attributeId) => {
            const previousAttribute = previousValue?.find((e) => e.name === attributeId) ?? undefined;
            const currentAttribute = currentValue?.find((e) => e.name === attributeId) ?? undefined;
            const attributeName = this._translateService.instant(`admin.update.attributes.${this.platformKey}.${attributeId}`);

            return {
                [TableFieldName.FIELD_NAME]: `${this.translatedKeys.attribute} ${attributeName}`,

                [TableFieldName.PREVIOUS_VALUE]: previousAttribute?.value
                    ? this._enumTranslatePipe.transform(previousAttribute.value, 'information_update_attribute_value')
                    : '',
                [TableFieldName.CURRENT_VALUE]: currentAttribute?.value
                    ? this._enumTranslatePipe.transform(currentAttribute.value, 'information_update_attribute_value')
                    : '',
            };
        });
    }
}
