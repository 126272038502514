import { isNumber, sum } from 'lodash';
import { DateTime, Interval } from 'luxon';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import {
    AggregationTimeScale,
    DayMonthYear,
    getMonthsFromPeriod,
    getWeeksFromPeriod,
    MalouComparisonPeriod,
    MalouMetric,
    Month,
    Week,
} from '@malou-io/package-utils';

import { ChartDataArray, ChartDataElement, mergeArrays } from ':shared/helpers';

export class GmbInsightsChartData {
    private _startDate: Date;
    private _endDate: Date;

    websiteClicks: ChartDataArray = [];
    phoneClicks: ChartDataArray = [];
    drivingClicks: ChartDataArray = [];
    bookingClicks: ChartDataArray = [];
    menuClicks: ChartDataArray = [];
    foodOrderClicks: ChartDataArray = [];

    impressionsDesktopMaps: ChartDataArray = [];
    impressionsDesktopSearch: ChartDataArray = [];
    impressionsMobileMaps: ChartDataArray = [];
    impressionsMobileSearch: ChartDataArray = [];

    impressionsSearch: ChartDataArray = [];
    impressionsMaps: ChartDataArray = [];

    totalImpressionsMaps: ChartDataElement;
    totalImpressionsSearch: ChartDataElement;

    totalImpressions: number;
    totalActions: number;
    ratioActionsOverImpressions: number | null;

    dates: Date[];
    aggregationTimeScale: AggregationTimeScale;
    comparisonPeriod?: MalouComparisonPeriod;

    constructor({
        data,
        startDate,
        endDate,
        aggregationTimeScale,
        comparisonPeriod,
    }: {
        data: DailyPlatformInsights['insights'];
        startDate: DayMonthYear;
        endDate: DayMonthYear;
        aggregationTimeScale: AggregationTimeScale;
        comparisonPeriod?: MalouComparisonPeriod;
    }) {
        this._startDate = DateTime.fromObject(startDate).toJSDate();
        this._endDate = DateTime.fromObject(endDate).toJSDate();
        this.aggregationTimeScale = aggregationTimeScale;
        this.comparisonPeriod = comparisonPeriod;
        this._initInsightsAndDates(data);

        this.impressionsSearch = mergeArrays(this.impressionsDesktopSearch, this.impressionsMobileSearch);
        this.impressionsMaps = mergeArrays(this.impressionsDesktopMaps, this.impressionsMobileMaps);

        this.totalImpressionsSearch = sum(this.impressionsSearch);
        this.totalImpressionsMaps = sum(this.impressionsMaps);

        this.totalActions = this._getTotalActions();
        this.totalImpressions = this._getTotalImpressions();

        this.ratioActionsOverImpressions = this.totalImpressions === 0 ? null : (this.totalActions / this.totalImpressions) * 100;
    }

    private _getTotalActions(): number {
        return [this.websiteClicks, this.phoneClicks, this.drivingClicks, this.bookingClicks, this.menuClicks, this.foodOrderClicks]
            .map(sum)
            .reduce((acc: number, curr) => (isNumber(curr) ? acc + curr : acc + 0), 0) as number;
    }

    private _getTotalImpressions(): number {
        return [this.impressionsDesktopMaps, this.impressionsDesktopSearch, this.impressionsMobileMaps, this.impressionsMobileSearch]
            .map(sum)
            .reduce((acc: number, curr) => (isNumber(curr) ? acc + curr : acc + 0), 0) as number;
    }

    private _initInsightsAndDates(data: DailyPlatformInsights['insights']): void {
        switch (this.aggregationTimeScale) {
            case AggregationTimeScale.BY_MONTH:
                const months = getMonthsFromPeriod(this._startDate, this._endDate);
                this.dates = months.map((month) => month.start);
                this._initInsightsAggregatedByMonth(data, months);
                break;

            case AggregationTimeScale.BY_WEEK:
                const weeks = getWeeksFromPeriod(this._startDate, this._endDate);
                this.dates = weeks.map((week) => week.start);
                this._initInsightsAggregatedByWeek(data, weeks);
                break;

            case AggregationTimeScale.BY_DAY:
            default:
                const days = this._getDaysFromPeriod(this._startDate, this._endDate);
                this.dates = days;
                this._initInsightsAggregatedByDay(data, days);
                break;
        }
    }

    private _sumDailyInsights(data: DailyPlatformInsights['insights'], days: Date[], metric: MalouMetric): ChartDataElement {
        return days.map((day) => data![metric]?.[this._formatDateToISO(day)] ?? 0).reduce((acc, curr) => acc + curr, 0);
    }

    private _initInsightsAggregatedByDay(data: DailyPlatformInsights['insights'], days: Date[]): void {
        days.forEach((day) => {
            const formattedDay = this._formatDateToISO(day);
            this.websiteClicks.push(data![MalouMetric.ACTIONS_WEBSITE]?.[formattedDay] ?? 0);
            this.phoneClicks.push(data![MalouMetric.ACTIONS_PHONE]?.[formattedDay] ?? 0);
            this.drivingClicks.push(data![MalouMetric.ACTIONS_DRIVING_DIRECTIONS]?.[formattedDay] ?? 0);
            this.bookingClicks.push(data![MalouMetric.ACTIONS_BOOKING_CLICK]?.[formattedDay] ?? 0);
            this.menuClicks.push(data![MalouMetric.ACTIONS_MENU_CLICK]?.[formattedDay] ?? 0);
            this.foodOrderClicks.push(data![MalouMetric.BUSINESS_FOOD_ORDERS]?.[formattedDay] ?? 0);
            this.impressionsDesktopMaps.push(data![MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS]?.[formattedDay] ?? 0);
            this.impressionsDesktopSearch.push(data![MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH]?.[formattedDay] ?? 0);
            this.impressionsMobileMaps.push(data![MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS]?.[formattedDay] ?? 0);
            this.impressionsMobileSearch.push(data![MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH]?.[formattedDay] ?? 0);
        });
    }

    private _initInsightsAggregatedByWeek(data: DailyPlatformInsights['insights'], weeks: Week[]): void {
        this._initInsightsAggregatedByPeriod(data, weeks);
    }

    private _initInsightsAggregatedByMonth(data: DailyPlatformInsights['insights'], months: Month[]): void {
        this._initInsightsAggregatedByPeriod(data, months);
    }

    private _initInsightsAggregatedByPeriod(data: DailyPlatformInsights['insights'], periods: { days: Date[] }[]): void {
        periods.forEach((period) => {
            const days = period.days;
            this.websiteClicks.push(this._sumDailyInsights(data, days, MalouMetric.ACTIONS_WEBSITE));
            this.phoneClicks.push(this._sumDailyInsights(data, days, MalouMetric.ACTIONS_PHONE));
            this.drivingClicks.push(this._sumDailyInsights(data, days, MalouMetric.ACTIONS_DRIVING_DIRECTIONS));
            this.bookingClicks.push(this._sumDailyInsights(data, days, MalouMetric.ACTIONS_BOOKING_CLICK));
            this.menuClicks.push(this._sumDailyInsights(data, days, MalouMetric.ACTIONS_MENU_CLICK));
            this.foodOrderClicks.push(this._sumDailyInsights(data, days, MalouMetric.BUSINESS_FOOD_ORDERS));
            this.impressionsDesktopMaps.push(this._sumDailyInsights(data, days, MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS));
            this.impressionsDesktopSearch.push(this._sumDailyInsights(data, days, MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH));
            this.impressionsMobileMaps.push(this._sumDailyInsights(data, days, MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS));
            this.impressionsMobileSearch.push(this._sumDailyInsights(data, days, MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH));
        });
    }

    private _formatDateToISO(date: Date): string {
        return DateTime.fromJSDate(date).toISODate();
    }

    private _getDaysFromPeriod(startDate: Date, endDate: Date): Date[] {
        const intervals = Interval.fromDateTimes(DateTime.fromJSDate(startDate).startOf('day'), DateTime.fromJSDate(endDate).endOf('day'))
            .splitBy({ day: 1 })
            .map((d) => d.start.toJSDate());
        return intervals;
    }
}
