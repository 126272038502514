import { z } from 'zod';

import {
    AspectRatio,
    FeedbackMessageParticipantType,
    FeedbackMessageType,
    FeedbackMessageVisibility,
    MapstrCtaButtonType,
    MediaType,
    PlatformKey,
    PostPublicationStatus,
    PostType,
    Role,
    TiktokPrivacyStatus,
} from '@malou-io/package-utils';

import { objectIdValidator, urlValidator } from '../utils/validators';
import { postHashtagsValidator } from './post-hashtags.dto';

export const postLocationValidator = z.object({
    id: z.string(),
    name: z.string(),
    link: urlValidator(),
    location: z
        .object({
            city: z.string().nullish(),
            country: z.string().nullish(),
            latitude: z.number().nullish(),
            longitude: z.number().nullish(),
            street: z.string().nullish(),
            zip: z.string().nullish(),
        })
        .nullish(),
});

export const feedbackParticipantValidator = z.object({
    id: z.string(),
    name: z.string(),
    lastname: z.string().nullish(),
    email: z.string().email(),
    role: z.nativeEnum(Role).nullish(),
    userId: objectIdValidator.nullish(),
});

export const feedbackAuthorValidator = z.object({
    id: z.string(),
    name: z.string(),
    lastname: z.string(),
    email: z.string().email(),
    role: z.nativeEnum(Role),
    userId: objectIdValidator.nullish(),
    profilePictureUrl: z.string().nullish(),
});

export const feedbackMessageValidator = z.object({
    id: z.string(),
    author: feedbackAuthorValidator,
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    text: z.string().optional(),
    type: z.nativeEnum(FeedbackMessageType),
    visibility: z.nativeEnum(FeedbackMessageVisibility),
});

export const postFeedbacksValidator = z.object({
    id: z.string(),
    isOpen: z.boolean(),
    participants: z.array(
        z.object({
            participant: feedbackParticipantValidator,
            types: z.array(z.nativeEnum(FeedbackMessageParticipantType)),
        })
    ),
    feedbackMessages: z.array(feedbackMessageValidator),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
});

export const postMediaBaseValidator = z.object({
    id: objectIdValidator,
    thumbnail1024OutsideUrl: z.string(),
    thumbnail256OutsideUrl: z.string(),
    thumbnail256OutsideDimensions: z.object({ width: z.number(), height: z.number() }).optional(),
    thumbnail1024OutsideDimensions: z.object({ width: z.number(), height: z.number() }).optional(),
    aspectRatio: z.number(),
    transformData: z.object({
        aspectRatio: z.nativeEnum(AspectRatio),
        rotationInDegrees: z.number(),
        left: z.number(),
        top: z.number(),
        width: z.number(),
        height: z.number(),
    }),
    aiDescription: z.string().nullish(),
});

/**
 * Many of the following fields are ignored by the route that updates posts. They
 * are here only because the type SocialPostDto is infered from this validator.
 */
export const postMediaValidator = z.union([
    z
        .object({
            type: z.literal(MediaType.PHOTO),
        })
        .merge(postMediaBaseValidator),
    z
        .object({
            type: z.literal(MediaType.VIDEO),
            videoUrl: z.string(),
            videoDimensions: z.object({ width: z.number(), height: z.number() }),

            // ignored when updating a post
            duration: z.number().optional(),
        })
        .merge(postMediaBaseValidator),
]);

export const reelThumbnailValidator = z
    .union([
        z.object({ type: z.literal('custom'), media: postMediaValidator }),
        z.object({ type: z.literal('videoFrame'), thumbnailOffsetTimeInMs: z.number() }),
    ])
    .optional();
export type ReelThumbnail = z.infer<typeof reelThumbnailValidator>;

/**
 * Many of the following fields are ignored by the route that updates posts. They
 * are here only because the type SocialPostDto is infered from this validator.
 */
export const updateSocialPostBodyValidator = z
    .object({
        id: objectIdValidator,
        title: z.string().nullish(),
        text: z.string().nullish(),
        platformKeys: z.array(z.nativeEnum(PlatformKey)),
        published: z.nativeEnum(PostPublicationStatus),
        isPublishing: z.boolean().optional(),
        postType: z.nativeEnum(PostType),
        location: postLocationValidator.nullish(),
        callToAction: z
            .object({
                actionType: z.nativeEnum(MapstrCtaButtonType),
                url: urlValidator({ allowEmpty: true }),
            })
            .nullish(),
        error: z
            .object({
                code: z.string(),
                rawData: z.string(),
            })
            .nullish(),
        socialLink: urlValidator().nullish(),
        socialCreatedAt: z.string().datetime().nullish(),
        feedbacks: postFeedbacksValidator.nullish(),
        plannedPublicationDate: z.string().datetime(),
        hashtags: postHashtagsValidator,
        attachments: z.array(postMediaValidator),
        author: z
            .object({
                id: objectIdValidator,
                name: z.string().optional(),
                lastname: z.string().optional(),
                picture: z.string().optional(),
            })
            .nullish(),
        userTagsList: z.array(z.array(z.object({ x: z.number(), y: z.number(), username: z.string() })).or(z.null())),
        bindingId: z.string().optional(),
        tiktokOptions: z.object({
            privacyStatus: z.nativeEnum(TiktokPrivacyStatus),
            interactionAbility: z.object({
                comment: z.boolean(),
                duet: z.boolean(),
                stitch: z.boolean(),
            }),
            contentDisclosureSettings: z.object({
                isActivated: z.boolean(),
                yourBrand: z.boolean(),
                brandedContent: z.boolean(),
            }),
        }),
        reelThumbnail: reelThumbnailValidator,
    })
    .transform((data) => ({
        ...data,
        text: data.text ?? '',
        isPublishing: data.isPublishing ?? false,
    }));

export type SocialPostDto = z.infer<typeof updateSocialPostBodyValidator>;
