import { LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { capitalize, groupBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import {
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    EMPTY,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    startWith,
    switchMap,
    tap,
} from 'rxjs';

import {
    GetSegmentAnalysesTopTopicsBodyDto,
    GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto,
    SegmentAnalysisParentTopicInsights,
} from '@malou-io/package-dto';
import {
    ApplicationLanguage,
    isNotNil,
    PlatformFilterPage,
    PlatformKey,
    ReviewAnalysisSentiment,
    ReviewAnalysisTag,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SegmentAnalysesService } from ':core/services/segment-analyses.service';
import { SegmentAnalysisParentTopicsService } from ':core/services/segment-analysis-parent-topics.service';
import { LocalStorage } from ':core/storage/local-storage';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import {
    TabIndex,
    TopicSegmentAnalysisModalComponent,
} from ':shared/components/review-analyses-v2/topic-segment-analysis-modal/topic-segment-analysis-modal.component';
import { SearchBarDisplayStyle, SearchComponent } from ':shared/components/search/search.component';
import { SelectBaseDisplayStyle } from ':shared/components/select-abstract/select-base.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { AreaChartComponent } from ':shared/components/semantic-analysis-topic-evolution/area-chart/area-chart.component';
import {
    TimeSeriesSegmentAnalysis,
    TimeSeriesSegmentAnalysisWithRange,
} from ':shared/components/semantic-analysis-topic-evolution/semantic-analysis-topic-evolution.interface';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { NavBarTab, TabNavBarComponent, TabNavBarDisplayStyle } from ':shared/components/tab-nav-bar/tab-nav-bar.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    createDate,
    getDateOfISOWeek,
    getDaysYearRange,
    getMonthsYearRange,
    getWeekAndYearNumber,
    isDateSetOrGenericPeriod,
} from ':shared/helpers';
import { DatesAndPeriod, DayYear, MonthYear, WeekDayMonthYear, WeekYear } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const ANALYSIS_CATEGORIES_SORTED: ReviewAnalysisTag[] = [
    ReviewAnalysisTag.FOOD,
    ReviewAnalysisTag.SERVICE,
    ReviewAnalysisTag.ATMOSPHERE,
    ReviewAnalysisTag.PRICE,
    ReviewAnalysisTag.HYGIENE,
];

enum TopicSortBy {
    ASC = 'ASC',
    DESC = 'DESC',
}

type SegmentAnalysisParentTopicInsightsWithTranslation = SegmentAnalysisParentTopicInsights & {
    displayedNameInCurrentLang: string;
};

interface SelectedTopic {
    name: string;
    translations: Record<ApplicationLanguage, string>;
    parentTopicId: string;
    isMain: boolean;
}

type SentimentCountKey = 'positiveCount' | 'negativeCount';

interface TimeSeriesSegmentAnalysisWithCategory {
    category: ReviewAnalysisTag;
    timeSeries: TimeSeriesSegmentAnalysisWithRange;
}

interface PdfBuiltTab {
    title: string;
    data: ReviewAnalysisTag;
    category: ReviewAnalysisTag;
    sentiment: ReviewAnalysisSentiment;
    topics: SegmentAnalysisParentTopicInsightsWithTranslation[];
    sortedTopics: SegmentAnalysisParentTopicInsightsWithTranslation[];
    currentMainTopic: SegmentAnalysisParentTopicInsightsWithTranslation | null;
    positiveChartData: number[];
    negativeChartData: number[];
    dateLabels: Date[];
}

@Component({
    selector: 'app-semantic-analysis-topic-evolution',
    standalone: true,
    imports: [
        NgClass,
        MatIconModule,
        MatTooltipModule,
        NgTemplateOutlet,
        TranslateModule,
        AreaChartComponent,
        NumberEvolutionComponent,
        SearchComponent,
        SelectComponent,
        SkeletonComponent,
        TabNavBarComponent,
        IllustrationPathResolverPipe,
        EnumTranslatePipe,
        ApplyPurePipe,
        LowerCasePipe,
    ],
    templateUrl: './semantic-analysis-topic-evolution.component.html',
    styleUrl: './semantic-analysis-topic-evolution.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SemanticAnalysisTopicEvolutionComponent implements OnInit {
    readonly segmentAnalysesTopicInsights = input.required<GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto>();
    readonly segmentAnalysesTopicFilter = input.required<GetSegmentAnalysesTopTopicsBodyDto | null>();
    readonly defaultSelectedCategory = input.required<ReviewAnalysisTag | null>();
    readonly viewBy = input<ViewBy>(ViewBy.WEEK);
    readonly isPdfDownload = input<boolean>(false);
    readonly favoriteStatusChanged = output<{ id: string; isFavorite: boolean }>();
    readonly viewByChange = output<ViewBy>();

    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _segmentAnalysesParentTopicService = inject(SegmentAnalysisParentTopicsService);
    private readonly _segmentAnalysesService = inject(SegmentAnalysesService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);

    readonly Illustration = Illustration;
    readonly SvgIcon = SvgIcon;
    readonly ReviewAnalysisSentiment = ReviewAnalysisSentiment;
    readonly TopicSortBy = TopicSortBy;
    readonly SearchBarDisplayStyle = SearchBarDisplayStyle;
    readonly TabNavBarDisplayStyle = TabNavBarDisplayStyle;
    readonly SelectBaseDisplayStyle = SelectBaseDisplayStyle;

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.WEEK) as FormControl<ViewBy>;
    readonly chartViewBy: WritableSignal<ViewBy> = signal(this.viewBy());

    readonly SHORT_TEXT_LENGTH = 17;
    readonly DEFAULT_SELECTED_TOPIC = {
        name: '',
        isMain: false,
        translations: {
            [ApplicationLanguage.FR]: '',
            [ApplicationLanguage.EN]: '',
            [ApplicationLanguage.ES]: '',
            [ApplicationLanguage.IT]: '',
        },
        parentTopicId: '',
    };
    readonly restaurant = this._restaurantsService.selectedRestaurant;
    readonly statisticsFilters$: Observable<StatisticsState['filters']> = this._store.select(StatisticsSelectors.selectFilters);

    readonly timeSeriesSegmentAnalyses = signal<TimeSeriesSegmentAnalysisWithRange | null>(null);
    readonly positiveChartData = signal<number[]>([]);
    readonly negativeChartData = signal<number[]>([]);
    readonly labels = signal<Date[]>([]);

    readonly httpError: WritableSignal<any> = signal(null);
    readonly noResults: WritableSignal<boolean> = signal(false);
    readonly isLoading: WritableSignal<boolean> = signal(false);

    readonly tabs = computed<NavBarTab[]>(() =>
        Object.entries(this.segmentAnalysesTopicInsights())
            .filter(([_, { total }]) => total > 0)
            .filter(([key]) => ANALYSIS_CATEGORIES_SORTED.includes(key as ReviewAnalysisTag))
            .map(([key]) => ({
                title: this._enumTranslatePipe.transform(key, 'review_analysis_tags'),
                subtitle: '',
                data: key,
            }))
            .sort(
                (a, b) =>
                    ANALYSIS_CATEGORIES_SORTED.indexOf(a.data as ReviewAnalysisTag) -
                    ANALYSIS_CATEGORIES_SORTED.indexOf(b.data as ReviewAnalysisTag)
            )
    );

    readonly pdfChartData = signal<TimeSeriesSegmentAnalysisWithCategory[] | null>(null);
    readonly currentSentiment: WritableSignal<ReviewAnalysisSentiment> = signal(ReviewAnalysisSentiment.NEGATIVE);
    readonly currentSentiment$ = toObservable(this.currentSentiment);

    readonly builtTabsForPdf = computed<PdfBuiltTab[]>(() => {
        if (!this.isPdfDownload()) {
            return [];
        }
        return this.tabs().map((tab) => {
            const associatedTimeSeriesSegmentAnalyses = this.pdfChartData()?.find(({ category }) => category === tab.data)?.timeSeries;
            const result = associatedTimeSeriesSegmentAnalyses
                ? this._getChartDataAndLabelsByView(associatedTimeSeriesSegmentAnalyses, this.chartViewBy())
                : { positiveChartData: [], negativeChartData: [], dateLabels: [] };

            const sentiment = this.currentSentiment();
            const sentimentCountKey: SentimentCountKey = this.currentSentimentCountKey();
            const topics = this._getTopicsForCategory(tab.data, sentimentCountKey);
            return {
                title: this._enumTranslatePipe.transform(tab.data, 'review_analysis_tags'),
                data: tab.data,
                category: tab.data,
                sentiment,
                topics,
                sortedTopics: this._filterAndSortTopics(topics, sentiment),
                currentMainTopic: this._getCurrentMainTopic(topics, tab.data),
                positiveChartData: result?.positiveChartData,
                negativeChartData: result?.negativeChartData,
                dateLabels: result?.dateLabels,
            };
        });
    });

    readonly currentSentimentCountKey = computed<SentimentCountKey>(() =>
        this.currentSentiment() === ReviewAnalysisSentiment.POSITIVE ? 'positiveCount' : 'negativeCount'
    );

    readonly searchText: WritableSignal<string> = signal('');

    readonly isCurrentMainTopicMatchingSearch = computed<boolean>(
        () => !this.searchText() || (this.currentMainTopic()?.name.toLowerCase().includes(this.searchText().toLowerCase()) ?? false)
    );

    readonly selectedTopic: WritableSignal<SelectedTopic> = signal(this.DEFAULT_SELECTED_TOPIC);
    readonly selectedTopic$ = toObservable(this.selectedTopic);
    readonly selectedTopicName: Signal<string> = computed(() => this.selectedTopic().name);
    readonly selectedSortBy: WritableSignal<TopicSortBy> = signal(TopicSortBy.DESC);

    readonly currentLang = signal(LocalStorage.getLang());
    readonly currentCategory: WritableSignal<ReviewAnalysisTag | null> = signal(null);
    readonly currentCategory$ = toObservable(this.currentCategory);
    readonly defaultSelectedTab = computed<NavBarTab | null>(() => this.tabs()?.find((tab) => tab.data === this.currentCategory()) ?? null);

    readonly currentCategoryTopics: Signal<SegmentAnalysisParentTopicInsightsWithTranslation[]> = computed(() => {
        const currentCategory = this.currentCategory();
        if (!currentCategory) {
            return [];
        }
        return this._getTopicsForCategory(currentCategory, this.currentSentimentCountKey());
    });

    readonly filteredAndSortedCurrentCategoryTopics = computed<SegmentAnalysisParentTopicInsightsWithTranslation[]>(() =>
        this._filterAndSortTopics(this.currentCategoryTopics(), this.currentSentiment())
    );

    readonly currentMainTopic: Signal<SegmentAnalysisParentTopicInsightsWithTranslation | null> = computed(() => {
        const allTopics = this.currentCategoryTopics();
        const currentCategory = this.currentCategory();
        if (!allTopics?.length || !currentCategory) {
            return null;
        }

        return this._getCurrentMainTopic(allTopics, currentCategory);
    });

    readonly hasData = computed<boolean>(
        () => !!Object.values(this.segmentAnalysesTopicInsights())?.flat()?.length && !!this.tabs()?.length
    );

    readonly chartTopicFilter = computed(() => {
        const segmentAnalysesTopicFilter = this.segmentAnalysesTopicFilter();
        if (!segmentAnalysesTopicFilter) {
            return null;
        }
        return {
            topicName: this.selectedTopicName(),
            category: this.currentCategory(),
            isMain: this.selectedTopic().isMain,
            topicTranslations: this.selectedTopic().translations,
            restaurantId: segmentAnalysesTopicFilter.restaurantId,
            keys: segmentAnalysesTopicFilter.keys,
        };
    });

    constructor() {
        effect(
            () => {
                if (!this.currentCategory() && this.tabs()?.length) {
                    this.onTabChange(this.tabs()[0]);
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                const defaultSelectedCategory = this.defaultSelectedCategory();
                const associatedDefaultTab = this.tabs()?.find((tab) => tab.data === defaultSelectedCategory);
                if (associatedDefaultTab) {
                    this.onTabChange(associatedDefaultTab);
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                const viewBy = this.viewBy();
                this.chartViewBy.set(viewBy);
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        combineLatest([
            this.statisticsFilters$,
            this.selectedTopic$.pipe(distinctUntilChanged((prev, curr) => prev.parentTopicId === curr.parentTopicId)),
        ])
            .pipe(
                filter(
                    ([statisticsFilters, _]: [StatisticsState['filters'], SelectedTopic]) =>
                        isDateSetOrGenericPeriod(statisticsFilters.dates) &&
                        statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION].length > 0 &&
                        statisticsFilters.isFiltersLoaded
                ),
                map(([statisticsFilters, selectedTopic]: [StatisticsState['filters'], SelectedTopic]) => [
                    statisticsFilters.dates,
                    statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION],
                    selectedTopic,
                ]),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, keys, selectedTopic]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        SelectedTopic,
                    ]): Observable<TimeSeriesSegmentAnalysisWithRange | null> => {
                        const { startDate, endDate } = dates;
                        const restaurant = this.restaurant();
                        const currentCategory = this.currentCategory();
                        const isCategory = !!selectedTopic.isMain && !!currentCategory;

                        if (!selectedTopic?.parentTopicId) {
                            return of(null);
                        }

                        return restaurant && isCategory
                            ? this._segmentAnalysesService
                                  .getTimeSeriesSegmentTopicsByCategory(currentCategory, {
                                      restaurantId: restaurant.id,
                                      keys,
                                      startDate,
                                      endDate,
                                  })
                                  .pipe(
                                      catchError((error) => {
                                          this.httpError.set(error);
                                          this.isLoading.set(false);
                                          return EMPTY;
                                      })
                                  )
                            : this._segmentAnalysesService
                                  .getTimeSeriesSegmentTopicsByParentTopicId(selectedTopic.parentTopicId, { keys, startDate, endDate })
                                  .pipe(
                                      catchError((error) => {
                                          this.httpError.set(error);
                                          this.isLoading.set(false);
                                          return EMPTY;
                                      })
                                  );
                    }
                ),
                map((timeSeriesSegmentAnalyses: TimeSeriesSegmentAnalysisWithRange) => {
                    if (!timeSeriesSegmentAnalyses || timeSeriesSegmentAnalyses?.results?.dataPerWeek?.length === 0) {
                        this.isLoading.set(false);
                        this.noResults.set(true);
                        return null;
                    }
                    return timeSeriesSegmentAnalyses;
                }),
                filter((dataOrNull: TimeSeriesSegmentAnalysisWithRange | null) => isNotNil(dataOrNull)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((timeSeriesSegmentAnalyses: TimeSeriesSegmentAnalysisWithRange) => {
                this.timeSeriesSegmentAnalyses.set(timeSeriesSegmentAnalyses);
                const viewBy = this.viewByControl.value;
                try {
                    this._computeCurrentData(timeSeriesSegmentAnalyses, viewBy);
                } catch (error) {
                    console.error(error);
                }

                this.isLoading.set(false);
            });

        if (this.isPdfDownload()) {
            this._getChartDataForAllCategories();
        }
        combineLatest([this.currentSentiment$, this.currentCategory$])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                const selectedTopic = this.selectedTopic();
                const currentCategoryTopicsForNewSentiment = this.currentCategoryTopics();
                const doesTopicExistWithNewSentiment = currentCategoryTopicsForNewSentiment.find(
                    (topic) => topic.parentTopicId === selectedTopic.parentTopicId
                );
                if (!doesTopicExistWithNewSentiment) {
                    const currentMainTopic = this.currentMainTopic();
                    this.updateSelectedTopic(currentMainTopic, true);
                }
            });

        this.viewByControl.valueChanges.pipe(startWith(ViewBy.WEEK), takeUntilDestroyed(this._destroyRef)).subscribe((viewBy: ViewBy) => {
            this.chartViewBy.set(viewBy);
            this.viewByChange.emit(viewBy);
            const timeSeriesSegmentAnalyses = this.timeSeriesSegmentAnalyses();
            if (!timeSeriesSegmentAnalyses) {
                return;
            }
            this._computeCurrentData(timeSeriesSegmentAnalyses, viewBy);
        });
    }

    onSearchValueChange(searchValue: string): void {
        this.searchText.set(searchValue);
    }

    onTabChange(tab: NavBarTab): void {
        this.currentCategory.set(tab.data);
        this._setSelectedTopicFromMainTopic(this.currentMainTopic());
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslatePipe.transform(option, 'view_by');

    getIdSuffixFn = (option: ViewBy): string => option.toLowerCase() + '_click';

    openTopicReviewsModal(topic: SegmentAnalysisParentTopicInsightsWithTranslation, isMainTopic: boolean): void {
        const segmentAnalysesTopicFilter = this.segmentAnalysesTopicFilter();
        if (!segmentAnalysesTopicFilter) {
            return;
        }
        this._customDialogService.open(TopicSegmentAnalysisModalComponent, {
            width: '80vw',
            height: '80vh',
            disableClose: true,
            data: {
                topic: isMainTopic ? this.currentCategory() : topic.name,
                topicTranslations: topic.translations,
                reviewAnalysesFilter: {
                    ...segmentAnalysesTopicFilter,
                    restaurantIds: [segmentAnalysesTopicFilter.restaurantId],
                },
                isFromAggregatedStatistics: false,
                tabIndex: this.currentSentiment() === ReviewAnalysisSentiment.POSITIVE ? TabIndex.POSITIVE : TabIndex.NEGATIVE,
                isMainCategory: isMainTopic,
                shouldShowSubcategories: true,
            },
        });
    }

    updateSelectedTopic(topic: SegmentAnalysisParentTopicInsightsWithTranslation | null, isMain: boolean): void {
        if (!topic) {
            this.selectedTopic.set(this.DEFAULT_SELECTED_TOPIC);
            return;
        }
        this.selectedTopic.set({
            name: topic.name,
            isMain,
            translations: {
                [ApplicationLanguage.FR]: topic.translations[ApplicationLanguage.FR],
                [ApplicationLanguage.EN]: topic.translations[ApplicationLanguage.EN],
                [ApplicationLanguage.ES]: topic.translations[ApplicationLanguage.ES],
                [ApplicationLanguage.IT]: topic.translations[ApplicationLanguage.IT],
            },
            parentTopicId: topic.parentTopicId,
        });
    }

    updateSentiment(sentiment: ReviewAnalysisSentiment): void {
        this.currentSentiment.set(sentiment);
    }

    toggleFavoriteStatus(topic: SegmentAnalysisParentTopicInsightsWithTranslation): void {
        const newFavoriteStatus = !topic.isFavorite;
        this._segmentAnalysesParentTopicService.update(topic.parentTopicId, { isFavorite: newFavoriteStatus }).subscribe({
            next: () => {
                this.favoriteStatusChanged.emit({ id: topic.parentTopicId, isFavorite: newFavoriteStatus });
            },
        });
    }

    toggleSelectedSortBy(): void {
        const newSortBy = this.selectedSortBy() === TopicSortBy.ASC ? TopicSortBy.DESC : TopicSortBy.ASC;
        this.selectedSortBy.set(newSortBy);
    }

    private _getChartDataForAllCategories(): void {
        this.statisticsFilters$
            .pipe(
                filter(
                    (statisticsFilters: StatisticsState['filters']) =>
                        isDateSetOrGenericPeriod(statisticsFilters.dates) &&
                        statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION].length > 0 &&
                        statisticsFilters.isFiltersLoaded
                ),
                map((statisticsFilters: StatisticsState['filters']) => [
                    statisticsFilters.dates,
                    statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION],
                ]),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([dates, keys]: [DatesAndPeriod, PlatformKey[]]): Observable<TimeSeriesSegmentAnalysisWithCategory[]> => {
                    const { startDate, endDate } = dates;
                    const restaurant = this.restaurant();
                    if (!restaurant) {
                        return EMPTY;
                    }

                    return forkJoin(
                        this.builtTabsForPdf().map((tab) =>
                            forkJoin({
                                category: of(tab.category),
                                timeSeries: this._segmentAnalysesService
                                    .getTimeSeriesSegmentTopicsByCategory(tab.category, {
                                        restaurantId: restaurant.id,
                                        keys,
                                        startDate,
                                        endDate,
                                    })
                                    .pipe(
                                        catchError((error) => {
                                            this.httpError.set(error);
                                            this.isLoading.set(false);
                                            return EMPTY;
                                        })
                                    ),
                            })
                        )
                    );
                }),
                filter((dataOrNull: TimeSeriesSegmentAnalysisWithCategory[] | null) => isNotNil(dataOrNull)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((timeSeriesSegmentAnalysesArray: TimeSeriesSegmentAnalysisWithCategory[]) => {
                this.pdfChartData.set(timeSeriesSegmentAnalysesArray);
            });
    }

    private _getTopicsForCategory(
        category: ReviewAnalysisTag,
        sentimentKey: SentimentCountKey
    ): SegmentAnalysisParentTopicInsightsWithTranslation[] {
        const parentTopics = this.segmentAnalysesTopicInsights()[category]?.parentTopics ?? [];
        return parentTopics
            .filter((topic) => topic.isFavorite || !!this.searchText()?.length || topic[sentimentKey] > 0)
            .map((topic) => ({
                ...topic,
                displayedNameInCurrentLang: capitalize(topic.translations[this.currentLang()]),
            }));
    }

    private _getCurrentMainTopic(
        allTopics: SegmentAnalysisParentTopicInsightsWithTranslation[],
        currentCategory: ReviewAnalysisTag
    ): SegmentAnalysisParentTopicInsightsWithTranslation | null {
        const translation = this._enumTranslatePipe.transform(currentCategory, 'review_analysis_tags');
        return {
            parentTopicId: currentCategory,
            name: translation,
            translations: {
                [ApplicationLanguage.FR]: translation,
                [ApplicationLanguage.EN]: translation,
                [ApplicationLanguage.ES]: translation,
                [ApplicationLanguage.IT]: translation,
            },
            displayedNameInCurrentLang: capitalize(translation),
            isNew: false,
            isFavorite: false,
            positiveCount: sumBy(allTopics, 'positiveCount'),
            negativeCount: sumBy(allTopics, 'negativeCount'),
            positiveCountEvolution: sumBy(allTopics, 'positiveCountEvolution'),
            negativeCountEvolution: sumBy(allTopics, 'negativeCountEvolution'),
        };
    }

    private _filterAndSortTopics(
        topics: SegmentAnalysisParentTopicInsightsWithTranslation[],
        sentiment: ReviewAnalysisSentiment
    ): SegmentAnalysisParentTopicInsightsWithTranslation[] {
        return topics
            .filter((topic) => !this.searchText() || topic.name.toLowerCase().includes(this.searchText().toLowerCase()))
            .sort((topicA, topicB) => {
                if (topicA.isFavorite && !topicB.isFavorite) {
                    return -1;
                }
                if (!topicA.isFavorite && topicB.isFavorite) {
                    return 1;
                }
                const currentSentimentCountA = sentiment === ReviewAnalysisSentiment.POSITIVE ? topicA.positiveCount : topicA.negativeCount;
                const currentSentimentCountB = sentiment === ReviewAnalysisSentiment.POSITIVE ? topicB.positiveCount : topicB.negativeCount;
                if (currentSentimentCountA > currentSentimentCountB) {
                    return this.selectedSortBy() === TopicSortBy.ASC ? 1 : -1;
                }
                if (currentSentimentCountA < currentSentimentCountB) {
                    return this.selectedSortBy() === TopicSortBy.ASC ? -1 : 1;
                }
                return this.selectedSortBy() === TopicSortBy.ASC
                    ? topicA.name.localeCompare(topicB.name)
                    : topicB.name.localeCompare(topicA.name);
            });
    }

    private _setSelectedTopicFromMainTopic(mainTopic: SegmentAnalysisParentTopicInsightsWithTranslation | null): void {
        if (mainTopic) {
            this.selectedTopic.set({
                name: mainTopic.name,
                isMain: true,
                translations: {
                    [ApplicationLanguage.FR]: mainTopic.displayedNameInCurrentLang,
                    [ApplicationLanguage.EN]: mainTopic.displayedNameInCurrentLang,
                    [ApplicationLanguage.ES]: mainTopic.displayedNameInCurrentLang,
                    [ApplicationLanguage.IT]: mainTopic.displayedNameInCurrentLang,
                },
                parentTopicId: mainTopic.parentTopicId,
            });
        }
    }

    private _computeCurrentData(timeSeriesSegmentAnalyses: TimeSeriesSegmentAnalysisWithRange, viewBy: ViewBy): void {
        const { positiveChartData, negativeChartData, dateLabels } = this._getChartDataAndLabelsByView(timeSeriesSegmentAnalyses, viewBy);
        this.positiveChartData.set(positiveChartData);
        this.negativeChartData.set(negativeChartData);
        this.labels.set(dateLabels);
    }

    private _getChartDataAndLabelsByView(
        timeSeriesSegmentAnalyses: TimeSeriesSegmentAnalysisWithRange,
        viewBy: ViewBy
    ): { positiveChartData: number[]; negativeChartData: number[]; dateLabels: Date[] } {
        const {
            results: { dataPerDay, dataPerWeek, dataPerMonth },
            startDate,
            endDate,
        } = timeSeriesSegmentAnalyses;
        const start = createDate(startDate);
        const end = createDate(endDate);
        switch (viewBy) {
            case ViewBy.DAY:
                const days: DayYear[] = getDaysYearRange(start, end);
                const filledDataPerDay = this._fillDataForRange<TimeSeriesSegmentAnalysis, DayYear>(dataPerDay, days, viewBy);
                return {
                    positiveChartData: filledDataPerDay.map((e) => e.positiveCount),
                    negativeChartData: filledDataPerDay.map((e) => e.negativeCount),
                    dateLabels: this._getDayYearDateLabels(days),
                };
            case ViewBy.WEEK:
                const weekYears: WeekYear[] = this._getWeekYears(start, end);
                const filledDataPerWeek = this._fillDataForRange<TimeSeriesSegmentAnalysis, WeekYear>(dataPerWeek, weekYears, viewBy);
                return {
                    positiveChartData: filledDataPerWeek.map((e) => e.positiveCount),
                    negativeChartData: filledDataPerWeek.map((e) => e.negativeCount),
                    dateLabels: this._getWeekYearDateLabels(weekYears),
                };
            case ViewBy.MONTH:
                const months: MonthYear[] = getMonthsYearRange(start, end);
                const filledDataPerMonth = this._fillDataForRange<TimeSeriesSegmentAnalysis, MonthYear>(dataPerMonth, months, viewBy);
                return {
                    positiveChartData: filledDataPerMonth.map((e) => e.positiveCount),
                    negativeChartData: filledDataPerMonth.map((e) => e.negativeCount),
                    dateLabels: this._getMonthYearDateLabels(months),
                };
            default:
                return {
                    positiveChartData: [],
                    negativeChartData: [],
                    dateLabels: [],
                };
        }
    }

    private _fillDataForRange<T extends TimeSeriesSegmentAnalysis, U extends WeekDayMonthYear>(
        timeSeriesSegmentAnalyses: T[],
        timeRanges: U[],
        viewBy: ViewBy
    ): { positiveCount: number; negativeCount: number }[] {
        const viewByKey = viewBy.toLowerCase();
        const data = groupBy(timeSeriesSegmentAnalyses, (timeSeriesSegmentAnalysis) => timeSeriesSegmentAnalysis.getKey());
        return timeRanges.map((timeRange) => {
            const key = [timeRange.year, timeRange[viewByKey]].join('-');
            const timeSeriesSegmentAnalysis = data[key]?.[0];
            return (
                timeSeriesSegmentAnalysis ?? {
                    positiveCount: 0,
                    negativeCount: 0,
                }
            );
        });
    }

    private _getWeekYears(start: Date, end: Date): WeekYear[] {
        const startDateWeekYear = getWeekAndYearNumber(start);
        const endDateWeekYear = getWeekAndYearNumber(end);
        const weekYears: WeekYear[] = this._getWeeksRange(startDateWeekYear, endDateWeekYear);
        return weekYears;
    }

    private _getWeeksRange(start: WeekYear, end: WeekYear): WeekYear[] {
        const weeks: WeekYear[] = [];
        let currentWeek = start.week;
        let currentYear = start.year;
        while (currentYear < end.year || (currentYear === end.year && currentWeek <= end.week)) {
            weeks.push({ week: currentWeek, year: currentYear });
            const weeksInWeekYear = this._getYearWeekNumber(currentYear);
            currentWeek = currentWeek === weeksInWeekYear ? 1 : currentWeek + 1;
            currentYear = currentWeek === 1 ? currentYear + 1 : currentYear;
        }
        return weeks;
    }

    /**
     * Return the number of weeks in a year
     * @param year
     * @return number 52 ou 53
     */
    private _getYearWeekNumber(year: number): number {
        // We set the month to 2 (= february) to avoid the january edge case
        // when week number is equal to 52 (or 53) in early january
        // see https://en.wikipedia.org/wiki/ISO_week_date
        return DateTime.utc(year, 2).weeksInWeekYear;
    }

    private _getWeekYearDateLabels(weekYears: WeekYear[]): Date[] {
        return weekYears.map((e) => getDateOfISOWeek(e.week, e.year));
    }

    private _getDayYearDateLabels(days: DayYear[]): Date[] {
        return days.map((e) => new Date(e.year, 0, e.day));
    }

    private _getMonthYearDateLabels(months: MonthYear[]): Date[] {
        return months.map((e) => new Date(e.year, e.month - 1, 1));
    }

    private _reset(): void {
        this.httpError.set(null);
        this.isLoading.set(true);
        this.noResults.set(false);
    }
}
