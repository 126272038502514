import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, output, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MediaDimensionDto, MediaTransformDataDto } from '@malou-io/package-dto';
import { MediaType } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CreateArrayPipe } from ':shared/pipes/create-array.pipe';

interface LocalMedia {
    id: string;
    url: string;
    dimensions?: MediaDimensionDto;
    transformData?: MediaTransformDataDto;
    type: MediaType;
}

@Component({
    selector: 'app-media-thumbnail-list',
    templateUrl: './media-thumbnail-list.component.html',
    styleUrl: './media-thumbnail-list.component.scss',
    standalone: true,
    imports: [MatIconModule, NgClass, NgTemplateOutlet, CdkDrag, CdkDropList, CreateArrayPipe, MalouSpinnerComponent, ImageViewerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaThumbnailListComponent {
    readonly medias = input.required<LocalMedia[]>();
    readonly uploadingMediaCount = input<number>(0);
    readonly showEditMediaButton = input<boolean>(false);
    readonly noEditAndDragAndDrop = input<boolean>(false);
    readonly isReadonly = input<boolean>(false);
    readonly mediaClicked = output<string>();
    readonly editMedia = output<string>();
    readonly removeMedia = output<string>();
    readonly dropMedia = output<{ previousIndex: number; currentIndex: number }>();
    readonly mediaAdded = output<{ id: string }>();

    readonly scrollableDiv = viewChild<ElementRef<HTMLElement>>('scrollableDiv');
    readonly fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');

    readonly SvgIcon = SvgIcon;
    readonly MediaType = MediaType;

    readonly atLeftStop = signal<boolean>(true);
    readonly atRightStop = signal<boolean>(false);

    private readonly _scrollableDivObserver: ResizeObserver;

    constructor() {
        this._scrollableDivObserver = new ResizeObserver(() => {
            this.updateStopBoolean();
        });

        effect(() => {
            const scrollableDiv = this.scrollableDiv();
            if (scrollableDiv) {
                this._scrollableDivObserver.disconnect();
                this._scrollableDivObserver.observe(scrollableDiv.nativeElement);
            }
        });
    }

    onChevronLeftClick(): void {
        this._scroll(-200);
    }

    onChevronRightClick(): void {
        this._scroll(200);
    }

    onScroll(): void {
        this.updateStopBoolean();
    }

    updateStopBoolean(): void {
        const scrollable = this.scrollableDiv()?.nativeElement;
        if (!scrollable) {
            return;
        }
        this.atLeftStop.set(scrollable.scrollLeft === 0);
        const maxScrollLeft = scrollable.scrollWidth - scrollable.clientWidth;
        this.atRightStop.set(scrollable.scrollLeft >= maxScrollLeft);
    }

    onRemoveMedia(mediaId: string): void {
        this.removeMedia.emit(mediaId);
    }

    onEditMedia(mediaId: string): void {
        this.editMedia.emit(mediaId);
    }

    onDropMedia(event: { previousIndex: number; currentIndex: number }): void {
        this.dropMedia.emit(event);
    }

    private _scroll(increment: number): void {
        const scrollableDiv = this.scrollableDiv();
        if (!scrollableDiv) {
            return;
        }
        scrollableDiv.nativeElement.scrollTo({ left: scrollableDiv.nativeElement.scrollLeft + increment, behavior: 'smooth' });
    }
}
