<div class="malou-dialog malou-dialog-mobile">
    <div class="malou-dialog__header">
        <span>
            {{
                keywordSearchImpressionsType() === KeywordSearchImpressionsType.DISCOVERY
                    ? ('statistics.seo.keyword_search_impressions.discovery_keywords' | translate)
                    : ('statistics.seo.keyword_search_impressions.branding_keywords' | translate)
            }}
        </span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="cancel()"></mat-icon>
    </div>
    <div class="malou-dialog__body hide-scrollbar mb-4 overflow-auto">
        <div class="flex flex-col gap-4">
            @for (keyword of topKeywordSearchImpressions()[keywordSearchImpressionsType()]; track $index) {
                <div class="malou-simple-card flex flex-1 justify-between px-6 py-4">
                    <div class="flex items-center gap-4">
                        <div class="w-[22px]">
                            <img class="w-full" [src]="getEmojiFromPosition | applyPure: $index" />
                        </div>
                        <p class="malou-text-13 malou-color-text-2">
                            {{ keyword.keywordSearch }}
                        </p>
                    </div>
                    <p class="malou-text-13--bold malou-color-text-1">
                        @if (keyword.value === MIN_KEYWORD_SEARCH_IMPRESSIONS_VALUE) {
                            <span>&#60; </span>
                        }
                        {{ keyword.value }}
                    </p>
                </div>
            }
        </div>
    </div>
</div>
