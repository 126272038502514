import { BehaviorSubject } from 'rxjs';

import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { Platform } from ':shared/models';

export interface UpsertSocialPostModalProps {
    postId?: string;
    shouldOpenFeedbacks?: boolean;
    date?: Date;
    isReel?: boolean;
    disconnectedPlatforms$: BehaviorSubject<Platform[]>;
}

export interface UpsertSocialPostModalResult {
    post: SocialPostItem | null;
    scrollToPostId?: string;
    duplicateToSeo: boolean;
}

export enum SubmitPublicationStatus {
    NOW = 'now',
    SCHEDULE = 'schedule',
    DRAFT = 'draft',
}
