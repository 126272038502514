import { Component, DestroyRef, effect, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';

import { MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { ReviewsAverageRatingWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewsKpisCardComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-card/reviews-kpis-card.component';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-reviews-kpis-average-rating',
    templateUrl: './reviews-kpis-average-rating.component.html',
    styleUrls: ['./reviews-kpis-average-rating.component.scss'],
    standalone: true,
    imports: [ReviewsKpisCardComponent, MatIconModule, MatTooltipModule, TranslateModule, ShortNumberPipe],
})
export class ReviewsKpisAverageRatingComponent implements OnInit {
    filters$ = input.required<Observable<[DatesAndPeriod, PlatformKey[], Restaurant, MalouComparisonPeriod]>>();
    hasDataChange = output<boolean>();
    isLoadingEvent = output<boolean>();

    private readonly _reviewsService = inject(ReviewsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;

    readonly currentAverageRating: WritableSignal<number | undefined> = signal(undefined);
    readonly previousAverageRating: WritableSignal<number | undefined> = signal(undefined);
    readonly diffAverageRating: WritableSignal<number | undefined> = signal(undefined);
    readonly comparisonPeriodKey: WritableSignal<string> = signal('');

    readonly httpError: WritableSignal<Error | undefined> = signal(undefined);
    readonly isLoading = signal(true);

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this.filters$()
            .pipe(
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant, comparisonPeriod]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        Restaurant,
                        MalouComparisonPeriod,
                    ]): Observable<[ReviewsAverageRatingWithRange, ReviewsAverageRatingWithRange]> => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;
                        this.comparisonPeriodKey.set(this._getComparisonPeriodKey(comparisonPeriod));
                        return forkJoin([
                            this._reviewsService
                                .getRestaurantsReviewsAverageChartData({ restaurantId, platforms, startDate, endDate })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                            this._reviewsService
                                .getRestaurantsReviewsAverageChartData({
                                    restaurantId,
                                    platforms,
                                    startDate,
                                    endDate,
                                    comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
                                })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                        ]);
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([currentReviewsAverageRating, previousReviewsAverageRating]: [
                    ReviewsAverageRatingWithRange,
                    ReviewsAverageRatingWithRange,
                ]) => {
                    this.currentAverageRating.set(this._computeCurrentAverageRating(currentReviewsAverageRating));
                    this.diffAverageRating.set(this._computeDiffAverageRating(this.currentAverageRating(), previousReviewsAverageRating));
                    this.previousAverageRating.set(this._computeCurrentAverageRating(previousReviewsAverageRating));
                    this.isLoading.set(false);
                }
            );
    }

    private _computeCurrentAverageRating(currentReviewsAverageRating: ReviewsAverageRatingWithRange): number | undefined {
        return currentReviewsAverageRating?.results?.[0]?.averageRating;
    }

    private _computeDiffAverageRating(
        currentAverageRating: number | undefined,
        previousReviewsAverageRating: ReviewsAverageRatingWithRange
    ): number | undefined {
        const previousAverageRating = previousReviewsAverageRating?.results?.[0]?.averageRating;
        if (currentAverageRating && previousAverageRating) {
            return currentAverageRating - previousAverageRating;
        }
    }

    private _reset(): void {
        this.httpError.set(undefined);
        this.isLoading.set(true);
    }

    private _handleHttpError(error: Error): Observable<never> {
        this.httpError.set(error);
        this.hasDataChange.emit(false);
        this.isLoading.set(false);
        return EMPTY;
    }

    private _getComparisonPeriodKey(comparisonPeriod: MalouComparisonPeriod): string {
        return `date_filter.comparison_period.${comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD}`;
    }
}
