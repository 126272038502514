import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, map, switchMap, take, tap } from 'rxjs';

import { MediaType, PlatformDefinitions, PlatformKey, PostPublicationStatus } from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { updateRefreshDates } from ':modules/posts/posts.actions';
import { selectRefreshDates } from ':modules/posts/posts.selectors';
import { SocialPostsService } from ':modules/social-posts/social-posts.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { shouldRefreshPost } from ':shared/helpers/should-refresh-post';
import { showFeedbackNotification } from ':shared/helpers/show-feedback-notification';
import { PostWithJob, Restaurant, SocialPost } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

@Component({
    selector: 'app-social-post',
    templateUrl: './social-post.component.html',
    styleUrls: ['./social-post.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        SocialPostMediaComponent,
        AsyncPipe,
        ApplyPurePipe,
        ApplySelfPurePipe,
        IncludesPipe,
        PlatformLogoComponent,
        ShortTextPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostComponent implements OnInit {
    readonly post = input.required<PostWithJob>();
    readonly isChecked = input.required<boolean>();

    readonly duplicate = output<{ to: string; post: PostWithJob; platformsKeys: PlatformKey[]; inBackground: boolean }>();
    readonly editPostEvent = output<string>();
    readonly deletePostEvent = output<string>();
    readonly postChecked = output<boolean>();
    readonly postEdited = output<PostWithJob>();
    readonly refreshPostData = output<Partial<PostWithJob>>();

    private readonly _postsService = inject(PostsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _socialPostsService = inject(SocialPostsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;
    readonly PublicationStatus = PostPublicationStatus;
    readonly ExtendedPostPublicationStatus = ExtendedPostPublicationStatus;
    readonly DuplicationDestination = DuplicationDestination;
    readonly PlatformKey = PlatformKey;

    restaurant: Restaurant;
    socialPost = computed(() => new SocialPost(this.post()));
    postBorder: string;
    isForLater: boolean;
    isRemovable: boolean;
    userInfos: User | null;

    readonly seeMore = signal(false);
    readonly showMovedBorder = signal(false);
    readonly MediaType = MediaType;

    readonly platformKeysWithSeoPost = PlatformDefinitions.getSeoPlatformKeysWithDuplicablePosts();

    // META bug : reels with copyrighted audio don't have media_url
    // https://developers.facebook.com/support/bugs/404495981650858/
    readonly isInstagramReelWithoutVideo = computed(() => this.post().isInstagramReelWithoutVideo());

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly isPostInError = computed(() =>
        [ExtendedPostPublicationStatus.REJECTED, ExtendedPostPublicationStatus.ERROR].includes(this.post().postStatus.type)
    );

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$.subscribe((rest) => (this.restaurant = rest ?? this.restaurant));

        this._store.select(selectUserInfos).subscribe((infos) => {
            this.userInfos = infos;
        });
        this._postsService.refreshIfPostLacksSocialAttachments(this.socialPost());

        this._onHighlightedPosts();

        this.initPostFrame();
    }

    get showFeedbackBadge(): boolean {
        if (!showFeedbackNotification(this.post(), this.userInfos?.role)) {
            return false;
        }
        return !!this.post().feedback?.isOpen;
    }

    get errorMessage(): string | null | undefined {
        const clarifyError = this.socialPost()?.clarifyError;
        if (clarifyError) {
            return clarifyError(this.post().errorData || this.post().job?.failReason || 'unknown_error', this._translateService);
        }
    }

    toggleSeeMore = (): void => this.seeMore.update((currentSeeMore) => !currentSeeMore);

    platformKeysToShow(): PlatformKey[] {
        if (this.post().key) {
            return [this.post().key];
        }
        return this.post().keys?.sort((a, b) => b.localeCompare(a));
    }

    onPostCheckedChange(event: MatCheckboxChange): void {
        this.postChecked.emit(event.checked);
    }

    hasVideoInSelection = (): boolean => (this.post().attachments ?? []).some((a) => a.type === 'video');

    canOpenPostSocialLink(post: SocialPost): boolean {
        return !!post.socialLink && post.postStatus.type === ExtendedPostPublicationStatus.PUBLISHED;
    }

    getPostSocialPlatformKeys(): PlatformKey[] {
        return this.post().key
            ? [this.post().key]
            : this.post().keys.filter((key) => PlatformDefinitions.getSocialPlatformKeys().includes(key));
    }

    initPostFrame(): void {
        this.isForLater = !!this.post().job && !this.post().job?.lastRunAt;
        this.isRemovable = this.post().published !== PostPublicationStatus.PUBLISHED || this.post().key !== PlatformKey.INSTAGRAM;
    }

    openSocialLink(): void {
        if (this.post().socialLink) {
            window.open(this.post().socialLink, '_blank');
        }
    }

    deletePost(): void {
        this.deletePostEvent.emit(this.post().id);
    }

    editPost(): void {
        this.editPostEvent.emit(this.post().id);
    }

    duplicatePost(to: string, platformsKeys: PlatformKey[] = [], inBackground = false): void {
        this.duplicate.emit({ to, post: this.post(), platformsKeys, inBackground });
    }

    refreshPost(): void {
        this._store
            .select(selectRefreshDates)
            .pipe(
                take(1),
                map((dates) => dates[this.post().id]),
                switchMap((date) => {
                    if (shouldRefreshPost(date)) {
                        return this._postsService.refresh(this.post().id).pipe(
                            map((res) => res.data),
                            tap((p) => {
                                this.refreshPostData.emit(new PostWithJob(p));
                                this._store.dispatch(updateRefreshDates({ postId: this.post().id }));
                            })
                        );
                    }
                    return EMPTY;
                })
            )
            .subscribe({
                next: () => {
                    this.initPostFrame();
                },
                error: (err) => console.error('fail silently :>> ', err),
            });
    }

    private _onHighlightedPosts(): void {
        this._socialPostsService.highlightedPostIds$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((postIds: string[]) => {
            const isPostHighlighted = postIds.includes(this.post().id);
            this.showMovedBorder.set(isPostHighlighted);
        });
    }
}
