<div class="flex h-full flex-col" [id]="CREATE_SOCIAL_POST_MODAL_HTML_ID">
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>

    <div class="flex min-h-0 grow">
        <div class="flex min-w-0 grow flex-col">
            <div class="flex min-h-0 grow">
                <app-duplicate-and-select-platforms
                    class="w-[270px] shrink-0"
                    [isReadonly]="isReadonly()"
                    [isReel]="isReel()"></app-duplicate-and-select-platforms>

                <app-social-post-content-form class="min-w-0 grow" [isReadonly]="isReadonly()"></app-social-post-content-form>
            </div>

            <app-upsert-social-post-modal-footer
                [isSubmitting]="isSubmitting()"
                [isDisabled]="isLoadingPost()"
                (cancel)="onCancel()"
                (savePost)="onSavePost($event)"></app-upsert-social-post-modal-footer>
        </div>

        <app-previews-feed-notes
            class="w-[400px] shrink-0"
            [isReadonly]="isReadonly()"
            [shouldOpenFeedbacks]="shouldOpenFeedbacks()"></app-previews-feed-notes>
    </div>
</div>

<ng-template #headerTemplate>
    <div class="flex items-center justify-between border-b border-malou-color-border-primary px-6 py-5">
        <div class="malou-text-18--semibold text-malou-color-text-1">
            @if (isReel()) {
                @if (isTiktokEnabled()) {
                    {{
                        postId()
                            ? ('social_posts.upsert_social_post_modal.title.update_reel_tiktok' | translate)
                            : ('social_posts.upsert_social_post_modal.title.create_reel_tiktok' | translate)
                    }}
                } @else {
                    {{
                        postId()
                            ? ('social_posts.upsert_social_post_modal.title.update_reel' | translate)
                            : ('social_posts.upsert_social_post_modal.title.create_reel' | translate)
                    }}
                }
            } @else {
                {{
                    postId()
                        ? ('social_posts.upsert_social_post_modal.title.update' | translate)
                        : ('social_posts.upsert_social_post_modal.title.create' | translate)
                }}
            }
        </div>

        <button
            class="malou-btn-icon !rounded-full !bg-malou-color-background-dark"
            mat-icon-button
            [disabled]="isReadonly()"
            (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
</ng-template>
