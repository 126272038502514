import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { KeywordSearchImpressionInsights } from ':modules/aggregated-statistics/seo/models/top-keyword-search-impressions.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-search-impressions-modal',
    standalone: true,
    imports: [TranslateModule, MatIconModule, ApplyPurePipe, NumberEvolutionComponent, ShortNumberPipe],
    templateUrl: './search-impressions-modal.component.html',
    styleUrl: './search-impressions-modal.component.scss',
    providers: [EmojiPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchImpressionsModalComponent {
    readonly data: KeywordSearchImpressionInsights = inject(MAT_DIALOG_DATA);
    readonly _dialogRef: MatDialogRef<SearchImpressionsModalComponent> = inject(MatDialogRef);
    readonly _emojiPathResolverPipe: EmojiPathResolverPipe = inject(EmojiPathResolverPipe);

    readonly SvgIcon = SvgIcon;

    cancel(): void {
        this._dialogRef.close();
    }

    getEmojiFromPosition = (position: number): string =>
        [
            this._emojiPathResolverPipe.transform(Emoji.TROPHY),
            this._emojiPathResolverPipe.transform(Emoji.SECOND_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.THIRD_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE),
        ][position] ?? this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE);
}
