<div class="relative h-[350px]">
    <canvas
        baseChart
        [datasets]="chartDataSets()"
        [labels]="chartLabels()"
        [options]="chartOptions"
        [type]="CHART_TYPE"
        [plugins]="[LABEL_CLICK_PLUGIN]">
    </canvas>
</div>
