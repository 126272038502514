<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [displayWith]="displayWith"
    [maxSelectableValues]="maxSelectableValues()"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [showSelectAllCheckbox]="multiSelection()"
    [selectAllCheckboxMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.all_totems' | translate"
    [showValuesSelectedCount]="showValuesSelectedCount()"
    [valuesSelectedCountMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.totems' | translate"
    [groupSelectedValuesAtTop]="true"
    [multiSelectionElementWrap]="multiSelectionElementWrap()"
    [shouldSwitchToWrapModeOnClick]="shouldSwitchToWrapModeOnClick()"
    [defaultEmptyValueMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem' | translate"
    [shouldUpdateValuesToDisplayAfterSelection]="shouldUpdateValuesToDisplayAfterSelection()"
    [sortBy]="sortBy"
    [selectedValues]="selectedValues()"
    [disabled]="disabled()"
    [computeObjectHash]="computeObjectHash()"
    [displayedOptionCount]="displayedOptionCount()"
    [valuesCanBeDisabled]="valuesCanBeDisabled()"
    [testId]="testId()"
    (selectBaseChange)="selectNfcChange.emit($event)">
    <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
        <div class="malou-chip malou-chip--primary">
            @if (isSticker | applyPure: value) {
                <span>{{ 'enums.nfc_type.sticker' | translate }} - {{ getRestaurantName | applyPure: value }}</span>
            } @else {
                <span>{{ displayWith | applyPure: value }}</span>
            }
            <mat-icon
                class="malou-chip-icon--right malou-color-primary"
                [svgIcon]="SvgIcon.REMOVE"
                (click)="deleteValueAt(index)"></mat-icon>
        </div>
    </ng-template>
    <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
        <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">
            @if (isSticker | applyPure: value) {
                {{ 'enums.nfc_type.sticker' | translate }} - {{ getRestaurantName | applyPure: value }}
            } @else {
                {{ displayWith | applyPure: value }}
            }
        </div>
    </ng-template>
</app-select-base>
