import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { catchError, debounceTime, filter, of, switchMap } from 'rxjs';

import { IGAccount, PlatformKey } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

@Component({
    selector: 'app-tag-users-with-input',
    standalone: true,
    templateUrl: './tag-users-with-input.component.html',
    styleUrls: ['./tag-users-with-input.component.scss'],
    imports: [
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        SelectBaseComponent,
        ApplyPurePipe,
        IllustrationPathResolverPipe,
        ShortTextPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagUsersWithInputComponent {
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _postsService = inject(PostsService);

    readonly userTagsHistory = this._upsertSocialPostContext.upsertSocialPostState.userTagsHistory;

    readonly igAccounts = computed(() => {
        const foundAccount = this._foundAccount();
        if (foundAccount) {
            return [...this.userTagsHistory().map((tag) => tag.igAccount), foundAccount];
        }
        return this.userTagsHistory().map((tag) => tag.igAccount);
    });

    readonly igAccountsFiltered = computed(() => {
        const searchValue = this._searchValue();
        return this.igAccounts().filter((tag) => tag.username.toLowerCase().includes(searchValue.toLowerCase()));
    });

    readonly values = computed(() => this.igAccountsFiltered().map((tag) => tag.username));
    readonly selectedValues = signal<string[]>([]);

    readonly SvgIcon = SvgIcon;

    readonly inputAutoCompleteControl = new UntypedFormControl('');

    private readonly _searchValue = signal('');
    private readonly _foundAccount = signal<IGAccount | undefined>(undefined);

    private readonly _igPlatformId = computed((): string | null => {
        const connectedPlatforms = this._upsertSocialPostContext.upsertSocialPostState.connectedSocialPlatforms();
        const instagramPlatform = connectedPlatforms.find((platform) => platform.key === PlatformKey.INSTAGRAM);
        return instagramPlatform?._id ?? null;
    });

    constructor() {
        this.inputAutoCompleteControl.valueChanges
            .pipe(
                filter((text) => {
                    this._searchValue.set(text);
                    this._foundAccount.set(undefined);
                    const isText = !!text && typeof text === 'string';
                    if (!isText) {
                        this._searchValue.set('');
                        return false;
                    }
                    return true;
                }),
                debounceTime(400),
                switchMap((text) => {
                    const igPlatformId = this._igPlatformId();
                    if (!text || !igPlatformId) {
                        return of(null);
                    }
                    return this._postsService.igSearch(text, igPlatformId).pipe(catchError(() => of({ data: null })));
                })
            )
            .subscribe({
                next: (result) => {
                    const account = result?.data?.business_discovery;
                    this._foundAccount.set(account);
                },
                error: () => {
                    this._foundAccount.set(undefined);
                },
            });
    }

    getIgAccountFromUsername = (username: string): IGAccount | undefined => this.igAccounts().find((tag) => tag.username === username);

    onSelectBaseChange = (values: string[]): void => {
        this.selectedValues.set(values);
        this._upsertSocialPostContext.updateUserTagsForReel(values.map((username) => ({ username, x: 0, y: 0 })));
    };
}
