import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { capitalize, isEqual, sumBy } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import {
    GetSegmentAnalysesTopTopicsBodyDto,
    GetSegmentAnalysesTopTopicsResponseDto,
    GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto,
    SegmentAnalysesCategoryInsights,
    SegmentAnalysesTopicDto,
} from '@malou-io/package-dto';
import {
    isSameDay,
    MalouComparisonPeriod,
    PlatformFilterPage,
    PlatformKey,
    ReviewAnalysisChartDataTag,
    ReviewAnalysisTag,
} from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SegmentAnalysesService } from ':core/services/segment-analyses.service';
import { LocalStorage } from ':core/storage/local-storage';
import { ReviewsService } from ':modules/reviews/reviews.service';
import {
    SemanticAnalysisReviewForCsv,
    SemanticAnalysisTopicForCsv,
} from ':modules/statistics/e-reputation/semantic-analysis/semantic-analysis.interface';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { TagsBarChartComponent } from ':shared/components/review-analyses-v2/tags-bar-chart/tags-bar-chart.component';
import { SemanticAnalysisTopTopicsComponent } from ':shared/components/semantic-analysis-top-topics/semantic-analysis-top-topics.component';
import { SemanticAnalysisTopicEvolutionComponent } from ':shared/components/semantic-analysis-topic-evolution/semantic-analysis-topic-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { formatDate } from ':shared/helpers';
import { LightReviewWithSegmentAnalyses } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-semantic-analysis',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        TranslateModule,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        TagsBarChartComponent,
        SemanticAnalysisTopTopicsComponent,
        SemanticAnalysisTopicEvolutionComponent,
    ],
    templateUrl: './semantic-analysis.component.html',
    styleUrl: './semantic-analysis.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SemanticAnalysisComponent implements OnInit {
    readonly shouldDisplaySemanticAnalysisForReviews = input<boolean>(true);
    readonly shouldDisplaySemanticAnalysisTopicsEvolution = input<boolean>(true);
    readonly shouldDisplaySemanticAnalysisTopTopics = input<boolean>(true);
    readonly viewBy = input<ViewBy>(ViewBy.WEEK);
    readonly isPdfDownload = input<boolean>(false);
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();
    readonly viewByChange = output<ViewBy>();

    private readonly _segmentAnalysesService = inject(SegmentAnalysesService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _store = inject(Store);

    readonly Illustration = Illustration;

    readonly currentLang = signal(LocalStorage.getLang());
    readonly isLoading = signal(true);
    readonly defaultSelectedCategory = signal<ReviewAnalysisTag | null>(null);
    readonly segmentAnalysesTopicFilter = signal<GetSegmentAnalysesTopTopicsBodyDto | null>(null);
    readonly segmentAnalysesPositiveTopics = signal<SegmentAnalysesTopicDto[]>([]);
    readonly segmentAnalysesNegativeTopics = signal<SegmentAnalysesTopicDto[]>([]);
    readonly segmentsAnalysesTopicInsightsByCategory = signal<GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto | null>(null);
    readonly reviewAnalysesChartData = signal<ReviewAnalysesChartDataByRestaurantId>(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
    readonly hasData = computed(() => this.reviewAnalysesChartData().hasData(ReviewAnalysisChartDataTag.TOTAL));

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis'),
        { initialValue: false }
    );

    readonly dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(StatisticsSelectors.selectDatesFilter)
        .pipe(
            distinctUntilChanged((prev, curr) => {
                const isSameStartDate = !!prev.startDate && !!curr.startDate && isSameDay(prev.startDate, curr.startDate);
                const isSameEndDate = !!prev.endDate && !!curr.endDate && isSameDay(prev.endDate, curr.endDate);
                return isSameStartDate && isSameEndDate;
            })
        );

    readonly comparisonFilter$: Observable<MalouComparisonPeriod> = this._store
        .select(StatisticsSelectors.selectComparisonPeriodFilter)
        .pipe(distinctUntilChanged());

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(
            distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)),
            map((platforms) => {
                if (platforms?.length) {
                    return platforms;
                }
                return Object.values(PlatformKey);
            })
        );

    constructor() {
        effect(() => {
            this.isLoadingEvent.emit(this.isLoading());
        });
    }

    ngOnInit(): void {
        this._buildSemanticAnalysisData();
    }

    onUpdateFavoriteStatus({ id, isFavorite }: { id: string; isFavorite: boolean }): void {
        this.segmentsAnalysesTopicInsightsByCategory.update((topicInsights) => {
            if (!topicInsights) {
                return null;
            }
            return Object.keys(topicInsights).reduce((acc, categoryKey) => {
                const category: SegmentAnalysesCategoryInsights = topicInsights[categoryKey];

                const updatedTopics = category.parentTopics.map((topic) => {
                    if (topic.parentTopicId === id) {
                        return { ...topic, isFavorite };
                    }
                    return topic;
                });

                acc[categoryKey] = { ...category, parentTopics: updatedTopics };

                return acc;
            }, {} as GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto);
        });
    }

    onDefaultSelectedCategoryChanged(category: ReviewAnalysisTag): void {
        const topicEvolutionChart = document.getElementById('semantic-analysis-topic-evolution-chart');
        if (topicEvolutionChart) {
            topicEvolutionChart.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        this.defaultSelectedCategory.set(category);
        // After triggering the change we go back to initial state
        setTimeout(() => {
            this.defaultSelectedCategory.set(null);
        }, 1000);
    }

    private _buildSemanticAnalysisData(): void {
        let startDate: Date | null;
        let endDate: Date | null;
        combineLatest([
            this.dates$.pipe(tap(() => this._resetChartData())),
            this.comparisonFilter$.pipe(tap(() => this._resetChartData())),
            this.platformKeys$.pipe(tap(() => this._resetChartData())),
            this._restaurantsService.restaurantSelected$,
        ])
            .pipe(
                filter(
                    ([dates, comparisonFilter, platforms, restaurant]) =>
                        !!restaurant && !!dates.startDate && !!dates.endDate && !!comparisonFilter && platforms.length > 0
                ),
                tap(() => this.isLoading.set(true)),
                switchMap(([dates, comparisonFilter, platforms, restaurant]) => {
                    startDate = dates.startDate;
                    endDate = dates.endDate;
                    const restaurantId = restaurant!._id;
                    const emptyData = this._buildEmptyChartData([restaurantId]);
                    this.segmentAnalysesTopicFilter.set({ startDate, endDate, keys: platforms, restaurantId });
                    return forkJoin({
                        chartData: this._segmentAnalysesService
                            .getSegmentAnalysesChartData({
                                startDate,
                                endDate,
                                keys: platforms,
                                restaurantIds: [restaurantId],
                            })
                            .pipe(
                                map((res) => emptyData.merge(ReviewAnalysesChartDataByRestaurantId.fromDto(res.data))),
                                catchError((error) => {
                                    console.error('Error while fetching review analyses chart data', error);
                                    return of(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
                                })
                            ),
                        topTopics: this._segmentAnalysesService
                            .getSegmentAnalysesTopTopics({
                                startDate,
                                endDate,
                                keys: platforms,
                                restaurantId,
                            })
                            .pipe(map((res) => res.data)),
                        segmentsAnalysesTopicInsightsByCategory: this._segmentAnalysesService
                            .getSegmentAnalysisParentTopicInsightsByCategory({
                                startDate,
                                endDate,
                                keys: platforms,
                                restaurantIds: [restaurantId],
                                comparisonPeriod: comparisonFilter,
                            })
                            .pipe(map((res) => res.data)),
                        reviewsWithSegmentAnalysis: this._reviewsService.getReviewsWithSegmentAnalyses({
                            restaurantId,
                            startDate,
                            endDate,
                            platformKeys: platforms,
                        }),
                    });
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ({
                    chartData: newReviewAnalysesChartData,
                    topTopics,
                    segmentsAnalysesTopicInsightsByCategory,
                    reviewsWithSegmentAnalysis,
                }: {
                    chartData: ReviewAnalysesChartDataByRestaurantId;
                    topTopics: GetSegmentAnalysesTopTopicsResponseDto;
                    segmentsAnalysesTopicInsightsByCategory: GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto;
                    reviewsWithSegmentAnalysis: LightReviewWithSegmentAnalyses[];
                }) => {
                    this._store.dispatch(
                        StatisticsActions.editSemanticAnalysisDetailsData({
                            data: this._buildCsvDataForReviews(startDate, endDate, reviewsWithSegmentAnalysis),
                        })
                    );
                    this._store.dispatch(
                        StatisticsActions.editSemanticAnalysisTopicsData({
                            data: this._buildCsvDataForTopics(startDate, endDate, segmentsAnalysesTopicInsightsByCategory),
                        })
                    );
                    this.reviewAnalysesChartData.update((reviewAnalysesChartData) =>
                        reviewAnalysesChartData.merge(newReviewAnalysesChartData)
                    );
                    this.segmentAnalysesPositiveTopics.set(topTopics?.positiveTopics ?? []);
                    this.segmentAnalysesNegativeTopics.set(topTopics?.negativeTopics ?? []);
                    this.segmentsAnalysesTopicInsightsByCategory.set(segmentsAnalysesTopicInsightsByCategory);
                    this.isLoading.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                },
            });
    }

    private _resetChartData(): void {
        this.reviewAnalysesChartData.set(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
    }

    private _buildEmptyChartData(restaurantIds: string[]): ReviewAnalysesChartDataByRestaurantId {
        return ReviewAnalysesChartDataByRestaurantId.buildEmptyChartData(restaurantIds);
    }

    private _buildCsvDataForTopics(
        startDate: Date | null,
        endDate: Date | null,
        semanticAnalysisTopicsData: GetSegmentAnalysisParentTopicInsightsByCategoryResponseDto
    ): SemanticAnalysisTopicForCsv[] {
        return Object.entries(semanticAnalysisTopicsData)
            .filter(([category, _]) => category !== ReviewAnalysisTag.OVERALL_EXPERIENCE)
            .flatMap(([category, categoryInsights]) => {
                const allPositive = sumBy(categoryInsights.parentTopics, 'positiveCount');
                const allNegative = sumBy(categoryInsights.parentTopics, 'negativeCount');
                const allPositiveEvolution = sumBy(categoryInsights.parentTopics, 'positiveCountEvolution');
                const allNegativeEvolution = sumBy(categoryInsights.parentTopics, 'negativeCountEvolution');
                const mainTopic = {
                    startDate: startDate ? formatDate(startDate, false) : '',
                    endDate: endDate ? formatDate(endDate, false) : '',
                    category: this._enumTranslatePipe.transform(category, 'review_analysis_tags'),
                    name: this._translateService.instant('common.all').toUpperCase(),
                    positiveCount: allPositive,
                    negativeCount: allNegative,
                    positiveCountEvolutionPercentage: allPositive ? Math.round((allPositiveEvolution / allPositive) * 100) : 0,
                    negativeCountEvolutionPercentage: allNegative ? Math.round((allNegativeEvolution / allNegative) * 100) : 0,
                };

                const mappedTopics = categoryInsights.parentTopics.map((topic) => ({
                    startDate: startDate ? formatDate(startDate, false) : '',
                    endDate: endDate ? formatDate(endDate, false) : '',
                    category: this._enumTranslatePipe.transform(category, 'review_analysis_tags'),
                    name: capitalize(topic.translations[this.currentLang()] ?? topic.name),
                    positiveCount: topic.positiveCount,
                    negativeCount: topic.negativeCount,
                    positiveCountEvolutionPercentage: topic.positiveCount
                        ? Math.round((topic.positiveCountEvolution / topic.positiveCount) * 100)
                        : 0,
                    negativeCountEvolutionPercentage: topic.negativeCount
                        ? Math.round((topic.negativeCountEvolution / topic.negativeCount) * 100)
                        : 0,
                }));
                return [mainTopic, ...mappedTopics];
            });
    }

    private _buildCsvDataForReviews(
        startDate: Date | null,
        endDate: Date | null,
        reviews: LightReviewWithSegmentAnalyses[]
    ): SemanticAnalysisReviewForCsv[] {
        if (!reviews.length) {
            return [];
        }
        return reviews
            .filter((review) => review.text && review.semanticAnalysisSegments?.length)
            .map(
                (review) =>
                    review.semanticAnalysisSegments
                        ?.filter(
                            (segmentAnalysis) =>
                                segmentAnalysis.category !== ReviewAnalysisTag.OVERALL_EXPERIENCE &&
                                !!segmentAnalysis.segmentAnalysisParentTopic
                        )
                        ?.map((segmentAnalysis) => ({
                            startDate: startDate ? formatDate(startDate, false) : '',
                            endDate: endDate ? formatDate(endDate, false) : '',
                            review: review.translations?.[this.currentLang()] ?? review.text,
                            reviewId: review.id,
                            category: this._enumTranslatePipe.transform(segmentAnalysis.category, 'review_analysis_tags'),
                            name: capitalize(
                                segmentAnalysis.segmentAnalysisParentTopic?.translations?.[this.currentLang()] ||
                                    segmentAnalysis.segmentAnalysisParentTopic?.name
                            ),
                            sentiment: segmentAnalysis.sentiment,
                        })) ?? []
            )
            .flat();
    }
}
