@if (isGrowthbookLoading()) {
    <div class="flex h-full w-full items-center justify-center">
        <app-malou-spinner></app-malou-spinner>
    </div>
} @else {
    @if (isNewSocialPostsAndMediasEnabled()) {
        <app-social-posts-list-v2
            (createPost)="onCreatePost()"
            (updatePost)="onUpdatePost($event)"
            (deletePost)="onDeletePost($event)"
            (createReelOrTikTok)="onCreateReelOrTikTok()"
            (duplicateToOtherRestaurants)="onDuplicateToOtherRestaurants($event)"
            (duplicateToSeoHere)="onDuplicateHere($event)"></app-social-posts-list-v2>
    } @else {
        <app-social-posts-list></app-social-posts-list>
    }
}
