import { z } from 'zod';

import { PostPublicationStatus } from '@malou-io/package-utils';

export type PollingPostStatusDto = {
    postId: string;
    published: PostPublicationStatus;
    isPublishing: boolean;
    bindingId?: string;
};

export const pollingPostsStatusesBodyValidator = z.object({
    bindingIds: z.array(z.string()),
});
export type PollingPostsStatusesBodyDto = z.infer<typeof pollingPostsStatusesBodyValidator>;
