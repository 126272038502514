import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { AspectRatio } from '@malou-io/package-utils';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

export enum RotationDirection {
    CLOCKWISE = 'CLOCKWISE',
    COUNTERCLOCKWISE = 'COUNTERCLOCKWISE',
}

@Component({
    selector: 'app-image-transform-buttons',
    templateUrl: './image-transform-buttons.component.html',
    standalone: true,
    imports: [MatIconModule, TranslateModule, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTransformButtonsComponent {
    readonly initialAspectRatio = input.required<AspectRatio>();
    readonly isDisabled = input(false);
    readonly canRotate = input.required<boolean>();
    readonly aspectRatioChange = output<AspectRatio>();
    readonly rotationDirectionChange = output<RotationDirection>();

    readonly selectedAspectRatio = signal<AspectRatio | undefined>(undefined);

    readonly SvgIcon = SvgIcon;
    readonly AspectRatio = AspectRatio;
    readonly RotationDirection = RotationDirection;
    readonly displayedAspectRatios = [
        { aspectRatio: AspectRatio.SQUARE, i18nKey: 'image_transform_buttons.square' },
        { aspectRatio: AspectRatio.PORTRAIT, i18nKey: 'image_transform_buttons.portrait' },
        { aspectRatio: AspectRatio.LANDSCAPE, i18nKey: 'image_transform_buttons.landscape' },
        { aspectRatio: AspectRatio.ORIGINAL, i18nKey: 'image_transform_buttons.original' },
    ];

    constructor() {
        effect(
            () => {
                this.selectedAspectRatio.set(this.initialAspectRatio());
            },
            { allowSignalWrites: true }
        );
    }

    onAspectRatioClick(aspectRatio: AspectRatio): void {
        this.selectedAspectRatio.set(aspectRatio);
        this.aspectRatioChange.emit(aspectRatio);
    }

    onRotate(direction: RotationDirection): void {
        this.rotationDirectionChange.emit(direction);
    }
}
