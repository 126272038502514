import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { SemanticAnalysisTopicForCsv } from ':modules/statistics/e-reputation/semantic-analysis/semantic-analysis.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = SemanticAnalysisTopicForCsv[] | undefined;

@Injectable({ providedIn: 'root' })
export class SemanticAnalysisTopicsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return this._store.select(StatisticsSelectors.selectSemanticAnalysisTopicsData).pipe(take(1));
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return [
            'Date Start',
            'Date End',
            'Category',
            'Topic',
            'Positive Count',
            'Evolution Positive',
            'Negative Count',
            'Evolution Negative',
        ];
    }

    protected override _getCsvDataRows(data: Exclude<Data, undefined>): CsvAsStringArrays {
        return data
            .filter((topic) => !!topic.positiveCount && !!topic.negativeCount)
            .map((topic) => [
                topic.startDate,
                topic.endDate,
                topic.category,
                topic.name,
                topic.positiveCount.toString(),
                topic.positiveCountEvolutionPercentage.toString() + '%',
                topic.negativeCount.toString(),
                topic.negativeCountEvolutionPercentage.toString() + '%',
            ]);
    }
}
