<div class="malou-dialog-container">
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div>{{ 'download_insights_modal.title' | translate }}</div>
            <button class="malou-btn-icon" mat-icon-button (click)="onClose()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body !overflow-hidden text-center">
            <form [formGroup]="formGroup">
                <div class="flex flex-col gap-y-6">
                    <app-select
                        formControlName="downloadFormat"
                        [title]="'download_insights_modal.mode_input_title' | translate"
                        [required]="true"
                        [displayWith]="downloadFormatDisplayWith"
                        [values]="downloadFormatValues"
                        (selectChange)="onDownloadFormatChange($event)">
                    </app-select>

                    @if (shouldShowDownloadTypeSelect()) {
                        <app-select
                            formControlName="downloadType"
                            [title]="'download_insights_modal.type_input_title' | translate"
                            [required]="true"
                            [displayWith]="downloadTypeDisplayWith"
                            [values]="downloadTypeValues"
                            (selectChange)="onDownloadTypeChange($event)">
                        </app-select>
                    }

                    @if (shouldShowStatisticPageSections()) {
                        <app-select-chip-list
                            [formControlName]="chartControlName()"
                            [title]="'download_insights_modal.charts_input_title' | translate"
                            [required]="true"
                            [values]="$any(chartList())"
                            [displayWith]="chartsDisplayWith"
                            [checkboxOption]="true"
                            [showSelectAllCheckbox]="true"
                            [selectAllCheckboxMessage]="'download_insights_modal.all_charts' | translate">
                        </app-select-chip-list>
                    }
                </div>
            </form>
        </div>

        <div class="malou-dialog__footer">
            <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="onClose()">
                {{ 'common.cancel' | translate }}
            </button>
            <app-button
                buttonClasses="!h-11"
                [id]="'tracking_download_' + data.tab + '_stats_button_click_' + downloadFormat().toLowerCase()"
                [disabled]="formGroup.invalid"
                [text]="'common.download' | translate"
                [loading]="isDownloading()"
                (buttonClick)="onDownload()"></app-button>
        </div>
    </div>
</div>
