import { TranslateService } from '@ngx-translate/core';

import { AppInjectorService } from ':shared/services/app-injector.service';

interface KeywordTableDataRowProps {
    restaurantId: string;
    restaurantName: string;
    keywords?: string[];
    keywordsTop20?: string[];
    keywordsTop3?: string[];
    currentTop20?: number;
    currentTop3?: number;
    previousTop20?: number;
    previousTop3?: number;
    brandingKeywordImpressions?: number;
    discoveryKeywordImpressions?: number;
    previousBrandingKeywordImpressions?: number;
    previousDiscoveryKeywordImpressions?: number;
}

export class KeywordTableDataRow {
    private _translateService: TranslateService;

    chartAxisName: string;
    restaurantId: string;
    restaurantName: string;
    keywords?: string[];
    keywordsTop20?: string[];
    keywordsTop3?: string[];
    currentTop20?: number;
    currentTop3?: number;
    previousTop20?: number;
    previousTop3?: number;
    brandingKeywordImpressions?: number;
    discoveryKeywordImpressions?: number;
    previousBrandingKeywordImpressions?: number;
    previousDiscoveryKeywordImpressions?: number;
    error?: string;

    constructor(init: KeywordTableDataRowProps) {
        this._translateService = AppInjectorService.getInjector()?.get(TranslateService);

        if (init) {
            this.chartAxisName = init.restaurantName + ':ID:' + init.restaurantId;
            this.restaurantId = init.restaurantId;
            this.restaurantName = init.restaurantName;
            this.keywords = init.keywords;
            this.keywordsTop20 = init.keywordsTop20;
            this.keywordsTop3 = init.keywordsTop3;
            this.currentTop20 = init.currentTop20;
            this.currentTop3 = init.currentTop3;
            this.previousTop20 = init.previousTop20;
            this.previousTop3 = init.previousTop3;
            this.brandingKeywordImpressions = init.brandingKeywordImpressions;
            this.discoveryKeywordImpressions = init.discoveryKeywordImpressions;
            this.previousBrandingKeywordImpressions = init.previousBrandingKeywordImpressions;
            this.previousDiscoveryKeywordImpressions = init.previousDiscoveryKeywordImpressions;
        }
        this.setError(this.translateError());
    }

    getDiffTop20(): number | undefined {
        return this.currentTop20 && this.previousTop20 ? this.currentTop20 - this.previousTop20 : undefined;
    }

    getDiffTop3(): number | undefined {
        return this.currentTop3 && this.previousTop3 ? this.currentTop3 - this.previousTop3 : undefined;
    }

    getDiffBrandingKeywordImpressions(): number | undefined {
        return this.brandingKeywordImpressions && this.previousBrandingKeywordImpressions
            ? this.brandingKeywordImpressions - this.previousBrandingKeywordImpressions
            : undefined;
    }

    getDiffDiscoveryKeywordImpressions(): number | undefined {
        return this.discoveryKeywordImpressions && this.previousDiscoveryKeywordImpressions
            ? this.discoveryKeywordImpressions - this.previousDiscoveryKeywordImpressions
            : undefined;
    }

    getKeywordsToString(): string {
        return (this.keywords ?? []).map((k) => `${k}`).join(' - ');
    }

    getKeywordsTop20ToString(): string {
        return (this.keywordsTop20 ?? []).map((k) => `- ${k}`).join('\n');
    }

    getKeywordsTop3ToString(): string {
        return (this.keywordsTop3 ?? []).map((k) => `- ${k}`).join('\n');
    }

    hasError(): boolean {
        return !!this.error;
    }

    setError(error?: string): void {
        this.error = error;
    }

    getError(): string | undefined {
        return this.error;
    }

    translateError(): string | undefined {
        if (this.error?.match(/The provided Place ID is no longer valid/)) {
            return this._translateService?.instant('aggregated_statistics.seo.keywords.cant_get_data');
        }
        return;
    }

    getAggregatedKeywordRakingData(): AggregatedKeywordRankingData {
        return {
            restaurantName: this.restaurantName,
            currentTop20: this.currentTop20,
            currentTop3: this.currentTop3,
            previousTop20: this.previousTop20,
            previousTop3: this.previousTop3,
            brandingKeywordImpressions: this.brandingKeywordImpressions,
            discoveryKeywordImpressions: this.discoveryKeywordImpressions,
            previousBrandingKeywordImpressions: this.previousBrandingKeywordImpressions,
            previousDiscoveryKeywordImpressions: this.previousDiscoveryKeywordImpressions,
        };
    }
}

export interface AggregatedKeywordRankingData {
    restaurantName: string;
    currentTop20: number | undefined;
    currentTop3: number | undefined;
    previousTop20: number | undefined;
    previousTop3: number | undefined;
    brandingKeywordImpressions: number | undefined;
    discoveryKeywordImpressions: number | undefined;
    previousBrandingKeywordImpressions: number | undefined;
    previousDiscoveryKeywordImpressions: number | undefined;
}
