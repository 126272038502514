export enum SemanticAnalysisProviderKey {
    OPENAI = 'openai',
}

export enum ReviewAnalysisSentiment {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral',
}

// TODO: Rename in SegmentAnalysisCategory when removing feature toggle 'release-new-semantic-analysis'
export enum ReviewAnalysisTag {
    ATMOSPHERE = 'atmosphere',
    EXPEDITIOUSNESS = 'expeditiousness', // TODO: Remove this category when feature toggle 'release-new-semantic-analysis' is removed
    FOOD = 'food',
    HYGIENE = 'hygiene',
    OVERALL_EXPERIENCE = 'overall_experience',
    PRICE = 'price',
    SERVICE = 'service',
}

export enum ReviewAnalysisSubCategory {
    MENU_ITEMS = 'menu_items',
    STAFF_MEMBERS = 'staff_members',
}

export enum ReviewAnalysisStatus {
    DONE = 'done',
    PENDING = 'pending',
    FAILED = 'failed',
    REVIEW_TOO_OLD = 'review_too_old', // TODO: Remove handling of this type when feature toggle 'release-new-semantic-analysis' is removed
    UNSUPPORTED_PLATFORM = 'unsupported_platform',
    NO_RESULTS = 'no_results',
}

export enum PlatformPresenceStatus {
    NOT_FOUND = 'not_found', // means that the reviews was not found/deleted on the platform
    FOUND = 'found',
}

/**
 * Used for chart in frontend
 */
export enum ReviewAnalysisChartDataTag {
    ATMOSPHERE = ReviewAnalysisTag.ATMOSPHERE,
    EXPEDITIOUSNESS = ReviewAnalysisTag.EXPEDITIOUSNESS,
    FOOD = ReviewAnalysisTag.FOOD,
    HYGIENE = ReviewAnalysisTag.HYGIENE,
    PRICE = ReviewAnalysisTag.PRICE,
    SERVICE = ReviewAnalysisTag.SERVICE,
    TOTAL = 'total',
}

export enum ReviewAnalysisChartDataSentiment {
    POSITIVE = ReviewAnalysisSentiment.POSITIVE,
    NEGATIVE = ReviewAnalysisSentiment.NEGATIVE,
    TOTAL = 'total',
}

export enum ReviewCannotReplyExplanation {
    IS_TOO_OLD = 'is_too_old',
    HAS_NO_TEXT = 'has_no_text',
    IS_PRIVATE_FROM_TOTEM = 'is_private_from_totem',
    PLATFORM_DOESNT_SUPPORT_REPLY = 'platform_doesnt_support_reply',
}
