<div
    class="rounded-[5px] border border-malou-color-background-dark bg-malou-color-background-light p-3"
    [ngClass]="{ 'border-dotted border-malou-color-primary': isDragging() }">
    @if (isDragging()) {
        <ng-container [ngTemplateOutlet]="dragOverTemplate"></ng-container>
    } @else if (medias().length === 0 && uploadingMediaCount() === 0) {
        <ng-container [ngTemplateOutlet]="addFirstMediaTemplate"></ng-container>
    } @else {
        @switch (publicationType()) {
            @case (PublicationType.POST) {
                <app-post-media-list
                    [(medias)]="medias"
                    [uploadingMediaCount]="uploadingMediaCount()"
                    [showEditMediaButton]="options().post?.showEditMediaButton ?? true"
                    [isReadonly]="isReadonly()"
                    (disableDragEvents)="areDragEventsEnable.set(false)"
                    (enableDragEvents)="areDragEventsEnable.set(true)"
                    (openFilePicker)="fileInput.click()"
                    (importFromGallery)="onImportMediaFromGallery()"
                    (mediaClicked)="postMediaClicked.emit($event)"></app-post-media-list>
            }
            @case (PublicationType.REEL) {
                <app-reel-media
                    [media]="getReelMedia()"
                    [isLoading]="uploadingMediaCount() > 0"
                    [isReadonly]="isReadonly()"
                    [thumbnail]="reelThumbnail()"
                    (deleteMedia)="onDeleteReelMedia()"
                    (openThumbnailFilePicker)="reelThumbnailFileInput.click()"
                    (importThumbnailFromGallery)="chooseCustomReelThumbnailFromGallery()"
                    (onThumbnailSelectedFromVideo)="extractReelThumbnailFromVideo($event)"></app-reel-media>
            }
        }
    }
</div>

<ng-template #addFirstMediaTemplate>
    <div class="flex h-[75px] items-center gap-x-5 rounded-md bg-white p-4">
        <mat-icon class="!h-14 !w-14 !fill-malou-color-background-dark" [svgIcon]="SvgIcon.IMAGES"></mat-icon>
        <div class="flex flex-col gap-y-1">
            <div class="malou-text-11--semibold text-malou-color-text-1">
                <span class="cursor-pointer text-malou-color-primary" [matMenuTriggerFor]="uploadMenu">{{
                    'social_post_medias.add_media' | translate
                }}</span>
                {{ 'social_post_medias.or_drag_and_drop' | translate }}
            </div>
            <div class="malou-text-10--regular italic text-malou-color-text-2">
                @if (publicationType() === PublicationType.POST) {
                    {{ 'social_post_medias.photo_or_video_file' | translate }}
                } @else if (publicationType() === PublicationType.REEL) {
                    {{ 'social_post_medias.video_file' | translate }}
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dragOverTemplate>
    <div class="flex h-[75px] items-center gap-x-5 rounded-md bg-white p-4">
        <mat-icon class="!h-14 !w-14 !fill-malou-color-background-dark" [svgIcon]="SvgIcon.IMAGES"></mat-icon>
        <div class="malou-text-11--semibold text-malou-color-text-1">
            {{ 'social_post_medias.drag_and_drop_here' | translate }}
        </div>
    </div>
</ng-template>

<input
    type="file"
    hidden
    [multiple]="multipleMediaSelection()"
    [accept]="acceptAttribute()"
    [disabled]="isReadonly()"
    (change)="onImportFromFile($event)"
    #fileInput />

<!-- only for reels -->
<input
    type="file"
    hidden
    [multiple]="false"
    [accept]="IMAGE_MIME_TYPES"
    (change)="uploadCustomReelThumbnail($event)"
    #reelThumbnailFileInput />

<mat-menu class="malou-mat-menu malou-box-shadow rounded-md" #uploadMenu="matMenu">
    <button mat-menu-item [disabled]="isReadonly()" (click)="fileInput.click()">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
        <span class="malou-text-12--regular text-malou-color-text-2">
            {{ 'common.upload_from_computer' | translate }}
        </span>
    </button>
    <button mat-menu-item [disabled]="isReadonly()" (click)="onImportMediaFromGallery()">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
        <span class="malou-text-12--regular text-malou-color-text-2">{{ 'common.upload_from_gallery' | translate }}</span>
    </button>
</mat-menu>
