import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { CommunityChartV2Component } from ':modules/statistics/social-networks/community-v2/community-chart/community-chart.component';
import { CommunityMainInfosV2Component } from ':modules/statistics/social-networks/community-v2/community-main-infos/community-main-infos.component';
import { CommunityData } from ':modules/statistics/social-networks/community-v2/community.interface';
import { SocialNetworksCommunityInsightsContext } from ':modules/statistics/social-networks/context/social-networks-community-insights.context';
import { CommunityInsightsChartData } from ':modules/statistics/social-networks/models/community-insight-chart-data';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { mergeArrays } from ':shared/helpers';
import { DatesAndPeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

const DEFAULT_COMMUNITY_DATA: CommunityData = {
    instagramFollowers: [],
    facebookFollowers: [],
    totalFollowers: [],
    previousInstagramFollowers: [],
    previousFacebookFollowers: [],
    previousTotalFollowers: [],
    instagramNewFollowers: [],
    facebookNewFollowers: [],
    totalNewFollowers: [],
};
@Component({
    selector: 'app-community-v2',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        CommunityChartV2Component,
        MatProgressSpinnerModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        LowerCasePipe,
        ApplyPurePipe,
        CommunityMainInfosV2Component,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityV2Component implements OnInit {
    readonly showViewByTextInsteadOfSelector = input<boolean>(false);
    readonly viewBy = input<ViewBy>(ViewBy.DAY);
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _destroyRef = inject(DestroyRef);
    public readonly socialNetworksCommunityInsightsContext = inject(SocialNetworksCommunityInsightsContext);

    readonly SvgIcon = SvgIcon;
    readonly ViewBy = ViewBy;
    readonly Illustration = Illustration;

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelector.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);

    readonly httpError = computed(() => this.socialNetworksCommunityInsightsContext.httpError());
    readonly isLoading = computed(() => this.socialNetworksCommunityInsightsContext.isLoading());
    readonly insightsError = computed(() => this.socialNetworksCommunityInsightsContext.insightsError());
    readonly areAllPlatformsInError = computed(() => this.socialNetworksCommunityInsightsContext.areAllPlatformsInError());
    readonly platformsErrorTooltip = computed(() => this.socialNetworksCommunityInsightsContext.platformsErrorTooltip());

    readonly viewByFilter: WritableSignal<ViewBy> = signal(ViewBy.DAY);

    readonly previousPeriodDates: Signal<Date[]> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return this.socialNetworksCommunityInsightsContext.monthlyPreviousInsightsChartData().dates;
            case ViewBy.WEEK:
                return this.socialNetworksCommunityInsightsContext.weeklyPreviousInsightsChartData().dates;
            default:
            case ViewBy.DAY:
                return this.socialNetworksCommunityInsightsContext.dailyPreviousInsightsChartData().dates;
        }
    });

    readonly dateLabels: Signal<Date[]> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return this.socialNetworksCommunityInsightsContext.monthlyInsightsChartData().dates;
            case ViewBy.WEEK:
                return this.socialNetworksCommunityInsightsContext.weeklyInsightsChartData().dates;
            default:
            case ViewBy.DAY:
                return this.socialNetworksCommunityInsightsContext.dailyInsightsChartData().dates;
        }
    });

    readonly communityData: Signal<CommunityData> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return this._getCommunityData(
                    this.socialNetworksCommunityInsightsContext.monthlyInsightsChartData(),
                    this.socialNetworksCommunityInsightsContext.monthlyPreviousInsightsChartData()
                );
                break;
            case ViewBy.WEEK:
                return this._getCommunityData(
                    this.socialNetworksCommunityInsightsContext.weeklyInsightsChartData(),
                    this.socialNetworksCommunityInsightsContext.weeklyPreviousInsightsChartData()
                );
                break;
            default:
            case ViewBy.DAY:
                return this._getCommunityData(
                    this.socialNetworksCommunityInsightsContext.dailyInsightsChartData(),
                    this.socialNetworksCommunityInsightsContext.dailyPreviousInsightsChartData()
                );
                break;
        }
        return DEFAULT_COMMUNITY_DATA;
    });

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        const viewBy = this.viewBy();
        if (viewBy) {
            this.viewByFilterSubject$.next(viewBy);
        }

        this.socialNetworksCommunityInsightsContext.chartData$.subscribe();

        this.viewByFilterSubject$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (viewByFilter) => {
                this.viewByChange.emit(viewByFilter);
                this.viewByFilter.set(viewByFilter);
            },
        });
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslatePipe.transform(option, 'view_by');

    private _getCommunityData(
        currentFollowersData: CommunityInsightsChartData,
        previousFollowersData: CommunityInsightsChartData
    ): CommunityData {
        return {
            instagramFollowers: currentFollowersData.instagramFollowers || [],
            facebookFollowers: currentFollowersData.facebookFollowers || [],
            totalFollowers: mergeArrays(currentFollowersData.instagramFollowers || [], currentFollowersData.facebookFollowers || []),
            previousInstagramFollowers: previousFollowersData.instagramFollowers || [],
            previousFacebookFollowers: previousFollowersData.facebookFollowers || [],
            previousTotalFollowers: mergeArrays(
                previousFollowersData.instagramFollowers || [],
                previousFollowersData.facebookFollowers || []
            ),
            instagramNewFollowers: currentFollowersData.instagramNewFollowers,
            facebookNewFollowers: currentFollowersData.facebookNewFollowers,
            totalNewFollowers: mergeArrays(currentFollowersData.instagramNewFollowers, currentFollowersData.facebookNewFollowers),
            comparisonPeriod: previousFollowersData.comparisonPeriod,
        };
    }
}
