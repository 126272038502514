import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { ApplicationLanguage, PlatformKey, PostSource } from '@malou-io/package-utils';

import { times } from ':core/constants';
import { AddFbLocationV2Component } from ':shared/components/add-fb-location-v2/add-fb-location-v2.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { InputTextTheme } from ':shared/components/input-text/input-text.interface';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { isPastHour } from ':shared/helpers';
import { FbLocation, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-social-post-caption-preview-card-v2',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatSelectModule,
        MatTooltipModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        InputDatePickerComponent,
        InputTextComponent,
        ApplySelfPurePipe,
        ImagePathResolverPipe,
        ApplyPurePipe,
        FormatTimePipe,
        AddFbLocationV2Component,
        CdkTextareaAutosize,
        PlatformLogoComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './social-post-caption-preview-card-v2.component.html',
    styleUrl: './social-post-caption-preview-card-v2.component.scss',
})
export class SocialPostCaptionPreviewCardV2Component {
    readonly shouldDisplayDateForm = input.required<boolean>();
    readonly postForm = input.required<
        FormGroup<{
            plannedPublicationDate: FormControl<Date>;
            postTime: FormControl<string>;
            postCaption: FormControl<string>;
            postCaptionTriggeredByKeepSameCaptionToggle: FormControl<string>;
            keys: FormControl<PlatformKey[]>;
            hashtagTexts: FormControl<string[]>;
            // wont be visible in the UI
            restaurant: FormControl<Restaurant>;
            location: FormControl<FbLocation | null>;
            fbPlatformName: FormControl<string | null>;
            hasPlatformsConnected: FormControl<boolean>;
        }>
    >();
    readonly shouldKeepSameCaptionForAllPosts = input.required<boolean>();
    readonly postDestination = input.required<PostSource>();

    readonly currentLang = signal<ApplicationLanguage>(inject(TranslateService).currentLang as ApplicationLanguage);

    readonly MAX_LENGTH_FOR_SOCIAL_POST = 1500;
    readonly TIMES = times;
    readonly MIN_DATE = new Date();
    readonly SvgIcon = SvgIcon;
    readonly InputTextTheme = InputTextTheme;
    readonly PostSource = PostSource;

    readonly isLoadingLocation$: BehaviorSubject<{ loading: boolean; location: FbLocation | null }> = new BehaviorSubject({
        loading: false,
        location: null,
    });

    readonly allHashtagTexts = computed(() => this.postForm().get('hashtagTexts')!.value);

    isPastHour = isPastHour;

    get restaurant(): Restaurant {
        return this.postForm().get('restaurant')!.value;
    }

    get platformSocialId(): string | null {
        return this.postForm().get('platformSocialId')!.value;
    }

    get location(): FormControl<FbLocation | null> {
        return this.postForm().controls.location;
    }

    get keys(): PlatformKey[] {
        return this.postForm().get('keys')!.value;
    }

    get fbPlatformName(): string | null {
        return this.postForm().get('fbPlatformName')!.value;
    }

    get hashtagTexts(): string[] {
        return this.postForm().get('hashtagTexts')!.value;
    }

    get hasPlatformsConnected(): boolean {
        return this.postForm().get('hasPlatformsConnected')!.value;
    }

    changeSelectedTime(event: MatSelectChange): void {
        this.postForm().patchValue({
            postTime: event.value,
        });
    }

    onLocationChange(location: FbLocation | null): void {
        this.postForm().patchValue({
            location,
        });
    }

    removeHashtag(hashtagIndex: number): void {
        this.postForm().patchValue({
            hashtagTexts: this.hashtagTexts.filter((_, index) => index !== hashtagIndex),
        });
    }
}
