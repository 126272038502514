import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ApplicationLanguage, DEFAULT_LANG_UNKNOWN, ReviewAnalysisSentiment } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { DisplayMenuItemsPipe } from ':modules/reviews/pipe/display-menu-items.pipe';
import { GetMenuItemReviewsPipe } from ':modules/reviews/pipe/get-menu-item-reviews.pipe';
import { ReviewTextPipe } from ':modules/reviews/pipe/review-text.pipe';
import { RestaurantHeaderForReviewPreviewComponent } from ':modules/reviews/review-preview/restaurant-header-for-review-preview/restaurant-header-for-review-preview.component';
import { ReviewsService } from ':modules/reviews/reviews.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { ReviewTranslationsComponent } from ':shared/components/review-translations/review-translations.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { MalouPeriod, Restaurant, ReviewWithAnalysis, SemanticAnalysisInsightsReview } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { DateToStringPipe } from ':shared/pipes/date.pipe';
import { PluralCategory, PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ToRangePipe } from ':shared/pipes/to-range.pipe';

export enum TabIndex {
    POSITIVE,
    NEGATIVE,
}

interface SegmentAnalysisModalData {
    topic: string;
    topicTranslations: Record<ApplicationLanguage, string>;
    reviewAnalysesFilter: ReviewAnalysesChartFilter;
    isFromAggregatedStatistics: boolean;
    isMainCategory: boolean;
    shouldShowSubcategories: boolean;
    tabIndex?: TabIndex;
}

@Component({
    selector: 'app-topic-segment-analysis-modal',
    templateUrl: './topic-segment-analysis-modal.component.html',
    styleUrls: ['./topic-segment-analysis-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatTabsModule,
        LazyLoadImageModule,
        TranslateModule,
        PlatformLogoComponent,
        RestaurantHeaderForReviewPreviewComponent,
        StarGaugeComponent,
        SkeletonComponent,
        ReviewTranslationsComponent,
        AvatarPipe,
        DatePipe,
        DateToStringPipe,
        PluralTranslatePipe,
        ToRangePipe,
        ReviewTextPipe,
        ApplySelfPurePipe,
        GetMenuItemReviewsPipe,
        DisplayMenuItemsPipe,
    ],
})
export class TopicSegmentAnalysisModalComponent implements OnInit {
    private readonly _dialogRef = inject(MatDialogRef<TopicSegmentAnalysisModalComponent>);
    public readonly data: SegmentAnalysisModalData = inject(MAT_DIALOG_DATA);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _restaurantsService = inject(RestaurantsService);

    readonly SvgIcon = SvgIcon;
    readonly ReviewAnalysisSentiment = ReviewAnalysisSentiment;
    readonly PluralCategory = PluralCategory;
    readonly MAX_MENU_ITEM_SHOWN = 3;

    readonly areReviewsLoading = signal(false);

    readonly currentLang = signal(LocalStorage.getLang());
    readonly topicNameInCurrentLang = computed(() => capitalize(this.data.topicTranslations?.[this.currentLang()] ?? this.data.topic));
    readonly insightsReviews = signal<SemanticAnalysisInsightsReview[]>([]);
    readonly positiveInsightsReviews = computed(() =>
        this.insightsReviews().filter((r) => r.sentiment === ReviewAnalysisSentiment.POSITIVE)
    );
    readonly positiveReviewCount = computed(() => this.positiveInsightsReviews().length);
    readonly negativeInsightsReviews = computed(() =>
        this.insightsReviews().filter((r) => r.sentiment === ReviewAnalysisSentiment.NEGATIVE)
    );
    readonly negativeReviewCount = computed(() => this.negativeInsightsReviews().length);

    readonly translationByReviewId: WritableSignal<Record<string, string>> = signal({});
    readonly shouldShowTranslation = computed(
        () =>
            (review: SemanticAnalysisInsightsReview): boolean =>
                review.lang !== this.currentLang() && !!this.translationByReviewId()[review.id]
    );
    readonly hasTranslationsAvailable = computed(
        () =>
            (review: SemanticAnalysisInsightsReview): boolean =>
                review.lang !== DEFAULT_LANG_UNKNOWN && review.lang !== this.currentLang() && !!review.text?.length && !!review.lang
    );

    readonly selectedTabIndex = signal<TabIndex>(TabIndex.POSITIVE);
    restaurantsById: Record<string, Restaurant> = {};
    isFromAggregatedStatistics = false;

    constructor() {
        effect(
            () => {
                const translationByReviewId = this.insightsReviews().reduce((acc, review) => {
                    acc[review.id] = review.hasTranslations(this.currentLang()) ? review.getTranslation(this.currentLang()) : '';
                    return acc;
                }, {});
                this.translationByReviewId.set(translationByReviewId);
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                if (this.data.tabIndex) {
                    return;
                }
                const positiveReviewCount = this.positiveReviewCount();
                this.selectedTabIndex.set(!!positiveReviewCount ? TabIndex.POSITIVE : TabIndex.NEGATIVE);
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.isFromAggregatedStatistics = this.data.isFromAggregatedStatistics;
        this.restaurantsById = this._getRestaurantsById(this.data);

        if (this.data.tabIndex) {
            this.selectedTabIndex.set(this.data.tabIndex);
        }

        this.areReviewsLoading.set(true);
        this._reviewsService
            .getReviewsBySegmentTopic({
                topic: this.data.topic,
                startDate: this.data.reviewAnalysesFilter.startDate,
                endDate: this.data.reviewAnalysesFilter.endDate,
                platformKeys: this.data.reviewAnalysesFilter.keys,
                restaurantIds: this.data.reviewAnalysesFilter.restaurantIds,
                isMainCategory: this.data.isMainCategory,
                shouldShowSubcategories: this.data.shouldShowSubcategories,
            })
            .subscribe((reviews) => {
                this.insightsReviews.set(reviews);
                this.areReviewsLoading.set(false);
            });
    }

    close(): void {
        this._dialogRef.close();
    }

    goToReview(review: ReviewWithAnalysis | SemanticAnalysisInsightsReview): void {
        const startDate = DateTime.fromJSDate(review.socialCreatedAt).startOf('day').toJSDate();
        const endDate = DateTime.fromJSDate(review.socialCreatedAt).endOf('day').plus({ day: 7 }).toJSDate();
        this._store.dispatch(
            ReviewsActions.editReviewsFiltersDates({
                datesFilters: {
                    period: MalouPeriod.CUSTOM,
                    startDate,
                    endDate,
                },
            })
        );
        const reviewId = review instanceof SemanticAnalysisInsightsReview ? review.id : review._id;
        const urlTree = this.isFromAggregatedStatistics
            ? ['/groups', 'reputation', 'reviews']
            : ['/restaurants', review.restaurantId, 'reputation', 'reviews'];
        const url = this._router.serializeUrl(
            this._router.createUrlTree(urlTree, {
                queryParams: { reviewId, resetDatesFilters: true },
            })
        );
        window.open(url, '_blank');
    }

    onShowTranslatedText(reviewId: string, { value: _value, translatedText }: { value: boolean; translatedText?: string }): void {
        this.translationByReviewId.update((translationByReviewId) => ({
            ...translationByReviewId,
            [reviewId]: translatedText ?? '',
        }));
    }

    private _getRestaurantsById({ isFromAggregatedStatistics }: { isFromAggregatedStatistics: boolean }): Record<string, Restaurant> {
        if (isFromAggregatedStatistics) {
            return this._restaurantsService.restaurants().reduce((acc, restaurant) => {
                acc[restaurant._id] = restaurant;
                return acc;
            }, {});
        }
        const selectedRestaurant = this._restaurantsService.selectedRestaurant();
        if (!selectedRestaurant) {
            return {};
        }
        return {
            [selectedRestaurant._id]: selectedRestaurant,
        };
    }
}
