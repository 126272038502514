import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ReviewCannotReplyExplanation } from '@malou-io/package-utils';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'reviewAnswerTooltip',
    standalone: true,
})
export class ReviewAnswerTooltipPipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(review: Review | PrivateReview, isSyncReviewsFinished: boolean): string {
        return this.getAnswerButtonTooltip(review, isSyncReviewsFinished);
    }

    getAnswerButtonTooltip(review: Review | PrivateReview, isSyncReviewsFinished: boolean): string {
        if (!isSyncReviewsFinished) {
            return this._translate.instant('reviews.cant_reply_review_while_sync');
        }
        if (isSyncReviewsFinished && review.getCannotReplyExplanation() === ReviewCannotReplyExplanation.IS_TOO_OLD) {
            return this._translate.instant('reviews.cant_reply_because_date', { nbDays: review.getNbDaysUntilCantBeAnswered() });
        }
        return '';
    }
}
