import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, HostListener, input, output, viewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SvgIcon } from ':shared/modules/svg-icon.enum';

// TODO posts-v2: read-only mode

@Component({
    selector: 'app-video-thumbnail-slider',
    templateUrl: './video-thumbnail-slider.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, MatIcon, MatTooltipModule],
})
export class VideoThumbnailSliderComponent {
    /// ///////////////////////////////////////// Public attributes

    public readonly isReadonly = input<boolean>(false);

    /** A floating point number between 0 and 1 (optional) */
    public readonly loadingProgress = input<number>();

    /** The picture displayed on the slider (optional) */
    public readonly sliderThumbnailUrl = input<string>();

    /**
     * URLs of a set of pictures displayed in the timeline of the video.
     * This is supposed to be a set of frames extracted from the video
     * at a regular interval, over the whole duration of the video.
     */
    public readonly timelinePreviewUrls = input.required<string[]>();

    /** The position of the slider as a floating point number between 0 and 1. */
    public readonly position = input<number>();

    public readonly onMediaDeleted = output();

    /**
     * Emits the position of the slider as a floating point number between 0 and 1
     * when the user stops dragging the slider.
     */
    public readonly onPositionChange = output<number>();

    /// ///////////////////////////////////////// Private attributes

    readonly SvgIcon = SvgIcon;

    /** the clientX attribute of the mouse event when the drag was initiated */
    private _dragStartClientX = 0;

    /** this._sliderLeftPx() when the drag was initiated */
    private _dragStartSliderLeftPx = 0;

    /** true if the mouse is pressed to drag the slider */
    private _draggingSlider = false;

    /** the current position of the slider in CSS “px” */
    private _sliderLeftPx = 0;

    private _sliderElement = viewChild<ElementRef>('slider');
    private _slideBarElement = viewChild<ElementRef>('slideBar');

    constructor() {
        effect(() => {
            const newPosition = this.position();
            if (newPosition !== undefined && !this._draggingSlider) {
                const slider = this._sliderElement();
                if (slider) {
                    this._sliderLeftPx = newPosition * this._slidableWidthPx();
                    slider.nativeElement.style.left = this._sliderLeftPx + 'px';
                }
            }
        });
    }

    sliderOnMouseDown(event: MouseEvent): void {
        this._draggingSlider = true;
        this._dragStartClientX = event.clientX;
        this._dragStartSliderLeftPx = this._sliderLeftPx;
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp() {
        this._dragEnd();
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this._draggingSlider) {
            if (event.buttons !== 1) {
                this._dragEnd();
                return;
            }
            const delta = event.clientX - this._dragStartClientX;
            const maxWidth = this._slidableWidthPx();
            this._sliderLeftPx = Math.min(Math.max(0, this._dragStartSliderLeftPx + delta), maxWidth);
            const slider = this._sliderElement();
            if (slider) {
                slider.nativeElement.style.left = this._sliderLeftPx + 'px';
            }
        }
    }

    onTimelineClick(event_: Event): void {
        const event = event_ as MouseEvent;
        const slideBarPositionX = this._slideBarElement()!.nativeElement.getBoundingClientRect().x;
        const sliderWidth = this._sliderElement()!.nativeElement.clientWidth;
        const relativePositionX = event.clientX - slideBarPositionX - sliderWidth / 2;
        this.onPositionChange.emit(Math.min(1, Math.max(0, relativePositionX / this._slidableWidthPx())));
    }

    private _slidableWidthPx(): number {
        return this._slideBarElement()!.nativeElement.clientWidth - this._sliderElement()!.nativeElement.clientWidth;
    }

    private _dragEnd(): void {
        if (this._draggingSlider) {
            this._draggingSlider = false;
            this.onPositionChange.emit(this._sliderLeftPx / this._slidableWidthPx());
        }
    }
}
