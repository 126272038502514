import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { PasswordManagedConnectionStep2Component } from ':modules/platforms/platforms-connection-modals/platforms-connection-password-managed-modal/account-managed-connection-step-2/password-managed-connection-step-2.component';
import {
    PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
    PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
    ParentStepperComponentDialogData,
    PlatformsConnectionParentStepperComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/platforms-connection-parent-stepper.component';
import { createStep } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/step.interface';
import { GetStepIndexFromPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/get-step-index-from-platform.service';
import { PlatformPresentationStepComponent } from ':modules/platforms/platforms-connection-modals/shared/steps/platform-presentation-step/platform-presentation-step.component';
import { SelectBusinessStepComponent } from ':modules/platforms/platforms-connection-modals/shared/steps/select-business-step/select-business-step.component';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

export type AuthorizedPasswordManagedPlatformKeys = PlatformKey.LAFOURCHETTE;
export interface PASSWORD_MANAGED_SHARED_DATA_TYPE {
    platformKey: AuthorizedPasswordManagedPlatformKeys;
    socialId?: string;
}
export interface PasswordManagedConnectionModalResult {
    hasDataChanged?: boolean;
    openHelpModal?: boolean;
}

interface PasswordManagedConnectionModalTranslations {
    title: string;
    subtitle: string;
    step0: string;
    step1: string;
    step2: string;
}

const NOT_CONNECTED_STEP_INDEX = 0;
const CONNECTED_STEP_INDEX = 1;

@Injectable({ providedIn: 'root' })
export class PasswordManagedConnectionModalService {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _getStepIndexFromPlatformService = inject(GetStepIndexFromPlatformService);

    open(platformKey: AuthorizedPasswordManagedPlatformKeys): Observable<PasswordManagedConnectionModalResult | undefined> {
        const stepIndex$ = this._getStepIndexFromPlatformService.execute(platformKey, NOT_CONNECTED_STEP_INDEX, CONNECTED_STEP_INDEX);
        return stepIndex$.pipe(
            switchMap((stepIndex) => {
                const translations = this._getTranslations(platformKey);
                const baseTranslationKey = `platforms.connection_new.password_managed.${platformKey}`;
                return this._customDialogService
                    .open<
                        PlatformsConnectionParentStepperComponent,
                        ParentStepperComponentDialogData<PASSWORD_MANAGED_SHARED_DATA_TYPE, PasswordManagedConnectionModalResult>,
                        PasswordManagedConnectionModalResult
                    >(PlatformsConnectionParentStepperComponent, {
                        height: PARENT_STEPPER_COMPONENT_PREFERRED_HEIGHT,
                        width: PARENT_STEPPER_COMPONENT_PREFERRED_WIDTH,
                        data: {
                            sharedData: { platformKey },
                            steps: [
                                // @ts-ignore don't know how to resolve this issue, @cyril
                                createStep(this._translateService.instant(translations.step0), PlatformPresentationStepComponent, {
                                    translations: {
                                        title: `${baseTranslationKey}.step_0.step_name`,
                                        primaryButton: `${baseTranslationKey}.step_0.primary_button`,
                                        secondaryButton: `${baseTranslationKey}.step_0.secondary_button`,
                                        description: `${baseTranslationKey}.step_0.description`,
                                    },
                                }),
                                // @ts-ignore don't know how to resolve this issue, @cyril
                                createStep(this._translateService.instant(translations.step1), SelectBusinessStepComponent, {
                                    titleTranslationKey: `${baseTranslationKey}.step_1.step_name`,
                                }),
                                {
                                    stepName: this._translateService.instant(translations.step2),
                                    component: PasswordManagedConnectionStep2Component,
                                    componentInputs: {},
                                },
                            ],
                            stepperTitle: this._translateService.instant(translations.title),
                            stepperSubtitle: this._translateService.instant(translations.subtitle),
                            stepperPlatformKeyIcon: platformKey,
                            stepIndex: stepIndex,
                        },
                    })
                    .afterClosed();
            })
        );
    }

    private _getTranslations(platformKey: AuthorizedPasswordManagedPlatformKeys): PasswordManagedConnectionModalTranslations {
        const base = 'platforms.connection_new.password_managed';
        return {
            title: `${base}.${platformKey}.stepper.title`,
            subtitle: `${base}.${platformKey}.stepper.subtitle`,
            step0: `${base}.${platformKey}.step_0.step_name`,
            step1: `${base}.${platformKey}.step_1.step_name`,
            step2: `${base}.${platformKey}.step_2.step_name`,
        };
    }
}
