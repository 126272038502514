import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { forkJoin, map, of, switchMap } from 'rxjs';

import {
    aggregatedInsightsTabs,
    CsvInsightChart,
    HeapEventName,
    InsightsChart,
    InsightsTab,
    isNotNil,
    MalouComparisonPeriod,
    MonthYearPeriod,
    PlatformKey,
} from '@malou-io/package-utils';

import { UsersContext } from ':core/context/users.context';
import { DownloadService } from ':core/services/download.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { environment } from ':environments/environment';
import { InsightsService } from ':modules/statistics/insights.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { DownloadCsvInsightsService } from ':shared/components/download-insights-modal/download-csv-insights.service';
import { DownloadInsightsFooterPopinComponent } from ':shared/components/download-insights-modal/download-insights-footer-popin/download-insights-footer-popin.component';
import {
    chartByTab,
    ChartOptions,
    csvChartDataByTab,
    DownloadFormat,
    DownloadInsightsQueryParamParams,
    DownloadStatsFormData,
    DownloadType,
    FileExtension,
} from ':shared/components/download-insights-modal/download-insights.interface';
import { FooterPopinService } from ':shared/components/footer-popin/footer-popin.service';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { downloadFilesAsZip } from ':shared/helpers/download-files-as-zip';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

export interface DownloadInsightsModalData {
    tab: InsightsTab;
    filters: {
        platforms?: PlatformKey[];
        nfcIds?: string[];
        dates: { startDate: Date; endDate: Date };
        restaurantIds?: string[];
        comparisonPeriod?: MalouComparisonPeriod;
        monthYearPeriod?: MonthYearPeriod;
    };
    chartOptions?: ChartOptions;
}

const INSIGHTS_TAB_TO_STRING: Record<InsightsTab, string> = {
    [InsightsTab.SEO]: 'seo',
    [InsightsTab.SEO_KEYWORDS]: 'seo_keywords',
    [InsightsTab.SEO_IMPRESSIONS]: 'seo_impressions',
    [InsightsTab.E_REPUTATION]: 'e_reputation',
    [InsightsTab.SOCIAL_NETWORKS]: 'social_networks',
    [InsightsTab.BOOSTERS]: 'boosters',
    [InsightsTab.AGGREGATED_SEO]: 'aggregated_seo',
    [InsightsTab.AGGREGATED_E_REPUTATION]: 'aggregated_e_reputation',
    [InsightsTab.AGGREGATED_SOCIAL_NETWORKS]: 'aggregated_social_networks',
    [InsightsTab.AGGREGATED_BOOSTERS]: 'aggregated_boosters',
    [InsightsTab.E_REPUTATION_WITH_NEW_SEMANTIC_ANALYSIS]: 'e_reputation_with_new_semantic_analysis',
    [InsightsTab.AGGREGATED_SEO_KEYWORDS]: 'aggregated_seo_keywords',
    [InsightsTab.AGGREGATED_SEO_KEYWORDS_V2]: 'aggregated_seo_keywords_v2',
    [InsightsTab.AGGREGATED_SEO_IMPRESSIONS]: 'aggregated_seo_impressions',
};

@Component({
    selector: 'app-download-insights-modal',
    templateUrl: './download-insights-modal.component.html',
    styleUrls: ['./download-insights-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        ButtonComponent,
        SelectChipListComponent,
        SelectComponent,
    ],
})
export class DownloadInsightsModalComponent {
    private readonly _dialogRef = inject(MatDialogRef<DownloadInsightsModalComponent>);
    private readonly _formBuilder = inject(FormBuilder);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _insightsService = inject(InsightsService);
    private readonly _downloadService = inject(DownloadService);
    private readonly _footerPopinService = inject(FooterPopinService);
    private readonly _downloadCsvInsightsService = inject(DownloadCsvInsightsService);
    private readonly _heapService = inject(HeapService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _usersContext = inject(UsersContext);
    public readonly data: DownloadInsightsModalData = inject(MAT_DIALOG_DATA);

    readonly SvgIcon = SvgIcon;

    formGroup: INullableFormGroup<DownloadStatsFormData>;
    downloadFormatValues: DownloadFormat[] = Object.values(DownloadFormat);
    downloadTypeValues: DownloadType[] = Object.values(DownloadType);

    readonly chartControlName: WritableSignal<string> = signal('pdfCharts');
    readonly chartList: WritableSignal<InsightsChart[] | CsvInsightChart[]> = signal([]);
    readonly downloadFormat: WritableSignal<DownloadFormat> = signal(DownloadFormat.PDF);
    readonly downloadType: WritableSignal<DownloadType> = signal(DownloadType.PRECISE);

    readonly isDownloading = signal<boolean>(false);

    readonly isDownloadStatisticsResumeEnabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-download-statistics-resume'),
        {
            initialValue: false,
        }
    );

    readonly shouldShowDownloadTypeSelect = computed(
        () => this.isDownloadStatisticsResumeEnabled() && this.downloadFormat() === DownloadFormat.CSV
    );

    readonly shouldShowStatisticPageSections = computed(
        () => !this.isDownloadStatisticsResumeEnabled() || this.downloadType() === DownloadType.PRECISE
    );

    constructor() {
        this.chartList.set(chartByTab[this.data.tab]);
        this.formGroup = this._formBuilder.group({
            pdfCharts: new FormControl<InsightsChart[]>(chartByTab[this.data.tab], [Validators.required]),
            csvCharts: new FormControl<CsvInsightChart[]>(csvChartDataByTab[this.data.tab], [Validators.required]),
            downloadFormat: new FormControl<DownloadFormat>(DownloadFormat.PDF),
            downloadType: new FormControl<DownloadType>(DownloadType.SUMMARY),
        });
    }

    showFooter(): string {
        return this._footerPopinService.open(DownloadInsightsFooterPopinComponent, {});
    }

    hideFooter(footerId: string): void {
        this._footerPopinService.close(footerId);
    }

    onClose(): void {
        this._dialogRef.close();
    }

    chartsDisplayWith = (chart: InsightsChart | CsvInsightChart): string =>
        this.downloadFormat() === DownloadFormat.CSV
            ? this._enumTranslatePipe.transform(chart, 'csv_insights_chart')
            : this._enumTranslatePipe.transform(chart, 'insights_chart');

    downloadFormatDisplayWith = (downloadFormat: DownloadFormat): string =>
        this._enumTranslatePipe.transform(downloadFormat, 'download_insights_format');

    downloadTypeDisplayWith = (downloadType: DownloadType): string =>
        this._enumTranslatePipe.transform(downloadType, 'download_insights_type');

    onDownloadFormatChange(format: DownloadFormat): void {
        this.downloadFormat.set(format);
        this._resetForm();
        if (format === DownloadFormat.CSV) {
            this.chartControlName.set('csvCharts');
            this.chartList.set(csvChartDataByTab[this.data.tab]);
            this.downloadType.set(DownloadType.SUMMARY);
        } else {
            this.chartControlName.set('pdfCharts');
            this.chartList.set(chartByTab[this.data.tab]);
            this.downloadType.set(DownloadType.PRECISE);
        }
    }

    onDownloadTypeChange(type: DownloadType): void {
        this.downloadType.set(type);
    }

    onDownload(): void {
        const downloadFormat = this.formGroup.get('downloadFormat')?.value;
        if (downloadFormat === DownloadFormat.PDF) {
            this._downloadPdf({ isDevMode: !environment.production });
        } else {
            const downloadType = this.formGroup.get('downloadType')?.value;
            if (this.isDownloadStatisticsResumeEnabled() && downloadType === DownloadType.SUMMARY) {
                this._downloadSummaryCsv();
            } else {
                this._downloadPreciseCsv();
            }
        }
    }

    private _resetForm(): void {
        this.formGroup.get('pdfCharts')?.setValue(chartByTab[this.data.tab]);
        this.formGroup.get('csvCharts')?.setValue(csvChartDataByTab[this.data.tab]);
        this.formGroup.get('downloadType')?.setValue(DownloadType.SUMMARY);
    }

    private _buildCallbackUrlAndParams(): { callbackUrl: string; params: string } | null {
        const restaurantId = this._restaurantService.currentRestaurant?._id;
        const rawFormData = this.formGroup.getRawValue();
        const queryParams: DownloadInsightsQueryParamParams = {
            displayedCharts: rawFormData.pdfCharts ?? [],
            chartOptions: this.data.chartOptions,
            dates: {
                startDate: this.data.filters.dates.startDate.toISOString(),
                endDate: this.data.filters.dates.endDate.toISOString(),
            },
            platformKeys: this.data.filters.platforms,
            restaurantIds: this.data.filters.restaurantIds,
            nfcIds: this.data.filters.nfcIds,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            comparisonPeriod: this.data.filters.comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
            monthYearPeriod: this.data.filters.monthYearPeriod,
        };

        let callbackUrl: string | null = null;
        switch (this.data.tab) {
            case InsightsTab.SEO:
                callbackUrl = `statistics-pdf/${restaurantId}/seo`;
                break;
            case InsightsTab.SEO_KEYWORDS:
                callbackUrl = `statistics-pdf/${restaurantId}/seo/keywords`;
                break;
            case InsightsTab.SEO_IMPRESSIONS:
                callbackUrl = `statistics-pdf/${restaurantId}/seo/impressions`;
                break;
            case InsightsTab.E_REPUTATION:
            case InsightsTab.E_REPUTATION_WITH_NEW_SEMANTIC_ANALYSIS:
                callbackUrl = `statistics-pdf/${restaurantId}/e-reputation`;
                break;
            case InsightsTab.SOCIAL_NETWORKS:
                callbackUrl = `statistics-pdf/${restaurantId}/social-networks`;
                break;
            case InsightsTab.BOOSTERS:
                callbackUrl = `statistics-pdf/${restaurantId}/boosters`;
                break;
            case InsightsTab.AGGREGATED_SEO:
            case InsightsTab.AGGREGATED_SEO_KEYWORDS:
            case InsightsTab.AGGREGATED_SEO_KEYWORDS_V2:
            case InsightsTab.AGGREGATED_SEO_IMPRESSIONS:
                callbackUrl = 'aggregated-statistics-pdf/seo';
                break;
            case InsightsTab.AGGREGATED_E_REPUTATION:
                callbackUrl = 'aggregated-statistics-pdf/e-reputation';
                break;
            case InsightsTab.AGGREGATED_SOCIAL_NETWORKS:
                callbackUrl = 'aggregated-statistics-pdf/social-networks';
                break;
            case InsightsTab.AGGREGATED_BOOSTERS:
                callbackUrl = 'aggregated-statistics-pdf/boosters';
                break;
            default:
                callbackUrl = null;
                break;
        }
        if (!callbackUrl) {
            return null;
        }
        return { callbackUrl, params: JSON.stringify(queryParams) };
    }

    private _downloadPdf({ isDevMode }: { isDevMode?: boolean } = { isDevMode: false }): void {
        const callbackUrlAndParams = this._buildCallbackUrlAndParams();
        const jwtToken = LocalStorage.getItem(LocalStorageKey.JWT_TOKEN);
        if (!callbackUrlAndParams || !jwtToken) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
            return;
        }

        if (isDevMode) {
            LocalStorage.setItem(LocalStorageKey.DOWNLOAD_INSIGHTS_PARAMS, callbackUrlAndParams.params);
            window.open(`${callbackUrlAndParams.callbackUrl}`, '_blank');
            return;
        }

        const footerId = this.showFooter();
        this.isDownloading.set(true);
        this.formGroup.disable();
        this._insightsService
            .getInsightsPdfUrl({
                ...callbackUrlAndParams,
                jwtToken,
                restaurantId: this._restaurantService.currentRestaurant?._id,
                insightTab: this.data.tab,
            })
            .pipe(switchMap((pdfUrl) => this._downloadService.downloadFile(pdfUrl)))
            .subscribe({
                next: (blob) => {
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    const suffix = this._getDatesSuffix(this.data.filters);
                    const filename = this._getInsightTabFilename(this.data.tab, suffix, FileExtension.PDF);
                    saveAs(blob, filename);

                    this._heapService.track(HeapEventName.DOWNLOAD_INSIGHTS, {
                        tab: INSIGHTS_TAB_TO_STRING[this.data.tab],
                        restaurantIds: this._getRestaurantIdsForHeapEvent(),
                        userEmail: this._usersContext.currentUser()?.email ?? '',
                        userId: this._usersContext.currentUser()?.id ?? '',
                        charts: this.formGroup.get('pdfCharts')?.value?.join(','),
                        format: DownloadFormat.PDF,
                    });
                },
                error: () => {
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                },
            });
        this.onClose();
    }

    private _downloadSummaryCsv(): void {
        const footerId = this.showFooter();
        this.isDownloading.set(true);
        this.formGroup.disable();
        this._downloadCsvInsightsService
            .getCsvInsightsData$(CsvInsightChart.INSIGHTS_SUMMARY)
            .pipe(
                map((csvStringData) => {
                    if (!csvStringData || !csvStringData.length) {
                        return null;
                    }
                    return new Blob([csvStringData], { type: 'text/csv;charset=UTF-8' });
                })
            )
            .subscribe({
                next: (res) => {
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    if (!res) {
                        this._toastService.openErrorToast(this._translateService.instant('common.error'));
                        return;
                    }
                    const suffix = this._getDatesSuffix(this.data.filters);
                    const zipFilename = this._getInsightTabFilename(this.data.tab, suffix, FileExtension.ZIP);
                    void downloadFilesAsZip(
                        [res],
                        [this._getCsvInsightsChartFilename(CsvInsightChart.INSIGHTS_SUMMARY, suffix, FileExtension.CSV)],
                        zipFilename
                    );
                    // TODO: add later [@hamza]
                    // this._heapService.track(HeapEventName.DOWNLOAD_INSIGHTS, {
                    //     tab: this.data.tab,
                    //     restaurantIds: this._getRestaurantIdsForHeapEvent(),
                    //     userEmail: this._usersContext.currentUser()?.email ?? '',
                    //     userId: this._usersContext.currentUser()?.id ?? '',
                    //     charts: this.formGroup.get('csvCharts')?.value,
                    //     format: DownloadFormat.CSV,
                    // });
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.hideFooter(footerId);
                    this.isDownloading.set(false);
                    this.formGroup.enable();
                    this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                },
            });
        this.onClose();
    }

    private _downloadPreciseCsv(): void {
        const rawFormData = this.formGroup.getRawValue();
        const csvCharts = rawFormData.csvCharts as CsvInsightChart[];
        const footerId = this.showFooter();
        this.isDownloading.set(true);
        this.formGroup.disable();
        forkJoin(
            csvCharts.map((chart) =>
                forkJoin({
                    csvChart: of(chart),
                    blob: this._downloadCsvInsightsService.getCsvInsightsData$(chart).pipe(
                        map((csvStringData) => {
                            if (!csvStringData || !csvStringData.length) {
                                return null;
                            }
                            return new Blob([csvStringData], { type: 'text/csv;charset=UTF-8' });
                        })
                    ),
                })
            )
        ).subscribe({
            next: (res) => {
                this.hideFooter(footerId);
                this.isDownloading.set(false);
                this.formGroup.enable();
                const validRes = res.filter((r) => !!r.blob);
                if (!validRes.length) {
                    this._toastService.openErrorToast(this._translateService.instant('common.error'));
                    return;
                }
                const suffix = this._getDatesSuffix(this.data.filters);
                const validBlobs = validRes.map((r) => r.blob).filter(isNotNil);
                const filenames = validRes.map((r) => this._getCsvInsightsChartFilename(r.csvChart, suffix, FileExtension.CSV));
                const zipFilename = this._getInsightTabFilename(this.data.tab, suffix, FileExtension.ZIP);
                void downloadFilesAsZip(validBlobs, filenames, zipFilename);

                this._heapService.track(HeapEventName.DOWNLOAD_INSIGHTS, {
                    tab: INSIGHTS_TAB_TO_STRING[this.data.tab],
                    restaurantId: this._restaurantService.currentRestaurant?._id,
                    charts: this.formGroup.get('csvCharts')?.value?.join(','),
                    format: DownloadFormat.CSV,
                });
            },
            error: (err) => {
                console.warn('err :>>', err);
                this.hideFooter(footerId);
                this.isDownloading.set(false);
                this.formGroup.enable();
                this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
            },
        });
        this.onClose();
    }

    private _getInsightTabFilename(tab: InsightsTab, suffix: string, extension: string): string {
        const base = this._enumTranslatePipe.transform(tab, 'insights_tab_filename');
        if (aggregatedInsightsTabs.includes(tab)) {
            return `${base}${suffix}.${extension}`;
        } else {
            const restaurantName = this._restaurantService.currentRestaurant?.name;
            return `${base}_${restaurantName}${suffix}.${extension}`;
        }
    }

    private _getCsvInsightsChartFilename(chart: CsvInsightChart, suffix: string, extension: string): string {
        const base = this._enumTranslatePipe.transform(chart, 'csv_insights_chart_filename');
        return `${base}${suffix}.${extension}`;
    }

    private _getDatesSuffix(filters: DownloadInsightsModalData['filters']): string {
        const startDateFormatted = filters.dates.startDate ? DateTime.fromJSDate(filters.dates.startDate).toISODate() : '-';
        const endDateFormatted = filters.dates.endDate ? DateTime.fromJSDate(filters.dates.startDate).toISODate() : '-';
        return `_${startDateFormatted}_${endDateFormatted}`;
    }

    private _getRestaurantIdsForHeapEvent(): string {
        const restaurantIds = this.data.filters.restaurantIds;
        if (restaurantIds && restaurantIds.length > 0) {
            return restaurantIds.join(',');
        }
        return this._restaurantService.currentRestaurant?._id ?? '';
    }
}
