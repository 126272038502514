import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { BoostersPresentationComponent } from ':modules/boosters/boosters-presentation/boosters-presentation.component';

import { AggregatedBoostersComponent } from './aggregated-boosters.component';
import { WheelsOfFortuneComponent } from './wheels-of-fortune/wheels-of-fortune.component';

export const AGGREGATED_BOOSTERS_ROUTES: Routes = [
    {
        path: '',
        component: AggregatedBoostersComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'wheels-of-fortune',
            },
            {
                path: 'wheels-of-fortune',
                component: WheelsOfFortuneComponent,
                data: { routePath: RoutePath.WHEEL_OF_FORTUNE },
            },
            {
                path: 'presentation',
                component: BoostersPresentationComponent,
                data: { routePath: RoutePath.BOOSTERS_PRESENTATION },
            },
        ],
    },
];
