import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, Input, input, OnInit, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DateTime } from 'luxon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { map, Subject, switchMap, takeUntil } from 'rxjs';

import { MediaType, PlatformDefinitions, PlatformKey, PostPublicationStatus, PostSource, PostType } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PostsService } from ':core/services/posts.service';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Pagination } from ':shared/models';
import { Post, SocialPost } from ':shared/models/post';

import { SocialPostMediaComponent } from '../social-post-media/social-post-media.component';

export type StaticFeedPost = Pick<
    Post,
    'attachments' | '_id' | 'keys' | 'thumbnail' | 'thumbnailOffsetTimeInMs' | 'postType' | 'isReelDisplayedInFeed'
>;

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-static-feed',
    templateUrl: './static-feed.component.html',
    styleUrls: ['./static-feed.component.scss'],
    standalone: true,
    imports: [NgClass, CdkDropListGroup, InfiniteScrollModule, SocialPostMediaComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticFeedComponent implements OnInit {
    @Input() restaurantId: string;
    readonly currentPost = input.required<Partial<StaticFeedPost>>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly startCurrentPostSubscription$ = new Subject<void>();
    readonly loadMorePosts$: Subject<void> = new Subject();

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');
    readonly MediaType = MediaType;

    pagination: Pagination = {
        pageNumber: 0,
        pageSize: 30,
        total: 0,
    };
    posts = signal<SocialPost[]>([]);
    thumbnailUrlsForPosts = signal<
        Record<string, { post: SocialPost; url: string | undefined; dimensions?: { width: number; height: number } } | undefined>
    >({});

    currentPost$ = toObservable(this.currentPost);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly _FAR_PAST = DateTime.now().minus({ years: 2 }).toJSDate();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly _FAR_FUTURE = DateTime.now().plus({ years: 1 }).toJSDate();

    readonly isFeed45Enabled = toSignal(this._experimentationService.isFeatureEnabled$('release-feed-4-5'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-feed-4-5'),
    });

    constructor(
        private readonly _postsService: PostsService,
        private readonly _experimentationService: ExperimentationService
    ) {
        effect(
            () => {
                const thumbnailUrlsForPosts = this.thumbnailUrlsForPosts();

                for (const post of this.posts()) {
                    let shouldRefreshUrl = false;
                    const entry = thumbnailUrlsForPosts[post.id];
                    if (entry) {
                        const oldPost = entry.post;
                        shouldRefreshUrl =
                            oldPost.thumbnail !== post.thumbnail || oldPost.thumbnailOffsetTimeInMs !== post.thumbnailOffsetTimeInMs;
                    } else {
                        shouldRefreshUrl = true;
                        this.thumbnailUrlsForPosts.update((urls) => ({
                            ...urls,
                            [post.id]: { post, url: undefined },
                        }));
                    }

                    if (shouldRefreshUrl) {
                        post.getThumbnailUrlAndDimensions().then((res) => {
                            if (res) {
                                this.thumbnailUrlsForPosts.update((urls) => ({
                                    ...urls,
                                    [post.id]: { post, url: res?.url, dimensions: res?.dimensions },
                                }));
                            }
                        });
                    }
                }
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.loadMorePosts$
            .pipe(
                switchMap(() =>
                    this._postsService
                        .getRestaurantPostsPaginated(this.restaurantId, this.pagination, {
                            startDate: this._FAR_PAST,
                            endDate: this._FAR_FUTURE,
                            platforms: PlatformDefinitions.getPlatformKeysWithFeed(),
                            category: PostSource.SOCIAL,
                            source: PostSource.SOCIAL,
                            publicationStatus: [PostPublicationStatus.PUBLISHED, PostPublicationStatus.PENDING],
                            isStory: false,
                        })
                        .pipe(
                            map((res) => res.data?.posts?.map((p) => new SocialPost(p))),
                            takeUntil(this.killSubscriptions$)
                        )
                )
            )
            .subscribe((posts) => {
                this.posts.update((prev) => [...prev, ...posts]);
                this.startCurrentPostSubscription$.next();
            });

        this.loadMorePosts$.next();

        this.startCurrentPostSubscription$.pipe(switchMap(() => this.currentPost$)).subscribe((val) => {
            if (!val.keys?.includes(PlatformKey.INSTAGRAM) || (val.postType === PostType.REEL && !val.isReelDisplayedInFeed)) {
                this.posts.update((prev) => prev.filter((p) => p._id !== val._id));
                return;
            }
            const post = new SocialPost(val);
            const index = this.posts().findIndex((p) => p._id === val._id);
            if (index >= 0) {
                this.posts.update((oldPosts) => {
                    const newPosts = [...oldPosts];
                    newPosts[index] = post;
                    return newPosts;
                });
            } else {
                this.posts.update((prev) => [post, ...prev]);
            }
        });
    }

    scrolledDown(): void {
        this.pagination = { ...this.pagination, pageNumber: this.pagination.pageNumber + 1 };
        this.loadMorePosts$.next();
    }

    refreshPost(post: SocialPost): void {
        this._postsService
            .refresh(post?._id)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (freshPost) => {
                    this.posts.update((prev) =>
                        prev.map((p) => {
                            if (p._id === freshPost._id) {
                                p.refreshData({ ...freshPost });
                            }
                            return p;
                        })
                    );
                },
            });
    }
}
