import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { Role } from '@malou-io/package-utils';

import { ChatbotService } from ':core/services/chatbot.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import { selectUnansweredCommentCount, selectUnansweredMentionCount } from ':modules/comments/store/comments.reducer';
import { selectUnreadConversations } from ':modules/messages/messages.reducer';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { ReferYourFriendsComponent } from ':shared/components/refer-your-friends/refer-your-friends.component';
import { SidenavToggleButtonComponent } from ':shared/components/sidenav-toggle-button/sidenav-toggle-button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ConcatPipe } from ':shared/pipes/concat.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { SidenavContentAggregatedInsightsRoutesComponent } from './routes/sidenav-content-aggregated-insights-routes/sidenav-content-aggregated-insights-routes.component';
import { SidenavContentBoostersRoutesComponent } from './routes/sidenav-content-boosters-routes/sidenav-content-boosters-routes.component';
import { SidenavContentEReputationRoutesComponent } from './routes/sidenav-content-e-reputation-routes/sidenav-content-e-reputation-routes.component';
import { SidenavContentInsightsRoutesComponent } from './routes/sidenav-content-insights-routes/sidenav-content-insights-routes.component';
import { SidenavContentInteractionsRoutesComponent } from './routes/sidenav-content-interactions-routes/sidenav-content-interactions-routes.component';
import { SidenavContentResourcesRoutesComponent } from './routes/sidenav-content-resources-routes/sidenav-content-resources-routes.component';
import { SidenavContentSeoRoutesComponent } from './routes/sidenav-content-seo-routes/sidenav-content-seo-routes.component';
import { SidenavContentSettingsRoutesComponent } from './routes/sidenav-content-settings-routes/sidenav-content-settings-routes.component';
import { SidenavContentSocialNetworksRoutesComponent } from './routes/sidenav-content-social-networks-routes/sidenav-content-social-networks-routes.component';
import { SidenavContentRouteComponent } from './sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';
import { SidenavContentSelectRestaurantComponent } from './sidenav-content-select-restaurant/sidenav-content-select-restaurant.component';

@Component({
    selector: 'app-sidenav-content',
    templateUrl: './sidenav-content.component.html',
    styleUrls: ['./sidenav-content.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgClass,
        ImagePathResolverPipe,
        TranslateModule,
        SidenavToggleButtonComponent,
        SidenavContentRouteComponent,
        SidenavContentSelectRestaurantComponent,
        ConcatPipe,
        MatIconModule,
        ReferYourFriendsComponent,
        SidenavContentAggregatedInsightsRoutesComponent,
        SidenavContentSeoRoutesComponent,
        SidenavContentBoostersRoutesComponent,
        SidenavContentEReputationRoutesComponent,
        SidenavContentSocialNetworksRoutesComponent,
        SidenavContentInteractionsRoutesComponent,
        SidenavContentInsightsRoutesComponent,
        SidenavContentResourcesRoutesComponent,
        SidenavContentSettingsRoutesComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentComponent {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _chatbotService = inject(ChatbotService);
    private readonly _router = inject(Router);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly ownRestaurants = this._store.selectSignal(selectOwnRestaurants);
    readonly selectedRestaurant = toSignal(this._restaurantsService.restaurantSelected$);
    readonly isRestaurantsListRoute = toSignal(
        this._router.events.pipe(
            map((event) =>
                event instanceof NavigationEnd
                    ? event.url.includes('/restaurants/list') || event.urlAfterRedirects.includes('/restaurants/list')
                    : false
            )
        )
    );
    readonly isAllRestaurantsSelected = computed(() => !this.selectedRestaurant());
    readonly isBrandBusiness = computed(() => !!this.selectedRestaurant()?.isBrandBusiness());

    readonly unreadConversationCount = this._store.selectSignal(selectUnreadConversations);
    readonly unreadCommentsCount = this._store.selectSignal(selectUnansweredCommentCount);
    readonly unreadMentionsCount = this._store.selectSignal(selectUnansweredMentionCount);
    readonly hasOneRestaurantWithBoosterPackActivated: Signal<boolean> = computed(() =>
        this.ownRestaurants().some((restaurant) => restaurant.boosterPack?.activated)
    );

    readonly doesCurrentLangSupportChatbot = signal<boolean>(this._chatbotService.chatbotAcceptedLangs.includes(LocalStorage.getLang()));

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    readonly isSidenavOpened = this._store.selectSignal(SidenavSelectors.selectIsOpened);

    readonly SvgIcon = SvgIcon;
    readonly userInfos = this._store.selectSignal(selectUserInfos);
    readonly Role = Role;
    readonly christmasLogo = toSignal(this._experimentationService.isFeatureEnabled$('kill-switch-christmas'));

    readonly logo = computed(() =>
        this.christmasLogo() && this.isSidenavOpened()
            ? 'malou_logo_noel'
            : this.isSidenavOpened()
              ? 'malou_logo_black'
              : 'malou_initial_logo'
    );

    readonly isFeatureBoostersV2Enabled = toSignal(this._experimentationService.isFeatureEnabled$('release-boosters-v2'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-boosters-v2'),
    });

    onNeedHelpButtonClick(): void {
        this._chatbotService.show();
    }
}
