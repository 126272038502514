import { z } from 'zod';

import {
    BusinessCategory,
    CaslRole,
    containsForbiddenDomains,
    PlatformAccessStatus,
    PlatformAccessType,
    PlatformKey,
} from '@malou-io/package-utils';

import { objectIdValidator, urlValidator } from '../utils/validators';
import { addressDtoValidator } from './address/address.dto';
import { bookmarkedPostValidator } from './bookmarkedPost/bookmarkedPost.dto';
import { currentStateDtoValidator } from './currentState/currentState.dto';
import { descriptionDtoValidator } from './description/description.dto';
import { otherHourValidator, regularHourValidator, specialHourValidator } from './hours/hours.dto';
import { latlngDtoValidator } from './latlng/latlng.dto';
import { phoneDtoValidator } from './phone/phone.dto';
import { socialNetworkUrlsValidator } from './social-networks/social-networks.dto';

// ------------------------------------------------------------------------------------------
export const getRestaurantsForUserQueryValidator = z.object({
    fields: z.string(),
});
export type GetRestaurantsForUserQueryDto = z.infer<typeof getRestaurantsForUserQueryValidator>;
// ------------------------------------------------------------------------------------------

export const searchRestaurantsQueryValidator = z.object({
    text: z.string().min(1),
});
export type SearchRestaurantsQueryDto = z.infer<typeof searchRestaurantsQueryValidator>;
// ------------------------------------------------------------------------------------------

export const getAllRestaurantsQueryValidator = z
    .object({
        fields: z.array(z.string()),
        active: z.string().optional(),
    })
    .transform((data) => ({
        fields: data.fields,
        active: data.active === 'true',
    }));
export type GetAllRestaurantsQueryDto = z.infer<typeof getAllRestaurantsQueryValidator>;
// ------------------------------------------------------------------------------------------

export const checkRestaurantIdInParamsValidator = z
    .object({
        restaurant_id: z.string(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type CheckRestaurantIdInParamsDto = z.infer<typeof checkRestaurantIdInParamsValidator>;

export type PlatformData = z.infer<typeof platformDataValidator>;

// ------------------------------------------------------------------------------------------

export const createPlatformAccessParamsValidator = z
    .object({
        restaurant_id: z.string(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export const createPlatformAccessBodyValidator = z.object({
    data: z.object({
        platformKey: z.nativeEnum(PlatformKey),
        status: z.nativeEnum(PlatformAccessStatus),
        accessType: z.nativeEnum(PlatformAccessType),
        lastUpdated: z
            .string()
            .optional()
            .transform((data) => (data ? new Date(data) : undefined)),
        lastVerified: z
            .string()
            .optional()
            .transform((data) => (data ? new Date(data) : undefined)),
        active: z.boolean().optional(),
        data: z
            .object({
                login: z.string(),
                password: z.string().optional(),
            })
            .optional(),
    }),
});

export type CreatePlatformAccessParamsDto = z.infer<typeof createPlatformAccessParamsValidator>;
export type CreatePlatformAccessBodyDto = z.infer<typeof createPlatformAccessBodyValidator>;

// ------------------------------------------------------------------------------------------

export const updatePlatformAccessStatusParamsValidator = z
    .object({
        restaurant_id: z.string(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export const updatePlatformAccessStatusBodyValidator = z.object({
    platformKey: z.nativeEnum(PlatformKey),
    status: z.nativeEnum(PlatformAccessStatus),
    active: z.boolean(),
    accessType: z.nativeEnum(PlatformAccessType),
});

export type UpdatePlatformAccessStatusParamsDto = z.infer<typeof updatePlatformAccessStatusParamsValidator>;
export type UpdatePlatformAccessStatusBodyDto = z.infer<typeof updatePlatformAccessStatusBodyValidator>;
// ------------------------------------------------------------------------------------------

export const handleShowPlatformAccessParamsValidator = z
    .object({
        restaurant_id: z.string(),
        platform_key: z.nativeEnum(PlatformKey),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        platformKey: data.platform_key,
    }));
export type HandleShowPlatformAccessParamsDto = z.infer<typeof handleShowPlatformAccessParamsValidator>;

// ------------------------------------------------------------------------------------------

export const addRestaurantForUserWithEmailParamsValidator = z
    .object({
        restaurant_id: z.string(),
        user_id: z.string(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        userId: data.user_id,
    }));
export const addRestaurantForUserWithEmailBodyValidator = z.object({
    caslRole: z.nativeEnum(CaslRole),
});

export type AddRestaurantForUserWithEmailParamsDto = z.infer<typeof addRestaurantForUserWithEmailParamsValidator>;
export type AddRestaurantForUserWithEmailBodyDto = z.infer<typeof addRestaurantForUserWithEmailBodyValidator>;

// ------------------------------------------------------------------------------------------

export const addRestaurantsForUserWithEmailBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    caslRole: z.nativeEnum(CaslRole),
});

export const addRestaurantsForUserWithEmailParamsValidator = z
    .object({
        user_id: objectIdValidator,
    })
    .transform((data) => ({
        userId: data.user_id,
    }));

export type AddRestaurantsForUserWithEmailBodyDto = z.infer<typeof addRestaurantsForUserWithEmailBodyValidator>;
export type AddRestaurantsForUserWithEmailParamsDto = z.infer<typeof addRestaurantsForUserWithEmailParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantsForUserWithEmailParamsValidator = addRestaurantsForUserWithEmailParamsValidator;
export type GetRestaurantsForUserWithEmailParamsDto = z.infer<typeof getRestaurantsForUserWithEmailParamsValidator>;

// ------------------------------------------------------------------------------------------
export const updateRestaurantsForUserWithEmailBodyValidator = addRestaurantsForUserWithEmailBodyValidator;
export const updateRestaurantsForUserWithEmailParamsValidator = addRestaurantsForUserWithEmailParamsValidator;
export type updateRestaurantsForUserWithEmailBodyDto = z.infer<typeof updateRestaurantsForUserWithEmailBodyValidator>;
export type updateRestaurantsForUserWithEmailParamsDto = z.infer<typeof updateRestaurantsForUserWithEmailParamsValidator>;

// ------------------------------------------------------------------------------------------

export const removeUserRestaurantsBodyValidator = z.object({
    restaurantsIds: z.array(z.string()),
});
export type RemoveUserRestaurantsBodyDto = z.infer<typeof removeUserRestaurantsBodyValidator>;
// ------------------------------------------------------------------------------------------

// to be honest, idk why this payload is so big because we refetch the data in the use case ...
// cf getLocationDataForPlatform()
const gmbRestaurantValidator = z.object({
    socialId: z.string(),
    name: z.string(),
    formattedAddress: z.string(),
    address: z.object({
        regionCode: z.string(),
        country: z.string(),
        postalCode: z.string().nullish(), // example: Emirates doesn't have postal code
        locality: z.string(),
        formattedAddress: z.string(),
    }),
    socialUrl: z.string(),
    accountId: z.string(),
    accountName: z.string(),
    access: z.object({ isValid: z.boolean(), missing: z.array(z.string()) }),
    apiEndpointV2: z.string(),
    locationId: z.string(),
    type: z.literal(BusinessCategory.LOCAL_BUSINESS),
    attributes: z
        .array(
            z.union([
                z.object({
                    name: z.string(),
                    valueType: z.string(),
                    values: z.array(z.boolean()),
                }),
                z.object({
                    name: z.string(),
                    valueType: z.string(),
                    uriValues: z.array(z.object({ uri: z.string() })),
                }),
                z.object({
                    name: z.string(),
                    valueType: z.string(),
                    values: z.array(z.string()),
                }),
                z.object({
                    name: z.string(),
                    valueType: z.string(),
                    repeatedEnumValue: z.object({ setValues: z.array(z.string()).optional(), unsetValues: z.array(z.string()).optional() }),
                }),
            ])
        )
        .optional(),
    credentialId: z.string(),
    organizationId: z.string(),
    fromForm: z.boolean().optional(),
    fromTests: z.boolean().optional(),
});
const facebookRestaurantValidator = z.object({
    socialId: z.string(),
    name: z.string(),
    formattedAddress: z.string().nullable(),
    socialUrl: z.string(),
    picture: z.string(),
    rating: z.number().nullable(),
    pageCategory: z.string(),
    hasTransitionedToNewPageExperience: z.boolean(),
    access: z.object({
        isValid: z.boolean(),
        missing: z.array(z.unknown()),
        dataExpiresAt: z.number(),
    }),
    type: z.literal(BusinessCategory.BRAND),
    credentialId: z.string(),
    organizationId: z.string(),
    fromForm: z.boolean().optional(),
    fromTests: z.boolean().optional(),
    accountId: z.string().optional(),
    apiEndpointV2: z.string().optional(),
});

export const createRestaurantBodyValidator = z.discriminatedUnion('type', [facebookRestaurantValidator, gmbRestaurantValidator]);

export type CreateRestaurantBodyDto = z.infer<typeof createRestaurantBodyValidator>;
// ------------------------------------------------------------------------------------------

export const platformDataValidator = z.object({
    name: z.string().optional(),
    type: z.string().optional(),
    placeId: z.string().optional(),
    socialId: z.string().optional(),
    address: z
        .object({
            streetNumber: z.string().optional(),
            route: z.string().optional(),
            country: z.string().optional(),
            regionCode: z.string().optional(),
            postalCode: z.string().optional(),
            locality: z.string().optional(),
            formattedAddress: z.string().optional(),
            administrativeArea: z.string().optional(),
        })
        .optional(),
    latlng: z
        .object({
            // string or number
            lat: z.any().optional(),
            lng: z.any().optional(),
        })
        .optional(),
    category: z.any().optional(),
    descriptions: z.array(z.any()).optional(),
    logo: z.any().optional(),
    cover: z.any().optional(),
    phone: phoneDtoValidator.nullish(),
    website: z.string().optional(),
    menuUrl: z.string().optional(),
    email: z.string().optional(),
    openingDate: z.any().optional(),
    regularHours: z.array(z.any()).optional(),
    specialHours: z.array(z.any()).optional(),
    otherHours: z.array(z.any()).optional(),
    availableHoursTypeIds: z.array(z.any()).optional(),
    isClosedTemporarily: z.boolean().optional(),
    menu: z.any().optional(),
    categoryList: z.array(z.any()).optional(),
    attributeList: z.array(z.any()).optional(),
    access: z.any().optional(),
    relatedUrls: z.array(z.any()).optional(),
});

// ------------------------------------------------------------------------------------------

export const updateRestaurantActiveBodyValidator = z.object({
    active: z.boolean(),
});
export type UpdateRestaurantActiveBodyDto = z.infer<typeof updateRestaurantActiveBodyValidator>;
// ------------------------------------------------------------------------------------------

export const updateRestaurantParamsValidator = z
    .object({
        restaurant_id: z.string(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export const updateRestaurantBodyValidator = z
    .object({
        duplicatedFromRestaurantId: objectIdValidator.nullish(),
        name: z.string().min(1).optional(),
        category: objectIdValidator.optional(),
        categoryList: z.array(objectIdValidator).optional(),
        internalName: z.string().optional(),
        address: addressDtoValidator.optional(),
        latlng: latlngDtoValidator.optional(),
        website: urlValidator({ allowEmpty: true }).optional(),
        menuUrl: urlValidator({ allowEmpty: true }).optional(),
        reservationUrl: urlValidator({ allowEmpty: true })
            .refine((reservationUrl) => !containsForbiddenDomains(reservationUrl, ['google.com']))
            .optional(),
        orderUrl: urlValidator({ allowEmpty: true }).optional(),
        socialNetworkUrls: socialNetworkUrlsValidator.optional(),
        phone: phoneDtoValidator.nullish(),
        openingDate: z
            .string()
            .datetime()
            .optional()
            .transform((data) => (data ? new Date(data) : undefined)),
        regularHours: z.array(regularHourValidator).optional(),
        specialHours: z.array(specialHourValidator).optional(),
        otherHours: z.array(otherHourValidator).optional(),
        availableHoursTypeIds: z.array(objectIdValidator).optional(),
        isClosedTemporarily: z.boolean().optional(),
        descriptions: z.array(descriptionDtoValidator).optional(),
        attributeList: z.array(z.any()).optional(),
        commentsLastUpdate: z
            .string()
            .datetime()
            .optional()
            .transform((data) => (data ? new Date(data) : undefined)),
        calendarEventsCountry: z.string().optional(),
        relatedUrls: z.array(z.string()).optional(),
        currentState: currentStateDtoValidator.optional(),
        logo: objectIdValidator.optional(),
        cover: objectIdValidator.optional(),
        logoChanged: z.boolean().optional(),
        coverChanged: z.boolean().optional(),
        bookmarkedPosts: z.array(bookmarkedPostValidator).optional(),
    })
    .strict();

export type UpdateRestaurantParamsDto = z.infer<typeof updateRestaurantParamsValidator>;
export type UpdateRestaurantBodyDto = z.infer<typeof updateRestaurantBodyValidator>;
// ------------------------------------------------------------------------------------------

export const adminUpdateRestaurantParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type AdminUpdateRestaurantParamsDto = z.infer<typeof adminUpdateRestaurantParamsValidator>;

export const adminUpdateRestaurantBodyValidator = z.object({
    organizationId: objectIdValidator.optional(),
    boosterPack: z
        .object({
            activated: z.boolean(),
        })
        .optional(),
    isYextActivated: z.boolean().optional(),
    roiActivated: z.boolean().optional(),
});
export type AdminUpdateRestaurantBodyDto = z.infer<typeof adminUpdateRestaurantBodyValidator>;
// ------------------------------------------------------------------------------------------
export const getRestaurantsByIdsQueryValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
});
export type GetRestaurantsByIdsQueryDto = z.infer<typeof getRestaurantsByIdsQueryValidator>;
// ------------------------------------------------------------------------------------------
export const yextToggleParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));

export type ActivateYextParamsDto = z.infer<typeof yextToggleParamsValidator>;
export type DeactivateYextParamsDto = z.infer<typeof yextToggleParamsValidator>;

// ------------------------------------------------------------------------------------------

export const handleGetOrganizationRestaurantsParamsValidator = z
    .object({
        organization_id: objectIdValidator,
    })
    .transform((data) => ({
        organizationId: data.organization_id,
    }));

export type HandleGetOrganizationRestaurantsParamsDto = z.infer<typeof handleGetOrganizationRestaurantsParamsValidator>;

// ------------------------------------------------------------------------------------------

export const getRestaurantCalendarEventsQueryValidator = z
    .object({
        after_date: z.coerce.date().optional(),
        before_date: z.coerce.date().optional(),
    })
    .transform((data) => ({ afterDate: data.after_date, beforeDate: data.before_date }));

export type GetRestaurantCalendarEventsQueryDto = z.infer<typeof getRestaurantCalendarEventsQueryValidator>;

// ------------------------------------------------------------------------------------------

export const duplicateSpecialHoursBodyValidator = z.object({
    specialHoursToDuplicate: z.array(specialHourValidator),
    restaurantIds: z.array(objectIdValidator),
});

export type DuplicateSpecialHoursBodyDto = z.infer<typeof duplicateSpecialHoursBodyValidator>;
