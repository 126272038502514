import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    Component,
    computed,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    QueryList,
    Signal,
    signal,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual, isInteger } from 'lodash';
import { combineLatest, distinctUntilChanged, EMPTY, fromEvent, Observable, switchMap, take } from 'rxjs';

import { HeapEventName, InsightsChart, InsightsTab, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { StatisticsSeoRatingsComponent } from ':modules/statistics/e-reputation/ratings/ratings.component';
import { ReviewAnalysesComponent } from ':modules/statistics/e-reputation/review-analyses/review-analyses.component';
import { ReviewsKpisComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis.component';
import { ReviewsRatingsEvolutionComponent } from ':modules/statistics/e-reputation/reviews-ratings-evolution/reviews-ratings-evolution.component';
import { ReviewsRatingsTotalComponent } from ':modules/statistics/e-reputation/reviews-ratings-total/reviews-ratings-total.component';
import { SemanticAnalysisComponent } from ':modules/statistics/e-reputation/semantic-analysis/semantic-analysis.component';
import { FiltersComponent } from ':modules/statistics/filters/filters.component';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions, StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum EReputationInsightsTabs {
    REVIEWS,
    SEMANTIC_ANALYSIS,
}

@Component({
    selector: 'app-statistics-e-reputation',
    templateUrl: './e-reputation.component.html',
    styleUrls: ['./e-reputation.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ReviewsKpisComponent,
        ReviewsRatingsEvolutionComponent,
        ReviewsRatingsTotalComponent,
        ReviewAnalysesComponent,
        FiltersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        MatTabsModule,
        StatisticsSeoRatingsComponent,
        SemanticAnalysisComponent,
    ],
})
export class EReputationComponent implements AfterViewInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;
    @ViewChildren('scrollContainer') scrollContainers: QueryList<ElementRef>;

    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _store = inject(Store);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _heapService = inject(HeapService);
    private readonly _router = inject(Router);
    public readonly screenSizeService = inject(ScreenSizeService);

    readonly PlatformFilterPage = PlatformFilterPage;
    readonly Illustration = Illustration;
    readonly InsightsChart = InsightsChart;
    readonly EReputationInsightsTabs = EReputationInsightsTabs;

    readonly isSeoRatingLoading = signal(true);
    readonly isReviewsKpisLoading = signal(true);
    readonly isReviewsRatingsEvolutionLoading = signal(true);
    readonly isReviewsRatingsTotalLoading = signal(true);
    readonly isReviewAnalysesLoading = signal(true);
    readonly selectedIndex = signal(EReputationInsightsTabs.REVIEWS);
    readonly semanticAnalysisSectionDisplayStartTime = signal<number>(0);
    readonly reviewsSectionDisplayStartTime = signal<number>(0);

    readonly isLoading = computed(
        () =>
            this.isSeoRatingLoading() ||
            this.isReviewsKpisLoading() ||
            this.isReviewsRatingsEvolutionLoading() ||
            this.isReviewsRatingsTotalLoading() ||
            this.isReviewAnalysesLoading()
    );

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)));
    readonly platformKeys: Signal<PlatformKey[]> = toSignal(this.platformKeys$, { initialValue: [] });
    readonly hasAtLeastOnePlatformConnected = computed(() => this.platformKeys().length > 0);

    readonly isNewSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-semantic-analysis'),
        { initialValue: false }
    );

    chartOptions: ChartOptions = {
        [InsightsChart.REVIEW_RATING_EVOLUTION]: {
            viewBy: ViewBy.WEEK,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.REVIEW_ANALYSES_TAG_EVOLUTION]: {
            viewBy: ViewBy.WEEK,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.SEMANTIC_ANALYSIS_TOPICS_EVOLUTION]: {
            viewBy: ViewBy.WEEK,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.REVIEW_RATING_TOTAL]: {
            viewMode: StatisticsDataViewMode.CHART,
            hiddenDatasetIndexes: [],
        },
    };

    constructor() {
        this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((params) => {
            const tab = params['tab'];
            const expectedValues = Object.values(EReputationInsightsTabs).map((value) => value.toString());
            if (tab && expectedValues.includes(tab.toUpperCase())) {
                if (isInteger(+tab)) {
                    this.selectedIndex.set(tab);
                } else {
                    this.selectedIndex.set(EReputationInsightsTabs[tab.toUpperCase() as keyof typeof EReputationInsightsTabs]);
                }
            }
            if (this.selectedIndex() === EReputationInsightsTabs.REVIEWS) {
                this.reviewsSectionDisplayStartTime.set(Date.now());
            } else if (this.selectedIndex() === EReputationInsightsTabs.SEMANTIC_ANALYSIS) {
                this.semanticAnalysisSectionDisplayStartTime.set(Date.now());
            }
        });

        effect(
            () => {
                const currentTabIndex = this.selectedIndex();
                this._router.navigate([], {
                    relativeTo: this._activatedRoute,
                    queryParams: { tab: currentTabIndex },
                });
            },
            { allowSignalWrites: true }
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.topOfComponent?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }));

        const scrollContainer = this.scrollContainers.find(
            (container) => container.nativeElement.id === EReputationInsightsTabs.SEMANTIC_ANALYSIS.toString()
        );
        fromEvent(scrollContainer?.nativeElement, 'scroll')
            .pipe(take(1))
            .subscribe(() => {
                const semanticAnalysisSectionDisplayStartTime = this.semanticAnalysisSectionDisplayStartTime();
                const durationBeforeFirstScroll = semanticAnalysisSectionDisplayStartTime
                    ? new Date().getTime() - semanticAnalysisSectionDisplayStartTime
                    : null;
                this._heapService.track(HeapEventName.TRACKING_SEMANTIC_ANALYSIS_SCROLLING, {
                    durationBeforeFirstScroll,
                });
            });
    }

    openStatisticsDownload(): void {
        combineLatest([this._store.select(StatisticsSelectors.selectDatesFilter), this.platformKeys$])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, platforms]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    const tab = this.isNewSemanticAnalysisFeatureEnabled()
                        ? InsightsTab.E_REPUTATION_WITH_NEW_SEMANTIC_ANALYSIS
                        : InsightsTab.E_REPUTATION;
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab,
                                filters: {
                                    dates: { startDate, endDate },
                                    platforms,
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewModeChange(chart: InsightsChart, value: StatisticsDataViewMode): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewMode: value,
            },
        };
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                viewBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    handleTabChange(event: number): void {
        switch (event) {
            case EReputationInsightsTabs.REVIEWS:
                this.reviewsSectionDisplayStartTime.set(Date.now());
                this._heapService.track(HeapEventName.TRACKING_CLICK_E_REPUTATION_INSIGHTS_REVIEWS_TAB);
                this.selectedIndex.set(EReputationInsightsTabs.REVIEWS);
                break;
            case EReputationInsightsTabs.SEMANTIC_ANALYSIS:
                const reviewsSectionDisplayStartTime = this.reviewsSectionDisplayStartTime();
                const durationBetweenTabSwitch = reviewsSectionDisplayStartTime ? Date.now() - reviewsSectionDisplayStartTime : 0;
                this._heapService.track(HeapEventName.TRACKING_CLICK_E_REPUTATION_INSIGHTS_SEMANTIC_ANALYSES_TAB, {
                    timeSpentOnReviewsBefore: durationBetweenTabSwitch,
                });
                this.selectedIndex.set(EReputationInsightsTabs.SEMANTIC_ANALYSIS);
                this.semanticAnalysisSectionDisplayStartTime.set(Date.now());
                break;
        }
    }
}
