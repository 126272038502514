import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil, round, toInteger } from 'lodash';

import { TimeInMilliseconds, TimeInMinutes } from '@malou-io/package-utils';

import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';
import { SummarySectionsData, SummarySectionsTranslationKeys } from ':shared/services/csv-services/insights/summary/summary.interface';

const DEFAULT_CSV_EMPTY_VALUE = 'N/A';

@Injectable({ providedIn: 'root' })
export class DataToCsvRowMapperService {
    constructor(
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _translate: TranslateService
    ) {}

    mapSeoSectionDataToCsvRows(currentData: SummarySectionsData['seo'], previousData: SummarySectionsData['seo']): CsvAsStringArrays {
        if (!currentData) {
            return [[]];
        }

        const impressionRows = Object.entries(currentData.impressions).map(([key, value]) => [
            this._getRowNameFromKey(`impressions.${key}`, 'seo'),
            this._formatNumber(value),
            this._formatNumber(previousData?.impressions[key]),
            this._formatNumber(this._computeEvolution(value, previousData?.impressions[key])),
        ]);

        const actionRows = Object.entries(currentData.actions).map(([key, value]) => {
            if (key === 'conversionRate') {
                return [
                    this._getRowNameFromKey(`actions.${key}`, 'seo'),
                    this._formatNumber(value, { isPercentage: true, shouldRound: true, round: 2 }),
                    this._formatNumber(previousData?.actions[key], { isPercentage: true, shouldRound: true, round: 2 }),
                    this._formatNumber(this._computeEvolution(value, previousData?.actions[key]), {
                        isPercentage: true,
                        shouldRound: true,
                        round: 2,
                    }),
                ];
            }
            return [
                this._getRowNameFromKey(`actions.${key}`, 'seo'),
                this._formatNumber(value),
                this._formatNumber(previousData?.actions[key]),
                this._formatNumber(this._computeEvolution(value, previousData?.actions[key])),
            ];
        });
        const otherRows = [
            [
                this._getRowNameFromKey('keywordsInTop20Count', 'seo'),
                this._formatNumber(currentData.keywordsInTop20Count),
                this._formatNumber(previousData?.keywordsInTop20Count),
                this._formatNumber(this._computeEvolution(currentData.keywordsInTop20Count, previousData?.keywordsInTop20Count)),
            ],
            [
                this._getRowNameFromKey('totalNotorietySearchCount', 'seo'),
                this._formatNumber(currentData.totalNotorietySearchCount),
                this._formatNumber(previousData?.totalNotorietySearchCount),
                this._formatNumber(this._computeEvolution(currentData.totalNotorietySearchCount, previousData?.totalNotorietySearchCount)),
            ],
            [
                this._getRowNameFromKey('totalDiscoverySearchCount', 'seo'),
                this._formatNumber(currentData.totalDiscoverySearchCount),
                this._formatNumber(previousData?.totalDiscoverySearchCount),
                this._formatNumber(this._computeEvolution(currentData.totalDiscoverySearchCount, previousData?.totalDiscoverySearchCount)),
            ],
        ];
        return [...impressionRows, ...actionRows, ...otherRows];
    }

    mapEReputationSectionDataToCsvRows(
        currentData: SummarySectionsData['eReputation'],
        previousData: SummarySectionsData['eReputation']
    ): CsvAsStringArrays {
        if (!currentData) {
            return [[]];
        }

        const notesRows = currentData.notes
            .filter((platformNote) => platformNote.note !== 1)
            .map(({ platform, note }) => [
                `Note ${this._enumTranslatePipe.transform(platform, 'platform_key')}`,
                this._formatNumber(note, { shouldRound: true, round: 2 }),
                this._formatNumber(previousData?.notes.find((n) => n.platform === platform)?.note),
                this._formatNumber(this._computeEvolution(note, previousData?.notes.find((n) => n.platform === platform)?.note), {
                    shouldRound: true,
                    round: 2,
                }),
            ]);

        const reviewsRows = [
            [
                this._getRowNameFromKey('reviews.totalCount', 'eReputation'),
                this._formatNumber(currentData.reviews.totalCount),
                this._formatNumber(previousData?.reviews.totalCount),
                this._formatNumber(this._computeEvolution(currentData.reviews.totalCount, previousData?.reviews.totalCount)),
            ],
            [
                this._getRowNameFromKey('reviews.averageRating', 'eReputation'),
                this._formatNumber(currentData.reviews.averageRating, { shouldRound: true, round: 2 }),
                this._formatNumber(previousData?.reviews.averageRating, { shouldRound: true, round: 2 }),
                this._formatNumber(this._computeEvolution(currentData.reviews.averageRating, previousData?.reviews.averageRating), {
                    shouldRound: true,
                    round: 2,
                }),
            ],
        ];

        const reviewsRatingsRows = Object.entries(currentData.reviewsRatings).map(([key, value]) => [
            'Star ' + toInteger(key),
            this._formatNumber(value),
            this._formatNumber(previousData?.reviewsRatings[key]),
            this._formatNumber(this._computeEvolution(value, previousData?.reviewsRatings[key])),
        ]);

        const reviewsResponseRows = [
            [
                this._getRowNameFromKey('reviewsResponse.rate', 'eReputation'),
                this._formatNumber(currentData.reviewsResponse.rate, { isPercentage: true, shouldRound: true, round: 2 }),
                this._formatNumber(previousData?.reviewsResponse.rate, { isPercentage: true, shouldRound: true, round: 2 }),
                this._formatNumber(this._computeEvolution(currentData.reviewsResponse.rate, previousData?.reviewsResponse.rate), {
                    isPercentage: true,
                    shouldRound: true,
                    round: 2,
                }),
            ],
            [
                this._getRowNameFromKey('reviewsResponse.averageTime', 'eReputation'),
                this._formatNumber(currentData.reviewsResponse.averageTime, { isTime: true }),
                this._formatNumber(previousData?.reviewsResponse.averageTime, { isTime: true }),
                this._formatNumber(
                    this._computeEvolution(currentData.reviewsResponse.averageTime, previousData?.reviewsResponse.averageTime),
                    { isTime: true }
                ),
            ],
        ];

        const sentimentsPercentageRows = [
            [
                this._getRowNameFromKey('sentimentsPercentage.positive', 'eReputation'),
                this._formatNumber(currentData.sentimentsPercentage.positive, { isPercentage: true, shouldRound: true, round: 2 }),
                this._formatNumber(previousData?.sentimentsPercentage.positive, { isPercentage: true, shouldRound: true, round: 2 }),
                this._formatNumber(
                    this._computeEvolution(currentData.sentimentsPercentage.positive, previousData?.sentimentsPercentage.positive),
                    { isPercentage: true, shouldRound: true, round: 2 }
                ),
            ],
            [
                this._getRowNameFromKey('sentimentsPercentage.negative', 'eReputation'),
                this._formatNumber(currentData.sentimentsPercentage.negative, { isPercentage: true, shouldRound: true, round: 2 }),
                this._formatNumber(previousData?.sentimentsPercentage.negative, { isPercentage: true, shouldRound: true, round: 2 }),
                this._formatNumber(
                    this._computeEvolution(currentData.sentimentsPercentage.negative, previousData?.sentimentsPercentage.negative),
                    { isPercentage: true, shouldRound: true, round: 2 }
                ),
            ],
        ];

        return [...notesRows, ...reviewsRows, ...reviewsRatingsRows, ...reviewsResponseRows, ...sentimentsPercentageRows];
    }

    private _getRowNameFromKey(translationKey: string, section: SummarySectionsTranslationKeys) {
        return this._enumTranslatePipe.transform(
            section,
            'csv_insights_field_names.summary',
            translationKey.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
        );
    }

    private _computeEvolution(currentValue: number | null, previousValue: number | null | undefined): number | null {
        if (currentValue === null) {
            return null;
        }
        if (isNil(previousValue)) {
            return currentValue;
        }
        return currentValue - previousValue;
    }

    private _formatNumber(
        value: number | null | undefined,
        options?: { isPercentage?: boolean; shouldRound?: boolean; round?: number; isTime?: boolean }
    ): string {
        if (isNil(value)) {
            return DEFAULT_CSV_EMPTY_VALUE;
        }
        let formattedNumber = value.toString();
        if (options?.isTime) {
            return this._formatTime(value);
        }
        if (options?.shouldRound) {
            formattedNumber = `${round(value, options.round ?? 0)}`;
        }
        if (options?.isPercentage) {
            formattedNumber = `${formattedNumber}%`;
        }
        return formattedNumber;
    }

    private _formatTime(value: number): string {
        const hours = Math.floor(value / TimeInMilliseconds.HOUR);
        const minutes = Math.floor((value / TimeInMilliseconds.MINUTE) % TimeInMinutes.HOUR);

        if (hours < 1 && minutes > 0) {
            return minutes + this._translate.instant('common.minutes_short');
        }

        if (hours === 0 && minutes === 0) {
            return '0' + this._translate.instant('common.hours_short');
        }

        const min = minutes ? minutes + this._translate.instant('common.minutes_short') : '';
        return hours + this._translate.instant('common.hours_short') + ' ' + min;
    }
}
