import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { DatePipe, NgClass, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, effect, input, output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlatformAccessStatus, PlatformAccessType, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { Address, PlatformAccess } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export enum AccessStatusGroupName {
    ERROR = 'error',
    SUCCESS = 'success',
    PENDING = 'pending',
}

interface GroupAccessStatus {
    name: AccessStatusGroupName;
    accessStatuses: PlatformAccessStatus[];
}

const DEFAULT_ERROR_ACCESS_STATUS_GROUP = {
    name: AccessStatusGroupName.ERROR,
    accessStatuses: [PlatformAccessStatus.BAD_ACCESS, PlatformAccessStatus.INVALID_PAGE, PlatformAccessStatus.UNCLAIMED_PAGE],
};

const DEFAULT_SUCCESS_ACCESS_STATUS_GROUP = {
    name: AccessStatusGroupName.SUCCESS,
    accessStatuses: [PlatformAccessStatus.VERIFIED],
};

const DEFAULT_PENDING_ACCESS_STATUS_GROUP = {
    name: AccessStatusGroupName.PENDING,
    accessStatuses: [PlatformAccessStatus.NEED_REVIEW],
};

export const DEFAULT_ACCESS_STATUS_GROUPS: GroupAccessStatus[] = [
    DEFAULT_ERROR_ACCESS_STATUS_GROUP,
    DEFAULT_PENDING_ACCESS_STATUS_GROUP,
    DEFAULT_SUCCESS_ACCESS_STATUS_GROUP,
];

@Component({
    selector: 'app-access',
    templateUrl: './access.component.html',
    styleUrls: ['./access.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ClipboardModule,
        FormsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        TranslateModule,
        PlatformLogoComponent,
        ApplyPurePipe,
        DatePipe,
        EnumTranslatePipe,
        ImagePathResolverPipe,
        TitleCasePipe,
        RouterLink,
    ],
})
export class AccessComponent {
    readonly accessList = input.required<PlatformAccess[]>();
    readonly isBusinessView = input.required<boolean>();
    readonly allAccessList = input.required<PlatformAccess[]>();

    readonly SvgIcon = SvgIcon;

    readonly onTogglePlatformAccess = output<{
        restaurantId: string;
        element: PlatformAccess;
        status: PlatformAccessStatus;
    }>();

    readonly AccessType = PlatformAccessType;
    readonly AccessStatusGroupName = AccessStatusGroupName;

    readonly DISPLAYED_COLUMNS = ['platformKey', 'accessType', 'lastVerified', 'lastUpdated', 'login', 'password', 'status', 'active'];
    readonly dataSource = new MatTableDataSource<PlatformAccess>();
    passwordsCache: Record<string, string> = {};

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _clipboard: Clipboard,
        private readonly _translate: TranslateService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _platformsService: PlatformsService,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {
        effect(() => {
            this.dataSource.data = this.accessList();
        });
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
        }
    }

    changeStatus(event: Event, platformAccess: PlatformAccess): void {
        const { restaurantId } = platformAccess;

        if (!restaurantId) {
            return;
        }
        const target = event.target as HTMLTextAreaElement;
        const status = target.value as PlatformAccessStatus;
        this.onTogglePlatformAccess.emit({ restaurantId, element: platformAccess, status });
    }

    copyPassword(platformAccess: PlatformAccess): void {
        const { platformKey, restaurantId } = platformAccess;
        if (!restaurantId) {
            return;
        }
        const cacheKey = `${restaurantId}_${platformKey}`;
        if (this.passwordsCache[cacheKey]) {
            this._clipboard.copy(this.passwordsCache[cacheKey]);
            this.copied();
            return;
        }
        this._getPlatformPassword$(restaurantId, platformKey).subscribe({
            next: (password) => {
                this.passwordsCache[cacheKey] = password;
                this._clipboard.copy(password);
                this.copied();
            },
            error: () => {
                this._toastService.openErrorToast(this._translate.instant('admin.access.password_error'));
            },
        });
    }

    copied(): void {
        this._toastService.openSuccessToast(this._translate.instant('common.copied_to_the_clipboard'));
    }

    openPlatformTab(access: PlatformAccess): void {
        if (!access.restaurantId) {
            return;
        }
        this._platformsService
            .getPlatformsForRestaurant(access.restaurantId)
            .pipe(map((apiResult) => apiResult.data))
            .subscribe((platforms) => {
                const platform = platforms.find((p) => p.key === access.platformKey);
                if (!platform) {
                    return;
                }
                const platformLink = PlatformDefinitions.getPlatformDefinition(platform.key)?.accessLink?.(platform.socialId);
                if (!platformLink) {
                    return;
                }
                window.open(platformLink, '_blank');
            });
    }

    getAvailableStatusesByPlatformKey(platformKey: PlatformKey): GroupAccessStatus[] {
        const errorAccessStatusesByPlatformKey = {
            // From here https://www.notion.so/welcomehomemalou/Produit-Am-lio-messages-d-erreurs-App-846c47865bff40818c4a920ffd78811e
            [PlatformKey.DELIVEROO]: [PlatformAccessStatus.BAD_ACCESS],
            [PlatformKey.FOURSQUARE]: [PlatformAccessStatus.BAD_ACCESS],
            [PlatformKey.LAFOURCHETTE]: [PlatformAccessStatus.BAD_ACCESS],
            [PlatformKey.TRIPADVISOR]: [PlatformAccessStatus.BAD_ACCESS, PlatformAccessStatus.UNCLAIMED_PAGE],
            [PlatformKey.YELP]: [PlatformAccessStatus.BAD_ACCESS, PlatformAccessStatus.UNCLAIMED_PAGE, PlatformAccessStatus.INVALID_PAGE],
            [PlatformKey.ZENCHEF]: [PlatformAccessStatus.BAD_ACCESS],
            [PlatformKey.RESY]: [PlatformAccessStatus.BAD_ACCESS],
        };
        const errorAccessStatuses = errorAccessStatusesByPlatformKey[platformKey];
        if (!errorAccessStatusesByPlatformKey[platformKey]) {
            return DEFAULT_ACCESS_STATUS_GROUPS;
        }
        const errorAccessStatusGroup = {
            name: AccessStatusGroupName.ERROR,
            accessStatuses: errorAccessStatuses,
        };
        return [errorAccessStatusGroup, DEFAULT_PENDING_ACCESS_STATUS_GROUP, DEFAULT_SUCCESS_ACCESS_STATUS_GROUP];
    }

    getAccessStatusGroupNameByStatus(status: PlatformAccessStatus): AccessStatusGroupName | undefined {
        const group = DEFAULT_ACCESS_STATUS_GROUPS.find((accessStatusGroup) => accessStatusGroup.accessStatuses.includes(status));
        return group?.name;
    }

    getRestaurantNameAndAddressTooltip(restaurantName: string | undefined, restaurantAddress: Address | undefined): string {
        const restaurantAddressFormatted = restaurantAddress?.getFormattedAddressWithPostalCodeAndLocality();
        return restaurantAddressFormatted ? `${restaurantName ?? ''}\n${restaurantAddressFormatted}` : (restaurantName ?? '');
    }

    private _getPlatformPassword$(restaurantId: string, platformKey: string): Observable<string> {
        return this._restaurantsService.showAccess(restaurantId, platformKey).pipe(
            map((res) => res.data.password),
            catchError((err) => of(err))
        );
    }
}
