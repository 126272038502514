import { computed, effect, inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { GetMediaForEditionResponseDto } from '@malou-io/package-dto';
import { PublicationType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';
import { GetVideoMetadataService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/get-video-metadata.service';

type OnEndCallback = (media?: GetMediaForEditionResponseDto) => void;
interface FilesToUpload {
    file: File;
    onEnd: OnEndCallback;
}

export class MediaUploaderService {
    public readonly mediaCount = computed(
        () => this._queue().length + this._mediaCountBeingUploaded() + this._mediaCountBeingDuplicatedFromGallery()
    );

    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _mediaService = inject(MediaService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _getVideoMetadataService = inject(GetVideoMetadataService);
    private _publicationType: PublicationType | undefined;
    private readonly _queue = signal<FilesToUpload[]>([]);
    private readonly _mediaCountBeingUploaded = signal(0);
    private readonly _mediaCountBeingDuplicatedFromGallery = signal(0);

    constructor() {
        effect(() => this._notify(), { allowSignalWrites: true });
    }

    /**
     * Call this function before doing any uploading!!!
     */
    public setPublicationType(publicationType: PublicationType): void {
        this._publicationType = publicationType;
    }

    /** Resolves to undefined if the upload fails */
    public uploadFromFile(file: File): Promise<GetMediaForEditionResponseDto | undefined> {
        return new Promise((resolve) => {
            this._queue.update((files) => [...files, { file, onEnd: resolve }]);
        });
    }

    /** Resolves to undefined if the duplication fails */
    public async uploadFromGalleryMediaId(originalMediaId: string): Promise<GetMediaForEditionResponseDto | undefined> {
        this._mediaCountBeingDuplicatedFromGallery.update((c) => c + 1);
        try {
            return await this._duplicateMedia(originalMediaId);
        } catch {
            this._showError();
            return undefined;
        } finally {
            this._mediaCountBeingDuplicatedFromGallery.update((c) => c - 1);
        }
    }

    private async _duplicateMedia(originalMediaId: string): Promise<GetMediaForEditionResponseDto> {
        const publicationType = this._publicationType;
        if (!publicationType) {
            throw new Error('You must call MediaUploaderService.setPublicationType first');
        }
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        const { data } = await lastValueFrom(
            this._mediaService.duplicateMediaForPublication(
                { mediaId: originalMediaId },
                { restaurantIds: [restaurantId], publicationType }
            )
        );
        const newMedia = await lastValueFrom(
            this._mediaService.getMediaForEdition({ mediaId: data[0].duplicatedMediaId }, { publicationType })
        );
        return newMedia.data;
    }

    private async _notify(): Promise<void> {
        if (this._mediaCountBeingUploaded() >= 2) {
            // concurrency limit
            return;
        }
        const fileToUpload: FilesToUpload | undefined = this._queue()[0];
        if (!fileToUpload) {
            return;
        }

        this._mediaCountBeingUploaded.update((c) => c + 1);
        this._queue.update((files) => files.filter((f) => f !== fileToUpload));

        try {
            // videoMetadata will be null if the media is a photo
            const videoMetadata = await this._getVideoMetadataService.getMetadata(fileToUpload.file).catch((error) => {
                console.warn(error); // happens if the media is not a video (a photo…)
                return null;
            });
            const result = await this._mediaService.uploadV2({
                file: fileToUpload.file,
                onProgress: () => {},
                queryParams: {
                    restaurantId: this._restaurantsService.currentRestaurant._id,
                    videoWidthInPixels: videoMetadata?.widthInPixels,
                    videoHeightInPixels: videoMetadata?.heightInPixels,
                    videoDurationInMilliseconds: videoMetadata?.durationInMilliseconds,
                },
            });
            if (!result.success) {
                throw new Error(); // jump to the `catch` below
            }
            fileToUpload.onEnd(await this._duplicateMedia(result.mediaId));
        } catch (_) {
            this._showError();
            fileToUpload.onEnd(undefined);
        } finally {
            this._mediaCountBeingUploaded.update((c) => c - 1);
        }
    }

    private _showError(): void {
        this._toastService.openErrorToast(this._translateService.instant('media_uploader_service.upload_error'));
    }
}
