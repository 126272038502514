import { Component, DestroyRef, effect, inject, input, OnInit, output, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsEvolutionTotalWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewsKpisCardComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-card/reviews-kpis-card.component';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-reviews-kpis-count-evolution',
    standalone: true,
    imports: [ReviewsKpisCardComponent, MatIconModule, MatTooltipModule, TranslateModule, ShortNumberPipe],
    templateUrl: './reviews-kpis-count-evolution.component.html',
    styleUrl: './reviews-kpis-count-evolution.component.scss',
})
export class ReviewsKpisCountEvolutionComponent implements OnInit {
    filters$ = input.required<Observable<[DatesAndPeriod, PlatformKey[], Restaurant, MalouComparisonPeriod]>>();
    hasDataChange = output<boolean>();
    isLoadingEvent = output<boolean>();

    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;

    readonly httpError: WritableSignal<Error | undefined> = signal(undefined);
    readonly isLoading = signal(true);

    readonly currentTotalReviews: WritableSignal<number | undefined> = signal(undefined);
    readonly previousTotalReviews: WritableSignal<number | undefined> = signal(undefined);
    readonly diffTotalReviews: WritableSignal<number | undefined> = signal(undefined);
    readonly gmbTotalReviewCountTooltip: Signal<string> = toSignal(this._getTotalReviewCountForPlatform$(PlatformKey.GMB), {
        initialValue: '',
    });
    readonly comparisonPeriodKey: WritableSignal<string> = signal('');

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this.filters$()
            .pipe(
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant, comparisonPeriod]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        Restaurant,
                        MalouComparisonPeriod,
                    ]): Observable<[ReviewsEvolutionTotalWithRange, ReviewsEvolutionTotalWithRange]> => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;
                        this.comparisonPeriodKey.set(this._getComparisonPeriodKey(comparisonPeriod));
                        return forkJoin([
                            this._reviewsService
                                .getChartRestaurantsReviewsTotal({ restaurantId, platforms, startDate, endDate })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                            this._reviewsService
                                .getChartRestaurantsReviewsTotal({
                                    restaurantId,
                                    platforms,
                                    startDate,
                                    endDate,
                                    comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
                                })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                        ]);
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([currentReviewsEvolutionTotal, previousReviewsEvolutionTotal]: [
                    ReviewsEvolutionTotalWithRange,
                    ReviewsEvolutionTotalWithRange,
                ]) => {
                    this.currentTotalReviews.set(this._computeCurrentTotalReviews(currentReviewsEvolutionTotal));
                    this.diffTotalReviews.set(this._computeDiffTotalReviews(this.currentTotalReviews(), previousReviewsEvolutionTotal));
                    this.previousTotalReviews.set(this._computeCurrentTotalReviews(previousReviewsEvolutionTotal));
                    this.hasDataChange.emit(!!this.currentTotalReviews());
                    this.isLoading.set(false);
                }
            );
    }

    private _handleHttpError(error: Error): Observable<never> {
        this.httpError.set(error);
        this.hasDataChange.emit(false);
        this.isLoading.set(false);
        return EMPTY;
    }

    private _computeCurrentTotalReviews(currentReviewsEvolutionTotal: ReviewsEvolutionTotalWithRange): number | undefined {
        return currentReviewsEvolutionTotal?.results?.[0]?.total;
    }

    private _computeDiffTotalReviews(
        currentTotalReviews: number | undefined,
        previousReviewsEvolutionTotal: ReviewsEvolutionTotalWithRange
    ): number | undefined {
        const previousTotalReviews = previousReviewsEvolutionTotal?.results?.[0]?.total;
        if (currentTotalReviews && previousTotalReviews) {
            return currentTotalReviews - previousTotalReviews;
        }
    }

    private _reset(): void {
        this.httpError.set(undefined);
        this.isLoading.set(true);
    }

    private _getTotalReviewCountForPlatform$(platformKey: PlatformKey): Observable<string> {
        return this._restaurantsService.restaurantSelected$.pipe(
            switchMap((restaurant: Restaurant) => this._reviewsService.getTotalReviewCountForPlatform(restaurant._id, platformKey)),
            catchError((error) => {
                console.warn('fetch total review count error :>>', error);
                return EMPTY;
            }),
            map((data) =>
                data?.count
                    ? this._translateService.instant('statistics.e_reputation.reviews_kpis.total_reviews', { count: data?.count })
                    : ''
            ),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _getComparisonPeriodKey(comparisonPeriod: MalouComparisonPeriod): string {
        return `date_filter.comparison_period.${comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD}`;
    }
}
