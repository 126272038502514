<app-select-base
    [required]="false"
    [values]="values()"
    [multiSelection]="true"
    [selectedValues]="selectedValues()"
    [control]="inputAutoCompleteControl"
    [hideSelectedValues]="true"
    (selectBaseChange)="onSelectBaseChange($event)">
    <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
        <div class="malou-chip malou-chip--primary">
            <span>{{ '@' + value }}</span>
            <mat-icon
                class="malou-chip-icon--right malou-color-primary !h-3.5 !w-3.5 shrink-0"
                [svgIcon]="SvgIcon.REMOVE"
                (click)="deleteValueAt(index)"></mat-icon>
        </div>
    </ng-template>

    <ng-template let-value="value" #optionTemplate>
        @let igAccount = getIgAccountFromUsername | applyPure: value;
        @if (igAccount) {
            <div class="flex items-center gap-x-3">
                <img
                    class="h-7.5 w-7.5 rounded-full"
                    [defaultImage]="'default-picture-grey' | illustrationPathResolver"
                    [lazyLoad]="igAccount.profile_picture_url ?? ''"
                    [errorImage]="'default-picture-grey' | illustrationPathResolver" />
                <span
                    class="malou-text-10--semibold text-malou-color-text-2"
                    [matTooltip]="igAccount.username.length > 16 ? (igAccount.username | shortText: 200) : ''">
                    {{ '@' }}{{ igAccount.username | shortText: 16 }}
                    <span class="malou-text-10--regular italic text-malou-color-text-2">
                        ({{ igAccount.followers_count }} {{ 'common.followers' | translate }})
                    </span>
                </span>
            </div>
        } @else {
            <!-- Should not happen -->
            <div class="malou-text-10--semibold text-malou-color-text-2">--</div>
        }
    </ng-template>
</app-select-base>
