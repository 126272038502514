import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import {
    BehaviorSubject,
    combineLatest,
    delay,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    tap,
    timer,
} from 'rxjs';

import { PollingPostStatusDto, SocialPostItemDto } from '@malou-io/package-dto';
import {
    ApiResultV2,
    isBeforeNow,
    isNotNil,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
    SocialPostsListFilter,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { PostCategory } from ':core/constants/post-category';
import { DuplicatePostModalService } from ':core/services/duplicate-post-modal.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { SelectionModel } from ':shared/helpers/selection-model';
import { Platform, Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class SocialPostsContext {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _duplicatePostModalService = inject(DuplicatePostModalService);
    private readonly _platformsService = inject(PlatformsService);

    // Posts list
    private readonly _posts = signal<SocialPostItem[]>([]);
    readonly sortedPosts = computed(() => this._sortPostsByDate(this._posts()));
    readonly isFetchingPosts = signal<boolean>(false);
    readonly isFetchingMorePosts = signal<boolean>(false);
    readonly hasFetchedPostsAtLeastOnce = signal<boolean>(false);
    readonly selectedFilter = signal<SocialPostsListFilter>(SocialPostsListFilter.ALL);
    private readonly _socialPostsCursor = signal<string | null>(null);
    readonly hasNextPage = computed(() => this._socialPostsCursor() !== null);
    readonly fetchNextPage$ = new Subject<void>();

    // Feed items list
    readonly feed = signal<FeedItem[]>([]);
    readonly isFetchingFeed = signal<boolean>(false);
    readonly isFetchingMoreFeed = signal<boolean>(false);
    private readonly _feedCursor = signal<string | null>(null);
    readonly hasNextPageFeed = computed(() => this._feedCursor() !== null);
    readonly fetchFeedNextPage$ = new Subject<void>();

    // Selection
    readonly postSelection = new SelectionModel<SocialPostItem>((value) => value.id);
    readonly isSelecting = signal(false);

    // Highlight posts
    readonly highlightedPostIds = signal<string[]>([]);
    private readonly _highlightPosts$ = new Subject<string[]>();

    // Used to fetch posts until a specific post is found
    private _fetchUntilPostIsFound: string | null = null;
    readonly foundPost$ = new Subject<string>();

    readonly restaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil));
    readonly restaurant = toSignal(this.restaurant$, { initialValue: this._restaurantsService.currentRestaurant });

    readonly restaurantHasNoPost = computed(
        (): boolean =>
            this.hasFetchedPostsAtLeastOnce() &&
            this._posts().length === 0 &&
            !this.isFetchingPosts() &&
            !this.isFetchingMorePosts() &&
            this.selectedFilter() === SocialPostsListFilter.ALL
    );

    readonly shouldFetchFilterOptionsCount$ = new Subject<void>();

    readonly stopPollingPostsStatus$ = new Subject<void>();

    readonly disconnectedPlatforms$ = new BehaviorSubject<Platform[]>([]);

    constructor() {
        this._initHighlightPosts();
    }

    init(): void {
        this.hasFetchedPostsAtLeastOnce.set(false);
        this.postSelection.unselectAll();
        this.isSelecting.set(false);
        this.resetFilter();
        this.resetPagination();
        this.resetFeedPagination();
        this._resetHighlightPosts();
        this._initPollingPostsStatus();
        this._initDisconnectedSocialPlatforms();
    }

    fetchPosts(postsFilter: SocialPostsListFilter, restaurantId: string, limit: number): void {
        const cursor = this._socialPostsCursor();
        this.isFetchingPosts.set(cursor === null);
        this.isFetchingMorePosts.set(cursor !== null);

        this._socialPostsService.getSocialPosts$(postsFilter, cursor, restaurantId, limit).subscribe(({ socialPostItems, nextCursor }) => {
            const newPosts = socialPostItems.map((post) => SocialPostItem.fromDto(post));
            if (cursor === null) {
                this._posts.set(newPosts);
            } else {
                // In some cases, the same post can already be on the list (e.g. with polling)
                // so we need to remove the old post and add the new one
                this._posts.update((currentPosts) => {
                    const filteredPosts = currentPosts.filter((post) => !newPosts.some((p) => p.id === post.id));
                    return this._sortPostsByDate([...filteredPosts, ...newPosts]);
                });
            }
            this.isFetchingPosts.set(false);
            this.isFetchingMorePosts.set(false);
            this.hasFetchedPostsAtLeastOnce.set(true);
            this._socialPostsCursor.set(nextCursor);

            if (this._fetchUntilPostIsFound !== null) {
                this.fetchPostsUntilPostIsFound(this._fetchUntilPostIsFound);
            }
        });
    }

    fetchFeed(restaurantId: string, limit: number): void {
        const feedCursor = this._feedCursor();
        this.isFetchingFeed.set(feedCursor === null);
        this.isFetchingMoreFeed.set(feedCursor !== null);

        this._socialPostsService.getFeed$(restaurantId, feedCursor, limit).subscribe(({ feed, nextCursor }) => {
            const newFeed = feed.map((feedItem) => FeedItem.fromDto(feedItem));
            if (feedCursor === null) {
                this.feed.set(newFeed);
            } else {
                this.feed.update((currentFeed) => [...currentFeed, ...newFeed]);
            }
            this.isFetchingFeed.set(false);
            this.isFetchingMoreFeed.set(false);
            this._feedCursor.set(nextCursor);
        });
    }

    deleteSocialPosts(postIds: string[]): void {
        const postsToDelete = this._posts().filter((p) => postIds.includes(p.id));
        if (postsToDelete.length === 0) {
            return;
        }
        const feedItemsToDelete = this.feed().filter((f) => postIds.includes(f.postId));

        // Optimistic update
        this._optimisticDeletePosts(postIds);
        this.postSelection.unselect(postsToDelete);
        this.setIsSelecting(false);

        this._socialPostsService.deleteSocialPosts$(postIds).subscribe((result) => {
            const postIdsThatFailed = result.filter((r) => !r.success).map((r) => r.postId);
            if (postIdsThatFailed.length > 0) {
                // Revert optimistic update
                this._undoOptimisticDeletePosts({ postIdsThatFailed, postsToDelete, feedItemsToDelete });
                this.setIsSelecting(true);

                this._toastService.openErrorToast(
                    postsToDelete.length > 1
                        ? this._translateService.instant('social_posts.error_delete_posts_bulk')
                        : this._translateService.instant('social_posts.error_delete_post')
                );
            } else {
                this._toastService.openSuccessToast(
                    postsToDelete.length > 1
                        ? this._translateService.instant('social_posts.success_delete_posts_bulk')
                        : this._translateService.instant('social_posts.success_delete_post')
                );
                if (this._posts().length === 0) {
                    // This is a hack to prevent the empty state from being displayed when the user deletes all posts
                    this.isFetchingPosts.set(this.selectedFilter() !== SocialPostsListFilter.ALL);

                    this.resetFilter();
                }
            }
            // Re-fetch the filter options count
            this.shouldFetchFilterOptionsCount$.next();
        });
    }

    upsertSocialPost(post: SocialPostItem): void {
        const shouldRemovePostFromList =
            (this.selectedFilter() === SocialPostsListFilter.FEEDBACK && post.feedbackMessageCount === 0) ||
            (this.selectedFilter() === SocialPostsListFilter.DRAFT && post.published !== PostPublicationStatus.DRAFT) ||
            (this.selectedFilter() === SocialPostsListFilter.ERROR && post.published !== PostPublicationStatus.ERROR);

        this._posts.update((currentPosts) => {
            if (shouldRemovePostFromList) {
                return currentPosts.filter((p) => p.id !== post.id);
            }

            const postIndex = currentPosts.findIndex((p) => p.id === post.id);
            if (postIndex === -1) {
                return this._sortPostsByDate([...currentPosts, post]);
            }
            currentPosts[postIndex] = post;
            return [...currentPosts];
        });

        if (post.platformKeys.includes(PlatformKey.INSTAGRAM)) {
            const newFeedItem = FeedItem.fromSocialPostItem(post);
            this.feed.update((currentFeed) => {
                const feedItemIndex = currentFeed.findIndex((f) => f.postId === post.id);
                if (feedItemIndex === -1) {
                    return [...currentFeed, newFeedItem].sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
                }
                currentFeed[feedItemIndex] = newFeedItem;
                return [...currentFeed].sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
            });
        } else {
            this.feed.update((currentFeed) => currentFeed.filter((feedItem) => feedItem.postId !== post.id));
        }

        if (this._posts().length === 0) {
            // This is a hack to prevent the empty state from being displayed when the user update a post and the list is empty
            this.isFetchingPosts.set(this.selectedFilter() !== SocialPostsListFilter.ALL);

            this.resetFilter();
        }
    }

    refreshSocialPost(postId: string): void {
        this._socialPostsService.refreshSocialPost$(postId).subscribe((result) => {
            this.upsertSocialPost(SocialPostItem.fromDto(result.data));
        });
    }

    duplicatePosts({
        postIds,
        restaurantIds,
        postDestination,
    }: {
        postIds: string[];
        restaurantIds: string[];
        postDestination: PostSource;
    }): void {
        const fromRestaurantId = this.restaurant().id;

        this._socialPostsService
            .duplicatePosts$({
                fromRestaurantId,
                restaurantIds,
                postIdsToDuplicate: postIds,
                postDestination,
            })
            .subscribe({
                next: (result) => {
                    if (postDestination === PostSource.SOCIAL) {
                        // Filter posts that should be displayed
                        const postsInCurrentRestaurant = result.data.socialPostsDuplicated.filter(
                            (r) => r.restaurantId === fromRestaurantId
                        );
                        const selectedFilter = this.selectedFilter();
                        const newPosts = postsInCurrentRestaurant
                            .filter(
                                (r) =>
                                    selectedFilter === SocialPostsListFilter.ALL ||
                                    (selectedFilter === SocialPostsListFilter.DRAFT && r.post.published === PostPublicationStatus.DRAFT)
                            )
                            .map((r) => {
                                const post = UpsertSocialPost.fromDto(r.post);
                                return SocialPostItem.fromIUpsertSocialPost(post.toInterface());
                            });
                        if (newPosts.length === 0) {
                            return;
                        }
                        // Add posts and feed items to the list
                        this._posts.update((currentPosts) => [...currentPosts, ...newPosts]);
                        const newFeedItems = newPosts
                            .filter((post) => post.platformKeys.includes(PlatformKey.INSTAGRAM))
                            .map((post) => FeedItem.fromSocialPostItem(post));
                        if (newFeedItems.length > 0) {
                            this.feed.update((currentFeed) =>
                                [...currentFeed, ...newFeedItems].sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime())
                            );
                        }

                        this._toastService.openSuccessToast(
                            newPosts.length > 1
                                ? this._translateService.instant('social_posts.success_duplicate_posts_bulk')
                                : this._translateService.instant('social_posts.success_duplicate_post')
                        );

                        // Re-fetch the filter options count
                        this.shouldFetchFilterOptionsCount$.next();

                        // Scroll to the last duplicated post
                        const postIdToScrollTo = this._sortPostsByDate(newPosts)[0].id;
                        if (postIdToScrollTo) {
                            this.foundPost$.next(postIdToScrollTo);
                        }

                        this.highlightPosts(newPosts.map((p) => p.id));
                    } else {
                        const seoPostsFromCurrentRestaurant = result.data.seoPostsDuplicated.filter(
                            (r) => r.restaurantId === fromRestaurantId
                        );
                        if (seoPostsFromCurrentRestaurant.length > 0) {
                            const hasManyPosts = seoPostsFromCurrentRestaurant.length > 1;
                            const postId = hasManyPosts ? null : seoPostsFromCurrentRestaurant[0].post.id;
                            this._duplicatePostModalService.openDuplicateModal(postId, PostCategory.SEO, hasManyPosts);
                        }
                    }

                    // Unselect all posts and toggle selecting mode
                    this.postSelection.unselectAll();
                    this.setIsSelecting(false);
                },
                error: () => {
                    this._toastService.openErrorToast(
                        postIds.length > 1
                            ? this._translateService.instant('social_posts.error_duplicate_posts_bulk')
                            : this._translateService.instant('social_posts.error_duplicate_post')
                    );
                },
            });
    }

    resetPagination(): void {
        this._socialPostsCursor.set(null);
        this.goNextPage();
    }

    resetFilter(): void {
        this.selectedFilter.set(SocialPostsListFilter.ALL);
    }

    resetFeedPagination(): void {
        this._feedCursor.set(null);
        this.goNextPageFeed();
    }

    goNextPage(): void {
        this.fetchNextPage$.next();
    }

    goNextPageFeed(): void {
        this.fetchFeedNextPage$.next();
    }

    fetchPostsUntilPostIsFound(postId: string): void {
        const posts = this._posts();
        if (posts.some((p) => p.id === postId)) {
            // Found the post, reset the variable and notify subscribers
            this._fetchUntilPostIsFound = null;
            this.foundPost$.next(postId);
            return;
        }

        if (this._socialPostsCursor() === null) {
            // No more posts to fetch
            this._fetchUntilPostIsFound = null;
            return;
        }

        // Fetch next page to find the post
        this.fetchNextPage$.next();
    }

    setFetchUntilPostIsFound(postId: string): void {
        this._fetchUntilPostIsFound = postId;
    }

    swapPlannedPublicationDates(sourceIndex: number, destinationIndex: number): void {
        this._optimisticUpdatePlannedPublicationDates(sourceIndex, destinationIndex);

        const minIndex = Math.min(sourceIndex, destinationIndex);
        const maxIndex = Math.max(sourceIndex, destinationIndex);
        const slicedFeed = this.feed().slice(minIndex, maxIndex + 1);
        const order = sourceIndex < destinationIndex ? -1 : 1;

        this._socialPostsService.swapPlannedPublicationDates$(slicedFeed, order).subscribe((result) => {
            if (!result.success) {
                this._toastService.openErrorToast(this._translateService.instant('social_posts.error_swap_dates'));
                this._undoOptimisticUpdatePlannedPublicationDates(sourceIndex, destinationIndex);
            }
        });
    }

    setIsSelecting(isSelecting: boolean): void {
        this.isSelecting.set(isSelecting);
    }

    highlightPosts(postIds: string[]): void {
        this._highlightPosts$.next(postIds);
    }

    private _optimisticUpdatePlannedPublicationDates(sourceIndex: number, destinationIndex: number): void {
        const minIndex = Math.min(sourceIndex, destinationIndex);
        const maxIndex = Math.max(sourceIndex, destinationIndex);
        const order = sourceIndex < destinationIndex ? -1 : 1;

        const postsToUpdate: { postId: string; plannedPublicationDate: Date | null; sortDate?: Date }[] = [];

        this.feed.update((currentFeed) => {
            const slicedFeed = currentFeed.slice(minIndex, maxIndex + 1);
            const slicedFeedLength = slicedFeed.length;

            const updatedFeed = slicedFeed.map((item, index) => {
                const destinationFeedItemIndex = (index + order + slicedFeedLength) % slicedFeedLength;
                const destinationFeedItem = slicedFeed[destinationFeedItemIndex];
                if (!destinationFeedItem) {
                    // Should never happen
                    throw new Error(`Feed item not found for index ${destinationFeedItemIndex}`);
                }
                const updatedItem = item.copyWith({
                    plannedPublicationDate: destinationFeedItem.plannedPublicationDate,
                    sortDate: destinationFeedItem.sortDate,
                });
                postsToUpdate.push({
                    postId: updatedItem.postId,
                    plannedPublicationDate: updatedItem.plannedPublicationDate,
                    sortDate: updatedItem.sortDate,
                });
                return updatedItem;
            });
            const sortedUpdatedFeed = updatedFeed.sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
            return [...currentFeed.slice(0, minIndex), ...sortedUpdatedFeed, ...currentFeed.slice(maxIndex + 1)];
        });

        this._posts.update((currentPosts) => {
            postsToUpdate.forEach((postToUpdate) => {
                const postIndex = currentPosts.findIndex((post) => post.id === postToUpdate.postId);
                if (postIndex !== -1) {
                    currentPosts[postIndex] = currentPosts[postIndex].copyWith({
                        plannedPublicationDate: postToUpdate.plannedPublicationDate,
                        sortDate: postToUpdate.sortDate,
                    });
                }
            });
            return [...currentPosts];
        });
    }

    private _undoOptimisticUpdatePlannedPublicationDates(sourceIndex: number, destinationIndex: number): void {
        this._optimisticUpdatePlannedPublicationDates(destinationIndex, sourceIndex);
    }

    private _optimisticDeletePosts(postIds: string[]): void {
        this._posts.update((currentPosts) => currentPosts.filter((post) => !postIds.includes(post.id)));
        this.feed.update((currentFeed) => currentFeed.filter((feedItem) => !postIds.includes(feedItem.postId)));
    }

    private _undoOptimisticDeletePosts({
        postIdsThatFailed,
        postsToDelete,
        feedItemsToDelete,
    }: {
        postIdsThatFailed: string[];
        postsToDelete: SocialPostItem[];
        feedItemsToDelete: FeedItem[];
    }): void {
        const postsToUndelete = postsToDelete.filter((p) => postIdsThatFailed.includes(p.id));
        this._posts.update((currentPosts) => [...currentPosts, ...postsToUndelete]);
        const feedItemsToUndelete = feedItemsToDelete.filter((f) => postIdsThatFailed.includes(f.postId));
        if (feedItemsToUndelete.length > 0) {
            this.feed.update((currentFeed) =>
                [...currentFeed, ...feedItemsToUndelete].sort(
                    (a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime()
                )
            );
        }
    }

    private _resetHighlightPosts(): void {
        this.highlightedPostIds.set([]);
    }

    private _initHighlightPosts(): void {
        const highlightDuration = 5 * TimeInMilliseconds.SECOND;
        this._highlightPosts$
            .pipe(
                tap((postIds) => this.highlightedPostIds.update((currentIds) => [...currentIds, ...postIds])),
                delay(highlightDuration),
                takeUntilDestroyed()
            )
            .subscribe((postIds) => {
                this.highlightedPostIds.update((currentIds) => {
                    for (const postId of postIds) {
                        const index = currentIds.indexOf(postId);
                        if (index !== -1) {
                            currentIds.splice(index, 1);
                        }
                    }
                    return [...currentIds];
                });
            });
    }

    private _initPollingPostsStatus(): void {
        const threeSeconds = 3 * TimeInMilliseconds.SECOND;
        combineLatest([this.restaurant$, timer(0, threeSeconds)])
            .pipe(
                filter(([restaurant]) => isNotNil(restaurant)),
                switchMap(() => {
                    const bindingIdsToFetchStatus = this._getBindingIdsForPolling();
                    const pollStatus$ =
                        bindingIdsToFetchStatus.length > 0
                            ? this._socialPostsService.pollingPostsStatus$(bindingIdsToFetchStatus)
                            : of({ data: [] });

                    return forkJoin([pollStatus$, of(bindingIdsToFetchStatus)]);
                }),
                map(([result, bindingIdsToFetchStatus]) => this._updatePostsStatusAndGetPostIdsToFetch(result, bindingIdsToFetchStatus)),
                switchMap((postIds) => (postIds.length > 0 ? this._socialPostsService.getSocialPostsByIds$(postIds) : of({ data: [] }))),
                takeUntil(this.stopPollingPostsStatus$)
            )
            .subscribe((result) => {
                this._addNewPostsAfterPollingToPostList(result);
            });
    }

    private _updatePostsStatusAndGetPostIdsToFetch(
        result: ApiResultV2<PollingPostStatusDto[]>,
        bindingIdsToFetchStatus: string[]
    ): string[] {
        const postsStatuses = result.data;
        if (postsStatuses.length === 0) {
            // Remove posts with bindingId from the list
            this._posts.update((currentPosts) => currentPosts.filter((post) => !bindingIdsToFetchStatus.includes(post.bindingId ?? '')));
            return [];
        }

        const postIdsToGet: string[] = [];

        this._posts.update((currentPosts) => {
            // Remove posts with bindingId from the list that are not in the statuses
            const postsToRemoveFromList = currentPosts.filter(
                (post) =>
                    post.bindingId &&
                    bindingIdsToFetchStatus.includes(post.bindingId) &&
                    postsStatuses.every((status) => status.postId !== post.id)
            );
            const postsToKeep = currentPosts.filter((post) => postsToRemoveFromList.every((postToRemove) => postToRemove.id !== post.id));

            // Update posts with the statuses
            const updatedPosts = postsToKeep.map((post) => {
                const postStatus = postsStatuses.find((status) => status.bindingId === post.bindingId && status.postId === post.id);
                if (postStatus) {
                    // If the post has a different PostPublicationStatus, or isPublishing value, get the updated post
                    if (postStatus.published !== post.published || postStatus.isPublishing !== post.isPublishing) {
                        postIdsToGet.push(post.id);
                    }
                    return post.copyWith({
                        published: postStatus.published,
                        isPublishing: postStatus.isPublishing,
                    });
                }
                return post;
            });

            // Post to get because they are not in the list but in the statuses
            postIdsToGet.push(
                ...postsStatuses
                    .filter((status) =>
                        updatedPosts.every(
                            (post) =>
                                post.id !== status.postId ||
                                post.published !== status.published ||
                                post.isPublishing !== status.isPublishing
                        )
                    )
                    .map((status) => status.postId)
            );

            return updatedPosts;
        });

        return postIdsToGet;
    }

    private _getBindingIdsForPolling(): string[] {
        return this._posts()
            .filter(
                (post) =>
                    post.isPublishing ||
                    (post.published === PostPublicationStatus.PENDING &&
                        post.plannedPublicationDate &&
                        isBeforeNow(post.plannedPublicationDate))
            )
            .map((post) => post.bindingId)
            .filter(isNotNil);
    }

    private _addNewPostsAfterPollingToPostList(result: ApiResultV2<SocialPostItemDto[]>): void {
        if (result.data.length > 0) {
            const selectedFilter = this.selectedFilter();
            const newPosts = result.data
                .map((post) => SocialPostItem.fromDto(post))
                .filter(
                    (post) =>
                        selectedFilter === SocialPostsListFilter.ALL ||
                        (selectedFilter === SocialPostsListFilter.DRAFT && post.published === PostPublicationStatus.DRAFT) ||
                        (selectedFilter === SocialPostsListFilter.ERROR && post.published === PostPublicationStatus.ERROR) ||
                        (selectedFilter === SocialPostsListFilter.FEEDBACK && post.feedbackMessageCount > 0)
                );
            if (newPosts.length > 0) {
                this._posts.update((currentPosts) => {
                    const filteredPosts = currentPosts.filter((post) => !newPosts.some((p) => p.id === post.id));
                    return this._sortPostsByDate([...filteredPosts, ...newPosts]);
                });
            }
        }
    }

    private _sortPostsByDate(posts: SocialPostItem[]): SocialPostItem[] {
        return posts.sort((a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime());
    }

    private _initDisconnectedSocialPlatforms(): void {
        const restaurantId = this.restaurant().id;
        this.disconnectedPlatforms$.next([]);

        const platformKeys = uniq([
            ...PlatformDefinitions.getPlatformKeysWithReel(),
            ...PlatformDefinitions.getSocialPlatformKeysWithPost(),
        ]);

        this._platformsService.getDisconnectedPlatformsForRestaurant(restaurantId, platformKeys).subscribe((result) => {
            const platforms = result.data;
            this.disconnectedPlatforms$.next(platforms);
        });
    }
}
