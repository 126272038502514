import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { catchError, combineLatest, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import { AggregationTimeScale, isNotNil, MalouMetric, PlatformDefinitions, PlatformKey, PostType } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { selectDatesFilter } from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { InsightsService } from ':modules/statistics/insights.service';
import { getClosestValueFromDate } from ':shared/helpers';
import {
    InsightsByPlatformByRestaurant,
    PostsWithInsightsByPlatforms,
    PostWithInsights,
    Restaurant,
    TimeScaleToMetricToDataValues,
} from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AbstractCsvService } from '../csv-service.abstract';

interface Data {
    postsWithInsights: { restaurantId: string; data: PostsWithInsightsByPlatforms | undefined }[];
    followers: InsightsByPlatformByRestaurant;
    restaurants: Restaurant[];
}

@Injectable({ providedIn: 'root' })
export class AggregatedPublicationsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _insightsService: InsightsService,
        private readonly _postsService: PostsService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        super();
    }

    protected override _getData$(): any {
        return combineLatest([
            this._store.select(selectDatesFilter).pipe(take(1)),
            this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
        ]).pipe(
            switchMap(([dates, restaurants]) => {
                const { startDate, endDate } = dates;
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                const platformKeys = PlatformDefinitions.getPlatformKeysWithRSStats();

                return forkJoin([
                    this._getPostsWithInsightsByRestaurant$(restaurantIds, platformKeys, startDate, endDate),
                    this._insightsService
                        .getInsights({
                            restaurantIds,
                            platformKeys: PlatformDefinitions.getPlatformKeysWithFollowers(),
                            metrics: [MalouMetric.FOLLOWERS],
                            aggregators: [AggregationTimeScale.BY_DAY],
                            startDate,
                            endDate,
                        })
                        .pipe(map((res) => res.data)),
                    of(restaurants),
                ]).pipe(
                    map(([postsWithInsights, followers, _restaurants]) => ({
                        postsWithInsights,
                        followers,
                        restaurants: _restaurants,
                    }))
                );
            })
        );
    }

    protected override _isDataValid(data: Data): boolean {
        return data.postsWithInsights.some((p) => !!p.data?.length);
    }

    protected override _getCsvHeaderRow(): string[] {
        return [
            'Date',
            'Location',
            'Location Internal Name',
            'Location Address',
            'Type',
            'Caption',
            'Platform',
            'Impressions',
            'Likes',
            'Comments',
            'Shares',
            'Saves',
            'Engagement Rate',
        ];
    }

    protected override _getCsvDataRows(insightsData: Data): string[][] {
        const { postsWithInsights, followers, restaurants } = insightsData;

        return postsWithInsights
            .map(({ restaurantId, data }) => {
                const restaurant = restaurants.find((r) => r._id === restaurantId);
                if (!data || !restaurant) {
                    return null;
                }
                return data
                    .map((platformPostsWithInsights) => {
                        const platformKey = Object.keys(platformPostsWithInsights)[0];
                        const posts = platformPostsWithInsights[platformKey]?.data;
                        if (!posts?.length) {
                            return [];
                        }
                        return posts.map((post) => {
                            const nbFollowers = followers[restaurantId][platformKey].error
                                ? '0'
                                : (this._getFollowerCountByDate(
                                      new Date(post.createdAt),
                                      followers[restaurantId][platformKey]
                                  )?.toString() ?? '0');
                            const postWithIngights = new PostWithInsights({
                                ...post,
                                key: platformKey,
                                nbFollowers,
                            });

                            const postDate = new Date(postWithIngights.createdAt).toLocaleDateString();
                            const location = restaurant.name;
                            const locationInternalName = restaurant.internalName ?? '';
                            const locationAddress = restaurant.getFullFormattedAddress().replace(/,/g, '');
                            const type = postWithIngights.postType === PostType.REEL ? 'Reel' : 'Post';
                            const caption = postWithIngights.caption ?? '';
                            const platform = this._enumTranslatePipe.transform(platformKey, 'platform_key');
                            const impressions = postWithIngights.impressions?.toString() ?? '0';
                            const likes = postWithIngights.likes?.toString() ?? '0';
                            const comments = postWithIngights.comments?.toString() ?? '0';
                            const shares = postWithIngights.shares?.toString() ?? '0';
                            const saves = postWithIngights.saved?.toString() ?? '0';
                            const engagementRate =
                                typeof postWithIngights.engagementRate === 'number'
                                    ? this._shortNumberPipe.transform(postWithIngights.engagementRate)
                                    : '';

                            return [
                                postDate,
                                location,
                                locationInternalName,
                                locationAddress,
                                type,
                                caption,
                                platform,
                                impressions,
                                likes,
                                comments,
                                shares,
                                saves,
                                engagementRate,
                            ];
                        });
                    })
                    .flat();
            })
            .filter(isNotNil)
            .flat();
    }

    private _getPostsWithInsightsByRestaurant$(
        restaurantIds: string[],
        platformKeys: PlatformKey[],
        startDate: Date | null,
        endDate: Date | null
    ): Observable<{ data: PostsWithInsightsByPlatforms | undefined; restaurantId: string }[]> {
        return forkJoin(
            restaurantIds.map((restaurantId) =>
                this._postsService.getPostsWithInsights(restaurantId, platformKeys, startDate, endDate).pipe(
                    catchError(() => of(null)),
                    map((res) => ({ restaurantId, data: res?.data }))
                )
            )
        );
    }

    private _getFollowerCountByDate(date: Date, platformFollowersInsights: TimeScaleToMetricToDataValues): number | null {
        const formattedTargetDate = DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate();
        const followers = platformFollowersInsights?.by_day?.followers;
        if (!followers) {
            return null;
        }
        const followersByDay = followers.reduce((acc, f) => ({ ...acc, [f.date]: f.value }), {});
        const possibleDates = Object.keys(followersByDay).map((d) => new Date(d));
        const closestDate = getClosestValueFromDate(formattedTargetDate, possibleDates);
        return closestDate ? followersByDay[closestDate.toISOString()] : null;
    }
}
