import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, output, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { concat } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { catchError, debounceTime, distinctUntilChanged, filter, of, Subject, switchMap } from 'rxjs';

import { IGAccount, PlatformKey } from '@malou-io/package-utils';

import { mapsterPostCaptionTextLimit, postCaptionTextLimit } from ':core/constants';
import { PostsService } from ':core/services/posts.service';
import { SocialPostHashtagsComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-caption/social-post-hashtags/social-post-hashtags.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { PostHashtagsComponent } from ':modules/social-posts/new-social-post-modal/components/post-form-body/post-hashtags/post-hashtags.component';
import { CREATE_SOCIAL_POST_MODAL_HTML_ID } from ':modules/social-posts/new-social-post-modal/utils/utils';
import { AiGenerationActionsV2Component } from ':shared/components/ai-generation-actions-v2/ai-generation-actions-v2.component';
import { AiGenerationActionsDisplayStyle } from ':shared/components/ai-generation-actions/ai-generation-actions.component';
import { InfiniteTextSlideComponent } from ':shared/components/infinite-text-slide/infinite-text-slide.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { AiOperation } from ':shared/openai-prompt/openai-prompt.component';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

@Component({
    selector: 'app-social-post-caption',
    templateUrl: './social-post-caption.component.html',
    styleUrls: ['./social-post-caption.component.scss'],
    standalone: true,
    imports: [
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        AiGenerationActionsV2Component,
        InfiniteTextSlideComponent,
        SocialPostHashtagsComponent,
        TextAreaComponent,
        ClickOutsideDirective,
        IllustrationPathResolverPipe,
        ShortTextPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostCaptionComponent {
    readonly isMapstrPlatformChecked = input.required<boolean>();
    readonly isReel = input.required<boolean>();
    readonly value = input<string | undefined>();
    readonly isDisabled = input.required<boolean>();

    readonly valueChange = output<string>();

    private readonly _postsService = inject(PostsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    readonly upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);

    readonly postHashtagsComponent = viewChild(PostHashtagsComponent, { read: ElementRef });

    readonly atLeastOneHashtagSelected = computed(
        () => this._upsertSocialPostContext.upsertSocialPostState.post.hashtags().selected.length > 0
    );

    readonly SvgIcon = SvgIcon;
    readonly AiGenerationActionsDisplayStyle = AiGenerationActionsDisplayStyle;

    readonly postDescriptionFormControl = new FormControl<string>('') as FormControl<string>;

    readonly loadingAnimationDefaultText = signal(
        this._translateService.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
    );
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));

    readonly MAPSTR_POST_TEXT_LIMIT = mapsterPostCaptionTextLimit;
    readonly POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    readonly TEXT_AREA_ID = 'postCaptionTextArea';

    private readonly _foundAccount = signal<IGAccount | undefined>(undefined);
    private readonly _userTagsHistoryAndFoundAccount = computed(() => {
        const foundAccount = this._foundAccount();
        return concat(
            foundAccount ? [{ username: foundAccount.username, count: 1, igAccount: foundAccount }] : [],
            this._upsertSocialPostContext.upsertSocialPostState.userTagsHistory()
        );
    });

    private readonly _searchAccount$ = new Subject<string>();

    readonly mentionConfig = computed(() =>
        this._upsertSocialPostContext.upsertSocialPostState.post.platformKeys().includes(PlatformKey.INSTAGRAM)
            ? {
                  mentions: [
                      {
                          items: this._userTagsHistoryAndFoundAccount(),
                          labelKey: 'username',
                          dropUp: true,
                          mentionSelect: (userTag: { username: string; count: number; igAccount: IGAccount }): string =>
                              `@${userTag.username} `,
                      },
                  ],
              }
            : { mentions: [] }
    );

    private readonly _igPlatformId = computed((): string | null => {
        const connectedPlatforms = this._upsertSocialPostContext.upsertSocialPostState.connectedSocialPlatforms();
        const instagramPlatform = connectedPlatforms.find((platform) => platform.key === PlatformKey.INSTAGRAM);
        return instagramPlatform?._id ?? null;
    });

    constructor() {
        effect(() => {
            const value = this.value();
            if (value !== null && value !== undefined) {
                this.postDescriptionFormControl.setValue(value);
            }
        });

        this.postDescriptionFormControl.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe((value) => {
            this.valueChange.emit(value);
        });

        this._searchAccount$
            .pipe(
                filter(Boolean),
                debounceTime(400),
                switchMap((text) => {
                    const igPlatformId = this._igPlatformId();
                    if (!text || !igPlatformId) {
                        return of(null);
                    }
                    return this._postsService.igSearch(text, igPlatformId).pipe(catchError(() => of({ data: null })));
                }),
                takeUntilDestroyed()
            )
            .subscribe({
                next: (result) => {
                    const account = result?.data?.business_discovery;
                    this._foundAccount.set(account);
                },
                error: () => {
                    this._foundAccount.set(undefined);
                },
            });
    }

    onSearchTerm = (searchTerm: string): void => {
        this._searchAccount$.next(searchTerm);
    };

    onWriteWithAiClick(): void {
        setTimeout(() => document.getElementById('openaiPromptTextarea')?.focus(), 300);
    }

    handleClickOutside(target: Element): void {
        // used to avoid a bug when user fill out the ai settings form
        const modal = document.querySelector(`#${CREATE_SOCIAL_POST_MODAL_HTML_ID}`);
        if (!target?.closest('#aiBtnInsideTextarea') && (!modal || modal.contains(target))) {
            this.upsertSocialPostAiContext.closePostCaptionAiGeneration();
        }
    }

    focusTextArea(): void {
        document.getElementById(this.TEXT_AREA_ID)?.getElementsByTagName('textarea')?.item(0)?.focus();
    }

    openPostCaptionAiGeneration(): void {
        this.upsertSocialPostAiContext.openPostCaptionAiGeneration();
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.post_subject'),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.usual_tone'),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.emojis'),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.results_criteria'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translateService.instant('common.langs.en').toLowerCase(),
                    this._translateService.instant('common.langs.es').toLowerCase(),
                    this._translateService.instant('common.langs.it').toLowerCase(),
                    this._translateService.instant('social_posts.new_social_post.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }
}
