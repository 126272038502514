import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
    catchError,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs';

import { GetStoredInsightsRequestInputBodyDto, StoredInsightsResponseDto } from '@malou-io/package-dto';
import {
    AggregationTimeScale,
    isNotNil,
    MalouComparisonPeriod,
    MalouMetric,
    PlatformFilterPage,
    PlatformKey,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { CommunityInsightsChartData } from ':modules/statistics/social-networks/models/community-insight-chart-data';
import { CommunityChartData } from ':modules/statistics/social-networks/social-networks.interfaces';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { getDayMonthYearFromDate, isDateSetOrGenericPeriod } from ':shared/helpers';
import { SocialNetworkInsights } from ':shared/interfaces/social-network-insights.interface';
import { getInsightsErrorText, Restaurant } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Injectable({
    providedIn: 'root',
})
export class SocialNetworksCommunityInsightsContext {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _insightsService = inject(InsightsService);
    private readonly _store = inject(Store);
    private readonly _translate = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _destroyRef = inject(DestroyRef);

    readonly statisticsFilters$: Observable<StatisticsState['filters']> = this._store.select(StatisticsSelector.selectFilters);
    readonly selectedRestaurant$ = this._restaurantsService.restaurantSelected$.pipe(
        distinctUntilChanged((prev, curr) => prev?._id === curr?._id)
    );

    readonly httpError: WritableSignal<string | null> = signal(null);
    readonly isLoading = signal(true);
    readonly insightsError: WritableSignal<string | null> = signal(null);
    readonly areAllPlatformsInError: WritableSignal<boolean> = signal(false);
    readonly platformsErrorTooltip: WritableSignal<string | null> = signal(null);
    readonly hasDate: WritableSignal<boolean> = signal(false);

    readonly dailyInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly dailyPreviousInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly weeklyInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly weeklyPreviousInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly monthlyInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly monthlyPreviousInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);

    readonly dailyStoredInsightsResponseDtoData = signal<StoredInsightsResponseDto>({} as StoredInsightsResponseDto);
    readonly dailyPreviousStoredInsightsResponseDtoData = signal<StoredInsightsResponseDto>({} as StoredInsightsResponseDto);

    readonly startDate: WritableSignal<string | null> = signal(null);
    readonly endDate: WritableSignal<string | null> = signal(null);

    readonly chartData$ = combineLatest([this.selectedRestaurant$, this.statisticsFilters$]).pipe(
        filter(
            ([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) =>
                !!restaurant &&
                isNotNil(statisticsFilters.dates.startDate) &&
                isNotNil(statisticsFilters.dates.endDate) &&
                isDateSetOrGenericPeriod(statisticsFilters.dates) &&
                statisticsFilters.isFiltersLoaded
        ),
        map(([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) => [
            restaurant,
            {
                startDate: statisticsFilters.dates.startDate,
                endDate: statisticsFilters.dates.endDate,
            },
            statisticsFilters.platforms[PlatformFilterPage.SOCIAL_NETWORKS],
            statisticsFilters.comparisonPeriod,
        ]),
        tap(() => this._reset()),
        switchMap(
            ([restaurant, { startDate, endDate }, platformKeys, comparisonPeriod]: [
                Restaurant,
                { startDate: Date; endDate: Date },
                PlatformKey[],
                MalouComparisonPeriod,
            ]) => {
                const requestBody: GetStoredInsightsRequestInputBodyDto = {
                    restaurantIds: [restaurant._id],
                    platformKeys,
                    startDate: getDayMonthYearFromDate(startDate),
                    endDate: getDayMonthYearFromDate(endDate),
                    metrics: [MalouMetric.FOLLOWERS],
                };
                return forkJoin([
                    this._insightsService.getStoredInsights(requestBody),
                    this._insightsService.getStoredInsights({ ...requestBody, comparisonPeriod }),
                    of(restaurant._id),
                    of(platformKeys),
                    of(comparisonPeriod),
                ]);
            }
        ),
        filter(([current]) => !!current),
        map(([currentInsights, previousInsights, restaurantId, platformKeys, comparisonPeriod]) => {
            const currentRestaurantInsights = currentInsights.data?.[restaurantId];
            const previousRestaurantInsights = previousInsights.data?.[restaurantId];

            const platformsInError = this._getPlatformsInError(currentInsights.data, restaurantId, platformKeys);
            if (platformsInError.length) {
                this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
            }
            if (platformKeys.length === platformsInError.length) {
                this.areAllPlatformsInError.set(true);
                this._setInsightsError(currentRestaurantInsights?.[PlatformKey.FACEBOOK]?.malouErrorCode);
                this.hasDate.set(false);
            } else {
                platformKeys.forEach((platform) => {
                    if (platformsInError.includes(platform)) {
                        if (!currentRestaurantInsights?.[platform]?.hasData) {
                            delete currentRestaurantInsights?.[platform];
                        }
                        if (!previousRestaurantInsights?.[platform]?.hasData) {
                            delete previousRestaurantInsights?.[platform];
                        }
                    }
                });

                this._setActionStoreData({
                    [PlatformKey.FACEBOOK]: currentRestaurantInsights?.[PlatformKey.FACEBOOK]?.insights,
                    [PlatformKey.INSTAGRAM]: currentRestaurantInsights?.[PlatformKey.INSTAGRAM]?.insights,
                });

                this.dailyInsightsChartData.set(
                    this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_DAY)
                );
                this.dailyPreviousInsightsChartData.set(
                    this._getInsightsChartDataByTimeScale(
                        previousInsights.data,
                        restaurantId,
                        AggregationTimeScale.BY_DAY,
                        comparisonPeriod
                    )
                );
                this.weeklyInsightsChartData.set(
                    this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_WEEK)
                );
                this.weeklyPreviousInsightsChartData.set(
                    this._getInsightsChartDataByTimeScale(
                        previousInsights.data,
                        restaurantId,
                        AggregationTimeScale.BY_WEEK,
                        comparisonPeriod
                    )
                );
                this.monthlyInsightsChartData.set(
                    this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_MONTH)
                );
                this.monthlyPreviousInsightsChartData.set(
                    this._getInsightsChartDataByTimeScale(
                        previousInsights.data,
                        restaurantId,
                        AggregationTimeScale.BY_MONTH,
                        comparisonPeriod
                    )
                );

                this.startDate.set(DateTime.fromObject(currentInsights.data.startDate).toISODate());
                this.endDate.set(DateTime.fromObject(currentInsights.data.endDate).toISODate());
                this.dailyStoredInsightsResponseDtoData.set(currentInsights.data);
                this.dailyPreviousStoredInsightsResponseDtoData.set(previousInsights.data);
            }
            this.isLoading.set(false);
        }),
        catchError((error) => {
            this.httpError.set(error);
            this.hasDate.set(false);
            this.isLoading.set(false);
            return of(EMPTY);
        }),
        shareReplay(1),
        takeUntilDestroyed(this._destroyRef)
    );

    private _reset(): void {
        this.httpError.set(null);
        this.insightsError.set(null);
        this.isLoading.set(true);
        this.platformsErrorTooltip.set(null);
        this.areAllPlatformsInError.set(false);
    }

    private _setInsightsError(message?: string): void {
        this.insightsError.set(
            this._translate.instant(getInsightsErrorText(message), {
                platformName: this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key'),
            })
        );
    }

    private _getPlatformsInError(
        storedInsights: StoredInsightsResponseDto,
        restaurantId: string,
        filteredPlatforms: PlatformKey[]
    ): PlatformKey[] {
        return filteredPlatforms.filter((platform) => storedInsights?.[restaurantId]?.[platform]?.malouErrorCode);
    }

    private _getPlatformsErrorTooltip(platformsInError: PlatformKey[]): string {
        return platformsInError.map((platform) => this._enumTranslatePipe.transform(platform, 'platform_key')).join(', ');
    }

    private _getInsightsChartDataByTimeScale(
        data: SocialNetworkInsights,
        restaurantId: string,
        aggregationTimeScale: AggregationTimeScale,
        comparisonPeriod?: MalouComparisonPeriod
    ): CommunityInsightsChartData {
        let startDate = data.startDate;
        let endDate = data.endDate;
        if (comparisonPeriod && comparisonPeriod !== MalouComparisonPeriod.PREVIOUS_PERIOD) {
            startDate = data[restaurantId]!.dateFilters!.startDate;
            endDate = data[restaurantId]!.dateFilters!.endDate;
        }
        return new CommunityInsightsChartData({
            data: {
                [PlatformKey.FACEBOOK]: data[restaurantId]?.[PlatformKey.FACEBOOK]?.insights,
                [PlatformKey.INSTAGRAM]: data[restaurantId]?.[PlatformKey.INSTAGRAM]?.insights,
            },
            startDate,
            endDate,
            aggregationTimeScale,
            comparisonPeriod,
        });
    }

    private _setActionStoreData(data: CommunityChartData): void {
        this._store.dispatch(StatisticsActions.editFollowersRawDataV2({ data }));
    }
}
