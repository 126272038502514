import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, take } from 'rxjs';

import { KeywordSearchImpressionsTypeExtended } from '@malou-io/package-utils';

import { TopKeywordSearchImpressions } from ':modules/aggregated-statistics/seo/models/top-keyword-search-impressions.interface';
import { selectTopKeywordSearchImpressions } from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';

import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from '../csv-service.abstract';

interface Data {
    keywordSearchImpressions: TopKeywordSearchImpressions;
    dates: { startDate: string; endDate: string };
}

@Injectable({ providedIn: 'root' })
export class AggregatedKeywordSearchImpressionsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin([
            this._store.select(selectTopKeywordSearchImpressions).pipe(take(1)),
            this._store.select(AggregatedStatisticsSelector.selectFilters).pipe(take(1)),
        ]).pipe(
            map(([keywordSearchImpressions, filters]) => {
                const monthYearPeriod = filters.monthYearPeriod;
                const startDate = DateTime.fromObject(monthYearPeriod.startMonthYear).startOf('month').toISODate();
                const endDate = DateTime.fromObject(monthYearPeriod.endMonthYear).endOf('month').toISODate();
                return {
                    keywordSearchImpressions,
                    dates: { startDate, endDate },
                };
            })
        );
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Start Date', 'End Date', 'Keyword', 'Type', 'Average Appearances', 'Evolution of Appearances', 'Establishments'];
    }

    protected override _getCsvDataRows(data: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const { keywordSearchImpressions, dates } = data;
        const { startDate, endDate } = dates;
        const csvDataTypes = [KeywordSearchImpressionsTypeExtended.MALOU_SELECTED, KeywordSearchImpressionsTypeExtended.DISCOVERY];
        return csvDataTypes
            .map((type) =>
                keywordSearchImpressions[type].map((impression) => {
                    const keyword = impression.keywordSearch;
                    const averageAppearances = impression.avgValue.toString();
                    const evolutionOfAppearances = (impression.evolution ?? '-').toString();
                    const locations = impression.restaurants.map((restaurant) => restaurant.name).join(', ');
                    return [startDate, endDate, keyword, type, averageAppearances, evolutionOfAppearances, locations];
                })
            )
            .flat();
    }
}
