import { z } from 'zod';

import { urlValidator } from '../../utils';

export const storeLocatorStorePageCallToActionsBlockValidator = z.object({
    title: z.string(),
    links: z.array(
        z.object({
            providerName: z.string(),
            providerUrl: urlValidator(),
        })
    ),
});
