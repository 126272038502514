import { Component, DestroyRef, effect, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';

import { isNotNil, MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { ReviewsAverageAnswerTime } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewsKpisCardComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-card/reviews-kpis-card.component';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatMillisecondsDurationPipe } from ':shared/pipes/format-millisecond-duration.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-reviews-kpis-answer-time',
    templateUrl: './reviews-kpis-answer-time.component.html',
    styleUrls: ['./reviews-kpis-answer-time.component.scss'],
    standalone: true,
    imports: [ReviewsKpisCardComponent, MatIconModule, MatTooltipModule, TranslateModule, FormatMillisecondsDurationPipe],
    providers: [PluralTranslatePipe],
})
export class ReviewsKpisAnswerTimeComponent implements OnInit {
    filters$ = input.required<Observable<[DatesAndPeriod, PlatformKey[], Restaurant, MalouComparisonPeriod]>>();
    hasDataChange = output<boolean>();
    isLoadingEvent = output<boolean>();

    private readonly _reviewsService = inject(ReviewsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;

    readonly currentAverageAnswerTime: WritableSignal<number | undefined> = signal(undefined);
    readonly previousAverageAnswerTime: WritableSignal<number | undefined> = signal(undefined);
    readonly diffAverageAnswerTime: WritableSignal<number | undefined> = signal(undefined);
    readonly comparisonPeriodKey: WritableSignal<string> = signal('');

    readonly httpError: WritableSignal<Error | undefined> = signal(undefined);
    readonly isLoading = signal(true);

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this.filters$()
            .pipe(
                filter(
                    ([dates, platforms, restaurant, _]: [DatesAndPeriod, PlatformKey[], Restaurant, MalouComparisonPeriod]) =>
                        isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant, comparisonPeriod]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        Restaurant,
                        MalouComparisonPeriod,
                    ]): Observable<[ReviewsAverageAnswerTime, ReviewsAverageAnswerTime]> => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;
                        this.comparisonPeriodKey.set(this._getComparisonPeriodKey(comparisonPeriod));
                        return forkJoin([
                            this._reviewsService
                                .getChartAverageAnswerTime({ restaurantId, platforms, startDate, endDate })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                            this._reviewsService
                                .getChartAverageAnswerTime({
                                    restaurantId,
                                    platforms,
                                    startDate,
                                    endDate,
                                    comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
                                })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                        ]);
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([currentAverageAnswerTime, previousAverageAnswerTime]: [ReviewsAverageAnswerTime, ReviewsAverageAnswerTime]) => {
                this.currentAverageAnswerTime.set(this._computeCurrentAverageAnswerTime(currentAverageAnswerTime));
                this.previousAverageAnswerTime.set(this._computeCurrentAverageAnswerTime(previousAverageAnswerTime));
                this.diffAverageAnswerTime.set(
                    this._computeDiffAverageAnswerTime(this.currentAverageAnswerTime(), previousAverageAnswerTime)
                );

                this.hasDataChange.emit(!!this.currentAverageAnswerTime());
                this.isLoading.set(false);
            });
    }

    private _handleHttpError(error: Error): Observable<never> {
        this.httpError.set(error);
        this.hasDataChange.emit(false);
        this.isLoading.set(false);
        return EMPTY;
    }

    private _computeCurrentAverageAnswerTime(currentReviewsEvolutionTotal: ReviewsAverageAnswerTime): number | undefined {
        return currentReviewsEvolutionTotal ?? undefined;
    }

    private _computeDiffAverageAnswerTime(
        currentAverageAnswerTime: number | undefined,
        previousAverageAnswerTime: ReviewsAverageAnswerTime
    ): number | undefined {
        if (previousAverageAnswerTime && currentAverageAnswerTime) {
            return previousAverageAnswerTime - currentAverageAnswerTime;
        }
    }

    private _reset(): void {
        this.httpError.set(undefined);
        this.isLoading.set(true);
    }

    private _getComparisonPeriodKey(comparisonPeriod: MalouComparisonPeriod): string {
        return `date_filter.comparison_period.${comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD}`;
    }
}
