import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { ApiResult, CaslAction, CaslRole, CaslSubject, isNotNil } from '@malou-io/package-utils';

import { selectOpenedFooterCount } from ':core/components/restaurant/footer-manager/store/footer-manager.reducer';
import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { RolesManagerContext } from ':modules/roles/roles-manager.context';
import * as UserActions from ':modules/user/store/user.actions';
import { selectCurrentUserRestaurant } from ':modules/user/store/user.selectors';
import { UserRestaurant } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CaslAblePipe } from ':shared/pipes/casl-able.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';

enum RoleManagerTableColumns {
    AVATAR = 'avatar',
    LASTNAME = 'lastname',
    NAME = 'name',
    EMAIL = 'email',
    UR_ROLE = 'urRole',
    ACTIONS = 'actions',
}

interface TableUserRestaurant {
    _id: string;
    email: string;
    urRole: string;
    restaurantName: string;
    userId: string;
    avatar: string | undefined;
    name: string;
    lastname: string;
}

@Component({
    selector: 'app-roles-manager',
    templateUrl: './roles-manager.component.html',
    styleUrls: ['./roles-manager.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        ScrollToTopComponent,
        SkeletonComponent,
        SortByFiltersComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        SearchComponent,
        LazyLoadImageModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        CaslAblePipe,
        MatMenuModule,
    ],
})
@AutoUnsubscribeOnDestroy()
export class RolesManagerComponent implements OnInit, KillSubscriptions {
    private readonly _usersService = inject(UsersService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _spinner = inject(SpinnerService);
    private readonly _toastService = inject(ToastService);
    private readonly _dialogService = inject(DialogService);
    private readonly _store = inject(Store);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _rolesManagerContext = inject(RolesManagerContext);

    readonly SvgIcon = SvgIcon;
    readonly RoleManagerTableColumns = RoleManagerTableColumns;
    readonly CaslAction = CaslAction;
    readonly CaslSubject = CaslSubject;
    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly usersRestaurants$ = this._restaurantsService.restaurantSelected$.pipe(
        filter(isNotNil),
        switchMap((restaurant) => this._usersService.getUsersForRestaurant(restaurant._id)),
        map((res) => res.data),
        map((ur) => ur.sort((a, b) => (a.userId > b.userId ? 1 : -1)))
    );

    readonly SCROLL_CONTAINER_SELECTOR = '.scrollable';
    readonly DISPLAYED_COLUMNS: RoleManagerTableColumns[] = Object.values(RoleManagerTableColumns);
    readonly TABLE_COLUMNS = RoleManagerTableColumns;
    readonly CASL_ROLES = [
        { key: CaslRole.OWNER, text: this._translateService.instant('roles.roles.owner') },
        { key: CaslRole.EDITOR, text: this._translateService.instant('roles.roles.editor') },
        { key: CaslRole.MODERATOR, text: this._translateService.instant('roles.roles.moderator') },
        { key: CaslRole.GUEST, text: this._translateService.instant('roles.roles.guest') },
    ];
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: RoleManagerTableColumns.LASTNAME, label: this._translateService.instant('roles.manager.lastname') },
        { key: RoleManagerTableColumns.NAME, label: this._translateService.instant('roles.manager.name_header') },
        { key: RoleManagerTableColumns.EMAIL, label: this._translateService.instant('roles.manager.email_header') },
        { key: RoleManagerTableColumns.UR_ROLE, label: this._translateService.instant('roles.manager.role_header') },
    ];
    private readonly _CURRENT_RESTAURANT = this._restaurantsService.currentRestaurant;

    dataSource: MatTableDataSource<TableUserRestaurant> = new MatTableDataSource([]);

    readonly prevSelectedRole: WritableSignal<string> = signal('');
    readonly hasFetchedUsers: WritableSignal<boolean> = signal(false);

    readonly footersVisibilityCount: WritableSignal<number> = signal(0);
    readonly currentUserRestaurant: WritableSignal<UserRestaurant> = signal({} as UserRestaurant);
    readonly shouldDisableAddUserBtn: WritableSignal<boolean> = signal(false);
    readonly usersRestaurants: WritableSignal<UserRestaurant[]> = signal([]);

    readonly addUserBtnTooltip = computed(() => {
        if (!this._CURRENT_RESTAURANT.organization) {
            return this._translateService.instant('roles.manager.no_organization');
        }
        const currentUserRestaurant = this.currentUserRestaurant();
        if (currentUserRestaurant.caslRole && currentUserRestaurant?.caslRole !== CaslRole.OWNER) {
            return this._translateService.instant('roles.manager.no_owner');
        }
        return '';
    });
    readonly isUserOwner = computed(() => this.currentUserRestaurant().caslRole === CaslRole.OWNER);

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
            if (this.dataSource.sort) {
                this.dataSource.sort.active = RoleManagerTableColumns.NAME;
            }
        }
    }

    ngOnInit(): void {
        this.usersRestaurants$.subscribe({
            next: (usersRestaurants) => {
                this.hasFetchedUsers.set(true);
                this.dataSource.data = usersRestaurants
                    .filter((userRestaurant) => !userRestaurant.user.hasBeenDeactivatedByAdmin)
                    .map((userRestaurant) => ({
                        _id: userRestaurant._id,
                        email: userRestaurant.user?.email,
                        name: userRestaurant.user?.name,
                        lastname: userRestaurant.user?.lastname,
                        urRole: userRestaurant?.caslRole,
                        restaurantName: userRestaurant.restaurant?.name,
                        userId: userRestaurant.user?._id,
                        avatar: userRestaurant.user?.profilePicture?.urls?.small,
                    }));
                this.usersRestaurants.set(usersRestaurants);
                this._rolesManagerContext.userCount.set(this.dataSource.filteredData.length);
            },
        });

        this._store
            .select(selectOpenedFooterCount)
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((count) => {
                this.footersVisibilityCount.set(count);
            });

        this._store.select(selectCurrentUserRestaurant).subscribe({
            next: (userRestaurant) => {
                if (!userRestaurant) {
                    return;
                }
                this.currentUserRestaurant.set(userRestaurant);
                this.shouldDisableAddUserBtn.set(userRestaurant?.caslRole !== CaslRole.OWNER || !this._CURRENT_RESTAURANT.organization);
            },
        });
    }

    selectRoleOpen(currentRole: string, hasBeenOpened: boolean): void {
        if (hasBeenOpened) {
            this.prevSelectedRole.set(currentRole);
        }
    }

    changeRole(event: MatSelectChange, ur: TableUserRestaurant): void {
        if (
            this.currentUserRestaurant().caslRole === CaslRole.OWNER &&
            event.value !== CaslRole.OWNER &&
            ur.userId === this.currentUserRestaurant().userId
        ) {
            this._dialogService
                .open({
                    title: this._translateService.instant('common.oh'),
                    message: this._translateService.instant('roles.roles.downgrade_confirm'),
                    variant: DialogVariant.ALERT,
                    primaryButton: {
                        label: this._translateService.instant('common.confirm'),
                        action: () => {
                            this._commitChangeRole(event, ur._id);
                        },
                    },
                    secondaryButton: {
                        label: this._translateService.instant('common.cancel'),
                        action: () => {
                            const currentUrRow = this.dataSource.data.find((e) => e._id === ur._id);
                            if (currentUrRow) {
                                currentUrRow.urRole = this.prevSelectedRole();
                            }
                        },
                    },
                })
                .afterClosed()
                .subscribe();
        } else {
            this._commitChangeRole(event, ur._id);
        }
    }

    _commitChangeRole(event: MatSelectChange, id: string): void {
        this._usersService.updateUserRestaurantById(id, { caslRole: event.value }).subscribe({
            next: () => {
                this._spinner.hide();
                this._store.dispatch({ type: UserActions.loadUser.type });
                this._toastService.openSuccessToast(this._translateService.instant('roles.roles.role_updated'));
            },
            error: (err) => {
                const tableSource = this.dataSource.data.find((source) => source._id === id);
                if (tableSource) {
                    tableSource.urRole = this.prevSelectedRole();
                }
                this._spinner.hide();
                console.warn('err :>> ', err);
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(new HttpErrorPipe(this._translateService).transform(err));
            },
        });
    }

    openAddUser(userId?: string): void {
        const userRestaurant = userId ? this.usersRestaurants().find((ur) => ur.user._id === userId) : undefined;
        this._customDialogService
            .open(NewUserModalComponent, {
                height: undefined,
                width: '800px',
                autoFocus: false,
                data: {
                    userToUpdate: userRestaurant?.user,
                    userRole: userRestaurant?.caslRole,
                    isEdit: !!userId,
                },
            })
            .afterClosed()
            .subscribe({
                next: () => {
                    this._restaurantsService.reloadSelectedRestaurant();
                },
                error: (err) => {
                    console.warn(err);
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(new HttpErrorPipe(this._translateService).transform(err));
                },
            });
    }

    pullOut(ur: TableUserRestaurant, fromAllRestaurants: boolean = false): void {
        this._dialogService.open({
            title: this._translateService.instant('common.are_you_sure'),
            message: this._translateService.instant(
                fromAllRestaurants ? 'roles.roles.pull_out_from_all_locations_confirm' : 'roles.roles.pull_out_confirm'
            ),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translateService.instant('common.delete'),
                action: () => this._removeUserRestaurant(ur, fromAllRestaurants),
            },
            secondaryButton: {
                label: this._translateService.instant('common.cancel'),
            },
        });
    }

    onSearchChange(searchValue: string): void {
        this.dataSource.filter = searchValue.trim().toLowerCase();
        this._rolesManagerContext.userCount.set(this.dataSource.filteredData.length);
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: ChartSortBy.ASC, disableClear: false });
    }

    onSortOrderChange(): void {
        const start = this.dataSource.sort?.direction === ChartSortBy.ASC ? ChartSortBy.DESC : ChartSortBy.ASC;
        this.dataSource.sort?.sort({
            id: this.dataSource.sort.active || RoleManagerTableColumns.NAME,
            start,
            disableClear: false,
        });
    }

    private _removeUserRestaurant(ur: TableUserRestaurant, fromAllLocations: boolean = false): void {
        this._getObservableForDeleteUser(ur, fromAllLocations).subscribe({
            next: () => {
                this._restaurantsService.reloadSelectedRestaurant();
                this._toastService.openSuccessToast(this._translateService.instant('roles.roles.user_removed'));
            },
            error: (err) => {
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(new HttpErrorPipe(this._translateService).transform(err));
            },
        });
    }

    private _getObservableForDeleteUser(ur: TableUserRestaurant, fromAllLocations: boolean = false): Observable<ApiResult> {
        return fromAllLocations
            ? this._usersService.deleteUserFromAllOwnedRestaurants(ur.userId)
            : this._usersService.deleteUserRestaurant(ur._id);
    }
}
