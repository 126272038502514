import { NgTemplateOutlet } from '@angular/common';
import { Component, effect, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { difference, uniq } from 'lodash';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { isNotNil, MalouComparisonPeriod, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewsRating, ReviewsRatingsWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewsRatingsTotalChartComponent } from ':modules/statistics/e-reputation/reviews-ratings-total/reviews-ratings-total-chart/reviews-ratings-total-chart.component';
import { ReviewsRatingsTotalTableComponent } from ':modules/statistics/e-reputation/reviews-ratings-total/reviews-ratings-total-table/reviews-ratings-total-table.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { linkedSignal } from ':shared/helpers/linked-signal';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-reviews-ratings-total',
    templateUrl: './reviews-ratings-total.component.html',
    styleUrls: ['./reviews-ratings-total.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        ReviewsRatingsTotalChartComponent,
        MatProgressSpinnerModule,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        MatButtonToggleModule,
        ReviewsRatingsTotalTableComponent,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewsRatingsTotalComponent implements OnInit, KillSubscriptions {
    readonly statisticsDataViewMode = input<StatisticsDataViewMode>(StatisticsDataViewMode.CHART);

    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();
    readonly onStatisticsDataViewModeChange = output<StatisticsDataViewMode>();

    private readonly _store = inject(Store);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslate = inject(EnumTranslatePipe);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly StatisticsDataViewMode = StatisticsDataViewMode;

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly statisticsFilters$: Observable<StatisticsState['filters']> = this._store.select(StatisticsSelectors.selectFilters);

    readonly labels: WritableSignal<string[]> = signal([]);
    readonly reviewsRatings: WritableSignal<ReviewsRating[]> = signal([]);
    readonly previousReviewsRatings: WritableSignal<ReviewsRating[]> = signal([]);
    readonly selectedViewMode: WritableSignal<StatisticsDataViewMode> = linkedSignal(() => this.statisticsDataViewMode());
    readonly comparisonPeriod: WritableSignal<MalouComparisonPeriod> = signal(MalouComparisonPeriod.PREVIOUS_PERIOD);

    readonly httpError: WritableSignal<any> = signal(null);
    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly noResults: WritableSignal<boolean> = signal(false);
    readonly isSomePlatformGotNoData: WritableSignal<boolean> = signal(false);
    readonly somePlatformGotNoDataErrorMsg: WritableSignal<string> = signal('');

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.restaurant$, this.statisticsFilters$])
            .pipe(
                filter(
                    ([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) =>
                        isNotNil(restaurant) &&
                        isDateSetOrGenericPeriod(statisticsFilters.dates) &&
                        statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION].length > 0 &&
                        statisticsFilters.isFiltersLoaded
                ),
                map(([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) => [
                    statisticsFilters.dates,
                    statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION],
                    restaurant,
                    statisticsFilters.comparisonPeriod,
                ]),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant, comparisonPeriod]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        Restaurant,
                        MalouComparisonPeriod,
                    ]): Observable<[ReviewsRatingsWithRange, ReviewsRatingsWithRange, string[]]> => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;
                        this.comparisonPeriod.set(comparisonPeriod);
                        return forkJoin([
                            this._reviewsService.getChartRestaurantsReviewsRatings({ restaurantId, platforms, startDate, endDate }).pipe(
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService
                                .getChartRestaurantsReviewsRatings({
                                    restaurantId,
                                    platforms,
                                    startDate,
                                    endDate,
                                    comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
                                })
                                .pipe(
                                    catchError((error) => {
                                        this.httpError.set(error);
                                        this.hasDataChange.emit(false);
                                        this.isLoading.set(false);
                                        return EMPTY;
                                    })
                                ),
                            of(platforms),
                        ]);
                    }
                ),
                map(([reviewsRatings, previousReviewsRatings, platforms]: [ReviewsRatingsWithRange, ReviewsRatingsWithRange, string[]]) => {
                    if (!reviewsRatings || reviewsRatings.results.length === 0) {
                        this.isLoading.set(false);
                        this.noResults.set(true);
                        this.hasDataChange.emit(false);
                        return null;
                    }
                    return [reviewsRatings, previousReviewsRatings, platforms];
                }),
                filter((dataOrNull: [ReviewsRatingsWithRange, ReviewsRatingsWithRange, string[]] | null) => !!dataOrNull),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(
                ([reviewsRatings, previousReviewsRatings, platforms]: [ReviewsRatingsWithRange, ReviewsRatingsWithRange, string[]]) => {
                    this._setReviewsRatingsData({
                        reviewsRatings,
                        reviewsRatingState: this.reviewsRatings,
                        platforms,
                        isCurrent: true,
                    });
                    this._setReviewsRatingsData({
                        reviewsRatings: previousReviewsRatings,
                        reviewsRatingState: this.previousReviewsRatings,
                        platforms,
                    });

                    this.isLoading.set(false);
                }
            );
    }

    onViewModeChange(value: StatisticsDataViewMode): void {
        this.selectedViewMode.set(value);
        this.onStatisticsDataViewModeChange.emit(value);
    }

    private _setReviewsRatingsData({
        reviewsRatings,
        reviewsRatingState,
        platforms,
        isCurrent,
    }: {
        reviewsRatings: ReviewsRatingsWithRange;
        reviewsRatingState: WritableSignal<ReviewsRating[]>;
        platforms: string[];
        isCurrent?: boolean;
    }): void {
        const { results } = reviewsRatings;
        if (results.length === 0) {
            reviewsRatingState.set([]);
            return;
        }
        reviewsRatingState.set(
            results.sort((a, b) => {
                if (!a.rating) {
                    return 1;
                }
                if (!b.rating) {
                    return -1;
                }
                return b.rating - a.rating;
            })
        );

        if (isCurrent) {
            this._checkIfPlatformsGoNoData(results, platforms);
            this._store.dispatch(StatisticsActions.editReviewsRatingsTotalData({ data: [...reviewsRatings.results] }));
            this.labels.set(this.reviewsRatings().map((e) => (e.rating ?? 0).toString()));
        }
    }

    private _checkIfPlatformsGoNoData(reviewsRatings: ReviewsRating[], platforms: string[]): void {
        const platformsWithData: string[] = uniq(reviewsRatings.flatMap((e) => e.platforms).map((e) => e.key));
        const platformsWithNoData: string[] = difference(platforms, platformsWithData).map((platformKey) =>
            this._enumTranslate.transform(platformKey, 'platform_key')
        );
        if (platformsWithNoData.length > 0) {
            const baseMsg = this._translateService.instant('statistics.e_reputation.reviews_ratings.some_platforms_got_no_data');
            this.somePlatformGotNoDataErrorMsg.set(`${baseMsg} ${platformsWithNoData.join(', ')}`);
            this.isSomePlatformGotNoData.set(true);
        } else {
            this.isSomePlatformGotNoData.set(false);
        }
    }

    private _reset(): void {
        this.httpError.set(null);
        this.isLoading.set(true);
        this.noResults.set(false);
    }
}
