import { AggregatedUserFiltersResponseDto, UserRestaurantFiltersResponseDto } from '@malou-io/package-dto';
import {
    AggregatedReviewsFilters,
    AggregatedStatisticsFilters,
    MalouPeriod,
    ReviewsPerRestaurantFilters,
    StatisticsPerRestaurantFilters,
} from '@malou-io/package-utils';

import { getRealDateByPeriod } from ':shared/helpers/get-real-date-by-period';

export class AggregatedUserFilters {
    id: string;
    aggregatedStatisticsFilters: AggregatedStatisticsFilters;
    aggregatedReviewsFilters: AggregatedReviewsFilters;

    constructor(init: AggregatedUserFiltersResponseDto) {
        this.id = init.id;
        this.aggregatedStatisticsFilters = {
            dates: getRealDate({
                ...init.aggregatedStatisticsFilters.dates,
            }),
            platforms: init.aggregatedStatisticsFilters.platforms,
            restaurantIds: init.aggregatedStatisticsFilters.restaurantIds,
            roiRestaurantIds: init.aggregatedStatisticsFilters.roiRestaurantIds,
            totemIds: init.aggregatedStatisticsFilters.totemIds,
            timeScale: init.aggregatedStatisticsFilters.timeScale,
            monthYearPeriod: init.aggregatedStatisticsFilters.monthYearPeriod,
        };
        const aggregatedReviewsFiltersPeriod = init.aggregatedReviewsFilters.filters.period;
        this.aggregatedReviewsFilters = {
            ...init.aggregatedReviewsFilters,
            filters: {
                ...init.aggregatedReviewsFilters.filters,
                period: aggregatedReviewsFiltersPeriod === MalouPeriod.CUSTOM ? MalouPeriod.ALL : aggregatedReviewsFiltersPeriod,
            },
        };
    }
}

export class UserRestaurantFilters {
    id: string;
    restaurantId: string;
    statisticsFilters: StatisticsPerRestaurantFilters['filters'];
    reviewsFilters: ReviewsPerRestaurantFilters['filters'];

    constructor(init: UserRestaurantFiltersResponseDto) {
        this.id = init.id;
        this.restaurantId = init.restaurantId;
        this.statisticsFilters = {
            ...init.statisticsFilters,
            dates: getRealDate({
                ...init.statisticsFilters.dates,
            }),
        };
        this.reviewsFilters = {
            ...init.reviewsFilters,
            period: init.reviewsFilters.period === MalouPeriod.CUSTOM ? MalouPeriod.ALL : init.reviewsFilters.period,
        };
    }
}

function dateStringToDate(date: string | null): Date | null {
    return date ? new Date(date) : null;
}

function getRealDate({ endDate, period, startDate }: AggregatedUserFiltersResponseDto['aggregatedStatisticsFilters']['dates']): {
    startDate: Date | null;
    endDate: Date | null;
    period: MalouPeriod;
} {
    if (period !== MalouPeriod.CUSTOM) {
        return {
            startDate: getRealDateByPeriod(
                {
                    period,
                    startDate: dateStringToDate(startDate),
                    endDate: dateStringToDate(endDate),
                },
                'startDate'
            ),
            endDate: getRealDateByPeriod(
                {
                    period,
                    startDate: dateStringToDate(startDate),
                    endDate: dateStringToDate(endDate),
                },
                'endDate'
            ),
            period,
        };
    }

    return {
        startDate: dateStringToDate(startDate),
        endDate: dateStringToDate(endDate),
        period: period,
    };
}
