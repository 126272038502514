<div class="flex items-center gap-4 md:flex-col md:items-start">
    <div class="md:w-full">
        @switch (datePickerType()) {
            @case (DatePickerType.MONTH_YEAR) {
                <ng-container [ngTemplateOutlet]="monthYearDatePickerTemplate"></ng-container>
            }
            @case (DatePickerType.TIME_SCALE) {
                <ng-container [ngTemplateOutlet]="timeScaleFilterTemplate"></ng-container>
            }
            @default {
                <ng-container [ngTemplateOutlet]="groupedDateFiltersTemplate"></ng-container>
            }
        }
    </div>

    @if (showPlatformsFilter()) {
        <div class="min-w-0 grow md:w-full">
            <app-select-platforms
                [values]="connectedPlatforms"
                [formControl]="platformsFilterControl"
                (selectPlatformsChange)="onPlatformsChange($event)">
            </app-select-platforms>
        </div>
    }

    @if (showTotemsFilter()) {
        <div class="min-w-72 md:w-full md:max-w-[90vw]">
            <app-select-chip-list
                [values]="restaurantTotems()"
                [formControl]="totemsFilterControl"
                [displayWith]="totemsDisplayWith"
                [computeObjectHash]="getTotemHash"
                [checkboxOption]="true"
                [groupSelectedValuesAtTop]="true"
                [showSelectAllCheckbox]="true"
                (selectChipListChange)="onTotemsChange($event)">
            </app-select-chip-list>
        </div>
    }
</div>

<ng-template #groupedDateFiltersTemplate>
    @if (isReleaseNewCalendarEnabled()) {
        <app-grouped-date-filters-v2
            [startDate]="startDate()"
            [endDate]="endDate()"
            [comparisonPeriod]="comparisonPeriod()"
            [defaultPeriod]="period()"
            [periodOptions]="DEFAULT_PERIODS"
            [withLabel]="!screenSizeService.isPhoneScreen"
            [blockFutureDates]="true"
            [isLeftMaxDateToday]="true"
            [shouldShowComparisonPeriodSelector]="shouldShowComparisonPeriodSelector()"
            [restaurantStartDate]="restaurantStartDate()"
            (chooseBoundaryDateAndComparisonPeriod)="chooseBoundaryDateAndPeriod($event)">
        </app-grouped-date-filters-v2>
    } @else {
        <app-grouped-date-filters
            [startDate]="startDate()"
            [endDate]="endDate()"
            [defaultPeriod]="period()"
            [periodOptions]="DEFAULT_PERIODS"
            [withLabel]="!screenSizeService.isPhoneScreen"
            [blockFutureDates]="true"
            [isLeftMaxDateToday]="true"
            (chooseBoundaryDate)="chooseBoundaryDate($event)">
        </app-grouped-date-filters>
    }
</ng-template>

<ng-template #timeScaleFilterTemplate>
    <app-select-time-scale-filter
        [formControl]="timeScaleFilterControl"
        [minAcceptedDate]="timeScaleMinAcceptedDate()"
        (selectTimeScaleChange)="onTimeScaleChange($event)" />
</ng-template>

<ng-template #monthYearDatePickerTemplate>
    <app-month-year-date-picker-v2
        [maxRangeInMonths]="DEFAULT_MAX_MONTH_YEAR_RANGE"
        [initialMonthYearPeriod]="monthYearPeriod()"
        (monthYearPeriodChanged)="onMonthYearPeriodChanged($event)">
    </app-month-year-date-picker-v2>
</ng-template>
