<div class="flex h-full flex-col gap-5">
    <div class="flex items-center justify-between px-5 pt-5">
        <div class="malou-text-18--bold text-malou-color-text-1">
            {{ 'social_posts.post_date_picker_modal.title' | translate }}
        </div>

        <button class="malou-btn-icon !rounded-full !bg-malou-color-background-dark" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="flex min-w-0 grow justify-center px-5">
        <div class="w-[300px]">
            <mat-calendar
                [(selected)]="selectedDate"
                [headerComponent]="CustomMatCalendarHeaderComponent"
                [minDate]="now"
                #calendar></mat-calendar>
        </div>
    </div>

    <div class="flex flex-col">
        <div class="flex items-start justify-center gap-8 bg-malou-color-background-light px-12 py-4">
            <div class="flex h-[52px] w-1/2 items-center gap-x-2 rounded-[5px] border border-malou-color-border-primary px-4 py-2">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                <div class="malou-text-12--medium text-malou-color-text-1">{{ formattedDate() }}</div>
            </div>

            <app-simple-select
                class="w-1/2"
                placeholder="----"
                [defaultValue]="selectedTime()"
                [values]="filteredTimes()"
                [errorMessage]="
                    (isPastHour | applyPure: { hourWithMinute: selectedTime(), date: selectedDate() })
                        ? ('common.invalid_time' | translate)
                        : ''
                "
                (simpleSelectChange)="onInputTextChange($event)">
                <ng-template let-value="value" #selectedValueTemplate>
                    <div class="malou-text-12--medium mt-[1px] text-malou-color-text-1">
                        @if (value) {
                            {{ value | formatTime: locale === 'en' }}
                        }
                    </div>
                </ng-template>

                <ng-template let-option="option" #optionTemplate>
                    <div class="malou-text-12--regular text-malou-color-text-2">
                        {{ option | formatTime }}
                    </div>
                </ng-template>
            </app-simple-select>
        </div>

        <div class="flex items-center justify-end gap-3 p-5">
            <button class="malou-btn-raised--secondary !h-11 !rounded-[5px] md:grow" mat-raised-button (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            <app-button
                buttonClasses="!h-11 md:w-full !rounded-[5px]"
                [text]="'common.save' | translate"
                [disabled]="isDisabled()"
                (buttonClick)="save()"></app-button>
        </div>
    </div>
</div>
