import { CdkDrag, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { LowerCasePipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    Component,
    computed,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    input,
    OnDestroy,
    output,
    Signal,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { DEFAULT_MAX_IMAGE_SIZE } from '@malou-io/package-utils';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaService } from ':modules/media/media.service';
import { pickImagesFromVideo } from ':shared/helpers/video-cover-url';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

import { Media } from '../../models';
import { VideoMediaAnalyzer } from '../../models/media-analyzer';
import { CustomDialogService } from '../../services/custom-dialog.service';
import { MediaFileUploaderComponent } from '../media-file-uploader/media-file-uploader.component';

export enum ThumbnailType {
    /** The thumbnail is a frame of the original video */
    VIDEO_FRAME,

    /** The thumbnail is a custom picture (not extracted from the original video) */
    CUSTOM,
}

const THUMBNAIL_SIZE_PX = 75;

export type Thumbnail =
    | { type: ThumbnailType.CUSTOM; media: Media }
    | { type: ThumbnailType.VIDEO_FRAME; timestampSeconds: number; url?: string };

@Component({
    selector: 'app-media-thumbnail-picker',
    templateUrl: './media-thumbnail-picker.component.html',
    styleUrls: ['./media-thumbnail-picker.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        NgStyle,
        CdkDrag,
        MatIconModule,
        TranslateModule,
        MediaFileUploaderComponent,
        LowerCasePipe,
        ApplySelfPurePipe,
    ],
})
export class MediaThumbnailPickerComponent implements OnDestroy {
    // INPUTS
    public readonly media = input.required<Media>();
    public readonly thumbnail = input<Thumbnail>();

    /** Output: called when the thumbnail changes. */
    public readonly thumbnailChange = output<Thumbnail>();
    /**
     * Output: this callback is regularly called with a number that increases progressively
     * from 0 to 1 while the component is loading. This is designed to display progress
     * bars. 1 means that the component is loaded and works.
     */
    public readonly onLoading = output<number>();

    // INTERNAL PROPERTIES
    readonly sliderBackgroundImageUrls: WritableSignal<string[]> = signal([]);
    readonly isLoadingSliderImages: WritableSignal<boolean> = signal(true);

    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;
    readonly ThumbnailType = ThumbnailType;

    readonly sliderThumbnailUrl: WritableSignal<string | undefined> = signal(undefined);

    readonly thumbnailUrl: Signal<string | undefined> = computed(() => {
        const t = this.thumbnail();
        if (t === undefined) {
            return this.sliderThumbnailUrl();
        }
        if (t.type === ThumbnailType.CUSTOM) {
            return t.media.getMediaUrl('igFit');
        }
        return t.url ?? this.sliderThumbnailUrl();
    });

    readonly SvgIcon = SvgIcon;

    private _destroyRef = inject(DestroyRef);

    private readonly _cdkDrag = viewChild<CdkDrag>('dragElement');
    private readonly _sliderBackgroundContainerRef = viewChild<ElementRef>('sliderBackgroundContainer');
    private readonly _sliderRef = viewChild<ElementRef>('slider');
    private readonly _mediaDurationInSeconds: WritableSignal<number | undefined> = signal(undefined);

    /** Undefined if the thumbnail is a custom one. */
    private readonly _thumbnailTimestampSeconds: Signal<number | undefined> = computed(() => {
        const t = this.thumbnail();
        if (t && t.type === ThumbnailType.VIDEO_FRAME) {
            return t.timestampSeconds;
        }
    });

    /**
     * A float between 0 and 1, where 0 is the very start of the video and 1 is the very end.
     * Undefined if the thumbnail is a custom thumbnail.
     */
    private readonly _thumbnailPositionFloat: Signal<number | undefined> = computed(() => {
        const duration = this._mediaDurationInSeconds();
        const offset = this._thumbnailTimestampSeconds();
        if (duration === undefined || offset === undefined) {
            return undefined;
        }

        return Math.min(1, offset / duration);
    });

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _spinnerService: SpinnerService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        private readonly _mediaService: MediaService
    ) {
        // Move the thumbnail slider when the thumbnail timestamp changes
        effect(() => {
            const thumbnailPositionFloat = this._thumbnailPositionFloat();
            if (thumbnailPositionFloat === undefined) {
                return;
            }

            const barWidthPx = this._getSlideBarPxWidth();
            if (barWidthPx === undefined) {
                return;
            }
            const sliderWidthPx = this._getSliderPxWidth();
            if (sliderWidthPx === undefined) {
                return;
            }

            const x = thumbnailPositionFloat * (barWidthPx - sliderWidthPx);
            this._cdkDrag()?.setFreeDragPosition({ x, y: 0 });
        });

        // Refresh the thumbnail picture when the thumbnail timestamp changes
        effect(() => {
            if (this.sliderThumbnailUrl()) {
                return;
            }
            const timestampSeconds = this._thumbnailTimestampSeconds();
            this.media()
                .getVideoCoverUrl({
                    timestampSeconds: timestampSeconds ?? 0,
                    resolutionPx: THUMBNAIL_SIZE_PX * window.devicePixelRatio,
                })
                .then((res) => this.sliderThumbnailUrl.set(res.url));
        });

        effect(() => {
            const mediaAnalyzer = new VideoMediaAnalyzer(this._mediaService);
            mediaAnalyzer.getMetadata(this.media().getMediaUrl('igFit')).then(
                (metadata) => this._mediaDurationInSeconds.set(metadata.duration as number),
                (error) => console.warn('Error when loading video file', error)
            );
        });

        effect(
            () => {
                const media = this.media();
                this.isLoadingSliderImages.set(true);

                pickImagesFromVideo({
                    videoUrl: media.getMediaUrl('igFit'),
                    videoDuration: media.duration,
                    resolutionPx: THUMBNAIL_SIZE_PX * window.devicePixelRatio,
                    numberOfImages: 5,
                    onProgress: (progress: number) => this.onLoading.emit(progress),
                }).then(
                    (imageUrls) => {
                        this.onLoading.emit(1);
                        this.sliderBackgroundImageUrls.set(imageUrls);
                        this.isLoadingSliderImages.set(false);
                    },
                    (error) => console.error('MediaThumbnailPickerComponent: error loading slider images', error)
                );
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        for (const url of this.sliderBackgroundImageUrls()) {
            window.URL.revokeObjectURL(url);
        }
    }

    public onSlideBarBackgroundClick(event: MouseEvent) {
        event.preventDefault();
        const containerRef = this._sliderBackgroundContainerRef();
        if (!containerRef) {
            return;
        }
        const containerRect = containerRef.nativeElement.getBoundingClientRect();
        const positionFloat = Math.max(0, Math.min(1, (event.clientX - containerRect.x) / containerRect.width));

        const durationSecs = this._mediaDurationInSeconds();
        if (durationSecs === undefined) {
            return;
        }

        this._updateVideoFrameThumbnail(positionFloat * durationSecs);
    }

    public onDragEnded(_event: CdkDragEnd): void {
        const pos = this._getSliderPositionFloat();
        if (pos === undefined) {
            return;
        }
        const durationSecs = this._mediaDurationInSeconds();
        if (durationSecs === undefined) {
            return;
        }

        this._updateVideoFrameThumbnail(pos * durationSecs);
    }

    resetThumbnail(): void {
        const sliderBackgroundImageUrls = this.sliderBackgroundImageUrls();
        if (!sliderBackgroundImageUrls.length) {
            return;
        }
        this.thumbnailChange.emit({
            type: ThumbnailType.VIDEO_FRAME,
            timestampSeconds: 0.0,
            url: sliderBackgroundImageUrls[0],
        });
        this.sliderThumbnailUrl.set(sliderBackgroundImageUrls[0]);
    }

    public openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: false,
                    filter: MediaPickerFilter.ONLY_IMAGE,
                },
            })
            .afterClosed()
            .pipe(
                map((medias: Media[]) => {
                    if (medias?.length > 0) {
                        return medias[0];
                    }
                    return null;
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (media: Media | null) => {
                    if (media) {
                        this.thumbnailChange.emit({ type: ThumbnailType.CUSTOM, media });
                    }
                },
                error: (err) => {
                    console.warn('Error when getting thumbnail from video', err);
                },
            });
    }

    onFinishUpload(createdMedias: Media[]): void {
        this._spinnerService.hide();
        if (createdMedias.length > 0) {
            this.thumbnailChange.emit({
                type: ThumbnailType.CUSTOM,
                media: createdMedias[0],
            });
        }
    }

    startReadingFile(): void {
        this._spinnerService.show();
    }

    processError(error: Error | string): void {
        console.error('Error during uploading image', error);
        this._spinnerService.hide();
        this._toastService.openErrorToast(this._translate.instant('media_thumbnail_slider.error_uploading_image'));
    }

    private _isCdkDragEnd(event: CdkDragEnd | CdkDragMove): event is CdkDragEnd {
        return (event as CdkDragEnd).distance !== undefined;
    }

    private _getSlideBarPxWidth(): number | undefined {
        const containerRef = this._sliderBackgroundContainerRef();
        if (!containerRef) {
            return;
        }
        return containerRef.nativeElement.offsetWidth;
    }

    private _getSliderPxWidth(): number | undefined {
        const ref = this._sliderRef();
        if (!ref) {
            return;
        }
        return ref.nativeElement.getBoundingClientRect().width;
    }

    private _getSliderElement(): HTMLElement | undefined {
        return this._sliderRef()?.nativeElement;
    }

    /** returns a number between 0 and 1 or undefined */
    private _getSliderPositionFloat(): number | undefined {
        const barWidthPx = this._getSlideBarPxWidth();
        if (barWidthPx === undefined) {
            return;
        }
        const d = this._cdkDrag();
        if (d === undefined) {
            return;
        }
        const sliderWidth = this._getSliderPxWidth();
        if (sliderWidth === undefined) {
            return;
        }
        return Math.max(0, Math.min(1, d.getFreeDragPosition().x / (barWidthPx - sliderWidth)));
    }

    private _updateVideoFrameThumbnail(timestampSeconds: number): void {
        this.media()
            .getVideoCoverUrl({
                timestampSeconds,
                resolutionPx: THUMBNAIL_SIZE_PX * window.devicePixelRatio,
            })
            .then(
                (res) => {
                    this.thumbnailChange.emit({
                        type: ThumbnailType.VIDEO_FRAME,
                        timestampSeconds,
                        url: res.url,
                    });
                    this.sliderThumbnailUrl.set(res.url);
                },
                (error) => console.warn('Error when loading video file', error)
            );
    }
}
