import { Component, computed, effect, input, Input, OnInit, output, Signal, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { partition } from 'lodash';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { getReviewsForSemanticAnalysis, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { TagsBarChartComponent } from ':shared/components/review-analyses/tags-bar-chart/tags-bar-chart.component';
import { TagsDoughnutChartComponent } from ':shared/components/review-analyses/tags-doughnut-chart/tags-doughnut-chart.component';
import { TagsEvolutionComponent } from ':shared/components/review-analyses/tags-evolution/tags-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant, ReviewWithAnalysis } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-review-analyses',
    templateUrl: './review-analyses.component.html',
    styleUrls: ['./review-analyses.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        TagsBarChartComponent,
        TagsDoughnutChartComponent,
        TagsEvolutionComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewAnalysesComponent implements OnInit, KillSubscriptions {
    readonly shouldDetailTagsEvolutionCharts = input(false);
    readonly shouldDisplayAnalysesTagCharts = input(true);
    readonly shouldDisplayAnalysesTagEvolution = input(true);
    readonly showSplitByRestaurant = input(true);
    readonly showSeeMoreButton = input(false);
    readonly tagsEvolutionSortBy = input<ChartSortBy | undefined>(undefined);
    readonly shouldDisplayAnalysesTagClickableLabels = input(true);
    readonly tagsEvolutionSortByChange = output<ChartSortBy>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    @Input() dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(AggregatedStatisticsSelectors.selectDatesFilter)
        .pipe(map(({ startDate, endDate }) => ({ startDate, endDate })));
    @Input() platformKeys$: Observable<PlatformKey[]> = this._store
        .select(AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(
            map((platforms) => {
                if (platforms?.length) {
                    return platforms;
                }
                return Object.values(PlatformKey);
            })
        );
    @Input() selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    readonly SvgIcon = SvgIcon;

    readonly detailHref = environment.BASE_URL + '/groups/statistics/e-reputation';

    isLoading = signal(true);
    isAnalysisDisabledOnAllRestaurants = signal(false);
    noData = signal(false);
    warningTooltip = signal<string | undefined>(undefined);
    reviewsByRestaurantId = signal<Record<string, ReviewWithAnalysis[]>>({});
    allReviews: Signal<ReviewWithAnalysis[]> = computed(() => Object.values(this.reviewsByRestaurantId()).flat());

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _store: Store,
        private readonly _reviewsService: ReviewsService,
        private readonly _translate: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        combineLatest([this.dates$, this.platformKeys$, this.selectedRestaurants$])
            .pipe(
                filter(
                    ([dates, platforms, _restaurants]) =>
                        !!_restaurants?.length && !!dates.startDate && !!dates.endDate && platforms.length > 0
                ),
                tap(() => this._reset()),
                map(([dates, platforms, restaurants]) => {
                    const [businessRestaurant, nonBusinessRestaurants] = partition(restaurants, (r) => r.isBrandBusiness());
                    this.warningTooltip.set(this._computeWarningTooltip(businessRestaurant));
                    return [dates, platforms, nonBusinessRestaurants];
                }),
                filter(([_dates, _platforms, restaurants]: [DatesAndPeriod, PlatformKey[], Restaurant[]]) => {
                    if (restaurants.length === 0) {
                        this.isLoading.set(false);
                        this.isAnalysisDisabledOnAllRestaurants.set(true);
                        this.hasDataChange.emit(false);
                        return false;
                    }
                    return true;
                }),
                switchMap(([dates, platforms, restaurants]) => {
                    const { startDate, endDate } = dates;
                    return forkJoin(
                        restaurants
                            .map((r) => r._id)
                            .reduce(
                                (acc, restaurantId) => ({
                                    ...acc,
                                    [restaurantId]: this._reviewsService.getReviewsWithAnalysis(
                                        startDate,
                                        endDate,
                                        platforms,
                                        restaurantId
                                    ),
                                }),
                                {}
                            )
                    );
                }),
                filter((res: Record<string, ReviewWithAnalysis[]>) => {
                    if (
                        Object.values(res)
                            .map((r) => r.length)
                            .reduce((acc, cur) => acc + cur, 0) === 0
                    ) {
                        this.isLoading.set(false);
                        this.hasDataChange.emit(false);
                        this.noData.set(true);
                        return false;
                    }
                    return true;
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (res: Record<string, ReviewWithAnalysis[]>) => {
                    this.reviewsByRestaurantId.set(
                        Object.entries(res).reduce((acc, [key, value]) => {
                            acc[key] = getReviewsForSemanticAnalysis(value);
                            return acc;
                        }, {})
                    );
                    this.isLoading.set(false);
                },
            });
    }

    private _computeWarningTooltip(restaurants: Restaurant[]): string | undefined {
        if (!restaurants.length) {
            return;
        }
        const restaurantsLabel = restaurants.map((e) => e.name).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.isAnalysisDisabledOnAllRestaurants.set(false);
        this.noData.set(false);
        this.warningTooltip.set(undefined);
    }
}
