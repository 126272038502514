import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { isNotNil } from '@malou-io/package-utils';

import { AggregatedBoostersStatisticsDataV2 } from ':modules/aggregated-statistics/boosters/booster.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName {
    BUSINESS = 'business',
    REVIEWS = 'reviews',
    EVOLUTION = 'evolution',
}

interface AggregatedWheelOfFortuneEstimatedReviewCountTableData {
    restaurantId: string;
    restaurantName: string;
    estimatedReviewCount: number;
    estimatedReviewCountDifferenceWithPreviousPeriod: number;
}

@Component({
    selector: 'app-aggregated-wheel-of-fortune-estimated-review-count-v2',
    templateUrl: './aggregated-wheel-of-fortune-estimated-review-count.component.html',
    styleUrls: ['./aggregated-wheel-of-fortune-estimated-review-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        ReactiveFormsModule,
        TranslateModule,
        NumberEvolutionComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        MatIconModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedWheelOfFortuneEstimatedReviewCountV2Component implements OnInit {
    readonly tableSort = input<Sort>();
    readonly data = input<AggregatedBoostersStatisticsDataV2['estimatedReviewCountPerRestaurant'] | null>();
    readonly restaurants = input<Restaurant[]>();
    readonly isParentLoading = input<boolean>(true);
    readonly isParentError = input<boolean>(false);
    readonly tableSortByChange = output<Sort>();
    readonly hasDataChange = output<boolean>();

    private readonly _router = inject(Router);

    readonly Illustration = Illustration;
    readonly TableFieldName = AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName;
    readonly SvgIcon = SvgIcon;

    readonly displayedColumns = Object.values(AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName);
    readonly trackByIdFn = TrackByFunctionFactory.get('restaurantId');
    readonly dataSource: MatTableDataSource<AggregatedWheelOfFortuneEstimatedReviewCountTableData> =
        new MatTableDataSource<AggregatedWheelOfFortuneEstimatedReviewCountTableData>([]);

    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly isError: WritableSignal<boolean> = signal(false);

    defaultSort: Sort = {
        active: AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.REVIEWS,
        direction: ChartSortBy.DESC,
    };

    readonly hasData: Signal<boolean> = computed(() => {
        const data = this.data();
        if (data) {
            this.hasDataChange.emit(Object.keys(data).length > 0);
            return Object.keys(data).length > 0;
        }
        return false;
    });

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    case AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.BUSINESS:
                        return item.restaurantName;
                    case AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.REVIEWS:
                        return item.estimatedReviewCount;
                    case AggregatedWheelOfFortuneEstimatedReviewCountTableFieldName.EVOLUTION:
                        return item.estimatedReviewCountDifferenceWithPreviousPeriod;
                    default:
                        return '';
                }
            };
            this.dataSource.sort = sort;
        }
    }

    readonly matTableData = computed(() => {
        const data = this.data();
        const restaurants = this.restaurants();
        if (!data || !restaurants) {
            return [];
        }
        return Object.keys(data)
            .map((restaurantId) => {
                const restaurant = restaurants.find((r) => r.id === restaurantId);
                if (!restaurant) {
                    return null;
                }
                return {
                    restaurantId,
                    restaurantName: restaurant?.getDisplayName() || '',
                    estimatedReviewCount: data[restaurantId].estimatedReviewCount,
                    estimatedReviewCountDifferenceWithPreviousPeriod: data[restaurantId].estimatedReviewCountDifferenceWithPreviousPeriod,
                };
            })
            .filter(isNotNil);
    });

    constructor() {
        effect(
            () => {
                this._updateMatTableData();
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        const tableSort = this.tableSort();
        if (tableSort) {
            this.defaultSort = tableSort;
        }
    }

    redirectToRestaurantBoosterStatsPage(restaurantId: string): void {
        this._router.navigate([`/restaurants/${restaurantId}/statistics/boosters`]);
    }

    private _updateMatTableData(): void {
        try {
            this.isLoading.set(true);
            this.isError.set(false);
            this.dataSource.data = this.matTableData();
            this.isLoading.set(false);
            this.isError.set(false);
        } catch (error) {
            console.warn(error);
            this.isError.set(true);
            this.isLoading.set(false);
        }
    }
}
