import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { BaseEmailProps, concernedRestaurantPropsValidator, ctaValidator, reviewPropsValidator } from '../reports';

export const downloadReviewsRestaurantValidator = z.object({
    name: z.string(),
    address: z.string(),
    image: z.string(),
    alt: z.string().optional(),
    reviewsCount: z.object({
        totalReviewsCount: z.number(),
        nonAnsweredReviewsCount: z.number(),
    }),
});
//--------------------------------------------

export const downloadRestaurantReviewsDataValidator = z.object({
    header: downloadReviewsRestaurantValidator,
    cta: ctaValidator,
    reviewsGroupedByPlatform: z.record(z.nativeEnum(PlatformKey), z.array(reviewPropsValidator)),
    bordered: z.boolean(),
});

export type DownloadRestaurantReviewsDataProps = z.infer<typeof downloadRestaurantReviewsDataValidator> & BaseEmailProps;

//--------------------------------------------

export const downloadReviewsStatsValidator = z.object({
    stats: z.object({
        reviewCount: z.number(),
        notAnsweredReviewCount: z.number(),
        averageReviewsRating: z.number(),
    }),
    restaurantCount: z.number(),
    aiGlobalAnalysisCta: z.object({
        noticeText: z.string().optional(),
        link: z.string(),
    }),
    noReviewsCta: z.object({
        link: z.string(),
    }),
});

export type DownloadReviewsStatsProps = z.infer<typeof downloadReviewsStatsValidator>;

//--------------------------------------------

export const downloadReviewsTemplateValidator = z.object({
    reviewsStats: downloadReviewsStatsValidator,
    restaurantsReviews: z.array(downloadRestaurantReviewsDataValidator),
    concernedRestaurants: z.array(concernedRestaurantPropsValidator),
    period: z.object({
        startDate: z.date(),
        endDate: z.date(),
        timeZone: z.string(),
    }),
});

export type DownloadReviewsTemplateProps = z.infer<typeof downloadReviewsTemplateValidator> & BaseEmailProps;

//--------------------------------------------

export const basicReviewValidator = z.object({
    reviewerProfilePictureUrl: z.string().optional(),
    socialAttachmentUrls: z.array(z.string()).optional(),
    reviewerName: z.string(),
    rating: z.number(),
    text: z.string().optional(),
    socialCreatedAt: z.date(),
    platformName: z.string(),
    platformKey: z.nativeEnum(PlatformKey),
});
export type BasicReviewProps = z.infer<typeof basicReviewValidator>;
