import { NgClass, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    Injector,
    input,
    OnDestroy,
    output,
    signal,
    viewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage, OutputFormat } from 'ngx-image-cropper';

import { IGAccount, PictureSize } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { CropOption } from ':shared/enums/crop-options';
import { computeTransformDataStyles } from ':shared/helpers/compute-transfrom-data-styles';
import { parseAspectRatio } from ':shared/helpers/dimensions';
import { Media, UserTag } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { TagAccountComponent } from '../tag-account/tag-account.component';

@Component({
    selector: 'app-media-view',
    templateUrl: './media-view.component.html',
    styleUrls: ['./media-view.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, NgClass, ImageCropperModule, NgStyle, ApplyPurePipe, TagAccountComponent, MalouSpinnerComponent],
})
export class MediaViewComponent implements OnDestroy {
    private readonly _injector = inject(Injector);

    readonly media = input.required<Media>();
    readonly originalMedia = input.required<Media>();
    readonly isCropping = input.required<boolean>();
    readonly imageCropperMediaLoading = input.required<boolean>();

    readonly state = input<string>();
    readonly angle = input<number>();
    readonly ratio = input<number>();
    readonly format = input<OutputFormat>();

    readonly cropSize = input.required<CropOption>();
    readonly maxMedia = input.required<number>();
    readonly isMediaDeletable = input.required<boolean>();
    readonly disabled = input.required<boolean>();
    readonly tagButtonToggled = input.required<boolean>();
    readonly showTagContainer = input.required<boolean>();
    readonly userTags = input.required<UserTag[]>();
    readonly tagControl = input.required<FormControl<string | null>>();
    readonly foundAccount = input.required<IGAccount | undefined>();
    readonly searching = input.required<boolean>();

    readonly onAccountSelected = output<MatAutocompleteSelectedEvent>();
    readonly imageCroppedEvent = output<ImageCroppedEvent>();
    readonly imageLoadedEvent = output<LoadedImage>();
    readonly removeMedia = output<Media>();
    readonly toggleShowTagContainer = output<{ x: number; y: number }>();
    readonly removeAccount = output<string>();
    readonly onClose = output<void>();

    readonly mediaUrl = computed(() => this.media().getMediaUrl(PictureSize.IG_FIT));

    private readonly _aspectRatioContainerElement = viewChild<ElementRef<HTMLDivElement> | undefined>('aspectRatioContainerElement');
    private readonly _containerElement = viewChild<ElementRef<HTMLDivElement> | undefined>('containerElement');
    readonly imageContainer = viewChild<ElementRef<HTMLImageElement> | undefined>('imageContainer');
    readonly videoPlayerElement = viewChild<ElementRef<HTMLVideoElement> | undefined>('videoPlayer');

    readonly isMediaVideo = computed(() => this.media().isVideo());

    readonly aspectRatioClass = computed(() => {
        if (this.cropSize() === CropOption.SQUARE) {
            return 'aspect-square';
        }

        if (this.cropSize() === CropOption.LANDSCAPE) {
            return 'aspect-video';
        }

        return 'aspect-[4/5]';
    });

    readonly mediaSizeClass = computed(() => {
        if (this.cropSize() === CropOption.SQUARE) {
            return 'w-full h-full';
        }

        if (this.cropSize() === CropOption.LANDSCAPE) {
            return 'w-full';
        }

        return 'h-full';
    });

    readonly objectFitClass = computed(() => {
        const mediaAspectRatio = parseAspectRatio(this.media().getAspectRatio());
        if (this.cropSize() === mediaAspectRatio) {
            return 'object-contain';
        }

        return 'object-cover';
    });

    readonly SvgIcon = SvgIcon;

    private readonly _containerSizeChanged = signal(0);
    private readonly _resizeObserver = new ResizeObserver(() => {
        this._containerSizeChanged.update((e) => ++e);
    });

    constructor() {
        effect(() => {
            this._containerSizeChanged(); // just a  trigger
            const media = this.media();
            const dimensions = media?.dimensions?.original;
            const transformData = media?.transformData;
            const aspectRatioContainerElement = this._aspectRatioContainerElement()?.nativeElement;
            const containerElement = this._containerElement()?.nativeElement;
            const imgElement = this.imageContainer()?.nativeElement;
            const videoPlayerElement = this.videoPlayerElement()?.nativeElement;

            if (!media || !dimensions || !transformData || !containerElement) {
                this._resetAspectRatioContainerStyles();
                if (imgElement) {
                    imgElement.style.width = '';
                    imgElement.style.height = '';
                    imgElement.style.transform = '';
                }
                if (videoPlayerElement) {
                    videoPlayerElement.style.width = '';
                    videoPlayerElement.style.height = '';
                    videoPlayerElement.style.transform = '';
                }
                return;
            }

            if (imgElement) {
                if (aspectRatioContainerElement) {
                    aspectRatioContainerElement.style.width = '100%';
                    aspectRatioContainerElement.style.height = '100%';
                    aspectRatioContainerElement.style.display = 'block';
                    aspectRatioContainerElement.style.maxWidth = '100%'; // needed on chrome / safari, width/height 100% is not sufficient
                    aspectRatioContainerElement.style.maxHeight = '100%'; // needed on chrome / safari, width/height 100% is not sufficient
                }
                computeTransformDataStyles(dimensions, transformData, containerElement, imgElement);
                return;
            }

            if (videoPlayerElement) {
                if (aspectRatioContainerElement) {
                    aspectRatioContainerElement.style.width = '100%';
                    aspectRatioContainerElement.style.height = '100%';
                    aspectRatioContainerElement.style.display = 'block';
                    aspectRatioContainerElement.style.maxWidth = '100%'; // needed on chrome / safari, width/height 100% is not sufficient
                    aspectRatioContainerElement.style.maxHeight = '100%'; // needed on chrome / safari, width/height 100% is not sufficient
                }
                computeTransformDataStyles(dimensions, transformData, containerElement, videoPlayerElement);
                return;
            }
            this._resetAspectRatioContainerStyles();
        });
        effect(() => {
            this._resizeObserver.disconnect();
            const containerElement = this._containerElement()?.nativeElement;
            if (containerElement) {
                this._resizeObserver.observe(containerElement);
            }
        });
    }

    ngOnDestroy(): void {
        this._resizeObserver.disconnect();
    }

    private _resetAspectRatioContainerStyles(): void {
        const aspectRatioContainerElement = this._aspectRatioContainerElement()?.nativeElement;
        if (aspectRatioContainerElement) {
            const mediaSizeClass = this.mediaSizeClass();
            aspectRatioContainerElement.style.width = '';
            aspectRatioContainerElement.style.height = '';
            if (mediaSizeClass.includes('w-full')) {
                aspectRatioContainerElement.style.width = '100%';
            }
            if (mediaSizeClass.includes('h-full')) {
                aspectRatioContainerElement.style.height = '100%';
            }
            aspectRatioContainerElement.style.display = 'flex';
            aspectRatioContainerElement.style.maxWidth = '';
            aspectRatioContainerElement.style.maxHeight = '';
        }
    }

    playVideo(): void {
        const videoPlayerElement = this.videoPlayerElement()?.nativeElement;
        if (!videoPlayerElement) {
            return;
        }
        if (videoPlayerElement?.paused) {
            videoPlayerElement.play();
        } else {
            videoPlayerElement.pause();
        }
    }

    muteUnmuteVideo(): void {
        const videoPlayerElement = this.videoPlayerElement()?.nativeElement;
        if (!videoPlayerElement) {
            return;
        }
        videoPlayerElement.muted = !videoPlayerElement.muted;
    }

    isVideoMuted(): boolean {
        return !!this.videoPlayerElement()?.nativeElement?.muted;
    }

    isVideoPlaying(): boolean {
        return !this.videoPlayerElement()?.nativeElement?.paused;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.imageCroppedEvent.emit(event);
    }

    imageLoaded(event: LoadedImage): void {
        this.imageLoadedEvent.emit(event);
    }

    removeMediaClick(): void {
        this.removeMedia.emit(this.media());
    }

    getCurrentTagPositionInMedia(x: number, y: number): { left: string; top: string } {
        const image = document.getElementById('imageContainer') as HTMLImageElement;
        const width = image.offsetWidth;
        const height = image.offsetHeight;

        return {
            left: x * width + 'px',
            top: y * height + 'px',
        };
    }

    toggleShowTagContainerClick(event: MouseEvent): void {
        const imageContainer = this.imageContainer();
        if (!imageContainer) {
            return;
        }
        const rect = imageContainer.nativeElement.getBoundingClientRect(); // Get the element's position and size

        const normalizedX = (event.clientX - rect.left) / rect.width;
        const normalizedY = (event.clientY - rect.top) / rect.height;
        this.toggleShowTagContainer.emit({ x: normalizedX, y: normalizedY });
    }

    removeAccountClick(username: string): void {
        this.removeAccount.emit(username);
    }
}
