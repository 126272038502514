import { ChangeDetectionStrategy, Component, computed, inject, input, signal, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Plugin, TimeUnit } from 'chart.js';
import { DateTime, DateTimeUnit } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { ApplicationLanguage, mapApplicationLanguageToLocale, PlatformKey, ReviewAnalysisTag } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { TopicSegmentAnalysisModalComponent } from ':shared/components/review-analyses-v2/topic-segment-analysis-modal/topic-segment-analysis-modal.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    malouChartBackgroundColorGreen,
    malouChartBackgroundColorRed,
    malouChartColorGreen,
    malouChartColorRed,
} from ':shared/helpers';
import { SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type LineChartType = Extract<ChartType, 'line'>;

@Component({
    selector: 'app-area-chart',
    standalone: true,
    imports: [NgChartsModule],
    templateUrl: './area-chart.component.html',
    styleUrl: './area-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaChartComponent {
    readonly positiveChartData = input.required<number[]>();
    readonly negativeChartData = input.required<number[]>();
    readonly labels = input.required<Date[]>();
    readonly viewBy = input.required<ViewBy>();
    readonly sortBy = input<ChartSortBy>();
    readonly chartTopicFilter = input.required<{
        topicName: string;
        category: ReviewAnalysisTag | null;
        isMain: boolean;
        topicTranslations: Record<ApplicationLanguage, string>;
        restaurantId: string;
        keys: PlatformKey[];
    } | null>();

    private readonly _translateService = inject(TranslateService);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);
    private readonly _customDialogService = inject(CustomDialogService);

    readonly CHART_TYPE: LineChartType = 'line';
    readonly EVENT_CATCHER_PLUGIN: Plugin = this._getEventCatcherPlugin();

    readonly chartDataSets: Signal<ChartDataset<LineChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.positiveChartData(), this.negativeChartData())
    );
    readonly chartLabels: Signal<Date[]> = computed(() => this._computeChartLabels(this.labels()));
    readonly chartOptions: Signal<ChartOptions<LineChartType>> = computed(() => this._computeChartOptions(this.viewBy()));

    readonly currentLang = signal(LocalStorage.getLang());
    readonly currentLocale = computed(() => mapApplicationLanguageToLocale(this.currentLang()));

    private _computeChartData(positiveChartData: number[], negativeChartData: number[]): ChartDataset<LineChartType, any[]>[] {
        return [
            {
                borderColor: malouChartBackgroundColorGreen,
                backgroundColor: malouChartBackgroundColorGreen,
                pointBorderColor: malouChartColorGreen,
                pointBackgroundColor: malouChartColorGreen,
                hoverBorderCapStyle: 'round',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: positiveChartData,
                parsing: {
                    yAxisKey: 'total',
                    xAxisKey: 'restaurantName',
                },
                fill: 'origin',
            },
            {
                borderColor: malouChartBackgroundColorRed,
                backgroundColor: malouChartBackgroundColorRed,
                pointBorderColor: malouChartColorRed,
                pointBackgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: negativeChartData,
                parsing: {
                    yAxisKey: 'total',
                    xAxisKey: 'restaurantName',
                },
                fill: 'origin',
            },
        ];
    }

    private _computeChartLabels(labels: Date[]): Date[] {
        return labels;
    }

    private _computeChartOptions(viewBy: ViewBy): ChartOptions<LineChartType> {
        return {
            plugins: {
                tooltip: {
                    enabled: false,
                    external: (context) => this._getCustomTooltip(context),
                },
                legend: {
                    display: false,
                },
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false,
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeTooltipFormatFromViewBy(viewBy),
                        unit: this._computeTimeUnitFromViewBy(viewBy),
                    },
                },
                yAxis: {
                    axis: 'y',
                    stacked: true,
                    type: 'linear',
                    ticks: {
                        stepSize: 1,
                    },
                },
            },
        };
    }

    private _getCustomTooltip(context: any): void {
        let tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div id="tooltip-content" style="font-size: 9px"></div>';
            document.body.appendChild(tooltipEl);
        }

        const tooltipModel = context.tooltip;
        if (!tooltipModel.opacity) {
            tooltipEl.style.opacity = '0';
            return;
        }

        const dataPoints = tooltipModel.dataPoints;
        const dateLabel = dataPoints[0].label;
        const positiveCount: number = dataPoints[0].raw;
        const negativeCount: number = dataPoints[1].raw;

        const containerDiv = document.createElement('div');
        containerDiv.setAttribute('style', 'display: flex; flex-direction: column; gap: 5px;');
        containerDiv.innerHTML = `
            <strong style='font-weight: 600'>${dateLabel}</strong><br>
            <span style='font-weight: 500'>
                <span style='color: ${malouChartColorGreen}'>●</span>
                ${this._computeTooltipLabel(positiveCount, 0)}
            </span>
            <br>
            <span style='font-weight: 500'>
                <span style='color: ${malouChartColorRed}'>●</span>
                ${this._computeTooltipLabel(negativeCount, 1)}
            </span>
            <br>`;

        document.getElementById('tooltip-content')!.innerHTML = containerDiv.innerHTML;
        if (positiveCount > 0 || negativeCount > 0) {
            const seeAssociatedReviewsButton = this._getTooltipButton(dateLabel, this.viewBy());
            document.getElementById('tooltip-content')!.appendChild(seeAssociatedReviewsButton);
        }

        const position = context.chart.canvas.getBoundingClientRect();

        const top = position.top + tooltipModel.caretY;
        const left = position.left + tooltipModel.caretX;

        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${left}px`;
        tooltipEl.style.top = `${top}px`;
        tooltipEl.style.zIndex = '10';
        tooltipEl.style.backgroundColor = '#0A2540';
        tooltipEl.style.color = '#fff';
        tooltipEl.style.padding = '10px';
        tooltipEl.style.borderRadius = '5px';
    }

    private _getTooltipButton(dateLabel: string, viewBy: ViewBy): HTMLButtonElement {
        const { startDate, endDate } = this._getDatesFromLabel(dateLabel, viewBy);
        const seeAssociatedReviewsButton = document.createElement('button');
        seeAssociatedReviewsButton.setAttribute(
            'style',
            'background-color: #0A2540; color: #6a52fd; border: none; padding: 5px; cursor: pointer;'
        );
        seeAssociatedReviewsButton.innerHTML = this._translateService.instant(
            'statistics.e_reputation.reviews_analysis.see_associated_reviews'
        );
        seeAssociatedReviewsButton.addEventListener('click', () => {
            this._openSegmentAnalysisModal(startDate, endDate);
        });
        return seeAssociatedReviewsButton;
    }

    private _getDatesFromLabel(label: string, viewBy: ViewBy): { startDate: Date; endDate: Date } {
        const date = DateTime.fromFormat(label, viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY, {
            locale: this.currentLocale(),
        }).startOf('day');
        const dateTimeUnit = this._viewByToLuxonDateTimeUnit(viewBy);
        return {
            startDate: date.toJSDate(),
            endDate: date.endOf(dateTimeUnit).toJSDate(),
        };
    }

    private _openSegmentAnalysisModal(startDate: Date, endDate: Date): void {
        const chartTopicFilter = this.chartTopicFilter();
        if (!chartTopicFilter) {
            return;
        }

        this._customDialogService.open(TopicSegmentAnalysisModalComponent, {
            width: '80vw',
            height: '80vh',
            disableClose: true,
            data: {
                topic: this.chartTopicFilter()?.isMain ? chartTopicFilter.category : chartTopicFilter.topicName,
                topicTranslations: chartTopicFilter.topicTranslations,
                reviewAnalysesFilter: {
                    restaurantIds: [chartTopicFilter.restaurantId],
                    startDate,
                    endDate,
                    keys: chartTopicFilter.keys,
                },
                isFromAggregatedStatistics: false,
                isMainCategory: this.chartTopicFilter()?.isMain,
                shouldShowSubcategories: true,
            },
        });
    }

    private _computeTooltipLabel(value: number, index: number): string {
        const sentiment =
            index === 0
                ? this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_analysis.positive', value).toLowerCase()
                : this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_analysis.negative', value).toLowerCase();
        return (
            SMALL_TOOLTIP_TAB +
            this._pluralTranslatePipe.transform('statistics.e_reputation.reviews_analysis.feeling', value, {
                sentiment,
            })
        );
    }

    private _computeTooltipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _viewByToLuxonDateTimeUnit(viewBy: ViewBy): DateTimeUnit {
        switch (viewBy) {
            case ViewBy.DAY:
                return 'day';
            case ViewBy.WEEK:
                return 'week';
            case ViewBy.MONTH:
                return 'month';
        }
    }

    private _getEventCatcherPlugin(): Plugin {
        return {
            id: 'myEventCatcher',
            beforeEvent(_chart, args, _pluginOptions): boolean {
                const event = args.event;
                if (event.type === 'mouseout') {
                    // If the mouse leaves the chart, we want to hide the tooltip
                    // but we still want to keep the tooltip visible when the mouse is over the chart
                    // When we hover the tooltip, a mouseout event is triggered and by default it hides the tooltip
                    // so we need to prevent this behavior
                    return !args.inChartArea;
                }
                return true;
            },
        };
    }
}
