<div>
    <button class="malou-btn-flat !px-0" [matMenuTriggerFor]="tooltipMenu" (click)="onAdviceButtonClick()">
        {{ 'statistics.seo.keywords.apparition_column.advice' | translate }}
    </button>

    <mat-menu class="malou-mat-menu malou-box-shadow" #tooltipMenu="matMenu">
        <div class="max-w-[200px] bg-malou-chip-text-primary px-3 py-2 text-[10px] font-medium leading-[14px] text-malou-color-text-white">
            @switch (advice()) {
                @case (null) {}
                @case (Advice.HIGH_POPULARITY_TARGET_TOP_3_POSITION) {
                    <span class="malou-text-9--semibold">
                        {{ 'statistics.seo.keywords.apparition_column.high_popularity_target_top_3_position' | translate }}
                    </span>
                }
                @case (Advice.NOT_POPULAR_ENOUGH_TARGET_TOP_3_POSITION) {
                    <span class="malou-text-9--semibold">
                        {{ 'statistics.seo.keywords.apparition_column.not_popular_enough_target_top_3_position' | translate }}
                    </span>
                }
                @case (Advice.LOW_POPULARITY_CHANGE_KEYWORD) {
                    <span class="malou-text-9--semibold">
                        {{ 'statistics.seo.keywords.apparition_column.low_popularity_change_keyword' | translate }}
                    </span>
                }
                @default {
                    <div class="flex flex-col gap-y-1">
                        <span class="malou-text-9--bold">
                            {{ 'statistics.seo.keywords.apparition_column.change_keyword' | translate }}
                        </span>
                        <span class="malou-text-9">
                            {{ 'statistics.seo.keywords.apparition_column.change_keyword_subtext' | translate }}
                        </span>
                        <ul>
                            <li class="malou-text-9">
                                {{ 'statistics.seo.keywords.apparition_column.change_keyword_reason1' | translate }}
                            </li>
                            <li class="malou-text-9">
                                {{ 'statistics.seo.keywords.apparition_column.change_keyword_reason2' | translate }}
                            </li>
                        </ul>
                    </div>
                }
            }
        </div>
    </mat-menu>
</div>
