import { ChangeDetectionStrategy, Component, forwardRef, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { DEFAULT_STICKER_DISPLAY_NAME, LightNfc } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { SelectBaseComponent } from '../select-abstract/select-base.component';

@Component({
    selector: 'app-select-nfcs',
    templateUrl: './select-nfcs.component.html',
    styleUrls: ['./select-nfcs.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectNfcsComponent),
        },
    ],
    standalone: true,
    imports: [
        SelectBaseComponent,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        TranslateModule,
        MatTooltipModule,
        ApplyPurePipe,
        LazyLoadImageModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectNfcsComponent extends ControlValueAccessorConnectorComponent {
    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Values */
    readonly values = input<LightNfc[]>([]);

    readonly selectedValues = input<LightNfc[]>([]);

    readonly multiSelection = input<boolean>(true);

    readonly multiSelectionElementWrap = input<boolean>(false);

    readonly shouldSwitchToWrapModeOnClick = input<boolean>(false);

    readonly shouldUpdateValuesToDisplayAfterSelection = input<boolean>(true);

    readonly defaultEmptyValueMessage = input<string | null>(null);

    readonly showValuesSelectedCount = input<boolean>(true);

    readonly disabled = input<boolean>(false);

    readonly computeObjectHash = input<((a?: any) => any) | undefined>();

    readonly displayedOptionCount = input<number>(Number.MAX_SAFE_INTEGER);

    readonly valuesCanBeDisabled = input<boolean>(false);

    readonly maxSelectableValues = input<number | undefined>();

    readonly testId = input<string>();

    // ------------ Event ------------//

    /** On change event */
    readonly selectNfcChange = output<LightNfc[]>();

    readonly SvgIcon = SvgIcon;

    displayWith: (option: LightNfc) => string = (option: LightNfc) => option.getDisplayName();

    isSticker = (option: LightNfc): boolean => option.getNfcName() === DEFAULT_STICKER_DISPLAY_NAME;

    getRestaurantName = (option: LightNfc): string => option.restaurantName;

    sortBy = (a: LightNfc, b: LightNfc): number => a.getDisplayName().localeCompare(b.getDisplayName());
}
