<div class="relative h-full w-full overflow-hidden" #containerElement>
    @if (data().videoOptions?.videoUrl; as videoUrl) {
        <video
            class="h-full w-full max-w-unset cursor-pointer"
            loop
            fill
            [ngClass]="{
                'object-none': hasOptionalData(),
                'object-cover': !hasOptionalData(),
            }"
            [src]="videoUrl"
            (error)="onError($event)"
            (click)="togglePlayPauseVideo()"
            #videoElement></video>
        @if (!isPlayingVideo()) {
            <div class="absolute bottom-0 left-0 right-0 top-0">
                <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
            </div>

            <div
                class="absolute bottom-0 left-0 right-0 top-0 flex cursor-pointer items-center justify-center"
                (click)="togglePlayPauseVideo(); $event.stopPropagation()">
                <mat-icon class="!h-full !w-[20%]" color="primary" [svgIcon]="SvgIcon.PLAY_RECT"></mat-icon>
            </div>
        }
    } @else {
        <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
    }
</div>

<ng-template #imageTemplate>
    <img
        class="h-full w-full max-w-unset"
        fill
        [ngClass]="{
            'object-none': hasOptionalData(),
            'object-cover': !hasOptionalData(),
        }"
        [priority]="!shouldLazyLoadMedia()"
        [ngSrc]="data().thumbnailUrl"
        (error)="onError($event)"
        #imgElement />
</ng-template>
