import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { KeywordSearchImpressionInsights } from ':modules/aggregated-statistics/seo/models/top-keyword-search-impressions.interface';
import { SearchImpressionsModalComponent } from ':modules/aggregated-statistics/seo/top-keyword-search-impressions/search-impressions-modal/search-impressions-modal.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum TableColumn {
    KEYWORD = 'keyword',
    AVG_APPARITIONS = 'avgApparitions',
    RESTAURANTS = 'restaurants',
}

@Component({
    selector: 'app-search-impressions-table',
    standalone: true,
    imports: [
        MatTableModule,
        MatSortModule,
        IllustrationPathResolverPipe,
        TranslateModule,
        NumberEvolutionComponent,
        ShortNumberPipe,
        ApplyPurePipe,
        MatButtonModule,
        NgTemplateOutlet,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        MatTooltipModule,
    ],
    templateUrl: './search-impressions-table.component.html',
    styleUrl: './search-impressions-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EmojiPathResolverPipe],
})
export class SearchImpressionsTableComponent {
    readonly searchImpressionInsights = input.required<KeywordSearchImpressionInsights[]>();
    readonly selectedRestaurantsCount = input.required<number>();
    readonly shouldHideTableClickableElements = input<boolean>(false);

    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _emojiPathResolverPipe = inject(EmojiPathResolverPipe);
    private readonly _heapService = inject(HeapService);

    readonly Illustration = Illustration;
    readonly TableColumn = TableColumn;
    readonly DISPLAYED_COLUMNS = [TableColumn.KEYWORD, TableColumn.AVG_APPARITIONS, TableColumn.RESTAURANTS];
    readonly dataSource = computed(() => new MatTableDataSource<KeywordSearchImpressionInsights>(this.searchImpressionInsights()));

    openSearchImpressionsModal(row: KeywordSearchImpressionInsights): void {
        this._heapService.track(HeapEventName.AGGRAGTED_TOP_KEYWORD_SEARCH_IMPRESSIONS_SEE_MORE, {
            venuesIds: row.restaurants.map((restaurant) => restaurant.restaurantId).join(','),
        });

        this._customDialogService
            .open(
                SearchImpressionsModalComponent,
                {
                    width: '40vw',
                    data: row,
                },
                { closeOnOutsideClick: true }
            )
            .afterClosed();
    }

    getEmojiForKeyword = (keyword: string): string => {
        const position = this.searchImpressionInsights().findIndex((insight) => insight.keywordSearch === keyword);
        return (
            [
                this._emojiPathResolverPipe.transform(Emoji.TROPHY),
                this._emojiPathResolverPipe.transform(Emoji.SECOND_PLACE_MEDAL),
                this._emojiPathResolverPipe.transform(Emoji.THIRD_PLACE_MEDAL),
                this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE),
            ][position] ?? this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE)
        );
    };

    onSortByChange(sortBy: string): void {
        const dataSource = this.dataSource();
        if (!dataSource) {
            return;
        }
        dataSource.sort?.sort({ id: sortBy, start: dataSource.sort.direction || ChartSortBy.ASC, disableClear: true });
    }

    onSortOrderChange(): void {
        const dataSource = this.dataSource();
        if (!dataSource) {
            return;
        }
        const direction = dataSource.sort?.direction === ChartSortBy.ASC ? ChartSortBy.DESC : ChartSortBy.ASC;
        dataSource.sort?.sort({ id: dataSource.sort.active, start: direction, disableClear: true });
    }
}
