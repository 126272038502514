import { z } from 'zod';

import {
    AggregationTimeScale,
    AggregationType,
    InsightsTab,
    MalouComparisonPeriod,
    MalouMetric,
    PlatformKey,
} from '@malou-io/package-utils';

import { dayMonthYearValidator, objectIdValidator } from '../utils/validators';

export const downloadInsightsAsPdfBodyValidator = z.object({
    callbackUrl: z.string(),
    params: z.string(),
    jwtToken: z.string(),
    restaurantId: objectIdValidator.optional(),
    insightTab: z.nativeEnum(InsightsTab),
});
export type DownloadInsightsAsPdfBodyDto = z.infer<typeof downloadInsightsAsPdfBodyValidator>;

// ---------------------------------------------------------------------------------------------

export const getInsightsAggregatedRequestQueryValidator = z
    .object({
        restaurant_ids: z.union([z.string().transform((id) => [id]), z.array(z.string())]).optional(),
        start_date: z.string().optional(),
        end_date: z.string().optional(),
        previous_period: z.enum(['true', 'false']).optional(),
        platforms_keys: z.union([z.nativeEnum(PlatformKey).transform((key) => [key]), z.array(z.nativeEnum(PlatformKey))]).optional(),
        aggregators: z
            .union([z.nativeEnum(AggregationTimeScale).transform((timeScale) => [timeScale]), z.array(z.nativeEnum(AggregationTimeScale))])
            .optional(),
        metrics: z
            .union([z.nativeEnum(MalouMetric).transform((malouMetric) => [malouMetric]), z.array(z.nativeEnum(MalouMetric))])
            .optional(),
    })
    .transform((data) => ({
        ...data,
        restaurantIds: data.restaurant_ids,
        startDate: data.start_date,
        endDate: data.end_date,
        previousPeriod: data.previous_period,
        platformsKeys: data.platforms_keys ?? [],
    }));

export type GetInsightsAggregatedRequestQueryDto = z.infer<typeof getInsightsAggregatedRequestQueryValidator>;

// ---------------------------------------------------------------------------------------------

export const getInsightsAggregatedRequestBodyValidator = z.object({
    restaurantIds: z.array(z.string()),
    startDate: z.string().datetime().nullish(),
    endDate: z.string().datetime().nullish(),
    previousPeriod: z.boolean().optional(),
    comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).optional(),
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
    aggregators: z.array(z.nativeEnum(AggregationTimeScale)),
    metrics: z.array(z.nativeEnum(MalouMetric)),
});

export type GetInsightsAggregatedRequestBodyDto = z.infer<typeof getInsightsAggregatedRequestBodyValidator>;

// ---------------------------------------------------------------------------------------------

export const getStoredInsightsRequestBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    startDate: dayMonthYearValidator,
    endDate: dayMonthYearValidator,
    metrics: z.array(z.nativeEnum(MalouMetric)),
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
    comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).optional(),
});

export type GetStoredInsightsRequestBodyDto = z.infer<typeof getStoredInsightsRequestBodyValidator>;
export type GetStoredInsightsRequestInputBodyDto = z.input<typeof getStoredInsightsRequestBodyValidator>;

// ---------------------------------------------------------------------------------------------
export const getStoredInsightsAggregatedRequestBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    startDate: z
        .string()
        .datetime()
        .transform((date) => new Date(date)),
    endDate: z
        .string()
        .datetime()
        .transform((date) => new Date(date)),
    metrics: z.array(z.nativeEnum(MalouMetric)),
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
    previousPeriod: z.boolean().optional(),
    aggregationType: z.nativeEnum(AggregationType),
});

export type GetStoredInsightsAggregatedRequestBodyDto = z.infer<typeof getStoredInsightsAggregatedRequestBodyValidator>;
export type GetStoredInsightsAggregatedRequestInputBodyDto = z.input<typeof getStoredInsightsAggregatedRequestBodyValidator>;
