import { waitFor } from '@malou-io/package-utils';

export const retryPromise = <T>(promise: Promise<T>, retries = 3, delayInMilliseconds = 50): Promise<T> =>
    new Promise((resolve, reject) =>
        promise.then(resolve).catch((reason: Error) => {
            if (retries > 0) {
                return waitFor(delayInMilliseconds)
                    .then(() => retryPromise(promise, retries - 1, delayInMilliseconds))
                    .then(resolve)
                    .catch(reject);
            }

            return reject(reason);
        })
    );
