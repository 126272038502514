@if (!isLoading()) {
    @if (hasError() || !hasData()) {
        <ng-container
            [ngTemplateOutlet]="emptyTemplate"
            [ngTemplateOutletContext]="{
                text: 'aggregated_statistics.e_reputation.reviews_analysis.semantic_analysis_not_available' | translate,
                subtext: 'aggregated_statistics.e_reputation.reviews_analysis.edit_filters' | translate,
            }">
        </ng-container>
    } @else {
        <div class="malou-simple-card mb-4 flex max-h-[500px] w-full break-inside-avoid flex-col gap-3 px-6 py-3 pb-12 md:flex-col md:px-2">
            <div class="flex flex-col gap-1">
                <div class="malou-text-section-title malou-color-text-1">
                    {{ 'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiment_percentage' | translate }}
                </div>
            </div>
            <div class="justify-content-between flex overflow-auto lg:flex-col-reverse">
                <div class="min-w-0 flex-1"><ng-container [ngTemplateOutlet]="tableTemplate"></ng-container></div>
            </div>
        </div>
    }
} @else {
    <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
}

<ng-template let-text="text" let-subtext="subtext" #emptyTemplate>
    <div class="my-4 flex flex-col items-center py-4">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">
            {{ text }}
        </span>
        <span class="malou-text-10--regular">
            {{ subtext }}
        </span>
    </div>
</ng-template>

<ng-template #skeletonTemplate>
    <app-skeleton skeletonClass="!h-[350px] secondary-bg"></app-skeleton>
</ng-template>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table overflow-y-auto"
        matSort
        multiTemplateDataRows
        [dataSource]="dataSource()"
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        #table="matTable">
        <ng-container [matColumnDef]="SemanticAnalysisColumns.RESTAURANT">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.restaurant' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table">
                <div class="hidden lg:flex"></div>
                <ng-container
                    [ngTemplateOutlet]="restaurantCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: {
                            restaurantId: element.restaurantId,
                            restaurantName: element.restaurantName,
                            restaurantLogo: element.restaurantLogo,
                        },
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.SENTIMENT_NUMBER">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header>
                {{ 'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.total_sentiments' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span>
                        {{ 'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.total_sentiments' | translate }}
                    </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.sentimentNumber,
                        unit: '',
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.SENTIMENT">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header>
                {{ 'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.sentiments' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span>
                        {{ 'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.sentiments' | translate }}
                    </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.positiveSentimentPercentage,
                        unit: '%',
                        asChip: true,
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.FOOD">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header
                >{{ ReviewAnalysisTag.FOOD | enumTranslate: 'review_analysis_tags' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span> {{ ReviewAnalysisTag.FOOD | enumTranslate: 'review_analysis_tags' }} </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.foodPositiveSentimentPercentage,
                        unit: '%',
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.SERVICE">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header>
                {{ ReviewAnalysisTag.SERVICE | enumTranslate: 'review_analysis_tags' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span> {{ ReviewAnalysisTag.SERVICE | enumTranslate: 'review_analysis_tags' }} </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.servicePositiveSentimentPercentage,
                        unit: '%',
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.ATMOSPHERE">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header>
                {{ ReviewAnalysisTag.ATMOSPHERE | enumTranslate: 'review_analysis_tags' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span> {{ ReviewAnalysisTag.ATMOSPHERE | enumTranslate: 'review_analysis_tags' }} </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.atmospherePositiveSentimentPercentage,
                        unit: '%',
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.PRICE">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header>
                {{ ReviewAnalysisTag.PRICE | enumTranslate: 'review_analysis_tags' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span> {{ ReviewAnalysisTag.PRICE | enumTranslate: 'review_analysis_tags' }} </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.pricePositiveSentimentPercentage,
                        unit: '%',
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.HYGIENE">
            <mat-header-cell *matHeaderCellDef class="flex justify-center" mat-sort-header>
                {{ ReviewAnalysisTag.HYGIENE | enumTranslate: 'review_analysis_tags' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <span> {{ ReviewAnalysisTag.HYGIENE | enumTranslate: 'review_analysis_tags' }} </span>
                </div>
                <ng-container
                    [ngTemplateOutlet]="valueWithEvolutionCellTemplate"
                    [ngTemplateOutletContext]="{
                        cellData: element.hygienePositiveSentimentPercentage,
                        unit: '%',
                    }">
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.TOP_TOPICS">
            <mat-header-cell *matHeaderCellDef class="malou-text-15--regular flex justify-center">
                <img class="h-4 w-4" [src]="Emoji.HEART | emojiPathResolver" />
            </mat-header-cell>
            <mat-cell *matCellDef="let element; table: table" class="flex items-center justify-center">
                <div class="hidden lg:flex">
                    <img class="h-4 w-4" [src]="Emoji.HEART | emojiPathResolver" />
                </div>
                @if (element.restaurantId !== ALL_RESTAURANTS_ID) {
                    <mat-icon
                        color="primary"
                        [svgIcon]="
                            expandedRow()?.restaurantId === element.restaurantId ? SvgIcon.CHEVRON_UP : SvgIcon.CHEVRON_DOWN
                        "></mat-icon>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="SemanticAnalysisColumns.EXPANDED_DETAIL">
            <td
                *matCellDef="let row"
                mat-cell
                [ngClass]="{
                    '!mt-0 w-full !rounded-t-none !border !border-malou-color-border-primary':
                        row.restaurantId == expandedRow()?.restaurantId,
                    'h-0': !expandedRow() || row.restaurantId !== expandedRow()?.restaurantId,
                }"
                [attr.colspan]="displayedColumns().length">
                <div class="py-2 pr-3 sm:w-full">
                    @if (expandedRow()) {
                        <div class="flex justify-between sm:flex-col sm:items-center">
                            <span class="malou-color-text-1 malou-text-12--semibold w-[23%] pl-6 pt-2 sm:w-full">
                                {{
                                    'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.what_clients_say'
                                        | translate: { restaurantName: row.restaurantName }
                                }}
                            </span>
                            @if (!row.topTopics.positiveTopics.length && !row.topTopics.negativeTopics.length) {
                                <div class="ml-20 flex w-full items-center justify-start">
                                    <span class="malou-color-text-1 malou-text-10--regular">
                                        {{
                                            'aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments_table.no_top_topics'
                                                | translate
                                        }}
                                    </span>
                                </div>
                            } @else {
                                <img class="h-5 w-4 pt-1" [src]="Emoji.HEART | emojiPathResolver" />
                                <ng-container
                                    [ngTemplateOutlet]="topicListTemplate"
                                    [ngTemplateOutletContext]="{
                                        topics: row.topTopics.positiveTopics[0],
                                        sentiment: ReviewAnalysisSentiment.POSITIVE,
                                    }"></ng-container>
                                <ng-container
                                    [ngTemplateOutlet]="topicListTemplate"
                                    [ngTemplateOutletContext]="{
                                        topics: row.topTopics.positiveTopics[1],
                                        sentiment: ReviewAnalysisSentiment.POSITIVE,
                                    }"></ng-container>
                                <img class="h-5 w-4 pt-1" [src]="Emoji.MONOCLE | emojiPathResolver" />
                                <ng-container
                                    [ngTemplateOutlet]="topicListTemplate"
                                    [ngTemplateOutletContext]="{
                                        topics: row.topTopics.negativeTopics[0],
                                        sentiment: ReviewAnalysisSentiment.NEGATIVE,
                                    }"></ng-container>
                                <ng-container
                                    [ngTemplateOutlet]="topicListTemplate"
                                    [ngTemplateOutletContext]="{
                                        topics: row.topTopics.negativeTopics[1],
                                        sentiment: ReviewAnalysisSentiment.NEGATIVE,
                                    }"></ng-container>
                            }
                        </div>
                    }
                </div>
            </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: displayedColumns(); table: table"
            class="!mb-0"
            [ngClass]="{
                '!rounded-b-none !bg-malou-color-background-dark': expandedRow()?.restaurantId === row.restaurantId,
                '!cursor-default !bg-malou-color-background-light': row.restaurantId === ALL_RESTAURANTS_ID,
            }"
            [id]="'row_' + row.restaurantId"
            (click)="toggleExpandedRow(row)"></mat-row>
        <mat-row
            *matRowDef="let row; columns: [SemanticAnalysisColumns.EXPANDED_DETAIL]"
            class="!m-0 h-0 min-h-0 w-full !border-none !bg-malou-color-text-white"></mat-row>
    </mat-table>
</ng-template>

<ng-template let-cellData="cellData" #restaurantCellTemplate>
    <div class="flex w-full items-center" (click)="redirectToRestaurantEReputationStatsPage(cellData.restaurantId)">
        <div class="flex w-[90%] items-center">
            @if (cellData.restaurantId !== ALL_RESTAURANTS_ID) {
                <img
                    class="malou-avatar--medium mr-2 h-[45px] w-[45px] !rounded-[5px]"
                    [src]="cellData.restaurantLogo ?? ('default-picture-grey' | imagePathResolver)" />
            }
            <span
                class="truncate-two-lines malou-color-text-1 malou-text-13--semibold break-words"
                [matTooltip]="textContainer.scrollHeight > textContainer.clientHeight ? cellData.restaurantName : null"
                #textContainer
                >{{ cellData.restaurantName }}</span
            >
        </div>
        @if (cellData.restaurantId !== ALL_RESTAURANTS_ID) {
            <mat-icon class="display-on-hover ml-1 !hidden !h-3 !w-3" color="primary" [svgIcon]="SvgIcon.EXPORT"></mat-icon>
        }
    </div>
</ng-template>

<ng-template let-cellData="cellData" let-unit="unit" let-asChip="asChip" #valueWithEvolutionCellTemplate>
    <div class="flex items-center justify-center">
        <span
            class="!malou-text-12--regular mr-2 text-center leading-none"
            [ngClass]="{
                'malou-chip flex !h-5 !w-8 !cursor-default items-center justify-center !pt-[10px]': asChip,
                'malou-chip--warn !text-malou-color-state-warn': asChip && cellData.value >= 60 && cellData.value <= 80,
                'malou-chip--success-light !text-malou-color-text-green': asChip && cellData.value > 80,
                'malou-chip--error-light !text-malou-color-state-error': asChip && cellData.value < 60,
            }">
            {{ isNumber(cellData.value) ? (cellData.value | shortNumber) + unit : '-' }}
        </span>
        <div>
            @if (cellData.evolution) {
                <app-number-evolution
                    [value]="cellData.evolution"
                    [displayedValue]="cellData.evolution | shortNumber: { shouldDisplayMinusSign: true, content: unit }"
                    [size]="'xs'">
                </app-number-evolution>
            }
        </div>
    </div>
</ng-template>

<ng-template let-topics="topics" let-sentiment="sentiment" #topicListTemplate>
    <div class="flex w-[16%] flex-col sm:w-[60%]">
        @for (topic of topics; track $index) {
            <ng-container [ngTemplateOutlet]="topicTemplate" [ngTemplateOutletContext]="{ sentiment, topic }"></ng-container>
        }
    </div>
</ng-template>

<ng-template let-sentiment="sentiment" let-topic="topic" #topicTemplate>
    @if (topic) {
        <div class="flex max-h-9 w-[90%] items-center justify-between rounded-[5px] p-1.5">
            <div class="items flex w-full items-center justify-between">
                <div class="malou-text-11--regular text-malou-color-text-2">
                    {{ topic.displayedNameInCurrentLang ?? topic.displayedName }}
                </div>
                <div
                    class="!malou-text-12--regular malou-chip flex !h-5 !w-8 !cursor-default items-center justify-center !pt-[10px] text-malou-color-text-2"
                    [ngClass]="{
                        'malou-chip--success-light text-malou-color-text-green': sentiment === ReviewAnalysisSentiment.POSITIVE,
                        'malou-chip--error-light malou-color-state-error': sentiment === ReviewAnalysisSentiment.NEGATIVE,
                    }">
                    {{ sentiment === ReviewAnalysisSentiment.POSITIVE ? (topic.positiveCount ?? '-') : (topic.negativeCount ?? '-') }}
                </div>
            </div>
        </div>
    }
</ng-template>
