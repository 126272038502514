<div class="relative min-w-0 grow overflow-hidden rounded-md">
    @if (noEditAndDragAndDrop()) {
        <div class="hide-scrollbar flex gap-x-1.5 overflow-x-auto" (scroll)="!isReadonly() && onScroll()" #scrollableDiv>
            @for (media of medias(); track media.id; let index = $index) {
                <div
                    class="group relative h-[75px] w-[75px] shrink-0 overflow-hidden rounded-md border border-white"
                    (click)="!isReadonly() && mediaClicked.emit(media.id)">
                    <app-image-viewer
                        [data]="{
                            thumbnailUrl: media.url,
                            dimensions: media.dimensions,
                            transformData: media.transformData,
                        }"></app-image-viewer>
                </div>
            }
        </div>
    } @else {
        <div
            class="hide-scrollbar flex gap-x-1.5 overflow-x-auto"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListAutoScrollStep]="10"
            (cdkDropListDropped)="!isReadonly() && onDropMedia($event)"
            (scroll)="!isReadonly() && onScroll()"
            #scrollableDiv>
            @for (media of medias(); track media.id; let index = $index) {
                <div
                    class="group relative h-[75px] w-[75px] shrink-0 overflow-hidden rounded-md"
                    cdkDrag
                    cdkDragLockAxis="x"
                    [ngClass]="{ 'cursor-move': !isReadonly() }"
                    (click)="!isReadonly() && mediaClicked.emit(media.id)">
                    <app-image-viewer
                        [data]="{
                            thumbnailUrl: media.url,
                            dimensions: media.dimensions,
                            transformData: media.transformData,
                        }"></app-image-viewer>
                    <div
                        class="invisible absolute right-2 top-2 rounded-md border border-malou-color-background-darker bg-white p-1 leading-[0] group-hover:visible"
                        [ngClass]="{ 'cursor-pointer': !isReadonly() }"
                        (click)="!isReadonly() && onRemoveMedia(media.id)">
                        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                    </div>

                    @if (showEditMediaButton()) {
                        <div
                            class="invisible absolute left-2 top-2 z-10 rounded-md border border-malou-color-background-darker bg-white p-1 leading-[0] group-hover:visible"
                            [ngClass]="{ 'cursor-pointer': !isReadonly() }"
                            (click)="!isReadonly() && editMedia.emit(media.id)">
                            <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        </div>
                    }

                    @if (media.type === MediaType.VIDEO) {
                        <div class="absolute right-2 top-[5px] group-hover:invisible">
                            <mat-icon class="!h-4 !w-4 !fill-white" color="white" [svgIcon]="SvgIcon.VIDEO_FILLED"></mat-icon>
                        </div>
                    }
                </div>
            }
            @for (_ of uploadingMediaCount() | createArray; track $index) {
                <div class="flex h-[75px] w-[75px] shrink-0 items-center justify-center rounded-md bg-white">
                    <app-malou-spinner size="small" />
                </div>
            }
        </div>
    }

    <div
        class="absolute left-[6px] top-1/2 -translate-y-1/2"
        [ngClass]="{ invisible: atLeftStop(), 'cursor-pointer': !isReadonly() }"
        (click)="!isReadonly() && onChevronLeftClick()">
        <ng-container
            [ngTemplateOutlet]="scrollButtonTemplate"
            [ngTemplateOutletContext]="{ svgIcon: SvgIcon.CHEVRON_LEFT }"></ng-container>
    </div>
    <div
        class="absolute right-[6px] top-1/2 -translate-y-1/2"
        [ngClass]="{ invisible: atRightStop(), 'cursor-pointer': !isReadonly() }"
        (click)="!isReadonly() && onChevronRightClick()">
        <ng-container
            [ngTemplateOutlet]="scrollButtonTemplate"
            [ngTemplateOutletContext]="{ svgIcon: SvgIcon.CHEVRON_RIGHT }"></ng-container>
    </div>
</div>

<ng-template let-svgIcon="svgIcon" #scrollButtonTemplate>
    <div class="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-white opacity-80">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="svgIcon"></mat-icon>
    </div>
</ng-template>
