import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal, Signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, LegendItem, Plugin, TimeUnit } from 'chart.js';
import { Tick, TooltipItem } from 'chart.js/dist/types';
import { isNumber, max } from 'lodash';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import {
    EngagementData,
    EngagementDataType,
    SplittedEngagementData,
} from ':modules/statistics/social-networks/engagement-v2/engagement.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    malouChartColorBluePurple,
    malouChartColorBluePurpleLight30Percent,
    malouChartColorLighterBlue,
    malouChartColorPink,
    malouChartColorPinkLight30Percent,
} from ':shared/helpers';
import { comparisonLegendPlugin, ComparisonLegendPluginMetadata } from ':shared/helpers/chart-comparison-legend-plugin';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

interface TicksSetup {
    max: number;
    min: number;
    stepSize: number;
}

const IMPRESSION_DATA_SET_INDEXES = [0, 1];
const ENGAGEMENT_DATA_SET_INDEXES = [2, 3];

type ChartData = ChartDataset<BarChartType, ChartDataArray> & {
    metadata?: {
        isPrevious: boolean;
        comparisonLegendPluginMetadata: ComparisonLegendPluginMetadata;
    };
};

@Component({
    selector: 'app-engagement-chart-v2',
    templateUrl: './engagement-chart.component.html',
    styleUrls: ['./engagement-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementChartV2Component {
    readonly engagementData = input.required<EngagementData>();
    readonly previousEngagementData = input.required<EngagementData>();
    readonly comparisonPeriod = input.required<MalouComparisonPeriod>();
    readonly labels = input<Date[]>([]);
    readonly previousLabels = input<Date[]>([]);
    readonly viewBy = input<ViewBy>(ViewBy.DAY);
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly hiddenDatasetIndexesChange = output<number[]>();

    private readonly _shortNumberPipe = inject(ShortNumberPipe);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    readonly _FACEBOOK_KEY_TRAD = this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key');
    readonly _INSTAGRAM_KEY_TRAD = this._enumTranslatePipe.transform(PlatformKey.INSTAGRAM, 'platform_key');
    readonly _NO_DATA_TRAD = this._translateService.instant('common.no_data');
    readonly legendContainerId = 'actions-legend-container';

    private readonly _hiddenDatasetIndexes: WritableSignal<number[]> = signal([]);

    readonly chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() => {
        let dataSet = this._computeChartData(this.engagementData(), this.previousEngagementData());
        if (this.hiddenDatasetIndexes().length) {
            dataSet = dataSet.filter((_, index) => !this.hiddenDatasetIndexes().includes(index));
        }
        return dataSet;
    });
    private readonly _ticksSetup: Signal<TicksSetup[]> = computed(() => this._computeTicksSetup(this.chartDataSets()));
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions());

    private _onLegendItemClick = (chart: Chart, legendItem: LegendItem) => {
        const index = legendItem.datasetIndex;
        if (!isNumber(index)) {
            return;
        }
        if (chart.isDatasetVisible(index)) {
            chart.hide(index);
            this._hiddenDatasetIndexes.set([...this._hiddenDatasetIndexes(), index]);
            legendItem.hidden = true;
        } else {
            chart.show(index);
            this._hiddenDatasetIndexes.set(this._hiddenDatasetIndexes().filter((i) => i !== index));
            legendItem.hidden = false;
        }
        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes());
    };

    readonly LEGEND_PLUGIN: Signal<Plugin> = computed(() =>
        comparisonLegendPlugin({
            id: 'impressionsLegendPlugin',
            elementId: this.legendContainerId,
            onLegendItemClick: this._onLegendItemClick,
            shouldOnlyShowPrimaryColor: this.viewBy() === ViewBy.DAY,
        })
    );

    private _computeChartData(data: EngagementData, previousData: EngagementData): ChartData[] {
        return [
            {
                label: 'previous impressions',
                borderColor: malouChartColorBluePurpleLight30Percent,
                backgroundColor: malouChartColorBluePurpleLight30Percent,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis1',
                data: this.viewBy() === ViewBy.DAY ? [] : previousData.impressions.total,
                metadata: {
                    isPrevious: true,
                    comparisonLegendPluginMetadata: {
                        display: false,
                    },
                },
            },
            {
                label: this._translateService.instant('statistics.social_networks.engagement.impressions'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis1',
                data: data.impressions.total,
                metadata: {
                    isPrevious: false,
                    comparisonLegendPluginMetadata: {
                        display: true,
                        primaryColor: malouChartColorBluePurple,
                        secondaryColor: malouChartColorBluePurpleLight30Percent,
                    },
                },
            },
            {
                label: 'previous engagement',
                borderColor: malouChartColorPinkLight30Percent,
                backgroundColor: malouChartColorPinkLight30Percent,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                data: this.viewBy() === ViewBy.DAY ? [] : previousData.engagement.total,
                metadata: {
                    isPrevious: true,
                    comparisonLegendPluginMetadata: {
                        display: false,
                    },
                },
            },
            {
                label: this._translateService.instant('statistics.social_networks.engagement.engagement_rate'),
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                type: 'bar',
                xAxisID: 'xAxis',
                yAxisID: 'yAxis2',
                data: data.engagement.total,
                metadata: {
                    isPrevious: false,
                    comparisonLegendPluginMetadata: {
                        display: true,
                        primaryColor: malouChartColorPink,
                        secondaryColor: malouChartColorPinkLight30Percent,
                    },
                },
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeToolTipFormatFromViewBy(this.viewBy()),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(this.viewBy()),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                        round: this._computeTimeRoundFromViewBy(this.viewBy()),
                    },
                },
                yAxis1: {
                    axis: 'y',
                    type: 'linear',
                    min: this._ticksSetup()[0].min,
                    max: this._ticksSetup()[0].max,
                    ticks: {
                        stepSize: this._ticksSetup()[0].stepSize,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number, { fixNumber: 0 }),
                    },
                    grid: {
                        display: true,
                        color: (context): string | undefined => {
                            if (context.tick.value === 0) {
                                return malouChartColorLighterBlue;
                            }
                        },
                    },
                },
                yAxis2: {
                    axis: 'y',
                    type: 'linear',
                    min: this._ticksSetup()[1].min,
                    max: this._ticksSetup()[1].max,
                    ticks: {
                        stepSize: this._ticksSetup()[1].stepSize,
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number, { content: '%', fixNumber: 1 }),
                    },
                    position: 'right',
                },
            },
        };
    }

    private _computeTicksSetup(datasets: ChartDataset<BarChartType, ChartDataArray>[]): TicksSetup[] {
        const impressionsTicksSetup = this._getTicksSetup([...datasets[0].data, ...datasets[1].data]);
        const engagementTicksSetup = this._getTicksSetup([...datasets[2].data, ...datasets[3].data]);
        return [impressionsTicksSetup, engagementTicksSetup];
    }

    private _getTicksSetup(data: ChartDataArray, ticksNumber = 8): TicksSetup {
        const maxData: number = max(data) || 1;
        const maxDataRounded = Math.ceil(maxData);
        return {
            max: maxDataRounded,
            min: 0,
            stepSize: maxDataRounded / ticksNumber,
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[]): string {
        const dataset = item[0].dataset as ChartData;
        const title = dataset.metadata?.isPrevious ? this._formatTitle(this.previousLabels()[item[0].dataIndex]) : item[0].label;
        const postCount = dataset.metadata?.isPrevious
            ? this.previousEngagementData().postsCount.total[item[0].dataIndex]
            : this.engagementData().postsCount.total[item[0].dataIndex];

        const post =
            (postCount || 0) > 1
                ? this._translateService.instant('statistics.social_networks.engagement.posts')
                : this._translateService.instant('statistics.social_networks.engagement.post');
        if (this.viewBy() === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.social_networks.engagement.week_of');
            return `${weekOf} ${title} : ${postCount} ${post}`;
        }
        return `${title} : ${postCount} ${post}`;
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string | string[] | undefined {
        if (IMPRESSION_DATA_SET_INDEXES.includes(item.datasetIndex)) {
            return this._computeTooltipLabelByKey(item, EngagementDataType.IMPRESSIONS);
        }
        if (ENGAGEMENT_DATA_SET_INDEXES.includes(item.datasetIndex)) {
            return this._computeTooltipLabelByKey(item, EngagementDataType.ENGAGEMENT);
        }
    }

    private _computeTooltipLabelByKey(item: TooltipItem<BarChartType>, key: EngagementDataType): string[] {
        const dataset = item.dataset as ChartData;
        const engagementData = dataset.metadata?.isPrevious ? this.previousEngagementData() : this.engagementData();
        const data: SplittedEngagementData = engagementData[key];
        const postsCountData: SplittedEngagementData = engagementData.postsCount;
        const isEngagement = key === EngagementDataType.ENGAGEMENT;

        const facebookLabel = this._computeTooltipLabelLine(data, postsCountData, PlatformKey.FACEBOOK, item.dataIndex, isEngagement);
        const instagramLabel = this._computeTooltipLabelLine(data, postsCountData, PlatformKey.INSTAGRAM, item.dataIndex, isEngagement);

        return [facebookLabel, instagramLabel];
    }

    private _computeTooltipLabelLine(
        data: SplittedEngagementData,
        postsCount: SplittedEngagementData,
        platformKey: PlatformKey,
        itemIndex: number,
        isEngagement = false
    ): string {
        const detailsText = isEngagement
            ? this._translateService.instant('statistics.social_networks.engagement.engagement_detail')
            : this._translateService.instant('statistics.social_networks.engagement.impressions_detail');

        const platformText = platformKey === PlatformKey.INSTAGRAM ? this._INSTAGRAM_KEY_TRAD : this._FACEBOOK_KEY_TRAD;
        const platformDataKey = `${platformKey.toLowerCase()}Data`;

        let valueText = '';
        const hasData = !!postsCount[platformDataKey][itemIndex];
        if (!hasData) {
            valueText = '-';
        } else {
            const value = data[platformDataKey][itemIndex] as number;
            const prettyValue = parseFloat(value.toFixed(2));
            const suffix = isEngagement ? '%' : '';
            valueText = `${prettyValue ?? this._NO_DATA_TRAD} ${suffix}`;
        }

        return `  ${detailsText} ${platformText} : ${valueText}`;
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeToolTipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }

    private _computeTimeRoundFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        const round = viewBy === ViewBy.WEEK ? ViewBy.WEEK : ViewBy.DAY;
        return round.toLowerCase() as TimeUnit;
    }

    private _formatTitle(title: Date): string {
        return this.viewBy() === ViewBy.MONTH
            ? DateTime.fromJSDate(title).toFormat(MMM_YYYY)
            : DateTime.fromJSDate(title).toFormat(DD_MMM_YYYY);
    }
}
