<div class="flex h-full flex-col px-8.5">
    <app-platforms-management-actions-header (searchChange)="onSearchChange($event)"></app-platforms-management-actions-header>
    @if (panelContents()) {
        @if (panelContents()!.length) {
            <ng-container [ngTemplateOutlet]="panelContentTemplate"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="noPanelContentTemplate"></ng-container>
        }
    } @else {
        <app-malou-spinner size="medium"></app-malou-spinner>
    }
</div>

<ng-template #panelContentTemplate>
    <div class="malou-expansion-panel">
        <mat-accordion>
            @for (panelContent of panelContents(); track panelContent.openPanelId) {
                <app-updates-and-accesses-panel-content [panelContent]="panelContent"></app-updates-and-accesses-panel-content>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template #noPanelContentTemplate>
    <div class="flex h-full flex-col items-center justify-center">
        <img class="h-32" loading="lazy" [src]="'Cook' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">
            {{ 'admin.platform_management.no_pending_access' | translate }}
        </p>
    </div>
</ng-template>
