import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    EventEmitter,
    input,
    Input,
    OnInit,
    Output,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { tap } from 'rxjs';

import { ApplyPurePipe } from '../../pipes/apply-fn.pipe';

@Component({
    selector: 'app-input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    styleUrls: ['./input-autocomplete.component.scss'],
    imports: [NgClass, FormsModule, ReactiveFormsModule, MatIconModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class InputAutocompleteComponent<T> implements OnInit {
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() errorMessage?: string;
    @Input() placeholder = '';
    @Input() required = false;
    @Input() icon?: string;
    @Input() options: T[];
    @Input() filterFn: (value: string, option: T) => boolean;
    @Input() sortFn?: (searchText: string) => (a: T, b: T) => number;
    @Input() writeValueFn: (option: T) => string;
    @Input() displayWithFn: (option: T) => string;
    @Input() minFilterLength = 0;
    @Input() maxOptionsDisplayed = 5;
    @Input() messageWhenFewResults?: string;
    @Input() set initialValue(value: T | null) {
        setTimeout(() => {
            this._ignoreNextSelectedValueOverride.set(true);
            this._setSelectedValue(value);
        }, 0); // it is needed to have a value for writeValueFn
    }
    @Input() id: string;

    readonly testId = input<string>();

    @Output() valueSelected: EventEmitter<T | null> = new EventEmitter();
    @Output() dirty: EventEmitter<void> = new EventEmitter();

    readonly filteredOptions = computed(() => {
        if (this.minFilterLength > this._filter().length) {
            return [];
        }
        let filtered = this.options.filter((option) => this.filterFn(this._filter(), option));

        if (this.sortFn) {
            filtered = filtered.sort(this.sortFn(this._filter()));
        }

        return filtered;
    });
    readonly filteredAndSlicedOptions = computed(() => this.filteredOptions().slice(0, this.maxOptionsDisplayed));

    readonly control = new UntypedFormControl();
    readonly isFocused = signal(false);
    readonly isEmptyValue = signal(true);

    private readonly _filter = signal('');
    private readonly _selectedValue: WritableSignal<T | null> = signal(null);
    private readonly _ignoreNextSelectedValueOverride = signal(false);

    constructor(private readonly _destroyRef: DestroyRef) {}

    ngOnInit(): void {
        this.control.valueChanges
            .pipe(
                tap((value) => this._setEmptyValue(value)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(this._onFilterChange);
    }

    onOptionSelected(option: T): void {
        this._setSelectedValue(option);
    }

    onFocus(): void {
        this.isFocused.set(true);
        this.dirty.emit();
    }

    isSelected = (option: T): boolean => this._selectedValue() === option;

    private _onFilterChange = (value: string): void => {
        this._filter.set(value);

        const currentSelectedValue = this._selectedValue();
        const shouldUpdateSelectedValue = !currentSelectedValue || this.displayWithFn(currentSelectedValue) !== value;

        if (this._ignoreNextSelectedValueOverride()) {
            // used for the initial value setting
            this._ignoreNextSelectedValueOverride.set(false);
        } else if (shouldUpdateSelectedValue) {
            this._setSelectedValue(null);
        }
    };

    private _setEmptyValue(value: string): void {
        this.isEmptyValue.set(value === '');
    }

    private _setSelectedValue(option: T | null): void {
        this._selectedValue.set(option);
        this.valueSelected.emit(option);
        if (option) {
            this.control.setValue(this.writeValueFn(option));
        }
    }
}
