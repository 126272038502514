@if (restaurantHasNoPost()) {
    <app-no-social-post (createPost)="createPost.emit()" (createReelOrTikTok)="createReelOrTikTok.emit()"></app-no-social-post>
} @else {
    <div class="flex h-full">
        <div class="min-h-0 grow">
            <app-social-posts-list-header
                [(selectedFilter)]="selectedFilter"
                [restaurant]="restaurant()"
                (createPost)="onCreatePost()"
                (createReelOrTikTok)="onCreateReelOrTikTok()"
                (duplicateToOtherRestaurants)="onDuplicateToOtherRestaurants($event)"
                (duplicateToSeoHere)="
                    duplicateToSeoHere.emit({ postIds: $event, postDestination: PostSource.SEO })
                "></app-social-posts-list-header>

            <div
                class="hide-scrollbar flex h-full flex-col gap-3 overflow-y-auto px-6 py-3 pb-10"
                infinite-scroll
                [infiniteScrollContainer]="scrollContainer"
                (scrolled)="onScrollDown()"
                #scrollContainer>
                @if (isFetchingPosts()) {
                    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
                } @else {
                    @for (post of posts(); track post.id) {
                        <div class="flex items-center gap-x-3">
                            @if (isSelecting()) {
                                <mat-checkbox
                                    class="checkbox"
                                    color="primary"
                                    [checked]="isSelected | applyPure: post : selectedPostsCount()"
                                    (change)="onSelect(post)"></mat-checkbox>
                            }
                            <app-social-post-item
                                class="grow"
                                [ngClass]="{ 'cursor-pointer': isSelecting() }"
                                [id]="'post-' + post.id"
                                [post]="post"
                                [isHighlighted]="isHighlighted | applyPure: post : highlightedPostIds()"
                                [isReadonly]="isSelecting()"
                                (updatePost)="onUpdatePost($event)"
                                (deletePost)="onDeletePost($event)"
                                (refreshPost)="onRefreshPost($event)"
                                (duplicatePost)="onDuplicatePost($event)"
                                (postDateChange)="onPostDateChange($event)"
                                (click)="isSelecting() && onSelect(post)"></app-social-post-item>
                        </div>
                    }
                    @if (isFetchingMorePosts()) {
                        <ng-container [ngTemplateOutlet]="postSkeletonTemplate"></ng-container>
                    }
                }
            </div>
        </div>

        <div class="w-[30%] shrink-0">
            <app-feed-header></app-feed-header>
            <div class="h-full border-l border-malou-color-background-dark">
                <app-feed
                    [feed]="feed()"
                    [shouldShowLoading]="isFetchingFeed()"
                    [shouldShowLoadingMore]="isFetchingMoreFeed()"
                    [draggable]="true"
                    (feedItemClicked)="onFeedItemClicked($event)"></app-feed>
            </div>
        </div>
    </div>
}

<ng-template #loadingTemplate>
    @for (count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track count) {
        <ng-container [ngTemplateOutlet]="postSkeletonTemplate"></ng-container>
    }
</ng-template>

<ng-template #postSkeletonTemplate>
    <app-skeleton skeletonClass="secondary-bg h-[106px] w-full"></app-skeleton>
</ng-template>
