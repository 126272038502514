import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, take } from 'rxjs';

import { getDatesBetween, isNotNil, isSameDay, PlatformKey } from '@malou-io/package-utils';

import { selectDatesFilter, selectFollowersData } from ':modules/statistics/store/statistics.selectors';
import { DatesAndPeriod, InsightsByPlatform } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    dates: DatesAndPeriod;
    followers: InsightsByPlatform;
}
interface Options {
    platformKeys: PlatformKey[];
}

@Injectable({ providedIn: 'root' })
export class FollowersCsvInsightsService extends AbstractCsvService<Data, Options> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected override _getData$(): Observable<Data> {
        return forkJoin([this._store.select(selectFollowersData).pipe(take(1)), this._store.select(selectDatesFilter).pipe(take(1))]).pipe(
            map(([followersData, datesFilter]) => ({
                dates: datesFilter,
                followers: followersData,
            }))
        );
    }

    protected override _isDataValid(data: Data, options: Options): boolean {
        if (!data?.dates?.startDate) {
            return false;
        }
        if (options.platformKeys.length === 1) {
            const platformKey = options.platformKeys[0];
            return !!data?.followers?.[platformKey];
        }
        return !!data?.followers?.facebook && !!data?.followers?.instagram;
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return ['Date', 'Followers'];
    }

    protected override _getCsvDataRows({ dates, followers }: Data, { platformKeys }: Options): CsvAsStringArrays {
        const { startDate, endDate } = dates;
        if (!startDate || !endDate) {
            return [];
        }
        const datesFiltered = getDatesBetween(startDate, endDate).filter(isNotNil);

        return datesFiltered.map((date) => {
            const localDate = date?.toLocaleDateString();

            const platformsFollowers = platformKeys
                .map(
                    (platformKey) =>
                        followers[platformKey]?.by_day?.followers?.find((day) => isSameDay(date, new Date(day.date)))?.value ?? 0
                )
                .reduce((acc, next) => acc + next, 0)
                .toString();
            return [localDate, platformsFollowers];
        });
    }
}
