import { Pipe, PipeTransform } from '@angular/core';

import { Review, SemanticAnalysisInsightsReview } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'reviewText',
    standalone: true,
})
export class ReviewTextPipe implements PipeTransform {
    constructor() {}

    transform(
        review: Review | PrivateReview | SemanticAnalysisInsightsReview,
        options?: { showTranslation: boolean; language: string }
    ): string {
        return review.getFullReviewTextWithRatingTags(options);
    }
}
