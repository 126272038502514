<mat-expansion-panel
    [expanded]="panelContent().openPanelId === currentOpenPanelId()"
    (opened)="openPanel(panelContent())"
    (closed)="closePanel()">
    <mat-expansion-panel-header>
        <div class="flex justify-between">
            <div class="flex items-center gap-x-4">
                <img
                    class="h-10 w-12 rounded-md object-cover"
                    loading="lazy"
                    [src]="panelContent().imageSrc ?? ('default_logo' | imagePathResolver)" />
                <p class="malou-text-12--semibold">{{ panelContent().title }}</p>
                <div class="diff-icon" [ngStyle]="{ 'background-color': panelContent().badge === 0 ? 'gray' : '' }">
                    {{ panelContent().badge }}
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
        @if ([InformationUpdateOptions.ACCESS, InformationUpdateOptions.BOTH] | includes: selectedOption()) {
            <app-access
                [accessList]="panelContent().accessList"
                [allAccessList]="allAccessList()"
                [isBusinessView]="isBusinessView()"
                (onTogglePlatformAccess)="onTogglePlatformAccess($event)">
            </app-access>
        }

        @if ([InformationUpdateOptions.UPDATE, InformationUpdateOptions.BOTH] | includes: selectedOption()) {
            <app-updates
                [updatesData]="updatesData()"
                [isBusinessView]="isBusinessView()"
                (onToggleInformationUpdateStatus)="onToggleInformationUpdateStatus($event)">
            </app-updates>
        }
    </ng-template>
</mat-expansion-panel>
