import { z } from 'zod';

import { CallToActionType, PlatformKey, PostPublicationStatus, PostType, SeoPostTopic } from '@malou-io/package-utils';

import { objectIdValidator, urlValidator } from '../utils';
import { postFeedbacksValidator, postMediaValidator } from './update-social-post.dto';

export const seoPostValidator = z
    .object({
        id: objectIdValidator,
        text: z.string().nullish(),
        key: z.literal(PlatformKey.GMB),
        published: z.nativeEnum(PostPublicationStatus),
        postType: z.nativeEnum(PostType),
        postTopic: z.nativeEnum(SeoPostTopic),
        event: z
            .object({
                title: z.string().optional(),
                startDate: z.string().datetime().optional(),
                endDate: z.string().datetime().optional(),
            })
            .optional(),
        offer: z
            .object({
                couponCode: z.string().optional(),
                onlineUrl: z.string().optional(),
                termsConditions: z.string().optional(),
            })
            .optional(),

        callToAction: z
            .object({
                actionType: z.nativeEnum(CallToActionType),
                url: urlValidator({ allowEmpty: true }),
            })
            .nullish(),
        error: z
            .object({
                code: z.string(),
                rawData: z.string(),
            })
            .nullish(),
        socialLink: urlValidator().nullish(),
        socialCreatedAt: z.string().datetime().nullish(),
        feedbacks: postFeedbacksValidator.nullish(),
        plannedPublicationDate: z.string().datetime(),
        attachments: z.array(postMediaValidator),
        author: z
            .object({
                id: objectIdValidator,
                name: z.string().optional(),
                lastname: z.string().optional(),
                picture: z.string().optional(),
            })
            .nullish(),
    })
    .transform((data) => ({
        ...data,
        text: data.text ?? '',
    }));

export type SeoPostDto = z.infer<typeof seoPostValidator>;
