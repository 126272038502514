@let mediaValue = media();
@if (!mediaValue || isLoading()) {
    <div class="flex h-[75px] items-center justify-center">
        <app-malou-spinner [size]="'small'"></app-malou-spinner>
    </div>
} @else {
    <div class="flex flex-col gap-y-2">
        <app-video-thumbnail-slider
            [isReadonly]="isReadonly()"
            [loadingProgress]="timelineLoadingProgress()"
            [timelinePreviewUrls]="timelinePreviewUrls()"
            [position]="thumbnailCursorPosition()"
            [sliderThumbnailUrl]="sliderThumbnailUrl()"
            (onPositionChange)="onThumbnailSelectedFromVideo.emit($event)"
            (onMediaDeleted)="deleteMedia.emit()" />

        <div class="w-full text-center text-malou-color-text-2">
            <span class="malou-color-text-2 malou-text-11--regular">{{ 'reel_media.upload_text_1' | translate }}</span>
            <span
                class="malou-color-text-primary malou-text-11--semibold"
                [ngClass]="{ 'cursor-pointer': !isReadonly() }"
                (click)="!isReadonly() && openThumbnailFilePicker.emit()"
                >{{ 'reel_media.upload_text_2' | translate }}</span
            >
            <span class="malou-color-text-2 malou-text-11--regular">{{ 'reel_media.upload_text_3' | translate }}</span>
            <span
                class="malou-color-text-primary malou-text-11--semibold"
                [ngClass]="{ 'cursor-pointer': !isReadonly() }"
                (click)="!isReadonly() && importThumbnailFromGallery.emit()"
                >{{ 'reel_media.upload_text_4' | translate }}</span
            >
        </div>
    </div>
}
