import { Component, DestroyRef, effect, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';

import { isNotNil, MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { ReviewsByAnswerStatus } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { ReviewsKpisCardComponent } from ':modules/statistics/e-reputation/reviews-kpis/reviews-kpis-card/reviews-kpis-card.component';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const MAX_PERCENTAGE = 100;

@Component({
    selector: 'app-reviews-kpis-answer-rate',
    templateUrl: './reviews-kpis-answer-rate.component.html',
    styleUrls: ['./reviews-kpis-answer-rate.component.scss'],
    standalone: true,
    imports: [ReviewsKpisCardComponent, MatIconModule, MatTooltipModule, TranslateModule, ShortNumberPipe],
    providers: [PluralTranslatePipe],
})
export class ReviewsKpisAnswerRateComponent implements OnInit {
    filters$ = input.required<Observable<[DatesAndPeriod, PlatformKey[], Restaurant, MalouComparisonPeriod]>>();
    hasDataChange = output<boolean>();
    isLoadingEvent = output<boolean>();

    private readonly _reviewsService = inject(ReviewsService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;

    readonly httpError: WritableSignal<Error | undefined> = signal(undefined);

    readonly isLoading = signal(true);

    readonly currentAnsweredRepliesRate: WritableSignal<number | undefined> = signal(undefined);
    readonly previousAnsweredRepliesRate: WritableSignal<number | undefined> = signal(undefined);
    readonly diffAnsweredRepliesRate: WritableSignal<number | undefined> = signal(undefined);
    readonly comparisonPeriodKey: WritableSignal<string> = signal('');

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        this.filters$()
            .pipe(
                filter(
                    ([dates, platforms, restaurant]: [DatesAndPeriod, PlatformKey[], Restaurant, MalouComparisonPeriod]) =>
                        isNotNil(restaurant) && isDateSetOrGenericPeriod(dates) && platforms.length > 0
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant, comparisonPeriod]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        Restaurant,
                        MalouComparisonPeriod,
                    ]): Observable<[ReviewsByAnswerStatus, ReviewsByAnswerStatus]> => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;
                        this.comparisonPeriodKey.set(this._getComparisonPeriodKey(comparisonPeriod));
                        return forkJoin([
                            this._reviewsService
                                .getChartRestaurantsReviewsReplied({ restaurantId, platforms, startDate, endDate })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                            this._reviewsService
                                .getChartRestaurantsReviewsReplied({
                                    restaurantId,
                                    platforms,
                                    startDate,
                                    endDate,
                                    comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
                                })
                                .pipe(catchError((error) => this._handleHttpError(error))),
                        ]);
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([currentReviewsReplies, previousReviewsReplies]: [ReviewsByAnswerStatus, ReviewsByAnswerStatus]) => {
                this.currentAnsweredRepliesRate.set(this._computeCurrentAnsweredRepliesRate(currentReviewsReplies));
                this.previousAnsweredRepliesRate.set(this._computeCurrentAnsweredRepliesRate(previousReviewsReplies));
                this.diffAnsweredRepliesRate.set(
                    this._computeDiffAnsweredRepliesRate(this.currentAnsweredRepliesRate(), previousReviewsReplies)
                );

                this.hasDataChange.emit(!!this.currentAnsweredRepliesRate());
                this.isLoading.set(false);
            });
    }

    private _handleHttpError(error: Error): Observable<never> {
        this.httpError.set(error);
        this.hasDataChange.emit(false);
        this.isLoading.set(false);
        return EMPTY;
    }

    private _computeCurrentAnsweredRepliesRate(currentReviewsReplies: ReviewsByAnswerStatus): number | undefined {
        const currentAnsweredTotal = currentReviewsReplies?.results?.answered?.length;
        const currentNotAnsweredTotal = currentReviewsReplies?.results?.notAnswered?.length;
        if (currentAnsweredTotal != null && currentNotAnsweredTotal != null) {
            if (currentNotAnsweredTotal === 0) {
                return currentAnsweredTotal > 0 ? MAX_PERCENTAGE : 0;
            }
            return (currentAnsweredTotal / (currentAnsweredTotal + currentNotAnsweredTotal)) * 100;
        }
    }

    private _computeDiffAnsweredRepliesRate(
        currentAnsweredReviewsReplies: number | undefined,
        previousReviewsReplies: ReviewsByAnswerStatus
    ): number | undefined {
        const previousAnsweredTotal = previousReviewsReplies?.results?.answered?.length;
        const previousNotAnsweredTotal = previousReviewsReplies?.results?.notAnswered?.length;
        let previousAnsweredRepliesRate: number | undefined;
        if (previousAnsweredTotal != null && previousNotAnsweredTotal != null) {
            if (previousNotAnsweredTotal === 0) {
                previousAnsweredRepliesRate = previousAnsweredTotal > 0 ? MAX_PERCENTAGE : 0;
            } else {
                previousAnsweredRepliesRate = (previousAnsweredTotal / (previousAnsweredTotal + previousNotAnsweredTotal)) * 100;
            }
        }
        if (currentAnsweredReviewsReplies != null && previousAnsweredRepliesRate != null) {
            return currentAnsweredReviewsReplies - previousAnsweredRepliesRate;
        }
    }

    private _reset(): void {
        this.httpError.set(undefined);
        this.isLoading.set(true);
    }

    private _getComparisonPeriodKey(comparisonPeriod: MalouComparisonPeriod): string {
        return `date_filter.comparison_period.${comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD}`;
    }
}
