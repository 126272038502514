import { NgTemplateOutlet } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-reviews-kpis-card',
    standalone: true,
    imports: [NgTemplateOutlet, NumberEvolutionComponent, SkeletonComponent, MatIconModule, MatTooltipModule, StatisticsHttpErrorPipe],
    templateUrl: './reviews-kpis-card.component.html',
    styleUrl: './reviews-kpis-card.component.scss',
})
export class ReviewsKpisCardComponent {
    readonly title = input.required<string>();
    readonly comparisonPeriodTitle = input<string>();
    readonly icon = input.required<string>();
    readonly currentValue = input.required<string>();
    readonly previousValue = input<string>();
    readonly currentValueUnit = input<string>('');
    readonly currentValueRaw = input<string | number>();
    readonly valueTooltip = input<string>('');
    readonly diffValue = input<number>();
    readonly diffDisplayedValue = input<string>();
    readonly numberEvolutionTooltip = input<string>('');
    readonly reverseArrow = input<boolean>(false);
    readonly isLoading = input<boolean>(false);
    readonly error = input<Error>();

    readonly SvgIcon = SvgIcon;
}
