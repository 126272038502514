import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import {
    EditMediaModalComponent,
    EditMediaModalDialogData,
    EditMediaModalDialogResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/post-media-list/components/edit-media-modal/edit-media-modal.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaThumbnailListComponent } from './components/media-thumbnail-list/media-thumbnail-list.component';

@Component({
    selector: 'app-post-media-list',
    templateUrl: './post-media-list.component.html',
    standalone: true,
    imports: [MatIconModule, TranslateModule, MediaThumbnailListComponent, MatMenuModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostMediaListComponent {
    private readonly _customDialogService = inject(CustomDialogService);

    readonly medias = model.required<EditionMedia[]>();
    readonly showEditMediaButton = input(true);
    readonly uploadingMediaCount = input(0);
    readonly isReadonly = input.required<boolean>();
    readonly mediaClicked = output<string>();
    readonly disableDragEvents = output();
    readonly enableDragEvents = output();
    readonly openFilePicker = output();
    readonly importFromGallery = output();

    readonly SvgIcon = SvgIcon;

    private readonly _isModalOpen = signal(false);

    readonly mediasForThumbnailList = computed(() =>
        this.medias().map((media) => ({
            id: media.id,
            url: media.thumbnail256OutsideUrl,
            dimensions: media.thumbnail256OutsideDimensions,
            transformData: media.transformData,
            type: media.type,
        }))
    );

    onEditMedia(mediaId: string): void {
        this._isModalOpen.set(true);
        this._customDialogService
            .open<EditMediaModalComponent, EditMediaModalDialogData, EditMediaModalDialogResult>(EditMediaModalComponent, {
                width: '700px',
                data: {
                    medias: this.medias(),
                    selectedMediaId: mediaId,
                },
            })
            .afterClosed()
            .subscribe((data) => {
                this._isModalOpen.set(false);
                if (data) {
                    this.medias.set(data.medias);
                }
            });
    }

    onRemoveMedia(mediaId: string): void {
        const medias = this.medias().filter((m) => m.id !== mediaId);
        this.medias.set(medias);
    }

    onDropMedia(event: { previousIndex: number; currentIndex: number }): void {
        const medias = [...this.medias()];
        moveItemInArray(medias, event.previousIndex, event.currentIndex);
        this.medias.set(medias);
    }
}
