import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AddFeedbackMessageBodyDto, FeedbacksDto, UpdateFeedbackMessageBodyDto } from '@malou-io/package-dto';
import { ApiResultV2, PostFeedbacks, PostFeedbacksParticipant } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { environment } from ':environments/environment';
import { objectToSnakeCase, removeNullOrUndefinedField } from ':shared/helpers';
import { ApiResult } from ':shared/models';
import { Feedback, FeedbackUser } from ':shared/models/feedback';

@Injectable({
    providedIn: 'root',
})
export class FeedbacksService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/feedbacks`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    getFeedback(feedbackId: string): Observable<ApiResult<Feedback>> {
        return this._http.get<ApiResult<Feedback>>(`${this.API_BASE_URL}/${feedbackId}`, { withCredentials: true }).pipe(
            map((res) => {
                res.data = new Feedback(res.data);
                return res;
            })
        );
    }

    createFeedback(postId: string): Observable<ApiResultV2<Feedback>> {
        return this._http
            .post<ApiResultV2<FeedbacksDto>>(this.API_BASE_URL, { postId }, { withCredentials: true })
            .pipe(map((res) => ({ data: Feedback.fromDto(res.data) })));
    }

    createFeedbackV2(postId: string): Observable<ApiResultV2<PostFeedbacks>> {
        return this._http
            .post<ApiResultV2<FeedbacksDto>>(this.API_BASE_URL, { postId }, { withCredentials: true })
            .pipe(map((res) => ({ data: this._feedbacksDtoToPostFeedbacks(res.data) })));
    }

    updateFeedbackIsOpen(
        feedbackId: string,
        isOpen: boolean,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http
            .put<ApiResultV2<FeedbacksDto>>(`${this.API_BASE_URL}/${feedbackId}/is-open`, { isOpen }, { withCredentials: true, params })
            .pipe(map((res) => ({ data: Feedback.fromDto(res.data) })));
    }

    updateFeedbackIsOpenV2(
        feedbackId: string,
        isOpen: boolean,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<PostFeedbacks>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));
        return this._http
            .put<ApiResultV2<FeedbacksDto>>(`${this.API_BASE_URL}/${feedbackId}/is-open`, { isOpen }, { withCredentials: true, params })
            .pipe(map((res) => ({ data: this._feedbacksDtoToPostFeedbacks(res.data) })));
    }

    addFeedbackMessage(
        feedbackId: string,
        message: AddFeedbackMessageBodyDto['message'],
        participants: FeedbackUser[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));

        const body: AddFeedbackMessageBodyDto = {
            message: {
                text: message.text,
                type: message.type,
                visibility: message.visibility,
            },
            participants: participants.map((participant) => ({
                id: participant._id,
                name: participant.name,
                lastname: participant.lastname,
                email: participant.email,
            })),
        };

        return this._http
            .post<ApiResultV2<FeedbacksDto>>(`${this.API_BASE_URL}/${feedbackId}/messages`, body, { withCredentials: true, params })
            .pipe(map((res) => ({ data: Feedback.fromDto(res.data) })));
    }

    addFeedbackMessageV2(
        feedbackId: string,
        message: AddFeedbackMessageBodyDto['message'],
        participants: PostFeedbacksParticipant[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<PostFeedbacks>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));

        const body: AddFeedbackMessageBodyDto = {
            message: {
                text: message.text,
                type: message.type,
                visibility: message.visibility,
            },
            participants: participants.map((participant) => ({
                id: participant.id,
                name: participant.name,
                lastname: participant.lastname,
                email: participant.email,
                role: participant.role,
            })),
        };

        return this._http
            .post<ApiResultV2<FeedbacksDto>>(`${this.API_BASE_URL}/${feedbackId}/messages`, body, {
                withCredentials: true,
                params,
            })
            .pipe(
                map((res) => ({
                    data: this._feedbacksDtoToPostFeedbacks(res.data),
                }))
            );
    }

    updateFeedbackMessage(
        feedbackId: string,
        messageId: string,
        message: UpdateFeedbackMessageBodyDto['message'],
        participants: FeedbackUser[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));

        const body: UpdateFeedbackMessageBodyDto = {
            message: {
                text: message.text,
                visibility: message.visibility,
            },
            participants: participants.map((participant) => ({
                id: participant._id,
                name: participant.name,
                lastname: participant.lastname,
                email: participant.email,
            })),
        };

        return this._http
            .put<
                ApiResultV2<FeedbacksDto>
            >(`${this.API_BASE_URL}/${feedbackId}/messages/${messageId}`, body, { withCredentials: true, params })
            .pipe(map((res) => ({ data: Feedback.fromDto(res.data) })));
    }

    updateFeedbackMessageV2(
        feedbackId: string,
        messageId: string,
        message: UpdateFeedbackMessageBodyDto['message'],
        participants: PostFeedbacksParticipant[],
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<PostFeedbacks>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId }));

        const body: UpdateFeedbackMessageBodyDto = {
            message: {
                text: message.text,
                visibility: message.visibility,
            },
            participants: participants.map((participant) => ({
                id: participant.id,
                name: participant.name,
                lastname: participant.lastname,
                email: participant.email,
                role: participant.role,
            })),
        };

        return this._http
            .put<
                ApiResultV2<FeedbacksDto>
            >(`${this.API_BASE_URL}/${feedbackId}/messages/${messageId}`, body, { withCredentials: true, params })
            .pipe(map((res) => ({ data: this._feedbacksDtoToPostFeedbacks(res.data) })));
    }

    deleteFeedbackMessage(
        feedbackId: string,
        messageId: string,
        sendFakeOpenedEmail = false,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<Feedback>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId, sendFakeOpenedEmail }));
        return this._http
            .delete<
                ApiResultV2<FeedbacksDto>
            >(`${this.API_BASE_URL}/${feedbackId}/messages/${messageId}`, { withCredentials: true, params })
            .pipe(map((res) => ({ data: Feedback.fromDto(res.data) })));
    }

    deleteFeedbackMessageV2(
        feedbackId: string,
        messageId: string,
        sendFakeOpenedEmail = false,
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResultV2<PostFeedbacks>> {
        const params = removeNullOrUndefinedField(objectToSnakeCase({ restaurantId, sendFakeOpenedEmail }));
        return this._http
            .delete<
                ApiResultV2<FeedbacksDto>
            >(`${this.API_BASE_URL}/${feedbackId}/messages/${messageId}`, { withCredentials: true, params })
            .pipe(map((res) => ({ data: this._feedbacksDtoToPostFeedbacks(res.data) })));
    }

    private _feedbacksDtoToPostFeedbacks(dto: FeedbacksDto): PostFeedbacks {
        return {
            id: dto.id,
            isOpen: dto.isOpen,
            participants: dto.participants,
            feedbackMessages: dto.feedbackMessages.map((feedbackMessage) => ({
                id: feedbackMessage.id,
                author: feedbackMessage.author,
                text: feedbackMessage.text,
                type: feedbackMessage.type,
                visibility: feedbackMessage.visibility,
                createdAt: new Date(feedbackMessage.createdAt),
                updatedAt: new Date(feedbackMessage.updatedAt),
                publishedAt: feedbackMessage.publishedAt ? new Date(feedbackMessage.publishedAt) : undefined,
                lastUpdatedAt: feedbackMessage.lastUpdatedAt ? new Date(feedbackMessage.lastUpdatedAt) : undefined,
            })),
            updatedAt: new Date(dto.updatedAt),
            createdAt: new Date(dto.createdAt),
        };
    }
}
