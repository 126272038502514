import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import {
    AggregationTimeScale,
    getDatesBetween,
    isNotNil,
    isSameDay,
    MalouMetric,
    PlatformDefinitions,
    PlatformKey,
} from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { selectDatesFilter } from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { InsightsService } from ':modules/statistics/insights.service';
import { DatesAndPeriod, InsightsByPlatformByRestaurant, Restaurant } from ':shared/models';

import { AbstractCsvService } from '../csv-service.abstract';

interface Data {
    insights: InsightsByPlatformByRestaurant;
    restaurants: Restaurant[];
    dates: DatesAndPeriod;
}
interface Options {
    platformKeys: PlatformKey[];
}

@Injectable({ providedIn: 'root' })
export class AggregatedFollowersCsvInsightsService extends AbstractCsvService<Data, Options> {
    constructor(
        private readonly _store: Store,
        private readonly _insightsService: InsightsService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        super();
    }

    protected override _getData$(): Observable<Data> {
        return forkJoin([
            this._store.select(selectDatesFilter).pipe(take(1)),
            this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
        ]).pipe(
            switchMap(([dates, restaurants]) => {
                const { startDate, endDate } = dates;
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                return forkJoin([
                    this._insightsService
                        .getInsights({
                            restaurantIds,
                            platformKeys: PlatformDefinitions.getPlatformKeysWithFollowers(),
                            metrics: [MalouMetric.FOLLOWERS],
                            aggregators: [AggregationTimeScale.BY_DAY],
                            startDate,
                            endDate,
                        })
                        .pipe(map((res) => res.data)),
                    of(restaurants),
                    of(dates),
                ]).pipe(
                    map(([data, _restaurants, _dates]) => ({
                        insights: data,
                        restaurants: _restaurants,
                        dates: _dates,
                    }))
                );
            })
        );
    }

    protected override _isDataValid(data: Data): boolean {
        return Object.values(data.insights).filter((value) => !value?.facebook?.error && !value?.instagram?.error).length > 0;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Date', 'Location', 'Location Internal Name', 'Location Address', 'Followers'];
    }

    protected override _getCsvDataRows(data: Data, options: Options): string[][] {
        const { startDate, endDate } = data.dates;
        if (!startDate || !endDate) {
            return [];
        }
        const dates = getDatesBetween(startDate, endDate).filter(isNotNil);
        const { insights, restaurants } = data;

        return dates
            .map((date) =>
                Object.keys(insights)
                    .map((restaurantId) => {
                        const restaurant = restaurants.find((r) => r._id === restaurantId);
                        const restaurantInsights = insights[restaurantId];
                        if (!restaurant || (restaurantInsights.facebook?.error && restaurantInsights.instagram?.error)) {
                            return null;
                        }
                        const localDate = date?.toLocaleDateString();
                        const restaurantName = restaurant.name;
                        const restaurantInternalName = restaurant.internalName ?? '';
                        const restaurantAddress = restaurant.getFullFormattedAddress().replace(/,/g, '');
                        const followers = options.platformKeys
                            .map(
                                (platformKey) =>
                                    restaurantInsights[platformKey]?.by_day?.followers?.find((day) => isSameDay(date, new Date(day.date)))
                                        ?.value ?? 0
                            )
                            .reduce((acc, next) => acc + next, 0)
                            .toString();

                        return [localDate, restaurantName, restaurantInternalName, restaurantAddress, followers];
                    })
                    .filter(isNotNil)
            )
            .flat();
    }
}
