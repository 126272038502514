import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { CommentsComponent } from '../comments/comments.component';
import { MessagesComponent } from '../messages/messages.component';
import { InteractionsComponent } from './interactions.component';

export const INTERACTIONS_ROUTES: Routes = [
    {
        path: '',
        component: InteractionsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'messages',
            },
            {
                path: 'messages',
                component: MessagesComponent,
                data: { routePath: RoutePath.MESSAGES },
            },
            {
                path: 'comments',
                component: CommentsComponent,
                data: { routePath: RoutePath.COMMENTS },
            },
        ],
    },
];
