import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { combineLatest, filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import * as PlatformsReducer from ':modules/platforms/store/platforms.reducer';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import { selectFilters } from ':modules/statistics/store/statistics.selectors';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';
import { DataToCsvRowMapperService } from ':shared/services/csv-services/insights/summary/data-csv-row-mapper';
import { SummaryCsvInsightsEReputationSectionService } from ':shared/services/csv-services/insights/summary/summary-sections/e-reputation-data-section.service';
import { SummaryCsvInsightsSeoSectionService } from ':shared/services/csv-services/insights/summary/summary-sections/seo-data-section.service';
import { SummaryCsvData } from ':shared/services/csv-services/insights/summary/summary.interface';

@Injectable({ providedIn: 'root' })
export class SummaryCsvInsightsService extends AbstractCsvService<SummaryCsvData> {
    constructor(
        private readonly _store: Store,
        private readonly _dataToCsvRowMapperService: DataToCsvRowMapperService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _summaryCsvInsightsSeoSectionService: SummaryCsvInsightsSeoSectionService,
        private readonly _summaryCsvInsightsEReputationSectionService: SummaryCsvInsightsEReputationSectionService
    ) {
        super();
    }

    protected _isDataValid(): boolean {
        return true;
    }

    protected override _getData$(): Observable<SummaryCsvData> {
        return combineLatest([
            this._store.select(selectFilters),
            this._restaurantsService.restaurantSelected$,
            this._store.select(PlatformsReducer.selectCurrentPlatformKeys),
        ]).pipe(
            filter(([filters, restaurant]) => isNotNil(restaurant) && !!filters.isFiltersLoaded),
            switchMap(([filters, restaurant, platformKeys]) =>
                forkJoin([
                    this._summaryCsvInsightsSeoSectionService.execute(filters, restaurant!),
                    this._summaryCsvInsightsEReputationSectionService.execute(filters, restaurant!, platformKeys),
                    of(filters),
                ])
            ),
            map(([seoSectionData, eReputationSectionData, filters]) => {
                const formattedDates = this._getFormattedDates(filters);
                return {
                    startDate: formattedDates.startDate,
                    endDate: formattedDates.endDate,
                    previousStartDate: formattedDates.previousStartDate,
                    previousEndDate: formattedDates.previousEndDate,
                    current: {
                        seo: seoSectionData.current,
                        eReputation: eReputationSectionData.current,
                    },
                    previous: {
                        seo: seoSectionData.previous,
                        eReputation: eReputationSectionData.previous,
                    },
                };
            })
        );
    }
    protected override _getCsvHeaderRow(data: SummaryCsvData): CsvAsStringArrays[0] {
        return ['Data', `${data.startDate} - ${data.endDate}`, `${data.previousStartDate} - ${data.previousEndDate}`, 'Evolution'];
    }

    protected override _getCsvDataRows(data: SummaryCsvData): CsvAsStringArrays {
        const seoRows = this._dataToCsvRowMapperService.mapSeoSectionDataToCsvRows(data.current.seo, data.previous.seo);
        const eReputationRows = this._dataToCsvRowMapperService.mapEReputationSectionDataToCsvRows(
            data.current.eReputation,
            data.previous.eReputation
        );
        return [...seoRows, ...eReputationRows];
    }

    private _getFormattedDates(
        filters: StatisticsState['filters']
    ): Pick<SummaryCsvData, 'startDate' | 'endDate' | 'previousStartDate' | 'previousEndDate'> {
        const startDateLuxon = DateTime.fromObject(filters.monthYearPeriod.startMonthYear);
        const endDateLuxon = DateTime.fromObject(filters.monthYearPeriod.endMonthYear);
        const periodInMonths = endDateLuxon.diff(startDateLuxon, 'months').months;

        const previousStartDate = startDateLuxon.minus({ months: periodInMonths });
        const previousEndDate = startDateLuxon.minus({ months: 1 });

        return {
            startDate: startDateLuxon.toFormat('yyyy-MM-dd'),
            endDate: endDateLuxon.toFormat('yyyy-MM-dd'),
            previousStartDate: previousStartDate.toFormat('yyyy-MM-dd'),
            previousEndDate: previousEndDate.toFormat('yyyy-MM-dd'),
        };
    }
}
