<div
    class="absolute z-10 cursor-default rounded-[10px] bg-white px-4.5 py-3.5"
    [ngStyle]="TAG_ACCOUNT_SIZE_STYLE"
    (click)="$event.stopPropagation()"
    #addTagAccountContainer>
    <div class="flex justify-between">
        <div class="malou-text-12--semibold">{{ 'social_posts.new_social_post.identify_account' | translate }}</div>
        <div class="ml-2">
            <mat-icon
                class="!h-4 !w-4 cursor-pointer text-malou-color-primary"
                [svgIcon]="SvgIcon.CROSS"
                (click)="onClose.emit($event); $event.stopPropagation()"></mat-icon>
        </div>
    </div>
    <div class="malou-text-10--regular mt-3 italic text-malou-color-text-2">
        {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.tag_account.description' | translate }}
    </div>
    <div class="malou-border-primary border-primary mt-3 flex h-12 self-stretch rounded-lg bg-white">
        <div class="w-full px-5">
            <input
                class="malou-text-12--medium box-border h-full w-full rounded-lg border-0 outline-none"
                type="text"
                [placeholder]="'common.search' | translate"
                [formControl]="tagControl()"
                [matAutocomplete]="auto" />
        </div>

        <div class="flex items-center pr-5">
            @if (!searching()) {
                <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
            }
            @if (searching()) {
                <mat-spinner class="stroke-malou-color-primary" [diameter]="16"></mat-spinner>
            }
        </div>
    </div>
    <mat-autocomplete
        class="!min-w-[300px] translate-x-[-10%] transform !rounded-[10px]"
        autoActiveFirstOption
        (optionSelected)="onAccountSelected.emit($event)"
        #auto="matAutocomplete">
        @if (foundAccount(); as foundAccount) {
            <mat-option [value]="foundAccount">
                <div class="flex items-center gap-x-3">
                    <img
                        class="h-7.5 w-7.5 rounded-full"
                        [defaultImage]="'default-picture-grey' | illustrationPathResolver"
                        [lazyLoad]="foundAccount.profile_picture_url ?? ''"
                        [errorImage]="'default-picture-grey' | illustrationPathResolver" />
                    <span
                        class="malou-text-10--semibold text-malou-color-text-2"
                        [matTooltip]="foundAccount.username.length > 16 ? (foundAccount.username | shortText: 200) : ''">
                        {{ '@' }}{{ foundAccount.username | shortText: 16 }}
                        <span class="malou-text-10--regular italic text-malou-color-text-2"
                            >({{ foundAccount.followers_count }} {{ 'common.followers' | translate }})</span
                        >
                    </span>
                </div>
            </mat-option>
        }
        @for (userTag of userTagsHistoryFiltered(); track userTag.username) {
            <mat-option [value]="userTag.igAccount">
                <div class="flex items-center gap-x-3">
                    <img
                        class="h-7.5 w-7.5 rounded-full"
                        [defaultImage]="'default-picture-grey' | illustrationPathResolver"
                        [lazyLoad]="userTag.igAccount.profile_picture_url ?? ''"
                        [errorImage]="'default-picture-grey' | illustrationPathResolver" />
                    <span
                        class="malou-text-10--semibold text-malou-color-text-2"
                        [matTooltip]="userTag.igAccount.username.length > 16 ? (userTag.igAccount.username | shortText: 200) : ''">
                        {{ '@' }}{{ userTag.igAccount.username | shortText: 16 }}
                        <span class="malou-text-10--regular italic text-malou-color-text-2"
                            >({{ userTag.igAccount.followers_count }} {{ 'common.followers' | translate }})</span
                        >
                    </span>
                </div>
            </mat-option>
        }
    </mat-autocomplete>

    <div class="absolute" id="triangle" [ngClass]="{ 'rotate-180': !showArrowAbove() }" [ngStyle]="tagPositionStyle()"></div>
</div>
