import { z } from 'zod';

import { latlngDtoValidator } from '../../restaurant';
import { urlValidator } from '../../utils';

export const storeLocatorStorePageInformationBlockValidator = z.object({
    restaurantName: z.string(),
    restaurantLocation: z.string(),
    imageUrl: urlValidator(),
    imageDescription: z.string(),
    fullAddress: z.string(),
    coordinates: latlngDtoValidator,
    phone: z.string().optional(),
    itineraryUrl: z.string(),
    orderUrl: z.string(),
    attributesNames: z.array(z.string()),
    paymentMethods: z.array(z.string()),
    hours: z.array(
        z.object({
            day: z.string(),
            formattedHour: z.string(),
            periods: z.array(
                z.object({
                    openTime: z.string().optional(),
                    closeTime: z.string().optional(),
                    isClosed: z.boolean(),
                    openDay: z.string(),
                    closeDay: z.string(),
                })
            ),
        })
    ),
});
