import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    input,
    OnDestroy,
    output,
    signal,
    viewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MediaDimensionDto } from '@malou-io/package-dto';
import { MediaType } from '@malou-io/package-utils';

import { ImageEditorUtils } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/post-media-list/components/edit-media-modal/components/image-editor/image-editor-utils';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

interface Data {
    thumbnailUrl: string;
    dimensions?: {
        width: number;
        height: number;
    };
    transformData?: {
        rotationInDegrees: number;
        left: number;
        top: number;
        width: number;
        height: number;
    };
    videoOptions?: {
        videoUrl: string;
        videoDimensions: MediaDimensionDto;
    };
}

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    standalone: true,
    imports: [NgOptimizedImage, NgClass, NgTemplateOutlet, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerComponent implements OnDestroy {
    readonly data = input.required<Data>();
    readonly shouldLazyLoadMedia = input(true);
    readonly isPlayingVideoChange = output<boolean>();

    readonly errorOnImage = output<ErrorEvent>();

    readonly hasOptionalData = computed(() => !!this.data().dimensions && !!this.data().transformData);

    private readonly _containerElement = viewChild.required<ElementRef<HTMLDivElement>>('containerElement');
    private readonly _imgElement = viewChild<ElementRef<HTMLImageElement>>('imgElement');
    private readonly _videoElement = viewChild<ElementRef<HTMLVideoElement>>('videoElement');

    readonly SvgIcon = SvgIcon;
    readonly MediaType = MediaType;

    readonly isPlayingVideo = signal(false);

    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private readonly _containerSizeChanged = signal(0);
    private readonly _resizeObserver = new ResizeObserver(() => {
        this._containerSizeChanged.update((e) => ++e);
    });

    constructor() {
        effect(
            () => {
                const data = this.data();
                if (!data.transformData) {
                    this._resetMediaContainerStyles();
                    return;
                }
                const containerElement = this._containerElement();
                const imgElement = this._imgElement();
                if (imgElement && data.dimensions) {
                    this._computeStyles(data.dimensions, data.transformData, containerElement.nativeElement, imgElement.nativeElement);
                    return;
                }
                const videoElement = this._videoElement();
                const videoDimensions = data.videoOptions?.videoDimensions;
                if (videoElement && videoDimensions) {
                    this._computeStyles(videoDimensions, data.transformData, containerElement.nativeElement, videoElement.nativeElement);
                    return;
                }
                this._resetMediaContainerStyles();
            },
            { allowSignalWrites: true }
        );

        effect(() => {
            this._resizeObserver.disconnect();
            const containerElement = this._containerElement()?.nativeElement;
            if (containerElement) {
                this._resizeObserver.observe(containerElement);
            }
        });

        effect(() => {
            this.isPlayingVideoChange.emit(this.isPlayingVideo());
        });
    }

    ngOnDestroy(): void {
        this._resizeObserver.disconnect();
    }

    onError(event: ErrorEvent): void {
        this.errorOnImage.emit(event);
    }

    togglePlayPauseVideo = async (): Promise<void> => {
        const videoPlayerElement = this._videoElement()?.nativeElement;
        if (!videoPlayerElement) {
            return this._playVideo();
        }
        if (videoPlayerElement.paused) {
            return this._playVideo();
        }
        return this._pauseVideo();
    };

    private _resetMediaContainerStyles(): void {
        const imgElement = this._imgElement()?.nativeElement;
        const videoElement = this._videoElement()?.nativeElement;
        if (imgElement) {
            imgElement.style.width = '';
            imgElement.style.height = '';
            imgElement.style.transform = '';
        }
        if (videoElement) {
            videoElement.style.width = '';
            videoElement.style.height = '';
            videoElement.style.transform = '';
        }
    }

    private _playVideo = async (): Promise<void> => {
        this.isPlayingVideo.set(true);
        this._changeDetectorRef.detectChanges();

        const videoPlayerElement = this._videoElement()?.nativeElement;
        if (!videoPlayerElement) {
            return;
        }
        try {
            await videoPlayerElement.play();
        } catch (error) {
            console.error('Error while playing video', error);
            this.isPlayingVideo.set(false);
        }
    };

    private _pauseVideo = (): void => {
        const videoPlayerElement = this._videoElement()?.nativeElement;
        if (!videoPlayerElement) {
            return;
        }
        videoPlayerElement.pause();
        videoPlayerElement.removeEventListener('ended', this._playVideo);
        this.isPlayingVideo.set(false);
    };

    private _computeStyles(
        mediaDimensions: Required<Data>['dimensions'],
        transformData: Required<Data>['transformData'],
        containerElement: HTMLDivElement,
        mediaElement: HTMLImageElement | HTMLVideoElement
    ): void {
        const containerSize = { width: containerElement.clientWidth, height: containerElement.clientHeight };

        const is90DegreesRotated = transformData.rotationInDegrees % 180 === 90;
        const mediaDimensionsRotated = {
            width: is90DegreesRotated ? mediaDimensions.height : mediaDimensions.width,
            height: is90DegreesRotated ? mediaDimensions.width : mediaDimensions.height,
        };

        const transformArea = ImageEditorUtils.scaleTransformArea(transformData, mediaDimensionsRotated);

        const scaleRatio = ImageEditorUtils.getZoomRatioToCover(transformArea, containerSize);

        const transformAreaZoomed = ImageEditorUtils.scaleTransformArea(transformArea, { width: scaleRatio, height: scaleRatio });

        const scaleTransform = `scale(${scaleRatio})`;
        const rotateTransform = `rotate(${transformData.rotationInDegrees ?? 0}deg)`;

        const offsetTranslateModifier = this._getOffsetTranslateModifier(transformData.rotationInDegrees);
        const offsetTranslateXPercentage =
            offsetTranslateModifier.x * ((is90DegreesRotated ? transformData.top : transformData.left) * 100);
        const offsetTranslateYPercentage =
            offsetTranslateModifier.y * ((is90DegreesRotated ? transformData.left : transformData.top) * 100);
        const offsetTranslateTransform = `translate(${offsetTranslateXPercentage}%, ${offsetTranslateYPercentage}%)`;

        const scaledCenteringX = ((transformAreaZoomed.width - containerSize.width) / 2) * -1;
        const scaledCenteringY = ((transformAreaZoomed.height - containerSize.height) / 2) * -1;
        const scaledTranslateTransform = `translate(${scaledCenteringX}px, ${scaledCenteringY}px)`;

        const undoRotationTranslatePercentages = this._getUndoRotationTranslatePercentages(transformData.rotationInDegrees);
        const undoRotationTranslateTransform = `translate(${undoRotationTranslatePercentages.x}%, ${undoRotationTranslatePercentages.y}%)`;

        /**
         * Reminder : In css, each transform is executed from left to right
         *
         * From left to right :
         *    - offsetTranslateTransform : we simply apply the transformData 'left' and 'top' attributes
         *    - undoRotationTranslateTransform : we cancel the position shift caused by the following rotation
         *      More info in _getUndoRotationTranslatePercentages.
         *    - rotateTransform : we rotate the media
         *    - scaleTransform : we scale the media
         *    - scaledTranslateTransform : we center the media on the container
         */
        // eslint-disable-next-line max-len
        mediaElement.style.transform = `${scaledTranslateTransform} ${scaleTransform} ${rotateTransform} ${undoRotationTranslateTransform} ${offsetTranslateTransform}`;
        mediaElement.style.transformOrigin = 'top left';
        mediaElement.style.width = `${mediaDimensions.width}px`;
        mediaElement.style.height = `${mediaDimensions.height}px`;
    }

    /**
     * If the media has rotation, we need to flip the sign if we apply a translate() that is positioned at the right of the rotate().
     * Reminder : In css, each transform is executed from left to right
     */
    private _getOffsetTranslateModifier(rotationInDegrees: number): { x: 1 | -1; y: 1 | -1 } {
        if (rotationInDegrees === 0) {
            return { x: -1, y: -1 };
        }
        if (rotationInDegrees === 90) {
            return { x: -1, y: 1 };
        }
        if (rotationInDegrees === 180) {
            return { x: 1, y: 1 };
        }
        if (rotationInDegrees === 270) {
            return { x: 1, y: -1 };
        }
        return { x: -1, y: -1 };
    }

    /**
     * We always want the media top left corner to be at the top left corner of his parent container.
     * If we will apply a rotate() (ie rotationInDegrees !== 0), we preemptively translate the media with these values
     * So that after the rotate(), the media is at the top left corner the his parent container.
     */
    private _getUndoRotationTranslatePercentages(rotationInDegrees: number): { x: number; y: number } {
        if (rotationInDegrees === 0) {
            return { x: 0, y: 0 };
        }
        if (rotationInDegrees === 90) {
            return { x: 0, y: -100 };
        }
        if (rotationInDegrees === 180) {
            return { x: -100, y: -100 };
        }
        if (rotationInDegrees === 270) {
            return { x: -100, y: 0 };
        }
        return { x: 0, y: 0 };
    }
}
