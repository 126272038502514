<div class="malou-dialog malou-dialog-mobile">
    <div class="malou-dialog__header">
        <span>
            {{ 'aggregated_statistics.seo.top_keyword_search_impression.modal.title' | translate: { keyword: data.keywordSearch } }}
        </span>
        <mat-icon class="close min-w-4 cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="cancel()"></mat-icon>
    </div>
    <div class="malou-dialog__body hide-scrollbar mb-4 overflow-auto">
        <div class="flex flex-col gap-4">
            @for (restaurant of data.restaurants; track $index) {
                <div class="malou-simple-card flex flex-1 justify-between px-6 py-4">
                    <div class="flex items-center gap-4">
                        <div class="w-[22px]">
                            <img class="w-full" [src]="getEmojiFromPosition | applyPure: $index" />
                        </div>
                        <p class="malou-text-13 malou-color-text-2">
                            {{ restaurant.name }}
                        </p>
                    </div>
                    <div class="malou-text-13--regular malou-color-text-1 flex items-center gap-2">
                        <span>{{ restaurant.avgValuePerRestaurant }} </span>
                        <app-number-evolution
                            size="xs"
                            [value]="restaurant.evolution"
                            [displayedValue]="restaurant.evolution | shortNumber: { shouldDisplayMinusSign: false }">
                        </app-number-evolution>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
