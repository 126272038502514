<div
    class="flex items-center gap-x-2"
    [ngClass]="{
        'bg-malou-color-background-light p-3': theme() === AiGenerationActionsDisplayStyle.FILLED,
        'p-5': theme() === AiGenerationActionsDisplayStyle.OUTLINED,
        '!cursor-not-allowed': disabled(),
    }">
    @if (theme() === AiGenerationActionsDisplayStyle.FILLED) {
        <button
            class="malou-btn-raised--secondary btn-sm !h-[30px] !rounded !bg-malou-color-background-white !px-2.5 !py-2 !text-[10px] !font-medium !text-malou-color-chart-purple--accent"
            mat-raised-button
            [disabled]="disabled()"
            (click)="wandIconClick()">
            <mat-icon class="!w-4 cursor-pointer text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
            {{ 'common.retry' | translate }}
        </button>
    } @else {
        <mat-icon
            class="!w-4 cursor-pointer text-malou-color-chart-purple--accent"
            [svgIcon]="SvgIcon.MAGIC_WAND"
            (click)="wandIconClick()"></mat-icon>
    }

    <button
        class="malou-btn-raised--secondary btn-sm !h-auto !rounded !px-2.5 !py-2 !text-[10px] !font-medium !text-malou-color-chart-purple--accent"
        data-testid="ai-generation-optimize-btn"
        mat-raised-button
        [ngClass]="{ '!bg-malou-color-background-white': theme() === AiGenerationActionsDisplayStyle.FILLED }"
        [id]="optimizeButtonId()"
        [disabled]="disabled()"
        (click)="optimize()">
        {{ 'social_posts.new_social_post.optimize' | translate }}
    </button>
    <button
        class="malou-btn-raised--secondary btn-sm !h-auto !rounded !px-2.5 !py-2 !text-[10px] !font-medium !text-malou-color-chart-purple--accent"
        data-testid="ai-generation-translate-btn"
        mat-raised-button
        [ngClass]="{ '!bg-malou-color-background-white': theme() === AiGenerationActionsDisplayStyle.FILLED }"
        [disabled]="disabled()"
        [matMenuTriggerFor]="languagesMenu">
        <div class="flex items-center gap-x-2">
            <span>{{ 'common.translate' | translate }}</span>
            <mat-icon class="!h-3 !w-3 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
        </div>
    </button>
    <button
        class="malou-btn-icon--secondary btn-sm !bg-malou-color-background-light text-malou-color-chart-purple--accent hover:!bg-white"
        data-testid="ai-generation-go-back-btn"
        mat-icon-button
        [ngClass]="{ '!bg-malou-color-background-white': theme() === AiGenerationActionsDisplayStyle.FILLED }"
        [disabled]="currentInteractionIndex() === 0 || disabled()"
        (click)="toPreviousInteraction()">
        <mat-icon class="!w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.ARROW_PREVIOUS"></mat-icon>
    </button>
    <button
        class="malou-btn-icon--secondary btn-sm !bg-malou-color-background-light text-malou-color-chart-purple--accent hover:!bg-white"
        data-testid="ai-generation-go-next-btn"
        mat-icon-button
        [ngClass]="{ '!bg-malou-color-background-white': theme() === AiGenerationActionsDisplayStyle.FILLED }"
        [disabled]="(currentInteractionIndex() ?? 0) >= totalInteractions() - 1 || disabled()"
        (click)="toNextInteraction()">
        <mat-icon class="!w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.ARROW_NEXT"></mat-icon>
    </button>
</div>

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-[100px] !max-w-none !rounded-[10px]" #languagesMenu="matMenu">
    <app-input-text
        class="mx-2 mt-2 block h-[50px] w-[180px]"
        [formControl]="languageFormControl"
        [showConfirmIcon]="true"
        [placeholder]="'common.language' | translate"
        [min]="3"
        [confirmationIconId]="trackerId()"
        (confirmControlValue)="translate(languageFormControl.value)"
        (click)="$event.stopPropagation()">
    </app-input-text>

    @for (lang of availableTranslations; track $index) {
        <div mat-menu-item [disabled]="disabled()" (click)="translate(lang)">
            <button
                class="flex w-full items-center gap-x-2"
                [id]="translateButtonIdPrefix() + lang + '_language_with_ai_button'"
                [attr.data-testid]="'lang-' + lang">
                <img class="h-4 w-4" [src]="lang | flagPathResolver" />
                <span class="malou-text-12--medium text-malou-color-text-1">{{ langDisplayWith | applyPure: lang }}</span>
            </button>
        </div>
    }
</mat-menu>
