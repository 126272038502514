import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Inject, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { ToastService } from ':core/services/toast.service';
import { FacebookPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/facebook-preview/facebook-preview.component';
import { FacebookReelPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/facebook-reel-preview/facebook-reel-preview.component';
import { InstagramPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/instagram-preview/instagram-preview.component';
import { InstagramReelPreviewComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/instagram-reel-preview/instagram-reel-preview.component';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export interface TransformToReelModalProps {
    post: IUpsertSocialPost;
    restaurantId: string;
    platformKey: PlatformKey.INSTAGRAM | PlatformKey.FACEBOOK;
}

export interface TransformToReelModalResult {
    newPost: UpsertSocialPost | undefined;
}

@Component({
    selector: 'app-transform-to-reel-modal',
    templateUrl: './transform-to-reel-modal.component.html',
    styleUrls: ['./transform-to-reel-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        ButtonComponent,
        FacebookPreviewComponent,
        FacebookReelPreviewComponent,
        InstagramPreviewComponent,
        InstagramReelPreviewComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransformToReelModalComponent implements AfterViewInit {
    readonly post: IUpsertSocialPost;
    readonly selectedHashtagsText: string[];
    readonly profilePictureUrl = signal<string | undefined>(undefined);
    readonly username = signal<string>('');
    readonly platformKey: PlatformKey.INSTAGRAM | PlatformKey.FACEBOOK;
    private readonly _restaurantId: string;

    readonly isSubmitting = signal<boolean>(false);

    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;

    private readonly _wrapper = viewChild.required<ElementRef<HTMLDivElement>>('wrapper');
    private readonly _mainContainer = viewChild.required<ElementRef<HTMLDivElement>>('mainContainer');
    private readonly _postContainer = viewChild.required<ElementRef<HTMLDivElement>>('postContainer');
    private readonly _reelContainer = viewChild.required<ElementRef<HTMLDivElement>>('reelContainer');
    private readonly _arrowContainer = viewChild.required<ElementRef<HTMLDivElement>>('arrowContainer');

    private readonly _platformsService = inject(PlatformsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    constructor(
        private readonly _dialogRef: MatDialogRef<TransformToReelModalComponent, TransformToReelModalResult>,
        @Inject(MAT_DIALOG_DATA)
        data: TransformToReelModalProps
    ) {
        this.post = data.post;
        this.selectedHashtagsText = data.post.hashtags.selected.map((postHashtag) => postHashtag.text);
        this._restaurantId = data.restaurantId;
        this.platformKey = data.platformKey;

        this._initUsernameAndProfilePicture();
    }

    ngAfterViewInit(): void {
        // We zoom out the previews with scale(), so we need to adjust the height of the container
        // to avoid the overflow of the containers
        const postContainerHeight = this._postContainer().nativeElement.offsetHeight;
        const reelContainerHeight = this._reelContainer().nativeElement.offsetHeight;
        const maxHeight = Math.max(postContainerHeight, reelContainerHeight) / 2;

        this._wrapper().nativeElement.style.height = `${maxHeight}px`;

        this._mainContainer().nativeElement.style.height = `${maxHeight}px`;
        this._mainContainer().nativeElement.style.transform = 'translateY(50%)';

        this._arrowContainer().nativeElement.style.transform = `translateY(-${maxHeight / 2}px)`;
        this._postContainer().nativeElement.style.transform = `translateY(-${maxHeight}px)`;
        this._reelContainer().nativeElement.style.transform = `translateY(-${maxHeight}px)`;
    }

    close(newPost: UpsertSocialPost | undefined = undefined): void {
        this._dialogRef.close({ newPost });
    }

    transformToReel(): void {
        this.isSubmitting.set(true);
        this._socialPostsService.transformToReel$(this.post.id).subscribe({
            next: (result) => {
                this.isSubmitting.set(false);
                const newPost = UpsertSocialPost.fromDto(result.data);
                this.close(newPost);
            },
            error: () => {
                this.isSubmitting.set(false);
                this._toastService.openErrorToast(this._translateService.instant('social_posts.transform_to_reel_modal.error'));
            },
        });
    }

    private _initUsernameAndProfilePicture(): void {
        this._platformsService
            .getProfilePictureUrl(this._restaurantId, this.platformKey)
            .pipe(
                catchError((error) => {
                    console.error('Error fetching profile picture URL', { platformKey: this.platformKey, error });
                    return of({ data: { profilePictureUrl: undefined } });
                })
            )
            .subscribe((response) => {
                this.profilePictureUrl.set(response.data.profilePictureUrl);
            });

        this._platformsService.getPlatformSocialLink(this._restaurantId, this.platformKey).subscribe((response) => {
            this.username.set(response.data?.name ?? '');
        });
    }
}
