import { z } from 'zod';

import { MalouComparisonPeriod, PlatformKey } from '@malou-io/package-utils';

import { AddressDto } from '../restaurant';
import { objectIdValidator } from '../utils/validators';

export const getReviewsEvolutionTotalBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    startDate: z.string().datetime(),
    endDate: z.string().datetime(),
    previousPeriod: z.boolean(),
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
});

export type GetReviewsEvolutionTotalBodyDto = z.infer<typeof getReviewsEvolutionTotalBodyValidator>;

export type GetReviewsEvolutionTotalResponseBodyDto = {
    restaurant: {
        _id: string;
        name: string;
        address: AddressDto | null | undefined;
        internalName?: string;
    };
    averageRating?: number;
    total?: number;
    reviews: {
        key: string;
        socialId: string;
        rating: number;
    }[];
}[];

// ----------------------------------------------------------------

export const getChartDataParamsValidator = z
    .object({
        restaurant_id: z.string(),
    })
    .transform((data) => {
        return { restaurantId: data.restaurant_id };
    });

export type GetChartDataParamsDto = z.infer<typeof getChartDataParamsValidator>;

// ----------------------------------------------------------------

export const getChartRestaurantsReviewsTotalQueryValidator = z
    .object({
        start_date: z.string(),
        end_date: z.string(),
        platforms: z.union([z.nativeEnum(PlatformKey).transform((key) => [key]), z.array(z.nativeEnum(PlatformKey))]),
        comparison_period: z.nativeEnum(MalouComparisonPeriod).optional(),
    })
    .transform((data) => ({
        startDate: new Date(data.start_date),
        endDate: new Date(data.end_date),
        platforms: data.platforms,
        comparisonPeriod: data.comparison_period,
    }));

export type GetChartRestaurantsReviewsTotalQueryDto = z.infer<typeof getChartRestaurantsReviewsTotalQueryValidator>;

// ----------------------------------------------------------------
