import { ReelThumbnail, SocialPostDto } from '@malou-io/package-dto';
import {
    PlatformKey,
    PostErrorData,
    PostFeedbacks,
    PostPublicationStatus,
    PostType,
    PostUserTag,
    RemoveMethodsFromClass,
    SocialPostCallToAction,
    TiktokPrivacyStatus,
} from '@malou-io/package-utils';

import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SocialPostAuthor } from ':modules/posts-v2/social-posts/models/social-post-author';
import { FbLocation, Hashtag, PostHashtags } from ':shared/models';

export type IUpsertSocialPost = RemoveMethodsFromClass<UpsertSocialPost> & { id: string };

export class UpsertSocialPost implements IUpsertSocialPost {
    id: string;
    title: string;
    text: string;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    isPublishing: boolean;
    postType: PostType;
    plannedPublicationDate: Date;
    attachments: EditionMedia[];
    hashtags: PostHashtags;
    location: FbLocation | null;
    callToAction?: SocialPostCallToAction;
    feedbacks: PostFeedbacks | null;
    error?: PostErrorData;
    socialLink?: string;
    socialCreatedAt?: Date;
    duplicateToPlatforms: PlatformKey[];
    author?: SocialPostAuthor;
    userTagsList: (PostUserTag[] | null)[];
    bindingId?: string;
    tiktokOptions: {
        privacyStatus: TiktokPrivacyStatus;
        interactionAbility: {
            comment: boolean;
            duet: boolean;
            stitch: boolean;
        };
        contentDisclosureSettings: {
            isActivated: boolean;
            yourBrand: boolean;
            brandedContent: boolean;
        };
    };
    reelThumbnail: ReelThumbnail | undefined;

    constructor(data: IUpsertSocialPost) {
        this.id = data.id;
        this.title = data.title;
        this.text = data.text;
        this.platformKeys = data.platformKeys;
        this.published = data.published;
        this.isPublishing = data.isPublishing;
        this.postType = data.postType;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.attachments = data.attachments;
        this.hashtags = data.hashtags;
        this.location = data.location;
        this.callToAction = data.callToAction;
        this.feedbacks = data.feedbacks;
        this.error = data.error;
        this.socialLink = data.socialLink;
        this.socialCreatedAt = data.socialCreatedAt;
        this.duplicateToPlatforms = data.duplicateToPlatforms;
        this.author = data.author;
        this.userTagsList = data.userTagsList;
        this.bindingId = data.bindingId;
        this.tiktokOptions = data.tiktokOptions;
        this.reelThumbnail = data.reelThumbnail;
    }

    static fromDto(dto: SocialPostDto): UpsertSocialPost {
        return new UpsertSocialPost({
            id: dto.id,
            title: dto.title ?? '',
            text: dto.text,
            platformKeys: dto.platformKeys,
            published: dto.published,
            isPublishing: dto.isPublishing,
            postType: dto.postType,
            plannedPublicationDate: new Date(dto.plannedPublicationDate),
            attachments: dto.attachments,
            hashtags: {
                selected: dto.hashtags?.selected.map((hashtag) => new Hashtag(hashtag)) ?? [],
                suggested: dto.hashtags?.suggested.map((hashtag) => new Hashtag(hashtag)) ?? [],
            },
            location: dto.location ?? null,
            callToAction: dto.callToAction
                ? {
                      actionType: dto.callToAction.actionType,
                      url: dto.callToAction.url,
                  }
                : undefined,
            feedbacks: dto.feedbacks
                ? {
                      ...dto.feedbacks,
                      createdAt: new Date(dto.feedbacks.createdAt),
                      updatedAt: new Date(dto.feedbacks.updatedAt),
                      feedbackMessages: dto.feedbacks.feedbackMessages.map((message) => ({
                          ...message,
                          author: {
                              ...message.author,
                              userId: message.author.userId ?? undefined,
                              profilePictureUrl: message.author.profilePictureUrl ?? undefined,
                          },
                          createdAt: new Date(message.createdAt),
                          updatedAt: new Date(message.updatedAt),
                      })),
                      participants: dto.feedbacks.participants.map((participant) => ({
                          ...participant,
                          participant: {
                              ...participant.participant,
                              role: participant.participant.role ?? undefined,
                              userId: participant.participant.userId ?? undefined,
                              lastname: participant.participant.lastname ?? undefined,
                          },
                      })),
                  }
                : null,
            error: dto.error ?? undefined,
            socialLink: dto.socialLink ?? undefined,
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
            duplicateToPlatforms: [],
            author: dto.author ? SocialPostAuthor.fromDto(dto.author) : undefined,
            userTagsList: dto.userTagsList,
            bindingId: dto.bindingId,
            tiktokOptions: {
                privacyStatus: dto.tiktokOptions.privacyStatus ?? TiktokPrivacyStatus.SELF_ONLY,
                interactionAbility: {
                    comment: dto.tiktokOptions.interactionAbility.comment ?? false,
                    duet: dto.tiktokOptions.interactionAbility.duet ?? false,
                    stitch: dto.tiktokOptions.interactionAbility.stitch ?? false,
                },
                contentDisclosureSettings: dto.tiktokOptions?.contentDisclosureSettings ?? {
                    isActivated: false,
                    yourBrand: false,
                    brandedContent: false,
                },
            },
            reelThumbnail: dto.reelThumbnail,
        });
    }

    static create(options?: { isReel?: boolean }): UpsertSocialPost {
        return new UpsertSocialPost({
            id: '',
            title: '',
            text: '',
            platformKeys: [],
            published: PostPublicationStatus.DRAFT,
            isPublishing: false,
            postType: options?.isReel ? PostType.REEL : PostType.CAROUSEL,
            plannedPublicationDate: new Date(),
            attachments: [],
            hashtags: { selected: [], suggested: [] },
            location: null,
            feedbacks: null,
            duplicateToPlatforms: [],
            userTagsList: [],
            tiktokOptions: {
                privacyStatus: TiktokPrivacyStatus.SELF_ONLY,
                interactionAbility: {
                    comment: false,
                    duet: false,
                    stitch: false,
                },
                contentDisclosureSettings: {
                    isActivated: false,
                    yourBrand: false,
                    brandedContent: false,
                },
            },
            reelThumbnail: undefined,
        });
    }

    toInterface(): IUpsertSocialPost {
        return {
            id: this.id,
            title: this.title,
            text: this.text,
            platformKeys: this.platformKeys,
            published: this.published,
            isPublishing: this.isPublishing,
            postType: this.postType,
            plannedPublicationDate: this.plannedPublicationDate,
            attachments: this.attachments,
            hashtags: this.hashtags,
            location: this.location,
            callToAction: this.callToAction,
            feedbacks: this.feedbacks,
            error: this.error,
            socialLink: this.socialLink,
            socialCreatedAt: this.socialCreatedAt,
            duplicateToPlatforms: this.duplicateToPlatforms,
            author: this.author,
            userTagsList: this.userTagsList,
            bindingId: this.bindingId,
            tiktokOptions: this.tiktokOptions,
            reelThumbnail: this.reelThumbnail,
        };
    }

    isEmpty(): boolean {
        return (
            this.published === PostPublicationStatus.DRAFT &&
            !this.text &&
            !this.title &&
            !this.attachments.length &&
            !this.hashtags.selected.length &&
            !this.callToAction &&
            (!this.feedbacks || this.feedbacks.feedbackMessages.length === 0) &&
            !this.reelThumbnail
        );
    }
}
