<div class="transition delay-300 ease-in-out">
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'information.disconnected_platforms_modal.title' | translate }}
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <div class="malou-text-12--regular text-malou-color-text-2">
                @switch (data.type) {
                    @case (DisconnectedPlatformsModalType.INFORMATION) {
                        {{ 'information.disconnected_platforms_modal.description' | translate }}
                    }
                    @case (DisconnectedPlatformsModalType.SOCIAL) {
                        {{ 'information.disconnected_platforms_modal.description_social' | translate }}
                    }
                }
            </div>

            <div class="malou-text-12--bold mb-4 mt-8 text-malou-color-text-1">
                {{ 'information.disconnected_platforms_modal.platforms_to_reconnect' | translate }}
            </div>

            <div class="grid grid-cols-2 gap-3">
                @for (platform of sortedPlatforms; track platform.id) {
                    <div class="flex !min-w-[34%] flex-1 items-center gap-x-3 rounded-[10px] border border-malou-color-border-primary p-2">
                        <app-platform-logo imgClasses="w-12 h-12" [logo]="platform.key"></app-platform-logo>
                        <div class="malou-text-12--bold text-malou-color-text-1">
                            {{ platform.key | enumTranslate: 'platform_key' }}
                        </div>
                    </div>
                }
            </div>

            <div class="malou-text-12--semibold mt-6 text-malou-color-text-2">
                {{ 'information.disconnected_platforms_modal.delete_connection' | translate }}
            </div>
        </div>

        <div class="malou-dialog__footer">
            <div class="flex gap-x-3 md:justify-between">
                <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
                    {{ 'common.later' | translate }}
                </button>
                <app-button
                    class="md:grow"
                    buttonClasses="!h-11 md:w-full"
                    [text]="'information.disconnected_platforms_modal.connect_my_platforms' | translate"
                    (buttonClick)="goToPlatformsConnection()"></app-button>
            </div>
        </div>
    </div>
</div>
