<div class="relative h-full w-full">
    <div
        class="h-full w-full bg-malou-color-background-dark"
        matTooltipClass="multiline-tooltip"
        [ngClass]="{ 'cursor-pointer': !!link() && !isReadonly() }"
        [matTooltip]="tooltipText()"
        [matTooltipDisabled]="!tooltipText()"
        (click)="!isReadonly() && openLink()">
        @if (media(); as media) {
            @switch (media.type) {
                @case (MediaType.PHOTO) {
                    <app-image-viewer
                        [data]="{
                            thumbnailUrl: media.thumbnailUrl ?? media.url,
                            dimensions: media.thumbnailDimensions,
                            transformData: media.transformData,
                        }"
                        (errorOnImage)="refresh($event)"></app-image-viewer>
                }
                @case (MediaType.VIDEO) {
                    <ng-container [ngTemplateOutlet]="videoMediaTemplate" [ngTemplateOutletContext]="{ media }"></ng-container>
                }
            }
        } @else {
            <div class="flex h-full w-full items-center justify-center">
                <mat-icon class="!h-[50px] !w-[50px] text-white" [svgIcon]="SvgIcon.IMAGES"></mat-icon>
            </div>
        }
    </div>
    @if (icon(); as icon) {
        <div class="absolute right-2 top-2 flex h-fit w-fit rounded-sm">
            <mat-icon class="!h-3 !w-3 !fill-white" [ngClass]="customIconClass()" [svgIcon]="icon"> </mat-icon>
        </div>
    }

    @if (status(); as status) {
        <div
            class="malou-text-10--semibold absolute top-2 flex h-fit w-fit items-center justify-center rounded-sm px-2 py-1 text-white opacity-60"
            [ngClass]="statusIconContainerClass()">
            {{ status | enumTranslate: 'publication_status' }}
        </div>
    }

    @if (tag(); as tag) {
        <div class="absolute bottom-2 left-0 flex w-full justify-center px-2">
            <div
                class="malou-text-9--semibold flex w-full items-center justify-center rounded-[3px] bg-malou-color-primary px-2 py-1 text-white"
                [ngClass]="{ 'cursor-pointer': !isReadonly() }"
                (click)="!isReadonly() && onTagClick()">
                {{ tag }}
            </div>
        </div>
    }
</div>

<ng-template let-media="media" #videoMediaTemplate>
    @if (media.thumbnailUrl) {
        <app-image-viewer
            [data]="{
                thumbnailUrl: media.thumbnailUrl,
                dimensions: media.thumbnailDimensions,
                transformData: media.transformData,
            }"
            (errorOnImage)="refresh($event)"></app-image-viewer>
    } @else if (media.url) {
        <video class="h-full w-full !touch-none object-cover">
            <source type="video/mp4" [src]="media.url" (error)="refresh($event)" />
        </video>
    } @else {
        <!-- should never happen -->
    }
</ng-template>
