import { PlatformKey, TimeInMilliseconds } from '@malou-io/package-utils';

export const platformsUpdateConfig = [
    {
        key: PlatformKey.GMB,
        delayInMilliseconds: 0,
        delayLabelName: 'right_now',
    },
    {
        key: PlatformKey.FACEBOOK,
        delayInMilliseconds: 0,
        delayLabelName: 'right_now',
    },
    {
        key: PlatformKey.MAPSTR,
        delayInMilliseconds: 0,
        delayLabelName: 'right_now',
    },
    {
        key: PlatformKey.LAFOURCHETTE,
        delayInMilliseconds: 3 * TimeInMilliseconds.DAY,
        delayLabelName: 'up_to_three_days',
    },
    {
        key: PlatformKey.TRIPADVISOR,
        delayInMilliseconds: TimeInMilliseconds.WEEK,
        delayLabelName: 'up_to_one_week',
    },
    {
        key: PlatformKey.YELP,
        delayInMilliseconds: 3 * TimeInMilliseconds.WEEK,
        delayLabelName: 'up_to_three_weeks',
    },
    {
        key: PlatformKey.FOURSQUARE,
        delayInMilliseconds: 30 * TimeInMilliseconds.DAY,
        delayLabelName: 'up_to_a_month',
    },
    {
        key: PlatformKey.RESY,
        delayInMilliseconds: TimeInMilliseconds.WEEK,
        delayLabelName: 'up_to_one_week',
    },
];
