<div class="relative flex overflow-hidden rounded-[10px] font-[Helvetica] text-white">
    @if (media(); as media) {
        <app-image-viewer
            class="aspect-9/16 min-w-full"
            [data]="{
                thumbnailUrl: media.thumbnail1024OutsideUrl,
                dimensions: media.thumbnail1024OutsideDimensions,
                transformData: media.transformData,
                videoOptions:
                    media.type === MediaType.VIDEO
                        ? {
                              videoUrl: media.videoUrl,
                              videoDimensions: media.videoDimensions,
                          }
                        : undefined,
            }">
        </app-image-viewer>
    } @else {
        <div class="flex aspect-9/16 items-center justify-center bg-malou-color-background-dark">
            <img class="w-full" [src]="'default_post' | imagePathResolver" />
        </div>
    }
    <!-- HEADER -->
    <div class="malou-text-11--semibold absolute left-4 top-3">
        <img
            class="h-5 w-5"
            style="filter: brightness(0) invert(1)"
            [src]="'previous' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'svg' }" />
    </div>
    <div class="absolute right-4 top-3 flex items-center gap-x-4">
        <mat-icon class="!h-6 !w-6" color="white" [svgIcon]="SvgIcon.CAMERA"></mat-icon>
        <img
            class="h-5 w-5"
            style="filter: brightness(0) invert(1)"
            [src]="'search' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'svg' }" />
        <img
            class="h-5 w-5"
            style="filter: brightness(0) invert(1)"
            [src]="'people' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'svg' }" />
    </div>

    <!-- FOOTER -->
    <div class="absolute bottom-5 left-4 flex flex-col gap-3">
        <div class="flex items-center gap-2">
            @if (profilePicture(); as profilePicture) {
                <img class="h-[25px] w-[25px] rounded-full" [src]="profilePicture" />
            }
            <div class="text-[13px] font-bold text-white">{{ username() }}</div>
            <div class="rounded-[6px] border border-white px-[10px] text-[12px] font-medium leading-tight">
                <div class="mt-[2px]">
                    {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook_reel.follow' | translate }}
                </div>
            </div>
        </div>

        @if (formattedText(); as formattedText) {
            <div
                class="max-w-[250px] overflow-hidden overflow-ellipsis whitespace-nowrap text-[12px] font-normal"
                [innerHTML]="formattedText | htmlTag"></div>
        }
    </div>

    <!-- RIGHT SIDEBAR ACTIONS -->
    <div class="malou-text-8--regular absolute bottom-5 right-4 flex flex-col items-center justify-center gap-3">
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                class="h-6 w-6"
                style="filter: brightness(0) invert(1)"
                [src]="'like' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
            <div>2,7M</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                class="h-6 w-6"
                style="filter: brightness(0) invert(1)"
                [src]="'comment' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
            <div>9 868</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                class="h-6 w-6"
                style="filter: brightness(0) invert(1)"
                [src]="'share' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
            <div>983 K</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                class="h-6 w-6"
                style="filter: brightness(0) invert(1)"
                [src]="'whatsapp' | imagePathResolver: { folder: 'social-post-previews/facebook', extensionFormat: 'png' }" />
            <div>
                {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.facebook_reel.send' | translate }}
            </div>
        </div>
        <div class="flex items-center justify-center">
            <mat-icon class="!h-4 !w-4 rotate-90" color="white" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
        </div>
    </div>
</div>
