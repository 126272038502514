import { NgClass, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    input,
    OnDestroy,
    OnInit,
    signal,
    viewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, debounceTime, filter, of, switchMap } from 'rxjs';

import { IGAccount, MediaType, PlatformKey, PostUserTag, PublicationType, TransformDataComputerService } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import {
    TAG_ACCOUNT_HEIGHT,
    TAG_ACCOUNT_WIDTH,
    TagAccountV2Component,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/instagram-preview/tag-account/tag-account.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-instagram-preview',
    templateUrl: './instagram-preview.component.html',
    styleUrls: ['./instagram-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        MatIconModule,
        TranslateModule,
        ImageViewerComponent,
        TagAccountV2Component,
        ApplyPurePipe,
        HtmlTagPipe,
        ImagePathResolverPipe,
    ],
})
export class InstagramPreviewComponent implements AfterViewInit, OnInit, OnDestroy {
    readonly readonly = input<boolean>(false);
    readonly text = input<string>('');
    readonly medias = input<EditionMedia[]>([]);
    readonly username = input<string>('');
    readonly profilePicture = input<string | undefined>();
    readonly hashtags = input<string[]>([]);

    readonly slider = viewChild<HTMLDivElement>('slider');
    readonly sliderContainer = viewChild.required<ElementRef<HTMLDivElement>>('sliderContainer');

    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _postsService = inject(PostsService);

    readonly usernameWithoutAt = computed(() => this.username().replace('@', ''));

    readonly currentMediaIndex = signal(0);

    readonly aspectRatioStyle = computed(() => {
        const medias = this.medias();
        const index = medias.length - 1;

        if (index === -1 || !medias[index]) {
            return { aspectRatio: 1 };
        }

        const aspectRatioFromTransformData = TransformDataComputerService.getAspectRatioNumberFor(
            PublicationType.POST,
            medias[index].transformData?.aspectRatio
        );
        return { aspectRatio: aspectRatioFromTransformData ?? medias[index].aspectRatio ?? 1 };
    });

    readonly formattedText = computed(() => this._formatText(this.text(), this.hashtags()));

    readonly isCarousel = computed(() => this.medias().length > 1);

    readonly addTagAccount = signal(false);
    readonly tagControl = new FormControl<string | null>(null);
    readonly foundAccount = signal<IGAccount | undefined>(undefined);
    readonly searching = signal(false);
    readonly addTagAccountPosition = signal<{ top: string; left: string }>({ top: '0px', left: '0px' });
    readonly tagPosition = signal<{ top: number; left: number }>({ top: 0, left: 0 });
    readonly showArrowAbove = signal(true);
    readonly userTagsHistory = this._upsertSocialPostContext.upsertSocialPostState.userTagsHistory;

    private readonly _igPlatformId = computed((): string | null => {
        const connectedPlatforms = this._upsertSocialPostContext.upsertSocialPostState.connectedSocialPlatforms();
        const instagramPlatform = connectedPlatforms.find((platform) => platform.key === PlatformKey.INSTAGRAM);
        return instagramPlatform?._id ?? null;
    });

    readonly userTagsList = this._upsertSocialPostContext.upsertSocialPostState.post.userTagsList;
    readonly userTags = computed(() => {
        const userTagsList = this.userTagsList();
        const currentMediaIndex = this.currentMediaIndex();
        return userTagsList[currentMediaIndex] ?? [];
    });

    readonly isCurrentMediaAVideo = computed(() => {
        const medias = this.medias();
        const index = this.currentMediaIndex();
        return medias[index]?.type === MediaType.VIDEO;
    });

    readonly SvgIcon = SvgIcon;
    readonly MediaType = MediaType;
    readonly IMG_ID_PREFIX = 'instagram-preview-img-';

    readonly metaGraphApiBugIsResolved = false; // Current bug in Meta Graph API https://developers.facebook.com/support/bugs/1187206212645170/
    // The user tags are not supported for video containers in carousel but it should be. In the Instagram UI it is supported.
    // Once the bug is fixed, delete metaGraphApiBugIsResolved.

    constructor() {
        effect(
            () => {
                const medias = this.medias();
                this.currentMediaIndex.update((index) => Math.max(Math.min(index, medias.length - 1), 0));
                this.closeAddTagAccount();
                this._scrollToCurrentMedia();
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this.tagControl.valueChanges
            .pipe(
                filter((text) => {
                    this.searching.set(true);
                    this.foundAccount.set(undefined);
                    const isText = !!text && typeof text === 'string';
                    if (!isText) {
                        this.searching.set(false);
                        return false;
                    }
                    return true;
                }),
                debounceTime(400),
                switchMap((text) => {
                    const igPlatformId = this._igPlatformId();
                    if (!text || !igPlatformId) {
                        return of(null);
                    }
                    return this._postsService.igSearch(text, igPlatformId).pipe(catchError(() => of({ data: null })));
                })
            )
            .subscribe({
                next: (result) => {
                    const account = result?.data?.business_discovery;
                    this.searching.set(false);
                    this.foundAccount.set(account);
                },
                error: () => {
                    this.searching.set(false);
                    this.foundAccount.set(undefined);
                },
            });
    }

    ngAfterViewInit(): void {
        this._scrollToCurrentMedia('instant');

        document.addEventListener('mousemove', this.moveTooltip);
    }

    ngOnDestroy(): void {
        document.removeEventListener('mousemove', this.moveTooltip);
    }

    moveTooltip = (event: MouseEvent): void => {
        const sliderContainer = this.sliderContainer().nativeElement;
        const tooltip = document.getElementById('userTagTooltip');
        if (tooltip && sliderContainer) {
            const sliderContainerRect = sliderContainer.getBoundingClientRect();
            tooltip.style.left = `${event.clientX - sliderContainerRect.x}px`;
            tooltip.style.top = `${event.clientY - sliderContainerRect.y}px`;
        }
    };

    openAddTagAccount(event: MouseEvent): void {
        const sliderContainer = this.sliderContainer().nativeElement;
        const sliderContainerRect = sliderContainer.getBoundingClientRect();

        const topForUserTagInVideo = sliderContainer.clientHeight - 58; // 58 is the position of the tag toggle button
        const leftForUserTagInVideo = 36; // 36 is the position of the tag toggle button
        const { top, left } = this.isCurrentMediaAVideo()
            ? { top: topForUserTagInVideo, left: leftForUserTagInVideo }
            : { top: event.clientY - sliderContainerRect.y, left: event.clientX - sliderContainerRect.x };
        this.tagPosition.set({ top, left });
        const TOP_OFFSET = 7;
        const LEFT_OFFSET = 20;

        const MAX_TOLERATED_OVERFLOW = 15;
        const spaceBetweenClickAndRightSideOfSliderContainer = sliderContainer.clientWidth - left;
        const toleratedOverFlow = Math.max(MAX_TOLERATED_OVERFLOW - spaceBetweenClickAndRightSideOfSliderContainer, 0);
        const leftWithOffset = Math.min(left - LEFT_OFFSET, sliderContainer.clientWidth - TAG_ACCOUNT_WIDTH + toleratedOverFlow);

        let showArrowAbove = true;

        let topWithOffset = top + TOP_OFFSET;
        if (topWithOffset + TAG_ACCOUNT_HEIGHT > sliderContainer.clientHeight) {
            topWithOffset = top - TOP_OFFSET - TAG_ACCOUNT_HEIGHT;
            showArrowAbove = false;
        }

        document.addEventListener('keyup', (ev) => this._hideTagContainerOnEscape(ev));

        this.showArrowAbove.set(showArrowAbove);
        this.addTagAccountPosition.set({ top: `${topWithOffset}px`, left: `${leftWithOffset}px` });
        this.tagControl.setValue('');
        this.addTagAccount.set(true);
    }

    closeAddTagAccount(): void {
        this.addTagAccount.set(false);
    }

    addAccount(event: MatAutocompleteSelectedEvent): void {
        const username = event.option.value.username;
        const sliderContainer = this.sliderContainer().nativeElement;
        const sliderContainerRect = sliderContainer.getBoundingClientRect();

        // We save the ratio of the position of the tag in the image.
        // For videos there is no position for the user tag.
        const isCurrentMediaAVideo = this.isCurrentMediaAVideo();
        const x = isCurrentMediaAVideo ? 0 : this.tagPosition().left / sliderContainerRect.width;
        const y = isCurrentMediaAVideo ? 0 : this.tagPosition().top / sliderContainerRect.height;

        const userTagsList = this.userTagsList();
        const currentMediaIndex = this.currentMediaIndex();

        if (username.length && !userTagsList[currentMediaIndex]?.some((el) => el.username === username)) {
            this._upsertSocialPostContext.addUserTag(currentMediaIndex, { x, y, username });
        }
        this.tagControl.setValue('');
        this.addTagAccount.set(false);
        document.removeEventListener('keyup', (ev) => this._hideTagContainerOnEscape(ev));
    }

    removeAccount(username: string): void {
        this._upsertSocialPostContext.removeUserTag(this.currentMediaIndex(), username);
    }

    nextMedia(): void {
        this.closeAddTagAccount();
        this.currentMediaIndex.update((currentValue) => currentValue + 1);
        this._scrollToCurrentMedia();
    }

    previousMedia(): void {
        this.closeAddTagAccount();
        this.currentMediaIndex.update((currentValue) => currentValue - 1);
        this._scrollToCurrentMedia();
    }

    userTagIsOnTheLeftSide = (userTag: PostUserTag): boolean => userTag.x < 0.5;

    getUserTagPositionInPx = (userTag: PostUserTag): { top: string; left: string } => {
        const sliderContainer = this.sliderContainer().nativeElement;
        const sliderContainerRect = sliderContainer.getBoundingClientRect();

        const top = userTag.y * sliderContainerRect.height;
        const left = userTag.x * sliderContainerRect.width;

        return { top: `${top}px`, left: `${left}px` };
    };

    private _scrollToCurrentMedia(behavior?: ScrollBehavior): void {
        const currentMediaIndex = this.currentMediaIndex();
        const mediaElement = document.getElementById(`${this.IMG_ID_PREFIX}${currentMediaIndex}`);
        if (mediaElement) {
            mediaElement.scrollIntoView({ behavior: behavior ?? 'smooth', block: 'nearest', inline: 'center' });
        }
    }

    private _formatText(text: string, hashtags: string[]): string {
        const textWithLineBreaks = text.replace(/\n/g, ' <br /> ');
        const textWords = textWithLineBreaks.split(' ');

        const instagramMentionsAndHashtagsColor = '#3769A9';
        const textWithAndMentions = textWords
            .map((word) =>
                word.startsWith('@') && word.length > 1 ? `<span style="color: ${instagramMentionsAndHashtagsColor}">${word}</span>` : word
            )
            .join(' ');

        if (hashtags.length === 0) {
            return textWithAndMentions;
        }

        const hashtagsText = hashtags.join(' ');
        const hashtagsWithColor = `<span style="color: ${instagramMentionsAndHashtagsColor};">${hashtagsText}</span>`;
        return `${textWithAndMentions}<br><br>${hashtagsWithColor}`;
    }

    private _hideTagContainerOnEscape(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.addTagAccount.set(false);
        }
    }
}
