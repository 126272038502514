import { NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    contentChild,
    DestroyRef,
    effect,
    ElementRef,
    forwardRef,
    inject,
    input,
    Input,
    model,
    NgZone,
    OnDestroy,
    OnInit,
    output,
    signal,
    TemplateRef,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { HideOverflowingChildrenDirective } from '../../directives/hide-overflowing-children.directive';
import { NoopMatCheckboxComponent } from '../noop-mat-checkbox/noop-mat-checkbox.component';

export enum SelectBaseDisplayStyle {
    DEFAULT = 'default',
    WITH_BACKGROUND = 'with-background',
}

const DEFAULT_CLOSE_TIMEOUT_MS = 100;

/**
 * This component is very, very generic and customizable,
 * prefer to include it in others, also generic but more precise components
 * and not directly use it in pages.
 */
@Component({
    selector: 'app-select-base',
    templateUrl: 'select-base.component.html',
    styleUrls: ['./select-base.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectBaseComponent),
        },
    ],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatOptionModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        NoopMatCheckboxComponent,
        HideOverflowingChildrenDirective,
        ApplyPurePipe,
        SlicePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
    // ------------ CORE ------------//
    /** Title */
    readonly title = input<string | undefined>();

    /** Subtitle */
    readonly subtitle = input<string | undefined>();

    /** Placeholder */
    readonly placeholder = input<string>('');

    /** If true, will display an asterisk after the title */
    readonly required = input<boolean>(false);

    /** Error message, will display a colored border and the error message below the input */
    readonly errorMessage = input<string | undefined>();

    /** Disable select */
    readonly disabled = input<boolean>(false);

    /** Readonly input */
    readonly inputReadOnly = input<boolean>(false);

    /** Hide arrow */
    readonly hideArrow = input<boolean>(false);

    // ------------ Values ------------//

    /** Values */
    readonly values = input<any[]>([]);

    /** Default selectedValues */
    readonly selectedValues = model<any[]>([]);

    /** Map each value from the 'values' array to a string to display (autocomplete works with these strings) */
    readonly displayWith = input<(option: any) => string>((option: any) => option);

    /** Item builder */
    readonly itemBuilder = input<any | null>(null);

    /** Compute unique hash key for an object */
    readonly computeObjectHash = input<(a?: any) => any | undefined>();

    /** Track by function for @for */
    readonly filteredValuesTrackByFn = input<(index: number, item: any) => any>((_index: number, item: any) => item);

    // ------------ Simple selected value ------------//

    /**
     * Use this template to override the input and show custom html when a value is selected.
     * Only for multiSelection = false
     * Takes 1 parameters:
     *      - value: the value (an element of 'values' array)
     */
    readonly simpleSelectedValueTemplate = contentChild<TemplateRef<any>>('simpleSelectedValueTemplate');

    // ------------ Multiple selected values ------------//

    /**
     * Determine if multiple values can be selected.
     * If true, the panel will not close when an option is selected.
     * If true, you might want to use a the 'selectedValueTemplate' template to customize each selected values,
     *
     * Ex :
     * <app-select-base>
     *       <ng-template #selectedValueTemplate let-value="value">
     *           <!-- your template -->
     *       </ng-template>
     * </app-select-base>
     */
    readonly multiSelection = input<boolean>(false);

    /**
     * Determine if selected values will be displayed on one line or will wrap on multiple lines.
     * If false and the box is not wide enough to display all the selected values,
     * the maximum amount of selected values will be displayed and the rest will be counted with a '+X' displayed at the end of the line,
     * with X the count of all selected values that overflow.
     */
    readonly multiSelectionElementWrap = model<boolean>(false);

    /**
     * Use this template to customize each selected values that will be displayed at the left of the input.
     * Takes 3 parameters:
     *      - value: the value (an element of 'values' array)
     *      - index: the index of the array of selected values
     *      - deleteValueAt: a function to call if you want to delete a select value directly in your template
     */
    readonly selectedValueTemplate = contentChild<TemplateRef<any>>('selectedValueTemplate');

    readonly shouldSwitchToWrapModeOnClick = input<boolean>(false);

    readonly shouldUpdateValuesToDisplayAfterSelection = input<boolean>(true);

    readonly defaultEmptyValueMessage = input<string | null>(null);

    // ------------ Selection panel ------------//

    /** Show values selected count in the selection panel footer */
    readonly showValuesSelectedCount = input<boolean>(false);

    /**
     * Message to display with the count of option selected.
     * Only take effect if showValuesSelectedCount is true
     */
    readonly valuesSelectedCountMessage = input<string | undefined>();

    /**
     * Maximum values selectable.
     * Infinite if null/undefined. At Maximum, equal to the length of 'values' array
     */
    readonly maxSelectableValues = input<number | undefined>(undefined);

    /**
     * Maximum values to display in the input.
     * Infinite if null/undefined. At Maximum, equal to the length of 'values' array
     */
    readonly maxSelectedValuesToDisplay = input<number>(Number.MAX_SAFE_INTEGER);

    /** Show a checkbox to select all option at once in the selection panel header */
    readonly showSelectAllCheckbox = input<boolean>(false);

    /** Message to display at the right of the 'select all' checkbox */
    readonly selectAllCheckboxMessage = input<string | undefined>();

    /** Hide already selected values in panel */
    readonly hideSelectedValues = input<boolean>(false);

    readonly idPrefix = input<string | undefined>();

    readonly getIdSuffixFn = input<((value: any) => string) | undefined>();

    /**
     * Take in consideration that some items can be disabled.
     */
    readonly valuesCanBeDisabled = input<boolean>(false);

    // ------------ Options ------------//

    /**
     * Show checkbox to select options.
     * If true, unique values cannot be selected more than one time.
     * Only take effect if multiSelection is true
     */
    readonly checkboxOption = input<boolean>(false);

    /**
     * Use this template to customize each option.
     * By default, each option is the string computed with the 'displayWith' function.
     * Takes 2 parameters:
     *      - value: the value (an element of 'values' array)
     *      - isValueSelected: a function that will return if a value is currently selected
     */
    readonly optionTemplate = contentChild<TemplateRef<any>>('optionTemplate');

    // ------------ Grouping and sorting ------------//

    /** Group selected values at top of the selection panel */
    readonly groupSelectedValuesAtTop = input<boolean>(false);

    /** Sort values in the selection panel */
    readonly sortBy = input<((a: any, b: any) => number) | undefined>();

    // ------------ Autocomplete item builder ------------//
    readonly valueBuilderTemplate = contentChild<TemplateRef<any>>('valueBuilderTemplate');

    readonly buildValueFromText = input<((fieldName: any) => any) | undefined>();

    readonly onAddValue = input<((value: string) => void) | undefined>();

    readonly theme = input<SelectBaseDisplayStyle>(SelectBaseDisplayStyle.DEFAULT);

    @Input()
    control = new UntypedFormControl();

    readonly clearInputAfterSelect = input<boolean>(false);

    readonly displayedOptionCount = input<number>(Number.MAX_SAFE_INTEGER);

    // ------------ other ------------//
    readonly testId = input<string>();

    readonly inputWithFixedWidth = input<boolean>(false);

    // ------------ Event ------------//

    /** On change event */
    readonly selectBaseChange = output<any>();

    readonly SvgIcon = SvgIcon;

    // ------------ Variables ------------//

    readonly isFocused = signal(false);
    readonly isEmptyValue = signal(true);
    readonly isNewOption = signal(false);
    readonly isDestroyed = signal(false);
    private _resetOnBlur = false;
    private _isTouched = false;

    readonly SelectBaseDisplayStyle = SelectBaseDisplayStyle;

    readonly filter = signal('');
    readonly computedMaxSelectableValues = computed(() =>
        Math.min(
            this.maxSelectableValues() ?? Number.MAX_SAFE_INTEGER,
            this.checkboxOption() && !this.buildValueFromText() ? (this.values()?.length ?? 0) : Number.MAX_SAFE_INTEGER
        )
    );
    readonly currentSelectedValue = signal<any | undefined>(undefined);
    readonly childrenHiddenCount = signal<number | undefined>(undefined);
    private _valueToPropagateAfterClose: any;

    readonly filteredValues = computed(() => {
        const filter = this.filter();
        const values = this.values() ?? [];
        const selectedValues = this.selectedValues();
        const groupSelectedValuesAtTop = this.groupSelectedValuesAtTop();
        const hideSelectedValues = this.hideSelectedValues();
        const sortBy = this.sortBy();

        const valuesAtTop: any[] = [];
        let otherValues: any[] = [];
        if (groupSelectedValuesAtTop) {
            otherValues.push(...values.filter((e) => !this.isValueSelected(selectedValues, e)));
            valuesAtTop.push(...values.filter((e) => this.isValueSelected(selectedValues, e)));
        } else {
            otherValues = this.values() ?? [];
        }

        if (sortBy) {
            otherValues.sort(sortBy);
            valuesAtTop.sort(sortBy);
        }
        const mergedValues = valuesAtTop.concat(otherValues);
        const mergedValuesFiltered = mergedValues.filter(
            (value) =>
                this.displayWithHighFn(value)?.toLowerCase().includes(filter.toLowerCase()) &&
                (hideSelectedValues ? !this.valueAlreadySelected(value) : true)
        );

        return mergedValuesFiltered.filter(Boolean);
    });

    hideChildrenRefreshFn: () => void;

    scrollListenerFn: () => void;

    readonly matAutocompleteTriggerElement = viewChild<MatAutocompleteTrigger>('trigger');

    readonly inputElement = viewChild.required<ElementRef<HTMLInputElement>>('inputElement');

    // ------------ Life cycle ------------//

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _ngZone = inject(NgZone);

    constructor() {
        effect(() => {
            if (this.matAutocompleteTriggerElement()) {
                this._ngZone.runOutsideAngular(() => {
                    window.addEventListener('scroll', this.scrollListenerFn, true);
                });
            }
        });
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((e) => {
            if (e?.disabledWithTooltip) {
                return;
            }
            this.isNewOption.set(
                e?.length > 0 && !Array.isArray(e) && !(this.values() ?? []).map((value) => this.displayWith()(value)).includes(e?.trim())
            );
            this.isEmptyValue.set(!e);
        });
        // In case of multiple of this component is used, we use bind method to create a unique reference for the further removeEventListener
        this.scrollListenerFn = this.updatePanelPosition.bind(this);
    }

    ngOnDestroy(): void {
        this.isDestroyed.set(true);
        window.removeEventListener('scroll', this.scrollListenerFn, true);
    }

    // ------------ Utils ------------//

    onChildrenHidden(childrenHiddenCount: number): void {
        this.childrenHiddenCount.set(childrenHiddenCount);
        this._changeDetectorRef.detectChanges();
    }

    onSelectedOption(event: MatAutocompleteSelectedEvent, options?: { isOptionClicked: boolean }): void {
        this.markAsTouched();
        const value = event.option.value;

        const cannotAddNewValue =
            value.disabledWithTooltip ||
            this.displayWithHighFn(value) === ' ' ||
            (this.computedMaxSelectableValues() <= this.selectedValues().length && this._isNewValue(this.selectedValues(), value));

        if (cannotAddNewValue) {
            return;
        }
        if (this.isNewOption() && !options?.isOptionClicked) {
            if (!!this.buildValueFromText()?.prototype) {
                this.addValue();
                if (this.clearInputAfterSelect()) {
                    this.control.setValue('');
                    this.filter.set('');
                }
                return;
            }

            if (this.itemBuilder()) {
                if (this.itemBuilder()() instanceof Observable) {
                    this.itemBuilder()(value).subscribe({
                        next: (el: any) => {
                            this.writeValueFn(el);
                            this.propagate(el);
                            if (this.clearInputAfterSelect()) {
                                this.control.setValue('');
                                this.filter.set('');
                            }
                        },
                        error: (err) => {
                            console.warn('error >', err);
                        },
                    });
                } else {
                    const newElement = this.itemBuilder()(value);

                    this.writeValue(newElement);
                    this.propagate(newElement);
                }

                if (this.clearInputAfterSelect()) {
                    this.control.setValue('');
                    this.filter.set('');
                }
                return;
            }
        }

        if (!this.multiSelection()) {
            this._resetOnBlur = false;
        } else {
            if (this.checkboxOption()) {
                const computeObjectHash = this.computeObjectHash();
                const index = computeObjectHash
                    ? this.selectedValues().map(computeObjectHash).indexOf(computeObjectHash(value))
                    : this.selectedValues().indexOf(value);
                if (index === -1) {
                    this.selectedValues.update((currentSelectedValues) => [...currentSelectedValues, value]);
                } else {
                    this.selectedValues.update((currentSelectedValues) =>
                        computeObjectHash
                            ? // @ts-ignore
                              currentSelectedValues.filter((v) => computeObjectHash(v) !== computeObjectHash(value))
                            : currentSelectedValues.filter((v) => v !== value)
                    );
                }
            } else {
                this.selectedValues.update((currentSelectedValues) => [...currentSelectedValues, value]);
            }
            if (this.shouldUpdateValuesToDisplayAfterSelection()) {
                this.control.setValue('');
                this.filter.set('');
            }
        }

        this.propagate(value);
        if (this.clearInputAfterSelect()) {
            this.control.setValue('');
            this.filter.set('');
        }
    }

    onInput(event: Event): void {
        this.markAsTouched();
        const value = (event.target as HTMLInputElement).value;

        if (!this.multiSelection()) {
            this.propagate(null);
        }

        this.filter.set(value);
    }

    propagate(value?: any): void {
        const toPropagate = this.multiSelection() ? this.selectedValues() : value;
        this.currentSelectedValue.set(value);
        if (this.shouldUpdateValuesToDisplayAfterSelection()) {
            this.onChange(toPropagate);
            this.selectBaseChange.emit(toPropagate);
        } else {
            this._valueToPropagateAfterClose = toPropagate;
        }

        // we use setTimeout to refresh the directive after the change detection
        setTimeout(() => this.hideChildrenRefreshFn?.());
    }

    displayWithHighFn = (option: any): string => {
        if (option === null || option === '') {
            return '';
        }
        return this.displayWith()(option);
    };

    isAllFilteredSelected(): boolean {
        return (
            this.selectedValues().length >= this.computedMaxSelectableValues() ||
            (this.filteredValues().length > 0 && this.filteredValues().every((value) => this.isValueSelected(this.selectedValues(), value)))
        );
    }

    isAllNonDisabledValuesSelected(): boolean {
        return (
            this.valuesCanBeDisabled() &&
            (this.selectedValues().length >= this.computedMaxSelectableValues() ||
                (this.selectedValues().length > 0 &&
                    this.selectedValues().length === this.values().filter((value) => !value.disabledWithTooltip).length))
        );
    }

    toggleAllFiltered(): void {
        const computeObjectHash = this.computeObjectHash();
        if (this.isAllFilteredSelected() || this.isAllNonDisabledValuesSelected()) {
            this.selectedValues.update((currentSelectedValues) =>
                currentSelectedValues.filter((selectedValue) => {
                    if (computeObjectHash) {
                        return !this.filteredValues()
                            .map((fv) => computeObjectHash(fv))
                            .includes(computeObjectHash(selectedValue));
                    }
                    return !this.filteredValues().includes(selectedValue);
                })
            );
        } else {
            const selectableValues = Math.max(this.computedMaxSelectableValues() - this.selectedValues().length, 0);
            const valuesToAdd = this.filteredValues()
                .filter((value) => !value.disabledWithTooltip)
                .filter((value) => {
                    if (computeObjectHash) {
                        return !this.selectedValues()
                            .map((sv) => computeObjectHash(sv))
                            .includes(computeObjectHash(value));
                    }
                    return !this.selectedValues().includes(value);
                })
                .slice(0, selectableValues);
            this.selectedValues.update((currentSelectedValues) => currentSelectedValues.concat(valuesToAdd));
        }
        this.propagate();
    }

    updatePanelPosition(): void {
        const matAutocompleteTriggerElement = this.matAutocompleteTriggerElement();
        if (matAutocompleteTriggerElement) {
            matAutocompleteTriggerElement.updatePosition();
        }
    }

    // ------------ UI logic ------------//

    openPanel(): void {
        const matAutocompleteTriggerElement = this.matAutocompleteTriggerElement();
        if (!matAutocompleteTriggerElement?.panelOpen) {
            matAutocompleteTriggerElement?.openPanel();
        }
    }

    onArrowClick(): void {
        const matAutocompleteTriggerElement = this.matAutocompleteTriggerElement();
        if (matAutocompleteTriggerElement?.panelOpen) {
            matAutocompleteTriggerElement?.closePanel();
        } else {
            matAutocompleteTriggerElement?.openPanel();
            this.inputElement().nativeElement.focus();
        }
    }

    onFocus(): void {
        this.isFocused.set(true);
    }

    onBlur(): void {
        this.isFocused.set(false);

        if (this._resetOnBlur) {
            this.control.setValue('');
            this.filter.set('');
        }
    }

    // ------------ Multi selection ------------//

    onMatOptionClick(value: any): void {
        this.onSelectedOption({ option: { value } } as MatAutocompleteSelectedEvent, { isOptionClicked: true });
    }

    onBackspaceKeyPressed(): void {
        if (this.simpleSelectedValueTemplate()) {
            if (this.control.value !== '') {
                this.control.setValue('');
                this.propagate();
                this._changeDetectorRef.detectChanges();
                this.inputElement().nativeElement.focus();
            }
        }
        if (this.multiSelection()) {
            if (!this.control.value && this.selectedValues().length > 0) {
                this.selectedValues.update((currentSelectedValues) => currentSelectedValues.slice(0, -1));
                this.propagate();
            }
        }
    }

    // Important to be a lambda because it is use in another place
    deleteValueAt = (chipIndex: number): void => {
        this.selectedValues.update((currentSelectedValues) => currentSelectedValues.filter((_, index) => index !== chipIndex));
        this.propagate();
        setTimeout(() => this.openPanel());
    };

    // Important to be a lambda because it is use in another place
    isValueSelected = (selectedValues: any[], value: any): boolean => {
        const computeObjectHash = this.computeObjectHash();
        return selectedValues.some((selectedValue) => {
            if (computeObjectHash) {
                return computeObjectHash(selectedValue) === computeObjectHash(value);
            }
            return selectedValue === value;
        });
    };

    needSelectedClass = (value: any, selectedValues: any[], checkboxOption: boolean = false): boolean => {
        if (this.multiSelection()) {
            return checkboxOption && this.isValueSelected(selectedValues, value);
        }
        const computeObjectHash = this.computeObjectHash();
        if (computeObjectHash) {
            return computeObjectHash(value) === computeObjectHash(this.control.value);
        }
        return value === this.control.value;
    };

    // ------------ Item builder methods ------------//
    buildTempChip = (controlValue: any): any => this.buildValueFromText()?.(controlValue);

    addValue(): void {
        const onAddValue = this.onAddValue();
        if (onAddValue) {
            onAddValue(this.control.value);
        }
        this.selectedValues.update((currentSelectedValues) => [...currentSelectedValues, this.buildTempChip(this.control.value)]);
        this.selectBaseChange.emit(this.selectedValues());
        this.propagate();
        this.control.setValue('');
    }

    displayAllSelectedValues(): void {
        if (this.shouldSwitchToWrapModeOnClick()) {
            this.multiSelectionElementWrap.set(true);
            this.hideChildrenRefreshFn?.();
        }
    }

    valueAlreadySelected(value: any): boolean {
        return this.selectedValues().find((selectedValue) => this.displayWithHighFn(selectedValue) === this.displayWithHighFn(value));
    }

    // ------------ To implement ControlValueAccessor ------------//

    onTouched = (): void => {};

    onChange = (_value: any): void => {};

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched(): void {
        if (!this._isTouched) {
            this.onTouched();
            this._isTouched = true;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    writeValue(value: any): void {
        // Because writeValue can be called before the first ngOnChanges
        setTimeout(() => {
            if (!this.isDestroyed()) {
                this.writeValueFn(value);
            }
        });
    }

    writeValueFn(value: any): void {
        if (!this.multiSelection()) {
            // mat-autocomplete will call displayWith function so we will not use it here
            this.control.setValue(value);
            this.currentSelectedValue.set(this.control.value);
        } else {
            this.selectedValues.update((currentSelectedValues) => [...(value ?? currentSelectedValues)]);
            const sortBy = this.sortBy();
            if (sortBy) {
                this.selectedValues().sort(sortBy);
            }
        }
        this._changeDetectorRef.detectChanges();
    }

    onEnterKeyPressed(): void {
        if (this.computedMaxSelectableValues() <= this.selectedValues().length) {
            return;
        }
        if (this.control?.value?.length > 0 && this.filteredValues().length === 0) {
            if (this.buildValueFromText()?.prototype) {
                this.addValue();
            }
            if (this.itemBuilder()) {
                this.onSelectedOption({ option: { value: this.control.value } } as any);
            }
        }
    }

    registerHideChildrenRefreshFn(fn: () => void): void {
        this.hideChildrenRefreshFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control.disable() : this.control.enable();
    }

    onPanelClosed(): void {
        if (!this.clearInputAfterSelect() && this._valueToPropagateAfterClose) {
            setTimeout(() => {
                this.onChange(this._valueToPropagateAfterClose);
                this.selectBaseChange.emit(this._valueToPropagateAfterClose);
                this.control.setValue('');
                this.filter.set('');
            }, DEFAULT_CLOSE_TIMEOUT_MS);
        }
    }

    getId = (value: any, idPrefix: string | undefined, getIdSuffixFn: ((v: any) => string) | undefined): string => {
        if (!idPrefix || !getIdSuffixFn) {
            return '';
        }
        return `${idPrefix}_${getIdSuffixFn(value)}`;
    };

    private _isNewValue(values: any[], value: any): boolean {
        const computeObjectHash = this.computeObjectHash();
        return (computeObjectHash ? values.map(computeObjectHash).indexOf(computeObjectHash(value)) : values.indexOf(value)) === -1;
    }
}
