import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const getSocialPostsByIdsQueryValidator = z
    .object({
        post_ids: z.array(objectIdValidator),
    })
    .transform((data) => ({ postIds: data.post_ids }));

export type GetSocialPostsByIdsQueryDto = z.infer<typeof getSocialPostsByIdsQueryValidator>;
