import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    effect,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { AggregationTimeScale, MalouMetric, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    ChartDataArray,
    createDateWithClientTimeZoneDifference,
    getDaysFromCurrentRange,
    getMonthsFromPeriod,
    getWeeksFromCurrentRange,
    isDateSetOrGenericPeriod,
    isSameDay,
    mergeArrays,
    Month,
    WeekRange,
} from ':shared/helpers';
import { DatesAndPeriod, getInsightsErrorText, InsightsByPlatform, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { InsightsService } from '../../insights.service';
import { StatisticsHttpErrorPipe } from '../../statistics-http-error.pipe';
import * as StatisticsActions from '../../store/statistics.actions';
import * as StatisticsSelector from '../../store/statistics.selectors';
import { CommunityChartComponent, CommunityData } from './community-chart/community-chart.component';
import { CommunityMainInfosComponent } from './community-main-infos/community-main-infos.component';

const DEFAULT_COMMUNITY_DATA: CommunityData = {
    instagramFollowers: [],
    facebookFollowers: [],
    totalFollowers: [],
    previousInstagramFollowers: [],
    previousFacebookFollowers: [],
    previousTotalFollowers: [],
    instagramNewFollowers: [],
    facebookNewFollowers: [],
    totalNewFollowers: [],
};
@Component({
    selector: 'app-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        CommunityChartComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        LowerCasePipe,
        ApplyPurePipe,
        CommunityMainInfosComponent,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityComponent implements OnInit {
    @Input() showViewByTextInsteadOfSelector = false;
    @Input() viewBy?: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() readonly viewByChange: EventEmitter<ViewBy> = new EventEmitter<ViewBy>();
    @Output() readonly hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelector.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);
    readonly previousPeriodDates: WritableSignal<Date[]> = signal([]);

    readonly httpError: WritableSignal<string | null> = signal(null);
    readonly isLoading = signal(true);
    readonly insightsError: WritableSignal<string | null> = signal(null);
    readonly ViewBy = ViewBy;

    readonly communityDataByDay: WritableSignal<CommunityData> = signal(DEFAULT_COMMUNITY_DATA);
    readonly communityData: WritableSignal<CommunityData> = signal(DEFAULT_COMMUNITY_DATA);
    readonly dateLabels: WritableSignal<Date[]> = signal([]);
    readonly areAllPlatformsInError = signal(false);
    readonly platformsErrorTooltip: WritableSignal<string | null> = signal(null);

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _insightsService: InsightsService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _enumTranslatePipe: EnumTranslatePipe
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.viewBy) {
            this.viewByFilterSubject$.next(this.viewBy);
        }
        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.platformKeys$, this.viewByFilterSubject$])
            .pipe(
                filter(
                    ([_restaurant, dates, _platforms, actionsViewBy]) =>
                        isDateSetOrGenericPeriod(dates) && Object.values(ViewBy).includes(actionsViewBy)
                ),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, platformKeys, actionsViewBy]: [Restaurant, DatesAndPeriod, PlatformKey[], ViewBy]) => {
                    const { startDate, endDate } = dates;
                    const aggregationTimeScale = this._computeInsightAggregatorFromViewByFilter(actionsViewBy);
                    this.viewByChange.emit(actionsViewBy);

                    const startDateMinusOneUnit = this._getDateMinusOneUnit(startDate, aggregationTimeScale);
                    const { _id: restaurantId } = restaurant;
                    return forkJoin([
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformKeys: platformKeys,
                                metrics: [MalouMetric.FOLLOWERS],
                                aggregators: [AggregationTimeScale.BY_DAY],
                                startDate: startDateMinusOneUnit,
                                endDate,
                            })
                            .pipe(
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        this._insightsService
                            .getInsights({
                                restaurantIds: [restaurantId],
                                platformKeys: platformKeys,
                                metrics: [MalouMetric.FOLLOWERS],
                                aggregators: [AggregationTimeScale.BY_DAY],
                                startDate,
                                endDate,
                                previousPeriod: true,
                            })
                            .pipe(
                                map((res) => res.data[restaurantId]),
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                        of(startDateMinusOneUnit),
                        of(startDate),
                        of(endDate),
                        of(platformKeys),
                        of(aggregationTimeScale),
                    ]);
                }),
                filter(([current]) => !!current),
                switchMap(
                    ([currentInsights, previousInsights, startDateMinusOneUnit, startDate, endDate, platforms, aggregationTimeScale]: [
                        InsightsByPlatform,
                        InsightsByPlatform,
                        Date,
                        Date,
                        Date,
                        PlatformKey[],
                        AggregationTimeScale,
                    ]) => {
                        this._store.dispatch(StatisticsActions.editFollowersRawData({ data: currentInsights }));
                        const platformsInError: PlatformKey[] = this._getPlatformsInError(currentInsights, platforms);
                        platformsInError.forEach((platform) => {
                            delete currentInsights[platform];
                            delete currentInsights[platform];
                        });

                        if (platforms.length === platformsInError.length) {
                            this.areAllPlatformsInError.set(true);
                            this.isLoading.set(false);
                            this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                            return EMPTY;
                        }

                        if (platformsInError.length) {
                            this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                        }
                        platforms = platforms.filter((e) => !platformsInError.includes(e));

                        return of([
                            currentInsights,
                            previousInsights,
                            startDateMinusOneUnit,
                            startDate,
                            endDate,
                            platforms,
                            aggregationTimeScale,
                        ]);
                    }
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([currentInsights, previousInsights, startDateMinusOneUnit, startDate, endDate, platforms, timeScale]: [
                    InsightsByPlatform,
                    InsightsByPlatform,
                    Date,
                    Date,
                    Date,
                    PlatformKey[],
                    AggregationTimeScale,
                ]) => {
                    if (!currentInsights[PlatformKey.FACEBOOK]?.by_day && !currentInsights[PlatformKey.INSTAGRAM]?.by_day) {
                        this._setInsightsError(currentInsights[PlatformKey.FACEBOOK]?.message);
                        this.hasDataChange.emit(false);
                        return;
                    }
                    this.communityDataByDay.set(
                        this._getCommunityDataByDayScale(currentInsights, previousInsights, startDate, endDate, platforms)
                    );

                    const datesRange = endDate.getTime() - startDate.getTime();
                    const previousStartDate = new Date(startDateMinusOneUnit.getTime() - datesRange);
                    const previousEndDate = new Date(endDate.getTime() - datesRange);

                    const days = getDaysFromCurrentRange(startDateMinusOneUnit, endDate);
                    const previousDays = getDaysFromCurrentRange(previousStartDate, previousEndDate);

                    let followersData: Partial<CommunityData> = {};
                    let previousFollowersData: Partial<CommunityData> = {};

                    // Data and Labels computing for chart
                    if (timeScale === AggregationTimeScale.BY_DAY) {
                        this.dateLabels.set(days.slice(1));
                        followersData = this._mapDailyInsightsToChart(currentInsights, days, platforms);
                        if (previousInsights) {
                            this.previousPeriodDates.set(previousDays.slice(1));
                            previousFollowersData = this._mapDailyInsightsToChart(previousInsights, previousDays, platforms);
                        }
                    } else if (timeScale === AggregationTimeScale.BY_WEEK) {
                        const weeks = getWeeksFromCurrentRange(startDateMinusOneUnit, endDate);
                        this.dateLabels.set(weeks.slice(1).map((week) => week.start));
                        followersData = this._mapWeeklyInsightsToChart(currentInsights, weeks, platforms);
                        if (previousInsights) {
                            const previousWeeks = getWeeksFromCurrentRange(previousStartDate, previousEndDate);
                            this.previousPeriodDates.set(previousWeeks.slice(1).map((week) => week.start));
                            previousFollowersData = this._mapWeeklyInsightsToChart(previousInsights, previousWeeks, platforms);
                        }
                    } else if (timeScale === AggregationTimeScale.BY_MONTH) {
                        const months: Month[] = getMonthsFromPeriod(startDateMinusOneUnit, endDate);
                        this.dateLabels.set(months.slice(1).map((e) => e.start));
                        followersData = this._mapMonthlyInsightsToChart(currentInsights, months, platforms);
                        if (previousInsights) {
                            const previousMonths: Month[] = getMonthsFromPeriod(previousStartDate, previousEndDate);
                            this.previousPeriodDates.set(previousMonths.slice(1).map((e) => e.start));
                            previousFollowersData = this._mapMonthlyInsightsToChart(previousInsights, previousMonths, platforms);
                        }
                    }

                    this.communityData.set(this._getCommunityData(followersData, previousFollowersData));

                    this.isLoading.set(false);
                }
            );
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslatePipe.transform(option, 'view_by');

    private _getCommunityDataByDayScale(
        currentInsights: InsightsByPlatform,
        previousInsights: InsightsByPlatform,
        startDate: Date,
        endDate: Date,
        platforms: string[]
    ): CommunityData {
        const datesRange = endDate.getTime() - startDate.getTime();
        const startDateMinusOneUnit = DateTime.fromJSDate(startDate).minus({ day: 1 }).toJSDate();
        const previousStartDate = new Date(startDateMinusOneUnit.getTime() - datesRange);
        const previousEndDate = new Date(endDate.getTime() - datesRange);

        const days = getDaysFromCurrentRange(startDateMinusOneUnit, endDate);
        const previousDays = getDaysFromCurrentRange(previousStartDate, previousEndDate);

        const followersData = this._mapDailyInsightsToChart(currentInsights, days, platforms);
        const previousFollowersData = this._mapDailyInsightsToChart(previousInsights, previousDays, platforms);

        return this._getCommunityData(followersData, previousFollowersData);
    }

    private _getCommunityData(currentFollowersData: Partial<CommunityData>, previousFollowersData: Partial<CommunityData>): CommunityData {
        const instagramNewFollowers = this._computeNewFollowers(currentFollowersData.instagramFollowers || []);
        const facebookNewFollowers = this._computeNewFollowers(currentFollowersData.facebookFollowers || []);
        return {
            instagramFollowers: currentFollowersData.instagramFollowers?.slice(1) || [],
            facebookFollowers: currentFollowersData.facebookFollowers?.slice(1) || [],
            totalFollowers: mergeArrays(currentFollowersData.instagramFollowers || [], currentFollowersData.facebookFollowers || []).slice(
                1
            ),
            previousInstagramFollowers: previousFollowersData.instagramFollowers?.slice(1) || [],
            previousFacebookFollowers: previousFollowersData.facebookFollowers?.slice(1) || [],
            previousTotalFollowers: mergeArrays(
                previousFollowersData.instagramFollowers || [],
                previousFollowersData.facebookFollowers || []
            ).slice(1),
            instagramNewFollowers: instagramNewFollowers,
            facebookNewFollowers: facebookNewFollowers,
            totalNewFollowers: mergeArrays(instagramNewFollowers, facebookNewFollowers),
        };
    }

    private _computeNewFollowers(arr: ChartDataArray): ChartDataArray {
        return arr.slice(2).map((e, index) => (arr[index + 1] && e ? e - (arr[index + 1] || 0) : null));
    }

    private _getDateMinusOneUnit(date: Date | null, aggregationTimeScale: AggregationTimeScale | undefined): Date | null {
        if (!date) {
            return null;
        }
        if (aggregationTimeScale === AggregationTimeScale.BY_DAY) {
            return DateTime.fromJSDate(date).minus({ day: 1 }).toJSDate();
        }
        if (aggregationTimeScale === AggregationTimeScale.BY_WEEK) {
            return DateTime.fromJSDate(date).minus({ week: 1 }).toJSDate();
        }
        if (aggregationTimeScale === AggregationTimeScale.BY_MONTH) {
            return DateTime.fromJSDate(date).minus({ month: 1 }).toJSDate();
        }
        return date;
    }

    private _setInsightsError(message?: string): void {
        this.insightsError.set(
            this._translate.instant(getInsightsErrorText(message), {
                platformName: this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key'),
            })
        );
    }

    private _mapWeeklyInsightsToChart(current: InsightsByPlatform, weeks: WeekRange[], platforms: string[]): Partial<CommunityData> {
        const chartData: Partial<CommunityData> = {
            facebookFollowers: [],
            instagramFollowers: [],
        };

        for (const week of weeks) {
            for (const platform of platforms) {
                const value = current[platform as PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM]?.by_day?.followers?.find((e) => {
                    // todo need refacto to remove createDateWithoutTimeZoneDifference @cyril
                    const dateComputed = createDateWithClientTimeZoneDifference(new Date(e.date));
                    return isSameDay(dateComputed, week.start);
                })?.value;
                if (platform === PlatformKey.FACEBOOK) {
                    chartData.facebookFollowers?.push(isNumber(value) ? value : null);
                } else if (platform === PlatformKey.INSTAGRAM) {
                    chartData.instagramFollowers?.push(isNumber(value) ? value : null);
                }
            }
        }

        return chartData;
    }

    private _mapMonthlyInsightsToChart(current: InsightsByPlatform, months: Month[], platforms: string[]): Partial<CommunityData> {
        const chartData: Partial<CommunityData> = {
            facebookFollowers: [],
            instagramFollowers: [],
        };

        for (const month of months) {
            for (const platform of platforms) {
                const value = current[platform as PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM]?.by_day?.followers?.find((e) => {
                    // todo need refacto to remove createDateWithoutTimeZoneDifference @cyril
                    const dateComputed = createDateWithClientTimeZoneDifference(new Date(e.date));
                    return isSameDay(dateComputed, month.start);
                })?.value;
                if (platform === PlatformKey.FACEBOOK) {
                    chartData.facebookFollowers?.push(isNumber(value) ? value : null);
                } else if (platform === PlatformKey.INSTAGRAM) {
                    chartData.instagramFollowers?.push(isNumber(value) ? value : null);
                }
            }
        }

        return chartData;
    }

    private _mapDailyInsightsToChart(current: InsightsByPlatform, days: Date[], platforms: string[]): Partial<CommunityData> {
        const chartData: Partial<CommunityData> = {
            facebookFollowers: [],
            instagramFollowers: [],
        };

        for (const day of days) {
            for (const platform of platforms) {
                const value = current[platform as PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM]?.by_day?.followers?.find((e) => {
                    // todo need refacto to remove createDateWithoutTimeZoneDifference @cyril
                    const dateComputed = createDateWithClientTimeZoneDifference(new Date(e.date));
                    return isSameDay(dateComputed, day);
                })?.value;
                if (platform === PlatformKey.FACEBOOK) {
                    chartData.facebookFollowers?.push(isNumber(value) ? value : null);
                } else if (platform === PlatformKey.INSTAGRAM) {
                    chartData.instagramFollowers?.push(isNumber(value) ? value : null);
                }
            }
        }

        return chartData;
    }

    private _reset(): void {
        this.httpError.set(null);
        this.insightsError.set(null);
        this.isLoading.set(true);
        this.platformsErrorTooltip.set(null);
        this.areAllPlatformsInError.set(false);
        this.dateLabels.set([]);
    }

    private _computeInsightAggregatorFromViewByFilter(viewBy: ViewBy): AggregationTimeScale | undefined {
        if (viewBy === ViewBy.DAY) {
            return AggregationTimeScale.BY_DAY;
        }
        if (viewBy === ViewBy.WEEK) {
            return AggregationTimeScale.BY_WEEK;
        }
        if (viewBy === ViewBy.MONTH) {
            return AggregationTimeScale.BY_MONTH;
        }
    }

    private _getPlatformsInError(insightsByPlatform: InsightsByPlatform, filteredPlatforms: PlatformKey[]): PlatformKey[] {
        return filteredPlatforms.filter((platform) => insightsByPlatform[platform]?.error);
    }

    private _getPlatformsErrorTooltip(platformsInError: PlatformKey[]): string {
        return platformsInError.map((platform) => this._enumTranslatePipe.transform(platform, 'platform_key')).join(', ');
    }
}
