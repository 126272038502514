import { z } from 'zod';

import { GeoSamplePlatform, MalouComparisonPeriod } from '@malou-io/package-utils';

export const getKeywordRankingsForOneRestaurantV3BodyValidator = z.object({
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    platformKey: z.nativeEnum(GeoSamplePlatform),
});
export type GetKeywordRankingsForOneRestaurantV3BodyDto = z.infer<typeof getKeywordRankingsForOneRestaurantV3BodyValidator>;

export const getKeywordRankingsForManyRestaurantsV3BodyValidator = z.object({
    restaurantIds: z.array(z.string()),

    startDate: z
        .string()
        .datetime()
        .transform((s) => new Date(s)),

    endDate: z
        .string()
        .datetime()
        .transform((s) => new Date(s)),

    platformKey: z.nativeEnum(GeoSamplePlatform),

    keywords: z.array(z.string()).describe('every keyword if this field is omitted').optional(),

    doNotFetchRecentSamples: z
        .boolean()
        .describe('Don’t attempt to fetch recent samples from the provider (only use the data that we already have). Defaults to false.')
        .optional(),

    comparisonPeriod: z.nativeEnum(MalouComparisonPeriod).optional(),
});
export type GetKeywordRankingsForManyRestaurantsV3BodyDto = z.infer<typeof getKeywordRankingsForManyRestaurantsV3BodyValidator>;
