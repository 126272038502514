import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart, MalouComparisonPeriod } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { GmbInsightsComponent } from ':modules/statistics/seo/gmb-insights/gmb-insights.component';
import { KeywordSearchImpressionsComponent } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.component';
import { StatisticsKeywordsDetailComponent } from ':modules/statistics/seo/statistics-keywords-detail/statistics-keywords-detail.component';
import { StatisticsSeoKeywordsV3Component } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-statistics-seo-pdf',
    templateUrl: './seo-pdf.component.html',
    styleUrls: ['./seo-pdf.component.scss'],
    standalone: true,
    imports: [
        StatisticsSeoKeywordsV3Component,
        TranslateModule,
        NgTemplateOutlet,
        IncludesPipe,
        FromToDateFormatterPipe,
        AsyncPipe,
        StatisticsPdfRestaurantsFormatterPipe,
        GmbInsightsComponent,
        KeywordSearchImpressionsComponent,
        StatisticsKeywordsDetailComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoPdfComponent {
    startDate: Date;
    endDate: Date;
    displayedCharts: InsightsChart[] = [];

    chartOptions: ChartOptions;
    readonly InsightsChart = InsightsChart;

    seoKeywordsHasData = true;

    readonly keywordSearchImpressionsHasData = signal(true);
    readonly selectedRestaurantTitle$: Observable<string>;

    readonly isKeywordsInsightsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-keywords-insights-v2'),
        {
            initialValue: false,
        }
    );

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _experimentationService: ExperimentationService,
        private readonly _store: Store,
        public readonly translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams();
        const { displayedCharts, chartOptions, comparisonPeriod } = parsedQueryParams;

        this.chartOptions = chartOptions ?? {};
        this.displayedCharts = displayedCharts;

        const {
            dates: { startDate, endDate },
        } = parsedQueryParams;
        this.startDate = startDate;
        this.endDate = endDate;

        this._store.dispatch(
            StatisticsActions.editComparisonPeriod({ comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD })
        );
        this.selectedRestaurantTitle$ = this._restaurantsService.restaurantSelected$.pipe(
            map((restaurant) => restaurant?.internalName ?? restaurant?.name ?? '')
        );
    }
}
