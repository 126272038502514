<form class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-white p-5" [formGroup]="scheduleForm()">
    <div class="flex items-center justify-between">
        <span class="malou-text-13--bold text-malou-color-text-1">{{ 'posts.duplicate_post_modal.post_all_same_time' | translate }}</span>
        <app-slide-toggle [checked]="willPostAllAtSameTime()" (onToggle)="onToggleWillPostAllAtSameTime()"></app-slide-toggle>
    </div>

    @if (willPostAllAtSameTime()) {
        <div class="mt-4 flex gap-2">
            <app-select
                class="flex-1"
                formControlName="status"
                [displayWith]="statusDisplayWith"
                [values]="postDateStatuses"
                [inputReadOnly]="true">
            </app-select>
            <app-input-date-picker
                class="max-w-40"
                formControlName="plannedPublicationDate"
                [manualInputAvailable]="false"
                [ngClass]="{ hidden: currentStatus() === PostDateStatus.NOW }"
                [ownInputValidation]="true"
                [min]="MIN_DATE"></app-input-date-picker>
            <app-simple-select
                class="min-w-[90px]"
                placeholder="----"
                formControlName="postTime"
                [ngClass]="{ hidden: currentStatus() === PostDateStatus.NOW }"
                [values]="filteredTimes()"
                [errorMessage]="
                    (isPastHour | applyPure: { hourWithMinute: currentTime(), date: currentDate() })
                        ? ('common.invalid_time' | translate)
                        : ''
                "
                (simpleSelectChange)="changeSelectedTime($event)">
                <ng-template let-value="value" #selectedValueTemplate>
                    <div class="malou-text-12--medium mt-[1px] text-malou-color-text-1">
                        @if (value) {
                            {{ value | formatTime: locale === 'en' }}
                        }
                    </div>
                </ng-template>

                <ng-template let-option="option" #optionTemplate>
                    <div class="malou-text-12--regular text-malou-color-text-2">
                        {{ option | formatTime }}
                    </div>
                </ng-template>
            </app-simple-select>
        </div>
    }
</form>
