import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    OnInit,
    output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual, isEqualWith, isNumber } from 'lodash';
import { catchError, combineLatest, distinctUntilChanged, filter, map, Observable, of, switchMap, tap } from 'rxjs';

import { SegmentAnalysisInsightsForRestaurantDto } from '@malou-io/package-dto';
import {
    HeapEventName,
    isSameDay,
    MalouComparisonPeriod,
    PlatformFilterPage,
    PlatformKey,
    ReviewAnalysisSentiment,
    ReviewAnalysisTag,
} from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { SegmentAnalysesService } from ':core/services/segment-analyses.service';
import { LocalStorage } from ':core/storage/local-storage';
import {
    RestaurantSemanticAnalysisInsights,
    SemanticAnalysisInsightsMapper,
} from ':modules/aggregated-statistics/e-reputation/semantic-analysis/aggregated-semantic-analysis/aggregated-semantic-analysis-insights.mapper';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum SemanticAnalysisColumns {
    RESTAURANT = 'restaurant',
    SENTIMENT_NUMBER = 'sentimentNumber',
    SENTIMENT = 'sentiment',
    FOOD = ReviewAnalysisTag.FOOD,
    SERVICE = ReviewAnalysisTag.SERVICE,
    ATMOSPHERE = ReviewAnalysisTag.ATMOSPHERE,
    PRICE = ReviewAnalysisTag.PRICE,
    HYGIENE = ReviewAnalysisTag.HYGIENE,
    TOP_TOPICS = 'topTopics',
    EXPANDED_DETAIL = 'expandedDetail',
}

@Component({
    selector: 'app-aggregated-semantic-analysis',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatTableModule,
        MatIconModule,
        MatSortModule,
        MatTooltipModule,
        TranslateModule,
        SkeletonComponent,
        NumberEvolutionComponent,
        IllustrationPathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ShortNumberPipe,
        EnumTranslatePipe,
        ImagePathResolverPipe,
        EmojiPathResolverPipe,
    ],
    templateUrl: './aggregated-semantic-analysis.component.html',
    styleUrl: './aggregated-semantic-analysis.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedSemanticAnalysisComponent implements OnInit {
    readonly isLoadingEvent = output<boolean>();

    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _segmentAnalysesService = inject(SegmentAnalysesService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _heapService = inject(HeapService);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);

    readonly SvgIcon = SvgIcon;
    readonly Emoji = Emoji;
    readonly Illustration = Illustration;
    readonly SemanticAnalysisColumns = SemanticAnalysisColumns;
    readonly ReviewAnalysisTag = ReviewAnalysisTag;
    readonly ReviewAnalysisSentiment = ReviewAnalysisSentiment;
    readonly isNumber = isNumber;
    readonly ALL_RESTAURANTS_ID = 'ALL';

    sort: Sort = { active: SemanticAnalysisColumns.RESTAURANT, direction: ChartSortBy.ASC };
    readonly expandedRow = signal<RestaurantSemanticAnalysisInsights | null>(null);
    readonly currentLang = signal(LocalStorage.getLang());
    readonly isLoading = signal(true);
    readonly hasError = signal(false);
    readonly segmentAnalysesForRestaurants = signal<SegmentAnalysisInsightsForRestaurantDto[]>([]);

    readonly dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(AggregatedStatisticsSelectors.selectDatesFilter)
        .pipe(
            distinctUntilChanged((prev, curr) => {
                const isSameStartDate = !!prev.startDate && !!curr.startDate && isSameDay(prev.startDate, curr.startDate);
                const isSameEndDate = !!prev.endDate && !!curr.endDate && isSameDay(prev.endDate, curr.endDate);
                return isSameStartDate && isSameEndDate;
            })
        );

    // TODO: update when adding calendar with comparison period select in aggregated stats
    readonly comparisonPeriod$: Observable<MalouComparisonPeriod> = of(MalouComparisonPeriod.PREVIOUS_PERIOD);

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(
            distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)),
            map((platforms) => {
                if (platforms?.length) {
                    return platforms;
                }
                return Object.values(PlatformKey);
            })
        );

    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
        distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqualWith(prev, curr, (c, d) => c.id === d.id))
    );

    readonly dataSource: WritableSignal<MatTableDataSource<RestaurantSemanticAnalysisInsights>> = signal(
        new MatTableDataSource<RestaurantSemanticAnalysisInsights>([])
    );
    readonly hasData: Signal<boolean> = computed(() => this.dataSource().data.length > 0);
    readonly displayedColumns: WritableSignal<SemanticAnalysisColumns[]> = signal(
        Object.values(SemanticAnalysisColumns).filter((column) => column !== SemanticAnalysisColumns.EXPANDED_DETAIL)
    );

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource().sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    case SemanticAnalysisColumns.RESTAURANT:
                        return item.restaurantName ?? '';
                    case SemanticAnalysisColumns.SENTIMENT_NUMBER:
                        return item.sentimentNumber.value ?? 0;
                    case SemanticAnalysisColumns.SENTIMENT:
                        return item.positiveSentimentPercentage.value ?? 0;
                    case SemanticAnalysisColumns.FOOD:
                        return item.foodPositiveSentimentPercentage.value ?? 0;
                    case SemanticAnalysisColumns.SERVICE:
                        return item.servicePositiveSentimentPercentage.value ?? 0;
                    case SemanticAnalysisColumns.ATMOSPHERE:
                        return item.atmospherePositiveSentimentPercentage.value ?? 0;
                    case SemanticAnalysisColumns.PRICE:
                        return item.pricePositiveSentimentPercentage.value ?? 0;
                    case SemanticAnalysisColumns.HYGIENE:
                        return item.hygienePositiveSentimentPercentage.value ?? 0;
                    default:
                        return '';
                }
            };
            this.dataSource().sort = sort;

            this.dataSource().sortData = (data: any[], matSort: MatSort): RestaurantSemanticAnalysisInsights[] => {
                const sortedData = data.sort((a, b) => {
                    if (a.restaurantId === this.ALL_RESTAURANTS_ID && b.restaurantId !== this.ALL_RESTAURANTS_ID) {
                        return -1;
                    } else if (a.restaurantId !== this.ALL_RESTAURANTS_ID && b.restaurantId === this.ALL_RESTAURANTS_ID) {
                        return 1;
                    } else {
                        const property = matSort.active;
                        const direction = matSort.direction === 'asc' ? 1 : -1;
                        const valueA = this.dataSource().sortingDataAccessor(a, property);
                        const valueB = this.dataSource().sortingDataAccessor(b, property);

                        if (valueA < valueB) {
                            return -1 * direction;
                        } else if (valueA > valueB) {
                            return 1 * direction;
                        } else {
                            return 0;
                        }
                    }
                });

                return sortedData;
            };
        }
    }

    constructor() {
        effect(() => {
            this.isLoadingEvent.emit(this.isLoading());
        });
    }

    ngOnInit(): void {
        this._buildSemanticAnalysisData();
    }

    redirectToRestaurantEReputationStatsPage(restaurantId: string): void {
        if (restaurantId === this.ALL_RESTAURANTS_ID) {
            return;
        }
        const urlTree = ['/restaurants', restaurantId, 'statistics', 'e-reputation'];
        const url = this._router.serializeUrl(
            this._router.createUrlTree(urlTree, {
                queryParams: { tab: 1 },
            })
        );
        window.open(url, '_blank');
    }

    toggleExpandedRow(row: RestaurantSemanticAnalysisInsights): void {
        if (row.restaurantId === this.ALL_RESTAURANTS_ID) {
            return;
        }
        if (this.expandedRow() === row) {
            this.expandedRow.set(null);
        } else {
            this._heapService.track(HeapEventName.TRACKING_AGGREGATED_SEMANTIC_ANALYSIS_ROW_CLICK, { restaurantId: row.restaurantId });
            this.expandedRow.set(row);
            setTimeout(() => {
                this.scrollToRow(row.restaurantId);
            }, 100);
        }
    }

    scrollToRow(restaurantId: string): void {
        const rowElement = document.getElementById(`row_${restaurantId}`);

        if (rowElement) {
            rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    private _buildSemanticAnalysisData(): void {
        let selectedRestaurants: Restaurant[] = [];
        combineLatest([this.dates$, this.comparisonPeriod$, this.platformKeys$, this.selectedRestaurants$])
            .pipe(
                filter(
                    ([dates, comparisonPeriod, platforms, restaurants]) =>
                        !!restaurants.length && !!dates.startDate && !!dates.endDate && !!comparisonPeriod && platforms.length > 0
                ),
                tap(() => {
                    this.isLoading.set(true);
                    this.hasError.set(false);
                }),
                switchMap(([dates, comparisonPeriod, platforms, restaurants]) => {
                    const { startDate, endDate } = dates;
                    selectedRestaurants = restaurants;
                    const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                    return this._segmentAnalysesService
                        .getAggregatedSegmentAnalysisForRestaurants({
                            startDate,
                            endDate,
                            platformKeys: platforms,
                            restaurantIds: restaurantIds,
                            comparisonPeriod,
                        })
                        .pipe(
                            map((res) => res.data),
                            catchError((error) => {
                                console.error('Error while fetching aggregated segment analyses data', error);
                                this.hasError.set(true);
                                return of([]);
                            })
                        );
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (aggregatedSegmentAnalysisForRestaurant: SegmentAnalysisInsightsForRestaurantDto[]) => {
                    this.segmentAnalysesForRestaurants.set(aggregatedSegmentAnalysisForRestaurant);
                    this.dataSource().data = SemanticAnalysisInsightsMapper.createSegmentAnalysisInsightsFromDtoArray(
                        aggregatedSegmentAnalysisForRestaurant,
                        selectedRestaurants,
                        this.currentLang()
                    );
                    this.isLoading.set(false);
                },
                error: () => {
                    this.isLoading.set(false);
                },
            });
    }
}
