import { I18nPluralPipe, NgLocalization } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isString } from 'lodash';

export enum PluralCategory {
    NONE = 0,
    ONE = 1,
    MANY = 2,
}

@Pipe({
    name: 'pluralTranslate',
    standalone: true,
})
export class PluralTranslatePipe implements PipeTransform {
    public constructor(
        private readonly _translate: TranslateService,
        private readonly _localization: NgLocalization
    ) {}

    // Inspired by https://github.com/ngx-translate/core/issues/150#issuecomment-249328244
    public transform(key: string, count: number, params?: any): string {
        const pluralPipe: I18nPluralPipe = new I18nPluralPipe(this._localization);
        const mapping: Record<string, string> | string = this._translate.instant(key);
        if (!this._hasFoundKey(mapping)) {
            return key;
        }
        if (!params) {
            return pluralPipe.transform(count, mapping);
        }
        const builtMapping = Object.keys(mapping).reduce((acc, mappingKey) => {
            acc[mappingKey] = this._translate.instant([key, mappingKey].join('.'), params);
            return acc;
        }, {});
        return pluralPipe.transform(count, builtMapping);
    }

    private _hasFoundKey(mapping: Record<string, string> | string): mapping is Record<string, string> {
        return !isString(mapping);
    }
}
