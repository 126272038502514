import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    effect,
    inject,
    input,
    Input,
    OnInit,
    output,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { isNotNil, MalouComparisonPeriod, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewEvolution, ReviewsEvolutionWithRange } from ':modules/reviews/reviews.interface';
import { ReviewsService } from ':modules/reviews/reviews.service';
import {
    ReviewsRatingsCount,
    ReviewsRatingsEvolutionChartComponent,
} from ':modules/statistics/e-reputation/reviews-ratings-evolution/reviews-ratings-evolution-chart/reviews-ratings-evolution-chart.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import {
    createDate,
    getDateOfISOWeek,
    getDaysYearRange,
    getMonthsYearRange,
    getWeekAndYearNumber,
    isDateSetOrGenericPeriod,
} from ':shared/helpers';
import { DatesAndPeriod, DayYear, MonthYear, Restaurant, WeekDayMonthYear, WeekYear } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

enum ViewByTemporality {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}
interface EvolutionsMap {
    [key: string]: {
        [year: number]: {
            [time in ViewByTemporality]?: number;
        };
    };
}

@Component({
    selector: 'app-reviews-ratings-evolution',
    templateUrl: './reviews-ratings-evolution.component.html',
    styleUrls: ['./reviews-ratings-evolution.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        ReviewsRatingsEvolutionChartComponent,
        MatProgressSpinnerModule,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        LowerCasePipe,
        ApplyPurePipe,
    ],
    providers: [EnumTranslatePipe],
})
export class ReviewsRatingsEvolutionComponent implements OnInit {
    readonly showViewByTextInsteadOfSelector = input<boolean>(false);
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslate = inject(EnumTranslatePipe);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly statisticsFilters$: Observable<StatisticsState['filters']> = this._store.select(StatisticsSelectors.selectFilters);

    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.WEEK) as FormControl<ViewBy>;
    readonly chartViewBy: WritableSignal<ViewBy> = signal(ViewBy.WEEK);
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);

    readonly currentDateLabels: WritableSignal<Date[]> = signal([]);
    readonly currentPlatformsRatingsData: WritableSignal<ReviewsRatingsCount> = signal({});
    readonly previousPlatformsRatingsData: WritableSignal<ReviewsRatingsCount> = signal({});
    readonly previousDateLabels: WritableSignal<Date[]> = signal([]);

    readonly httpError: WritableSignal<any> = signal(null);
    readonly noResults: WritableSignal<boolean> = signal(false);
    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly isSomePlatformGotNoData: WritableSignal<boolean> = signal(false);
    readonly somePlatformGotNoDataErrorMsg: WritableSignal<string> = signal('');

    readonly reviewsEvolution: WritableSignal<ReviewsEvolutionWithRange | undefined> = signal(undefined);
    readonly previousReviewsEvolution: WritableSignal<ReviewsEvolutionWithRange | undefined> = signal(undefined);

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    @Input() set viewBy(value: ViewBy | undefined) {
        if (value) {
            this.chartViewBy.set(value);
            this.viewByControl.setValue(value);
        }
    }

    ngOnInit(): void {
        combineLatest([this.restaurant$, this.statisticsFilters$])
            .pipe(
                filter(
                    ([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) =>
                        isNotNil(restaurant) &&
                        isDateSetOrGenericPeriod(statisticsFilters.dates) &&
                        statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION].length > 0 &&
                        statisticsFilters.isFiltersLoaded
                ),
                map(([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) => [
                    statisticsFilters.dates,
                    statisticsFilters.platforms[PlatformFilterPage.E_REPUTATION],
                    restaurant,
                    statisticsFilters.comparisonPeriod,
                ]),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(
                    ([dates, platforms, restaurant, comparisonPeriod]: [
                        DatesAndPeriod,
                        PlatformKey[],
                        Restaurant,
                        MalouComparisonPeriod,
                    ]): Observable<[ReviewsEvolutionWithRange, ReviewsEvolutionWithRange, string[]]> => {
                        const restaurantId = restaurant._id;
                        const { startDate, endDate } = dates;

                        return forkJoin([
                            this._reviewsService.getChartRestaurantsReviewsEvolutions({ restaurantId, platforms, startDate, endDate }).pipe(
                                catchError((error) => {
                                    this.httpError.set(error);
                                    this.hasDataChange.emit(false);
                                    this.isLoading.set(false);
                                    return EMPTY;
                                })
                            ),
                            this._reviewsService
                                .getChartRestaurantsReviewsEvolutions({
                                    restaurantId,
                                    platforms,
                                    startDate,
                                    endDate,
                                    comparisonPeriod: comparisonPeriod ?? MalouComparisonPeriod.PREVIOUS_PERIOD,
                                })
                                .pipe(
                                    catchError((error) => {
                                        this.httpError.set(error);
                                        this.hasDataChange.emit(false);
                                        this.isLoading.set(false);
                                        return EMPTY;
                                    })
                                ),
                            of(platforms),
                        ]);
                    }
                ),
                map(
                    ([reviewsEvolution, previousReviewsEvolution, platforms]: [
                        ReviewsEvolutionWithRange,
                        ReviewsEvolutionWithRange,
                        string[],
                    ]) => {
                        if (!reviewsEvolution || reviewsEvolution?.results?.dataPerWeek?.length === 0) {
                            this.isLoading.set(false);
                            this.noResults.set(true);
                            this.hasDataChange.emit(false);
                            return null;
                        }
                        return [reviewsEvolution, previousReviewsEvolution, platforms];
                    }
                ),
                filter((dataOrNull: [ReviewsEvolutionWithRange, ReviewsEvolutionWithRange, string[]] | null) => isNotNil(dataOrNull)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(
                ([reviewsEvolution, previousReviewsEvolution, platforms]: [
                    ReviewsEvolutionWithRange,
                    ReviewsEvolutionWithRange,
                    string[],
                ]) => {
                    this._store.dispatch(StatisticsActions.editReviewsRatingsEvolutionData({ data: reviewsEvolution }));
                    this.reviewsEvolution.set(reviewsEvolution);
                    this.previousReviewsEvolution.set(previousReviewsEvolution);
                    const evolutionsPerWeek = reviewsEvolution?.results?.dataPerWeek?.filter((e) => !!e.year && !!e.week);
                    this._checkIfPlatformsGoNoData(evolutionsPerWeek, platforms);
                    const viewBy = this.viewByControl.value;
                    try {
                        this._computeCurrentAndPreviousData(reviewsEvolution, previousReviewsEvolution, viewBy);
                    } catch (error) {
                        console.error(error);
                    }

                    this.isLoading.set(false);
                }
            );

        this.viewByControl.valueChanges.pipe(startWith(ViewBy.WEEK), takeUntilDestroyed(this._destroyRef)).subscribe((viewBy: ViewBy) => {
            this.chartViewBy.set(viewBy);
            this.viewByChange.emit(viewBy);
            const reviewsEvolution = this.reviewsEvolution();
            const previousReviewsEvolution = this.previousReviewsEvolution();
            if (!reviewsEvolution) {
                return;
            }
            this._computeCurrentAndPreviousData(reviewsEvolution, previousReviewsEvolution, viewBy);
        });
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    private _getChartDataByView(
        reviewsEvolution: ReviewsEvolutionWithRange,
        viewBy: ViewBy
    ): { platformsRatingsData: ReviewsRatingsCount; dateLabels: Date[] } {
        const {
            results: { dataPerDay, dataPerWeek, dataPerMonth },
            startDate,
            endDate,
        } = reviewsEvolution;
        const start = createDate(startDate);
        const end = createDate(endDate);
        switch (viewBy) {
            case ViewBy.DAY:
                const days: DayYear[] = getDaysYearRange(start, end);
                return {
                    platformsRatingsData: this._getPlatformsRatingsData(dataPerDay, days, viewBy),
                    dateLabels: this._getDayYearDateLabels(days),
                };
            case ViewBy.WEEK:
                const weekYears: WeekYear[] = this._getWeekYears(start, end);
                return {
                    platformsRatingsData: this._getPlatformsRatingsData(dataPerWeek, weekYears, viewBy),
                    dateLabels: this._getWeekYearDateLabels(weekYears),
                };
            case ViewBy.MONTH:
                const months: MonthYear[] = getMonthsYearRange(start, end);
                return {
                    platformsRatingsData: this._getPlatformsRatingsData(dataPerMonth, months, viewBy),
                    dateLabels: this._getMonthYearDateLabels(months),
                };
            default:
                return {
                    platformsRatingsData: {},
                    dateLabels: [],
                };
        }
    }

    private _checkIfPlatformsGoNoData(evolutions: ReviewEvolution[], platforms: string[]): void {
        const platformsWithNoData: string[] = platforms
            .filter((platform) => !evolutions.some((evolution) => evolution.key === platform))
            .map((platformKey) => this._enumTranslate.transform(platformKey, 'platform_key'));
        if (platformsWithNoData.length > 0) {
            const baseMsg = this._translateService.instant('statistics.e_reputation.reviews_ratings.some_platforms_got_no_data');
            this.somePlatformGotNoDataErrorMsg.set(`${baseMsg} ${platformsWithNoData.join(', ')}`);
            this.isSomePlatformGotNoData.set(true);
        }
    }

    private _getWeekYears(start: Date, end: Date): WeekYear[] {
        const startDateWeekYear = getWeekAndYearNumber(start);
        const endDateWeekYear = getWeekAndYearNumber(end);
        const weekYears: WeekYear[] = this._getWeeksRange(startDateWeekYear, endDateWeekYear);
        return weekYears;
    }

    private _getWeekYearDateLabels(weekYears: WeekYear[]): Date[] {
        return weekYears.map((e) => getDateOfISOWeek(e.week, e.year));
    }

    private _getDayYearDateLabels(days: DayYear[]): Date[] {
        return days.map((e) => new Date(e.year, 0, e.day));
    }

    private _getMonthYearDateLabels(months: MonthYear[]): Date[] {
        return months.map((e) => new Date(e.year, e.month - 1, 1));
    }

    private _getPlatformsRatingsData<T extends ReviewEvolution, U extends WeekDayMonthYear>(
        reviewsEvolutions: T[],
        timeRange: U[],
        viewBy: ViewBy
    ): ReviewsRatingsCount {
        const temporality = this._getViewByTemporality(viewBy);
        const evolutionsMap = this._getEvolutionsMap<T>(reviewsEvolutions, temporality);
        const platformsKeys = Object.keys(evolutionsMap);
        const platformsRatingsData: ReviewsRatingsCount = {};
        for (const platformKey of platformsKeys) {
            const platformTotals: number[] = [];
            for (const time of timeRange) {
                platformTotals.push(evolutionsMap[platformKey]?.[time.year]?.[time[temporality]] || 0);
            }
            platformsRatingsData[platformKey] = platformTotals;
        }
        return platformsRatingsData;
    }

    private _getWeeksRange(start: WeekYear, end: WeekYear): WeekYear[] {
        const weeks: WeekYear[] = [];
        let currentWeek = start.week;
        let currentYear = start.year;
        while (currentYear < end.year || (currentYear === end.year && currentWeek <= end.week)) {
            weeks.push({ week: currentWeek, year: currentYear });
            const weeksInWeekYear = this._getYearWeekNumber(currentYear);
            currentWeek = currentWeek === weeksInWeekYear ? 1 : currentWeek + 1;
            currentYear = currentWeek === 1 ? currentYear + 1 : currentYear;
        }
        return weeks;
    }

    /**
     * Return the number of weeks in a year
     * @param year
     * @return number 52 ou 53
     */
    private _getYearWeekNumber(year: number): number {
        // We set the month to 2 (= february) to avoid the january edge case
        // when week number is equal to 52 (or 53) in early january
        // see https://en.wikipedia.org/wiki/ISO_week_date
        return DateTime.utc(year, 2).weeksInWeekYear;
    }

    private _getEvolutionsMap<T extends ReviewEvolution>(reviewsEvolutions: T[], temporality: ViewByTemporality): EvolutionsMap {
        const evolutionsMap: EvolutionsMap = {};
        reviewsEvolutions.forEach((e) => {
            if (!evolutionsMap[e.key]) {
                evolutionsMap[e.key] = {};
            }
            if (!evolutionsMap[e.key][e.year]) {
                evolutionsMap[e.key][e.year] = {};
            }
            if (!evolutionsMap[e.key][e.year][e[temporality]]) {
                evolutionsMap[e.key][e.year][e[temporality]] = e.total;
            }
        });
        return evolutionsMap;
    }

    private _getViewByTemporality(viewBy: ViewBy): ViewByTemporality {
        switch (viewBy) {
            case ViewBy.DAY:
                return ViewByTemporality.DAY;
            case ViewBy.WEEK:
                return ViewByTemporality.WEEK;
            case ViewBy.MONTH:
                return ViewByTemporality.MONTH;
            default:
                return ViewByTemporality.WEEK;
        }
    }

    private _computeCurrentAndPreviousData(
        reviewsEvolution: ReviewsEvolutionWithRange,
        previousReviewsEvolution: ReviewsEvolutionWithRange | undefined,
        viewBy: ViewBy
    ): void {
        const { platformsRatingsData, dateLabels } = this._getChartDataByView(reviewsEvolution, viewBy);
        this.currentPlatformsRatingsData.set(platformsRatingsData);
        this.currentDateLabels.set(dateLabels);
        if (previousReviewsEvolution) {
            const { previousDateLabels, previousPlatformsRatingsData } = this._getPreviousChartData(viewBy, previousReviewsEvolution);
            this.previousDateLabels.set(previousDateLabels);
            this.previousPlatformsRatingsData.set(previousPlatformsRatingsData);
        }
    }

    private _getPreviousChartData(
        viewBy: ViewBy,
        previousReviewsEvolution: ReviewsEvolutionWithRange
    ): {
        previousDateLabels: Date[];
        previousPlatformsRatingsData: ReviewsRatingsCount;
    } {
        if (viewBy === ViewBy.DAY) {
            return { previousDateLabels: [], previousPlatformsRatingsData: {} };
        }
        const { platformsRatingsData, dateLabels } = this._getChartDataByView(previousReviewsEvolution, viewBy);
        return { previousDateLabels: dateLabels, previousPlatformsRatingsData: platformsRatingsData };
    }

    private _reset(): void {
        this.httpError.set(null);
        this.isLoading.set(true);
        this.noResults.set(false);
        this.isSomePlatformGotNoData.set(false);
        this.somePlatformGotNoDataErrorMsg.set('');
    }
}
