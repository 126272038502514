<div
    class="relative"
    [ngClass]="{ 'h-[280px]': !isNewSemanticAnalysisFeatureEnabled(), 'h-[315px]': isNewSemanticAnalysisFeatureEnabled() }">
    <canvas
        baseChart
        [datasets]="chartDataSets()"
        [labels]="chartLabels()"
        [options]="chartOption"
        [type]="CHART_TYPE"
        [plugins]="[LABEL_CLICK_PLUGIN]"></canvas>
</div>
