import { ChangeDetectionStrategy, Component, input, model, OnInit, viewChildren, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, debounceTime, of, Subject, switchMap, tap } from 'rxjs';

import { SimpleRestaurant } from '@malou-io/package-dto';
import { BusinessCategory, generateDbId, ReportType } from '@malou-io/package-utils';

import { ReportsService } from ':core/services/report.service';
import { ToastService } from ':core/services/toast.service';
import { User } from ':modules/user/user';
import { isValidEmail } from ':shared/helpers/email.validator';
import { Address, Media, Restaurant } from ':shared/models';
import { Report } from ':shared/models/report';

@Component({
    selector: 'app-base-reports-settings',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '<div>base works</div>',
})
export class BaseReportsSettingsComponent implements OnInit {
    readonly restaurants = input.required<Restaurant[]>();
    readonly candidates = input.required<string[]>();
    readonly dailyReport = model.required<Report | null>();
    readonly weeklyReport = model.required<Report | null>();
    readonly currentUser = input.required<User | null>();

    readonly MAX_RECIPIENTS = 100;

    readonly showInvalidEmailToast$ = new Subject<boolean>();

    readonly dailyReportsConfigurations$: BehaviorSubject<FormGroup[]> = new BehaviorSubject<FormGroup[]>([]);

    readonly weeklyReportsConfigurations$: BehaviorSubject<FormGroup[]> = new BehaviorSubject<FormGroup[]>([]);

    readonly candidates$ = new BehaviorSubject<string[]>([]);

    readonly localRestaurants$ = new BehaviorSubject<Restaurant[]>([]);

    readonly updateDailyReportConfigurations$ = new Subject<void>();
    readonly updateWeeklyReportConfigurations$ = new Subject<void>();

    readonly expansionPanels = viewChildren(MatExpansionPanel);

    readonly FormControl = FormControl;

    constructor(
        protected readonly _toastService: ToastService,
        protected readonly _translate: TranslateService,
        protected readonly _formBuilder: FormBuilder,
        protected readonly _reportsService: ReportsService
    ) {}

    ngOnInit(): void {
        this.candidates$.next(this.candidates());
        this.localRestaurants$.next(this.restaurants().filter((restaurant) => !restaurant.isBrandBusiness()));
        this._listenToEmailValidationError();
        this.dailyReportsConfigurations$.next(this._initConfigurationsFormForReport(this.dailyReport()) ?? []);
        this.weeklyReportsConfigurations$.next(this._initConfigurationsFormForReport(this.weeklyReport()) ?? []);
        this.updateDailyReportConfigurations$
            .pipe(
                switchMap(() => {
                    const dailyReport = this.dailyReport();
                    return dailyReport
                        ? this._reportsService.updateReport(
                              dailyReport?.copyWith({
                                  configurations: this._mapConfigurationsFormToReportConfiguration(this.dailyReportsConfigurations$.value),
                              })
                          )
                        : of(null);
                })
            )
            .subscribe();

        this.updateWeeklyReportConfigurations$
            .pipe(
                switchMap(() => {
                    const weeklyReport = this.weeklyReport();
                    return weeklyReport
                        ? this._reportsService.updateReport(
                              weeklyReport.copyWith({
                                  configurations: this._mapConfigurationsFormToReportConfiguration(this.weeklyReportsConfigurations$.value),
                              })
                          )
                        : of(null);
                })
            )
            .subscribe();
    }

    toggleDailyReportState(expansionPanelIndex: number): void {
        this._openCloseExpansionPanel(this.dailyReport(), expansionPanelIndex);
        this.dailyReport.update((report) => {
            if (report) {
                report.configurations = this._mapConfigurationsFormToReportConfiguration(this.dailyReportsConfigurations$.value);
                report.toggleReportState();
                this._reportsService.updateReport(report).subscribe();
            }
            return cloneDeep(report);
        });
    }

    toggleWeeklyReportState(expansionPanelIndex: number): void {
        this._openCloseExpansionPanel(this.weeklyReport(), expansionPanelIndex);
        this.weeklyReport.update((report) => {
            if (report) {
                report.configurations = this._mapConfigurationsFormToReportConfiguration(this.weeklyReportsConfigurations$.value);
                report.toggleReportState();
                this._reportsService.updateReport(report).subscribe();
            }
            return cloneDeep(report);
        });
    }

    compareByRestaurantId(restaurant: Restaurant): string {
        return restaurant._id;
    }

    buildRecipientFromText(): (email: string) => string {
        return function (email: string) {
            return email;
        };
    }

    onAddRecipient(): (value: string) => void {
        const showInvalidEmailToast$ = this.showInvalidEmailToast$;
        return function (value: string) {
            const validationErrors = !isValidEmail(new FormControl(value));
            if (validationErrors) {
                showInvalidEmailToast$.next(true);
                throw new Error('Invalid Email');
            }
        };
    }

    addDailyReportConfiguration(): void {
        this.dailyReportsConfigurations$.next(
            this.dailyReportsConfigurations$.value.concat(this._buildConfigurationFormGroup(this.dailyReport()))
        );
    }

    removeDailyReportConfiguration(id: string): void {
        this.dailyReportsConfigurations$.next(this.dailyReportsConfigurations$.value.filter((config) => config.value.id !== id));
        this.updateDailyReportConfigurations$.next();
    }

    addWeeklyReportConfiguration(): void {
        this.weeklyReportsConfigurations$.next(
            this.weeklyReportsConfigurations$.value.concat(this._buildConfigurationFormGroup(this.weeklyReport()))
        );
    }

    removeWeeklyReportConfiguration(id: string): void {
        this.weeklyReportsConfigurations$.next(this.weeklyReportsConfigurations$.value.filter((config) => config.value.id !== id));
        this.updateWeeklyReportConfigurations$.next();
    }

    sendTestReportConfiguration(report: WritableSignal<Report | null>, configurationId: string): void {
        const reportValue = report();
        if (!reportValue) {
            return;
        }
        this._toggleReportIsSendingTestReport(report, configurationId);
        this._reportsService
            .sendTestConfiguration({
                configurationId,
                reportId: reportValue.id,
                reportType: reportValue.type,
            })
            .pipe()
            .subscribe({
                next: () => {
                    this._toggleReportIsSendingTestReport(report, configurationId);
                    this._toastService.openSuccessToast(this._translate.instant('user.reports-settings.test_report_sent'));
                },
                error: (error: any) => {
                    this._toggleReportIsSendingTestReport(report, configurationId);
                    this._toastService.openErrorToast(this._translate.instant('user.reports-settings.test_report_error' + error.message));
                },
            });
    }

    showInvalidFormToast(): void {
        throw new Error('Implement this method');
    }

    showSuccessToast(): void {
        throw new Error('Implement this method');
    }

    isSendButtonDisabled(configuration: FormGroup): boolean {
        return (
            configuration.controls.isCurrentlySendingTestReport.value ||
            configuration.value.recipients.length === 0 ||
            configuration.value.restaurants.length === 0
        );
    }

    private _openCloseExpansionPanel(report: Report | null, expansionPanelIndex: number): void {
        const expansionPanel = this.expansionPanels()[expansionPanelIndex];
        if (expansionPanel && report?.active) {
            expansionPanel.close();
        } else if (expansionPanel && !report?.active) {
            expansionPanel.open();
        }
    }

    private _toggleReportIsSendingTestReport(report: WritableSignal<Report | null>, configurationId: string): void {
        if (report()?.type === ReportType.DAILY_REVIEWS || report()?.type === ReportType.WEEKLY_PERFORMANCE) {
            this.dailyReportsConfigurations$.next(
                this.dailyReportsConfigurations$.value.map((c) => {
                    if (c.value.id === configurationId) {
                        c.controls.isCurrentlySendingTestReport.setValue(!c.controls.isCurrentlySendingTestReport.value);
                    }
                    return c;
                })
            );
        } else {
            this.weeklyReportsConfigurations$.next(
                this.weeklyReportsConfigurations$.value.map((c) => {
                    if (c.value.id === configurationId) {
                        c.controls.isCurrentlySendingTestReport.setValue(!c.controls.isCurrentlySendingTestReport.value);
                    }
                    return c;
                })
            );
        }
    }

    private _listenToEmailValidationError(): void {
        this.showInvalidEmailToast$.pipe(debounceTime(100)).subscribe((shouldShow) => {
            if (shouldShow) {
                this._toastService.openErrorToast(this._translate.instant('user.reports-settings.invalid_email'));
            }
        });
    }

    private _mapConfigurationsFormToReportConfiguration(
        configForms: FormGroup<{
            id: FormControl<string | null>;
            restaurants: FormControl<Restaurant[] | null>;
            recipients: FormControl<string[] | null>;
        }>[]
    ): any[] {
        return configForms.map((config) => ({
            id: config.value.id,
            recipients: config.value.recipients ?? [],
            restaurants: config.value.restaurants?.map((r) => this._mapRestaurantToSimpleRestaurant(r)) ?? [],
            restaurantsIds: config.value?.restaurants?.map((r) => r._id) ?? [],
        }));
    }

    private _mapRestaurantToSimpleRestaurant(restaurant: Restaurant): SimpleRestaurant {
        return {
            id: restaurant._id,
            _id: restaurant._id,
            name: restaurant.name,
            address: restaurant.getFullFormattedAddress(),
            logo: restaurant.logo?.getMediaUrl('small')!,
            type: restaurant.type as BusinessCategory,
            formattedAddress: restaurant.address?.formattedAddress ?? '',
        };
    }

    private _initConfigurationsFormForReport(report: Report | null): FormGroup[] | undefined {
        return report?.configurations.map((configuration) => {
            const form = this._formBuilder.group({
                id: new FormControl<string>(configuration.id!),
                restaurants: new FormControl<Restaurant[]>(
                    configuration.restaurants.map(
                        (r) =>
                            new Restaurant({
                                ...r,
                                logo: new Media({
                                    urls: {
                                        igFit: r.logo,
                                    },
                                }),
                                address: new Address({ formattedAddress: r.address }),
                            })
                    ),
                    Validators.required
                ),
                recipients: new FormControl<string[]>(configuration.recipients, Validators.required),
                isCurrentlySendingTestReport: new FormControl<boolean>(false),
            });

            form.valueChanges
                .pipe(
                    tap((configurationForm) => {
                        if (
                            configurationForm.recipients!.length > 0 &&
                            !this.candidates().includes(configurationForm!.recipients![configurationForm!.recipients!.length - 1])
                        ) {
                            this.candidates$.next(
                                this.candidates().concat(configurationForm.recipients![configurationForm.recipients!.length - 1])
                            );
                        }
                    })
                )
                .subscribe({
                    next: () => {
                        if (report?.type === ReportType.DAILY_REVIEWS || report?.type === ReportType.WEEKLY_PERFORMANCE) {
                            this.updateDailyReportConfigurations$.next();
                        } else {
                            this.updateWeeklyReportConfigurations$.next();
                        }
                    },
                });

            return form;
        });
    }

    private _buildConfigurationFormGroup(report: Report | null): FormGroup {
        const form = this._formBuilder.group({
            id: new FormControl<string | null>(generateDbId().toString()),
            restaurants: new FormControl<Restaurant[] | null>([], Validators.required),
            recipients: new FormControl<string[] | null>([], Validators.required),
            isCurrentlySendingTestReport: new FormControl<boolean>(false),
        });

        form.valueChanges
            .pipe(
                tap((configurationForm) => {
                    if (
                        configurationForm.recipients!.length > 0 &&
                        !this.candidates().includes(configurationForm!.recipients![configurationForm!.recipients!.length - 1])
                    ) {
                        this.candidates$.next(
                            this.candidates().concat(configurationForm.recipients![configurationForm.recipients!.length - 1])
                        );
                    }
                })
            )
            .subscribe({
                next: () => {
                    if (report?.type === ReportType.DAILY_REVIEWS || report?.type === ReportType.WEEKLY_PERFORMANCE) {
                        this.updateDailyReportConfigurations$.next();
                    } else {
                        this.updateWeeklyReportConfigurations$.next();
                    }
                },
            });

        return form;
    }
}
