import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    OnDestroy,
    OnInit,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UploadErrorCode } from '@malou-io/package-dto';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantsService } from ':core/services/restaurants.service';
import { MediaService, UploadClientError, UploadV2Result } from ':modules/media/media.service';
import { CircleProgressComponent } from ':shared/components-v3/circle-progress/circle-progress.component';
import { BodyDragAndDropEventsService } from ':shared/services/body-drag-and-drop-events.service';

import * as GalleryImportMediaActions from './gallery-import-media.actions';
import { selectIsGalleryOpen } from './gallery-import-media.reducer';
import { GalleryImportMediaService } from './gallery-import-media.service';

enum UploadStatus {
    SCHEDULED = 'SCHEDULED',
    UPLOADING = 'UPLOADING',
    UPLOADED = 'UPLOADED',
    FAILED = 'FAILED',
}

interface Upload {
    file: File;

    /** a number between 0 and 1 */
    progress: number | null;

    result: UploadV2Result | null;

    status: UploadStatus;
}

/**
 * This component is supposed to be instanciated once for the whole application.
 *
 * See https://airtable.com/appIqBldyX7wZlWnp/tblbOxMTpexQyxSTV/viwVSdtBlz857nQiA/recdHmIJwdJGtRTaf
 */
@Component({
    selector: 'app-gallery-import-media-v2',
    templateUrl: './gallery-import-media-v2.component.html',
    standalone: true,
    imports: [CircleProgressComponent, MalouSpinnerComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryImportMediaV2Component implements OnDestroy, OnInit {
    readonly uploads = signal<Upload[]>([]);

    readonly totalFiles = computed(() => this.uploads().length);

    /** between 0 and 100 */
    readonly uploadProgress = computed(() => {
        const uploads = this.uploads().filter((u) => u.status !== UploadStatus.FAILED);
        const sum = uploads.reduce((sum_, u) => sum_ + (u.progress ?? 0), 0);
        return (sum / uploads.length) * 100;
    });

    readonly uploadProgress$ = toObservable(this.uploadProgress);

    private _fileInput = viewChild.required<ElementRef<HTMLInputElement>>('fileInput');

    private readonly _errors: WritableSignal<string[]> = signal([]);

    private readonly _dragAndDropEnabled = this._store.selectSignal(selectIsGalleryOpen);

    constructor(
        private readonly _bodyDragAndDropEventsService: BodyDragAndDropEventsService,
        private readonly _galleryImportMediaService: GalleryImportMediaService,
        private readonly _mediaService: MediaService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _translateService: TranslateService
    ) {
        effect(
            () =>
                this._store.dispatch({
                    type: GalleryImportMediaActions.setFilesErrors.type,
                    filesErrors: this._errors(),
                }),
            { allowSignalWrites: true }
        );

        this._bodyDragAndDropEventsService.drop.pipe(takeUntilDestroyed()).subscribe(this._onDrop);
        this._bodyDragAndDropEventsService.dragOver.pipe(takeUntilDestroyed()).subscribe(this._onDragOver);
    }

    ngOnInit(): void {
        this._galleryImportMediaService.elementV2.set(this);
    }

    ngOnDestroy(): void {
        this._galleryImportMediaService.elementV2.set(null);
    }

    /** Can be called from other components */
    public openFilePicker(): void {
        this._fileInput().nativeElement.value = '';
        this._fileInput().nativeElement.click();
    }

    onFileInputChange(_event: Event): void {
        const files: File[] = Array.from(this._fileInput().nativeElement.files ?? []);
        for (const file of files) {
            this._scheduleUpload(file);
        }
    }

    private _onDragOver = (event: DragEvent): void => {
        if (this._dragAndDropEnabled()) {
            event.preventDefault();
        }
    };

    private _onDrop = (event: DragEvent): void => {
        if (!this._dragAndDropEnabled()) {
            return;
        }

        event.preventDefault();
        const droppedFiles: FileList | null = event.dataTransfer?.files as FileList | null;
        if (droppedFiles) {
            for (const file of Array.from(droppedFiles)) {
                this._scheduleUpload(file);
            }
        }
    };

    /**
     * Appends a file to the list of file to upload. This does not mean that the file
     * will be uploaded immediately.
     */
    private _scheduleUpload(file: File): void {
        const upload = { file, progress: 0, result: null, status: UploadStatus.SCHEDULED };
        this.uploads.update((uploads) => [...uploads, upload]);
        this._maybeStartUpload();
    }

    /** Starts a scheduled upload job if possible. Does nothing otherwise. */
    private _maybeStartUpload(): void {
        setTimeout(() => this._maybeStartUploadImpl());
    }

    /** Should not be called directly. Call it via _maybeStartUpload(). */
    private _maybeStartUploadImpl(): void {
        this.uploads.update((uploads: Upload[]): Upload[] => {
            const maxParallelUploads = 2;
            if (uploads.filter((u) => u.status === UploadStatus.UPLOADING).length >= maxParallelUploads) {
                // already enough uploads in progress
                return uploads;
            }

            const upload = uploads.find((u) => u.status === UploadStatus.SCHEDULED);
            if (!upload) {
                // nothing to start

                if (!uploads.some((u) => u.status === UploadStatus.UPLOADING)) {
                    // all the uploads are finished: display errors and clear the list
                    this._finalize(uploads);
                    return [];
                }
                return uploads;
            }

            this._mediaService
                .uploadV2({
                    file: upload.file,
                    onProgress: (progress) => this._updateProgress(upload.file, progress),
                    queryParams: { restaurantId: this._restaurantsService.currentRestaurant._id },
                })
                .then((result) => this._onUploadEnd(upload.file, result));

            return [...uploads.filter((u) => u !== upload), { ...upload, status: UploadStatus.UPLOADING }];
        });
    }

    private _finalize(uploads: Upload[]) {
        // TODO: translate error codes
        this._errors.set(
            uploads
                .filter((u) => u.result?.success !== true)
                .map((upload): string | null => {
                    const { result } = upload;
                    if (!result) {
                        return null;
                    }
                    if (result.success) {
                        return null;
                    }

                    const reasonKey = {
                        [UploadClientError.NETWORK_ERROR]: 'gallery.upload_errors.network_error',
                        [UploadErrorCode.INVALID_FILE]: 'gallery.upload_errors.invalid_file',
                    }[result.errorCode];

                    const reason = this._translateService.instant(reasonKey);
                    return this._translateService.instant('gallery.upload_error', { fileName: upload.file.name, reason });
                })
                .filter((error): error is string => error !== null)
        );
    }

    private _onUploadEnd(file: File, result: UploadV2Result) {
        this.uploads.update((uploads: Upload[]): Upload[] => {
            const upload = uploads.find((u) => u.file === file);
            if (!upload) {
                throw new Error();
            }

            this._maybeStartUpload();

            if (result.success) {
                this._mediaService.getMediumById(result.mediaId).subscribe(({ data: media }) => {
                    this._store.dispatch({
                        type: GalleryImportMediaActions.setCreatedMedia.type,
                        createdMedia: [media],
                    });
                });
            }

            return [
                ...uploads.filter((u) => u !== upload),
                {
                    ...upload,
                    result,
                    status: result.success ? UploadStatus.UPLOADED : UploadStatus.FAILED,
                },
            ];
        });
    }

    /** progress is a number between 0 and 1 */
    private _updateProgress(file: File, progress: number): void {
        this.uploads.update((uploads) =>
            uploads.map((upload) => {
                if (upload.file === file) {
                    return { ...upload, progress };
                }
                return upload;
            })
        );
    }
}
