import lodash from 'lodash';

export type Environment = 'local' | 'development' | 'staging' | 'production';

type NotificationTestRestaurantIds = {
    [key in Environment]: string[];
};

const NOTIFICATION_TEST_RESTAURANT_IDS: NotificationTestRestaurantIds = {
    local: [],
    development: [
        '651444606d6bef2863015fbc', // "La bonne fourchette - meilleur brunch"
        '6556396d235e32dcbbed77dd', // "Osteria Parigino (Démo)"
        '65719e39827a77655ea28f2d', // "La Chaumine"
        '6268039d8de2974d89ebd445', // "Churrasqueira SainMor"
        '65563a78235e32dcbbedad12', // "Les amis d'Alex"
    ],
    staging: [
        '635a7e0a2703cd4866cb848f', // "Les Copains Suisses"
        '66585addeb0afa1b151a73f7', // "La bonne fourchettas"
        '641aba47bce6983e660f3caf', // "Bon Gueletonnn"
        '6279027e2ac792d0f6362d99', // "Le Phénix Orientale"
        '63a1be2e852b267d29091eb0', // "Osteria Parigino (Démo)"
        '6650bb8f24db48c14d5302eb', // "La Chaumine"
        '650429e3b1060bba3d3402ab', // "Churrasqueira SainMor"
        '63ff85fb96614c9820e641a0', // "Les amis d'Alex"
    ],
    production: [],
};

export function canUpdateSpecialHoursFromNotification(env: Environment, restaurantId: string): boolean {
    const restaurantIds = NOTIFICATION_TEST_RESTAURANT_IDS[env];
    if (lodash.isNil(restaurantIds) || lodash.isNil(restaurantId)) {
        return false;
    }
    if (!restaurantIds?.length) {
        return true;
    }
    return restaurantIds.includes(restaurantId);
}
