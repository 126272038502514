<div class="rounded-[5px] border border-malou-color-border-primary bg-white">
    <div class="flex items-center gap-x-2 px-3 py-4">
        @if (profilePicture(); as profilePicture) {
            <div class="flex h-[29px] w-[29px] items-center justify-center rounded-full bg-malou-color-gradient-instagram">
                <img class="h-[25px] w-[25px] rounded-full" [src]="profilePicture" />
            </div>
        }
        <div class="font-[Helvetica] text-[13px] font-bold text-malou-color-text-1">{{ usernameWithoutAt() }}</div>
    </div>

    <div
        class="relative"
        id="instagramPreview"
        [ngClass]="{ 'cursor-crosshair': !isCurrentMediaAVideo() && !readonly() && medias().length > 0 }"
        (click)="!readonly() && !isCurrentMediaAVideo() && medias().length > 0 && openAddTagAccount($event)"
        #sliderContainer>
        @if (medias().length > 0) {
            <div class="flex overflow-hidden" #slider>
                @for (media of medias(); let index = $index; track media.id) {
                    <app-image-viewer
                        class="min-w-full"
                        [ngStyle]="aspectRatioStyle()"
                        [id]="IMG_ID_PREFIX + index"
                        [data]="{
                            thumbnailUrl: media.thumbnail1024OutsideUrl,
                            dimensions: media.thumbnail1024OutsideDimensions,
                            transformData: media.transformData,
                            videoOptions:
                                media.type === MediaType.VIDEO
                                    ? { videoUrl: media.videoUrl, videoDimensions: media.videoDimensions }
                                    : undefined,
                        }"></app-image-viewer>
                }
            </div>
            @if (isCarousel()) {
                @if (currentMediaIndex() > 0) {
                    <div
                        class="carousel-control absolute left-4 top-1/2 z-10 flex h-6 w-6 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white opacity-80"
                        (click)="previousMedia(); $event.stopPropagation()">
                        <mat-icon class="!h-[14px] !w-[14px]" color="transparent" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                    </div>
                }
                @if (currentMediaIndex() < medias().length - 1) {
                    <div
                        class="carousel-control absolute right-4 top-1/2 z-10 flex h-6 w-6 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white opacity-80"
                        (click)="nextMedia(); $event.stopPropagation()">
                        <mat-icon class="!h-[14px] !w-[14px]" color="transparent" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                    </div>
                }
                <div class="absolute bottom-4 left-1/2 flex -translate-x-1/2 gap-1">
                    @for (media of medias(); let index = $index; track index) {
                        <div
                            class="h-[6px] w-[6px] rounded-full bg-white"
                            [ngClass]="{ 'opacity-40': index !== currentMediaIndex() }"></div>
                    }
                </div>
            }
        } @else {
            <div class="flex aspect-square items-center justify-center bg-malou-color-background-dark" [id]="IMG_ID_PREFIX + 0">
                <img class="w-full max-w-[250px]" [src]="'default_post' | imagePathResolver" />
            </div>
        }
        @if (addTagAccount() && !readonly()) {
            <app-tag-account-v2
                class="absolute"
                [ngStyle]="addTagAccountPosition()"
                [tagControl]="tagControl"
                [searching]="searching()"
                [foundAccount]="foundAccount()"
                [tagPosition]="tagPosition()"
                [showArrowAbove]="showArrowAbove()"
                [userTagsHistory]="userTagsHistory()"
                (onAccountSelected)="addAccount($event)"
                (onClose)="closeAddTagAccount()"></app-tag-account-v2>
        }

        @if (metaGraphApiBugIsResolved || !isCurrentMediaAVideo()) {
            @if (isCurrentMediaAVideo()) {
                @if (userTags().length === 0) {
                    @if (!readonly()) {
                        <div
                            class="absolute bottom-3 left-3 flex cursor-pointer items-center gap-x-1 rounded-[5px] bg-malou-color-background-text-1 p-2 text-white"
                            (click)="openAddTagAccount($event)">
                            <mat-icon class="!h-[13px] !w-[13px] fill-white" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                            <div class="malou-text-12--regular">
                                {{
                                    'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram.tag_an_account'
                                        | translate
                                }}
                            </div>
                        </div>
                    }
                } @else {
                    <div
                        class="absolute bottom-3 left-3 flex items-center gap-x-1 rounded-[5px] bg-malou-color-background-text-1 p-2 text-white">
                        <div class="malou-text-12--regular">{{ '@' + userTags()[0].username }}</div>
                        <mat-icon
                            class="!h-3 !w-3 fill-white"
                            [ngClass]="{ 'cursor-pointer': !readonly() }"
                            [svgIcon]="SvgIcon.CROSS"
                            (click)="!readonly() && removeAccount(userTags()[0].username)"></mat-icon>
                    </div>
                }
            } @else {
                @for (userTag of userTags(); track userTag) {
                    @let isOnTheLeftSide = userTagIsOnTheLeftSide | applyPure: userTag;
                    <div
                        class="user-tag absolute z-[5] cursor-default"
                        [ngStyle]="getUserTagPositionInPx | applyPure: userTag"
                        [ngClass]="{
                            'translate-x-[-100%]': !isOnTheLeftSide,
                        }"
                        (click)="$event.stopPropagation()">
                        <div class="malou-box-shadow relative flex items-center gap-x-2 rounded-[8px] bg-white p-3.5">
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                            <span class="malou-text-12--semibold">{{ userTag.username }}</span>
                            <div
                                class="malou-box-shadow absolute top-[-9px] grid h-5 w-5 place-items-center rounded-full bg-white"
                                [ngClass]="{
                                    'left-[-9px]': isOnTheLeftSide,
                                    'right-[-9px]': !isOnTheLeftSide,
                                    'cursor-pointer': !readonly(),
                                }"
                                (click)="!readonly() && removeAccount(userTag.username); $event.stopPropagation()">
                                <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                            </div>
                        </div>
                    </div>
                }
            }
        }

        @if (!addTagAccount() && !isCurrentMediaAVideo() && medias().length > 0 && !readonly()) {
            <div
                class="malou-text-10 absolute z-10 translate-x-[-50%] translate-y-[-140%] transform whitespace-nowrap rounded-[5px] bg-white/80 p-2 text-malou-color-text-1"
                id="userTagTooltip">
                {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram.user_tag_tooltip' | translate }}
            </div>
        }
    </div>

    <div class="flex flex-col gap-6 px-3 py-4 font-[Helvetica] text-[13px] text-malou-color-text-1">
        <div class="flex justify-between">
            <div class="flex gap-x-3">
                <img [src]="'like' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
                <img [src]="'comment' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
                <img [src]="'send' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            </div>
            <div>
                <img [src]="'save' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            </div>
        </div>

        <div class="flex flex-col gap-[1px]">
            <div class="font-bold">
                {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram.likes' | translate }}
            </div>
            <div>
                <span class="mr-3 font-bold">{{ usernameWithoutAt() }}</span>
                <span class="font-normal" [innerHTML]="formattedText() | htmlTag"></span>
            </div>
        </div>
    </div>
</div>
