import { Component, computed, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';

import { LocalStorage } from ':core/storage/local-storage';
import { SpecialDatePeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-schedule-history-row',
    standalone: true,
    imports: [MatIconModule, ApplySelfPurePipe, TranslateModule],
    templateUrl: './schedule-history-row.component.html',
    styleUrl: './schedule-history-row.component.scss',
})
export class ScheduleHistoryRowComponent {
    readonly SvgIcon = SvgIcon;
    readonly LANG = LocalStorage.getLang();

    readonly schedule = input.required<SpecialDatePeriod>();

    readonly _translateService = inject(TranslateService);

    readonly hoursInterval = computed(() => {
        const schedule = this.schedule();
        if (!schedule.startDate) {
            return '';
        }
        if (!schedule.periods.length) {
            return '';
        }
        if (schedule.isClosed) {
            return this._translateService.instant('common.closed');
        }
        const periods = schedule.periods.filter((period) => period.openTime && period.closeTime);
        const uniqPeriods = uniqBy(periods, (period) => period.openTime! + period.closeTime!);
        const formattedPeriods = uniqPeriods
            .filter((period) => period.openTime && period.closeTime)
            .map((period) => `${period.openTime} - ${period.closeTime}`)
            .join(' / ');
        if (periods.length === 0) {
            return '';
        }
        return formattedPeriods;
    });
}
