<div class="malou-dialog">
    <div class="malou-dialog__header gap-x-5">
        <div class="flex flex-col">
            <span>{{
                'statistics.e_reputation.reviews_analysis.topic_related_review' | translate: { topic: topicNameInCurrentLang() }
            }}</span>
            @if (data.reviewAnalysesFilter.startDate && data.reviewAnalysesFilter.endDate) {
                <span class="flex gap-x-0.5 text-malou-color-text-2">
                    <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                    <span class="malou-text-12--regular mt-[1px] italic">
                        {{ data.reviewAnalysesFilter.startDate | date: 'dd.MM.yyyy' }} {{ 'common.to' | translate }}
                        {{ data.reviewAnalysesFilter.endDate | date: 'dd.MM.yyyy' }}
                    </span>
                </span>
            }
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    @if (areReviewsLoading()) {
        <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
    } @else {
        <ng-container [ngTemplateOutlet]="desktopView"></ng-container>
        <ng-container [ngTemplateOutlet]="mobileView"></ng-container>
    }
</div>

<ng-template #desktopView>
    <div class="malou-dialog__body flex grow gap-x-5 !overflow-hidden !pb-[26px] md:hidden">
        <div class="hide-scrollbar relative flex-2">
            <ng-container [ngTemplateOutlet]="reviewsTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #mobileView>
    <div class="malou-dialog__body hidden grow gap-x-5 !overflow-hidden !pb-[26px] md:flex">
        <div class="flex-1 grow">
            <div class="hide-scrollbar overflow-y-auto">
                <ng-container [ngTemplateOutlet]="reviewsTemplate"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reviewsTemplate>
    <mat-tab-group
        class="custom-tab-group h-full w-full"
        animationDuration="5ms"
        mat-align-tabs="start"
        [selectedIndex]="selectedTabIndex()">
        <mat-tab [disabled]="positiveReviewCount() === 0">
            <ng-template class="malou-text-14--bold" mat-tab-label>
                {{ 'statistics.e_reputation.reviews_analysis.positive' | pluralTranslate: PluralCategory.MANY }} &nbsp;
                <span class="malou-text-14--regular malou-color-text-2 italic"> ({{ positiveReviewCount() }})</span>
            </ng-template>
            <ng-container
                [ngTemplateOutlet]="segmentTabTemplate"
                [ngTemplateOutletContext]="{
                    reviews: positiveInsightsReviews(),
                }"></ng-container>
        </mat-tab>
        <mat-tab [disabled]="negativeReviewCount() === 0">
            <ng-template class="malou-text-14--bold" mat-tab-label>
                {{ 'statistics.e_reputation.reviews_analysis.negative' | pluralTranslate: PluralCategory.MANY }} &nbsp;
                <span class="malou-text-14--regular malou-color-text-2 italic"> ({{ negativeReviewCount() }})</span>
            </ng-template>
            <ng-container
                [ngTemplateOutlet]="segmentTabTemplate"
                [ngTemplateOutletContext]="{
                    reviews: negativeInsightsReviews(),
                }"></ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template let-reviews="reviews" #segmentTabTemplate>
    <div class="mr-2 mt-2 flex flex-col gap-y-4">
        @for (review of reviews; track $index) {
            <div>
                <ng-container [ngTemplateOutlet]="reviewTemplate" [ngTemplateOutletContext]="{ review }"></ng-container>
            </div>
        }
    </div>
</ng-template>

<ng-template let-review="review" #reviewTemplate>
    <div class="flex flex-col rounded-[10px] border !border-malou-color-border-primary px-6 py-4">
        @let reviewerDisplayName = review.reviewer?.displayName ?? ('reviews.anonymous' | translate);
        @if (isFromAggregatedStatistics) {
            <app-restaurant-header-for-review-preview
                [restaurant]="restaurantsById[review.restaurantId]"></app-restaurant-header-for-review-preview>
        }
        <div class="malou-card__header flex flex-col !pb-0 pl-0">
            <div class="flex w-full">
                <div class="malou-card__image-container">
                    <img class="avatar" alt="avatar" [lazyLoad]="reviewerDisplayName | avatar" />
                    <app-platform-logo
                        imgClasses="w-8.5 h-8.5 relative left-[-8px] top-[-27px] object-cover !rounded-full"
                        [logo]="review.key"
                        [withLazyLoading]="true"></app-platform-logo>
                </div>
                <div class="flex w-[30%] grow cursor-pointer flex-col justify-center" (click)="goToReview(review)">
                    <div class="malou-text-12--bold truncate">{{ reviewerDisplayName }}</div>
                    <div class="malou-text__card-subtitle">{{ (review.socialCreatedAt | DateToStringPipe) || '-' }}</div>
                </div>

                <div class="malou-card__action-button">
                    <app-star-gauge class="px-4" [stars]="review.rating"></app-star-gauge>
                </div>
            </div>
        </div>
        <div class="malou-card__body-text mt-2">
            @if (shouldShowTranslation()(review)) {
                <div [innerHTML]="review | reviewText: { showTranslation: true, language: currentLang() }"></div>
            } @else {
                <div class="font-normal" [innerHTML]="review | applySelfPure: 'getHighlightedText'"></div>
            }

            <ng-container [ngTemplateOutlet]="reviewMenuItemsTemplate" [ngTemplateOutletContext]="{ review }"></ng-container>

            @if (hasTranslationsAvailable()(review)) {
                <app-review-translations
                    [review]="review"
                    [restaurant]="restaurantsById[review.restaurantId]"
                    [showTranslation]="shouldShowTranslation()(review)"
                    (showTranslatedText)="onShowTranslatedText(review.id, $event)"></app-review-translations>
            }

            <div class="flex">
                @if (hasTranslationsAvailable()(review) && shouldShowTranslation()(review)) {
                    <span class="malou-text-10 italic">{{ 'reviews.review_analyses.show_original_text' | translate }}</span>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #skeletonTemplate>
    <div class="mx-10 flex h-full flex-col gap-y-2.5">
        <app-skeleton skeletonClass="secondary-bg h-[30px] w-[20%]" [useContainer]="false"></app-skeleton>
        @for (_ of 4 | toRange; track $index) {
            <app-skeleton skeletonClass="secondary-bg h-[120px]" [useContainer]="false">
                <ng-template #nestedSkeletonTemplate>
                    <div class="flex h-full flex-col justify-between p-5">
                        <div class="flex justify-between">
                            <app-skeleton class="!h-[15px] !w-[40%] !rounded-3xl"></app-skeleton>
                            <app-skeleton class="!h-[15px] !w-[20%] !rounded-3xl"></app-skeleton>
                        </div>
                        <app-skeleton class="!h-[45px]" skeletonClass="!w-full !h-[45px]"></app-skeleton>
                        <app-skeleton class="!h-[15px] !w-[20%] !rounded-3xl"></app-skeleton>
                    </div>
                </ng-template>
            </app-skeleton>
        }
    </div>
</ng-template>

<ng-template let-review="review" #reviewMenuItemsTemplate>
    @if (review | getMenuItemReviews; as menuItems) {
        <div class="mb-2">
            <div>
                <div class="grid-wrapper-menu-review gap-1.5">
                    @for (
                        itemReview of menuItems | displayMenuItems: { isFold: false, maxMenuItemShown: MAX_MENU_ITEM_SHOWN };
                        track itemReview
                    ) {
                        <div class="mt-1">
                            <div class="flex items-end">
                                <mat-icon
                                    class="smaller-icon mr-2 !h-[16px] !w-[16px]"
                                    svgIcon="{{ itemReview.rating ? 'thumb-up' : 'thumb-down' }}"
                                    [ngClass]="itemReview.rating ? 'thumb-up' : 'thumb-down'">
                                </mat-icon>
                                <span class="malou-text-10--regular flex">
                                    {{ itemReview.name }}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</ng-template>
