import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, forwardRef, inject, signal, untracked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import { AspectRatio, MediaType, PublicationType, TransformDataComputerService } from '@malou-io/package-utils';

import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { MediaUploaderService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/media-uploader.service';
import { SocialPostMediasComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/social-post-medias.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';

import { AreaRatio, ImageEditorComponent } from './components/image-editor/image-editor.component';
import { ImageTransformButtonsComponent, RotationDirection } from './components/image-transform-buttons/image-transform-buttons.component';

export interface EditMediaModalDialogData {
    medias: EditionMedia[];
    selectedMediaId?: string;
}

export interface EditMediaModalDialogResult {
    medias: EditionMedia[];
}

const ROTATION_INCREMENT_IN_DEGREES = 90;

@Component({
    selector: 'app-edit-media-modal',
    templateUrl: './edit-media-modal.component.html',
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        forwardRef(() => SocialPostMediasComponent),
        NgTemplateOutlet,
        ImageEditorComponent,
        ImageTransformButtonsComponent,
    ],
    providers: [MediaUploaderService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMediaModalComponent {
    private readonly _matDialogRef = inject<MatDialogRef<EditMediaModalComponent, EditMediaModalDialogResult>>(MatDialogRef);
    private readonly _matDialogData = inject<EditMediaModalDialogData>(MAT_DIALOG_DATA);

    readonly medias = signal(cloneDeep(this._matDialogData.medias));
    readonly selectedMediaId = signal<string | undefined>(this._matDialogData.selectedMediaId ?? this._matDialogData.medias[0].id);
    readonly isFirstMediaSelected = computed(() => this.selectedMediaId() === this.medias()[0].id);
    readonly selectedMedia = computed(() => {
        const medias = this.medias();
        const selectedMediaId = this.selectedMediaId();
        return medias.find((m) => m.id === selectedMediaId);
    });
    readonly selectedMediaAspectRatio = computed(() => {
        const medias = this.medias();
        const selectedMediaId = this.selectedMediaId();
        return medias.find((m) => m.id === selectedMediaId)?.transformData.aspectRatio;
    });

    transformAreaOutput: undefined | AreaRatio;

    readonly PublicationType = PublicationType;
    readonly ButtonStyle = ButtonStyle;
    readonly MediaType = MediaType;

    constructor() {
        this._checkSelectedMediaIdExistenceOnMediasChange();
    }

    onClose(): void {
        this._matDialogRef.close();
    }

    onPrimaryClick(): void {
        this._saveSelectedMedia();
        this._matDialogRef.close({ medias: this.medias() });
    }

    onSecondaryClick(): void {
        this._matDialogRef.close();
    }

    onMediaClicked(id: string): void {
        this._saveSelectedMedia();
        const index = this.medias().findIndex((media) => media.id === id);
        if (index !== -1) {
            this.selectedMediaId.set(id);
        }
    }

    onTransformAreaChange(event: AreaRatio): void {
        this.transformAreaOutput = event;
    }

    /**
     * Change aspect ratio for all medias (carousel rule)
     */
    onAspectRatioChange(aspectRatio: AspectRatio): void {
        const currentAspectRatio = this.medias()[0].transformData.aspectRatio;
        if (currentAspectRatio === aspectRatio) {
            return;
        }
        const medias = [...this.medias()].map((media) => {
            const is90DegreesRotated = media.transformData.rotationInDegrees % 180 === 90;
            const originalAspectRatio = is90DegreesRotated ? 1 / media.aspectRatio : media.aspectRatio;
            const targetAspectRatio =
                TransformDataComputerService.getAspectRatioNumberFor(PublicationType.POST, aspectRatio) ?? originalAspectRatio;
            const transformArea = TransformDataComputerService.computeArea(originalAspectRatio, targetAspectRatio);
            media.transformData = {
                aspectRatio,
                rotationInDegrees: media.transformData.rotationInDegrees,
                ...transformArea,
            };
            return media;
        });
        this.medias.set(medias);
    }

    /**
     * Only change rotation for selected media
     */
    onRotationDirectionChange(rotationDirection: RotationDirection): void {
        const selectedMedia = this.selectedMedia();
        if (!selectedMedia) {
            console.warn('No Selected media in onAspectRatioChange');
            return;
        }
        const increment =
            rotationDirection === RotationDirection.CLOCKWISE ? ROTATION_INCREMENT_IN_DEGREES : -ROTATION_INCREMENT_IN_DEGREES;
        const currentRotation = selectedMedia.transformData.rotationInDegrees;
        const newRotation = this._computePositiveModulo(currentRotation + increment, 360);
        const isImage90DegreesRotated = newRotation % 180 === 90;
        const originalAspectRatio = isImage90DegreesRotated ? 1 / selectedMedia.aspectRatio : selectedMedia.aspectRatio;
        const targetAspectRatio =
            TransformDataComputerService.getAspectRatioNumberFor(PublicationType.POST, selectedMedia.transformData.aspectRatio) ??
            originalAspectRatio;
        const transformArea = TransformDataComputerService.computeArea(originalAspectRatio, targetAspectRatio);
        selectedMedia.transformData = {
            aspectRatio: selectedMedia.transformData.aspectRatio,
            rotationInDegrees: newRotation,
            ...transformArea,
        };
        this.medias.update((medias) => medias.map((m) => (m.id === selectedMedia.id ? selectedMedia : m)));
    }

    private _checkSelectedMediaIdExistenceOnMediasChange(): void {
        effect(
            () => {
                const selectedMediaId = untracked(() => this.selectedMediaId());
                const index = this.medias().findIndex((media) => media.id === selectedMediaId);
                if (index === -1) {
                    this.selectedMediaId.set(this.medias()[0]?.id);
                }
            },
            { allowSignalWrites: true }
        );
    }

    private _saveSelectedMedia(): void {
        const selectedMedia = this.selectedMedia();
        if (!selectedMedia) {
            return;
        }
        if (this.transformAreaOutput) {
            selectedMedia.transformData = {
                aspectRatio: selectedMedia.transformData.aspectRatio,
                rotationInDegrees: selectedMedia.transformData.rotationInDegrees,
                ...this.transformAreaOutput,
            };
            this.transformAreaOutput = undefined;
        }
        this.medias.update((medias) => medias.map((m) => (m.id === selectedMedia.id ? selectedMedia : m)));
    }

    private _computePositiveModulo(value: number, modulo): number {
        return ((value % modulo) + modulo) % modulo;
    }
}
