@if (isLoading()) {
    <app-skeleton skeletonClass="!h-[100px] secondary-bg"></app-skeleton>
} @else {
    <div class="malou-simple-card h-25 p-5">
        <div class="flex w-full justify-between">
            <div class="flex items-center gap-4" [matTooltip]="valueTooltip()">
                <ng-container [ngTemplateOutlet]="gradientCircleTemplate" [ngTemplateOutletContext]="{ icon }"></ng-container>
                <div class="flex flex-col">
                    <div class="flex">
                        @if (error()) {
                            <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
                        } @else {
                            <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
                            <ng-container [ngTemplateOutlet]="evolutionTemplate"></ng-container>
                        }
                    </div>
                    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
                    <ng-container [ngTemplateOutlet]="comparisonTemplate"></ng-container>
                </div>
            </div>
        </div>
    </div>
}

<ng-template let-icon="icon" #gradientCircleTemplate>
    <div class="malou-background-gradient-5 flex h-12.5 w-12.5 shrink-0 items-center justify-center rounded-full">
        <mat-icon class="malou-color-white !h-4 !w-4" [svgIcon]="icon()"></mat-icon>
    </div>
</ng-template>

<ng-template #valueTemplate>
    <span class="malou-text-40--bold malou-color-text-1 mr-2 leading-none">
        {{ currentValue() }}
    </span>
</ng-template>

<ng-template #titleTemplate>
    <div class="flex">
        @if (currentValueUnit() && !error()) {
            <span class="malou-text-12--medium malou-color-text-2 mr-1">{{ currentValueUnit() }}.</span>
        }
        <span class="malou-text-12--medium malou-color-text-2 whitespace-nowrap">{{ title() }}</span>
    </div>
</ng-template>

<ng-template #comparisonTemplate>
    <div class="flex">
        <span class="malou-text-10--medium malou-color-text-2 whitespace-nowrap">
            ({{ previousValue() }} {{ comparisonPeriodTitle() }})
        </span>
    </div>
</ng-template>

<ng-template #evolutionTemplate>
    <div class="flex" [matTooltip]="numberEvolutionTooltip()">
        @if (currentValueRaw()) {
            <app-number-evolution
                [value]="diffValue() ?? null"
                [size]="'medium'"
                [displayedValue]="diffDisplayedValue()"
                [reverseArrow]="reverseArrow()">
            </app-number-evolution>
        }
    </div>
</ng-template>

<ng-template #errorTemplate>
    <span class="malou-text-40--bold malou-color-text-1 mr-2 leading-none"> - </span>
    <div class="malou-status--error pdf-hidden mt-2.5" [matTooltip]="error() | statisticsHttpError">
        <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
    </div>
</ng-template>
