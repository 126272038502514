import { z } from 'zod';

import { MediaType, PublicationType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';
import { MediaDimensionDto, MediaTransformDataDto } from './media.dto';

export const getMediaForEditionPathValidator = z.object({
    mediaId: objectIdValidator,
});

export type GetMediaForEditionPathDto = z.infer<typeof getMediaForEditionPathValidator>;

export const getMediaForEditionBodyValidator = z.object({
    publicationType: z.nativeEnum(PublicationType),
});

export type GetMediaForEditionBodyDto = z.infer<typeof getMediaForEditionBodyValidator>;

export type GetMediaForEditionResponseDto = {
    id: string;
    thumbnail1024OutsideUrl: string;
    thumbnail256OutsideUrl: string;
    thumbnail256OutsideDimensions?: MediaDimensionDto;
    thumbnail1024OutsideDimensions?: MediaDimensionDto;
    aspectRatio: number;
    transformData: MediaTransformDataDto;
    aiDescription?: string | null;
} & (
    | {
          type: MediaType.PHOTO;
      }
    | {
          type: MediaType.VIDEO;
          videoUrl: string;
          videoDimensions: MediaDimensionDto;

          /** the duration of the video in seconds */
          duration?: number | undefined;
      }
);
