import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { indexOf } from 'lodash';
import { DateTime, Interval } from 'luxon';

import { Day, DayMonthYear, DAYS, ROI_HIDDEN_FIRST_DAYS_NUMBER, TimeInMilliseconds } from '@malou-io/package-utils';

import { days } from ':core/constants';
import { LocalStorage } from ':core/storage/local-storage';
import { ViewBy } from ':shared/enums/view-by.enum';
import { DayYear, MonthYear, TimePeriod, WeekYear } from ':shared/models';

export enum MonthStringSize {
    short = 'short',
    long = 'long',
}

export interface WeekRange {
    start: Date;
    end: Date;
    days: Date[];
    realStart?: Date;
    realEnd?: Date;
}

export interface Month {
    start: Date;
    end: Date;
    days: Date[];
}

export function getDayMonthYearFromDate(date: Date): DayMonthYear {
    return {
        day: DateTime.fromJSDate(date).day,
        month: DateTime.fromJSDate(date).month,
        year: DateTime.fromJSDate(date).year,
    };
}

export function subtractDay(day: number, date: Date): Date {
    return new Date(date.setDate(date.getDate() - day));
}

export function addDay(day: number, date: Date): Date {
    const tempDate = new Date(date.getTime());
    return new Date(tempDate.setDate(tempDate.getDate() + day));
}

export function addHours(hoursNb: number, date: Date): Date {
    return DateTime.fromJSDate(date).plus({ hours: hoursNb }).toJSDate();
}

export function addMinutes(minutesNb: number, date: Date): Date {
    return DateTime.fromJSDate(date).plus({ minutes: minutesNb }).toJSDate();
}

export function weeksBetween(d1: Date, d2: Date): number {
    if (!d1 || !d2) {
        return 0;
    }
    return Math.round((d2.getTime() - d1.getTime()) / TimeInMilliseconds.WEEK);
}

export function daysBetween(d1: Date, d2: Date): number {
    if (!d1 || !d2) {
        return 0;
    }
    return Math.abs(Math.round((d2?.getTime() - d1?.getTime()) / TimeInMilliseconds.DAY));
}

// Voir https://en.wikipedia.org/wiki/ISO_week_date
export function getWeekAndYearNumber(jsDate: Date): { year: number; week: number } {
    const luxonDate = DateTime.fromJSDate(jsDate);
    return {
        year: luxonDate.weekYear,
        week: luxonDate.weekNumber,
    };
}

export function getDayAndYearNumber(jsDate: Date): { year: number; day: number } {
    const luxonDate = DateTime.fromJSDate(jsDate);
    return {
        year: luxonDate.year,
        day: luxonDate.ordinal,
    };
}

export function getMonthAndYearNumber(jsDate: Date): { year: number; month: number } {
    const luxonDate = DateTime.fromJSDate(jsDate);
    return {
        year: luxonDate.year,
        month: luxonDate.month,
    };
}

export function getDaysYearRange(start: Date, end: Date): DayYear[] {
    const result: DayYear[] = [];
    let currentDate = DateTime.fromJSDate(start);

    while (currentDate <= DateTime.fromJSDate(end)) {
        result.push(getDayAndYearNumber(currentDate.toJSDate()));
        currentDate = currentDate.plus({ days: 1 });
    }
    return result;
}

export function getMonthsYearRange(start: Date, end: Date): MonthYear[] {
    const startDate = DateTime.fromJSDate(start);
    const endDate = DateTime.fromJSDate(end);

    const result: MonthYear[] = [];

    const diff = Interval.fromDateTimes(startDate, endDate).splitBy({ days: 29 });
    diff.forEach((interval) => {
        result.push({ month: interval.start.month, year: interval.start.year });
    });
    return result;
}

export function getMonday(jsDate: Date): Date {
    return DateTime.fromJSDate(jsDate).startOf('day').startOf('week').toJSDate();
}

export function isBetween(jsDate: Date, startDate: Date, endDate: Date, openedRange = true): Boolean {
    if (!startDate && !endDate) {
        return true;
    }
    return openedRange
        ? DateTime.fromJSDate(jsDate) >= DateTime.fromJSDate(startDate) && DateTime.fromJSDate(jsDate) <= DateTime.fromJSDate(endDate)
        : DateTime.fromJSDate(jsDate) > DateTime.fromJSDate(startDate) && DateTime.fromJSDate(jsDate) < DateTime.fromJSDate(endDate);
}

export function getDateOfISOWeek(w: number, y: number): Date {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
}

export function createDate(date: string | Date | WeekYear): Date {
    if (date instanceof Date) {
        return date;
    }
    if (typeof date === 'string') {
        return DateTime.fromISO(date).toJSDate();
    }
    if (typeof date?.year === 'number' && typeof date?.week === 'number') {
        return getDateOfISOWeek(date.week, date.year);
    }
    throw new Error('Invalid date 1');
}

export function createDateFromMalouDate(date: DayMonthYear): Date {
    return new Date(date.year, date.month - 1, date.day);
}

export function compareWeekYear(a: WeekYear, b: WeekYear): number {
    if (a.year - b.year !== 0) {
        return a.year - b.year;
    }
    return a.week - b.week;
}

export const zeroPad = (num: number, places: number): string => String(num).padStart(places, '0');

/**
 * Creates a new Date object with the client timezone difference.
 * !!! Prefer to not use it, it's only useful in some edge cases where the date is already badly handled in the backend.
 *
 * Useful to convert a backend date that are in utc to the client timezone.
 * Ex, if the client is in France when the offset is +2 :
 *      this will transform 2000-01-01T10:00:00.000Z to 2000-01-01T08:00:00.00Z
 */
export const createDateWithClientTimeZoneDifference = (date: Date): Date => {
    const offsetInMinutes = DateTime.fromJSDate(date).offset;
    return DateTime.fromJSDate(date).minus({ minutes: offsetInMinutes }).toJSDate();
};

export function isSameDay(day1: Date, day2: Date): boolean {
    return (
        day1 &&
        day2 &&
        day1.getDate() === day2.getDate() &&
        day1.getMonth() === day2.getMonth() &&
        day1.getFullYear() === day2.getFullYear()
    );
}

export function isToday(date: Date): boolean {
    return isSameDay(date, new Date());
}

export function isTodayForMalouDate(day: DayMonthYear): boolean {
    const date = createDateFromMalouDate(day);
    return isToday(date);
}

export function isAfterToday(date: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0);
    return date.getTime() >= today.getTime();
}

export function isAfterTodayForMalouDate(day: DayMonthYear): boolean {
    const date = createDateFromMalouDate(day);
    return isAfterToday(date);
}

export function isBeforeToday(date?: Date | null): boolean {
    return !date || new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
}

export function compareDates(day: DayMonthYear): number {
    const date = createDateFromMalouDate(day);
    if (isToday(date)) {
        return 0;
    } else {
        return date.getTime() > new Date().getTime() ? 1 : -1;
    }
}

export function isInDayList(day: Date, dayList: Date[]): boolean {
    return !!dayList.filter((d) => isSameDay(d, day)).length;
}

// result: 01/12/2021, 09:32
export function formatDate(date: Date | string, hours = true): string {
    const realDate = typeof date === 'string' ? new Date(date) : date;
    const format: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    if (hours) {
        format['hour'] = 'numeric';
        format['minute'] = 'numeric';
    }
    return realDate && realDate.toLocaleDateString('fr-FR', format); // TODO: find a way to translate this depending on locale
}

// result: 2021-12-01
export function formatDateToISO(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// result: 09:30
export function formatHours(date: Date | string): string {
    const realDate = typeof date === 'string' ? new Date(date) : date;
    return zeroPad(realDate.getHours(), 2) + ':' + zeroPad(realDate.getMinutes(), 2);
}

export function formatViewByDate(date: Date, viewBy: ViewBy): string {
    const format: Intl.DateTimeFormatOptions =
        viewBy === ViewBy.MONTH ? { year: 'numeric', month: 'short' } : { year: 'numeric', month: 'short', day: '2-digit' };
    const currentLang = LocalStorage.getLang();
    const langFormat = currentLang + '-' + currentLang.toUpperCase();
    const stringDate = date.toLocaleDateString(langFormat, format);
    return _formatMonthInDateString(stringDate);
}

// result: 1 décembre 2021
export function formatStringDate(date: Date | string, hours = false): string {
    const realDate = typeof date === 'string' ? new Date(date) : date;
    const format: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    if (hours) {
        format['hour'] = 'numeric';
        format['minute'] = 'numeric';
    }
    const currentLang = LocalStorage.getLang();
    const langFormat = currentLang + '-' + currentLang.toUpperCase();
    const stringDate = realDate && realDate.toLocaleDateString(langFormat, format);
    return hours ? stringDate.replace('à', '-').replace('at', '-').replace('alle ore', '-') : stringDate;
}

// result: décembre
export function formatStringMonth(date: Date | string): string {
    const format: Intl.DateTimeFormatOptions = { month: MonthStringSize.long };
    const currentLang = LocalStorage.getLang();
    const langFormat = currentLang + '-' + currentLang.toUpperCase();
    return new Date(date).toLocaleString(langFormat, format);
}

// result: lundi
export function formatStringDay(date: Date): string {
    const dayOfWeek = date.getDay() || 7; // getDay() returns 0 for sunday but we need 7
    const dayObject = Object.values(days).find((d) => d.digit === dayOfWeek)!;
    const currentLang = LocalStorage.getLang();
    return dayObject?.[currentLang];
}

/**
 * create Date from string
 * @param stringDate // dd-mm-yyyy format (or dd/mm/yyyy)
 */
function createDateFromString(stringDate: string): Date {
    if (!stringDate) {
        throw new Error('Invalid date 2');
    }
    const separator = _getSeparator(stringDate);
    if (!separator) {
        throw new Error('Invalid date 3, ' + stringDate + ' is not a valid date');
    }
    const [day, month, year] = stringDate.split(separator);
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
}

/**
 * @param date string // dd-mm-yyyy format (or dd/mm/yyyy)
 */
export function toStartOfDay(date: string): string {
    const d = createDateFromString(date);
    const { year, month, day, hour, minute, second } = DateTime.fromJSDate(d).startOf('day');
    return DateTime.local(year, month, day, hour, minute, second).toJSDate().toISOString();
}

/**
 * @param date string // dd-mm-yyyy format (or dd/mm/yyyy)
 */
export function toEndOfDay(date: string): string {
    const d = createDateFromString(date);
    const { year, month, day, hour, minute, second } = DateTime.fromJSDate(d).endOf('day');
    return DateTime.local(year, month, day, hour, minute, second).toJSDate().toISOString();
}

export function getMonthRange(year: number, month: number): DayMonthYear[][] {
    const firstDay = DateTime.local(year, month).setLocale('en-EN');
    const lastDay = firstDay.endOf('month');
    const dayOfLastDay = lastDay.toObject().day ?? -1;
    if (month === 1) {
        year = year - 1;
        month = 13;
    }
    const previousMonthLastDay = DateTime.local(year, month - 1).endOf('month');
    const firstDayName: Day = Day[firstDay.toLocaleString({ weekday: 'long' }).toLocaleUpperCase()];
    const firstDayNameIndex = DAYS.indexOf(firstDayName);

    const firstWeekRange = _getFirstWeekRange(firstDay, previousMonthLastDay, firstDayNameIndex);
    const monthRange = [firstWeekRange];
    let tempDay = firstWeekRange[firstWeekRange.length - 1];
    tempDay = { ...tempDay, day: tempDay.day + 1 };
    let count = tempDay.day;
    while (count <= dayOfLastDay) {
        const temp: DayMonthYear[] = [];
        for (let i = 0; i < 7; i++) {
            temp.push(tempDay);
            if (tempDay.day === dayOfLastDay) {
                tempDay =
                    tempDay.month === 12 ? { year: tempDay.year + 1, month: 1, day: 1 } : { ...tempDay, month: tempDay.month + 1, day: 1 };
            } else {
                tempDay = { ...tempDay, day: tempDay.day + 1 };
            }
            count++;
        }
        monthRange.push(temp);
    }
    return monthRange;
}

function _getFirstWeekRange(firstDay: DateTime, previousMonthLastDay: DateTime, firstDayNameIndex: number): DayMonthYear[] {
    if (!previousMonthLastDay.isValid || !firstDay.isValid) {
        return [];
    }

    const firstWeekRange: DayMonthYear[] = [];
    let temp: DayMonthYear = getMalouDateFromDateTime(previousMonthLastDay);

    for (let i = firstDayNameIndex - 1; i >= 0; i--) {
        firstWeekRange.unshift(temp);
        temp = { ...temp, day: temp.day - 1 };
    }
    temp = getMalouDateFromDateTime(firstDay);

    for (let i = firstDayNameIndex; i < 7; i++) {
        firstWeekRange.push(temp);
        temp = { ...temp, day: temp.day + 1 };
    }

    return firstWeekRange;
}

export function getWeeksFromCurrentRange(startDate: Date, endDate: Date): WeekRange[] {
    const startMonday = getMonday(startDate);
    const endMonday = getMonday(endDate);
    const nbWeek = weeksBetween(startMonday, endMonday);
    const weeks: WeekRange[] = [];
    for (let index = 0; index <= nbWeek; index++) {
        const start = addDay(7 * index, startMonday);
        const end = addDay(7 * (index + 1) - 1, startMonday);
        const daysList: Date[] = [];
        for (let j = 0; j <= daysBetween(start, end); j++) {
            daysList.push(addDay(j, start));
        }
        const weekRange: WeekRange = {
            start,
            end,
            days: daysList,
        };

        if (index === 0) {
            Object.assign(weekRange, { realStart: startDate });
        } else if (index === nbWeek) {
            Object.assign(weekRange, { realEnd: endDate });
        }
        weeks.push(weekRange);
    }
    return weeks;
}

export function getDaysFromCurrentRange(startDate: Date, endDate: Date): Date[] {
    const daysList: Date[] = [];
    for (let i = 0; i < daysBetween(startDate, endDate); i++) {
        daysList.push(addDay(i, startDate));
    }
    return daysList;
}

export function getMalouDateFromDate(date: Date): DayMonthYear {
    return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    };
}

export function getMalouDateFromDateTime(date: DateTime): DayMonthYear {
    return {
        day: date.day,
        month: date.month,
        year: date.year,
    };
}

export function getMonthsFromPeriod(startDate: Date, endDate: Date): Month[] {
    if (startDate > endDate) {
        return [];
    }

    const startsOfMonths: DateTime[] = [DateTime.fromJSDate(startDate).startOf('month')];
    const startOfMonthUpperLimit = DateTime.fromJSDate(endDate).startOf('month');

    while (startOfMonthUpperLimit > startsOfMonths[startsOfMonths.length - 1]) {
        startsOfMonths.push(startsOfMonths[startsOfMonths.length - 1].plus({ month: 1 }));
    }

    const months: Month[] = startsOfMonths.map((startOfMonth) => {
        const start: DateTime = startOfMonth;
        const end: DateTime = startOfMonth.endOf('month');
        let current: DateTime = start;
        const daysList: Date[] = [];
        while (end >= current) {
            daysList.push(current.toJSDate());
            current = current.plus({ day: 1 });
        }

        return {
            start: start.toJSDate(),
            end: end.toJSDate(),
            days: daysList,
        };
    });

    return months;
}

export function isSameHours(day1: TimePeriod[], day2: TimePeriod[]): boolean {
    if (day1.length !== day2.length) {
        return false;
    }
    return day1.map((day, index) => day.hasSameHours(day2[index])).reduce((acc, next) => acc && next, true);
}

export function getTimeStringFromDate(date: Date): string {
    return DateTime.fromJSDate(date).toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
}

export function isControlValueInTimeFormat(translate: TranslateService) {
    return (control: AbstractControl): Record<string, any> | null => {
        const urlRegEx = new RegExp(/^\d{2}:\d{2}$/);
        return control.value?.match(urlRegEx) ? null : { error: translate.instant('common.invalid_time') };
    };
}

export function isPastHour({ hourWithMinute, date }: { hourWithMinute?: string | null; date?: Date | null }): boolean {
    if (!hourWithMinute || !date) {
        return false;
    }
    const todayWithoutTime = new Date().setHours(0, 0, 0, 0);
    const dateWithoutTime = date?.getTime() ? new Date(date.getTime()).setHours(0, 0, 0, 0) : todayWithoutTime;

    if (dateWithoutTime > todayWithoutTime) {
        return false;
    }

    if (dateWithoutTime === todayWithoutTime) {
        const [hour, minute] = hourWithMinute.split(':');
        const now = new Date().getTime();
        const hourToCompare = new Date().setHours(parseInt(hour, 10), parseInt(minute, 10), 0, 0);

        return hourToCompare <= now;
    }

    return true;
}

export const getClosestValueFromDate = (date: Date, datesList: Date[]): any | null => {
    if (!date || !datesList.length) {
        return null;
    }
    const rangesFromDate = datesList?.map((d) => Math.abs(d.getTime() - date.getTime()));
    const closestDateIndex = indexOf(rangesFromDate, Math.min(...rangesFromDate));
    return datesList[closestDateIndex] || null;
};

export const isTodayInFirstThreeDaysOfMonth = (): boolean => DateTime.now().day < ROI_HIDDEN_FIRST_DAYS_NUMBER;

export const groupAsPeriods = (dates: Date[]): { start: Date; end: Date | null }[] => {
    if (!dates.length) {
        return [];
    }
    const sortedDates = dates.sort((a: Date, b: Date) => (a.getTime() - b.getTime() ? 1 : -1)).map((date) => DateTime.fromJSDate(date));

    const periods: { start: Date; end: Date | null }[] = [];
    let startDate = sortedDates[0];
    let endDate = startDate;

    for (let i = 1; i < sortedDates.length; i++) {
        const currentDate = sortedDates[i];
        const previousDate = sortedDates[i - 1];

        if (currentDate.equals(previousDate.plus({ days: 1 }))) {
            endDate = currentDate;
        } else {
            if (startDate.equals(endDate)) {
                periods.push({ start: startDate.toJSDate(), end: null });
            } else {
                periods.push({ start: startDate.toJSDate(), end: endDate.toJSDate() });
            }
            startDate = currentDate;
            endDate = startDate;
        }
    }

    // Add the last period
    if (startDate.equals(endDate)) {
        periods.push({ start: startDate.toJSDate(), end: null });
    } else {
        periods.push({ start: startDate.toJSDate(), end: endDate.toJSDate() });
    }

    return periods;
};

function _getSeparator(date: string): string | null {
    if (date.match(/\d{1,2}\/\d{1,2}\/\d{4}/)) {
        return '/';
    } else if (date.match(/\d{1,2}-\d{1,2}-\d{4}/)) {
        return '-';
    }
    return null;
}

// déc. => Dec
function _formatMonthInDateString(dateString: string): string {
    return dateString
        .replace('.', '')
        .replace(/é/g, 'e')
        .replace(/\b([a-zA-Z]+)\b/, (match) => match.charAt(0).toUpperCase() + match.slice(1, 3));
}
