import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { AGGREGATED_BOOSTERS_ROUTES } from ':modules/aggregated-boosters/aggregated-boosters.routing';
import { AGGREGATED_REPUTATION_ROUTES } from ':modules/aggregated-reputation/aggregated-reputation.routing';
import { AGGREGATED_STATISTICS_ROUTES } from ':modules/aggregated-statistics/aggregated-statistics.routing';

export const GROUPS_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'reputation',
    },
    {
        path: 'statistics',
        loadChildren: () => AGGREGATED_STATISTICS_ROUTES,
        data: { routePath: RoutePath.STATISTICS },
    },
    {
        path: 'reputation',
        loadChildren: () => AGGREGATED_REPUTATION_ROUTES,
        data: { routePath: RoutePath.E_REPUTATION },
    },
    {
        path: 'resources',
        redirectTo: 'boosters',
    },
    {
        path: 'boosters',
        loadChildren: () => AGGREGATED_BOOSTERS_ROUTES,
        data: { routePath: RoutePath.BOOSTERS },
    },
];
