<div class="flex gap-x-1.5">
    <div class="min-w-0">
        <app-media-thumbnail-list
            [medias]="mediasForThumbnailList()"
            [uploadingMediaCount]="uploadingMediaCount()"
            [showEditMediaButton]="showEditMediaButton()"
            [isReadonly]="isReadonly()"
            (editMedia)="onEditMedia($event)"
            (removeMedia)="onRemoveMedia($event)"
            (dropMedia)="onDropMedia($event)"
            (mediaClicked)="mediaClicked.emit($event)"></app-media-thumbnail-list>
    </div>
    <div
        class="flex h-[75px] w-[75px] shrink-0 cursor-pointer items-center justify-center rounded-md bg-white"
        [matMenuTriggerFor]="uploadMenu">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
    </div>
</div>

<mat-menu class="malou-mat-menu malou-box-shadow rounded-md" #uploadMenu="matMenu">
    <button mat-menu-item [disabled]="isReadonly()" (click)="openFilePicker.emit()">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
        <span class="malou-text-12--regular text-malou-color-text-2">
            {{ 'common.upload_from_computer' | translate }}
        </span>
    </button>
    <button mat-menu-item [disabled]="isReadonly()" (click)="importFromGallery.emit()">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
        <span class="malou-text-12--regular text-malou-color-text-2">{{ 'common.upload_from_gallery' | translate }}</span>
    </button>
</mat-menu>
