import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, take } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { AggregatedKeywordRankingData } from ':modules/aggregated-statistics/seo/models/keyword-table-data-row';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    keywordRankings: AggregatedKeywordRankingData[];
    dates: { startDate: string; endDate: string };
}

@Injectable({ providedIn: 'root' })
export class AggregatedKeywordsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin([
            this._store.select(AggregatedStatisticsSelector.selectKeywordRankings).pipe(take(1)),
            this._store.select(AggregatedStatisticsSelector.selectFilters).pipe(take(1)),
        ]).pipe(
            map(([keywordRankings, filters]) => {
                const monthYearPeriod = filters.monthYearPeriod;
                const startDate = DateTime.fromObject(monthYearPeriod.startMonthYear).startOf('month').toISODate();
                const endDate = DateTime.fromObject(monthYearPeriod.endMonthYear).endOf('month').toISODate();
                return {
                    keywordRankings,
                    dates: { startDate, endDate },
                };
            })
        );
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return [
            'Start Date',
            'End Date',
            'Restaurant Name',
            'Position Google Top 20',
            'Position Google Top 20 Evolution',
            'Position Google Top 3',
            'Position Google Top 3 Evolution',
            'Google Search Discovery',
            'Google Search Discovery Evolution',
            'Google Search Notoriety',
            'Google Search Notoriety Evolution',
        ];
    }

    protected override _getCsvDataRows(data: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const { keywordRankings, dates } = data;
        const { startDate, endDate } = dates;

        return keywordRankings.map((keywordRanking) => {
            const restaurantName = keywordRanking.restaurantName;
            const positionGoogleTop20 = keywordRanking.currentTop20?.toString() ?? '-';
            const positionGoogleTop20Evolution = this._computeEvolution(keywordRanking.currentTop20, keywordRanking.previousTop20);
            const positionGoogleTop3 = keywordRanking.currentTop3?.toString() ?? '-';
            const positionGoogleTop3Evolution = this._computeEvolution(keywordRanking.currentTop3, keywordRanking.previousTop3);
            const googleSearchDiscovery = keywordRanking.discoveryKeywordImpressions?.toString() ?? '-';
            const googleSearchDiscoveryEvolution = this._computeEvolution(
                keywordRanking.discoveryKeywordImpressions,
                keywordRanking.previousDiscoveryKeywordImpressions
            );
            const googleSearchNotoreity = keywordRanking.brandingKeywordImpressions?.toString() ?? '-';
            const googleSearchNotoreityEvolution = this._computeEvolution(
                keywordRanking.brandingKeywordImpressions,
                keywordRanking.previousBrandingKeywordImpressions
            );

            return [
                startDate,
                endDate,
                restaurantName,
                positionGoogleTop20,
                positionGoogleTop20Evolution,
                positionGoogleTop3,
                positionGoogleTop3Evolution,
                googleSearchDiscovery,
                googleSearchDiscoveryEvolution,
                googleSearchNotoreity,
                googleSearchNotoreityEvolution,
            ];
        });
    }

    private _computeEvolution(current: number | undefined, previous: number | undefined): string {
        if (isNotNil(current) && isNotNil(previous)) {
            return (current - previous).toString();
        }
        return '-';
    }
}
