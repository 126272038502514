import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { BoostersComponent } from ':modules/statistics/boosters/boosters.component';

import { EReputationComponent } from './e-reputation/e-reputation.component';
import { RoiComponent } from './roi/roi.component';
import { SeoComponent } from './seo/seo.component';
import { SocialNetworksComponent } from './social-networks/social-networks.component';
import { StatisticsComponent } from './statistics.component';

export const STATISTICS_ROUTES: Routes = [
    {
        path: '',
        component: StatisticsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'seo',
            },
            {
                path: 'roi',
                component: RoiComponent,
                data: { routePath: RoutePath.EARNINGS },
            },
            {
                path: 'seo',
                component: SeoComponent,
                data: { routePath: RoutePath.SEO_STATS },
            },
            {
                path: 'e-reputation',
                component: EReputationComponent,
                data: { routePath: RoutePath.E_REPUTATION_STATS },
            },
            {
                path: 'social-networks',
                component: SocialNetworksComponent,
                data: { routePath: RoutePath.SOCIAL_NETWORKS_STATS },
            },
            {
                path: 'totems',
                redirectTo: 'boosters',
            },
            {
                path: 'boosters',
                component: BoostersComponent,
                data: { routePath: RoutePath.BOOSTERS_STATS },
            },
        ],
    },
];
