@if (!isLoading()) {
    @if (hasData()) {
        <div class="flex flex-col gap-6">
            @if (shouldDisplayAnalysesTagCharts()) {
                <div class="malou-simple-card flex break-inside-avoid flex-col gap-3 px-6 py-3 pb-12 md:px-2">
                    <div class="flex items-center gap-2">
                        <div class="malou-text-section-title malou-color-text-1">
                            {{ 'statistics.e_reputation.reviews_analysis.semantic_analysis' | translate }}
                        </div>
                        @if (warningTooltip()) {
                            <div class="malou-status--waiting pdf-hidden" [matTooltip]="warningTooltip()!">
                                <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                            </div>
                        }
                    </div>
                    <div class="justify-content-between flex lg:flex-col-reverse">
                        <div class="h-60 min-w-0 flex-1">
                            <app-tags-bar-chart
                                [chartData]="reviewAnalysesChartData()"
                                [reviewAnalysesFilter]="reviewAnalysesFilter()"
                                [shouldDisplayAnalysesTagClickableLabels]="true"
                                [isFromAggregatedStatistics]="true">
                            </app-tags-bar-chart>
                        </div>
                        <div class="h-60 min-w-0 flex-1">
                            <app-tags-doughnut-chart [chartData]="reviewAnalysesChartData()"></app-tags-doughnut-chart>
                        </div>
                    </div>
                    @if (showSeeMoreButton()) {
                        <div class="mt-12 self-center">
                            <a
                                class="malou-text-13--semibold rounded-md bg-malou-color-primary p-2"
                                style="color: white"
                                [href]="detailHref">
                                {{ 'semantic_analyses.go_to_detail_button' | translate }}
                            </a>
                        </div>
                    }
                </div>
            }
            @if (shouldDisplayAnalysesTagEvolution()) {
                <div>
                    @if (showSplitByRestaurant()) {
                        <app-tags-evolution
                            [chartData]="reviewAnalysesChartData()"
                            [restaurants]="(selectedRestaurants$ | async) ?? []"
                            [warningTooltip]="warningTooltip()"
                            [shouldDetailTagsEvolutionCharts]="shouldDetailTagsEvolutionCharts()"
                            [reviewAnalysesFilter]="reviewAnalysesFilter()"
                            [sortBy]="tagsEvolutionSortBy()"
                            (sortByChange)="tagsEvolutionSortByChange.emit($event)">
                        </app-tags-evolution>
                    }
                </div>
            }
        </div>
    } @else {
        <ng-container
            [ngTemplateOutlet]="emptyTemplate"
            [ngTemplateOutletContext]="{
                text: 'aggregated_statistics.e_reputation.reviews_analysis.semantic_analysis_not_available' | translate,
                subtext: 'aggregated_statistics.e_reputation.reviews_analysis.edit_filters' | translate,
            }">
        </ng-container>
    }
} @else {
    <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
}

<ng-template let-text="text" let-subtext="subtext" #emptyTemplate>
    <div class="my-4 flex flex-col items-center py-4">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">
            {{ text }}
        </span>
        <span class="malou-text-10--regular">
            {{ subtext }}
        </span>
    </div>
</ng-template>

<ng-template #skeletonTemplate>
    <div class="flex flex-col gap-4">
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
        <app-skeleton skeletonClass="!h-[403px] secondary-bg"></app-skeleton>
    </div>
</ng-template>
