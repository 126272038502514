import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import {
    isNotNil,
    KeywordSearchImpressionsType,
    MalouComparisonPeriod,
    MonthYearPeriod,
    PlatformKey,
    TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ImpressionsInsightsComponent } from ':modules/statistics/seo/keyword-search-impressions/impressions-insights/impressions-insights.component';
import {
    ImpressionsInsightsData,
    TopKeywordSearchImpressionsData,
} from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import { TopKeywordSearchImpressionsComponent } from ':modules/statistics/seo/keyword-search-impressions/top-keyword-search-impressions/top-keyword-search-impressions.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { MalouDateFilters, Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-keyword-search-impressions',
    standalone: true,
    imports: [
        ImpressionsInsightsComponent,
        TopKeywordSearchImpressionsComponent,
        SkeletonComponent,
        NgTemplateOutlet,
        TranslateModule,
        IllustrationPathResolverPipe,
        PlatformLogoComponent,
    ],
    templateUrl: './keyword-search-impressions.component.html',
    styleUrl: './keyword-search-impressions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordSearchImpressionsComponent implements OnInit {
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly shouldHideTopKeywordsSeeMoreButton = input<boolean>(false);
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _keywordSearchImpressionsService = inject(KeywordSearchImpressionsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);

    readonly currentKeywordSearchImpressionsInsights: WritableSignal<ImpressionsInsightsData[]> = signal([]);
    readonly previousKeywordSearchImpressionsInsights: WritableSignal<ImpressionsInsightsData[]> = signal([]);
    readonly topKeywordSearchImpressions: WritableSignal<TopKeywordSearchImpressionsData> = signal({
        [KeywordSearchImpressionsType.BRANDING]: [],
        [KeywordSearchImpressionsType.DISCOVERY]: [],
    });
    readonly monthYearPeriod: WritableSignal<MonthYearPeriod> = signal(MalouDateFilters.getDefaultMonthYearPeriod());
    readonly isLoading = signal(true);
    readonly hasData = signal(false);
    readonly httpError = signal(false);

    // we need both filters.monthYearPeriod and filters.isFiltersLoaded
    // we use filters.isFiltersLoaded to assert that filters are loaded and does not have initial value
    // select all filters state to avoid multiple selectors from the same state in the combineLatest to avoid multiple subscriptions
    readonly selectStatisticsFilters$: Observable<StatisticsState['filters']> = this._store.select(StatisticsSelector.selectFilters);
    readonly selectedRestaurant$ = this._restaurantsService.restaurantSelected$.pipe(
        distinctUntilChanged((prev, curr) => prev?._id === curr?._id)
    );

    readonly Illustration = Illustration;
    readonly PlatformKey = PlatformKey;

    ngOnInit(): void {
        combineLatest([this.selectedRestaurant$, this.selectStatisticsFilters$])
            .pipe(
                filter(([restaurant, statisticsFilters]) => isNotNil(restaurant) && statisticsFilters.isFiltersLoaded),
                map(([restaurant, statisticsFilters]: [Restaurant, StatisticsState['filters']]) => [
                    restaurant,
                    statisticsFilters.monthYearPeriod,
                ]),
                tap(() => this._reset()),
                switchMap(([restaurant, monthYearPeriod]: [Restaurant, MonthYearPeriod]) =>
                    forkJoin([
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights(restaurant._id, {
                            startMonthYear: monthYearPeriod.startMonthYear,
                            endMonthYear: monthYearPeriod.endMonthYear,
                        }),
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights(restaurant._id, {
                            startMonthYear: monthYearPeriod.startMonthYear,
                            endMonthYear: monthYearPeriod.endMonthYear,
                            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        }),
                        this._keywordSearchImpressionsService.getTopKeywordSearchImpressions(restaurant._id, {
                            startMonthYear: monthYearPeriod.startMonthYear,
                            endMonthYear: monthYearPeriod.endMonthYear,
                            limit: TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
                        }),
                        of(monthYearPeriod),
                    ])
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([
                    currentKeywordSearchImpressionsInsights,
                    previousKeywordSearchImpressionsInsights,
                    topKeywordSearchImpressions,
                    monthYearPeriod,
                ]) => {
                    this.isLoading.set(false);
                    this.hasData.set(currentKeywordSearchImpressionsInsights?.length > 0);

                    this.currentKeywordSearchImpressionsInsights.set(currentKeywordSearchImpressionsInsights);
                    this.previousKeywordSearchImpressionsInsights.set(previousKeywordSearchImpressionsInsights);

                    this.topKeywordSearchImpressions.set(topKeywordSearchImpressions);

                    this._setTopKeywordSearchImpressionsStoreData(topKeywordSearchImpressions);

                    this.monthYearPeriod.set(monthYearPeriod);
                    this.hasDataChange.emit(this.hasData());
                    this.isLoadingEvent.emit(false);
                },
                error: (error) => {
                    this.isLoading.set(false);
                    this.httpError.set(true);
                    this.hasData.set(false);
                    this.hasDataChange.emit(this.hasData());
                    this.isLoadingEvent.emit(false);
                    console.warn('error :>>', error);
                },
            });
    }

    onHiddenDatasetIndexesChange(hiddenDatasetIndexes: number[]): void {
        this.hiddenDatasetIndexesChange.emit(hiddenDatasetIndexes);
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.httpError.set(false);
        this.hasData.set(false);
        this.isLoadingEvent.emit(true);
    }

    private _setTopKeywordSearchImpressionsStoreData(topKeywordSearchImpressions: TopKeywordSearchImpressionsData): void {
        this._store.dispatch(StatisticsActions.editTopKeywordSearchImpressions({ data: topKeywordSearchImpressions }));
    }
}
