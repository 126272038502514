import { MaloupeLocale } from '@malou-io/package-utils';

import { MaloupeCategoryDto } from '../categories';
import { AddressDto } from '../restaurant';

export interface DiagnosticDto {
    id: string;
    placeId: string;
    isMalouRestaurant: boolean;
    restaurant: {
        name: string;
        address: AddressDto;
        category: MaloupeCategoryDto;
        averageRatingForSimilarRestaurants?: number;
        betterCompetitorCount?: number;
        rating: number;
        services: {
            acceptsCashOnly: boolean | null;
            acceptsCreditCards: boolean | null;
            acceptsDebitCards: boolean | null;
            acceptsNfc: boolean | null;
            allowsDogs: boolean | null;
            curbsidePickup: boolean | null;
            delivery: boolean | null;
            dineIn: boolean | null;
            freeParkingLot: boolean | null;
            freeStreetParking: boolean | null;
            goodForChildren: boolean | null;
            goodForGroups: boolean | null;
            goodForWatchingSports: boolean | null;
            liveMusic: boolean | null;
            menuForChildren: boolean | null;
            outdoorSeating: boolean | null;
            paidStreetParking: boolean | null;
            reservable: boolean | null;
            restroom: boolean | null;
            servesBeer: boolean | null;
            servesBreakfast: boolean | null;
            servesBrunch: boolean | null;
            servesCocktails: boolean | null;
            servesCoffee: boolean | null;
            servesDessert: boolean | null;
            servesDinner: boolean | null;
            servesLunch: boolean | null;
            servesVegetarianFood: boolean | null;
            servesWine: boolean | null;
            takeout: boolean | null;
            wheelchairAccessibleParking: boolean | null;
            wheelchairAccessibleSeating: boolean | null;
        };
    };
    similarRestaurantCount: number;
    language: string;
    photoCount: number;
    reviewCount: number;
    averageReviewCountForSimilarRestaurants?: number;
    semanticAnalysisOverviewByLang?: {
        [MaloupeLocale.FR]: string;
        [MaloupeLocale.EN]: string;
    };
    reviews: DiagnosticReviewDto[];
    createdAt: string;
    inconsistencies?: {
        platformCount: number;
        inconsistencyCount: number;
        platformKeys: string[];
    };
    instagramPage?: {
        userName: string;
        name: string;
        postCount: number;
        averageLikeCount: number;
        followerCount: number;
        averagePostCountForSimilarRestaurants: number;
        averageFollowerCountForSimilarRestaurants: number;
        averageLikeCountForSimilarRestaurants: number;
    };
    website?: string;
    keywords?: DiagnosticKeywordDto[];
}

export interface DiagnosticKeywordDto {
    keyword: {
        [MaloupeLocale.FR]: string;
        [MaloupeLocale.EN]: string;
    };
    googlePosition: number;
    competitorsGooglePositions: DiagnosticKeywordCompetitorsDto[];
}

export interface DiagnosticKeywordCompetitorsDto {
    name: string;
    googlePosition: number;
    address: string;
    placeId: string;
}

export interface InstagramPageInfoDto {
    business_discovery: {
        biography: string;
        id: string;
        name: string;
        username: string;
        profile_picture_url: string;
    };
    id: string;
}

export interface DiagnosticReviewDto {
    author: string;
    text: string;
    rating: number;
    socialId: string;
    language: string;
    segmentAnalyses: {
        tag: string;
        segment: string;
        sentiment: string;
    }[];
}

export interface DiagnosticWithRedirectionLinkDto {
    diagnostic: DiagnosticDto;
    publicDiagnosticUrl: string;
}

export interface DiagnosticsWithRedirectionLinkDto {
    diagnostics: DiagnosticDto[];
    publicDiagnosticUrl: string;
}
