import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { GALLERY_ROUTES } from ':modules/gallery/gallery.routing';
import { REVIEW_TEMPLATES_ROUTES } from ':modules/templates/review-templates/review-templates.routing';
import { UrlRedirectComponent } from ':shared/components/url-redirect/url-redirect.component';

import { CLIENTS_ROUTES } from '../clients/clients.routing';
import { KEYWORDS_ROUTES } from '../keywords/keywords.routing';
import { MESSAGE_TEMPLATES_ROUTES } from '../templates/message-templates/message-templates.routing';
import { ResourcesComponent } from './resources.component';

export const RESOURCES_ROUTES: Routes = [
    {
        path: '',
        component: ResourcesComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'gallery',
            },
            {
                path: 'gallery',
                loadChildren: () => GALLERY_ROUTES,
                data: { routePath: RoutePath.GALLERY },
            },
            {
                path: 'review-templates',
                loadChildren: () => REVIEW_TEMPLATES_ROUTES,
                data: { routePath: RoutePath.REVIEW_TEMPLATES },
            },
            {
                path: 'message-templates',
                loadChildren: () => MESSAGE_TEMPLATES_ROUTES,
                data: { routePath: RoutePath.MESSAGE_TEMPLATES },
            },
            {
                path: 'clients',
                loadChildren: () => CLIENTS_ROUTES,
                data: { routePath: RoutePath.CUSTOMERS },
            },
            {
                path: 'keywords',
                loadChildren: () => KEYWORDS_ROUTES,
                data: { routePath: RoutePath.KEYWORDS },
            },
            // redirectTo cannot go backwards higher than path resources/ so we use a component to navigate to updated path
            {
                path: 'totems',
                component: UrlRedirectComponent,
            },
            {
                path: 'boosters',
                component: UrlRedirectComponent,
            },
            {
                path: 'booster', // This one is because there was a typo in one of the redirect links from emails
                component: UrlRedirectComponent,
            },
        ],
    },
];
