import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, of } from 'rxjs';

import { StoredInsightsResponseDto } from '@malou-io/package-dto';
import {
    AggregationTimeScale,
    GeoSamplePlatform,
    getPreviousMonthYear,
    getRangeForMonthYearPeriod,
    MalouComparisonPeriod,
    MonthAndYear,
    MonthYearPeriod,
    PlatformKey,
} from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import { GmbInsightsChartData } from ':modules/statistics/seo/models/gmb-insight-chart-data';
import { StatisticsState } from ':modules/statistics/store/statistics.interface';
import { getDayMonthYearFromDate } from ':shared/helpers';
import { GMB_METRICS } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { SummarySectionsData } from ':shared/services/csv-services/insights/summary/summary.interface';

@Injectable({ providedIn: 'root' })
export class SummaryCsvInsightsSeoSectionService {
    constructor(
        private readonly _insightsService: InsightsService,
        private readonly _keywordsService: KeywordsService,
        private readonly _keywordSearchImpressionsService: KeywordSearchImpressionsService
    ) {}

    execute(
        filters: StatisticsState['filters'],
        restaurant: Restaurant
    ): Observable<{ current: SummarySectionsData['seo'] | null; previous: SummarySectionsData['seo'] | null }> {
        const requestBody = {
            restaurantIds: [restaurant._id],
            platformKeys: [PlatformKey.GMB],
            startDate: getDayMonthYearFromDate(filters.dates.startDate!),
            endDate: getDayMonthYearFromDate(filters.dates.endDate!),
            metrics: GMB_METRICS.map(({ metric }) => metric),
        };
        const startDateLuxon = DateTime.fromObject(filters.monthYearPeriod.startMonthYear);
        const endDateLuxon = DateTime.fromObject(filters.monthYearPeriod.endMonthYear);
        const periodInMonths = endDateLuxon.diff(startDateLuxon, 'months').months;

        const startDate = startDateLuxon.startOf('month').toJSDate();
        const endDate = endDateLuxon.endOf('month').toJSDate();
        const previousStartDate = startDateLuxon.minus({ months: periodInMonths }).startOf('month').toJSDate();
        const previousEndDate = startDateLuxon.minus({ months: 1 }).endOf('month').toJSDate();

        // TODO: add check if gmb is connected [@hamza]
        // TODO (Improvement): switch to 1 call to the api [@hamza]
        return forkJoin([
            // Actions and impressions
            this._insightsService.getStoredInsights(requestBody),
            this._insightsService.getStoredInsights({
                ...requestBody,
                comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
            }),
            // Keywords
            this._keywordsService.getKeywordRankingsForOneRestaurantV3({
                restaurantId: restaurant._id,
                startDate,
                endDate,
                platformKey: GeoSamplePlatform.GMAPS,
            }),
            this._keywordsService.getKeywordRankingsForOneRestaurantV3({
                restaurantId: restaurant._id,
                startDate: previousStartDate,
                endDate: previousEndDate,
                platformKey: GeoSamplePlatform.GMAPS,
            }),
            // Notoriety and discovery search
            this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights(restaurant._id, {
                startMonthYear: filters.monthYearPeriod.startMonthYear,
                endMonthYear: filters.monthYearPeriod.endMonthYear,
            }),
            this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights(restaurant._id, {
                startMonthYear: filters.monthYearPeriod.startMonthYear,
                endMonthYear: filters.monthYearPeriod.endMonthYear,
                comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
            }),
            of(restaurant._id),
        ]).pipe(
            map(
                ([
                    currentActionInsights,
                    previousActionInsights,
                    currentKeywords,
                    previousKeywords,
                    currentSearchImpressions,
                    previousSearchImpressions,
                    restaurantId,
                ]) => {
                    const seoSectionData = this._getActionsAndImpressionsData(currentActionInsights.data, restaurantId);
                    const previousSeoSectionData = this._getActionsAndImpressionsData(previousActionInsights.data, restaurantId);

                    if (!seoSectionData) {
                        return {
                            current: null,
                            previous: null,
                        };
                    }

                    // -- keywords
                    const currentKeywordCountInTop20 = currentKeywords.keywords.filter(
                        (keyword) => (keyword.rankHistory.at(-1)?.rank ?? 0) <= 20
                    ).length;
                    const previousKeywordCountInTop20 = previousKeywords.keywords.filter(
                        (keyword) => (keyword.rankHistory.at(-1)?.rank ?? 0) <= 20
                    ).length;
                    seoSectionData.keywordsInTop20Count = currentKeywordCountInTop20;
                    if (previousSeoSectionData) {
                        previousSeoSectionData.keywordsInTop20Count = previousKeywordCountInTop20;
                    }

                    // -- Notoriety and discovery search
                    const totalCurrentNotorietySearchCount = currentSearchImpressions.reduce(
                        (acc, curr) => (acc ?? 0) + (curr.branding ?? 0),
                        0
                    );
                    const totalCurrentDiscoverySearchCount = currentSearchImpressions.reduce(
                        (acc, curr) => (acc ?? 0) + (curr.discovery ?? 0),
                        0
                    );
                    seoSectionData.totalNotorietySearchCount = totalCurrentNotorietySearchCount;
                    seoSectionData.totalDiscoverySearchCount = totalCurrentDiscoverySearchCount;

                    if (previousSeoSectionData) {
                        const previousMonthYearsToExclude = this._getPreviousMonthYearToExclude(
                            currentSearchImpressions,
                            filters.monthYearPeriod
                        );
                        const previousImpressionsDataFiltered = previousSearchImpressions.filter(
                            (impressionsData) =>
                                !previousMonthYearsToExclude.some(
                                    (monthYear) => monthYear.month === impressionsData.month && monthYear.year === impressionsData.year
                                )
                        );
                        const totalPreviousNotorietySearchCount = previousImpressionsDataFiltered.reduce(
                            (acc, curr) => (acc ?? 0) + (curr.branding ?? 0),
                            0
                        );
                        const totalPreviousDiscoverySearchCount = previousImpressionsDataFiltered.reduce(
                            (acc, curr) => (acc ?? 0) + (curr.discovery ?? 0),
                            0
                        );
                        previousSeoSectionData.totalNotorietySearchCount = totalPreviousNotorietySearchCount;
                        previousSeoSectionData.totalDiscoverySearchCount = totalPreviousDiscoverySearchCount;
                    }

                    return {
                        current: seoSectionData,
                        previous: previousSeoSectionData,
                    };
                }
            )
        );
    }

    private _getActionsAndImpressionsData(data: StoredInsightsResponseDto, restaurantId: string): SummarySectionsData['seo'] {
        const actionAndImpressionsInsights = data?.[restaurantId]?.[PlatformKey.GMB];
        if (!actionAndImpressionsInsights?.hasData) {
            return {
                actions: {
                    totalActions: null,
                    conversionRate: null,
                    totalCallCount: null,
                    totalDirectionCount: null,
                    totalWebsiteVisitCount: null,
                    totalMenuClickCount: null,
                    totalBookingClickCount: null,
                    totalOrderClickCount: null,
                },
                impressions: {
                    totalAppearanceMaps: null,
                    totalAppearanceSearch: null,
                    totalAppearance: null,
                },
                keywordsInTop20Count: null,
                totalNotorietySearchCount: null,
                totalDiscoverySearchCount: null,
            };
        }
        const actionsData = new GmbInsightsChartData({
            data: actionAndImpressionsInsights.insights,
            startDate: data.startDate,
            endDate: data.endDate,
            aggregationTimeScale: AggregationTimeScale.BY_DAY,
            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
        });
        return {
            actions: {
                totalActions: actionsData.totalActions,
                conversionRate: actionsData.ratioActionsOverImpressions,
                totalCallCount: actionsData.phoneClicks.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0),
                totalDirectionCount: actionsData.drivingClicks.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0),
                totalWebsiteVisitCount: actionsData.websiteClicks.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0),
                totalMenuClickCount: actionsData.menuClicks.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0),
                totalBookingClickCount: actionsData.bookingClicks.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0),
                totalOrderClickCount: actionsData.foodOrderClicks.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0),
            },
            impressions: {
                totalAppearanceMaps: actionsData.totalImpressionsMaps,
                totalAppearanceSearch: actionsData.totalImpressionsSearch,
                totalAppearance: actionsData.totalImpressions,
            },
            keywordsInTop20Count: null,
            totalNotorietySearchCount: null,
            totalDiscoverySearchCount: null,
        };
    }

    private _getPreviousMonthYearToExclude(
        currentImpressionsData: ImpressionsInsightsData[],
        monthYearPeriod: MonthYearPeriod
    ): MonthAndYear[] {
        const monthAndYearRange: MonthAndYear[] = getRangeForMonthYearPeriod(monthYearPeriod);
        const currentMonthYearsWithoutData = monthAndYearRange.filter(
            (monthAndYear) =>
                !currentImpressionsData.some((insight) => insight.month === monthAndYear.month && insight.year === monthAndYear.year)
        );
        const monthsCount = monthAndYearRange.length;
        return currentMonthYearsWithoutData.map((monthAndYear) => getPreviousMonthYear(monthAndYear, monthsCount));
    }
}
