import { z } from 'zod';

import { PlatformKey, PostPublicationStatus, PostSource } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';
import { postHashtagsValidator } from './post-hashtags.dto';
import { SeoPostDto } from './seo-post.dto';
import { postLocationValidator, SocialPostDto } from './update-social-post.dto';

export const duplicatePostV2ParamsValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({ restaurantId: data.restaurant_id }));

export type DuplicatePostV2ParamsDto = z.infer<typeof duplicatePostV2ParamsValidator>;

export const duplicatePostV2BodyValidator = z
    .object({
        postIdsToDuplicate: z.array(objectIdValidator),
        restaurantIds: z.array(objectIdValidator),
        postDestination: z.nativeEnum(PostSource),
        customFields: z
            .array(
                z.object({
                    restaurantId: z.string(),
                    hashtags: postHashtagsValidator.nullish(),
                    location: postLocationValidator.nullish(),
                    text: z.string().optional(),
                    plannedPublicationDate: z.string().datetime().optional(),
                    platformKeys: z.array(z.nativeEnum(PlatformKey)).optional(),
                    published: z.nativeEnum(PostPublicationStatus).optional(),
                })
            )
            .optional(),
    })
    .transform((data) => ({
        ...data,
        customFields: data.customFields
            ? data.customFields.map((customFields) => ({
                  ...customFields,
                  plannedPublicationDate: customFields.plannedPublicationDate ? new Date(customFields.plannedPublicationDate) : undefined,
              }))
            : undefined,
    }));

export type DuplicatePostV2BodyDto = z.infer<typeof duplicatePostV2BodyValidator>;

export type DuplicatePostV2ResponseDto = {
    socialPostsDuplicated: { post: SocialPostDto; restaurantId: string }[];
    seoPostsDuplicated: { post: SeoPostDto; restaurantId: string }[];
};
