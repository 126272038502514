import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, lastValueFrom } from 'rxjs';

import { GetMediaForEditionResponseDto, ReelThumbnail } from '@malou-io/package-dto';
import { FileFormat, MediaType, MimeType, PublicationType, TransformDataComputerService } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaService } from ':modules/media/media.service';
import { PostMediaListComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/post-media-list/post-media-list.component';
import { ReelMediaComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/components/reel-media/reel-media.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { MediaUploaderService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/media-uploader.service';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { BodyDragAndDropEventsService } from ':shared/services/body-drag-and-drop-events.service';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const MAX_POST_MEDIA_COUNT = 10;
const MAX_REEL_MEDIA_COUNT = 1;

@Component({
    selector: 'app-social-post-medias',
    templateUrl: './social-post-medias.component.html',
    standalone: true,
    imports: [PostMediaListComponent, NgClass, MatIcon, MatMenuModule, TranslateModule, NgTemplateOutlet, ReelMediaComponent],
    providers: [MediaUploaderService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostMediasComponent {
    private readonly _mediaUploaderService = inject(MediaUploaderService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _mediaService = inject(MediaService);
    private readonly _bodyDragAndDropEventsService = inject(BodyDragAndDropEventsService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);

    public readonly reelThumbnail = input<ReelThumbnail | undefined>(undefined);
    public readonly onReelThumbnailChange = output<ReelThumbnail | undefined>();
    readonly medias = model.required<EditionMedia[]>();
    readonly publicationType = input.required<PublicationType>();
    readonly options = input<{ post?: { showEditMediaButton: boolean } }>({});
    readonly isReadonly = input<boolean>(false);
    readonly postMediaClicked = output<string>();

    readonly PublicationType = PublicationType;
    readonly SvgIcon = SvgIcon;

    readonly IMAGE_MIME_TYPES = [MimeType.IMAGE_PNG, MimeType.IMAGE_JPEG, MimeType.IMAGE_HEIC, MimeType.IMAGE_HEIF];
    readonly VIDEO_MIME_TYPES = [MimeType.VIDEO_MP4, MimeType.VIDEO_QUICKTIME];

    readonly maxMediaSizeInMo = signal(50);
    readonly mediaFormatAccepted = signal([FileFormat.PNG, FileFormat.JPEG]);
    readonly isDragging = signal(false);
    readonly areDragEventsEnable = signal(true);
    readonly multipleMediaSelection = computed(() => this.publicationType() === PublicationType.POST);

    // This list is given to the <input type='file'>, remember that it's only a suggestion, the user can bypass this.
    readonly acceptAttribute = computed(() => {
        if (this.publicationType() === PublicationType.POST) {
            return [...this.IMAGE_MIME_TYPES, ...this.VIDEO_MIME_TYPES];
        }
        if (this.publicationType() === PublicationType.REEL) {
            return this.VIDEO_MIME_TYPES;
        }
    });

    constructor() {
        this._handleBodyDragEvents();
        effect(() => {
            this._mediaUploaderService.setPublicationType(this.publicationType());
        });
    }

    private async _onMediaUploaded(media: GetMediaForEditionResponseDto): Promise<void> {
        const isFirstMedia = this.medias().length === 0;
        if (isFirstMedia) {
            this.medias.update((medias) => [...medias, media]);
            return;
        }
        const firstMediaAspectRatio = this.medias()[0].transformData.aspectRatio;
        const targetAspectRatio =
            TransformDataComputerService.getAspectRatioNumberFor(this.publicationType(), firstMediaAspectRatio) ?? media.aspectRatio;
        const newTransformAreaForCurrentMedia = TransformDataComputerService.computeArea(media.aspectRatio, targetAspectRatio);
        const newTransformDataForCurrentMedia = {
            ...media.transformData,
            ...newTransformAreaForCurrentMedia,
            aspectRatio: this.medias()[0].transformData.aspectRatio,
        };
        await lastValueFrom(this._mediaService.updateTransformData({ mediaId: media.id }, newTransformDataForCurrentMedia));
        const updatedMedia = await lastValueFrom(
            this._mediaService.getMediaForEdition({ mediaId: media.id }, { publicationType: PublicationType.POST })
        );
        this.medias.update((medias) => [...medias, updatedMedia.data]);
    }

    // ------- Events handlers : drag and drop ------- //

    private _handleBodyDragEvents(): void {
        this._bodyDragAndDropEventsService.dragEnter
            .pipe(
                filter(() => this.areDragEventsEnable()),
                filter(this._hasFile),
                takeUntilDestroyed()
            )
            .subscribe(this._onDragEnter);
        this._bodyDragAndDropEventsService.dragOver
            .pipe(
                filter(() => this.areDragEventsEnable()),
                filter(this._hasFile),
                takeUntilDestroyed()
            )
            .subscribe(this._onDragOver);
        this._bodyDragAndDropEventsService.dragLeave
            .pipe(
                filter(() => this.areDragEventsEnable()),
                filter(this._hasFile),
                takeUntilDestroyed()
            )
            .subscribe(this._onDragLeave);
        this._bodyDragAndDropEventsService.drop
            .pipe(
                filter(() => this.areDragEventsEnable()),
                filter(this._hasFile),
                takeUntilDestroyed()
            )
            .subscribe(this._onDrop);
    }

    private _hasFile(event: DragEvent): boolean {
        return (event.dataTransfer?.types ?? []).includes('Files');
    }

    private _onDragEnter = (): void => {
        this.isDragging.set(true);
    };

    private _onDragOver = (event: DragEvent): void => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
    };

    private _onDragLeave = (event: DragEvent): void => {
        event.preventDefault();
        this.isDragging.set(false);
    };

    private _onDrop = (event: DragEvent): void => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
        this.isDragging.set(false);
        for (const file of Array.from(event.dataTransfer?.files ?? [])) {
            this._importMediaFromFile(file);
        }
    };

    // ------- Events handlers : upload from file / gallery ------- //

    async uploadCustomReelThumbnail(event: Event): Promise<void> {
        const target = event.target as HTMLInputElement;
        const files = Array.from(target.files as FileList);
        if (files.length === 1) {
            const media = await this._mediaUploaderService.uploadFromFile(files[0]);
            if (!media) {
                // an error occurred but at this point the error is already displayed so we can
                // ignore this
                return;
            }
            this.onReelThumbnailChange.emit({ type: 'custom', media });
        }
    }

    async chooseCustomReelThumbnailFromGallery(): Promise<void> {
        const medias: Media[] | false = await lastValueFrom(
            this._customDialogService
                .open(MediaPickerModalComponent, {
                    width: '600px',
                    data: {
                        restaurant: this._restaurantsService.currentRestaurant,
                        multi: false,
                        filter: MediaPickerFilter.ONLY_IMAGE,
                        selectedMedias: [],
                        maxMedia: 1,
                    },
                })
                .afterClosed()
        );
        if (!medias || !medias.length) {
            return;
        }
        const newMedia = await this._mediaUploaderService.uploadFromGalleryMediaId(medias[0].id);
        if (newMedia) {
            this.onReelThumbnailChange.emit({ type: 'custom', media: newMedia });
        }
        // an error occurred but at this point the error is already displayed so we can
        // ignore this
    }

    extractReelThumbnailFromVideo(timeFloat: number): void {
        const media = this.medias()[0];
        if (media.type !== MediaType.VIDEO || !media.duration) {
            this._toastService.openErrorToast(this._translateService.instant('social_posts.unknown_error'));
            return;
        }
        this.onReelThumbnailChange.emit({
            type: 'videoFrame',
            thumbnailOffsetTimeInMs: timeFloat * media.duration * 1000,
        });
    }

    onImportFromFile(event: Event): void {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        for (const file of Array.from(files)) {
            this._importMediaFromFile(file);
        }
    }

    private async _importMediaFromFile(file: File): Promise<void> {
        const hasFreeSlots = this._hasFreeMediaSlots();
        if (!hasFreeSlots) {
            this._toastService.openWarnToast(this._translateService.instant('social_post_medias.max_medias_error'));
            return;
        }
        const media = await this._mediaUploaderService.uploadFromFile(file);
        if (media) {
            this._onMediaUploaded(media);
        }
    }

    onImportMediaFromGallery(): void {
        const mediaPickerFilter = this.publicationType() === PublicationType.REEL ? MediaPickerFilter.ONLY_VIDEO : '';
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: this.multipleMediaSelection(),
                    filter: mediaPickerFilter,
                    selectedMedias: [],
                    maxMedia: this._getMaxMediaCount(),
                },
            })
            .afterClosed()
            .subscribe({
                next: (medias: Media[] | false) => {
                    if (medias) {
                        medias.forEach((media) => {
                            this._importMediaFromGallery(media.id);
                        });
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    private async _importMediaFromGallery(mediaId: string): Promise<void> {
        const hasFreeSlots = this._hasFreeMediaSlots();
        if (!hasFreeSlots) {
            this._toastService.openWarnToast(this._translateService.instant('social_post_medias.max_medias_error'));
            return;
        }
        const media = await this._mediaUploaderService.uploadFromGalleryMediaId(mediaId);
        if (media) {
            this._onMediaUploaded(media);
        }
    }

    private _hasFreeMediaSlots(): boolean {
        const freeSlots = this._getMaxMediaCount() - this.medias().length - this.uploadingMediaCount();
        return freeSlots > 0;
    }

    /** only if publicationType() is PublicationType.REEL */
    getReelMedia(): (EditionMedia & { type: MediaType.VIDEO }) | undefined {
        if (this.publicationType() !== PublicationType.REEL) {
            throw new Error('not a reel');
        }
        const firstMedia = this.medias()[0];
        if (!firstMedia) {
            return undefined;
        }
        if (firstMedia.type !== MediaType.VIDEO) {
            throw new Error('the media of a reel must be a video');
        }
        return firstMedia;
    }

    // ------- MISC ------- //

    onDeleteReelMedia(): void {
        this.medias.set([]);
    }

    private _getMaxMediaCount(): number {
        if (this.publicationType() === PublicationType.POST) {
            return MAX_POST_MEDIA_COUNT;
        }
        if (this.publicationType() === PublicationType.REEL) {
            return MAX_REEL_MEDIA_COUNT;
        }
        return 1;
    }

    uploadingMediaCount(): number {
        return this._mediaUploaderService.mediaCount();
    }
}
