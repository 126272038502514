<div class="relative flex overflow-hidden rounded-[10px] font-[Helvetica] text-white">
    @if (media(); as media) {
        <app-image-viewer
            class="aspect-9/16 min-w-full"
            [data]="{
                thumbnailUrl: media.thumbnail1024OutsideUrl,
                dimensions: media.thumbnail1024OutsideDimensions,
                transformData: media.transformData,
                videoOptions:
                    media.type === MediaType.VIDEO ? { videoUrl: media.videoUrl, videoDimensions: media.videoDimensions } : undefined,
            }">
        </app-image-viewer>
    } @else {
        <div class="flex aspect-9/16 items-center justify-center bg-malou-color-background-dark">
            <img class="w-full" [src]="'default_post' | imagePathResolver" />
        </div>
    }
    <!-- HEADER -->
    <div class="malou-text-11--semibold absolute left-4 top-3">
        {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram_reel.for_you' | translate }}
    </div>
    <mat-icon class="absolute right-4 top-3" color="white" [svgIcon]="SvgIcon.CAMERA"></mat-icon>

    <!-- FOOTER -->
    <div class="absolute bottom-5 left-4 flex flex-col gap-3">
        <div class="flex items-center gap-2">
            @if (profilePicture(); as profilePicture) {
                <div class="relative flex h-[32px] w-[32px] items-center justify-center">
                    <canvas class="absolute bottom-0 left-0 right-0 top-0" #profilePictureCanvas> </canvas>
                    <img class="h-[25px] w-[25px] rounded-full" [src]="profilePicture" />
                </div>
            }
            <div class="text-[13px] font-bold text-white">{{ usernameWithoutAt() }}</div>
            <div class="rounded-[6px] border border-white px-[10px] text-[12px] font-medium leading-tight">
                <div class="mt-[2px]">
                    {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram_reel.follow' | translate }}
                </div>
            </div>
        </div>

        @if (formattedText(); as formattedText) {
            <div
                class="max-w-[250px] overflow-hidden overflow-ellipsis whitespace-nowrap text-[12px] font-normal"
                [innerHTML]="formattedText | htmlTag"></div>
        }

        <div class="flex items-center gap-2">
            <div class="flex">
                <img
                    class="h-3 w-3 rounded-full object-cover"
                    [src]="'people-1' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'jpeg' }" />
                <img
                    class="-ml-[3px] h-3 w-3 rounded-full object-cover"
                    [src]="'people-2' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'jpeg' }" />
                <img
                    class="-ml-[3px] h-3 w-3 rounded-full object-cover"
                    [src]="'people-3' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'jpeg' }" />
            </div>

            <div
                class="text-[8px] font-normal text-white/80"
                [innerHTML]="
                    'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram_reel.people_commented'
                        | translate: { username: 'malou.food', count: 7427 }
                "></div>
        </div>
    </div>

    <!-- RIGHT SIDEBAR ACTIONS -->
    <div class="malou-text-8--regular absolute bottom-5 right-4 flex flex-col items-center justify-center gap-3">
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'like' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            <div>2,7M</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'comment' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            <div>9 868</div>
        </div>
        <div class="flex flex-col items-center justify-center gap-1">
            <img
                style="filter: brightness(0) invert(1)"
                [src]="'send' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            <div>983 K</div>
        </div>
        <div class="flex items-center justify-center">
            <mat-icon class="!h-4 !w-4" color="white" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
        </div>
        <div class="relative h-[20px] w-[20px] overflow-hidden rounded-[5px] border border-white">
            @if (profilePicture(); as profilePicture) {
                <img class="h-full w-full" [src]="profilePicture" />
            }
            <div class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black/40">
                <img
                    class="h-[7px] w-[7px]"
                    style="filter: brightness(0) invert(1)"
                    [src]="'music-icon' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            </div>
        </div>
    </div>
</div>
