<div class="h-[80vh]">
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div>
                {{ 'social_posts.transform_to_reel_modal.title' | translate }}
            </div>
            <button
                class="malou-btn-icon !rounded-full !bg-malou-color-background-dark"
                mat-icon-button
                [disabled]="isSubmitting()"
                (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <div class="flex flex-col justify-center gap-5 overflow-x-hidden">
                <div class="malou-text-12--regular px-12 text-center text-malou-color-text-2">
                    {{ 'social_posts.transform_to_reel_modal.subtitle' | translate }}
                </div>

                <div class="mb-4 overflow-hidden" #wrapper>
                    <div class="flex items-center justify-center gap-x-4" #mainContainer>
                        <div class="translate-x-[25%] scale-50">
                            <div class="w-[340px]" #postContainer>
                                @if (platformKey === PlatformKey.INSTAGRAM) {
                                    <app-instagram-preview
                                        [readonly]="true"
                                        [text]="post.text"
                                        [username]="username()"
                                        [medias]="post.attachments"
                                        [profilePicture]="profilePictureUrl()"
                                        [hashtags]="selectedHashtagsText"></app-instagram-preview>
                                } @else if (platformKey === PlatformKey.FACEBOOK) {
                                    <app-facebook-preview
                                        [username]="username()"
                                        [profilePicture]="profilePictureUrl()"
                                        [text]="post.text"
                                        [medias]="post.attachments"
                                        [date]="post.plannedPublicationDate"
                                        [location]="post.location"
                                        [hashtags]="selectedHashtagsText"></app-facebook-preview>
                                }
                            </div>
                        </div>
                        <div #arrowContainer>
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ARROW_RIGHT"></mat-icon>
                        </div>
                        <div class="-translate-x-[25%] scale-50">
                            <div class="w-[340px]" #reelContainer>
                                @if (platformKey === PlatformKey.INSTAGRAM) {
                                    <app-instagram-reel-preview
                                        [text]="post.text"
                                        [username]="username()"
                                        [media]="post.attachments[0]"
                                        [profilePicture]="profilePictureUrl()"
                                        [hashtags]="selectedHashtagsText"></app-instagram-reel-preview>
                                } @else if (platformKey === PlatformKey.FACEBOOK) {
                                    <app-facebook-reel-preview
                                        [text]="post.text"
                                        [username]="username()"
                                        [media]="post.attachments[0]"
                                        [profilePicture]="profilePictureUrl()"
                                        [hashtags]="selectedHashtagsText"></app-facebook-reel-preview>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="malou-dialog__footer">
            <div class="flex w-full justify-center gap-x-3">
                <app-button
                    buttonClasses="!h-11 !rounded-[5px]"
                    [theme]="'secondary'"
                    [text]="'social_posts.transform_to_reel_modal.cancel' | translate"
                    [disabled]="isSubmitting()"
                    (click)="close()"></app-button>
                <app-button
                    buttonClasses="!h-11 !rounded-[5px]"
                    [text]="'social_posts.transform_to_reel_modal.confirm' | translate"
                    [loading]="isSubmitting()"
                    (buttonClick)="transformToReel()"></app-button>
            </div>
        </div>
    </div>
</div>
