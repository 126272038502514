<div class="px-8 py-5">
    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
</div>

<ng-template #titleTemplate>
    <div class="flex flex-col items-center py-5">
        <div class="malou-color-text-1 malou-text-18--bold">{{ 'statistics_pdf.seo_pdf.title' | translate }}</div>
        <div class="malou-color-text-2 malou-text-9--regular">{{ { startDate, endDate } | fromToDateFormatter }}</div>
        <span class="malou-text-11--regular malou-color-text-2 text-center italic">
            {{ selectedRestaurantTitle$ | async | statisticsPdfRestaurantsFormatter }}
        </span>
    </div>
</ng-template>

<ng-template #bodyTemplate>
    <ng-container [ngTemplateOutlet]="impressionsTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="keywordSearchImpressions"></ng-container>
    <ng-container [ngTemplateOutlet]="keywordsTemplate"></ng-container>
</ng-template>

<ng-template #keywordSearchImpressions>
    @if (isKeywordsInsightsV2Enabled()) {
        @if ((displayedCharts | includes: InsightsChart.KEYWORD_SEARCH_IMPRESSIONS) && keywordSearchImpressionsHasData()) {
            <app-keyword-search-impressions
                [hiddenDatasetIndexes]="chartOptions[InsightsChart.KEYWORD_SEARCH_IMPRESSIONS]?.hiddenDatasetIndexes ?? []"
                [shouldHideTopKeywordsSeeMoreButton]="true"
                (hasDataChange)="keywordSearchImpressionsHasData.set($event)">
            </app-keyword-search-impressions>
        }
    }
</ng-template>

<ng-template #keywordsTemplate>
    @if ((displayedCharts | includes: InsightsChart.KEYWORDS) && seoKeywordsHasData) {
        @if (isKeywordsInsightsV2Enabled()) {
            <app-statistics-keywords-detail [tableSort]="chartOptions[InsightsChart.KEYWORDS]?.tableSortOptions">
            </app-statistics-keywords-detail>
        } @else {
            <div class="mb-4 flex gap-6">
                <div class="min-w-0 flex-1">
                    <app-statistics-seo-keywords-v3
                        [isCompetitorsColumnShown]="false"
                        [tableSortOptions]="chartOptions[InsightsChart.KEYWORDS]?.tableSortOptions"
                        (hasDataChange)="seoKeywordsHasData = $event">
                    </app-statistics-seo-keywords-v3>
                </div>
            </div>
        }
    }
</ng-template>

<ng-template #impressionsTemplate>
    @if ((displayedCharts | includes: InsightsChart.ACTIONS) || (displayedCharts | includes: InsightsChart.APPARITIONS)) {
        <app-gmb-insights
            [shouldShowActionsChart]="displayedCharts | includes: InsightsChart.ACTIONS"
            [shouldShowImpressionsChart]="displayedCharts | includes: InsightsChart.APPARITIONS"
            [impressionsChartViewBy]="chartOptions[InsightsChart.APPARITIONS]?.viewBy"
            [actionsChartViewBy]="chartOptions[InsightsChart.ACTIONS]?.viewBy"
            [impressionsChartHiddenDatasetIndexes]="chartOptions[InsightsChart.APPARITIONS]?.hiddenDatasetIndexes ?? []"
            [actionsChartHiddenDatasetIndexes]="chartOptions[InsightsChart.ACTIONS]?.hiddenDatasetIndexes ?? []"
            [showViewByTextInsteadOfSelectorActions]="true"
            [showViewByTextInsteadOfSelectorImpressions]="true">
        </app-gmb-insights>
    }
</ng-template>
