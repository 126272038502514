import { z } from 'zod';

import { Locale, PersonType } from '@malou-io/package-utils';

export const handleSubmitRestaurantInformationToHubSpotBodyValidator = z
    .object({
        firstName: z.string(),
        lastName: z.string(),
        email: z.string().email(),
        personType: z.nativeEnum(PersonType),
        phoneNumber: z.string(),
        companyName: z.string(),
        locationCount: z.string(),
        zipCode: z.string(),
        malouDiagnosticIds: z.array(z.string()),
        utm_source: z.string().optional(),
        utm_medium: z.string().optional(),
        utm_campaign: z.string().optional(),
        utm_content: z.string().optional(),
        language: z.nativeEnum(Locale),
        identity: z.string(),
        pageUri: z.string(),
        hubspotutk: z.string()?.optional(),
    })
    .transform((data) => ({
        ...data,
        locationCount: parseInt(data.locationCount),
    }));

export type HandleSubmitRestaurantInformationToHubSpotBodyValidator = z.infer<
    typeof handleSubmitRestaurantInformationToHubSpotBodyValidator
>;
