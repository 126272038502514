import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash';
import { switchMap } from 'rxjs/operators';

import { MediaCategory, Role } from '@malou-io/package-utils';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { ToastService } from ':core/services/toast.service';
import { UsersService } from ':modules/user/users.service';
import { FileUploadComponent } from ':shared/components/file-upload/file-upload.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { isValidEmail } from ':shared/helpers/email.validator';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { MediaService } from '../../media/media.service';
import * as UserActions from '../store/user.actions';
import { User } from '../user';

interface IUserForm {
    name: string;
    lastname: string;
    email: string;
    role: ReturnType<typeof capitalize<Role>>;
    profilePicture: Media;
    profilePictureFile: File | null;
}

@Component({
    selector: 'app-edit-user-modal',
    templateUrl: './edit-user-modal.component.html',
    styleUrls: ['./edit-user-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadComponent,
        InputTextComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
    ],
})
export class EditUserModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    user: User;
    editForm: INullableFormGroup<IUserForm>;

    profilePictureErrorMessage: string;

    constructor(
        public dialogRef: MatDialogRef<EditUserModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            user: User;
        },
        private readonly _usersService: UsersService,
        private readonly _spinnerService: SpinnerService,
        private readonly _mediaService: MediaService,
        private readonly _store: Store<{ user: any }>,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _formBuilder: FormBuilder
    ) {
        this.user = data.user;
    }

    ngOnInit(): void {
        this.editForm = this._formBuilder.group({
            name: [this.user.name, Validators.required],
            lastname: [this.user.lastname, Validators.required],
            email: [this.user.email, [Validators.required, this._customEmailValidation()]],
            role: [{ value: capitalize(this.user.role), disabled: true }, Validators.required],
            profilePicture: [this.user.profilePicture],
            profilePictureFile: [null as File | null],
        });

        this.editForm
            .get('profilePictureFile')
            ?.valueChanges.pipe(
                switchMap((picture: File) => {
                    this._spinnerService.show();
                    return this._mediaService.uploadAndCreateMedia([
                        {
                            data: picture,
                            metadata: {
                                userId: this.user._id,
                                category: MediaCategory.PROFILE,
                            },
                        },
                    ]);
                })
            )
            .subscribe({
                next: (res) => {
                    this._spinnerService.hide();
                    this.editForm.controls['profilePicture'].setValue(res.data[0]);
                    this.editForm.controls['profilePicture'].markAsDirty();
                    this.editForm.controls['profilePictureFile'].markAsPristine();
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this._spinnerService.hide();
                    if (err.error.errorData && err.error.errorData.code === 'LIMIT_FILE_SIZE') {
                        this._toastService.openErrorToast(this._translate.instant('profile.file_too_big'));
                    } else {
                        this._toastService.openErrorToast(this._translate.instant('common.unknown_error'));
                    }
                },
            });
    }

    cancel(): void {
        this.dialogRef.close(true);
    }

    save(): void {
        const formValues = this.editForm.value;
        this._usersService
            .updateUser$(this.user._id, {
                email: formValues.email ?? undefined,
                name: formValues.name ?? undefined,
                lastname: formValues.lastname ?? undefined,
                profilePicture: formValues.profilePicture?.id ?? undefined,
            })
            .subscribe({
                next: (res: any) => {
                    this._store.dispatch(UserActions.editUserInfos({ infos: new User(res.data) }));
                    this._toastService.openSuccessToast(this._translate.instant('profile.saved'));
                    this.dialogRef.close();
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _customEmailValidation(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!isValidEmail(control)) {
                return { error: this._translate.instant('common.invalid_email') };
            }
            return null;
        };
    }
}
