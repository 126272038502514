import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { partition } from 'lodash';
import { combineLatest, forkJoin, map, Observable, switchMap, tap } from 'rxjs';

import { AvegrageTopKeywordSearchImpressionsDto } from '@malou-io/package-dto';
import {
    KeywordSearchImpressionsType,
    KeywordSearchImpressionsTypeExtended,
    MalouComparisonPeriod,
    MonthYearPeriod,
    TOP_AGGREGATED_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
} from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import {
    KeywordSearchImpressionInsights,
    TopKeywordSearchImpressions,
} from ':modules/aggregated-statistics/seo/models/top-keyword-search-impressions.interface';
import { SearchImpressionsTableComponent } from ':modules/aggregated-statistics/seo/top-keyword-search-impressions/search-impressions-table/search-impressions-table.component';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { AggregatedStatisticsState } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Restaurant } from ':shared/models';
import { Illustration } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-top-keyword-search-impressions',
    standalone: true,
    imports: [SkeletonComponent, TranslateModule, NgTemplateOutlet, SearchImpressionsTableComponent],
    templateUrl: './top-keyword-search-impressions.component.html',
    styleUrl: './top-keyword-search-impressions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopKeywordSearchImpressionsComponent implements OnInit {
    readonly shouldHideTableClickableElements = input<boolean>(false);
    readonly isLoadingEvent = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _keywordSearchImpressionsService = inject(KeywordSearchImpressionsService);

    readonly Illustration = Illustration;

    readonly aggStatsFilters$: Observable<AggregatedStatisticsState['filters']> = this._store.select(
        AggregatedStatisticsSelector.selectFilters
    );
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    readonly isLoading: WritableSignal<boolean> = signal(true);

    readonly topKeywordSearchImpressions: WritableSignal<TopKeywordSearchImpressions> = signal({
        [KeywordSearchImpressionsTypeExtended.BRANDING]: [],
        [KeywordSearchImpressionsTypeExtended.DISCOVERY]: [],
        [KeywordSearchImpressionsTypeExtended.MALOU_SELECTED]: [],
    });
    readonly restaurantAndNameMap: WritableSignal<{ [restaurantId: string]: string }> = signal({});
    readonly selectedRestaurantsCount: WritableSignal<number> = signal(0);

    ngOnInit(): void {
        combineLatest([this.aggStatsFilters$, this.selectedRestaurants$])
            .pipe(
                tap(() => this._reset()),
                map(([filters, restaurants]: [AggregatedStatisticsState['filters'], Restaurant[]]) => {
                    const [_businessRestaurants, nonBusinessRestaurants] = partition(restaurants, (restaurant) =>
                        restaurant.isBrandBusiness()
                    );
                    this.restaurantAndNameMap.set(
                        nonBusinessRestaurants.reduce<{ [restaurantId: string]: string }>((acc, restaurant) => {
                            acc[restaurant.id] = restaurant.internalName ?? restaurant.name;
                            return acc;
                        }, {})
                    );
                    return [nonBusinessRestaurants, filters.monthYearPeriod];
                }),
                switchMap(([restaurants, monthYearPeriod]: [Restaurant[], MonthYearPeriod]) => {
                    const restaurantIds = restaurants.map((restaurant) => restaurant.id);
                    this.selectedRestaurantsCount.set(restaurantIds.length);
                    return forkJoin([
                        this._keywordSearchImpressionsService.getTopKeywordSearchImpressionsAggregated({
                            restaurantIds,
                            monthYearPeriod,
                            limit: TOP_AGGREGATED_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
                        }),
                        this._keywordSearchImpressionsService.getTopKeywordSearchImpressionsAggregated({
                            restaurantIds,
                            monthYearPeriod,
                            limit: TOP_AGGREGATED_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
                            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        }),
                    ]);
                })
            )
            .subscribe({
                next: ([currentPeriodData, previousPeriodData]) => {
                    this.isLoading.set(false);
                    this.topKeywordSearchImpressions.set(this._getTopKeywordSearchImpressions({ currentPeriodData, previousPeriodData }));
                    this._store.dispatch({
                        type: AggregatedStatisticsActions.editTopKeywordSearchImpressions.type,
                        data: this.topKeywordSearchImpressions(),
                    });
                    this.isLoadingEvent.emit(false);
                },
                error: (err) => {
                    console.error('err :>>', err);
                    this.isLoading.set(false);
                    this.isLoadingEvent.emit(false);
                },
            });
    }

    private _getTopKeywordSearchImpressions({
        currentPeriodData,
        previousPeriodData,
    }: {
        currentPeriodData: AvegrageTopKeywordSearchImpressionsDto;
        previousPeriodData: AvegrageTopKeywordSearchImpressionsDto;
    }): TopKeywordSearchImpressions {
        const topKeywordSearchImpressions = Object.keys(currentPeriodData).reduce<TopKeywordSearchImpressions>(
            (acc, type) => {
                const data = currentPeriodData[type as KeywordSearchImpressionsType];
                const previousData = previousPeriodData[type as KeywordSearchImpressionsType];

                const typeImpressionsInsights: KeywordSearchImpressionInsights[] = data.map((keyword) => {
                    const previousPeriodKeyword = previousData.find((prevKeyword) => prevKeyword.keywordSearch === keyword.keywordSearch);
                    const evolution = previousPeriodKeyword ? keyword.avgValue - previousPeriodKeyword.avgValue : null;

                    const restaurants = keyword.restaurants.map((restaurant) => {
                        const previousPeriodRestaurant = previousPeriodKeyword?.restaurants.find(
                            (prevRestaurant) => prevRestaurant.restaurantId === restaurant.restaurantId
                        );
                        const evolutionPerRestaurant = previousPeriodRestaurant
                            ? restaurant.avgValuePerRestaurant - previousPeriodRestaurant?.avgValuePerRestaurant
                            : null;
                        return {
                            ...restaurant,
                            name: this.restaurantAndNameMap()[restaurant.restaurantId],
                            evolution: evolutionPerRestaurant,
                        };
                    });

                    return {
                        ...keyword,
                        evolution,
                        restaurants,
                    };
                });

                return { ...acc, [type]: typeImpressionsInsights };
            },
            {
                [KeywordSearchImpressionsTypeExtended.BRANDING]: [],
                [KeywordSearchImpressionsTypeExtended.DISCOVERY]: [],
                [KeywordSearchImpressionsTypeExtended.MALOU_SELECTED]: [],
            }
        );

        return topKeywordSearchImpressions;
    }

    private _reset(): void {
        this.isLoadingEvent.emit(true);
        this.isLoading.set(true);
    }
}
