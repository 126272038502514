<app-text-area
    title=""
    [id]="TEXT_AREA_ID"
    [testId]="'social-post-caption-input'"
    [shouldDisplayAiButton]="true"
    [formControl]="postDescriptionFormControl"
    [placeholder]="'social_posts.upsert_social_post_modal.content_form.caption.write_here_or' | translate"
    [resizable]="false"
    [shouldAutosizeRows]="true"
    [maxLength]="isMapstrPlatformChecked() ? MAPSTR_POST_TEXT_LIMIT : POST_CAPTION_TEXT_LIMIT"
    [showMaxLength]="isMapstrPlatformChecked()"
    [showCounterInTitle]="false"
    [isLoadingAnimationEnabled]="upsertSocialPostAiContext.isTextAreaLoadingAnimationEnabled()"
    [errorMessage]="
        isMapstrPlatformChecked() && postDescriptionFormControl.value.length > MAPSTR_POST_TEXT_LIMIT
            ? ('social_posts.new_social_post.mapstr_text_limit' | translate)
            : postDescriptionFormControl.invalid &&
                postDescriptionFormControl.touched &&
                !upsertSocialPostAiContext.isGeneratingPostTextFromAI()
              ? ('common.required_field' | translate)
              : null
    "
    [omitElement]="postHashtagsComponent()?.nativeElement"
    [resizeToMatchPlaceholder]="true"
    [mentionConfiguration]="mentionConfig()"
    [disabled]="isDisabled()"
    (appClickOutside)="handleClickOutside($event)"
    (mentionSearchTerm)="onSearchTerm($event)"
    (click)="openPostCaptionAiGeneration()">
    <ng-template #loadingAnimationTemplate>
        <app-infinite-text-slide [defaultText]="loadingAnimationDefaultText()" [slideTextList]="loadingAnimationSlideTextList()">
        </app-infinite-text-slide>
    </ng-template>
    @if (!upsertSocialPostAiContext.isTextAreaLoadingAnimationEnabled() && postDescriptionFormControl.value.length === 0) {
        <div class="mt-3 h-full w-max" afterTextAreaAiButtonTemplate (click)="focusTextArea()">
            <button
                class="malou-btn-icon--secondary btn-sm flex items-center !bg-malou-color-chart-purple--accent/10"
                id="aiBtnInsideTextarea"
                mat-button
                [disabled]="isDisabled()"
                (click)="onWriteWithAiClick()">
                <mat-icon class="h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>

                <div class="!malou-text-10--semibold text-malou-color-chart-purple--accent">
                    {{ 'social_posts.upsert_social_post_modal.content_form.caption.write_with_ai' | translate }}
                </div>
            </button>
        </div>
    }
    <div class="flex flex-col" footerTemplate>
        @if (postDescriptionFormControl.value) {
            <app-ai-generation-actions-v2
                optimizeButtonId="tracking_new_social_post_modal_optimize_post_with_ai_button"
                translateButtonIdPrefix="tracking_new_social_post_modal_translate_post_to_"
                [trackerId]="
                    'tracking_new_social_post_modal_translate_' + (isReel() ? 'reel' : 'post') + '_to_custom_language_with_ai_button'
                "
                [disabled]="upsertSocialPostAiContext.isGeneratingPostTextFromAI()"
                [interactions]="upsertSocialPostAiContext.interactions()"
                [onReset]="upsertSocialPostAiContext.resetBrowser()"
                [theme]="AiGenerationActionsDisplayStyle.FILLED"
                [disabled]="isDisabled()"
                (onWandIconClick)="onWriteWithAiClick()"
                (onTranslate)="upsertSocialPostAiContext.translateText($event)"
                (onOptimize)="upsertSocialPostAiContext.optimizePostCaption()"
                (interactionChanged)="upsertSocialPostAiContext.onInteractionChanged($event)"></app-ai-generation-actions-v2>
        }

        <app-social-post-hashtags [isDisabled]="isDisabled()" [postText]="postDescriptionFormControl.value"></app-social-post-hashtags>
    </div>

    <ng-template let-item="item" #mentionListTemplate>
        <div class="flex w-fit items-center gap-x-3">
            <img
                class="h-7.5 w-7.5 rounded-full"
                [defaultImage]="'default-picture-grey' | illustrationPathResolver"
                [lazyLoad]="item.igAccount.profile_picture_url ?? ''"
                [errorImage]="'default-picture-grey' | illustrationPathResolver" />
            <span
                class="malou-text-10--semibold text-malou-color-text-2"
                [matTooltip]="item.igAccount.username.length > 16 ? (item.igAccount.username | shortText: 200) : ''">
                {{ '@' }}{{ item.igAccount.username | shortText: 16 }}
                <span class="malou-text-10--regular italic text-malou-color-text-2"
                    >({{ item.igAccount.followers_count }} {{ 'common.followers' | translate }})</span
                >
            </span>
        </div>
    </ng-template>
</app-text-area>
