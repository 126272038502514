import { KeywordPopularity, RankingPosition, RankingPositionOutOf, RestaurantRankingFormat } from '@malou-io/package-utils';

import { RankingTableDataRow } from ':modules/keywords/keywords.interface';

// Keywords Stats V3

export interface RankingTableDataRowWithStats extends RankingTableDataRow {
    currentPosition?: RankingPositionOutOf;
    isLoading: boolean;

    /** competitors */
    ranking: RestaurantRankingFormat[];

    error: boolean;
    errorData: string;

    positions?: RankingPosition[];
}

export interface KeywordsStatsSummary {
    count: {
        current: number;
        currentDetails: {
            posts: number;
            reviews: number;
        };
        diff: number;
    };
    average: {
        current: number;
        diff: number;
    };
    top20: {
        current: number;
        diff: number;
    };
}

export enum KeywordsStatsColumnV3 {
    KEYWORD = 'keyword',
    LANGUAGE = 'language',
    VOLUME = 'volume',
    POSITION = 'position',
    EVOLUTION = 'evolution',
    COMPETITORS = 'competitors',
}

export const ALWAYS_DISPLAYED_COLUMNS_V3 = [
    KeywordsStatsColumnV3.KEYWORD,
    KeywordsStatsColumnV3.LANGUAGE,
    KeywordsStatsColumnV3.VOLUME,
    KeywordsStatsColumnV3.POSITION,
    KeywordsStatsColumnV3.EVOLUTION,
];

// Keywords Detail Stats

export interface KeywordsDetailsStatsTableRow {
    keywordId: string;
    restaurantKeywordId: string;
    /** Keyword column */
    keyword: string;
    /** Google Position column */
    currentPosition?: RankingPositionOutOf;
    /** Evolution column */
    positions?: RankingPosition[];
    /** Apparition column */
    impression: number | null;
    /** competitors column */
    ranking: RestaurantRankingFormat[];
    /** popularity column */
    popularity: KeywordPopularity;

    isLoading: boolean;
    lastRefresh: Date;
    isWaiting: boolean;
    volume: number;
    volumeFromAPI: number;
    impressionsEvolution: number;
    error: boolean;
    errorData: string;
}

export enum KeywordsDetailStatsColumn {
    KEYWORD = 'keyword',
    POPULARITY = 'popularity',
    POSITION = 'position',
    EVOLUTION = 'evolution',
    APPARITION = 'apparition',
    ADVICE = 'advice',
    COMPETITORS = 'competitors',
}

export const ALWAYS_DISPLAYED_KEYWORDS_DETAIL_COLUMNS = [
    KeywordsDetailStatsColumn.KEYWORD,
    KeywordsDetailStatsColumn.POPULARITY,
    KeywordsDetailStatsColumn.POSITION,
    KeywordsDetailStatsColumn.EVOLUTION,
    KeywordsDetailStatsColumn.APPARITION,
    // uncomment later
    // KeywordsDetailStatsColumn.ADVICE,
];
