import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';

import { isNotNil } from '@malou-io/package-utils';

import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { selectTotemsFilter } from ':modules/statistics/store/statistics.selectors';
import { MAX_TOTEM_NUMBER_FOR_FILTER } from ':shared/constants/filters';
import { LightNfc, Nfc } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class StatisticsFiltersContext {
    private readonly _store = inject(Store);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _nfcService = inject(NfcService);

    readonly currentSelectedRestaurant$ = this._restaurantsService.restaurantSelected$;
    readonly currentSelectedRestaurantId = toSignal(
        this.currentSelectedRestaurant$.pipe(
            filter(isNotNil),
            map((restaurant) => restaurant._id)
        ),
        {
            initialValue: '',
        }
    );

    // TODO: Remove this when the feature flag is removed [@hamza]

    readonly restaurantTotems$: Observable<Nfc[]> = combineLatest([
        this._restaurantsService.restaurantSelected$,
        this._restaurantsService.restaurantLoading$,
    ]).pipe(
        switchMap(([restaurant, isLoading]) =>
            // To reset the filter when the new restaurant fetching is loading
            isLoading
                ? of([])
                : this._nfcService
                      .search({ page: 1, limit: MAX_TOTEM_NUMBER_FOR_FILTER, restaurantId: restaurant?._id })
                      .pipe(map((nfcApiResult) => nfcApiResult.data.map((e) => Nfc.fromNfcDto(e))))
        ),
        takeUntilDestroyed(this._destroyRef)
    );

    readonly selectedTotems$ = combineLatest([this.restaurantTotems$, this._store.select(selectTotemsFilter)]).pipe(
        filter(([totems]) => totems.length > 0),
        map(([totems, selectedTotemIds]) => totems.filter((totem) => selectedTotemIds.includes(totem.id)))
    );

    // -----------------------------------------------------

    readonly restaurantLightTotems$: Observable<LightNfc[]> = combineLatest([
        this._restaurantsService.restaurantSelected$,
        this._restaurantsService.restaurantLoading$,
    ]).pipe(
        filter(([restaurant]) => isNotNil(restaurant)),
        switchMap(([restaurant, isLoading]) => {
            if (isLoading) {
                return of([]);
            }
            return this._nfcService.getLightNfcByRestaurantIds([restaurant!.id]);
        }),
        takeUntilDestroyed(this._destroyRef)
    );

    readonly selectedLightTotems$ = combineLatest([this.restaurantLightTotems$, this._store.select(selectTotemsFilter)]).pipe(
        filter(([totems]) => totems.length > 0),
        map(([totems, selectedTotemIds]) => totems.filter((totem) => selectedTotemIds.includes(totem.id))),
        takeUntilDestroyed(this._destroyRef)
    );
}
