<div class="rounded-[5px] border border-malou-color-border-primary bg-white p-3">
    <div class="flex flex-col gap-3 font-[Helvetica] text-malou-color-text-1">
        <div>
            <div
                class="-ml-[12px] flex min-h-[40px] items-center bg-left-top bg-no-repeat pl-[12px] pt-[8px]"
                [ngStyle]="{
                    backgroundImage:
                        'url(' + ('title-bg' | imagePathResolver: { folder: 'social-post-previews/mapstr', extensionFormat: 'png' }) + ')',
                    backgroundSize: '40px 40px',
                }">
                <span class="text-[13px] font-medium">
                    {{ title() }}
                </span>
            </div>
            <div class="text-[11px] font-normal" [innerHTML]="formattedText()"></div>
        </div>

        <div>
            @if (media(); as media) {
                <div class="aspect-[2] w-full overflow-hidden rounded-t-[6px]">
                    <app-image-viewer
                        [data]="{
                            thumbnailUrl: media.thumbnail1024OutsideUrl,
                            dimensions: media.thumbnail1024OutsideDimensions,
                            transformData: media.transformData,
                        }"></app-image-viewer>
                </div>
            } @else {
                <div class="flex aspect-[2] items-center justify-center overflow-hidden rounded-t-[6px] bg-malou-color-background-dark">
                    <img class="w-[200px]" [src]="'default_post' | imagePathResolver" />
                </div>
            }
            <div class="flex justify-between rounded-b-[6px] border border-malou-color-border-primary p-3">
                <div class="flex items-center gap-x-2">
                    <div
                        class="flex h-[26px] w-[26px] items-center justify-center rounded-full"
                        [ngStyle]="{ backgroundColor: malouChartColorMapstr }">
                        <img
                            class="h-[18px] w-[18px] fill-white"
                            [src]="'cuttlery' | imagePathResolver: { folder: 'social-post-previews/mapstr', extensionFormat: 'svg' }" />
                    </div>
                    <div class="text-malou-color-text-2">
                        <div class="text-[10px] font-medium">{{ currentRestaurant.name }}</div>
                        <div class="text-[8px]">
                            {{ currentRestaurant | applySelfPure: 'getFormattedAddressWithPostalCodeAndLocality' }}
                        </div>
                    </div>
                </div>

                @if (callToActionType(); as callToActionType) {
                    <div
                        class="flex h-[26px] items-center rounded-[4px] px-2 text-white"
                        [ngStyle]="{ backgroundColor: malouChartColorMapstr }">
                        <span class="text-[10px] font-bold">
                            {{ callToActionType | enumTranslate: 'mapstr_cta_button_type' }}
                        </span>
                    </div>
                } @else {
                    <div></div>
                }
            </div>

            <div class="mt-1 flex justify-end">
                @if (formattedDate(); as formattedDate) {
                    <span class="text-[8px] text-[#C9C9C9]">
                        {{ formattedDate }}
                    </span>
                }
            </div>
        </div>
    </div>
</div>
