import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { filter, Observable, of, switchMap } from 'rxjs';

import { DialogService } from ':core/services/dialog.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { HoursModalContext } from ':modules/informations/hours-modal/hours-modal.context';
import { HoursModalService } from ':modules/informations/hours-modal/hours-modal.service';
import { ScheduleHistoryRowComponent } from ':modules/informations/hours-modal/special-hours-form/schedule-history-row/schedule-history-row.component';
import { SpecialHoursCalendarEventComponent } from ':modules/informations/hours-modal/special-hours-form/special-hours-calendar-event/special-hours-calendar-event.component';
import { SpecialPeriodFormComponent } from ':modules/informations/hours-modal/special-hours-form/special-period-form/special-period-form.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Step } from ':shared/interfaces/step.interface';
import { Period, SpecialDatePeriod } from ':shared/models';
import { CalendarEvent } from ':shared/models/calendar-event';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-special-hours-form',
    templateUrl: './special-hours-form.component.html',
    styleUrls: ['./special-hours-form.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SpecialHoursCalendarEventComponent,
        SpecialPeriodFormComponent,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        ScheduleHistoryRowComponent,
        ScrollingModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialHoursFormComponent {
    readonly disabled = input<boolean>(false);

    readonly SvgIcon = SvgIcon;

    private readonly _dialogService = inject(DialogService);
    private readonly _hoursModalContext = inject(HoursModalContext);
    private readonly _hoursModalService = inject(HoursModalService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly DUPLICATE_DIALOG_ID = 'duplicate-special-hours-dialog';
    readonly trackById = TrackByFunctionFactory.get('divId');

    readonly specialPeriods = this._hoursModalContext.hoursModalState.specialHours.specialPeriods;

    readonly futureSpecialPeriods = computed<SpecialDatePeriod[]>(() =>
        this.specialPeriods().filter((period) => !period.endDate || this._isFutureDate(period.endDate.getDate()))
    );

    readonly scheduleHistory = computed(() => {
        const history = this.specialPeriods().filter(
            (period) => period.startDate && period.endDate && period.endDate.getDate() < new Date()
        );
        return history.sort((a, b) => (b?.startDate?.getDate()?.getTime() ?? 0) - (a?.startDate?.getDate()?.getTime() ?? 0));
    });

    readonly openHistorySchedule = signal(false);

    readonly calendarEvents = this._hoursModalContext.hoursModalState.specialHours.calendarEvents;
    readonly filteredCalendarEvents = computed(() => {
        const futureSpecialPeriods = this.futureSpecialPeriods();
        return this.calendarEvents().filter((event) => {
            const eventDateAsTime = DateTime.fromISO(event.startDate).startOf('day').toJSDate().getTime();
            return futureSpecialPeriods.every((period) => {
                if (!period.startDate || !period.endDate) {
                    return true;
                }
                const startDateTime = DateTime.fromJSDate(period.startDate.getDate()).startOf('day').toJSDate().getTime();
                const endDateTime = DateTime.fromJSDate(period.endDate.getDate()).startOf('day').toJSDate().getTime();
                return eventDateAsTime < startDateTime || eventDateAsTime > endDateTime;
            });
        });
    });

    addSpecialPeriod(): void {
        const newSpecialPeriodDivId = this._hoursModalContext.addSpecialPeriodToSpecialHours();
        setTimeout(() => {
            this._scrollToElement(newSpecialPeriodDivId);
        });
    }

    addSpecialPeriodFromCalendarEvent(calendarEvent: CalendarEvent): void {
        const newSpecialPeriodDivId = this._hoursModalContext.addSpecialPeriodToSpecialHoursFromCalendarEvent(calendarEvent);
        setTimeout(() => {
            this._scrollToElement(newSpecialPeriodDivId);
        });
    }

    updateSpecialPeriodStartDate(index: number, startDate: Date | null): void {
        this._hoursModalContext.updateSpecialPeriodStartDate(index, startDate);
    }

    updateSpecialPeriodEndDate(index: number, endDate: Date | null): void {
        this._hoursModalContext.updateSpecialPeriodEndDate(index, endDate);
    }

    deleteSpecialPeriod(index: number): void {
        this._hoursModalContext.removeSpecialPeriodFromSpecialHours(index);
    }

    updateSpecialPeriodTimePeriods(index: number, timePeriods: Period[]): void {
        this._hoursModalContext.updateSpecialPeriodTimePeriods(index, timePeriods);
    }

    updateSpecialPeriodIsClosed(index: number, isClosed: boolean): void {
        this._hoursModalContext.updateSpecialPeriodIsClosed(index, isClosed);
    }

    updateSpecialPeriodName(index: number, name: string): void {
        this._hoursModalContext.updateSpecialPeriodName(index, name);
    }

    canDuplicateSpecialPeriod = (specialPeriod: SpecialDatePeriod): boolean => {
        const isValid = specialPeriod.areDatesValid() && specialPeriod.arePeriodsValid();
        return isValid && !!specialPeriod.startDate && this._isFutureDate(specialPeriod.startDate.getDate());
    };

    duplicateSpecialPeriod(specialDatePeriod: SpecialDatePeriod): void {
        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness: false,
            selectedRestaurants: [],
        };

        const steps = this._getStepsForDuplication(specialDatePeriod);

        this._customDialogService.open(StepperModalComponent, {
            width: '900px',
            height: 'unset',
            id: this.DUPLICATE_DIALOG_ID,
            data: {
                steps,
                initialData,
                title: this._translateService.instant('duplicate_to_restaurants_dialog.title'),
                onSuccess: ({ success }: { success: boolean }) => {
                    if (success) {
                        this._onDuplicationSuccess();
                    } else {
                        this._onDuplicationError();
                    }
                },
                onError: (error: unknown) => {
                    this._onDuplicationError(error);
                },
                shouldDisplayConfirmationCloseModalAfterClosed: true,
            },
        });
    }

    toggleHistorySchedule(): void {
        this.openHistorySchedule.set(!this.openHistorySchedule());
    }

    private _isFutureDate(date: Date): boolean {
        return date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    }

    private _scrollToElement(elementId: string): void {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    private _getStepsForDuplication(specialDatePeriod: SpecialDatePeriod): Step[] {
        return [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                primaryButtonText: this._translateService.instant('common.duplicate'),
                nextFunction$: (data: RestaurantsSelectionData): Observable<{ success: boolean }> =>
                    this._dialogService
                        .open<Observable<{ success: boolean }> | null>({
                            title: this._translateService.instant('information.duplication_warning_dialog_title'),
                            message: this._translateService.instant('information.special_hours.duplication_warning_dialog_message'),
                            variant: DialogVariant.INFO,
                            primaryButton: {
                                label: this._translateService.instant('common.update'),
                                action: () => this._duplicateSpecialHours$(data, specialDatePeriod),
                            },
                            secondaryButton: {
                                label: this._translateService.instant('common.back'),
                                action: () => null,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            filter(Boolean),
                            switchMap((res) => res)
                        ),
            },
        ];
    }

    private _duplicateSpecialHours$(
        data: RestaurantsSelectionData,
        specialDatePeriod: SpecialDatePeriod
    ): Observable<{ success: boolean }> {
        const specialHoursToDuplicate = this._hoursModalService.getSpecialHoursFromSpecialHoursState([specialDatePeriod]);
        const restaurantIds = (data.selectedRestaurants ?? []).map((restaurant) => restaurant.id);
        const restaurantId = this._restaurantsService.currentRestaurant._id;

        if (restaurantIds.length === 0 || !restaurantId) {
            return of({ success: false });
        }

        return this._restaurantsService.duplicateSpecialHours(restaurantIds, specialHoursToDuplicate, restaurantId);
    }

    private _onDuplicationSuccess(): void {
        this._toastService.openSuccessToast(this._translateService.instant('information.special_hours.duplicate.success'));
        this._customDialogService.close(this.DUPLICATE_DIALOG_ID);
    }

    private _onDuplicationError(error?: unknown): void {
        console.error(error);
        this._toastService.openErrorToast(this._translateService.instant('information.special_hours.duplicate.error'));
        this._customDialogService.close(this.DUPLICATE_DIALOG_ID);
    }
}
