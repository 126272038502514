import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ImageViewerComponent } from ':modules/posts-v2/social-posts/components/image-viewer/image-viewer.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { FbLocation } from ':shared/models';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { HtmlTagPipe } from ':shared/pipes/html-tag.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-facebook-preview',
    templateUrl: './facebook-preview.component.html',
    styleUrls: ['./facebook-preview.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule, ImageViewerComponent, FormatDatePipe, HtmlTagPipe, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPreviewComponent {
    readonly username = input<string>('');
    readonly profilePicture = input<string | undefined>();
    readonly text = input<string>('');
    readonly medias = input<EditionMedia[]>([]);
    readonly date = input<Date | undefined>();
    readonly location = input<FbLocation | null>();
    readonly hashtags = input<string[]>([]);

    readonly MAX_MEDIA_DISPLAYED_IN_CAROUSEL = 5;
    readonly displayedMedias = computed((): EditionMedia[] => this.medias().slice(0, this.MAX_MEDIA_DISPLAYED_IN_CAROUSEL).reverse());
    readonly mediaNotShownCount = computed((): number => Math.max(this.medias().length - this.MAX_MEDIA_DISPLAYED_IN_CAROUSEL, 0));

    readonly locationName = computed((): string | undefined => this.location()?.name);
    readonly locationCity = computed((): string | undefined => this.location()?.location?.city ?? undefined);

    readonly formattedText = computed((): string => this._formatText(this.text(), this.hashtags()));

    private _formatText(text: string, hashtags: string[]): string {
        const textWithLineBreaks = text.replace(/\n/g, '<br>');

        if (hashtags.length === 0) {
            return textWithLineBreaks;
        }

        const facebookHashtagsColor = '#0064d1';
        const hashtagsText = hashtags.join(' ');
        const hashtagsWithColor = `<span style="color: ${facebookHashtagsColor};">${hashtagsText}</span>`;
        return `${textWithLineBreaks}<br>${hashtagsWithColor}`;
    }
}
