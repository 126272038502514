export enum KeywordSearchImpressionsType {
    DISCOVERY = 'discovery',
    BRANDING = 'branding',
}

export enum KeywordSearchImpressionsTypeExtended {
    DISCOVERY = 'discovery',
    BRANDING = 'branding',
    MALOU_SELECTED = 'malouSelected',
}

export const MAX_GMB_API_FETCH_MONTHS = 17;

export const MONTH_DAY_TO_FETCH_KEYWORD_SEARCH_IMPRESSIONS = 5;

export const TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT = 20;

export const TOP_AGGREGATED_KEYWORD_SEARCH_IMPRESSIONS_LIMIT = 10;

export const MIN_KEYWORD_SEARCH_IMPRESSIONS_VALUE = 15;

export const DEFAULT_KEYWORD_SEARCH = [
    'boulangerie',
    'cafés',
    'supermarché',
    'hôtels',
    'hotel',
    'hotels',
    'paris',
    'restaurant autour de moi',
    'paris hotels',
    'paris restaurants',
    'restaurant',
    'bar',
    'bars',
    'restaurant near me',
    'restaurant paris',
    'restaurants',
    'restaurants paris',
    'restaurantes',
    'restaurantes paris',
];
