<div class="flex h-full flex-col justify-between gap-4">
    <div class="flex items-center gap-4">
        <img class="malou-logo h-8 w-8" [src]="PlatformKey.GMB | platformLogoPathResolver" [alt]="PlatformKey.GMB" />
        <div class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.seo.keyword_search_impressions.insights.title' | translate }}
        </div>
    </div>
    <app-impressions-insights-chart
        [impressionsInsightsData]="impressionsInsightsData()"
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange($event)">
    </app-impressions-insights-chart>
    <ng-container [ngTemplateOutlet]="evolutionTemplate"></ng-container>
</div>

<ng-template #evolutionTemplate>
    <div class="flex gap-4 md:flex-col">
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ impressionsInsightsEvolutionData().branding.total | shortNumber: { shouldDisplayMinusSign: false } }}
                </div>
                <app-number-evolution
                    [value]="impressionsInsightsEvolutionData().branding.evolution"
                    [displayedValue]="
                        impressionsInsightsEvolutionData().branding.evolution | shortNumber: { shouldDisplayMinusSign: false }
                    ">
                </app-number-evolution>
            </div>
            <div class="flex items-center gap-2">
                <span class="malou-text-12--regular malou-color-text-2 whitespace-nowrap pt-1">
                    {{ 'statistics.seo.keyword_search_impressions.branding' | translate }}
                </span>
                <mat-icon
                    class="pdf-hidden !h-4 !w-4"
                    [position]="TooltipPosition.BOTTOM"
                    [appCustomTooltip]="brandingInfoTooltipTemplate"
                    [svgIcon]="SvgIcon.INFO">
                </mat-icon>
            </div>
        </div>
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ impressionsInsightsEvolutionData().discovery.total | shortNumber: { shouldDisplayMinusSign: false } }}
                </div>
                <app-number-evolution
                    [value]="impressionsInsightsEvolutionData().discovery.evolution"
                    [displayedValue]="
                        impressionsInsightsEvolutionData().discovery.evolution | shortNumber: { shouldDisplayMinusSign: false }
                    ">
                </app-number-evolution>
            </div>
            <div class="flex items-center gap-2">
                <span class="malou-text-12--regular malou-color-text-2 whitespace-nowrap pt-1">
                    {{ 'statistics.seo.keyword_search_impressions.discovery' | translate }}
                </span>
                <mat-icon
                    class="pdf-hidden !h-4 !w-4"
                    [position]="TooltipPosition.BOTTOM"
                    [appCustomTooltip]="discoveryInfoTooltipTemplate"
                    [svgIcon]="SvgIcon.INFO">
                </mat-icon>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #brandingInfoTooltipTemplate>
    <div class="max-w-[150px]" [innerHTML]="'statistics.seo.keyword_search_impressions.insights.branding_tooltip' | translate"></div>
</ng-template>

<ng-template #discoveryInfoTooltipTemplate>
    <div class="max-w-[150px]" [innerHTML]="'statistics.seo.keyword_search_impressions.insights.discovery_tooltip' | translate"></div>
</ng-template>
