import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

type Data = any;

@Injectable({ providedIn: 'root' })
export class SemanticAnalysisDetailsCsvInsightsService extends AbstractCsvService<Data> {
    constructor(private readonly _store: Store) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return this._store.select(StatisticsSelectors.selectSemanticAnalysisDetailsData).pipe(take(1));
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Date Start', 'Date End', 'Category', 'Topic', 'Sentiment', 'Review', 'Review ID'];
    }

    protected override _getCsvDataRows(data: Exclude<Data, undefined>): CsvAsStringArrays {
        return data.map((topic) => [
            topic.startDate,
            topic.endDate,
            topic.category,
            topic.name,
            topic.sentiment,
            topic.review,
            topic.reviewId,
        ]);
    }
}
