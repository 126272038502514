import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, OnInit, output, signal, Signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ReviewAnalysisChartDataSentiment, ReviewAnalysisChartDataTag } from '@malou-io/package-utils';

import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { TagsEvolutionChartComponent } from ':shared/components/review-analyses-v2/tags-evolution/tags-evolution-chart/tags-evolution-chart.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { NavBarTab, TabNavBarComponent } from ':shared/components/tab-nav-bar/tab-nav-bar.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ViewBy } from ':shared/enums/view-by.enum';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

const analysisTagsSorted: ReviewAnalysisChartDataTag[] = [
    ReviewAnalysisChartDataTag.FOOD,
    ReviewAnalysisChartDataTag.SERVICE,
    ReviewAnalysisChartDataTag.ATMOSPHERE,
    ReviewAnalysisChartDataTag.PRICE,
    ReviewAnalysisChartDataTag.EXPEDITIOUSNESS,
    ReviewAnalysisChartDataTag.HYGIENE,
];

@Component({
    selector: 'app-tags-evolution',
    templateUrl: './tags-evolution.component.html',
    styleUrls: ['./tags-evolution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatIconModule,
        TabNavBarComponent,
        TagsEvolutionChartComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        LowerCasePipe,
    ],
    providers: [EnumTranslatePipe],
})
export class TagsEvolutionComponent implements OnInit {
    chartData = input.required<ReviewAnalysesChartDataByRestaurantId>();
    restaurants = input<Restaurant[]>([]);
    shouldDetailTagsEvolutionCharts = input.required<boolean>();
    warningTooltip = input<string>();
    sortBy = input<ChartSortBy>();
    sortByChange = output<ChartSortBy>();
    readonly reviewAnalysesFilter = input.required<ReviewAnalysesChartFilter | null>();

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly CHART_SORT_BY_VALUES = Object.values(ChartSortBy);

    hasData: Signal<boolean> = computed(() => this.chartData().hasData(ReviewAnalysisChartDataTag.TOTAL));
    filteredTags: Signal<ReviewAnalysisChartDataTag[]> = computed(() => {
        const chartData = this.chartData();
        return Object.values(analysisTagsSorted).filter((tag: ReviewAnalysisChartDataTag) =>
            chartData.hasData(tag)
        ) as ReviewAnalysisChartDataTag[];
    });
    tabs: Signal<NavBarTab[]> = computed(() => {
        const chartData = this.chartData();
        const tags = this.filteredTags();
        return tags
            .map((tag) => ({
                title: this._translate.instant(`reviews.review_analyses.${tag}`),
                subtitle: `(${chartData.getCount(tag, ReviewAnalysisChartDataSentiment.TOTAL)})`,
                data: tag,
            }))
            .sort((a, b) => analysisTagsSorted.indexOf(a.data) - analysisTagsSorted.indexOf(b.data));
    });

    currentTag: WritableSignal<ReviewAnalysisChartDataTag> = signal(ReviewAnalysisChartDataTag.FOOD);

    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.ALPHABETICAL) as FormControl<ChartSortBy>;

    constructor(
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe
    ) {}

    ngOnInit(): void {
        const sortBy = this.sortBy();
        if (sortBy) {
            this.sortByControl.setValue(sortBy);
        }
    }

    onTabChange(tab: NavBarTab): void {
        this.currentTag.set(tab.data);
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    getChartTitleByTag = (tag: string): string => this._translate.instant(`reviews.review_analyses.${tag}`);

    onSortByChange(sortBy: ChartSortBy): void {
        this.sortByControl.setValue(sortBy);
        this.sortByChange.emit(sortBy);
    }
}
