import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { INSPIRATIONS_ROUTES } from ':modules/inspirations/inspirations.routing';
import { SOCIAL_POSTS_ROUTES } from ':modules/social-posts/social-posts.routing';
import { STORIES_ROUTES } from ':modules/stories/stories.routing';

import { SocialComponent } from './social.component';

export const SOCIAL_ROUTES: Routes = [
    {
        path: '',
        component: SocialComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'socialposts',
            },
            {
                path: 'socialposts',
                loadChildren: () => SOCIAL_POSTS_ROUTES,
                data: { routePath: RoutePath.SOCIAL_POSTS },
            },
            {
                path: 'stories',
                loadChildren: () => STORIES_ROUTES,
                data: { routePath: RoutePath.STORIES },
            },
            {
                path: 'inspirations',
                loadChildren: () => INSPIRATIONS_ROUTES,
                data: { routePath: RoutePath.INSPIRATIONS },
            },
        ],
    },
];
