import { z } from 'zod';

import { PublicationType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const duplicateMediaForPublicationParamsValidator = z.object({
    mediaId: objectIdValidator,
});
export type DuplicateMediaForPublicationParamsDto = z.infer<typeof duplicateMediaForPublicationParamsValidator>;

export const duplicateMediaForPublicationBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    publicationType: z.nativeEnum(PublicationType), // will determine the rules to compute the default transformData
});
export type DuplicateMediaForPublicationBodyDto = z.infer<typeof duplicateMediaForPublicationBodyValidator>;

export type DuplicateMediaForPublicationResponseDto = { duplicatedMediaId: string; restaurantId: string }[];
