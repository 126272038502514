import { TranslateService } from '@ngx-translate/core';
import { capitalize, chunk, meanBy, sumBy } from 'lodash';

import { GetSegmentAnalysesTopTopicsResponseDto, SegmentAnalysisInsightsForRestaurantDto } from '@malou-io/package-dto';
import { ApplicationLanguage, ReviewAnalysisTag } from '@malou-io/package-utils';

import { Restaurant } from ':shared/models';
import { AppInjectorService } from ':shared/services/app-injector.service';

interface DataWithEvolution {
    value: number | null;
    evolution: number | null;
}

export interface RestaurantSemanticAnalysisInsights {
    restaurantId: string;
    restaurantName?: string;
    restaurantLogo?: string;
    sentimentNumber: DataWithEvolution;
    positiveSentimentPercentage: DataWithEvolution;
    foodPositiveSentimentPercentage: DataWithEvolution;
    servicePositiveSentimentPercentage: DataWithEvolution;
    atmospherePositiveSentimentPercentage: DataWithEvolution;
    pricePositiveSentimentPercentage: DataWithEvolution;
    hygienePositiveSentimentPercentage: DataWithEvolution;
    topTopics: {
        positiveTopics: GetSegmentAnalysesTopTopicsResponseDto['positiveTopics'][];
        negativeTopics: GetSegmentAnalysesTopTopicsResponseDto['negativeTopics'][];
    };
}

export namespace SemanticAnalysisInsightsMapper {
    export const ALL_RESTAURANTS_ID = 'ALL';

    export const createSegmentAnalysisInsightsFromDtoArray = (
        data: SegmentAnalysisInsightsForRestaurantDto[],
        restaurants: Restaurant[],
        lang: ApplicationLanguage
    ): RestaurantSemanticAnalysisInsights[] => {
        const mappedRestaurants: RestaurantSemanticAnalysisInsights[] = data.map((insight: SegmentAnalysisInsightsForRestaurantDto) => {
            const associatedRestaurant = restaurants.find((restaurant) => restaurant._id === insight.restaurantId);
            return _buildSingleRestaurantSemanticAnalysisInsights(insight, lang, associatedRestaurant);
        });

        const allRestaurantsData = _getCombinedRestaurantSemanticAnalysisInsights(mappedRestaurants);
        return [allRestaurantsData, ...mappedRestaurants];
    };

    const _buildSingleRestaurantSemanticAnalysisInsights = (
        data: SegmentAnalysisInsightsForRestaurantDto,
        lang: ApplicationLanguage,
        restaurant?: Restaurant
    ): RestaurantSemanticAnalysisInsights => {
        const { totalSentiment, totalSentimentEvolution, totalPositiveSentiment, totalPositiveSentimentEvolution } = _getTotalValues(data);
        return {
            restaurantId: data.restaurantId,
            restaurantName: restaurant?.internalName || restaurant?.name,
            restaurantLogo: restaurant?.logo?.getMediaUrl('small'),

            sentimentNumber: {
                value: totalSentiment,
                evolution: totalSentimentEvolution,
            },
            positiveSentimentPercentage: {
                value: Math.round((totalPositiveSentiment * 100) / totalSentiment),
                evolution: totalSentimentEvolution ? Math.round((totalPositiveSentimentEvolution * 100) / totalSentimentEvolution) : 0,
            },
            foodPositiveSentimentPercentage: {
                value: _getPositiveSentimentPercentageForCategory(data, ReviewAnalysisTag.FOOD),
                evolution: _getPositiveSentimentEvolutionPercentageForCategory(data, ReviewAnalysisTag.FOOD),
            },
            servicePositiveSentimentPercentage: {
                value: _getPositiveSentimentPercentageForCategory(data, ReviewAnalysisTag.SERVICE),
                evolution: _getPositiveSentimentEvolutionPercentageForCategory(data, ReviewAnalysisTag.SERVICE),
            },
            atmospherePositiveSentimentPercentage: {
                value: _getPositiveSentimentPercentageForCategory(data, ReviewAnalysisTag.ATMOSPHERE),
                evolution: _getPositiveSentimentEvolutionPercentageForCategory(data, ReviewAnalysisTag.ATMOSPHERE),
            },
            pricePositiveSentimentPercentage: {
                value: _getPositiveSentimentPercentageForCategory(data, ReviewAnalysisTag.PRICE),
                evolution: _getPositiveSentimentEvolutionPercentageForCategory(data, ReviewAnalysisTag.PRICE),
            },
            hygienePositiveSentimentPercentage: {
                value: _getPositiveSentimentPercentageForCategory(data, ReviewAnalysisTag.HYGIENE),
                evolution: _getPositiveSentimentEvolutionPercentageForCategory(data, ReviewAnalysisTag.HYGIENE),
            },
            topTopics: _separateTopicsInTwoColumns(data.topTopics, lang),
        };
    };

    const _separateTopicsInTwoColumns = (
        topics: GetSegmentAnalysesTopTopicsResponseDto,
        lang: ApplicationLanguage
    ): {
        positiveTopics: GetSegmentAnalysesTopTopicsResponseDto['positiveTopics'][];
        negativeTopics: GetSegmentAnalysesTopTopicsResponseDto['negativeTopics'][];
    } => {
        const positiveTopics = topics.positiveTopics.map((topic) => ({
            ...topic,
            displayedName: capitalize(topic.name),
            displayedNameInCurrentLang: capitalize(topic.translations[lang]),
        }));
        const negativeTopics = topics.negativeTopics.map((topic) => ({
            ...topic,
            displayedName: capitalize(topic.name),
            displayedNameInCurrentLang: capitalize(topic.translations[lang]),
        }));
        return {
            positiveTopics: _splitArrayInHalf(positiveTopics),
            negativeTopics: _splitArrayInHalf(negativeTopics),
        };
    };

    const _splitArrayInHalf = (list: any[]): any[][] => {
        const middleIndex = Math.ceil(list.length / 2);
        return chunk(list, middleIndex);
    };

    const _getTotalValues = (
        data: SegmentAnalysisInsightsForRestaurantDto
    ): {
        totalSentiment: number;
        totalSentimentEvolution: number;
        totalPositiveSentiment: number;
        totalPositiveSentimentEvolution: number;
    } => {
        const totalPositiveSentiment = sumBy(Object.values(data.sentimentsByCategory), (category) => category.positiveCount);
        const totalPositiveSentimentEvolution = sumBy(
            Object.values(data.sentimentsByCategory),
            (category) => category.positiveCountEvolution
        );
        const totalNegativeSentiment = sumBy(Object.values(data.sentimentsByCategory), (category) => category.negativeCount);
        const totalNegativeSentimentEvolution = sumBy(
            Object.values(data.sentimentsByCategory),
            (category) => category.negativeCountEvolution
        );
        const totalSentiment = totalPositiveSentiment + totalNegativeSentiment;
        const totalSentimentEvolution = totalPositiveSentimentEvolution + totalNegativeSentimentEvolution;

        return {
            totalSentiment,
            totalSentimentEvolution,
            totalPositiveSentiment,
            totalPositiveSentimentEvolution,
        };
    };

    const _getSentimentTotalCountForCategory = (
        insights: SegmentAnalysisInsightsForRestaurantDto,
        category: ReviewAnalysisTag
    ): number | null => {
        const categoryInsights = insights.sentimentsByCategory[category];
        if (!categoryInsights) {
            return null;
        }
        return categoryInsights.positiveCount + categoryInsights.negativeCount;
    };

    const _getPositiveSentimentPercentageForCategory = (
        insights: SegmentAnalysisInsightsForRestaurantDto,
        category: ReviewAnalysisTag
    ): number | null => {
        const categoryInsights = insights.sentimentsByCategory[category];
        if (!categoryInsights) {
            return null;
        }
        const total = _getSentimentTotalCountForCategory(insights, category);
        return Math.round(total ? (categoryInsights.positiveCount * 100) / total : 0);
    };

    const _getPositiveSentimentEvolutionPercentageForCategory = (
        insights: SegmentAnalysisInsightsForRestaurantDto,
        category: ReviewAnalysisTag
    ): number | null => {
        const categoryInsights = insights.sentimentsByCategory[category];
        if (!categoryInsights) {
            return null;
        }
        return Math.round(
            categoryInsights.positiveCountEvolution ? (categoryInsights.positiveCountEvolution * 100) / categoryInsights.positiveCount : 0
        );
    };

    const _getCombinedRestaurantSemanticAnalysisInsights = (
        restaurantSemanticAnalysisInsights: RestaurantSemanticAnalysisInsights[]
    ): RestaurantSemanticAnalysisInsights => {
        const translateService = AppInjectorService.getInjector().get(TranslateService);
        return {
            restaurantId: ALL_RESTAURANTS_ID,
            restaurantName: translateService.instant('admin.notifications.all_businesses'),
            restaurantLogo: '',
            sentimentNumber: {
                value: Math.round(sumBy(restaurantSemanticAnalysisInsights, (data) => data.sentimentNumber.value ?? 0)),
                evolution: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.sentimentNumber.evolution)),
            },
            positiveSentimentPercentage: {
                value: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.positiveSentimentPercentage.value)),
                evolution: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.positiveSentimentPercentage.evolution)),
            },
            foodPositiveSentimentPercentage: {
                value: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.foodPositiveSentimentPercentage.value)),
                evolution: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.foodPositiveSentimentPercentage.evolution)),
            },
            servicePositiveSentimentPercentage: {
                value: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.servicePositiveSentimentPercentage.value)),
                evolution: Math.round(
                    meanBy(restaurantSemanticAnalysisInsights, (data) => data.servicePositiveSentimentPercentage.evolution)
                ),
            },
            atmospherePositiveSentimentPercentage: {
                value: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.atmospherePositiveSentimentPercentage.value)),
                evolution: Math.round(
                    meanBy(restaurantSemanticAnalysisInsights, (data) => data.atmospherePositiveSentimentPercentage.evolution)
                ),
            },
            pricePositiveSentimentPercentage: {
                value: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.pricePositiveSentimentPercentage.value)),
                evolution: Math.round(
                    meanBy(restaurantSemanticAnalysisInsights, (data) => data.pricePositiveSentimentPercentage.evolution)
                ),
            },
            hygienePositiveSentimentPercentage: {
                value: Math.round(meanBy(restaurantSemanticAnalysisInsights, (data) => data.hygienePositiveSentimentPercentage.value)),
                evolution: Math.round(
                    meanBy(restaurantSemanticAnalysisInsights, (data) => data.hygienePositiveSentimentPercentage.evolution)
                ),
            },
            topTopics: {
                positiveTopics: [],
                negativeTopics: [],
            },
        };
    };
}
