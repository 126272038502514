import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, model, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApplicationLanguage, isSameDay } from '@malou-io/package-utils';

import { times } from ':core/constants';
import { LocalStorage } from ':core/storage/local-storage';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { getTimeStringFromDate, isPastHour } from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

@Component({
    selector: 'app-schedule-post-form',
    standalone: true,
    imports: [
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        InputDatePickerComponent,
        SimpleSelectComponent,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSelectModule,
        FormatTimePipe,
        SelectComponent,
        ApplyPurePipe,
        SlideToggleComponent,
        NgClass,
    ],
    templateUrl: './schedule-post-form.component.html',
    styleUrl: './schedule-post-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulePostFormComponent {
    readonly scheduleForm = input.required<
        FormGroup<{
            status: FormControl<PostDateStatus | null>;
            plannedPublicationDate: FormControl<Date | null>;
            postTime: FormControl<string | null>;
        }>
    >();
    readonly willPostAllAtSameTime = model(false);

    readonly MIN_DATE = new Date();
    readonly TIMES = times;
    readonly SvgIcon = SvgIcon;
    readonly PostDateStatus = PostDateStatus;
    readonly postDateStatuses = Object.values(PostDateStatus);

    readonly locale = LocalStorage.getLang();

    readonly filteredTimes = computed(() => {
        const now = new Date();
        const selectedDate = this.currentDate() ?? this.scheduleForm().get('plannedPublicationDate')?.value;
        if (selectedDate && isSameDay(selectedDate, now)) {
            const nowTime = this._getTimeStringFromDate(now, false);
            return times.filter((time) => time >= nowTime);
        }
        return times;
    });

    readonly currentTime = signal<string | null>(null);
    readonly currentDate = signal<Date | null>(null);
    readonly currentStatus = signal<PostDateStatus | null>(null);

    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly currentLang = signal<ApplicationLanguage>(inject(TranslateService).currentLang as ApplicationLanguage);
    isPastHour = isPastHour;

    constructor() {
        effect(() => {
            const scheduleForm = this.scheduleForm();

            scheduleForm.controls.plannedPublicationDate.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((date) => {
                this.currentDate.set(date);
            });
            scheduleForm.controls.postTime.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((time) => {
                this.currentTime.set(time);
            });
            scheduleForm.controls.status.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((status) => {
                this.currentStatus.set(status);
            });
        });
    }

    statusDisplayWith = (status: PostDateStatus): string => this._translateService.instant('posts.duplication_modal.status.' + status);

    get plannedPublicationDate(): Date {
        return this.scheduleForm().get('plannedPublicationDate')!.value!;
    }

    changeSelectedTime(value: string | null): void {
        if (!value) {
            return;
        }
        this.scheduleForm().patchValue({
            postTime: value,
        });
    }

    onToggleWillPostAllAtSameTime(): void {
        this.willPostAllAtSameTime.update((value) => !value);
    }

    private _getTimeStringFromDate(date: Date, rounded = true): string {
        const dateCopy = new Date(date);
        const nearestQuarter = rounded ? Math.ceil(dateCopy.getMinutes() / 15) * 15 : dateCopy.getMinutes();
        dateCopy.setMinutes(nearestQuarter);
        const formattedTime = getTimeStringFromDate(dateCopy).split(' ')[0];
        return formattedTime;
    }
}
