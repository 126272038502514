import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { partition } from 'lodash';
import { DateTime } from 'luxon';
import { combineLatest, forkJoin, map, Observable, switchMap, tap } from 'rxjs';

import { AggregationType, GeoSamplePlatform, MalouComparisonPeriod, MonthYearPeriod } from '@malou-io/package-utils';

import { KeywordsService } from ':core/services/keywords.service';
import { AggregatedStatisticsHttpErrorPipe } from ':modules/aggregated-statistics/aggregated-statistics-http-error.pipe';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import {
    KeywordSearchImpressionsTotal,
    RestaurantsRankings,
} from ':modules/aggregated-statistics/seo/models/keyword-search-impressions.interface';
import { KeywordTableDataRow } from ':modules/aggregated-statistics/seo/models/keyword-table-data-row';
import { RestaurantWithKeywordsAndTopMap } from ':modules/aggregated-statistics/seo/models/restaurant-with-keywords-and-top';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { AggregatedStatisticsState } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelector from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum KeywordTableColumns {
    RESTAURANT_NAME = 'restaurantName',
    CURRENT_TOP_20 = 'currentTop20',
    CURRENT_TOP_3 = 'currentTop3',
    BRANDING_KEYWORD_IMPRESSIONS = 'brandingKeywordImpressions',
    DISCOVERY_KEYWORD_IMPRESSIONS = 'discoveryKeywordImpressions',
}

@Component({
    selector: 'app-keywords-v4',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatIconModule,
        MatButtonToggleModule,
        SortByFiltersComponent,
        MatTableModule,
        MatSortModule,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        TranslateModule,
        ApplySelfPurePipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ShortNumberPipe,
        NumberEvolutionComponent,
    ],
    templateUrl: './keywords-v4.component.html',
    styleUrl: './keywords-v4.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AggregatedStatisticsHttpErrorPipe],
})
export class KeywordsV4Component implements OnInit {
    readonly tableSortOptions = input<Sort | undefined>(undefined);
    readonly isLoadingEvent = output<boolean>();
    readonly tableSortOptionsChange = output<Sort>();
    readonly hasDataChange = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _translate = inject(TranslateService);
    private readonly _keywordsService = inject(KeywordsService);
    private readonly _keywordSearchImpressionsService = inject(KeywordSearchImpressionsService);
    private readonly _router = inject(Router);
    private readonly _aggregatedStatisticsHttpErrorPipe = inject(AggregatedStatisticsHttpErrorPipe);

    dataSource: MatTableDataSource<any>;
    readonly DISPLAYED_COLUMNS = [
        KeywordTableColumns.RESTAURANT_NAME,
        KeywordTableColumns.CURRENT_TOP_20,
        KeywordTableColumns.CURRENT_TOP_3,
        KeywordTableColumns.BRANDING_KEYWORD_IMPRESSIONS,
        KeywordTableColumns.DISCOVERY_KEYWORD_IMPRESSIONS,
    ];
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'restaurantName', label: this._translate.instant('aggregated_statistics.seo.keywords.restaurant') },
        { key: 'top20', label: this._translate.instant('aggregated_statistics.seo.keywords.top20') },
        { key: 'top3', label: this._translate.instant('aggregated_statistics.seo.keywords.top3') },
        { key: 'brandingKeywordImpressions', label: this._translate.instant('aggregated_statistics.seo.keywords.branding') },
        { key: 'discoveryKeywordImpressions', label: this._translate.instant('aggregated_statistics.seo.keywords.discovery') },
    ];
    readonly defaultSort: WritableSignal<Sort> = signal({ active: 'currentTop20', direction: ChartSortBy.DESC });

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): number | string => {
                const direction = this.dataSource.sort?.direction === 'desc' ? 1 : -1;
                if (item.restaurantId === 'all') {
                    if (property === 'restaurantName') {
                        return this.dataSource.sort?.direction === 'desc' ? 'ÿ' : '!';
                    }
                    return Number.MAX_SAFE_INTEGER * direction;
                }
                return item[property] ?? 0;
            };
            this.dataSource.sort = sort;
        }
    }

    readonly SvgIcon = SvgIcon;
    readonly KeywordTableColumns = KeywordTableColumns;
    readonly Illustration = Illustration;

    readonly aggStatsFilters$: Observable<AggregatedStatisticsState['filters']> = this._store.select(
        AggregatedStatisticsSelector.selectFilters
    );
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    readonly warningTooltip: WritableSignal<string | undefined> = signal(undefined);
    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly hasData: WritableSignal<boolean> = signal(true);
    readonly errorMessage: WritableSignal<string | undefined> = signal(undefined);

    ngOnInit(): void {
        combineLatest([this.aggStatsFilters$, this.selectedRestaurants$])
            .pipe(
                tap(() => this._reset()),
                map(([filters, restaurants]: [AggregatedStatisticsState['filters'], Restaurant[]]) => {
                    const [businessRestaurants, nonBusinessRestaurants] = partition(restaurants, (r) => r.isBrandBusiness());
                    if (businessRestaurants.length) {
                        this.warningTooltip.set(this._computeWarningTooltip(businessRestaurants));
                    }
                    return [filters, nonBusinessRestaurants];
                }),
                map(([filters, restaurants]: [AggregatedStatisticsState['filters'], Restaurant[]]) => {
                    const monthYearPeriod = filters.monthYearPeriod;
                    return [restaurants, monthYearPeriod];
                }),
                switchMap(([restaurants, monthYearPeriod]: [Restaurant[], MonthYearPeriod]) => {
                    const restaurantIds = restaurants.filter((r) => !r.isBrandBusiness()).map((r) => r._id);
                    const startDate = DateTime.fromObject(monthYearPeriod.startMonthYear).startOf('month').toJSDate();
                    const endDate = DateTime.fromObject(monthYearPeriod.endMonthYear).endOf('month').toJSDate();

                    return forkJoin([
                        this._keywordsService.handleGetKeywordRankingsForManyRestaurantsV3({
                            restaurantIds,
                            startDate,
                            endDate,
                            platformKey: GeoSamplePlatform.GMAPS,
                            doNotFetchRecentSamples: false,
                        }),
                        this._keywordsService.handleGetKeywordRankingsForManyRestaurantsV3({
                            restaurantIds,
                            startDate,
                            endDate,
                            platformKey: GeoSamplePlatform.GMAPS,
                            doNotFetchRecentSamples: false,
                            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        }),
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsAggregatedInsights({
                            monthYearPeriod,
                            restaurantIds,
                            aggregationType: AggregationType.TOTAL,
                        }),
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsAggregatedInsights({
                            monthYearPeriod,
                            restaurantIds,
                            aggregationType: AggregationType.TOTAL,
                            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        }),
                    ]);
                })
            )
            .subscribe({
                next: ([currentRestaurantRankings, previousRestaurantRankings, currentSearchImpressions, previousSearchImpressions]: [
                    RestaurantsRankings,
                    RestaurantsRankings,
                    KeywordSearchImpressionsTotal,
                    KeywordSearchImpressionsTotal,
                ]) => {
                    this.isLoading.set(false);
                    this.hasData.set(!!currentRestaurantRankings?.restaurants?.length);
                    this.errorMessage.set(undefined);
                    this.isLoadingEvent.emit(false);

                    if (this.hasData()) {
                        this._setTableDataSource({
                            currentRestaurantRankings,
                            previousRestaurantRankings,
                            currentSearchImpressions,
                            previousSearchImpressions,
                        });
                    }
                },
                error: (error) => {
                    this.errorMessage.set(this._aggregatedStatisticsHttpErrorPipe.transform(error));
                    this.isLoading.set(false);
                    this.hasData.set(false);
                    this.isLoadingEvent.emit(false);
                },
            });

        const tableSortOptions = this.tableSortOptions();
        if (tableSortOptions) {
            this.defaultSort.set(tableSortOptions);
        }
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || ChartSortBy.ASC, disableClear: true });
    }

    onSortOrderChange(): void {
        const direction = this.dataSource.sort?.direction === ChartSortBy.ASC ? ChartSortBy.DESC : ChartSortBy.ASC;
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active, start: direction, disableClear: true });
    }

    onSortChange(sort: Sort): void {
        this.tableSortOptionsChange.emit(sort);
    }

    goToRestaurantKeywordsStats(keywordTableDataRow: KeywordTableDataRow): void {
        if (keywordTableDataRow.restaurantId === 'all') {
            return;
        }
        void this._router.navigate([`/restaurants/${keywordTableDataRow.restaurantId}/statistics/seo`]);
    }

    private _setTableDataSource({
        currentRestaurantRankings,
        previousRestaurantRankings,
        currentSearchImpressions,
        previousSearchImpressions,
    }: {
        currentRestaurantRankings: RestaurantsRankings;
        previousRestaurantRankings: RestaurantsRankings;
        currentSearchImpressions: KeywordSearchImpressionsTotal;
        previousSearchImpressions: KeywordSearchImpressionsTotal;
    }): void {
        const restaurantWithKeywordsAndTopMap = new RestaurantWithKeywordsAndTopMap(currentRestaurantRankings);
        const previousRestaurantWithKeywordsAndTopMap = new RestaurantWithKeywordsAndTopMap(previousRestaurantRankings);

        const keywordTableDataRow: KeywordTableDataRow[] = currentRestaurantRankings.restaurants.map((restaurant) => {
            const { id } = restaurant.restaurantInfo;

            const restaurantWithKeywordsAndTop = restaurantWithKeywordsAndTopMap.get(id);
            const previousRestaurantWithKeywordsAndTop = previousRestaurantWithKeywordsAndTopMap.get(id);

            return new KeywordTableDataRow({
                restaurantId: id,
                restaurantName: restaurant.restaurantInfo.internalName ?? restaurant.restaurantInfo.name,
                keywords: restaurantWithKeywordsAndTop?.keywords,
                keywordsTop20: restaurantWithKeywordsAndTop?.keywordsTop20,
                keywordsTop3: restaurantWithKeywordsAndTop?.keywordsTop3,
                currentTop20: restaurantWithKeywordsAndTop?.top20,
                currentTop3: restaurantWithKeywordsAndTop?.top3,
                previousTop20: previousRestaurantWithKeywordsAndTop?.top20,
                previousTop3: previousRestaurantWithKeywordsAndTop?.top3,
                brandingKeywordImpressions: currentSearchImpressions[id].total?.branding,
                discoveryKeywordImpressions: currentSearchImpressions[id].total?.discovery,
                previousBrandingKeywordImpressions: previousSearchImpressions[id].total?.branding,
                previousDiscoveryKeywordImpressions: previousSearchImpressions[id].total?.discovery,
            });
        });

        this._setAggregatedKeywordRankingData(keywordTableDataRow);
        this.dataSource = new MatTableDataSource([this._getAllRestaurantsRow(keywordTableDataRow), ...keywordTableDataRow]);
        this.dataSource.sort?.sort({ id: this.defaultSort().active, start: this.defaultSort().direction, disableClear: true });
        if (!keywordTableDataRow.length) {
            this.hasDataChange.emit(false);
        }
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.isLoadingEvent.emit(true);
        this.hasData.set(false);
        this.errorMessage.set(undefined);
    }

    private _computeWarningTooltip(restaurants: Restaurant[] | undefined): string | undefined {
        if (!restaurants?.length) {
            return;
        }
        const restaurantsLabel = restaurants.map((e) => e.name).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _getAllRestaurantsRow(rows: KeywordTableDataRow[]): KeywordTableDataRow {
        const _sumRowsProp = (_rows: KeywordTableDataRow[], prop: string): number =>
            _rows.reduce((acc, next) => acc + (next[prop] ?? 0), 0);

        return new KeywordTableDataRow({
            restaurantId: 'all',
            restaurantName: this._translate.instant('aggregated_statistics.seo.keywords.all_businesses'),
            currentTop20: _sumRowsProp(rows, 'currentTop20'),
            currentTop3: _sumRowsProp(rows, 'currentTop3'),
            previousTop20: _sumRowsProp(rows, 'previousTop20'),
            previousTop3: _sumRowsProp(rows, 'previousTop3'),
            brandingKeywordImpressions: _sumRowsProp(rows, 'brandingKeywordImpressions'),
            discoveryKeywordImpressions: _sumRowsProp(rows, 'discoveryKeywordImpressions'),
            previousBrandingKeywordImpressions: _sumRowsProp(rows, 'previousBrandingKeywordImpressions'),
            previousDiscoveryKeywordImpressions: _sumRowsProp(rows, 'previousDiscoveryKeywordImpressions'),
        });
    }

    private _setAggregatedKeywordRankingData(keywordTableDataRows: KeywordTableDataRow[]): void {
        this._store.dispatch(
            AggregatedStatisticsActions.editKeywordRankings({ data: keywordTableDataRows.map((r) => r.getAggregatedKeywordRakingData()) })
        );
    }
}
