import { KeyValue, KeyValuePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal, viewChild, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime, Interval } from 'luxon';

import { LuxonFormats, MonthAndYear, MonthYearPeriod } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

const DEFAULT_MAX_RANGE_IN_MONTHS = 18;
const DEFAULT_RANGE_IN_MONTHS = 3;

interface MonthYearMap {
    [year: number]: {
        month: number;
        year: number;
        isDisabled: boolean;
        isSelected: boolean;
    }[];
}

@Component({
    selector: 'app-month-year-date-picker-v2',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatListModule,
        KeyValuePipe,
        ApplyPurePipe,
        TranslateModule,
        NgTemplateOutlet,
        MatChipsModule,
        NgClass,
        TranslateModule,
    ],
    templateUrl: './month-year-date-picker-v2.component.html',
    styleUrl: './month-year-date-picker-v2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthYearDatePickerV2Component {
    readonly startDatePickerMenuTrigger = viewChild<MatMenuTrigger>('startdatepickermenutrigger');
    readonly endDatePickerMenuTrigger = viewChild<MatMenuTrigger>('enddatepickermenutrigger');

    readonly maxRangeInMonths = input<number>(DEFAULT_MAX_RANGE_IN_MONTHS);
    readonly initialMonthYearPeriod = input<MonthYearPeriod>(this._getDefaultMonthYearPeriod());
    readonly monthYearPeriodChanged = output<MonthYearPeriod>();

    readonly monthYearPeriod: WritableSignal<MonthYearPeriod> = signal(this.initialMonthYearPeriod());
    readonly startMonthYearIntervals = computed<MonthYearMap>(() =>
        this.computeMonthYearMap({ monthYearPeriod: this.monthYearPeriod(), isStart: true, rangeInMonths: this.maxRangeInMonths() })
    );
    readonly endMonthYearIntervals = computed<MonthYearMap>(() =>
        this.computeMonthYearMap({ monthYearPeriod: this.monthYearPeriod(), isStart: false, rangeInMonths: this.maxRangeInMonths() })
    );

    readonly SvgIcon = SvgIcon;

    constructor() {
        effect(
            () => {
                const monthYearPeriod = this.initialMonthYearPeriod();

                this.monthYearPeriod.set(monthYearPeriod);
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    computeMonthYearMap({
        monthYearPeriod,
        isStart,
        rangeInMonths,
    }: {
        monthYearPeriod: MonthYearPeriod;
        isStart: boolean;
        rangeInMonths: number;
    }): MonthYearMap {
        const _isSameMonthYear = (a: MonthAndYear, b: MonthAndYear): boolean => a.year === b.year && a.month === b.month;
        const _isPrevMonthYear = (a: MonthAndYear, b: MonthAndYear): boolean => a.year < b.year || (a.year === b.year && a.month < b.month);

        const map = Interval.fromDateTimes(
            DateTime.now()
                .minus({ months: rangeInMonths - 1 })
                .startOf('month'),
            DateTime.now()
        )
            .splitBy({ months: 1 })
            .sort((a, b) => a.start.toMillis() - b.start.toMillis())
            .reduce((acc, interval) => {
                const year = interval.start.year;
                const month = interval.start.month;
                if (!acc[year]) {
                    acc[year] = [];
                }
                acc[year].push({
                    year,
                    month,
                    isDisabled: isStart ? false : _isPrevMonthYear({ year, month }, monthYearPeriod.startMonthYear),
                    isSelected: isStart
                        ? _isSameMonthYear(monthYearPeriod.startMonthYear, { year, month })
                        : _isSameMonthYear(monthYearPeriod.endMonthYear, { year, month }),
                });
                return acc;
            }, {});

        return map;
    }

    sortKeys = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => Number(b.key) - Number(a.key);

    onStartDateSelected({ month, year, isDisabled }: { month: number; year: number; isDisabled: boolean; isSelected: boolean }): void {
        if (isDisabled) {
            return;
        }

        const endMonthYear = this.monthYearPeriod().endMonthYear;
        if (DateTime.fromObject({ year, month }) > DateTime.fromObject(endMonthYear)) {
            this.monthYearPeriod.update(() => ({
                startMonthYear: { month, year },
                endMonthYear: { month, year },
            }));
        } else {
            this.monthYearPeriod.update((currentPeriod) => ({
                ...currentPeriod,
                startMonthYear: { month, year },
            }));
        }
        this.monthYearPeriodChanged.emit(this.monthYearPeriod());
        this.startDatePickerMenuTrigger()?.closeMenu();
    }

    onEndDateSelected({ month, year, isDisabled }: { month: number; year: number; isDisabled: boolean; isSelected: boolean }): void {
        if (isDisabled) {
            return;
        }
        if (DateTime.fromObject({ year, month }) < DateTime.fromObject(this.monthYearPeriod().startMonthYear)) {
            this.monthYearPeriod.update(() => ({
                startMonthYear: { month, year },
                endMonthYear: { month, year },
            }));
        } else {
            this.monthYearPeriod.update((currentPeriod) => ({
                ...currentPeriod,
                endMonthYear: { month, year },
            }));
        }

        this.monthYearPeriodChanged.emit(this.monthYearPeriod());
        this.endDatePickerMenuTrigger()?.closeMenu();
    }

    monthAndYearDisplayWith = (monthAndYear: MonthAndYear): string => {
        const lang = LocalStorage.getLang();
        const formattedDate = DateTime.fromObject(monthAndYear).setLocale(lang).toFormat(LuxonFormats.MonthYear);
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    };

    monthDisplayWith = (data: MonthAndYear & { isDisabled: boolean; isSelected: boolean }): string => {
        const lang = LocalStorage.getLang();
        const formattedDate = DateTime.fromObject({ year: data.year, month: data.month }).setLocale(lang).toFormat(LuxonFormats.Month);
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    };

    private _getDefaultMonthYearPeriod(): MonthYearPeriod {
        const startMonthYear = DateTime.now().minus({ months: DEFAULT_RANGE_IN_MONTHS - 1 });
        const endMonthYear = DateTime.now();
        return {
            startMonthYear: { year: startMonthYear.year, month: startMonthYear.month },
            endMonthYear: { year: endMonthYear.year, month: endMonthYear.month },
        };
    }
}
