import { AppFeatureName } from '../experimentation/types';
import { YextPublisherId } from '../modules/yext';
import { ApplicationLanguage, LanguageCodeISO_1 } from './languages';
import { PlatformCategory, PlatformGroup, PlatformKey, PlatformUpdateTime } from './platforms';

export enum InformationKeySent {
    name = 'name',
    coverUrl = 'coverUrl',
    logoUrl = 'logoUrl',
    address = 'address',
    website = 'website',
    categoryName = 'categoryName',
    secondaryCategoriesNames = 'secondaryCategoriesNames',
    menuUrl = 'menuUrl',
    orderUrl = 'orderUrl',
    reservationUrl = 'reservationUrl',
    phone = 'phone',
    openingDate = 'openingDate',
    regularHours = 'regularHours', // (monday, tuesday ...)
    specialHours = 'specialHours', // exceptional (holidays ...)
    otherHours = 'otherHours', // service (happy hours, delivery ...)
    longDescription = 'longDescription',
    isClosedTemporarily = 'isClosedTemporarily',
    shortDescription = 'shortDescription',
    attributes = 'attributes',
    xUrl = 'xUrl',
    instagramUrl = 'instagramUrl',
    facebookUrl = 'facebookUrl',
    linkedinUrl = 'linkedinUrl',
    youtubeUrl = 'youtubeUrl',
    pinterestUrl = 'pinterestUrl',
    tiktokUrl = 'tiktokUrl',
}

export enum Feature {
    INFORMATION = 'information',
    SEO_POSTS = 'seo_posts',
    SOCIAL_MEDIA_POSTS = 'social_media_posts',
    REVIEWS = 'reviews',
    BOOSTERS = 'boosters',
    MESSAGES = 'messages',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    COMMENTS_AND_INTERACTIONS = 'messages',
    STATISTICS = 'statistics',
}

export enum PlatformCostEnum {
    FREE = 'free',
    FREE_WITH_FREEMIUM = 'free_with_freemium',
    RESERVATION_MODULE = 'reservation_module',
    FEES_TO_CLAIM = 'fees_to_claim',
    FROM_249_PER_MONTH = 'from_249_per_month',
    FROM_39_PER_MONTH = 'from_39_per_month',
    SEE_WITH_DELIVEROO = 'see_with_deliveroo',
    SEE_WITH_UBEREATS = 'see_with_ubereats',
    FROM_109_PER_MONTH = 'from_109_per_month',
}

export enum RegistrationTimeEnum {
    FIVE_MINUTES = 'five_minutes',
    TEN_MINUTES = 'ten_minutes',
    FIFTEEN_MINUTES = 'fifteen_minutes',
    TWENTY_MINUTES = 'twenty_minutes',
    SIXTY_MINUTES = 'sixty_minutes',
    INSTANTLY = 'instantly',
    FEW_DAYS_DELIVEROO = 'few_days_deliveroo',
    FEW_DAYS_UBEREATS = 'few_days_ubereats',
}

export enum ValidationPeriodEnum {
    UP_TO_FIVE_DAYS = 'up_to_five_days',
    TEN_MINUTES = 'ten_minutes',
    INSTANTLY = 'instantly',
    SEVERAL_WEEKS = 'several_weeks',
    FEW_DAYS = 'few_days',
    FEW_DAYS_DELIVEROO = 'few_days_deliveroo',
    FEW_DAYS_UBEREATS = 'few_days_ubereats',
    ONE_WEEK_WITH_CHECK = 'one_week_with_check',
}

export interface PlatformDefinition {
    key: PlatformKey;
    fullName: string;

    // Urls
    apiUrl: string;
    apiParams?: string;
    apiVersion?: string;
    baseUrl: string;
    bizUrl: string;
    reviewsUrl: string;
    socialUrl: string;
    updateLink?: (socialId: string) => string;
    accessLink?: (socialId: string) => string;
    externalReviewLink?: string;
    canRedirectToExternalReview: boolean;
    domainMap?: { [key in LanguageCodeISO_1]?: string };

    // Malou configs
    oauth: boolean;
    isAsynchronouslyScrapped: boolean;
    isApiConnectedForPlatformConnection: boolean;
    shouldFetchPermissions: boolean;
    category: PlatformCategory;
    group?: PlatformGroup;
    featureFlagKey?: AppFeatureName;
    allowsReviewReplyAutomation: boolean;

    // Informations
    shouldCompareInformation: boolean;
    hasInformationCategories: boolean;
    hasInformationHours: boolean;
    hasInformationSuggestions: boolean;
    yextPublisherId?: YextPublisherId; // Add it when we have the platform handled by Yext and also by ourselves

    // Tutorials
    updateTime?: PlatformUpdateTime;
    tutorialLink: (lang?: ApplicationLanguage) => string | null;
    cost: PlatformCostEnum | null;
    registrationTime: RegistrationTimeEnum | null;
    validationPeriod: ValidationPeriodEnum | null;
    features?: Feature[];
    informationSent: InformationKeySent[];

    // Review
    hasReviews: boolean;
    canReviewBeModified?: boolean;
    hasComments: boolean;
    hasRating: boolean;
    hasRatingOutOfTen?: boolean;
    hasReviewSemanticAnalysis: boolean;
    shouldDeleteReviewsBeforeUpsert: boolean;
    shouldDetectReviewsLang: boolean;
    // only used for frontend right now
    reviewCanHaveMultipleReplies: boolean;
    maxReviewsDaysInterval?: number;

    // Others
    hasMessages: boolean;
    hasPosts: boolean;
    hasDuplicablePosts?: boolean;
    hasStory: boolean;
    hasFeed: boolean;
    canDeleteStory?: boolean;
    hasMentions: boolean;
    canRedirectInWheelOfFortune: boolean; // Warning: if you change a platform to true, you need to add it in WheelOfFortuneRedirectionAcceptedPlatformKeys as well
    isRatedForTotems: boolean;
    hasFollowers: boolean;
    hasRSStats: boolean;
    hasReel: boolean;
    hasKeywordSearchImpressions: boolean;
}

export const platformsKeys: Record<keyof typeof PlatformKey, PlatformDefinition> = Object.freeze({
    GMB: {
        key: PlatformKey.GMB,
        fullName: 'Google',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        externalReviewLink: 'https://search.google.com/local/writereview?placeid=',
        canRedirectToExternalReview: true,

        accessLink: (socialId: string) => `https://search.google.com/local/writereview?placeid=${socialId}`,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SEO,
        group: PlatformGroup.MUST_HAVE,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: true,
        hasInformationCategories: true,
        hasInformationHours: true,
        hasInformationSuggestions: true,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: true,
        hasDuplicablePosts: true,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: true,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: true,

        cost: PlatformCostEnum.FREE,
        tutorialLink: (): string => '',
        updateTime: PlatformUpdateTime.DAILY,
        registrationTime: RegistrationTimeEnum.TEN_MINUTES,
        validationPeriod: ValidationPeriodEnum.UP_TO_FIVE_DAYS,
        features: [Feature.INFORMATION, Feature.SEO_POSTS, Feature.REVIEWS, Feature.BOOSTERS, Feature.STATISTICS],
        informationSent: [
            InformationKeySent.name,
            InformationKeySent.address,
            InformationKeySent.website,
            InformationKeySent.categoryName,
            InformationKeySent.secondaryCategoriesNames,
            InformationKeySent.phone,
            InformationKeySent.regularHours,
            InformationKeySent.specialHours,
            InformationKeySent.otherHours,
            InformationKeySent.longDescription,
            InformationKeySent.attributes,
            InformationKeySent.menuUrl,
            InformationKeySent.orderUrl,
            InformationKeySent.reservationUrl,
            InformationKeySent.tiktokUrl,
            InformationKeySent.instagramUrl,
            InformationKeySent.facebookUrl,
            InformationKeySent.linkedinUrl,
            InformationKeySent.youtubeUrl,
            InformationKeySent.pinterestUrl,
            InformationKeySent.xUrl,
        ],
    },
    TRIPADVISOR: {
        key: PlatformKey.TRIPADVISOR,
        fullName: 'TripAdvisor',
        baseUrl: 'https://www.tripadvisor.fr',
        apiUrl: 'https://www.tripadvisor.fr/data/graphql',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        updateLink: (socialId: string) => `https://www.tripadvisor.fr/businessinfo?locationId=${socialId}`,
        accessLink: (socialId: string) => `https://www.tripadvisor.fr/overview?locationId=${socialId}`,
        canRedirectToExternalReview: true,
        domainMap: {
            [LanguageCodeISO_1.FR]: 'www.tripadvisor.fr',
            [LanguageCodeISO_1.EN]: 'www.tripadvisor.com',
            [LanguageCodeISO_1.ES]: 'www.tripadvisor.es',
            [LanguageCodeISO_1.IT]: 'www.tripadvisor.it',
            [LanguageCodeISO_1.PT]: 'www.tripadvisor.pt',
            [LanguageCodeISO_1.RU]: 'www.tripadvisor.ru',
            // Japanese
            [LanguageCodeISO_1.JA]: 'www.tripadvisor.jp',
            // Chinese (simplified)
            [LanguageCodeISO_1.ZH]: 'www.tripadvisor.cn',
            // Vietnamese
            [LanguageCodeISO_1.VI]: 'www.tripadvisor.com.vn',
        },

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SEO,
        group: PlatformGroup.MUST_HAVE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        yextPublisherId: YextPublisherId.TRIPADVISOR,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: true,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FREE,
        updateTime: PlatformUpdateTime.WEEKLY,
        registrationTime: RegistrationTimeEnum.FIFTEEN_MINUTES,
        validationPeriod: ValidationPeriodEnum.UP_TO_FIVE_DAYS,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/2974146'
                : 'https://help.malou.io/en/articles/1118658',
        features: [Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS],
        informationSent: [
            InformationKeySent.name,
            InformationKeySent.longDescription,
            InformationKeySent.address,
            InformationKeySent.phone,
            InformationKeySent.regularHours,
            InformationKeySent.attributes,
            InformationKeySent.coverUrl,
            InformationKeySent.logoUrl,
            InformationKeySent.menuUrl,
            InformationKeySent.website,
            InformationKeySent.isClosedTemporarily,
        ],
    },
    INSTAGRAM: {
        key: PlatformKey.INSTAGRAM,
        fullName: 'Instagram',
        apiUrl: 'https://graph.facebook.com',
        baseUrl: 'https://www.instagram.com',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.MUST_HAVE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: false,
        hasComments: true,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: true,
        hasPosts: true,
        hasDuplicablePosts: true,
        hasStory: true,
        hasFeed: true,
        canDeleteStory: false,
        hasMentions: true,
        canRedirectInWheelOfFortune: true,
        isRatedForTotems: false,
        hasFollowers: true,
        hasRSStats: true,
        hasReel: true,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FREE,
        updateTime: PlatformUpdateTime.REAL_TIME,
        features: [Feature.SOCIAL_MEDIA_POSTS, Feature.STATISTICS, Feature.MESSAGES, Feature.BOOSTERS],
        registrationTime: RegistrationTimeEnum.TEN_MINUTES,
        validationPeriod: ValidationPeriodEnum.TEN_MINUTES,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/2547138'
                : 'https://welcomehomemalou.notion.site/Instagram-5601193c94714a2495005b40441800e2',
        informationSent: [],
    },
    FACEBOOK: {
        key: PlatformKey.FACEBOOK,
        fullName: 'Facebook',
        apiUrl: 'https://graph.facebook.com',
        baseUrl: 'https://www.facebook.com',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        externalReviewLink: 'https://www.facebook.com/',
        canRedirectToExternalReview: false,

        accessLink: (socialId: string) => `https://www.facebook.com/${socialId}`,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.SOCIAL,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: true,
        hasInformationCategories: true,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: true,
        hasRating: false, // fb has rating but fb api is broken, we cant access this data: https://developers.facebook.com/community/threads/539633527347097/
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: true,

        hasMessages: true,
        hasPosts: true,
        hasDuplicablePosts: true,
        hasStory: true,
        hasFeed: false,
        hasMentions: true,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: true,
        hasRSStats: true,
        hasReel: true,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FREE,
        updateTime: PlatformUpdateTime.REAL_TIME,
        features: [Feature.INFORMATION, Feature.REVIEWS, Feature.SOCIAL_MEDIA_POSTS, Feature.MESSAGES, Feature.STATISTICS],
        registrationTime: RegistrationTimeEnum.TEN_MINUTES,
        validationPeriod: ValidationPeriodEnum.INSTANTLY,
        tutorialLink: (): string => 'https://help.malou.io/en/articles/2201218',
        informationSent: [
            InformationKeySent.logoUrl,
            InformationKeySent.categoryName,
            InformationKeySent.regularHours,
            InformationKeySent.otherHours,
            InformationKeySent.specialHours,
            InformationKeySent.shortDescription,
            InformationKeySent.attributes,
            InformationKeySent.orderUrl,
            InformationKeySent.website,
            InformationKeySent.secondaryCategoriesNames,
            InformationKeySent.phone,
            InformationKeySent.address,
        ],
    },
    MAPSTR: {
        key: PlatformKey.MAPSTR,
        fullName: 'Mapstr',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.SOCIAL,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: true,
        hasDuplicablePosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FREE_WITH_FREEMIUM,
        updateTime: PlatformUpdateTime.DAILY,
        features: [Feature.INFORMATION, Feature.SOCIAL_MEDIA_POSTS],
        registrationTime: RegistrationTimeEnum.INSTANTLY,
        validationPeriod: ValidationPeriodEnum.INSTANTLY,
        tutorialLink: (): string => 'https://help.malou.io/en/articles/1796034',
        informationSent: [
            InformationKeySent.name,
            InformationKeySent.address,
            InformationKeySent.website,
            InformationKeySent.categoryName,
            InformationKeySent.secondaryCategoriesNames,
            InformationKeySent.phone,
            InformationKeySent.regularHours,
            InformationKeySent.specialHours,
        ],
    },
    TIKTOK: {
        key: PlatformKey.TIKTOK,
        fullName: 'TikTok',
        baseUrl: 'https://www.tiktok.com/',
        apiUrl: 'https://open.tiktokapis.com/v2',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: true,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: true,
        shouldFetchPermissions: true,
        category: PlatformCategory.SOCIAL,
        group: PlatformGroup.SOCIAL,
        featureFlagKey: 'release-tiktok-platform',
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        yextPublisherId: YextPublisherId.TIKTOK,

        hasReviews: false,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        // TODO posts-v2 set to true when tiktok posts will be available
        hasPosts: false,
        hasStory: false,
        // TODO: set this to true when videos list is available ?
        hasFeed: false,
        hasMentions: true,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: true,
        hasRSStats: false,
        hasReel: true,
        hasKeywordSearchImpressions: false,

        updateTime: PlatformUpdateTime.REAL_TIME,
        tutorialLink: (): string => '',
        cost: null,
        registrationTime: null,
        validationPeriod: null,
        features: [Feature.SOCIAL_MEDIA_POSTS],
        informationSent: [],
    },
    YELP: {
        key: PlatformKey.YELP,
        fullName: 'Yelp',
        baseUrl: 'https://yelp.fr',
        bizUrl: 'https://biz.yelp.fr',
        apiUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        updateLink: (socialId: string) => `https://biz.yelp.fr/biz_info/${socialId}`,
        accessLink: (socialId: string) => `https://biz.yelp.fr/home/${socialId}`,
        externalReviewLink: 'https://www.yelp.fr/writeareview/biz/',
        canRedirectToExternalReview: true,
        domainMap: {
            [LanguageCodeISO_1.FR]: 'www.yelp.fr',
            [LanguageCodeISO_1.EN]: 'www.yelp.com',
            [LanguageCodeISO_1.ES]: 'www.yelp.es',
            [LanguageCodeISO_1.IT]: 'www.yelp.it',
            [LanguageCodeISO_1.PT]: 'www.yelp.pt',
            [LanguageCodeISO_1.DE]: 'www.yelp.de',
            [LanguageCodeISO_1.NL]: 'www.yelp.nl',
        },

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,
        yextPublisherId: YextPublisherId.YELP,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FREE,
        updateTime: PlatformUpdateTime.MONTHLY,
        features: [Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS],
        registrationTime: RegistrationTimeEnum.FIVE_MINUTES, //'5 minutes',
        validationPeriod: ValidationPeriodEnum.SEVERAL_WEEKS,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/2419906'
                : 'https://help.malou.io/en/articles/936450',
        informationSent: [
            InformationKeySent.name,
            InformationKeySent.shortDescription,
            InformationKeySent.longDescription,
            InformationKeySent.address,
            InformationKeySent.phone,
            InformationKeySent.regularHours,
            InformationKeySent.specialHours,
            InformationKeySent.attributes,
            InformationKeySent.categoryName,
            InformationKeySent.secondaryCategoriesNames,
            InformationKeySent.isClosedTemporarily,
            InformationKeySent.menuUrl,
            InformationKeySent.website,
            InformationKeySent.openingDate,
        ],
    },
    LAFOURCHETTE: {
        key: PlatformKey.LAFOURCHETTE,
        fullName: 'TheFork',
        baseUrl: 'https://www.lafourchette.com',
        bizUrl: ' https://manager.lafourchette.com',
        apiUrl: 'https://www.lafourchette.com/api/search/autocomplete',
        apiParams:
            '&filter%5Btypes%5D%5B0%5D=RESTAURANT&filter%5Btypes%5D%5B1%5D=RESTAURANT_TAG&filter%5Btypes%5D%5B2%5D=SALE_TYPE_TAG&sort%5BbrandId%5D=1',
        reviewsUrl: '',
        socialUrl: '',
        updateLink: (_socialId: string) => 'https://manager.lafourchette.com/login',
        accessLink: (_socialId: string) => 'https://manager.lafourchette.com/login',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.RESERVATION,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: true,
        canReviewBeModified: true,
        hasComments: false,
        hasRating: true,
        hasRatingOutOfTen: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false, // =false because TheFork is not very consistent when returning reviews
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.RESERVATION_MODULE,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: [Feature.INFORMATION, Feature.REVIEWS],
        registrationTime: RegistrationTimeEnum.TEN_MINUTES,
        validationPeriod: ValidationPeriodEnum.ONE_WEEK_WITH_CHECK,
        tutorialLink: (): string => 'https://welcomehomemalou.notion.site/Lafourchette-10190266a025440e9f500d0c5c666ab6',
        informationSent: [
            InformationKeySent.attributes,
            InformationKeySent.address,
            InformationKeySent.name,
            InformationKeySent.longDescription,
            InformationKeySent.phone,
            InformationKeySent.website,
            InformationKeySent.isClosedTemporarily,
        ],
    },
    FOURSQUARE: {
        key: PlatformKey.FOURSQUARE,
        fullName: 'Foursquare',
        apiUrl: 'https://api.foursquare.com/v2/venues',
        apiVersion: '20180323',
        baseUrl: 'https://fr.foursquare.com',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        updateLink: (socialId: string) => `https://fr.foursquare.com/v/${socialId}`,
        accessLink: (socialId: string) => `https://fr.foursquare.com/v/${socialId}`,
        canRedirectToExternalReview: true,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        yextPublisherId: YextPublisherId.FOURSQUARE,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasRatingOutOfTen: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FEES_TO_CLAIM,
        updateTime: PlatformUpdateTime.MONTHLY,
        features: [Feature.INFORMATION, Feature.REVIEWS, Feature.BOOSTERS],
        registrationTime: RegistrationTimeEnum.TWENTY_MINUTES,
        validationPeriod: ValidationPeriodEnum.SEVERAL_WEEKS,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/2973954'
                : 'https://help.malou.io/en/articles/936706',
        informationSent: [
            InformationKeySent.name,
            InformationKeySent.address,
            InformationKeySent.phone,
            InformationKeySent.regularHours,
            InformationKeySent.specialHours,
            InformationKeySent.attributes,
            InformationKeySent.logoUrl,
            InformationKeySent.website,
            InformationKeySent.isClosedTemporarily,
            InformationKeySent.longDescription,
        ],
    },
    ZENCHEF: {
        key: PlatformKey.ZENCHEF,
        fullName: 'Zenchef',
        baseUrl: 'https://www.zenchef.com/',
        bizUrl: 'https://app.zenchef.com/',
        apiUrl: 'https://api.zenchef.com/api/v1/',
        socialUrl: 'https://atable.zenchef.com/restaurant/',
        reviewsUrl: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: true,
        shouldFetchPermissions: true,
        category: PlatformCategory.RESERVATION,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: true,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FROM_109_PER_MONTH,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: [Feature.REVIEWS],
        registrationTime: RegistrationTimeEnum.SIXTY_MINUTES,
        validationPeriod: ValidationPeriodEnum.FEW_DAYS,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/3085570'
                : 'https://help.malou.io/en/articles/3085506',
        informationSent: [],
    },
    DELIVEROO: {
        key: PlatformKey.DELIVEROO,
        fullName: 'Deliveroo',
        apiUrl: 'https://partner-hub.deliveroo.com/api/',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: true,
        shouldFetchPermissions: true,
        category: PlatformCategory.DELIVERY,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,
        maxReviewsDaysInterval: 100,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        tutorialLink: (): string => '',
        updateTime: PlatformUpdateTime.WEEKLY,
        cost: PlatformCostEnum.SEE_WITH_DELIVEROO,
        features: [Feature.REVIEWS],
        registrationTime: RegistrationTimeEnum.FEW_DAYS_DELIVEROO,
        validationPeriod: ValidationPeriodEnum.FEW_DAYS_DELIVEROO,
        informationSent: [],
    },
    UBEREATS: {
        key: PlatformKey.UBEREATS,
        fullName: 'Uber Eats',
        apiUrl: 'https://api.uber.com/',
        baseUrl: 'https://www.ubereats.com/',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        updateLink: (socialId: string) => `'https://merchants.ubereats.com/manager/stores/${socialId}`,
        accessLink: (socialId: string) => `'https://merchants.ubereats.com/manager/stores/${socialId}`,

        oauth: false,
        isAsynchronouslyScrapped: true,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: true,
        category: PlatformCategory.DELIVERY,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: true,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        yextPublisherId: YextPublisherId.UBEREATS,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: true,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,
        maxReviewsDaysInterval: 100,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.SEE_WITH_UBEREATS,
        updateTime: PlatformUpdateTime.DAILY,
        features: [Feature.INFORMATION, Feature.REVIEWS],
        registrationTime: RegistrationTimeEnum.FEW_DAYS_UBEREATS,
        validationPeriod: ValidationPeriodEnum.FEW_DAYS_UBEREATS,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/2547266'
                : 'https://help.malou.io/en/articles/3924418',
        informationSent: [
            InformationKeySent.isClosedTemporarily,
            InformationKeySent.specialHours,
            InformationKeySent.address,
            InformationKeySent.name,
            InformationKeySent.phone,
        ],
    },
    OPENTABLE: {
        key: PlatformKey.OPENTABLE,
        fullName: 'OpenTable',
        apiUrl: 'https://guestcenter.opentable.com/gateway/proxies',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        updateLink: (socialId: string) => `https://guestcenter.opentable.com/restaurant/${socialId}/profile/basic-information`,
        accessLink: (socialId: string) => `https://guestcenter.opentable.com/restaurant/${socialId}/home`,
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        yextPublisherId: YextPublisherId.OPENTABLE,

        hasReviews: true,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: true,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FROM_39_PER_MONTH,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: [Feature.INFORMATION, Feature.REVIEWS],
        registrationTime: RegistrationTimeEnum.SIXTY_MINUTES,
        validationPeriod: ValidationPeriodEnum.FEW_DAYS,
        tutorialLink: (lang?: ApplicationLanguage): string =>
            lang === ApplicationLanguage.EN
                ? 'https://malouhelpcenter.frontkb.com/en/articles/2433346'
                : 'https://help.malou.io/en/articles/1967618',
        informationSent: [
            InformationKeySent.address,
            InformationKeySent.attributes,
            InformationKeySent.phone,
            InformationKeySent.regularHours,
            InformationKeySent.categoryName,
            InformationKeySent.secondaryCategoriesNames,
            InformationKeySent.website,
        ],
    },
    RESY: {
        key: PlatformKey.RESY,
        fullName: 'Resy',
        baseUrl: 'https://resy.com/',
        apiUrl: '',
        bizUrl: 'https://os.resy.com/portal/',
        reviewsUrl: '',
        socialUrl: '',
        updateLink: (socialId: string) => {
            const slugs = socialId.split('/venues/');
            const locationSlug = slugs[0].split('-').pop();
            const venueSlug = slugs[1];
            return `https://os.resy.com/portal/${locationSlug}/${venueSlug}/venue/listing-info`;
        },
        accessLink: (socialId: string) => {
            const slugs = socialId.split('/venues/');
            const locationSlug = slugs[0].split('-').pop();
            const venueSlug = slugs[1];
            return `https://os.resy.com/portal/${locationSlug}/${venueSlug}/analytics`;
        },
        canRedirectToExternalReview: true,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.RESERVATION,
        group: PlatformGroup.E_REPUTATION,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: true,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: true,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: true,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: true,
        reviewCanHaveMultipleReplies: false,
        maxReviewsDaysInterval: 100,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        cost: PlatformCostEnum.FROM_249_PER_MONTH,
        updateTime: PlatformUpdateTime.WEEKLY,
        features: [Feature.INFORMATION, Feature.REVIEWS],
        registrationTime: RegistrationTimeEnum.SIXTY_MINUTES,
        validationPeriod: ValidationPeriodEnum.FEW_DAYS,
        tutorialLink: (): string => 'https://welcomehomemalou.notion.site/Connecter-manuellement-Resy-e06546c84d554d559d9d3a1d1d529046',
        informationSent: [
            InformationKeySent.address,
            InformationKeySent.attributes,
            InformationKeySent.categoryName,
            InformationKeySent.phone,
            InformationKeySent.longDescription,
        ],
    },
    ABC: {
        // TODO: @Hugo @Maxime, fill with correct values, this is a placeholder
        key: PlatformKey.ABC,
        fullName: 'Apple',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.SEO,
        group: PlatformGroup.SEO,
        featureFlagKey: 'release-abc-platform',
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        // yextPublisherId: YextPublisherId.APPLE, // TODO: Uncomment when APPLE will be released on our side
        updateTime: PlatformUpdateTime.REAL_TIME,

        hasReviews: false,
        canReviewBeModified: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasDuplicablePosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        // TODO: fill
        tutorialLink: (): string => '',
        cost: null,
        registrationTime: null,
        validationPeriod: null,
        informationSent: [],
    },
    PRIVATE: {
        key: PlatformKey.PRIVATE,
        fullName: 'Private',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.PRIVATE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: true,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: false,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: true,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        tutorialLink: (): string => '',
        cost: null,
        registrationTime: null,
        validationPeriod: null,
        informationSent: [],
    },
    WEBSITE: {
        key: PlatformKey.WEBSITE,
        fullName: 'Website',
        apiUrl: '',
        baseUrl: '',
        bizUrl: '',
        reviewsUrl: '',
        socialUrl: '',
        canRedirectToExternalReview: false,

        oauth: false,
        isAsynchronouslyScrapped: false,
        isApiConnectedForPlatformConnection: false,
        shouldFetchPermissions: false,
        category: PlatformCategory.PRIVATE,
        allowsReviewReplyAutomation: false,

        shouldCompareInformation: false,
        hasInformationCategories: false,
        hasInformationHours: false,
        hasInformationSuggestions: false,

        hasReviews: false,
        hasComments: false,
        hasRating: false,
        hasReviewSemanticAnalysis: false,
        shouldDeleteReviewsBeforeUpsert: false,
        shouldDetectReviewsLang: false,
        reviewCanHaveMultipleReplies: true,

        hasMessages: false,
        hasPosts: false,
        hasStory: false,
        hasFeed: false,
        hasMentions: false,
        canRedirectInWheelOfFortune: false,
        isRatedForTotems: false,
        hasFollowers: false,
        hasRSStats: false,
        hasReel: false,
        hasKeywordSearchImpressions: false,

        tutorialLink: (): string => '',
        cost: null,
        registrationTime: null,
        validationPeriod: null,
        informationSent: [],
    },
});
