import { Routes } from '@angular/router';

import { RoutePath } from '@malou-io/package-utils';

import { RoleGuard } from ':core/auth/role.guard';
import { BOOSTERS_ROUTES } from ':modules/boosters/boosters.routing';
import { DASHBOARD_ROUTES } from ':modules/dashboard/dashboard.routing';
import { INTERACTIONS_ROUTES } from ':modules/interactions/interactions.routing';
import { REPUTATION_ROUTES } from ':modules/reputation/reputation.routing';
import { RESOURCES_ROUTES } from ':modules/resources/resources.routing';
import { SEO_ROUTES } from ':modules/seo/seo.routing';
import { SETTINGS_ROUTES } from ':modules/settings/settings.routing';
import { SOCIAL_ROUTES } from ':modules/social/social.routing';
import { STATISTICS_ROUTES } from ':modules/statistics/statistics.routing';
import { RestaurantContainerComponent } from ':shared/components/restaurant-container/restaurant-container.component';

export const RESTAURANTS_ROUTES: Routes = [
    {
        path: '',
        component: RestaurantContainerComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
            },
            {
                path: 'dashboard',
                loadChildren: () => DASHBOARD_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.DASHBOARD },
            },
            {
                path: 'informations',
                redirectTo: 'seo/informations',
            },
            {
                path: 'seo',
                loadChildren: () => SEO_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.SEO },
            },
            {
                path: 'boosters',
                loadChildren: () => BOOSTERS_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.BOOSTERS },
            },
            {
                path: 'reputation',
                loadChildren: () => REPUTATION_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.E_REPUTATION },
            },
            {
                path: 'social',
                loadChildren: () => SOCIAL_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.SOCIAL_NETWORKS },
            },
            {
                path: 'interactions',
                loadChildren: () => INTERACTIONS_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.INTERACTIONS },
            },
            {
                path: 'statistics',
                loadChildren: () => STATISTICS_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.STATISTICS },
            },
            {
                path: 'resources',
                loadChildren: () => RESOURCES_ROUTES,
                data: { routePath: RoutePath.RESOURCES },
            },
            {
                path: 'settings',
                loadChildren: () => SETTINGS_ROUTES,
                canActivate: [RoleGuard],
                data: { roles: ['admin', 'malou-basic'], routePath: RoutePath.SETTINGS },
            },
        ],
    },
];
