import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isInteger } from 'lodash';
import { combineLatest, EMPTY } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { HeapEventName, InsightsChart, InsightsTab, PlatformFilterPage, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import {
    AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT,
    MINIMUM_RESTAURANTS_SELECTED_THRESHOLD,
} from ':modules/aggregated-statistics/aggregated-statistics.constant';
import { ReviewsCountComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-count/reviews-count.component';
import { ReviewsRatingKpisComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-rating-kpis/reviews-rating-kpis.component';
import { ReviewsRatingsAverageComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-ratings-average/reviews-ratings-average.component';
import { ReviewsInsightsComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews.component';
import { AggregatedSemanticAnalysisComponent } from ':modules/aggregated-statistics/e-reputation/semantic-analysis/aggregated-semantic-analysis/aggregated-semantic-analysis.component';
import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import * as StatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions, StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { ReviewAnalysesV2Component } from ':shared/components/review-analyses-v2/review-analyses.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

enum AggregatedEReputationInsightsTabs {
    REVIEWS,
    SEMANTIC_ANALYSIS,
}

@Component({
    selector: 'app-e-reputation',
    templateUrl: './e-reputation.component.html',
    styleUrls: ['./e-reputation.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FiltersComponent,
        ReviewsCountComponent,
        ReviewsInsightsComponent,
        ReviewsRatingsAverageComponent,
        ReviewAnalysesV2Component,
        IllustrationPathResolverPipe,
        ReviewsRatingKpisComponent,
        MatButtonModule,
        MatTabsModule,
        TranslateModule,
        AggregatedSemanticAnalysisComponent,
    ],
})
export class EReputationComponent implements OnInit {
    private readonly _store = inject(Store);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _heapService = inject(HeapService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);
    public readonly screenSizeService = inject(ScreenSizeService);

    readonly PlatformFilterPage = PlatformFilterPage;
    readonly Illustration = Illustration;
    readonly InsightsChart = InsightsChart;

    readonly isNewAggregatedSemanticAnalysisFeatureEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-new-aggregated-semantic-analysis'),
        { initialValue: false }
    );

    readonly platformKeys: Signal<PlatformKey[]> = toSignal(
        this._store.select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })),
        { initialValue: [] }
    );
    readonly hasPlatformsSelected: Signal<boolean> = computed(() => this.platformKeys().length > 0);

    readonly restaurants: Signal<Restaurant[]> = toSignal(this._aggregatedStatisticsFiltersContext.selectedRestaurants$, {
        initialValue: [],
    });
    readonly isRestaurantsCountUiLimitExceeded: Signal<boolean> = computed(
        () => this.restaurants.length > AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT
    );
    readonly hasEnoughRestaurantsSelected: Signal<boolean> = computed(
        () => this.restaurants().length >= MINIMUM_RESTAURANTS_SELECTED_THRESHOLD
    );

    readonly reviewsRatingKpisDisplayedColumnsCount: WritableSignal<number> = signal(0);
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.AGGREGATED_REVIEWS_COUNT]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS]: {
            tableSortOptions: undefined,
        },
    });

    readonly isReviewsRatingsKpisLoading = signal(true);
    readonly isReviewsCountLoading = signal(true);
    readonly isReviewsRatingsAverageLoading = signal(true);
    readonly isReviewsAnalysesLoading = signal(true);
    readonly areReviewsLoading = signal(true);
    readonly selectedIndex = signal(AggregatedEReputationInsightsTabs.REVIEWS);

    readonly isReleaseReviewPerformanceImprovementsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-review-insights-v2'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-review-insights-v2'),
        }
    );

    readonly isLoading = computed(() => {
        const isReviewsLoading = this.isReleaseReviewPerformanceImprovementsEnabled()
            ? this.areReviewsLoading()
            : this.isReviewsRatingsKpisLoading() || this.isReviewsCountLoading() || this.isReviewsRatingsAverageLoading();
        return isReviewsLoading || this.isReviewsAnalysesLoading();
    });

    constructor() {
        effect(
            () => {
                const currentTabIndex = this.selectedIndex();
                this._router.navigate([], {
                    relativeTo: this._activatedRoute,
                    queryParams: { tab: currentTabIndex },
                });
            },
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this._store.dispatch(AggregatedStatisticsActions.editSelectedPage({ page: PlatformFilterPage.E_REPUTATION }));
        this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((params) => {
            const tab = params['tab'];
            const expectedValues = Object.values(AggregatedEReputationInsightsTabs).map((value) => value.toString());
            if (tab && expectedValues.includes(tab.toUpperCase())) {
                if (isInteger(+tab)) {
                    this.selectedIndex.set(tab);
                } else {
                    this.selectedIndex.set(
                        AggregatedEReputationInsightsTabs[tab.toUpperCase() as keyof typeof AggregatedEReputationInsightsTabs]
                    );
                }
            }
        });
    }

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(StatisticsSelectors.selectDatesFilter),
            this._store.select(StatisticsSelectors.selectRestaurantIdsFilter),
            this._store.select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, restaurantIds, platforms]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.AGGREGATED_E_REPUTATION,
                                filters: {
                                    dates: { startDate, endDate },
                                    restaurantIds,
                                    platforms,
                                },
                                chartOptions: this.chartOptions(),
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                chartSortBy: value,
            },
        }));
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                tableSortOptions: value,
            },
        }));
    }

    onViewModeChange(chart: InsightsChart, value: StatisticsDataViewMode): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                viewMode: value,
            },
        }));
    }

    handleTabChange(event: number): void {
        switch (event) {
            case AggregatedEReputationInsightsTabs.REVIEWS:
                this._heapService.track(HeapEventName.TRACKING_CLICK_AGGREGATED_E_REPUTATION_INSIGHTS_REVIEWS_TAB);
                this.selectedIndex.set(AggregatedEReputationInsightsTabs.REVIEWS);
                break;
            case AggregatedEReputationInsightsTabs.SEMANTIC_ANALYSIS:
                this._heapService.track(HeapEventName.TRACKING_CLICK_AGGREGATED_E_REPUTATION_INSIGHTS_SEMANTIC_ANALYSES_TAB);
                this.selectedIndex.set(AggregatedEReputationInsightsTabs.SEMANTIC_ANALYSIS);
                break;
        }
    }
}
