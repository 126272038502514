<div
    class="hide-scrollbar h-full overflow-y-auto"
    data-testid="post-modal-feed"
    cdkDropListGroup
    infinite-scroll
    [infiniteScrollDistance]="2"
    [scrollWindow]="false"
    [infiniteScrollContainer]="scrollContainerSelector"
    (scrolled)="scrolledDown()"
    #scrollContainerSelector>
    <div class="grid grid-cols-3">
        @for (post of posts(); track trackByIdFn($index, post)) {
            <div
                [ngClass]="{
                    'aspect-square': !isFeed45Enabled(),
                    'aspect-[4/5]': isFeed45Enabled(),
                }">
                @let firstAttachment = post.attachments?.[0];
                @let firstSocialAttachment = post.socialAttachments?.[0];
                <app-social-post-media
                    [customMediaClass]="'!rounded-[0]'"
                    [postType]="post.postType"
                    [workingMedia]="post.getSocialMediaUrl() || post.getMalouMediaUrl()"
                    [workingMediaDimensions]="post.getSocialMediaUrl() ? undefined : post.getMalouMediaUrlAndDimensions()?.dimensions"
                    [firstAttachmentType]="post.getFirstMediumType()"
                    [showVideoControls]="false"
                    [thumbnailUrl]="thumbnailUrlsForPosts()[post.id]?.url"
                    [thumbnailDimensions]="thumbnailUrlsForPosts()[post.id]?.dimensions"
                    [videoDimensions]="
                        post.getFirstMediumType() === MediaType.VIDEO && !firstSocialAttachment?.urls?.original
                            ? firstAttachment?.dimensions?.original
                            : undefined
                    "
                    [transformData]="firstSocialAttachment?.urls?.original ? undefined : firstAttachment?.transformData"
                    (refreshMedia)="refreshPost(post)"></app-social-post-media>
            </div>
        }
    </div>
</div>
