import { z } from 'zod';

import { AspectRatio } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const updateTransformDataParamsValidator = z.object({
    mediaId: objectIdValidator,
});

export type UpdateTransformDataParamsDto = z.infer<typeof updateTransformDataParamsValidator>;

export const updateTransformDataBodyValidator = z.object({
    aspectRatio: z.nativeEnum(AspectRatio),
    rotationInDegrees: z.number(),
    left: z.number(),
    top: z.number(),
    width: z.number(),
    height: z.number(),
});

export type UpdateTransformDataBodyDto = z.infer<typeof updateTransformDataBodyValidator>;
