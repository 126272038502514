import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, KeywordSearchImpressionsType, MIN_KEYWORD_SEARCH_IMPRESSIONS_VALUE } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { TopKeywordSearchImpressionsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { TopKeywordSearchModalComponent } from ':modules/statistics/seo/keyword-search-impressions/top-keyword-search-impressions/top-keyword-search-modal/top-keyword-search-modal.component';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CreateIndexArrayPipe } from ':shared/pipes/create-array.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface KeywordsToDisplayCountByType {
    [KeywordSearchImpressionsType.BRANDING]: number;
    [KeywordSearchImpressionsType.DISCOVERY]: number;
}

@Component({
    selector: 'app-top-keyword-search-impressions',
    standalone: true,
    imports: [CreateIndexArrayPipe, TranslateModule, ApplyPurePipe, ShortTextPipe, MatTooltip, NgTemplateOutlet],
    templateUrl: './top-keyword-search-impressions.component.html',
    styleUrl: './top-keyword-search-impressions.component.scss',
    providers: [EmojiPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopKeywordSearchImpressionsComponent {
    readonly topKeywordSearchImpressions = input.required<TopKeywordSearchImpressionsData>();
    readonly shouldHideTopKeywordsSeeMoreButton = input<boolean>(false);

    private readonly _emojiPathResolverPipe = inject(EmojiPathResolverPipe);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _heapService = inject(HeapService);

    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;
    private readonly _KEYWORDS_TO_DISPLAY_LIMIT = 3;
    readonly SHORT_TEXT_LENGTH = 35;
    readonly MIN_KEYWORD_SEARCH_IMPRESSIONS_VALUE = MIN_KEYWORD_SEARCH_IMPRESSIONS_VALUE;

    readonly keywordsToDisplayCountByType = computed<{
        [KeywordSearchImpressionsType.BRANDING]: number;
        [KeywordSearchImpressionsType.DISCOVERY]: number;
    }>(() => this._getKeywordsToDisplayCountByType(this.topKeywordSearchImpressions()));

    openTopKeywordSearchModal(type: KeywordSearchImpressionsType): void {
        this._trackTopKeywordsSeeMore(type);
        this._customDialogService
            .open(TopKeywordSearchModalComponent, {
                width: '40vw',
                data: {
                    topKeywordSearchImpressions: this.topKeywordSearchImpressions(),
                    type,
                },
            })
            .afterClosed();
    }

    getEmojiFromPosition = (position: number): string =>
        [
            this._emojiPathResolverPipe.transform(Emoji.TROPHY),
            this._emojiPathResolverPipe.transform(Emoji.SECOND_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.THIRD_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.PARTY_FACE),
        ][position] ?? this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE);

    getKeywordSearchTooltip = (keywordSearch: string): string => (keywordSearch.length > this.SHORT_TEXT_LENGTH ? keywordSearch : '');

    private _getKeywordsToDisplayCountByType(topKeywordSearchImpressions: TopKeywordSearchImpressionsData): KeywordsToDisplayCountByType {
        const brandingKeywordsCount = topKeywordSearchImpressions[KeywordSearchImpressionsType.BRANDING].length;
        const discoveryKeywordsCount = topKeywordSearchImpressions[KeywordSearchImpressionsType.DISCOVERY].length;

        const totalKeywordsCount = brandingKeywordsCount + discoveryKeywordsCount;
        const maxKeywordsToDisplay = Math.min(totalKeywordsCount, this._KEYWORDS_TO_DISPLAY_LIMIT * 2);

        let brandingToDisplay = Math.min(brandingKeywordsCount, this._KEYWORDS_TO_DISPLAY_LIMIT);
        let discoveryToDisplay = Math.min(discoveryKeywordsCount, this._KEYWORDS_TO_DISPLAY_LIMIT);

        if (brandingToDisplay + discoveryToDisplay < maxKeywordsToDisplay) {
            if (brandingKeywordsCount < this._KEYWORDS_TO_DISPLAY_LIMIT) {
                discoveryToDisplay = Math.min(discoveryKeywordsCount, maxKeywordsToDisplay - brandingToDisplay);
            } else if (discoveryKeywordsCount < this._KEYWORDS_TO_DISPLAY_LIMIT) {
                brandingToDisplay = Math.min(brandingKeywordsCount, maxKeywordsToDisplay - discoveryToDisplay);
            }
        }

        return {
            [KeywordSearchImpressionsType.BRANDING]: brandingToDisplay,
            [KeywordSearchImpressionsType.DISCOVERY]: discoveryToDisplay,
        };
    }

    private _trackTopKeywordsSeeMore(type: KeywordSearchImpressionsType): void {
        this._heapService.track(HeapEventName.TOP_KEYWORD_SEARCH_IMPRESSIONS_SEE_MORE, { type });
    }
}
